<template>
  <div>
    <v-dialog v-model="nodeHistoryDialogue.show" width="70%">
      <v-card>
        <v-card-title>{{ nodeHistoryDialogue.label }}</v-card-title>
        <v-card-text>
          <v-timeline dense>
            <v-timeline-item
              v-for="(r, ri) in nodeHistoryDialogue.data"
              :key="'hna' + ri"
            >
              <v-card class="elevation-2">
                <v-card-title class="text-h5">
                  {{ r.transaction_date }} by
                  <v-chip :title="r.user_email" class="mx-2">{{
                    r.user_name
                  }}</v-chip>
                  - {{ r.transaction_sub_type }}
                  <v-spacer></v-spacer>
                  <v-chip title="Reason for Change"
                    label
                    outlined
                    v-if="r.transaction_reason_type"
                    >{{ r.transaction_reason_type
                    }}<v-tooltip v-if="r.transaction_reason_notes" bottom
                      ><template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" right>
                          mdi-message-text
                        </v-icon>
                      </template>
                      <span style="max-width: 500px">
                        {{ r.transaction_reason_notes }}
                      </span></v-tooltip
                    ></v-chip
                  >
                </v-card-title>
                <v-card-text
                  ><h3
                    v-html="getAuditDescriptionHTML(r.transaction_description)"
                  ></h3>
                  <ul style="padding-left: 50px">
                    <li
                      v-for="(n, ni) in r.nodes_affected"
                      :key="'hna' + ri + 'n' + ni"
                      style="padding-top: 12px"
                      v-html="getAuditDescriptionHTML(n.effect_description)"
                    ></li>
                  </ul>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
        <v-card-actions
          ><v-spacer></v-spacer>
          <v-btn @click="nodeHistoryDialogue.show = false"
            >Close</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "HierarchyNodeHistory",
  props: {
    node: Object,
  },
  data: function () {
    return {
      nodeHistoryDialogue: {
        show: false,
        label: "",
        data: [],
      },
    };
  },
  components: {},
  watch: {
    node(val) {
      if (val) this.getNodeHistory();
    },
  },
  computed: {},
  created: function () {
    if (this.node) this.getNodeHistory();
  },
  methods: {
    getNodeHistory() {
      let possibleError = false;
      axios
        .post("hierarchy/getHierarchyNodeAuditData", {
          ht_id: this.node.ht_id,
          hierarchy_node_id: this.node.hierarchy_node_id,
        })
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK" && resp.data.Data.length) {
            this.nodeHistoryDialogue.data = resp.data.Data;
            this.nodeHistoryDialogue.label = `Audit Trail for ${resp.data.Data[0].node_type} "${this.node.name}"`;
            this.nodeHistoryDialogue.show = true;
          } else {
            resp.data.Message = "No changes present for this node";
          }
          this.response = resp.data;
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
          this.isSaving = false;
        });
    },
    getAuditDescriptionHTML(text) {
      let html = "";
      text = text || "";
      let posS = text.indexOf('"');
      let posE = text.indexOf('"', posS + 1);
      let start = 0;
      while (posS >= 0 && posE > posS) {
        html += `${text.substring(
          start,
          posS - 1
        )} <div class="node-description">${text.substring(
          posS + 1,
          posE
        )}</div>`;
        start = posE + 1;
        posS = text.indexOf('"', start);
        posE = text.indexOf('"', posS + 1);
      }
      html += text.substring(start);
      return html;
    },
  },
};
</script>

<style scoped lang="scss">
.nodeContainer {
  width: 50%;
  height: 24px;
  padding-left: 6px;
}
.nodeSeleted {
  background-color: rgb(205, 243, 205);
}
.nodePicked {
  background-color: #e8e7e7;
}
.nodeInfoIcon {
  float: right;
  padding-top: 2px;
}
.nodeInner {
  display: block;
  height: 24px;
  font-family: "Martel Sans", sans-serif;
  font-size: 12px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 90%;
  float: left;
}
.node {
  border: solid 1px #004d40;
  border-radius: 5px;
  display: block;
  height: 24px;
  font-family: "Martel Sans", sans-serif;
  font-size: 12px;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  float: right;
}
.nodePickable {
  width: 80%;
}
.nodeNonPickable {
  width: 90%;
}
.nodeExpandable {
  cursor: pointer;
}
.pickable {
  width: 10%;
  float: right;
  cursor: grab;
}
</style>