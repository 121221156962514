<template>
  <div>
    <div class="wizard" v-if="model">
      <v-row class="mb-5">
        <v-col cols="2" class="d-flex align-center">
          <h3 class="mr-5">
            {{ currentStepTitle }}
          </h3>
        </v-col>
        <v-col cols="8">
          <v-stepper
            v-model="model.currentStep"
            alt-labels
            class="elevation-0"
            style="background: transparent"
          >
            <v-stepper-header class="d-flex align-center px-2 elevation-0">
              <template v-for="s in model.steps">
                <v-stepper-step
                  :key="'ss' + s.tws_id"
                  :complete="s.complete"
                  :step="s.stepNumber"
                  class="text-no-wrap text-small"
                  @click="completeStep(s.stepNumber)"
                >
                  {{ s.title }}
                </v-stepper-step>
                <v-divider
                  :key="'sd' + s.tws_id"
                  v-if="s.stepNumber < model.steps.length"
                ></v-divider>
              </template>
            </v-stepper-header>
          </v-stepper>
        </v-col>
        <v-col cols="2" class="d-flex align-center justify-end">
          <v-btn
            outlined
            color="primary"
            @click="prevStep"
            v-if="model.currentStep > 1"
            ><v-icon left>mdi-arrow-left</v-icon> Back</v-btn
          >
          <v-btn
            color="primary"
            class="ml-6"
            @click="nextStep"
            v-if="model.currentStep < model.steps.length"
            >Next <v-icon right>mdi-arrow-right</v-icon></v-btn
          >
          <v-btn
            color="primary"
            class="ml-6"
            elevation="6"
            :disabled="!isEditable"
            v-if="
              model.currentStep === model.steps.length && lifecycleActionsAvailable[model.final_action_id]
            "
            @click="doStateAction(lifecycleActionsAvailable[model.final_action_id])"
            >{{ lifecycleActionsAvailable[model.final_action_id].name }}</v-btn
          >
        </v-col>
      </v-row>

      <v-stepper v-model="model.currentStep">
        <v-stepper-items>
          <v-stepper-content
            v-for="s in model.steps"
            :key="s.tws_id"
            :step="s.stepNumber"
            :class="['px-0', s.sections.length !== 1 ? 'py-0' : '']"
          >
            <template v-if="s.sections.length === 1">
              <v-row dense :class="{ 'mx-3': !s.sections[0].controls.some(c => c.control_type.startsWith('FLEX_') )}">
                <v-col
                  v-for="c in s.sections[0].controls"
                  :key="'sc' + c.twc_id"
                  cols="12"
                  :md="c.cols"
                  :class="{
                    controlCol: true,
                    flexControlCol: c.control_type.startsWith('FLEX_'),
                  }"
                  v-show="!c.dependantControl || c.dependantControl.text === c.dependant_value"
                >
                  <v-tooltip bottom v-if="c.info_text || c.help_link" max-width="800px">
                    <template v-slot:activator="{ on, attrs }">
                      <a
                        v-if="c.help_link"
                        :href="c.help_link"
                        target="_blank"
                        >
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          class="info-icon"
                        >
                          mdi-help-circle-outline
                        </v-icon>  
                      </a>
                      <v-icon
                        v-else
                        v-bind="attrs"
                        v-on="on"
                        class="info-icon"
                      >
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <span v-html="c.info_text ? c.info_text : 'Click for infomation'"></span>
                  </v-tooltip>
                  
                  <h3 v-if="c.control_type === 'HEADING'">
                    {{ c.label }}
                  </h3>
                  <v-row
                    v-else-if="(c.control_type === 'READONLY' || !isEditable) && c.control_type !== 'LIFECYCLE_ACTION'"
                    dense
                  >
                    <v-col class="pt-0">
                      <label
                        class="
                          text-uppercase text--disabled
                          font-weight-bold
                          caption
                        "
                        >{{ c.label }}</label
                      >
                      <div
                        :class="`mt-1 ${c.control_class}`"
                        v-html="c.text ? c.text.replaceAll('\n', '<br />') : ''"
                      ></div>
                    </v-col>
                  </v-row>
                  <v-row v-else-if="c.hierarchy" dense>
                    <v-col class="pt-0">
                      <HierarchyTreePicker
                        v-if="useTreePicker"
                        v-model="c.hierarchy.treePickerValue"
                        :label="c.label"
                        :hint="c.hint"
                        :options="c.hierarchy.treePickerOptions"
                        :placeholder="c.label"
                        :error="c.error"
                        @change="validate(c)"
                      >
                      </HierarchyTreePicker>
                      <v-autocomplete
                        v-else-if="!c.hierarchy.selectNew"
                        class="roundish"
                        outlined
                        auto-select-first
                        :ref="'hcb' + c.ht_id"
                        :readonly="!editAllAllowed"
                        :clearable="editAllAllowed"
                        :required="c.required"
                        :error="c.error"
                        :hint="c.hint"
                        persistent-hint
                        :return-object="false"
                        :items="c.hierarchy.values"
                        item-value="text"
                        item-text="text"
                        :label="c.label"
                        v-model="c.text"
                        @focus="focusHTID = c.ht_id"
                        @keyup="searchText($event, c.hierarchy)"
                        @keyup.enter.exact="pickValue(c)"
                        @keyup.ctrl.enter="pickValue(c, true)"
                        @change="validate(c)"
                        :filter="utils.comboFilterPicker"
                      >
                        <template v-slot:item="{ item }">
                          <p style="max-width: 800px">{{ item.text }}</p>
                        </template>
                        <template v-slot:prepend-item>
                          <div
                            v-if="c.hierarchy.showNewPrompt"
                            style="padding: 0px 0px 10px 10px; font-size: 14px"
                          >
                            <div v-if="canAddHierarchy !== 0">
                              Press <kbd>Ctrl</kbd> + <kbd>Enter</kbd> to create
                              a new {{ c.hierarchy.label }}
                              <mark>{{ c.hierarchy.searchText }}</mark> or
                              <kbd>Enter</kbd> to pick the highlighted item
                            </div>
                            <div v-else>
                              Press <kbd>Enter</kbd> to pick the highlighted
                              item
                            </div>
                          </div>
                        </template>
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title v-if="canAddHierarchy !== 0">
                                No matching results. Press
                                <kbd>Ctrl</kbd> + <kbd>enter</kbd> key to create
                                the new
                                {{ c.hierarchy.label }}
                              </v-list-item-title>
                              <v-list-item-title v-else>
                                {{ c.hierarchy.not_in_list_message }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                      <v-row
                        dense
                        v-else
                        style="
                          border: solid #1976d2 2px;
                          margin: 2px;
                          border-radius: 4px;
                        "
                      >
                        <v-col cols="6"
                          ><h4
                            style="
                              text-transform: initial;
                              padding-bottom: 10px;
                              padding-top: 5px;
                            "
                          >
                            New {{ h.label }}:
                            <mark>{{ h.hierarchy_text }}</mark>
                          </h4>
                          Please select the hierarchy elements if you can or
                          press
                          <v-btn elevation="2" x-small @click="cancelNew(h)"
                            >Cancel</v-btn
                          >
                          to pick an existing {{ h.label }} <br /><br />
                          Note: If any levels are left empty the new
                          {{ h.label }} will be saved as "Unclassified"
                        </v-col>
                        <v-col cols="5">
                          <v-row
                            dense
                            v-for="(l, li) in h.levels"
                            :key="'h' + hi + 'l' + li"
                          >
                            <v-col cols="1"></v-col>
                            <v-col cols="11">
                              <v-text-field
                                :label="'Level ' + (li + 1)"
                                class="roundish"
                                readonly
                                v-if="li === h.levels.length - 1"
                                v-model="h.hierarchy_text"
                              ></v-text-field>
                              <v-select
                                v-else
                                class="roundish"
                                :menu-props="{ 'offset-y': true }"
                                v-model="l.selected"
                                return-object
                                :label="'Level ' + (li + 1)"
                                :items="l.items"
                                item-text="text"
                                item-value="text"
                                @change="classifyHierarchy(h, l)"
                              >
                              </v-select>
                            </v-col> </v-row
                        ></v-col>
                        <v-col cols="1"
                          ><v-icon
                            class="float-right"
                            @click="cancelNew(h)"
                            style="color: #1976d2"
                            >mdi-close</v-icon
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <RoleFlexQuestions
                    ref="flexQuestions"
                    v-else-if="c.control_type === 'FLEX_QUESTIONS'"
                    :docPart="c.part"
                    :documentId="document.doc_id.toString()"
                    :open="s.stepNumber === model.currentStep"
                    :fixedResults="false"
                    @completed="nextStep"
                  >
                  </RoleFlexQuestions>
                  <RoleFlexOptions
                    ref="flexOptions"
                    v-else-if="c.control_type === 'FLEX_OPTIONS' || c.control_type === 'FLEX_OPTIONS_FIXED'"
                    :docPart="c.part"
                    :documentId="document.doc_id.toString()"
                    :open="s.stepNumber === model.currentStep"
                    :fixedResults="c.control_type === 'FLEX_OPTIONS_FIXED'"
                    @flexupdated="saveFlex"
                  >
                  </RoleFlexOptions>
                  <v-select
                    v-else-if="c.control_type === 'SELECT'"
                    outlined
                    :label="c.label"
                    :placeholder="c.placeholder"
                    :required="c.required"
                    @blur="validate(c)"
                    :error="c.error"
                    :hint="c.hint"
                    persistent-hint
                    v-model="c.text"
                    :items="c.lookup"
                    item-value="varVal"
                    item-text="varVal"
                    class="roundish"
                  >
                  </v-select>
                  <v-text-field
                    v-else-if="c.control_type === 'TEXT'"
                    outlined
                    :label="c.label"
                    :placeholder="c.placeholder"
                    :required="c.required"
                    @blur="validate(c)"
                    :error="c.error"
                    :hint="c.hint"
                    persistent-hint
                    v-model="c.text"
                    class="roundish"
                  >
                  </v-text-field>
                  <v-textarea
                    v-else-if="c.control_type === 'TEXTAREA'"
                    outlined
                    :label="c.label"
                    :placeholder="c.placeholder"
                    :required="c.required"
                    :error="c.error"
                    :hint="c.hint"
                    persistent-hint
                    v-model="c.text"
                    class="roundish"
                    auto-grow
                  >
                  </v-textarea>
                  <v-combobox
                    v-else-if="c.control_type === 'COMBOBOX'"
                    outlined
                    :label="c.label"
                    :placeholder="c.placeholder"
                    :required="c.required"
                    :error="c.error"
                    :hint="c.hint"
                    persistent-hint
                    v-model="c.text"
                    class="roundish"
                  >
                  </v-combobox>
                  <v-menu
                    v-else-if="c.control_type === 'DATEPICKER'"
                    v-model="c.menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        prepend-inner-icon="mdi-calendar"
                        @click:prepend-inner="c.menu = true"
                        @blur="c.date = parseDate(c.text)"
                        v-bind="attrs"
                        v-on="on"
                        :label="c.label"
                        :placeholder="c.placeholder"
                        :required="c.required"
                        :error="c.error"
                        :hint="c.hint"
                        persistent-hint
                        v-model="c.text"
                        class="roundish"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="c.date"
                      @input="
                        c.menu = false;
                        c.text = formatDate(c.date);
                      "
                    ></v-date-picker>
                  </v-menu>
                  <div class="d-flex justify-end" v-else-if="c.control_type === 'LIFECYCLE_ACTION' && lifecycleActionsAvailable[c.lifecycle_action_id]">
                    <v-btn
                      :outlined="c.control_class.includes('outlined')"
                      :class="c.control_class"
                      @click="doStateAction(lifecycleActionsAvailable[c.lifecycle_action_id], c.lifecycle_action_data_control_id)">
                      {{ lifecycleActionsAvailable[c.lifecycle_action_id].name }}
                    </v-btn>
                  </div>
                  <v-divider
                    v-else-if="c.control_type === 'DIVIDER'"
                    class="mb-5"
                  >
                  </v-divider>
                </v-col>
              </v-row>
            </template>
            <v-expansion-panels
              class="stepSections"
              accordion
              v-else
              :value="s.expandedSectionIndex"
            >
              <v-expansion-panel
                v-for="(sec, seci) in s.sections"
                :key="`sec${seci}`"
              >
                <v-expansion-panel-header class="px-5 py-5">
                  <h3>{{ seci + 1 }}. {{ sec.section_name }}</h3>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row dense>
                    <v-col
                      v-for="c in sec.controls"
                      :key="'sc' + c.twc_id"
                      cols="12"
                      :md="c.cols"
                      :class="{
                        controlCol: true,
                        flexControlCol: c.control_type.startsWith('FLEX_'),
                      }"
                    >
                      <v-tooltip bottom v-if="c.info_text || c.help_link" max-width="800px">
                        <template v-slot:activator="{ on, attrs }">
                          <a
                            v-if="c.help_link"
                            :href="c.help_link"
                            target="_blank"
                            >
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              class="info-icon"
                            >
                              mdi-help-circle-outline
                            </v-icon>  
                          </a>
                          <v-icon
                            v-else
                            v-bind="attrs"
                            v-on="on"
                            class="info-icon"
                          >
                            mdi-help-circle-outline
                          </v-icon>
                        </template>
                        <span v-html="c.info_text ? c.info_text : 'Click for infomation'"></span>
                      </v-tooltip>
                      
                      <h3 v-if="c.control_type === 'HEADING'">
                        {{ c.label }}
                      </h3>
                      <v-row
                        v-else-if="c.control_type === 'READONLY' || !isEditable"
                        dense
                      >
                        <v-col class="pt-0">
                          <label
                            class="
                              text-uppercase text--disabled
                              font-weight-bold
                              caption
                            "
                            >{{ c.label }}</label
                          >
                          <div
                            :class="`mt-1 ${c.control_class}`"
                            v-html="
                              c.text
                                ? c.text.replaceAll('\n', '<br /><br />')
                                : ''
                            "
                          ></div>
                        </v-col>
                      </v-row>
                      <v-text-field
                        v-else-if="c.control_type === 'TEXT'"
                        outlined
                        :label="c.label"
                        :placeholder="c.placeholder"
                        :required="c.required"
                        :error="c.error"
                        :hint="c.hint"
                        persistent-hint
                        v-model="c.text"
                        class="roundish"
                      >
                      </v-text-field>
                      <v-textarea
                        v-else-if="c.control_type === 'TEXTAREA'"
                        outlined
                        :label="c.label"
                        :placeholder="c.placeholder"
                        :required="c.required"
                        :error="c.error"
                        :hint="c.hint"
                        persistent-hint
                        v-model="c.text"
                        class="roundish"
                        auto-grow
                      >
                      </v-textarea>
                      <v-combobox
                        v-else-if="c.control_type === 'COMBOBOX'"
                        outlined
                        :label="c.label"
                        :placeholder="c.placeholder"
                        :required="c.required"
                        :error="c.error"
                        :hint="c.hint"
                        persistent-hint
                        v-model="c.text"
                        class="roundish"
                      >
                      </v-combobox>
                      <v-divider
                        v-else-if="c.control_type === 'DIVIDER'"
                        class="my-5"
                      >
                      </v-divider>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <v-row class="mt-5" v-if="model.allow_close">
        <v-col class="d-flex justify-end">
          <v-btn
            color="primary"
            outlined
            @click="$emit('openDocFull', document)"
            >Open Doc Full</v-btn
          >
        </v-col>
      </v-row>
    </div>
    <ResponseHandler :serviceResponse="response"></ResponseHandler>
    <Loading :isVisible="!readOnly && isLoading" />
  </div>
</template>

<script>
import axios from "axios";
import ResponseHandler from "@/components/ResponseHandler";
import RoleFlexQuestions from "@/components/cRoleFlexQuestions";
import RoleFlexOptions from "@/components/cRoleFlexOptions";
import HierarchyTreePicker from "@/components/cHierarchyTreePicker";
import utils from "@/common/utils.js";
import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "DocumentFullWizard",
  components: {
    ResponseHandler,
    RoleFlexQuestions,
    RoleFlexOptions,
    HierarchyTreePicker
  },
  props: {
    documentId: null,
    docClosing: null,
    data: null,
    isNew: Boolean,
    readOnly: Boolean,
    actionsAvailable: null
  },
  data: function () {
    return {
      response: null,
      model: null,
      document: null,
      docStatus: null,
      docHierarchies: [],
      docClassifiers: [],
      hierarchies: [],
      canAddHierarchy: -1,
      useTreePicker: false,
      isLoading: false,
      wordChecks: [],
      utils: utils,
      focusHTID: null,
      stepValid: false,
      basicRules: [(v) => !!v || "Required"],
      savingCount: 0,
      completedCount: 0
    };
  },
  created() {
    this.setup();
  },
  updated() {
    this.setup();
  },
  computed: {
    ...mapState({
      newUi: (state) => state.theme.newUi,
      statuses: (state) => state.hierarchies.statuses,
    }),
    isEditable() {
      return !this.readOnly && this.docStatus && this.docStatus.allow_edit && this.document.state.canEditDocParts;
    },
    currentStepTitle() {
      if (!this.model) return "";

      return `${this.model.currentStep}. ${
        this.model.steps[this.model.currentStep - 1].title
      }`;
    },
    editAllAllowed() {
      return this.document.doc_status !== "APPROVED";
    },
    lifecycleActionsAvailable() {
      const actions = {};
      if (this.actionsAvailable) {
        this.actionsAvailable.forEach(a => {
          actions[a.lifecycle_action_id] = a;
        });
      }
      return actions;
    }
  },
  watch: {
    docClosing(val) {
      if (val) {
        this.completeStep();
      }
    },
  },
  methods: {
    setup() {
      if (this.data) {
        let docUpdated =
          !this.document ||
          this.document.doc_id != this.data.document.doc_id;

        if (docUpdated) {
          this.document = this.data.document;
          this.docHierarchies = this.data.docHierarchies;
          this.docClassifiers = this.data.document.classifiers;
          this.wordChecks = this.data.wordChecks;
          this.partsHistory = this.data.partsHistory;
          this.useTreePicker = !!this.$loginState.user.settings.find(
            (s) => s.setting === "hierarchy_tree_picker" && s.value === "true"
          );
          this.document.parttypes.forEach((pt) => {
            pt.parts
              .filter((p) => p.expanded)
              .forEach((p) => this.expandPart(p));
          });
          let hierarchies = JSON.parse(
            JSON.stringify(this.$store.state.hierarchies.hierarchies)
          );
          hierarchies.forEach((h) => {
            let hr = this.document.hierarchies.find(
              (dh) => dh.ht_id === h.ht_id
            );
            h.hr_id = hr ? hr.hr_id : null;
            h.hierarchy_text = hr
              ? h.values.find((v) => v.value === hr.hr_id)?.text
              : null;
            h.missing = hr ? false : true;
            h.treePickerValue = h.hr_id;
          });
          this.hierarchies = hierarchies;
          this.model = this.buildWizardModel(
            this.data.wizardSettings,
            this.document
          );
        }

        if (
          !this.docStatus ||
          this.docStatus.status !== this.data.docStatus.status
        ) {
          this.setDocStatus(this.data.docStatus);
        }
      }
    },
    setDocStatus(docStatus) {
      this.docStatus = docStatus;
    },
    buildWizardModel(wizardSettings, document) {
      const model = JSON.parse(JSON.stringify(wizardSettings));
      model.steps.forEach((s, si) => {
        s.stepNumber = si + 1;
        s.expandedSectionIndex = 0;
        s.sections.forEach((sec) => {
          sec.controls.forEach((c) => {
            c.text = "";
            c.hint = "";
            c.error = false;
            c.menu = false;
            c.lookup = null;
            if (c.tp_id) {
              const pt = document.parttypes.find(
                (pt) => pt.tmpl_part_id === c.tp_id
              );
              if (pt && pt.parts.length !== 0) {
                const dp = pt.parts[0];
                c.partType = pt;
                c.docPart = dp;
                if (
                  c.attr_index &&
                  c.attr_index >= 0 &&
                  dp.attributes &&
                  c.attr_index < dp.attributes.length
                ) {
                  c.docPartAttribute = dp.attributes[c.attr_index];
                  c.text = dp.attributes[c.attr_index].text;
                } else {
                  c.text = dp.text;
                }

                c.complete = !!c.text;
              }
            }

            if (c.ht_id) {
              c.hierarchy = this.hierarchies.find((h) => h.ht_id === c.ht_id);
              c.text = c.hierarchy.hierarchy_text;
              c.complete = !!c.text;
            }

            if (c.header_field) {
              c.text = this.document[c.header_field];
              c.complete = !!c.text;
            }

            if (c.control_type.startsWith('FLEX_')) {
              c.complete = document.flexscore > 5;
            }

            if (c.control_type === "DATEPICKER") {
              c.date = this.parseDate(c.text);
            }

            if (c.dependant_control_id) {
              c.dependantControl = sec.controls.find(dc => dc.twc_id === c.dependant_control_id);
            }

            sec.complete = !sec.controls.some((c) => c.required && !c.complete);
          });

          s.complete =
            si < model.steps.length - 1 &&
            !s.sections.some((sec) => !sec.complete);
        });
      });
      let defaultStep;
      switch (model.default_step) {
        case "FIRST_INCOMPLETE":
          defaultStep = model.steps.find((s) => !s.complete)?.stepNumber || 1;
          break;
        case "FIRST":
        default:
          defaultStep = 1;
          break;
      }
      this.gotoStep(model, defaultStep);
      return model;
    },
    nextStep() {
      this.completeStep(this.model.currentStep + 1);
    },
    prevStep() {
      this.completeStep(this.model.currentStep - 1);
    },
    validate(c, action) {
      if (
        this.useTreePicker &&
        c.ht_id &&
        c.hierarchy
      ) {
        c.text = !c.hierarchy.treePickerValue ? '' : c.hierarchy.values.find(h => h.value === c.hierarchy.treePickerValue)?.text;
      }

      if (c.control_type === "FLEX_QUESTIONS") {
        const flexQuestions = this.$refs.flexQuestions[0];
        c.error = !flexQuestions.checkAllComplete();
      } else if (c.control_type === "FLEX_OPTIONS" || c.control_type === "FLEX_OPTIONS_FIXED") {
        const flexOptions = this.$refs.flexOptions[0];
        c.error = !flexOptions.allAccepted();
      } else if ((c.required || (action && action.lifecycle_action_id === c.required_for_lifecycle_action_id)) && !c.text) {
        c.error = true;
        c.hint = c.label + " is required";
      } else {
        c.error = false;
        c.hint = "";
      }
    },
    completeStep(next, action, lifecycle_action_data_control_id) {
      const step = this.model.steps[this.model.currentStep - 1];
      if (step) {
        const funcs = this;
        const hierarchies = [];
        const headerFields = [];
        this.savingCount = 0;
        this.completedCount = 0;
        if (!this.isEditable) {
          if (next > 0 && next <= this.model.steps.length)
            this.gotoStep(this.model, next);
        } else {
          const callback = function () {
            funcs.checkSaveCompleted(step, next, action, lifecycle_action_data_control_id);
          };
          step.sections.forEach((sec) => {
            sec.controls.forEach((c) => {
              this.validate(c, action);
            });
            sec.complete = !sec.controls.some((c) => c.error);
          });
          step.complete = !step.sections.some((sec) => !sec.complete);

          step.sections.forEach((sec) => {
            sec.controls.forEach((c) => {
              if (c.control_type === "READONLY") return;

              if (c.control_type === "FLEX_QUESTIONS") {
                const flexQuestions = this.$refs.flexQuestions[0];
                this.savingCount++;
                flexQuestions.saveAnswers(callback);
              } else if (c.control_type === "FLEX_OPTIONS" || c.control_type === "FLEX_OPTIONS_FIXED") {
                const flexOptions = this.$refs.flexOptions[0];
                this.savingCount++;
                flexOptions.saveOptions("all", true, callback);
              } else if (c.docPart) {
                if (c.docPartAttribute && c.docPartAttribute.text !== c.text) {
                  const attributes = c.docPart.attributes;
                  attributes[c.attr_index].text = c.text;
                  attributes[c.attr_index].isDirty = true;
                  this.savingCount++;
                  this.saveRow(
                    c.docPart,
                    c.text,
                    "",
                    undefined,
                    false,
                    attributes,
                    c.docPart.quality,
                    c.partType,
                    callback
                  );
                } else if (!c.docPartAttribute && c.text !== c.docPart.text) {
                  this.savingCount++;
                  this.saveRow(
                    c.docPart,
                    c.text,
                    "",
                    undefined,
                    false,
                    [],
                    c.docPart.quality,
                    c.partType,
                    callback
                  );
                }
              }

              if (
                c.ht_id &&
                c.hierarchy &&
                (c.hierarchy.isnew || c.hierarchy.hierarchy_text !== c.text || c.hierarchy.hr_id !== c.hierarchy.treePickerValue)
              ) {
                hierarchies.push(c);
              }

              if (
                c.header_field && this.document[c.header_field] !== c.text
              ) {
                headerFields.push({
                  name: c.header_field,
                  value: c.text
                });
              }
            });

            if (hierarchies.length || headerFields.length) {
              this.savingCount++;
              this.saveDocHeader(hierarchies, headerFields, callback);
            }
          });

          if (this.savingCount === 0) callback();
        }
      }
    },
    saveDocHeader(controls, headerFields, callback) {
      this.isLoading = true;
      let data = {
        system_number: this.document.system_number,
        doc_id: this.document.doc_id,
        doc_status: this.document.doc_status,
        doc_status_text: this.docStatus.text,
        recruiter: this.document.recruiter,
        contact: this.document.contact,
        approval_requested_date: this.document.approval_requested_date,
        start_date: this.document.start_date,
        quality: this.document.quality,
        hierarchies: controls.map((c) => {
          let h = c.hierarchy;
          let hr;
          if (c.hierarchy.treePickerValue)
            hr = c.hierarchy.values.find(v => v.value === c.hierarchy.treePickerValue)
          else
            hr = c.hierarchy.values.find(
              (v) =>
                v.text.toLowerCase() === (c.text || "").toLowerCase() && v.value
            );
          let ret = {
            ht_id: c.ht_id,
            hr_id: hr ? hr.value : c.text,
            isNew: hr ? false : true,
            wasMissing: h.missing,
          };
          [1, 2, 3, 4, 5, 6].forEach((n) => (ret["level" + n] = null));
          if (ret.isNew && !h.levels.some((l) => !l.selected)) {
            let missing = false;
            h.levels.forEach((l) => {
              ret["level" + l.index] = l.selected.text;
              if (!l.selected.text) missing = true;
            });
            if (missing) {
              h.levels.forEach((l) => {
                ret["level" + l.index] = null;
              });
            }
          }
          return ret;
        }),
        docClassifiers: [],
      };

      headerFields.forEach(h => {
        data[h.name] = h.value;
      });

      axios
        .post("document/saveDocumentHeader/", data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            controls.forEach((c) => {
              if (c.hierarchy) {
                c.hierarchy.hierarchy_text = c.text;
                c.hierarchy.hr_id =
                  resp.data.Data.documents[0].hierarchies?.find(
                    (h) => h.ht_id == c.ht_id
                  )?.hr_id;
              }
            });
            headerFields.forEach(h => {
              this.document[h.name] = h.value;
            });
            this.$store.dispatch("docs/updateDoc", resp.data.Data.documents[0]);
            this.$emit("docUpdated", resp.data.Data.documents[0]);
          } else {
            alert(resp.data.Message);
          }
          if (typeof callback === "function") callback();
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    checkSaveCompleted(step, next, action, lifecycle_action_data_control_id) {
      if (this.savingCount > 0) this.completedCount++;
      if (this.completedCount === this.savingCount) {
        this.isLoading = false;
        if (this.savingCount > 0) {
          this.response = { Status: "OK", Message: "Document details updated" };
        }
        if (action) {
          if (step.complete) {
            let actionData = null;
            step.sections.forEach(s => { 
              s.controls.forEach(c => {
                if (c.twc_id === lifecycle_action_data_control_id)
                  actionData = c.text;
              });
            });
            this.$emit("doStateAction", { action: action, data: actionData });
          }
        } else if (next > this.model.currentStep) {
          if (
            step.complete &&
            next <= this.model.steps.length
          ) {
            this.gotoStep(this.model, next);
          }
        } else if (next > 0) {
          this.gotoStep(this.model, next);
        }
      }
    },
    gotoStep(model, stepNumber) {
      if (!model.steps.filter((s, si) => si < stepNumber - 1).every(s => s.complete))
        return;

      model.currentStep = stepNumber;
      const step = model.steps[stepNumber - 1];
      step.sections.forEach((sec) => {
        sec.controls.forEach((c) => {
          c.error = false;
          c.hint = "";

          if (c.docPart) {
            if (c.docPartAttribute) {
              c.text = c.docPartAttribute.text;
            } else {
              c.text = c.docPart.text;
            }
          }

          if (c.control_type === "SELECT" || c.control_type === "COMBOBOX")
            this.loadLookup(c);

          if (c.ht_id) {
            c.hierarchy = this.hierarchies.find((h) => h.ht_id === c.ht_id);
            c.text = c.hierarchy.hierarchy_text;
          }
        });
      });
    },
    loadLookup(control) {
      if (!control.lookup) {
        axios
          .get(
            "document/getTemplatePartValues/" +
              `${control.tp_id}_${
                control.docPartAttribute
                  ? control.docPartAttribute.attr_id
                  : "0"
              }_${control.docPart ? control.docPart.doc_part_id : "0"}_${
                control.twc_id
              }`
          )
          .then((resp) => {
            control.lookup = resp.data.Data;
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              console.log(err);
              this.response = err.response
                ? err.response.data
                : { message: "Unexpected Error" };
            }
          });
      }
    },
    savePart(pt, part) {
      this.saveRow(
        part,
        part.text,
        part.notes,
        part.subParts,
        part.is_essential,
        part.attributes,
        part.quality,
        pt
      );
    },
    saveRow(
      row,
      newval,
      newnotes,
      newsubparts,
      is_essential,
      attributes,
      quality,
      partType,
      callback
    ) {
      this.isLoading = true;
      if (newsubparts && newsubparts.length) {
        newval = utils.subpartsToPart(newsubparts);
      }
      const isNew = !row.doc_part_id;
      let data = {
        text: newval,
        notes: newnotes,
        is_essential: is_essential,
        attributes: attributes
          ? attributes
              .filter((a) => a.isDirty)
              .map((a) => {
                return { tpa_id: a.tpa_id, dpa_id: a.dpa_id, text: a.text };
              })
          : [],
        quality: quality,
        doc_part_id: row.doc_part_id,
        doc_part_type: partType.type,
        tmpl_part_id: row.tmpl_part_id,
      };
      if (isNew) {
        data.sequence = row.sequence;
        data.doc_id = this.document.doc_id;
        data.system_number = this.document.system_number;
        data.parent_dp_id = row.parent_dp_id;
      }
      const doc = this.document;

      axios
        .post("document/savedocpart/", data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            row.isDirty = false;
            row.hover = false;
            row.editing = false;
            row.text = newval;
            row.notes = newnotes;
            row.is_essential = is_essential;
            row.quality = quality;
            if (row.attributes)
              row.attributes.forEach((a) => {
                a.editing = false;
                a.isDirty = false;
              });
            utils.setDocPartStatus(
              row,
              partType,
              this.wordChecks,
              this.docStatus,
              this.document
            );
            if (isNew) {
              row.doc_part_id = parseInt(resp.data.Data.newRow.doc_part_id);
              row.dp_name = resp.data.Data.newRow.dp_name;
              if (row.attributes)
                row.attributes.forEach((a) => {
                  a.dpa_id = resp.data.Data.newRowAttributes.find(
                    (na) => na.tpa_id === a.tpa_id
                  ).dpa_id;
                });

              let parent = null;
              if (row.parent_dp_id) {
                this.document.parttypes.forEach((pt) => {
                  if (!parent) {
                    parent = pt.parts.find(
                      (p) => p.doc_part_id === row.parent_dp_id
                    );
                  }
                });
              }

              if (partType.parts) {
                row.childParts = [];
                row.expanded = false;
                if (
                  !partType.parts.some((x) => x.doc_part_id === row.doc_part_id)
                ) {
                  if (row.newIndex >= 0) {
                    partType.parts.splice(row.newIndex, 0, row);
                  } else {
                    partType.parts.push(row);
                  }
                }

                if (parent) {
                  parent.childParts = partType.parts;
                }

                partType.parts.forEach((p) => {
                  utils.setDocPartStatus(
                    p,
                    partType,
                    this.wordChecks,
                    this.docStatus,
                    this.document
                  );
                });
              } else if (parent) {
                parent.childParts.push(row);
              }
            }
            if (typeof callback === "function") callback();
          } else {
            row.rowMessage = resp.data.Message;
          }
          this.$emit("docUpdated", doc);
          this.$emit("wordChecksUpdated", this.wordChecks);
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            row.rowMessage = err;
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    saveFlex(flexModel) {
      //this.isLoading = true;
      let pts = this.document.parttypes;
      const updateAttr = (pHead, outcome, outcomeColour) => {
        if (pHead) {
          const newOutcome = outcome
            ? `<div class="chip-${outcomeColour}">${outcome}</div>`
            : "";
          if (pHead.parts[0].attributes[1].text !== newOutcome) {
            pHead.parts[0].attributes[1].text = newOutcome;
            pHead.parts[0].attributes[1].isDirty = true;
            this.savePart(pHead, pHead.parts[0]);
          }
        }
      };
      flexModel.sections.forEach((s) => {
        let pt = pts.find((t) => t.type === s.name);
        let pth = pts.find((t) => t.type === s.name + " Header");
        let ptn = pts.find((t) => t.type === s.name + " Notes");
        if (
          pt &&
          pt.parts[0].subParts.length &&
          pt.parts[0].subParts[0].text !== s.outcomeDesc.trim()
        ) {
          pt.parts[0].subParts[0].text = s.outcomeDesc.trim();
          pt.parts[0].text = s.outcomeDesc;
          this.savePart(pt, pt.parts[0]);
        }
        if (ptn && ptn.parts[0].attributes[1].text !== s.comment) {
          ptn.parts[0].attributes[1].text = s.comment;
          ptn.parts[0].attributes[1].isDirty = true;
          this.savePart(ptn, ptn.parts[0]);
        }
        updateAttr(pth, s.outcome, s.outcomeColour);
      });
      flexModel.options.forEach((o) => {
        let pt = pts.find((t) => t.type === "FO " + o.sectionName);
        let pth = pts.find((t) => t.type === "FO " + o.sectionName + " Header");
        let ptn = pts.find((t) => t.type === "FO " + o.sectionName + " Notes");
        if (pt) {
          let newSubParts = [];
          if (o.activeQuestions.filter((q) => q.outputText).length) {
            newSubParts = o.activeQuestions
              .filter((q) => q.outputText)
              .map((q) => {
                return { editing: false, text: q.outputText, type: "listitem" };
              });
          } else if (o.outcome) {
            newSubParts = [
              { editing: false, text: "Not applicable", type: "listitem" },
            ];
          } else {
            newSubParts = [];
          }
          const newText = utils.subpartsToPart(newSubParts);
          if (utils.subpartsToPart(pt.parts[0].subParts) !== newText) {
            pt.parts[0].text = newText;
            pt.parts[0].subParts = newSubParts;
            this.savePart(pt, pt.parts[0]);
          }
        }
        if (ptn && ptn.parts[0].attributes[1].text !== o.comment) {
          ptn.parts[0].attributes[1].text = o.comment;
          ptn.parts[0].attributes[1].isDirty = true;
          this.savePart(ptn, ptn.parts[0]);
        }
        updateAttr(pth, o.outcome, o.outcomeColour);
      });
    },
    formatDate(date) {
      if (!date) return null;
      return moment(date).format("DD MMM YYYY");
    },
    parseDate(date) {
      if (!date) return null;
      return moment(date, "DD MMM YYYY").format("YYYY-MM-DD");
    },

    doStateAction(action, lifecycle_action_data_control_id) {
      if (action) {
        this.completeStep(0, action, lifecycle_action_data_control_id);
      }
    },
    //Hierarchy picker functions
    pickValue(c, isnew) {
      const hrchy = c.hierarchy;
      let ctl = this.$refs["hcb" + this.focusHTID][0],
        h = this.hierarchies.find((h) => h.ht_id === this.focusHTID);
      ctl.isMenuActive = false;
      if (
        this.canAddHierarchy !== 0 &&
        (isnew || !ctl.filteredItems.length) &&
        hrchy.searchText
      ) {
        c.text = hrchy.searchText;
        h.hierarchy_text = hrchy.searchText;
        hrchy.isnew = true;
        if (this.canAddHierarchy === 1) {
          hrchy.selectNew = true;
        } else {
          hrchy.values.push({ text: hrchy.searchText });
        }
      } else {
        ctl.lazySearch = h.hierarchy_text;
      }
      hrchy.showNewPrompt = false;
    },
    searchText($event, hrchy) {
      let ctl = this.$refs["hcb" + this.focusHTID][0];
      if ([13, 37, 38, 39, 40].indexOf($event.keyCode) >= 0) return;
      if (ctl.lazySearch) {
        if ($event.keyCode !== 13) hrchy.searchText = ctl.lazySearch.trim();
        let match = ctl.filteredItems.find(
          (f) => f.text.toLowerCase() === ctl.lazySearch.toLowerCase()
        );
        if (match) {
          ctl.selectItem(match);
          hrchy.hierarchy_text = match.text;
          hrchy.hr_id = match.hr_id;
          hrchy.showNewPrompt = false;
        } else {
          hrchy.showNewPrompt =
            ctl.filteredItems.length &&
            (!hrchy.hierarchy_text ||
              hrchy.hierarchy_text.toLowerCase() !==
                hrchy.searchText.toLowerCase());
        }
      } else {
        hrchy.hierarchy_text = "";
        hrchy.showNewPrompt = false;
      }
    },
    cancelNew(h) {
      h.selectNew = false;
      h.searchText = "";
      h.showNewPrompt = false;
    },
    //end hierarchy picker functions
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/vars";

::v-deep .v-stepper__step.v-stepper__step--complete:not(.v-stepper__step--active) {
  cursor: pointer;
  .v-stepper__step__step {
    background-color: #00b515 !important;
  }
  .v-stepper__label {
    color: #00b515 !important;
  }

}

.info-icon {
  float:left;
  margin: 14px 2px 0 -26px;
  opacity: 0.6;
}

.controlCol {
  padding: 12px 24px !important;
  &.flexControlCol {
    padding: 12px 0 !important;
  }
}

.stepSections ::v-deep .v-expansion-panel-header {
  display: flex;
  flex-direction: row-reverse;
  align-content: center;
  .v-expansion-panel-header__icon {
    width: 30px;
    height: 30px;
    margin-right: 20px;
    background-color: #f2f4fa;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-content: center;
  }
  &.v-expansion-panel-header--active {
    color: #377dff;
    .v-expansion-panel-header__icon i {
      color: #377dff !important;
    }
  }
}

.stepSections ::v-deep .v-expansion-panel-content {
  .v-expansion-panel-content__wrap {
    padding-left: 50px;
  }
}

::v-deep .vue-treeselect {
  .vue-treeselect__menu {
    max-height: 190px !important;
  }
}
</style>