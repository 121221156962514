<template>
  <div>
    <v-card>
      <v-toolbar fixed dense color="grey lighten-5">
        <v-btn small tile @click="closeAddDocument" style="margin-right: 15px">
          <v-icon>mdi-close</v-icon>Close
        </v-btn>
        <v-toolbar-title>Create New Document:</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="clearFilters">Clear Selection</v-btn>
      </v-toolbar>
      <v-card-text dense style="min-height: 650px">
        <v-row dense wrap>
          <v-col cols="4" v-if="docNames.length > 1">
            <v-select
              dense
              outlined
              :return-object="false"
              :items="docNames"
              label="Document Type"
              item-text="docType"
              item-value="docType"
              v-model="docType"
              @change="toggleDocTypeSelection"
            ></v-select>
          </v-col>
          <v-col cols="4" v-for="(h, hi) in hierarchies" :key="'h' + hi">
            <v-autocomplete
              v-if="!h.selectNew"
              dense
              outlined
              auto-select-first
              :ref="'hcb' + hi"
              :return-object="false"
              :items="h.values"
              item-value="text"
              item-text="text"
              :label="h.label"
              v-model="h.hierarchy_text"
              @focus="focusHTID = hi"
              @keyup="searchText($event, h)"
              @keyup.enter.exact="pickValue(h)"
              @keyup.ctrl.enter="pickValue(h, true)"
              :filter="utils.comboFilterPicker"
              @change="isCreateAllowed()"
            >
              <template v-slot:item="{ item }">
                <p style="max-width: 800px">{{ item.text }}</p>
              </template>
              <template v-slot:prepend-item>
                <div
                  v-if="h.showNewPrompt"
                  style="padding: 0px 0px 10px 10px; font-size: 14px"
                >
                  <div v-if="canAddHierarchy !== 0">
                    Press <kbd>Ctrl</kbd> + <kbd>Enter</kbd> to create a new
                    {{ h.label }} <mark>{{ h.searchText }}</mark> or
                    <kbd>Enter</kbd> to pick the highlighted item
                  </div>
                  <div v-else>
                    Press <kbd>Enter</kbd> to pick the highlighted item
                  </div>
                </div>
              </template>
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title v-if="canAddHierarchy !== 0">
                      No matching results. Press
                      <kbd>Ctrl</kbd> + <kbd>enter</kbd> key to create the new
                      {{ h.label }}
                    </v-list-item-title>
                    <v-list-item-title v-else>
                      {{h.not_in_list_message}}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
            <v-row
              dense
              v-else
              style="border: solid #1976d2 2px; margin: 2px; border-radius: 4px"
            >
              <v-col cols="6"
                ><h4
                  style="
                    text-transform: initial;
                    padding-bottom: 10px;
                    padding-top: 5px;
                  "
                >
                  New {{ h.label }}: <mark>{{ h.hierarchy_text }}</mark>
                </h4>
                Please select the hierarchy elements if you can or press
                <v-btn elevation="2" x-small @click="cancelNew(h)"
                  >Cancel</v-btn
                >
                to pick an existing {{ h.label }} <br /><br />
                Note: If any levels are left empty the new {{ h.label }} will be
                saved as "Unclassified"
              </v-col>
              <v-col cols="5">
                <v-row
                  dense
                  v-for="(l, li) in h.levels"
                  :key="'h' + hi + 'l' + li"
                >
                  <v-col cols="1"></v-col>
                  <v-col cols="11">
                    <v-text-field
                      :label="'Level ' + (li + 1)"
                      dense
                      readonly
                      v-if="li === h.levels.length - 1"
                      v-model="h.hierarchy_text"
                    ></v-text-field>
                    <v-select
                      v-else
                      dense
                      v-model="l.selected"
                      return-object
                      :label="'Level ' + (li + 1)"
                      :items="l.items"
                      item-text="text"
                      item-value="text"
                      @change="classifyHierarchy(h, l)"
                    >
                    </v-select>
                  </v-col> </v-row
              ></v-col>
              <v-col cols="1"
                ><v-icon
                  class="float-right"
                  @click="cancelNew(h)"
                  style="color: #1976d2"
                  >mdi-close</v-icon
                >
              </v-col>
            </v-row>
            <!-- <v-combobox
              dense
              outlined
              clearable
                  :ref="'hcb' + hi"
              :return-object="false"
              :items="h.values"
              :label="h.label"
              item-value="text"
              item-text="text"
              v-model="docHierarchies[hi].hierarchy_text"
              :filter="utils.comboFilterPicker"
              @focus="focusHTID = hi"
              @keyup.enter="pickValue"
              @change="isCreateAllowed()"
            >
              <template v-slot:item="{ item }">
                <p style="max-width: 800px;">{{item.text}}</p>
              </template>
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      No matching results. Press
                      <kbd>enter</kbd> to link to a new Job Title
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox> -->
          </v-col>
          <v-col cols="4">
            <v-combobox
              dense
              outlined
              clearable
              :return-object="false"
              :items="recruiters"
              label="Recruiter"
              item-text="text"
              item-value="text"
              @change="isCreateAllowed()"
              v-model="recruiter"
              :filter="utils.comboFilterPicker"
            >
              <template v-slot:item="{ item }">
                <p style="max-width: 800px">{{ item.text }}</p>
              </template>
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      No matching results. Press
                      <kbd>enter</kbd> to link to a new Recruiter
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </v-col>
          <v-col cols="4" dense style="display:flex">
            <v-col cols="6" dense style="padding-top: 2px;" v-if="!createExternal" >
              <v-btn :disabled="creationDisabled" @click="createDocument(false)">Create New Document</v-btn>
            </v-col>
            <v-col cols="6" dense style="padding-top: 2px;">
              
            <!--<FileUploader
              :fileUploadData="fileUploadFormData"
              :uploadDisabled="creationDisabled"
              :uploadedCallback="filesUploadedCallback"
              :useTranslation="useTranslation"
              multiple="false"
            />-->
            </v-col>
          </v-col>
          <v-col cols="6"
            >{{ matches.length }} Document{{
              matches.length !== 1 ? "s" : ""
            }}
            match the current selection.</v-col
          >
        </v-row>
        <v-row dense v-if="matches.length">
          <v-col>
            <v-data-table
              :headers="dtHeaders"
              dense
              :items="matches"
              sort-by="system_number"
              class="elevation-1"
              :footer-props="{ 'items-per-page-options': [10, 20, 30, 50, -1] }"
              :items-per-page="10"
              @click:row="openDocument"
            >
              <!-- @click:row="openDocument"
              @update:options="dtOptionChange"-->
              <template v-slot:system_number="{ item }">
                <PartTypeIcon
                  v-if="docNames.length > 1"
                  :partType="item.doc_type_abbrev"
                ></PartTypeIcon>
                <span
                  v-html="item.system_number"
                  style="font-size: 0.8rem"
                ></span>
              </template>
              <template v-slot:doc_name="{ item }">
                <span v-html="item.doc_name"></span>
              </template>
              <template v-slot:doc_status_text="{ item }">
                <span v-html="item.doc_status_text"></span>
              </template>
              <template v-slot:location="{ item }">
                <span v-html="item.location"></span>
              </template>
              <template v-slot:cost_center="{ item }">
                <span v-html="item.cost_center"></span>
              </template>
              <template v-slot:recruiter="{ item }">
                <span v-html="item.recruiter"></span>
              </template>
              <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">Reset</v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <ResponseHandler :serviceResponse="response"></ResponseHandler>
    <Loading :isVisible="isLoading" />
  </div>
</template>

<script>
import axios from "axios";
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
//import FileUploader from "../components/cFileUpload.vue";
import utils from "@/common/utils.js";
import PartTypeIcon from "@/components/cPartTypeIcon";

export default {
  name: "DocumentAdd",
  components: {
    ResponseHandler,
    //FileUploader,
    PartTypeIcon,
  },
  props: {
    open: null,
  },
  data: function () {
    return {
      useTranslation: false,
      documents: [],
      matches: [],
      createExternal: false,
      creationDisabled: true,
      canAddHierarchy: -1,
      hierarchies: [],
      fileUploadFormData: {},
      locations: [],
      costcentres: [],
      recruiters: [],
      managers: [],
      jobtypes: [],
      jobtype: "",
      location: "",
      costcentre: "",
      recruiter: "",
      response: null,
      document: null,
      docHierarchies: [],
      focusHTID: null,
      isLoading: false,
      utils: utils,
      docNames: ["Job Advert"],
      docType: null,
      dtHeaders: [
        { text: "System Number", value: "system_number" },
        { text: "Job Title", value: "listview1" },
        { text: "Status", value: "doc_status_text" },
        { text: "Location", value: "listview2" },
        { text: "Cost Center", value: "listview3" },
        { text: "Recruiter", value: "recruiter" },
        { text: "Created", value: "job_created_at" },
        { text: "", value: "action" },
      ],
    };
  },
  //   mounted() {
  //     this.fetchData();
  //   },
  updated() {},
  watch: {
    open: {
      immediate: true,
      handler(val) {
        if (val) {
          this.clearFilters();
          this.fetchData();
        }
      },
    },
    // docHierarchies: {
    hierarchies: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.getMatches();
        }
      },
    },
    recruiter() {
      this.getMatches();
    },
  },
  methods: {
    isCreateAllowed() {
      this.creationDisabled =
        this.hierarchies.filter((h) => h.hierarchy_text).length !==
          this.hierarchies.length || !this.recruiter;

      if (!this.creationDisabled) {
        this.fileUploadFormData = {
          hr_ids: [],
          doc_id: [],
          doc_type: this.docType,
          createFiles: true
        };
        //   this.docHierarchies.forEach(dh => {
        this.hierarchies.forEach((dh) => {
          if (dh.hierarchy_text) {
            let rootHierarchy = this.hierarchies.filter(
              (rh) => rh.ht_id == dh.ht_id
            );
            if (rootHierarchy) {
              let matchedHierarchy = rootHierarchy[0].values.find(
                (h) => h.text == dh.hierarchy_text
              );
              if (matchedHierarchy) {
                this.fileUploadFormData.hr_ids.push(matchedHierarchy.value);
              }
            }
          }
        });

        if(this.docType == "External File"){
          this.fileUploadFormData.createFiles = false;
        }

        this.fileUploadFormData = JSON.parse(
          JSON.stringify(this.fileUploadFormData)
        );
      }
    },
    toggleDocTypeSelection() {
      this.createExternal = this.docType === "External File" ? true : false;
      this.isCreateAllowed();
    },
    filesUploadedCallback(data) {
      let responseData = data[0];
      if(responseData.newDocuments && responseData.newDocuments.length > 1){
        //doc already created
        
      this.isLoading = false;
        this.response = responseData;
        this.$emit("created", responseData.newDocuments[responseData.newDocuments.length-1]);
      } else {
        
      this.isLoading = false;
        this.createDocument(data);
      }
    },
    fetchData() {
      this.isLoading = true;
      axios
        .get("document/getHierarchyValues/")
        .then((resp) => {
          if (resp.data.Status === "OK") {
            this.hierarchies = this.setHierarchies(resp.data.Data.hierarchies);
            this.recruiters = resp.data.Data.lookups.filter(
              (x) => x.type === "Recruiter"
            );
            this.managers = resp.data.Data.lookups.filter(
              (x) => x.type === "Manager"
            );
            // this.docNames = resp.data.Data.docTypes
            //   .sort((a, b) => a.tt_id - b.tt_id)
            //   .map((dt) => dt.tmpl_name);
            this.docNames = this.$loginState.user.createDocumentDialogOptions
              .sort((a, b) => a.tmpl_id - b.tmpl_id)
              .map((dt) => dt.tmpl_name);
            this.docType = this.docNames[0];
            this.canAddHierarchy = resp.data.Data.canAddHierarchy;
            this.errMessage = "";
          } else {
            this.errMessage = resp.data.message;
          }
          this.isLoading = false;
          //console.log(resp);
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
      axios
        .get("document/getDocs/")
        .then((resp) => {
          if (resp.data.Status === "OK") {
            resp.data.Data.hierarchyTypes.forEach((ht) => {
              let hdr = this.dtHeaders.find(
                (hd) => hd.value === "listview" + ht.position
              );
              if (hdr) hdr.text = ht.label;
            });
            let lvCols = resp.data.Data.hierarchyTypes.map(
              (ht) => "listview" + ht.position
            );

            let translateSetting = resp.data.Data.settings.find(x => x.setting === "UseTranslation");
            if(translateSetting){
              this.useTranslation = translateSetting.value == 'true' ? true : false;
            } else {
              this.useTranslation = false;
            }

            resp.data.Data.documents.forEach((d) => {
              lvCols.forEach((c) => {
                d[c] = "";
              });
              d.hierarchies.forEach((h) => {
                d["listview" + h.position] = h.hierarchy1;
              });
            });
            this.documents = resp.data.Data.documents;
            this.errMessage = "";
          } else {
            this.errMessage = resp.data.message;
          }
          this.isLoading = false;
          //console.log(resp);
        })
        .catch((err) => {
          alert("Server Error: " + err);
          console.log(err);
        });
    },
    getMatches() {
      //let hfilter = this.docHierarchies
      let hfilter = this.hierarchies
        .filter((ht) => ht.hierarchy_text)
        .map((ht) => {
          let sel = this.hierarchies
            .find((h) => h.ht_id === ht.ht_id)
            .values.find(
              (v) => v.text.toLowerCase() === ht.hierarchy_text.toLowerCase()
            );
          return sel ? sel.value : "";
        });
      let recFilter =
        this.recruiter && this.recruiters.some((x) => x.text === this.recruiter)
          ? this.recruiter
          : "";
      if (!recFilter && !hfilter.length) {
        this.matches = [];
      } else {
        this.matches = this.documents.filter(
          (x) =>
            (!hfilter.length ||
              hfilter.every((f) =>
                x.hierarchies.some((hv) => hv.hr_id === f)
              )) &&
            (!recFilter || x.recruiter === recFilter)
        );
      }
    },
    closeAddDocument() {
      this.creationDisabled = true;
      this.$emit("closed");
    },
    clearFilters() {
      //   if (this.docHierarchies)
      //     this.docHierarchies.forEach(dh => (dh.hierarchy_text = null));
      this.hierarchies.forEach((dh) => (dh.hierarchy_text = null));
      this.recruiter = null;
      this.creationDisabled = true;
    },
    linkFileToDoc(docData) {
      this.isLoading = true;
      let data = {
        doc_id: docData.doc_id,
        file_id: docData.file_data[0].file_id,
      };

      axios
        .post("file/linkFileToDoc/", data)
        .then((resp) => {
          this.response = resp.data;
          console.log(resp);
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    createDocument(uploaded) {
      this.isLoading = true;
      let data = this.checkNew();
      if (!data) return;
      //   let data = {
      //     hierarchies: this.docHierarchies.map(dh => {
      // 	let sel = this.hierarchies.find(h => h.ht_id === dh.ht_id).values.find(v => v.text.toLowerCase() === dh.hierarchy_text.toLowerCase());
      //       return { ht_id: dh.ht_id, hr_id: sel ? sel.value : dh.hierarchy_text, isNew: !sel };
      //     }),
      //     recruiter: this.recruiter,
      //     docType: this.docType
      //   };

      axios
        .post("document/createDocument/", data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            if (uploaded && uploaded.file_id) {
              //todo link doc
              resp.data.Data.file_data = uploaded;
              this.linkFileToDoc(resp.data.Data);
            }
            this.creationDisabled = true;
            this.$emit("created", resp.data.Data);
          } else {
            alert(resp.data.Message);
          }
          this.response = resp.data;
          console.log(resp);
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    openDocument(item) {
      this.$emit("openDocument", item);
    },
    checkNew() {
      let data = {
        // hierarchies: this.docHierarchies.map(dh => {
        // let sel = this.hierarchies.find(h => h.ht_id === dh.ht_id).values.find(v => v.text.toLowerCase() === dh.hierarchy_text.toLowerCase());
        //   return { ht_id: dh.ht_id, hr_id: sel ? sel.value : dh.hierarchy_text, isNew: !sel };
        // }),
        recruiter: this.recruiter,
        docType: this.docType,
        hierarchies: this.hierarchies.map((h) => {
          let hr = h.values.find(
            (v) =>
              v.text.toLowerCase() === (h.hierarchy_text || "").toLowerCase() && v.value
          );
          let ret = {
            ht_id: h.ht_id,
            hr_id: hr ? hr.value : h.hierarchy_text,
            isNew: hr ? false : true,
            wasMissing: h.missing,
          };
          [1, 2, 3, 4, 5, 6].forEach((n) => (ret["level" + n] = null));
          if (ret.isNew && !h.levels.some((l) => !l.selected)) {
            let missing = false;
            h.levels.forEach((l) => {
              ret["level" + l.index] = l.selected.text;
              if (!l.selected.text) missing = true;
            });
            if (missing) {
              h.levels.forEach((l) => {
                ret["level" + l.index] = null;
              });
            }
          }
          return ret;
        }),
		action: this.$loginState.user.createDocumentDialogOptions.find(a => a.tmpl_name === this.docType)
      };
      return data;
    },
    setHierarchies(hierarchies) {
      hierarchies.forEach((n) => {
        let doneLevels = false;
        n.selectNew = false;
        n.showNewPrompt = false;
        n.searchText = "";
        n.items = [];
        n.levels = [];
        n.values.forEach((v) => {
          let ignore = false;
          let i = 1;
          let parent = n.items;
          while (i <= n.linklevel) {
            let val = v[`level${i}`];
            if (n[`use_h${i}`]) {
              if (!val) ignore = true;
              if (!ignore) {
                let item = parent.find((s) => s.text === val);
                if (!item) {
                  item = { text: val, level: i, items: [] };
                  parent.push(item);
                }
                parent = item.items;
              }
              if (!doneLevels) {
                n.levels.push({ index: i, selected: null, items: [] });
              }
            }
            i++;
          }
          doneLevels = true;
        });
        n.levels[0].items = n.items;
      });
      return hierarchies;
    },
    classifyHierarchy(hierarchy, level) {
      if (level.index === hierarchy.levels[hierarchy.levels.length - 1].index) {
        return;
      }
      let penultimateIndex =
        hierarchy.levels[hierarchy.levels.length - 1].index;
      let doneN = false;
      hierarchy.levels.forEach((l) => {
        if (l.index > level.index) {
          l.items.splice(0);
          if (!doneN) {
            l.items.push(...level.selected.items);
            if (l.index === penultimateIndex) {
              let newItem = {
                text: hierarchy.hierarchy_text,
                level: penultimateIndex,
                items: [],
              };
              l.items.push(newItem);
              hierarchy.levels[hierarchy.levels.length - 1].selected = newItem;
            }

            doneN = true;
          }
        }
      });
    },
    pickValue(hrchy, isnew) {
      let ctl = this.$refs["hcb" + this.focusHTID][0],
        h = this.hierarchies[this.focusHTID];
      ctl.isMenuActive = false;
      if (
        this.canAddHierarchy !== 0 &&
        (isnew || !ctl.filteredItems.length) &&
        hrchy.searchText
      ) {
        h.hierarchy_text = hrchy.searchText;
        if (this.canAddHierarchy === 1) {
          hrchy.selectNew = true;
        } else {
          hrchy.values.push({ text: hrchy.searchText });
        }
      } else {
        ctl.lazySearch = h.hierarchy_text;
      }
      hrchy.showNewPrompt = false;
    },
    searchText($event, hrchy) {
      this.isCreateAllowed();
      let ctl = this.$refs["hcb" + this.focusHTID][0];
      if ([13, 37, 38, 39, 40].indexOf($event.keyCode) >= 0) return;
      if (ctl.lazySearch) {
        if ($event.keyCode !== 13) hrchy.searchText = ctl.lazySearch.trim();
        let match = ctl.filteredItems.find(
          (f) => f.text.toLowerCase() === ctl.lazySearch.toLowerCase()
        );
        if (match) {
          ctl.selectItem(match);
          hrchy.hierarchy_text = match.text;
          hrchy.hr_id = match.hr_id;
          hrchy.showNewPrompt = false;
        } else {
          hrchy.showNewPrompt =
            ctl.filteredItems.length &&
            (!hrchy.hierarchy_text ||
              hrchy.hierarchy_text.toLowerCase() !==
                hrchy.searchText.toLowerCase());
        }
      } else {
        hrchy.hierarchy_text = "";
        hrchy.showNewPrompt = false;
      }
    },
    cancelNew(h) {
      h.selectNew = false;
      h.searchText = "";
      h.showNewPrompt = false;
      this.creationDisabled = true;
    },
  },
};
</script>
<style scoped>
.buttons {
  margin-top: 35px;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.dragHandle {
  color: blue;
  cursor: grab;
  /* margin-left: 30%; */
}
</style>
