<template>
  <div v-if="action">
    <v-card v-if="isBusy" style="min-height: 500px" class="pa-5">
      <v-toolbar fixed dense flat color="transparent">
        <v-toolbar-title>Document Requisitions</v-toolbar-title>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="5"></v-col>
          <v-col>
            <v-progress-circular indeterminate size="50" color="grey">
            </v-progress-circular>
          </v-col>
          <v-col cols="5"></v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div v-else>
      <v-card
        v-if="action.definition"
        :class="{ 'pa-5': viewType === 'dialogue' }"
        ><!-- style="min-height: 500px"  -->
        <v-card-title @click.prevent="toggleHide" class="d-flex align-start pb-0" style="cursor: pointer">
          <b class="mt-1">{{action.definition.input_label || action.name}}</b>
          <v-spacer></v-spacer>
          <v-icon v-if="action.dataCaptureEditAction" @click="triggerEdit">
            mdi-square-edit-outline</v-icon
          >
          <v-icon v-if="action.isDataCaptureViewer && viewType === 'inline'"
            >{{
              action.isDataCaptureHidden ? `mdi-eye` : "mdi-eye-off"
            }}
            mdi-eye</v-icon
          >
          <template v-else-if="!action.isDataCaptureViewer">
            <v-form
              ref="form"
              v-model="valid"
              class="d-flex"
            >
              <v-text-field
                v-model="newReqId"
                dense
                style="maxwidth: 200px"
                class="roundish pl-5 pr-2"
                outlined
                clearable
                :label="action.definition.sub_method || action.data_capture_sub_method"
                :rules="validationRules"
                @keydown.enter="addReq"
              ></v-text-field>
              <v-btn @click="addReq" :disabled="!newReqId || !newReqId.length || !valid"
                >Add</v-btn
              >
            </v-form>
          </template>
          <template v-if="viewType === 'dialogue'">
            <v-spacer></v-spacer>
            <v-btn
              large
              :disabled="!UIstate.canCancel"
              icon
              class="btn-background ml-2"
              @click="cancel"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="!action.isDataCaptureViewer">
          <v-row
            dense
            v-if="
              (viewType === 'inline' || viewType === 'contained') &&
              UIstate.errorMessage
            "
          >
            <v-col cols="12">
              <v-alert dense type="warning">
                {{ UIstate.errorMessage }}</v-alert
              ></v-col
            ></v-row
          >
          <v-row v-if="!addNewOnly && definition.requisitions.length" dense>
            <v-col>
              <v-card>
                <v-list two-line dense inactive>
                  <template v-for="(r, ri) in definition.requisitions">
                    <v-divider :key="'rd' + ri"></v-divider>
                    <v-list-item :key="'rv' + ri" dense>
                      <template>
                        <v-list-item-icon>
                          <v-icon
                          >{{ r.isDeleted
                                ? 'mdi-receipt-text-minus'
                                : r.isNew
                                ? 'mdi-receipt-text-plus'
                                : 'mdi-receipt-text' }}</v-icon
                        ></v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{ r.requisition_number }}</v-list-item-title>
                          <v-list-item-subtitle>{{ r.isNew
                                ? '[Unsaved requisition]'
                                : 'Added ' +
                                  r.created_date +
                                  ' by ' +
                                  r.created_by }}</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action v-if="UIstate.canDelete">
                          <v-list-item-action-text
                            v-if="r.isDeleted"
                            color="red"
                            >marked for deletion</v-list-item-action-text
                          >
                          <v-icon
                            :disabled="!UIstate.canDelete"
                            v-if="!r.isDeleted"
                            :color="
                              UIstate.canDelete
                                ? 'grey lighten-1'
                                : 'grey lighten-4'
                            "
                            @click="toggleReq(r)"
                          >
                            mdi-delete
                          </v-icon>
                          <v-icon
                            v-else
                            color="red lighten-1"
                            @click="toggleReq(r)"
                          >
                            mdi-delete-off
                          </v-icon>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </template>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense v-if="viewType === 'dialogue'">
            <v-col cols="8">
              <v-alert v-if="UIstate.errorMessage" dense type="warning">
                {{ UIstate.errorMessage }}</v-alert
              ></v-col
            >
            <v-col class="text-right">
              <v-btn class="mr-2" @click="cancel" :disabled="!UIstate.canCancel" color="primary" outlined
                >Cancel</v-btn
              >
              <v-btn @click="save(true)" v-if="UIstate.canContinue" color="primary"
                >Continue</v-btn
              >
              <v-btn @click="save" v-else-if="!addNewOnly" :disabled="!UIstate.canSave" color="primary"
                >Save Changes</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text
          v-else-if="action.isDataCaptureViewer && !action.isDataCaptureHidden"
        >
          <v-row v-if="definition.requisitions.length" dense>
            <v-col>
              <v-card>
                <!-- <v-card-subtitle
                  >Requisitions linked to this document:</v-card-subtitle
                > -->
                <v-list two-line dense>
                  <template v-for="r in definition.requisitions">
                    <v-divider :key="'rd' + r.requisition_number"></v-divider>
                    <v-list-item :key="'rv' + r.requisition_number" dense>
                      <template>
                        <v-list-item-icon>
                          <v-icon>mdi-receipt-text</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{ r.requisition_number }}</v-list-item-title>
                          <v-list-item-subtitle>{{ 'Added ' + r.created_date + ' by ' + r.created_by }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <!-- <v-list-item-action>
                            <v-icon
                              color="grey lighten-1"
                              @click="toggleReq(r)"
                            >
                              mdi-edit
                            </v-icon>
                          </v-list-item-action> -->
                      </template>
                    </v-list-item>
                  </template>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
// import axios from "axios";

export default {
  name: "DataCaptureRequisitions",
  props: {
    action: Object,
    isBusy: Boolean,
    editState: Object,
  },
  data: function () {
    return {
      newReqId: null,
      definition: null,
      hasSavedReqs: false,
      savedReqs: 0,
      viewType: "", // inline, dialogue, contained
      addNewOnly: false,
      UIstate: {
        canSave: false,
        canContinue: false,
        canCancel: false,
        canDelete: true,
        errorMessage: "",
      },
      valid: false
    };
  },
  computed: {
    validationRules() {
      const rules = [];
      if (this.action && this.action.definition.format_regex) {
        const regex = new RegExp(this.action.definition.format_regex);
        rules.push(v => !v || regex.test(v) || this.action.definition.format_helptext || "Invalid format");
      }
      return rules;
    }
  },
  created() {
    this.init();
  },
  watch: {
    action(newval) {
      if (newval) {
        this.init();
      }
    },
    "editState.doSave"(newval) {
      if (newval) {
        this.save(true);
      }
    },
  },
  methods: {
    init() {
      //   let def = { ...this.action.definition };
      let def = JSON.parse(JSON.stringify(this.action.definition));
      def.requisitions.forEach((x) => {
        x.isNew = false;
        x.isDeleted = false;
      });
      this.savedReqs = def.requisitions.length;
      this.hasSavedReqs = !!def.requisitions.length;
      this.definition = def;
      this.addNewOnly = this.action.data_capture_method === "requisition_new";
      this.newReqId = null;
      this.viewType = this.action.isDataCaptureEditInHeader
        ? "contained"
        : this.action.isDataCaptureEditInPage
        ? "inline"
        : "dialogue";
      this.setUIstate();
    },
    addReq() {
      if (!this.$refs.form || !this.$refs.form.validate() || !this.newReqId)
        return;

      this.definition.requisitions.push({
        requisition_id: null,
        requisition_number: this.newReqId,
        isNew: true,
        isDeleted: false,
      });
      this.newReqId = null;
      if (this.addNewOnly) {
        this.save(true);
      } else if (this.viewType === "inline") {
        this.save();
      } else {
        this.setUIstate();
      }
    },
    toggleReq(req) {
      req.isDeleted = !req.isDeleted;
      if (req.isDeleted && req.isNew) {
        this.definition.requisitions.splice(
          this.definition.requisitions.findIndex(
            (r) =>
              r.requisition_number === req.requisition_number &&
              r.isNew &&
              r.isDeleted
          ),
          1
        );
      }
      if (this.viewType === "inline") {
        this.save();
      } else {
        this.setUIstate();
      }
    },
    setUIstate() {
      let hasReqs = false;
      let message = "";
      if (this.viewType === "inline") {
        hasReqs = this.hasSavedReqs;
        this.UIstate.canDelete = this.savedReqs > 1;
        message =
          this.action.data_capture_mandatory && !this.hasSavedReqs
            ? "This document must have a requisition number"
            : "";
      } else {
        let isDirty = this.definition.requisitions.some(
          (x) => x.isDeleted || x.isNew
        );
        hasReqs = this.definition.requisitions.some(
          (x) => !x.isDeleted || x.isNew
        );
        let isValid = (!this.action.data_capture_required && !this.action.data_capture_mandatory) || hasReqs;
        this.UIstate.canSave = isDirty && isValid;
        this.UIstate.canCancel = true;
        this.UIstate.canContinue = !isDirty && isValid;
        //   !this.action.data_capture_mandatory || this.hasSavedReqs;
        this.UIstate.canDelete = true;

        if (this.editState) {
          this.$emit("updateState", {
            isDirty: isDirty,
            canSave: this.UIstate.canSave,
            canCancel: this.UIstate.canCancel,
            isValid: isValid
          })
        }
        message =
          this.action.data_capture_mandatory && !hasReqs
            ? "This document must have a requisition number"
            : "";
        if (message && this.addNewOnly) {
          message = "You must add a Requisition number";
        }
      }
      this.UIstate.errorMessage = message;
    },
    save(forceSave) {
      if (forceSave && forceSave === true) {
        this.$emit("save", this.definition, this.action);
      } else {
        switch (this.viewType) {
          case "inline":
            this.$emit("save", this.definition, this.action);
            break;
          case "contained":
            break;
          case "dialogue":
            this.$emit("save", this.definition, this.action);
            break;
        }
      }
      this.setUIstate();
    },
    cancel() {
      this.$emit("cancel");
    },
    toggleHide() {
      this.$emit("toggleHidden");
    },
    triggerEdit() {
      this.$emit("requestEdit", this.action.dataCaptureEditAction);
    },
  },
};
</script>
<style scoped lang="scss">
</style>