<template>
  <draggable
    tag="tbody"
    :list="item[property]"
    group="tmplpart"
    handle=".rowActions"
    :emptyInsertThreshold="20"
    @add="$emit('add', $event)"
  >
    <tr
      v-for="row in item[property]"
      :key="row.tmpl_part_id"
      @mouseover="row.hover = true"
      @mouseout="row.hover = false"
      :style="getTableCss(row.css_styles)"
      :class="[row.ignore_alternating_style ? 'ignoreAlt' : '']"
      :align="row.align"
    >
      <td width="30px" class="rowActions">
        <v-icon
          v-if="item[property].length > 1"
          style="cursor: move"
          :style="`${row.hover ? '' : 'visibility: hidden'}`"
          x-small
          >mdi-drag-variant</v-icon
        >
      </td>
      <td style="width: 100%; border: 1px solid">
        <table width="100%">
          <tr>
            <td
              scope="row"
              v-for="(cell, cIdx) in row.cells"
              :key="cIdx"
              :colspan="cell.col_span"
              :class="cell.selected ? 'selected' : ''"
              :rowspan="cell.row_span"
              :style="{ width: 100 / row.cells.length + '%', borderLeft: cIdx > 0 ? '1px solid' : 'none' }"
              style="border-top: none; border-bottom: none; border-right: none;"
            >
              <div :style="cell.style">{{ cell.label }}</div>
            </td>
          </tr>
          <tr v-if="row.childRows?.length">
            <!-- <td class="rowActions"></td> -->
            <td :colspan="item[property][0].cells.length" style="border: none;">
              <table width="100%">
                <TemplateExportFormatRow
                  :rows="row.childRows"
                  :item="row"
                  property="childRows"
                  @add="$emit('add', $event)"
                  @setEdit="$emit('setEdit', $event)"
                ></TemplateExportFormatRow>
              </table>
            </td>
          </tr>
        </table>
      </td>
      <td width="30px" class="rowEndActions">
        <v-icon
          @click="$emit('setEdit', row)"
          :style="`${row.hover ? '' : 'visibility: hidden'}`"
          x-small
          >mdi-cog</v-icon
        >
      </td>
    </tr>
  </draggable>
</template>
    
    
<script>
import draggable from "vuedraggable";

export default {
  name: "TemplateExportFormatRow",
  components: { draggable },
  props: {
    item: Object,
    property: String,
    // rows: [],
  },
  data: function () {
    return {};
  },
  mounted() {},
  created() {
    // this.init();
  },
  watch: {
    // value() {
    //     this.init();
    // },
  },
  computed: {},
  methods: {
    getTableCss(css_styles) {
      if (!css_styles) return;
      let returnObj = {};
      returnObj["--table-id"] = 0;
      css_styles.forEach((c) => {
        returnObj[c.key] = c.value;
      });
      return returnObj;
    },
  },
};
</script>
<style scoped lang="scss">
.dragHandle {
  font-size: 12px !important;
  display: inline-flex;
  padding-right: 15px;
  cursor: move;
  align-items: flex-start !important;
  border-right: 1px dotted #387fe9;
  height: auto;
  padding-top: 10px;
  padding-left: 15px;
  width: 35px;
}
</style>
    