<template>
  <div class="taglist">
    <v-chip v-for="(t, ti) in tags" :key="ti">
      {{ t }}
      <v-icon v-if="!readOnly" right @click="removeTag(ti)">mdi-close</v-icon>
    </v-chip>
    <v-text-field dense ref="newTagInput"
      v-if="newTag !== null && (lookup === null || lookup.length === 0)"
      autofocus
      hide-details
      
      v-model="newTag"
      append-icon="mdi-check"
      @click:append="addTag"
      @blur="addTag"
      @keypress.esc="newTag = null"
      @keypress.enter="addTag"
    >
    </v-text-field>
    <v-autocomplete dense ref="newTagSelect"
      v-if="newTag !== null && lookup && lookup.length"
      autofocus
      hide-details
      
      v-model="newTag"
      :items="lookup"
      item-text="varVal"
      item-value="varVal"
      @blur="addTag"
      @change="addTag"
      @keypress.esc="newTag = null"
      @keypress.enter="addTag"
    >
    </v-autocomplete>
    <v-btn icon outlined v-if="!readOnly && newTag === null" @click="showAddTag">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "DocumentPartTagList",
  components: {},
  props: {
    value: String,
    restrict_to_lookup: Boolean,
    tmpl_part_id: Number,
    attr_id: Number,
    dp_id: Number,
    readOnly: Boolean,
  },
  data: function () {
    return {
      tags: [],
      lookup: null,
      newTag: null,
    };
  },
  created() {
    if (this.value)
      this.tags = this.value.split(',').map(t => t.trim()).filter(t => t);
  },
  updated() {},
  computed: {},
  watch: {},
  methods: {
    removeTag(index) {
      this.tags.splice(index, 1);
      this.save();
    },
    showAddTag() {
      if (!this.lookup && (this.restrict_to_lookup || this.tags.length == 0)) {
        axios
          .get(
            "document/getTemplatePartValues/" +
              `${this.tmpl_part_id}_${this.attr_id ? this.attr_id : ""}_${this.dp_id ? this.dp_id : ""}`
          )
          .then((resp) => {
            this.lookup = resp.data.Data;
            this.newTag = '';
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              console.log(err);
              this.response = err.response
                ? err.response.data
                : { message: "Unexpected Error" };
            }
          });
      } else {
        this.newTag = '';
      }
    },
    addTag() {
      if (this.newTag) {
        this.tags.push(this.newTag);
        this.save();
      }
      this.newTag = null;
    },
    save() {
      const newValue = this.tags.join(", ");
      this.$emit("changed", { text: newValue });
    }
  },
};
</script>
<style lang="scss" scoped>
.taglist {
  .v-chip {
    border-radius: 8px;
    padding: 3px 16px 0 16px;
    margin-right: 10px;
    height: 32px;
    .v-icon {
      font-size: 22px;
      margin-top: -3px;
    }
  }
  .v-chip.theme--light {
    background-color: #edf0f4 !important;
  }
  .v-text-field {
    width: auto;
    max-width: unset;
    display: inline-flex;
    margin-right: 10px;
  }
  .v-btn.theme--light {
    height: 32px;
    width: 32px;
    border-radius: 8px;
    border-color: #dde2e9; 
  }
}
</style>
