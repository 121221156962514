<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h1 class="title">Classifier Rules</h1>
      </v-col>
      <v-col cols="3" class="d-flex justify-end">
        <v-select
          v-if="this.docClassifiers.length > 1"
          dense
          outlined
          return-object
          v-model="classifierType"
          :items="docClassifiers"
          item-value="ct_id"
          item-text="name">
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
        :headers="dtHeaders"
        :items="docClassifierRules"
        sort-by="specificity"
        sort-desc
        :class="{ 'elevation-1': true }"
        :items-per-page="pageSize"
        :page.sync="currentPage"
        hide-default-footer
      >
        <template v-slot:[`item.compliantDocs`]="{ item }">
          <div class="d-flex justify-center align-center">
            {{ item.compliantDocs.length }}
          </div>
        </template>
        <template v-slot:[`item.nonCompliantDocs`]="{ item }">
          <div class="d-flex justify-center align-center">
            {{ item.nonCompliantDocs.length }}
            <v-btn icon v-if="item.nonCompliantDocs.length" @click="confirmDialog.open = true; confirmDialog.rule = item;"><v-icon>published_with_changes</v-icon></v-btn>
          </div>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <div class="d-flex justify-end">
            <v-btn small outlined color="red" class="mx-1" @click="deleteRule(item)">
              Delete
            </v-btn>
            <v-btn small outlined color="primary" class="mx-1" @click="editRule(item)">
              Edit
            </v-btn>
          </div>
        </template>
        <template v-slot:footer>
          <div class="pagination">
            <PageDescription
              :totalItems="docClassifierRules.length"
              :pageSize="pageSize"
              :currentPage="currentPage"
            />
            <Pagination
              :totalItems="docClassifierRules.length"
              :pageSize="pageSize"
              :currentPage="currentPage"
              @pageNavigation="currentPage = $event"
            />
            <div>
              <v-btn
                color="primary"
                small
                outlined
                class="mx-2"
                @click="addRule"
              >
                <span>Add New</span>
              </v-btn>
            </div>
          </div>
        </template>
      </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="editingItem.open" persistent max-width="950px">
      <v-card class="pa-2">
        <v-toolbar flat>
          <v-toolbar-title>
            {{ editingItem.dcr_id ? "Edit" : "Add New" }} Rule
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="editingItem.open = false" class="btn-background">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-form class="newRuleForm" ref="editForm" lazy-validation v-model="editingItem.valid">
            <v-row>
              <v-col cols="12" v-for="(h, hi) in hierarchies" :key="h.ht_id">
                <v-autocomplete
                  dense
                  outlined
                  clearable
                  auto-select-first
                  return-object
                  :ref="'hcb' + hi"
                  :items="h.values"
                  item-value="text"
                  item-text="text"
                  :label="h.label"
                  v-model="editingItem.selectedHrys[hi]"
                  @focus="focusHTID = hi"
                  @keyup="searchText($event, h)"
                  :filter="utils.comboFilterPicker"
                  hide-details
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-content style="max-width: 800px;">
                      <v-list-item-title>
                        {{ item.text }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <template v-slot:prepend-item>
                    <div
                      v-if="h.showNewPrompt"
                      style="padding: 0px 0px 10px 10px; font-size: 14px"
                    >
                      <div>
                        Press <kbd>Enter</kbd> to pick the highlighted item
                      </div>
                    </div>
                  </template>
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ h.not_in_list_message }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col>
                <v-select
                  outlined
                  label="Value"
                  dense
                  :items="classifierValues"
                  item-text="value"
                  item-value="cv_id"
                  v-model="editingItem.cv_id"
                  hide-details
                  :rules="[v => !!v || 'Value is required']"
                  required></v-select>
              </v-col>
              <v-col>
                <v-text-field
                  outlined
                  dense
                  label="Justification"
                  v-model="editingItem.comments"
                  hide-details></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text outlined @click="editingItem.open = false"
            >Cancel</v-btn
          >
          <v-btn
            v-if="editingItem.dcr_id"
            color="red"
            text
            outlined
            @click="deleteRule"
            >Delete Rule</v-btn
          >
          <v-btn
            v-if="editingItem.dcr_id"
            color="primary"
            text
            outlined
            @click="updateRule"
            >Update Rule</v-btn
          >
          <v-btn
            v-if="!editingItem.dcr_id"
            color="primary"
            text
            outlined
            @click="createRule"
            >Add Rule</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmDialog.open" persistent max-width="500px">
      <v-card class="pa-2">
        <v-toolbar flat>
          <v-toolbar-title>
            Confirm
          </v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          Update <b>{{ confirmDialog.rule ? confirmDialog.rule.nonCompliantDocs.length : 0 }}</b> documents and classify as <b>{{ confirmDialog.rule ? confirmDialog.rule.value : "" }}</b>?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text outlined @click="confirmDialog.open = false"
            >Cancel</v-btn
          >
          <v-btn
            color="primary"
            text
            outlined
            @click="updateDocClassification"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Loading :isVisible="!stateLoaded || isLoading" />
    <ResponseHandler :serviceResponse="response"></ResponseHandler>
  </v-container>
</template>

<script>
import axios from "axios";
import Pagination from "@/components/cPagination";
import PageDescription from "@/components/cPageDescription";
import Loading from "@/components/cLoading";
import ResponseHandler from "@/components/ResponseHandler";
import utils from "@/common/utils.js";
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "AdminDocClassifierRules",
  components: {
    Pagination,
    PageDescription,
    Loading,
    ResponseHandler,
  },
  data: function () {
    return {
      utils: utils,
      isLoading: false,
      dataLoaded: false,
      response: null,
      altsearch: "",
      errorText: "",
      classifierType: null,
      docClassifierRules: [],
      pageSize: 20,
      currentPage: 1,
      confirmDialog: {
        open: false,
        rule: null
      },
      editingItem: {
        open: false,
        dcr_id: 0,
        cv_id: "",
        comments: "",
        selectedHrys: [],
        valid: false
      },
      focusHTID: null
    };
  },
  computed: {
    ...mapState({
      stateLoaded: (state) => !state.hierarchies.loading && !state.docs.loading,
      hierarchyTypes: (state) => state.docs.hierarchyTypes,
      docStatusSummary: (state) => state.docs.docStatusSummary,
      hierarchies: (state) => state.hierarchies.hierarchies,
      docClassifiers: (state) => state.hierarchies.docClassifiers,
    }),
    dtHeaders() {
      const cols = [];
      this.hierarchyTypes.forEach(h => {
        cols.push({ text: h.label, value: `h_${h.ht_id}` });
      });
      cols.push( { text: "Value", value: "value" },
        { text: "Justification", value: "comments" },
        //{ text: "Specificity", value: "specificity" },
        { text: "Compliant Docs", value: "compliantDocs" },
        { text: "Non Compliant Docs", value: "nonCompliantDocs" },
        { text: "Created By", value: "created_by" },
        { text: "Created Date", value: "created_date", sort: (a, b) =>
            moment(a, "D MMM YYYY").isAfter(moment(b, "D MMM YYYY"))
              ? 1
              : moment(a, "D MMM YYYY").isBefore(moment(b, "D MMM YYYY"))
              ? -1
              : 0, },
        { text: "", value: "action" });
      return cols;
    },
    classifierValues() {
      return this.classifierType?.values;
    }
  },
  props: {},
  mounted() {},
  created() {
    if (this.stateLoaded)
      this.loadData();
  },
  watch: {
    stateLoaded(val) {
      if (val)
        this.loadData();
    }
  },
  methods: {
    loadData() {
      this.isLoading = true;
      if (this.docClassifiers.length !== 0) {
        this.classifierType = this.docClassifiers[0];
        axios
          .get("admin/docClassifierLoadRules/")
          .then((resp) => {
            if (resp.data.Status === "OK") {
              this.docClassifierRules = resp.data.Data.map(dcr => this.prepareRow(dcr));
            }
            this.classifyDocs();
            this.isLoading = false;
            this.dataLoaded = true;
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              alert(err.response ? err.response.data.message : err);
            }
            console.log(err);
            this.isLoading = false;
          });
      }
    },
    prepareRow(dcr) {
      let rowSpecificity = 0;
      this.hierarchyTypes.forEach(ht => {
        const criteria = dcr.criteria.find(c => c.ht_id === ht.ht_id);
        let description = "<ANY>";
        let specificity = 0;
        if (criteria) {
          if (criteria.h_level6) {
            description = criteria.h_level6;
            specificity = 6;
          } else if (criteria.h_level5) {
            description = criteria.h_level5;
            specificity = 5;
          } else if (criteria.h_level4) {
            description = criteria.h_level4;
            specificity = 4;
          } else if (criteria.h_level3) {
            description = criteria.h_level3;
            specificity = 3;
          } else if (criteria.h_level2) {
            description = criteria.h_level2;
            specificity = 2;
          } else if (criteria.h_level1) {
            description = criteria.h_level1;
            specificity = 1;
          }

          if (criteria.description)
            description = criteria.description;
        }
        dcr[`h_${ht.ht_id}`] = description;
        rowSpecificity += specificity;
      });
      dcr.specificity = rowSpecificity;
      dcr.compliantDocs = [];
      dcr.nonCompliantDocs = [];
      return dcr;
    },
    classifyDocs() {
      const filters = { docStatusIncluded: this.docStatusSummary.filter(s => s.status !== "Deleted" && s.status !== "Archived") };
      const docs = this.$store.getters["docs/filter"](filters);
      const rules = this.docClassifierRules.sort((a, b) => {
        if (a.specificity === b.specificity)
          return (a.dcr_id > b.dcr_id) ? 1 : -1;
        else
          return (a.specificity < b.specificity) ? 1 : -1;
      });
      rules.forEach(dcr => {
        dcr.compliantDocs.splice(0);
        dcr.nonCompliantDocs.splice(0);
      });

      docs.forEach(d => {
        rules.some((dcr) => {
          let allCriteriaMet = true;
          dcr.criteria.forEach(dcc => {
            if (allCriteriaMet) {
              const sel = d.hierarchies.find(h => h.ht_id === dcc.ht_id) || {};
              const htype = this.hierarchyTypes.find(ht => ht.ht_id === dcc.ht_id) || {};
              let used = 0;
              for (let i = 6; i >= 1; i--) {
                if (htype["use_h" + i] === 1) {
                  used++;
                  if (dcc["h_level" + i] && sel["hierarchy" + used] !== dcc["h_level" + i])
                    allCriteriaMet = false;
                }
              }
            }
          });
          if (allCriteriaMet) {
            const currentClassifier = d.classifiers.find(dc => dc.ct_id === dcr.ct_id);
            if (currentClassifier && currentClassifier.cv_id === dcr.cv_id) {
              dcr.compliantDocs.push(d.doc_id);
            } else {
              dcr.nonCompliantDocs.push(d.doc_id);
            }
          }
          return allCriteriaMet;
        });
      });
    },
    addRule() {
      this.editingItem.dcr_id = 0
      this.editingItem.value = "";
      this.editingItem.cv_id = null;
      this.editingItem.comments = "";
      this.editingItem.selectedHrys.splice(0);
      this.editingItem.open = true;
      if (this.$refs.editForm)
        this.$refs.editForm.resetValidation();
    },
    editRule(rule) {
      this.editingItem.dcr_id = rule.dcr_id;
      this.editingItem.value = rule.value;
      this.editingItem.cv_id = rule.cv_id;
      this.editingItem.comments = rule.comments;
      this.editingItem.selectedHrys.splice(0);
      rule.criteria.forEach(c => {
        const hry = this.hierarchies.find(h => h.ht_id === c.ht_id);
        const hryVal = hry.values.find(v =>
          (!c.h_level1 || c.h_level1.toLowerCase() == v.level1?.toLowerCase())
          && (!c.h_level2 || c.h_level2.toLowerCase() == v.level2?.toLowerCase())
          && (!c.h_level3 || c.h_level3.toLowerCase() == v.level3?.toLowerCase())
          && (!c.h_level4 || c.h_level4.toLowerCase() == v.level4?.toLowerCase())
          && (!c.h_level5 || c.h_level5.toLowerCase() == v.level5?.toLowerCase())
          && (!c.h_level6 || c.h_level6.toLowerCase() == v.level6?.toLowerCase()));
        if (hryVal) {
          this.editingItem.selectedHrys[this.hierarchies.indexOf(hry)] = hryVal;
        }
      });
      this.editingItem.open = true;
      if (this.$refs.editForm)
        this.$refs.editForm.resetValidation();
    },
    buildModel() {
      const item = this.editingItem;
      return {
        dcr_id: item.dcr_id,
        ct_id: this.classifierType.ct_id,
        cv_id: item.cv_id,
        comments: item.comments,
        criteria: this.hierarchies.map((h, hi) => {
          const sel = item.selectedHrys[hi];
          const model = {
            ht_id: h.ht_id
          }
          if (sel) {
            model.description = sel.text;
            const htype = this.hierarchyTypes.find(ht => ht.ht_id === h.ht_id);
            for (let i = 6; i >= 1; i--) {
              if (htype["use_h" + i] === 1) {
                model["h_level" + i] = sel["level" + i];
              }
            }
          }
          return model;
        })
      };
    },
    createRule() {
      if (this.$refs.editForm.validate()) {
        this.isLoading = true;
        axios
          .post("admin/docClassifierCreateRule", this.buildModel())
          .then((resp) => {
            if (resp.data.Status === "OK") {
              this.docClassifierRules.push(this.prepareRow(resp.data.Data));
              this.editingItem.open = false;
              this.$store.dispatch("hierarchies/updateClassificationRules", JSON.parse(JSON.stringify(this.docClassifierRules)));
              this.classifyDocs();
            }
            this.isLoading = false;
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              alert(err.response ? err.response.data.message : err);
            }
            console.log(err);
            this.isLoading = false;
          });
      }
    },
    updateRule() {
      if (this.$refs.editForm.validate()) {
        this.isLoading = true;
        axios
          .post("admin/docClassifierUpdateRule", this.buildModel())
          .then((resp) => {
            if (resp.data.Status === "OK") {
              const persistedIndex = this.docClassifierRules.findIndex(dcr => dcr.dcr_id === this.editingItem.dcr_id);
              this.docClassifierRules.splice(persistedIndex, 1, this.prepareRow(resp.data.Data));
              this.editingItem.open = false;
              this.$store.dispatch("hierarchies/updateClassificationRules", JSON.parse(JSON.stringify(this.docClassifierRules)));
              this.classifyDocs();
            }
            this.isLoading = false;
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              alert(err.response ? err.response.data.message : err);
            }
            console.log(err);
            this.isLoading = false;
          });
      }
    },
    deleteRule(rule) {
      this.isLoading = true;
      axios
        .post("admin/docClassifierDeleteRule", { dcr_id: rule.dcr_id })
        .then((resp) => {
          if (resp.data.Status === "OK") {
            const persistedIndex = this.docClassifierRules.findIndex(dcr => dcr.dcr_id === this.editingItem.dcr_id);
            this.docClassifierRules.splice(persistedIndex, 1);
            this.$store.dispatch("hierarchies/updateClassificationRules", JSON.parse(JSON.stringify(this.docClassifierRules)));
            this.classifyDocs();
          }
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
          this.isLoading = false;
        });
    },
    updateDocClassification() {
      const rule = this.confirmDialog.rule;
      this.isLoading = true;
      axios
        .post("admin/docClassifierUpdateDocs", {
          ct_id: this.classifierType.ct_id,
          cv_id: rule.cv_id,
          docIds: rule.nonCompliantDocs
        }).then((resp) => {
          if (resp.data.Status === "OK") {
            this.$store.dispatch("docs/updateDocClassifiers", {
              docIds: rule.nonCompliantDocs,
              classifier: {
                ct_id: this.classifierType.ct_id,
                ct_name: this.classifierType.name,
                cv_id: rule.cv_id,
                value: rule.value
              }
            });
            this.classifyDocs();
            this.confirmDialog.open = false;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
          this.isLoading = false;
        });
    },
    searchText($event, hrchy) {
      let ctl = this.$refs["hcb" + this.focusHTID][0];
      if ([13, 37, 38, 39, 40].indexOf($event.keyCode) >= 0) return;
      if (ctl.lazySearch) {
        if ($event.keyCode !== 13) hrchy.searchText = ctl.lazySearch.trim();
        let match = ctl.filteredItems.find(
          (f) => f.text.toLowerCase() === ctl.lazySearch.toLowerCase()
        );
        if (match) {
          ctl.selectItem(match);
          hrchy.hierarchy_text = match.text;
          hrchy.hr_id = match.hr_id;
          hrchy.showNewPrompt = false;
        } else {
          hrchy.showNewPrompt =
            ctl.filteredItems.length &&
            (!hrchy.hierarchy_text ||
              hrchy.hierarchy_text.toLowerCase() !==
                hrchy.searchText.toLowerCase());
        }
      } else {
        hrchy.hierarchy_text = "";
        hrchy.showNewPrompt = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.v-toolbar {
  border: 0 !important;
}
::v-deep .v-data-table {
  .pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.875rem;
    padding: 10px 15px;
  }
}
</style>