<template>
  <div class="page">
    <div style="display: none">
      <!-- Sacrificial fields to remove annoying Chrome habit of auto-filling fields -->
      <input type="text" />
      <input type="password" />
    </div>
    <v-container>
      <!-- <v-icon class="logout" text @click="logout()">mdi-logout</v-icon> -->

      <v-row dense>
        <v-col cols="4" class="results">
          <v-row>
            <v-col cols="2">
              <img
                style="margin-top: -10px; width: 140px"
                src="../assets/static/roleRecruitLogo.png"
              />
            </v-col>
            <v-col cols="10">
              <v-text-field
                class="searchBox"
                autofocus
                color="#005073"
                placeholder="Enter text to search ..."
                outlined
                @input="changefilterText"
                @keyup="handleKeyUp"
              ></v-text-field>
            </v-col>
            <v-col cols="1" style="padding-top: 0"></v-col>
            <v-col cols="11" style="padding-top: 15px">
              <v-radio-group
                @change="doFilter"
                v-model="myJobs"
                class="myJobs"
                row
              >
                <v-radio label="All" value="all" color="#005073"></v-radio>
                <v-radio label="My Jobs" value="my" color="#005073"></v-radio>
                <v-radio
                  label="My Team Jobs"
                  value="team"
                  color="#005073"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <!-- <v-col cols="3" class="pt-0">
              <v-btn
                title="Create New Document"
                @click="addDocument"
                color="primary"
                class="createBtn whiteBack"
                outlined
              >
                Create New
              </v-btn>
            </v-col> -->
          </v-row>
          <v-row
            wrap
            v-for="item in filteredItems"
            :key="item.index"
            class="result whiteBack"
            :class="{
              selResult:
                item.system_number === selectedDocumentId && item.system_number,
            }"
          >
            <v-col
              cols="7"
              v-if="item.advert_job_title || '' === item.doc_type || ''"
              class="result_header"
              @click="openDocument(item)"
            ><div v-html="item.doc_name"></div></v-col>
            <v-col
              cols="7"
              v-else
              class="result_header"
              @click="openDocument(item)"
            ><div v-html="item.advert_job_title"></div></v-col>
            <v-col
              cols="5"
              v-if="item.listview2"
              class="result_client"
              @click="openDocument(item)"
            ><div v-html="item.listview2"></div></v-col>
            <v-col
              cols="5"
              v-else
              class="result_client"
              @click="openDocument(item)"
            ><div v-html="item.listview3"></div></v-col>
            <v-col
              cols="12"
              class="result_desc"
              @click="openDocument(item)"
            ><div v-html="item.description"></div></v-col>
          </v-row>
        </v-col>
        <v-col cols="8">
          <v-card>
            <v-row dense class=" pt-3">
              <v-col cols="6" class="d-flex justify-start">
                <v-btn
                  @click="openDocumentFull(item)"
                  color="primary"
                  style="
                    margin-left: 60px;
                    text-transform: none;
                    min-width: 114px;
                  "
                  outlined
                >
                  Edit
                </v-btn>
              </v-col>
              <v-col cols="6" class="d-flex justify-end">
                <v-btn
                  @click="copyDocumentFull(item)"
                  color="primary"
                  style="
                    margin-right: 60px;
                    text-transform: none;
                    min-width: 114px;
                  "
                  outlined
                >
                  <span>Copy</span>
                </v-btn>
              </v-col>
              
              <v-col cols="12" class="px-3">
                <EditSection
                  v-if="selectedDocumentId"
                  :documentId="selectedDocumentId"
                  :readOnly="true"
                  :isCopied="false"
                  :useInline="true"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <ResponseHandler :serviceResponse="response"></ResponseHandler>
      <Loading :isVisible="isLoading || isDocListLoading" />

      <v-dialog
        v-model="showDocumentFull"
        persistent
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <DocumentFullNEW
          v-if="
            showDocumentFull &&
            $loginState.user &&
            $loginState.user.settings.some(
              (s) =>
                s.setting === 'document_viewer' &&
                s.value.indexOf('docFull') === 0
            )
          "
          :documentId="selectedDocumentId"
          :isCopied="docCopied"
          :useInline="
            $loginState.user.settings.some(
              (s) => s.setting === 'document_viewer' && s.value === 'docFull'
            )
          "
          @copyDocument="copyDocumentFull"
          @openDocument="openDocumentFull"
          @close="showDocumentFull = false"
          @closeDocument="showDocumentFull = false"
          @documentHeaderSaved="documentHeaderSaved"
          @sessionExpired="sessionExpired"
        ></DocumentFullNEW>
        <DocumentFull
          v-else-if="showDocumentFull"
          :documentId="selectedDocumentId"
          :isCopied="docCopied"
          @copyDocument="copyDocumentFull"
          @openDocument="openDocumentFull"
          @close="closeDocument"
          @closeDocument="closeDocument"
          @documentHeaderSaved="documentHeaderSaved"
          @sessionExpired="sessionExpired"
        ></DocumentFull>
      </v-dialog>
      <v-dialog
        v-model="showExternalDocument"
        fullscreen
        transition="dialog-bottom-transition"
      >
        <DocumentExternal
          :documentId="selectedDocumentId"
          :isCopied="docCopied"
          @copyDocument="copyDocumentFull"
          @openDocument="openDocumentFull"
          @close="closeExternalDocument"
          @closeExternalDocument="closeExternalDocument"
          @documentHeaderSaved="documentHeaderSaved"
          @sessionExpired="sessionExpired"
        ></DocumentExternal>
      </v-dialog>
      <v-dialog
        v-model="showAddDocument"
        fullscreen
        transition="dialog-bottom-transition"
      >
        <DocumentAdd
          :open="showAddDocument"
          @closed="showAddDocument = false"
          @created="documentCreated"
          @sessionExpired="sessionExpired"
          @openDocument="openDocumentFull"
        ></DocumentAdd>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
//import draggable from "vuedraggable";
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
//import HierarchySearch from "@/components/cHierarchySearch"; // @ is an alias to /src
//import PartTypeIcon from "@/components/cPartTypeIcon"; // @ is an alias to /src
import utils from "@/common/utils.js";
import moment from "moment";
import EditSection from "@/components/cDocumentFullEditSection";
import DocumentFullNEW from "@/components/cDocumentFullNEW";
import DocumentFull from "@/components/cDocumentFull";
import DocumentAdd from "@/components/cDocumentAdd";
import DocumentExternal from "@/components/cDocumentExternal";
import { mapState } from 'vuex';
//import CountryFlag from 'vue-country-flag'

export default {
  name: "checkJP",
  components: {
    ResponseHandler,
    EditSection,
    DocumentFullNEW,
    DocumentFull,
    DocumentAdd,
    DocumentExternal,
    //    HierarchySearch,
    //    PartTypeIcon,
    //    CountryFlag
  },
  props: {},
  data: function () {
    return {    
      response: null,
      languageDialog: false,
      items: [],
      isDirty: false,
      dirtyCount: 0,
      isLoading: false,
      docStatusIncluded: [],
      docTypeIncluded: [],
      //   itemStatusSummary: [],
      itemStatusIncluded: [],
      hierarchyFilters: [],
      tempFilterText: "",
      filterText: "",
      selDoc: "",
      viewSelect: "",
      dtItemsPerPage: 20,
      showDocument: false,
      showDocumentFull: false,
      docCopied: false,
      closeDocument: false,
      documentHeaderSaved: false,
      showExternalDocument: false,
      closeExternalDocument: false,
      showAddDocument: false,
      dtHeaders: [
        {
          text: "System Number",
          align: "start",
          value: "system_number",
        },
        { text: "Job Title", value: "doc_name" },
        { text: "Status", value: "doc_status_text" },
        { text: "Location", value: "listview2" },
        { text: "Cost Center", value: "listview3" },
        { text: "Recruiter", value: "recruiter" },
        { text: "Updated", value: "job_updated_at" },
        {
          text: "Created",
          value: "job_created_at",
          sort: (a, b) =>
            moment(a, "D MMM YYYY").isAfter(moment(b, "D MMM YYYY"))
              ? 1
              : moment(a, "D MMM YYYY").isBefore(moment(b, "D MMM YYYY"))
              ? -1
              : 0,
        },
      ],
      searchText: null,
      selectedDocumentId: null,
      docType: "Job Advert",
      csvHeaders: [],
      cvLabel1: "Job Title",
      cvLabel2: "Location",
      cvLabel3: "Cost Center",
      dialog: false,
      options: { itemsPerPage: 15 },
      genPDF: false,
      ignoreTVInputEvent: false,
      defaultStatusFilterList: "approved,pre-draft,pending,draft",
      filteredItems: [],
      maxCards: 24,
      myJobs: "all",
      item: null,
      cardSelect: [
        // {text: '16 cards', value: 16},
        { text: "24 cards", value: 24 },
        { text: "32 cards", value: 32 },
        { text: "64 cards", value: 64 },
        // { text: "128 cards", value: 128 },
        // { text: "256 cards", value: 256 },
        // { text: "512 cards", value: 512 },
        // { text: "All cards", value: 10000 },
      ],
      showHierarchies: true,
      passedParams: {}
    };
  },
  watch: {
    showDocumentFull(open) {
      //ensure we don't end up with double scrollbars when fullscreen dialog is open
      if (open) document.documentElement.classList.add('noscroll');
      else document.documentElement.classList.remove('noscroll');
    },
    dialog(val) {
      val || this.close();
    },
    isDocListLoading(val) {
      if (!val) this.fetchData();
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    ...mapState({
      isDocListLoading: (state) => state.docs.loading,
      cleanItems: (state) => state.docs.docsList,
      hierarchyTypes: (state) => state.docs.hierarchyTypes,
      docStatusSummary: (state) => state.docs.docStatusSummary,
      docTypeSummary: (state) => state.docs.docTypeSummary,
      useTranslation: (state) => state.docs.useTranslation,
    }),
  },
  created() {
    this.passedParams = this.$passedParams.get();
    this.fetchData();
    this.viewSelect = localStorage.getItem("dlview") || "cards";
    this.dtItemsPerPage = Number(localStorage.getItem("dtRPP")) || 20;
    this.$emit("documentChangedHandler", this.documentChanged);
  },
  methods: {
    sessionExpired(err) {
      console.log(err);
      this.$loginState.reEnterPassword = true;
      this.$emit("sessionExpired", err);
    },
    resolveHeaderStyle(extension) {
      switch (extension) {
        case "xlsx":
        case "xls":
          return "#3cc542ad";
        case "doc":
        case "docx":
          return "#327bb5c7";
        case "txt":
        case "log":
          return "#ff23138f";
        case "pdf":
          return "#ff23138f";
      }
    },
    documentCreated(item) {
      this.selectedDocumentId = item.system_number;
      this.showAddDocument = false;
      if (item.doc_type == "External File") {
        this.showExternalDocument = true;
      } else {
        this.showDocument = true;
      }
      this.docCopied = false;
      this.documentOpened({
        system_number: item.system_number,
        doc_name: item.doc_name,
      });
      if (this.notifyDocumentChange) {
        this.notifyDocumentChange(item);
      }
      this.openDocumentFull(item, true);
    },
    addDocument() {
      this.showAddDocument = true;
    },
    closeAddDocument() {
      this.showAddDocument = false;
    },
    documentOpened(item) {
      this.filterText = item.doc_name;
      this.tempFilterText = item.doc_name;
      this.items.push(item);
      this.changefilterText(item.doc_name);
      this.recentDocs = utils.addRecentDoc(this.$loginState, item);
    },
    openDocumentFull(doc, isNew) {
      doc = doc || this.selectedItem;
      this.selectedDocumentId = doc.reference || doc.system_number || doc.id;
      this.refDocument = null;
      if (doc.doc_type == "External File" || doc.tmpl_name == "External File") {
        this.showExternalDocument = true;
        this.showDocument = false;
        this.showDocumentFull = false;
      } else {
        this.showDocumentFull = true;
        this.showDocument = false;
        this.showExternalDocument = false;
      }
      this.docCopied = isNew;
      this.documentOpened({
        system_number: doc.reference || doc.system_number || doc.id,
        doc_name: doc.doc_name || doc.title,
        doc_type: doc.doc_type,
      });
    },
    copyDocumentFull(item, tmpl_id, uploaded) {
      item = item || this.selectedItem;
      this.isLoading = true;
      let data = {
        source_doc_id: item.doc_id,
        tmpl_id: tmpl_id || item.tmpl_id,
      };

      axios
        .post("document/copyDocument/", data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            let doc = resp.data.Data[0][0];
            if (uploaded) {
              resp.data.Data[0].file_data = uploaded[0];
              resp.data.Data[0].doc_id = doc.doc_id;
              this.linkFileToDoc(resp.data.Data[0]);
            }
            this.openDocument(doc, !doc.groupMemberAdded);
            this.openDocumentFull(doc, !doc.groupMemberAdded);
          } else {
            alert(resp.data.Message);
          }
          this.response = resp.data;
          console.log(resp);
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.sessionExpired(err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    linkFileToDoc(docData) {
      this.isLoading = true;
      let data = {
        doc_id: docData.doc_id,
        file_id: docData.file_data.file_id,
      };

      axios
        .post("file/linkFileToDoc/", data)
        .then((resp) => {
          this.response = resp.data;
          console.log(resp);
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    documentChanged(doc) {
      this.$store.dispatch("docs/updateDoc", doc);
      this.doFilter();
    },
    fetchData() {
      if (this.isDocListLoading)
        return;

      this.hierarchyTypes.forEach((ht) => {
        let hdr = this.dtHeaders.find(
          (hd) => hd.value === "listview" + ht.position
        );
        if (hdr) hdr.text = ht.label;
        if (this["cvLabel" + ht.position])
          this["cvLabel" + ht.position] = ht.label;
      });

      if (this.$loginState.readOnly) {
        this.docStatusIncluded.push(
          this.docStatusSummary.find(
            (s) => s.status.toLowerCase() === "approved"
          ) || this.docStatusSummary[0]
        );
      } else {
        let defaultStatuses = utils.getDefaultStatuses(this.$loginState);
        var checkDefaultStatusesExist = false;
        defaultStatuses.forEach((ds) => {
          if (
            this.cleanItems.find(
              (itm) => itm.doc_status.toLowerCase() === ds.status.toLowerCase()
            )
          ) {
            checkDefaultStatusesExist = true;
          }
        });
        if (defaultStatuses.length && checkDefaultStatusesExist) {
          this.docStatusIncluded = defaultStatuses;
        } else {
          this.docStatusIncluded = this.docStatusSummary.filter(
            (s) =>
              this.defaultStatusFilterList.indexOf(s.status.toLowerCase()) >= 0
          );
        }
      }

      let docFound = false;
      if (this.passedParams.docTypes && this.passedParams.docTypes.length) {
        // passed default docType
        this.passedParams.docTypes.forEach((dt) => {
          let dtFound = this.docTypeSummary.find((dst) => dst.docType === dt);
          if (dtFound) {
            this.docTypeIncluded.push(dtFound);
            docFound = true;
          }
        });
      }

      if (!docFound) {
        this.docTypeIncluded = this.$loginState.getSetting("dwpDocTypes", []);
        if (!Array.isArray(this.docTypeIncluded)) this.docTypeIncluded = [];
        if (this.docTypeIncluded.length === 0)
          this.docTypeIncluded.push(this.docTypeSummary[0]);
      }
      this.doHierarchyFilter();
    },
    prepareRow(row, lvCols) {
      let item = JSON.parse(JSON.stringify(row)),
        match = "";
      lvCols.forEach((c) => {
        item[c] = "";
      });
      item.hierarchies.forEach((h) => {
        item["listview" + h.position] = h.hierarchy_text;
        match += h.hierarchy_text + " ";
      });
      item.match = `${item.system_number} ${match} ${item.recruiter} ${item.created_by} ${item.doc_status_text} ${item.description} ${item.advert_job_title}`
        .toLowerCase()
        .split(" ")
        .filter((x) => x.length)
        .reduce((p, c) => p + c + "|", "|");
      return item;
    },
    getStatusFilterText(item, index) {
      if (item && index > 0) return "";
      if (this.docStatusIncluded.length === this.docStatusSummary.length) {
        return this.docStatusSummary.length > 0 ? "All" : "None";
      } else {
        let defList = this.defaultStatusFilterList.split(",");
        if (
          defList.every((s) =>
            this.docStatusIncluded.some((si) => si.status.toLowerCase() === s)
          )
        ) {
          let text = "";
          text = "Default statuses";
          if (defList.length < this.docStatusIncluded.length) {
            text +=
              " (+ " +
              this.docStatusIncluded
                .filter(
                  (ds) => !defList.some((d) => d === ds.status.toLowerCase())
                )
                .reduce((prev, curr) => {
                  return prev + (prev ? ", " : "") + curr.status;
                }, "");
          }
          return text;
        } else {
          return this.docStatusIncluded.reduce((prev, curr) => {
            return prev + (prev ? ", " : "") + curr.status;
          }, "");
        }
      }
    },
    handleKeyUp() {
      //throttle so as not to run search too frequently
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = null;
      }

      this.searchTimeout = setTimeout(() => this.triggerFilter(), 500);
    },
    changefilterText(val) {
      this.tempFilterText = val;
    },
    changeView(val) {
      this.viewSelect = val;
      localStorage.setItem("dlview", val);
    },
    clearFilter() {
      this.tempFilterText = "";
      this.triggerFilter();
    },
    triggerFilter() {
      this.filterText = this.tempFilterText;
      this.doFilter();
    },
    doHierarchyFilter(filters) {
      this.hierarchyFilters = filters || [];
      this.doFilter();
    },
    doFilter() {
      utils.setDefaultStatuses(this.$loginState, this.docStatusIncluded);

      const params = {
        filterText: (this.filterText || "").trim().toLowerCase(),
        myJobs: this.myJobs !== "all",
        userName: this.$loginState.user ? this.$loginState.user.name : undefined,
        docStatusIncluded: this.docStatusIncluded,
        docTypeIncluded: this.docTypeIncluded,
      };
      this.items = this.$store.getters["docs/filter"](params);

      this.filteredItems = JSON.parse(JSON.stringify(this.items)).slice(0, 30);

      if (this.filteredItems.length) {
        this.openDocument(this.filteredItems[0]);
      } else {
        this.selectedItem = null;
        this.selectedDocumentId = null;
      }

      this.$loginState.saveSetting("dwpDocTypes", this.docTypeIncluded);
    },
    openDocument(item) {
      this.selectedItem = item;
      this.selectedDocumentId = item.reference || item.system_number;
      this.docType = item.doc_type;
      this.refDocument = null;
      if (
        item.doc_type == "External File" ||
        item.tmpl_name == "External File"
      ) {
        this.showExternalDocument = true;
        this.showDocument = false;
      } else {
        this.showDocument = true;
        this.showExternalDocument = false;
      }
    },
    dtOptionChange(value) {
      localStorage.setItem("dtRPP", value.itemsPerPage);
      this.dtItemsPerPage = value.itemsPerPage;
    },
    exportToCSV() {
      this.csvHeaders = JSON.parse(JSON.stringify(this.dtHeaders));
      this.csvHeaders.push({
        text: "Created By",
        value: "created_by",
      });
      let data =
        this.csvHeaders.map((h) => '"' + h.text + '"').join(",") + "\n";
      this.items.forEach((d) => {
        data +=
          this.csvHeaders
            .map((h) => utils.csvEscape(utils.removeTags(d[h.value])))
            .join(",") + "\n";
      });
      utils.downloadFile(data, "Documents.csv", "text/csv;encoding:utf-8");
    },
    logout() {
      this.$loginState.isAuthenticated = false;
      this.dataPermissionNeeded = false;
      localStorage.removeItem("rmapit");
      localStorage.removeItem("rmsso");
      this.$router.replace({ name: "default" });
      this.$router.push("/");
      window.location.reload();

      this.setPasswordToken = "";
      this.$emit("sessionExpired");
      //      this.showLogin();
    },
    sortByProperty(property) {
      return function (a, b) {
        if (a[property] > b[property]) return 1;
        else if (a[property] < b[property]) return -1;

        return 0;
      };
    },
  },
};
</script>
<style scoped lang="scss">
.buttons {
  margin-top: 35px;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.dragHandle {
  color: rgba(56, 106, 163, 0.801);
  cursor: grab;
  margin-left: -40px;
  /* margin-left: 30%; */
}
.status-chip {
  font-size: 0.7rem;
  margin-left: -8px;
  height: 25px;
}
.drag-handle,
.number-part {
  max-width: 20px;
  padding: 6px 0;
}
.data-content {
  margin-top: -5px;
  padding-top: 0;
}
.data-content-2 {
  margin-top: -7px;
  min-height: auto !important;
  max-height: auto;
  padding-bottom: 0;
}
.data-label {
  font-size: 0.6rem;
  padding: 0 5px 0 16px;
  color: darkgray;
}
.data-value {
  font-size: 0.9rem;
}
.data-value-2 {
  font-size: 0.75rem;
  font-family: "Arial Narrow", "Open Sans", Calibri;
  font-style: italic;
  padding-bottom: 10px;
}
.editIcon {
  position: relative;
  left: 870px;
  bottom: 14px;
  font-size: 1.1rem;
  color: lightgrey;
}
.editIcon2 {
  position: relative;
  left: 824px;
  bottom: 18px;
  font-size: 1.1rem;
  color: lightgrey;
}
.addIcon {
  position: relative;
  left: -40px;
  bottom: 3px;
  font-size: 1.3rem;
  color: lightgrey;
}
.workflowIcon {
  position: relative;
  left: -88px;
  bottom: 74px;
  font-size: 1.7rem;
  color: lightgrey;
}
.notesIcon {
  position: relative;
  left: 840px;
  bottom: 18px;
  font-size: 1.1rem;
  color: rgba(56, 106, 163, 0.801);
}
.pdfIcon {
  position: relative;
  left: 55px;
  top: -2px;
}
.notes {
  position: relative;
  left: 70px;
  top: -5px;
}
.editIcon:hover,
.editIcon2:hover,
.addIcon:hover,
.notesIcon:hover {
  color: black !important;
}
.errorComment {
  font-style: italic;
  font-size: 0.75rem;
  position: relative;
  left: 169px;
  min-width: 150px;
}
.errorComment2 {
  font-style: italic;
  font-size: 0.75rem;
  position: relative;
  left: 122px;
  min-width: 105px;
}
.CallToAction {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 1rem;
  margin-top: -6px;
  color: rgb(73, 80, 87);
}
.AdvertJobTitle {
  display: flex;
  justify-content: center;
  font-size: 1.8rem;
  margin-bottom: 5px;
  color: rgb(73, 80, 87);
}
.AboutTeamHdr,
.JobUSPHdr,
.ReqHdr,
.RespHdr,
.FlexibleWork,
.WithUsHdr,
.ForYouHdr,
.AboutUsHdr,
.JoinUsHdr {
  font-weight: 700;
  font-size: 1.1rem;
  padding-bottom: 0;
  color: rgb(73, 80, 87);
}
.WorkingPattern {
  line-height: 18px;
}
.AdvertJobTitleGutter,
.CallToActionGutter,
.AbouttheTeamGutter,
.JobUSPGutter,
.ResponsibilityGutter,
.RequirementGutter,
.FlexibleWorkGutter,
.WorkingPatternGutter {
  color: #ce8705ba;
}
.JobUSPHdrRow,
.RespIntroRow,
.ResponsibilityRow,
.RequirementRow,
.FlexibleWorkRow,
.FlexIntroRow,
.WorkingPatternRow,
.WithUsRow,
.ForYouRow,
.AboutUsRow,
.JoinUsRow {
  margin-top: -10px;
}
.AbouttheTeamRow,
.JobUSPRow {
  margin-top: -20px;
}
.JobUSPHdrGutter,
.RespIntroGutter,
.AboutTeamHdrGutter,
.ReqHdrGutter,
.RespHdrGutter,
.FlexIntroGutter,
.WithUsHdrGutter,
.ForYouHdrGutter,
.AboutUsHdrGutter,
.JoinUsHdrGutter,
.WithUsGutter,
.JoinUsGutter,
.AboutUsGutter,
.ForYouGutter {
  display: none !important;
}
.JobUSPHdrError,
.RespIntroError,
.AboutTeamHdrError,
.ReqHdrError,
.FlexIntroError,
.WithUsHdrError,
.ForYouHdrError,
.AboutUsHdrError,
.JoinUsHdrError {
  display: none !important;
}
.headerCell {
  padding: 10px 10px 10px 0;
}
.headerCellSmall {
  padding: 0 10px;
  font-size: 0.75rem;
  color: #946206da;
}
@media print {
  .AdvertJobTitle {
    font-size: 1.5rem !important;
  }
  .CallToAction {
    margin-top: -25px;
    font-size: 0.8rem !important;
  }
  .JobUSPHdrRow,
  .RespIntroRow,
  .WithUsRow,
  .WithUsHdr,
  .FlexIntro,
  .WorkingPattern,
  .ForYouHdr,
  .ForYouRow,
  .AboutUsHdr,
  .AboutUsRow {
    margin-top: -30px;
  }
  .list-group {
    margin-top: -30px;
  }
}

.page {
  background-color: rgb(249, 250, 254);
}

.whiteBack {
  background-color: white;
}

.result {
  width: 99%;
  margin-bottom: 2px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border: solid transparent 1px;
}
.results {
  padding: 0 10px;
}
.result_header {
  font-size: 15px;
  color: #005073 !important;
  padding-top: 5px;
  cursor: pointer;
}
.result_desc {
  font-size: 11px;
  font-weight: 500;
  line-height: 1.4;
  color: #8b9bad;
  cursor: pointer;
  padding-top: 0;
}
.logout {
  float: right;
}
.result {
  padding: 3px;
}
.result:hover {
  padding: 3px;
  outline: none;
  box-shadow: 0 0 10px #9ecaed;
  border: solid #9ecaed 1px;
  cursor: pointer;
  border-radius: 5px;
}
.myJobs {
  margin-top: -10px;
  margin-left: -20px;
}
.v-text-field.v-text-field--enclosed.searchBox {
  margin-left: -70px;
  border-radius: 12px;
  margin-top: 20px;
  max-height: 60px;
  padding-bottom: 0;
}
.theme--light {
  .v-text-field.v-text-field--enclosed.searchBox {
    background-color: white;
  }
}
.createBtn {
  position: relative;
  float: right;
  margin-right: 75px;
  text-transform: none;
  min-width: 114px;
}
.selResult {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  position: relative;
  left: 5px;
}
.selResult:after {
  content: "";
  display: block;
  position: absolute;
  left: 100%;
  top: 50%;
  margin-top: -10px;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 15px solid #999;
}
.result_client {
  font-size: 13px;
  color: #005073 !important;
  padding-top: 5px;
  cursor: pointer;
  text-align: right;
}

.theme--dark {
 .page {
    background-color:#000;
  }

  .whiteBack {
    background-color: #1e1e1e;
  }

  .result {
    box-shadow: none;
  }

  .result:hover {
    box-shadow: none;
    border: solid gray 1px;
    cursor: pointer;
    border-radius: 0;
  }

  .selResult {
    box-shadow: none;
    border: 1px solid gray;
  }

  .result_header,
  .result_client {
    color: #0091cf !important;
  }
}
</style>