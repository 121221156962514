<template>
  <div>
    <v-dialog v-model="show" max-width="600px">
      <v-card>
        <v-card-title>Save Report As </v-card-title>
        <v-card-text>
          <v-row
            ><v-col
              ><v-text-field
                v-model="view.name"
                label="Report Name"
              ></v-text-field></v-col
          ></v-row>
          <v-row v-if="!permissions || permissions.saveViewOwn"
            ><v-col
              ><v-checkbox
                :disabled="
                  permissions &&
                  permissions.saveViewOwn &&
                  !permissions.saveViewMain
                "
                v-model="view.restricted"
                label="Visible to me only?"
              ></v-checkbox></v-col
          ></v-row>
        </v-card-text>
        <v-card-actions
          ><v-spacer></v-spacer
          ><v-btn
            @click="saveDefinition()"
            :disabled="!view.name || !view.name.trim()"
            >Save</v-btn
          >
          <v-btn @click="show = false">Cancel</v-btn></v-card-actions
        >
      </v-card>
    </v-dialog>
    <ResponseHandler :serviceResponse="response"></ResponseHandler>
    <Loading :isVisible="isLoading" />
  </div>
</template>

<script>
import axios from "axios";
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
import { mapState } from "vuex";

export default {
  name: "saveViewDefinition",
  components: {
    ResponseHandler,
  },
  props: {
    saveTrigger: { type: Number, required: false, default: 0 },
    saveAsTrigger: { type: Number, required: false, default: 0 },
    inactiveTrigger: { type: Number, required: false, default: 0 },
    currentView: { type: Object, required: false },
    page: { type: String, required: true },
    permissions: { type: Object, required: false },
  },
  data: function () {
    return {
      show: false,
      view: {
        name: "",
        view_type: this.page,
        active: true,
        view_definition_id: 0,
        definition: null,
        description: "",
        restricted: true,
        isNew: false,
      },
      response: null,
    };
  },
  watch: {
    saveTrigger(val) {
      if (val > 0) {
        this.prepView();
        this.saveDefinition();
      }
    },
    inactiveTrigger(val) {
      if (val > 0) {
        this.prepView();
        this.saveDefinition(true);
      }
    },
    saveAsTrigger(val) {
      if (val > 0) {
        this.prepView();
        this.saveDefinitionAs();
      }
    },
    // currentView(val) {
    //   if (val) {
    //     this.view = { ...this.view, ...val };
    //   }
    // },
    isLoading(val) {
      if (!val) this.fetchData();
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.docs.loading,
    }),
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      if (this.isLoading) return;
      this.$store
        .dispatch("settings/getViewDefinitions", this.page)
        .then((resp) => {
          this.$emit("viewsLoaded", resp.data.Data);
        }),
        (error) => {
          console.error(error);
        };
    },
    prepView() {
      this.view = { ...this.view, ...this.currentView };
    },
    saveDefinitionAs() {
      this.show = true;
      this.view.isNew = true;
      this.view.restricted = true;
    },
    setViewTitle(view) {
      view.title = view.name + (view.restricted ? " (My report)" : "");
    },
    saveDefinition(makeInactive) {
      let possibleError = false;
      let view = this.view;
      view.active = makeInactive ? false : true;
      view.restricted = view.isNew
        ? view.restricted
        : this.currentView.restricted;
      view.view_definition_id =
        view.isNew && !makeInactive
          ? null
          : this.currentView.view_definition_id;
      view.name = view.isNew ? view.name.trim() : this.currentView.name;
      view.definition = this.currentView.definition;
      view.description = this.currentView.description;
      this.setViewTitle(view);
      axios
        .post("user/saveViewDefinition/", view)
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            if (resp.data.Data) {
              if (resp.data.Data.isDuplicateName) {
                resp.data.Status = "Error";
                resp.data.Message =
                  "Name already exists. Please choose another name.";
              } else {
                if (view.isNew) {
                  view = JSON.parse(JSON.stringify(view));
                }
                if (!view.view_definition_id) {
                  view.view_definition_id =
                    resp.data.Data.view.view_definition_id;
                }
                if (view.isNew) {
                  view.isNew = false;
                  this.$emit("viewAdded", view);
                } else {
                  if (makeInactive) {
                    resp.data.Message = "Report removed.";
                    this.$emit("viewInactive", view);
                  } else {
                    this.$emit("viewSaved", view);
                  }
                }
                this.show = false;
              }
            }
          }
          this.response = resp.data;
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/vars";
</style>
