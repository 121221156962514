<template>
  <div style="border-top: lightgray 1px solid; padding-top: 5px">
    <div class="footer-col1">
      <span v-if="rowEnd > 0"
        >{{ 1 + rowStart }} - {{ rowEnd }} of {{ totalItems }}</span
      >
    </div>
    <div class="footer-col2">
      <v-pagination
        v-model="currentPage"
        class="my-1"
        :length="pageCount"
        :total-visible="10"
      ></v-pagination>
    </div>
    <div class="footer-col3">
      <v-menu offset-y top z-index="301" class="mr-2">
        <template v-slot:activator="{ on, attrs }">
          <v-btn outlined small color="primary" v-bind="attrs" v-on="on">
            Export
            <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item @click="$emit('exportToCSV')">
            <v-list-item-title> Export to CSV </v-list-item-title>
          </v-list-item>
          <!-- <v-list-item @click="$emit('exportToCSVWithParts')">
            <v-list-item-title>
              Export to CSV with full details
            </v-list-item-title>
          </v-list-item> -->
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: "TablePager",
  components: {},
  props: {
    totalItems: { type: Number, required: true },
    pageSize: { type: Number, required: true },
    resetPage: { type: Number, required: true },
  },
  data: function () {
    return {
      currentPage: 1,
    };
  },
  watch: {
    pageSize(val) {
      if (val) {
        if (this.currentPage > this.pageCount)
          this.currentPage = this.pageCount;
        this.$emit("pageChanged", {
          start: this.rowStart,
          end: this.rowEnd,
          pageNo: this.currentPage,
        });
      }
    },
    resetPage(val) {
      if (val) {
        this.currentPage = 1;
      }
    },
    currentPage() {
      this.$emit("pageChanged", {
        start: this.rowStart,
        end: this.rowEnd,
        pageNo: this.currentPage,
      });
    },
  },
  computed: {
    pageCount() {
      let pageCount = Math.floor(this.totalItems / this.pageSize);
      if (this.totalItems % this.pageSize > 0) pageCount++;
      return pageCount;
    },
    rowStart() {
      return this.pageSize < 0 ? 0 : this.pageSize * (this.currentPage - 1);
    },
    rowEnd() {
      if (this.pageSize < 0) return this.totalItems;

      const rowEnd = this.rowStart + this.pageSize - 1;
      if (rowEnd > this.totalItems) return this.totalItems;
      else return rowEnd;
    },
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/vars";

div {
  font-size: 0.875rem;
}

.v-application.theme--light {
  div {
    color: $input-text-color;
  }
}
.page {
  border: solid 1px rgba(78, 93, 120, 0.1);
  background-color: transparent;
  margin: 0 2px;
  &.v-btn--disabled {
    border: 0;
    margin: 0;
    width: 20px;
  }
  &.selected {
    background-color: $secondary-color-light;
    &.theme--dark {
      background-color: $secondary-color-dark;
    }
  }
}
.footer-col1,
.footer-col2,
.footer-col3 {
  display: inline-block;
  width: 33%;
}

.footer-col2 {
  text-align: center;
}
.footer-col3 {
  text-align: right;
}
</style>
