<template>
  <div>
    <v-container fluid class="px-0">
      <v-row dense class="mt-3 px-6">
        <v-col class="d-flex align-center">
          <h1 class="title">Events Gateway Log</h1>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-data-table
            :headers="logHeaders"
            :items="eventLog"
            dense
            :expanded.sync="expanded"
            item-key="event_gateway_log_id"
            class="elevation-1"
          >
          <template v-slot:[`item.event_type`]="{ item }">
            <v-icon>
              {{getEventIcon(item.event_type)}}
            </v-icon>
             &nbsp; {{item.event_type}}
          </template>
            <template v-slot:[`item.event_data`]="{ item }">
              <div v-if="item.event_type === 'CopyTranslate'" style="display:inline-flex; padding-left: 30px;">
                  <v-icon @click="openDoc(item)" :title="item.document">
                    mdi-file-document
                  </v-icon> 
                  <span @click="openDoc(item)" style="padding-left:5px; padding-right:5px; padding-top: 9px; cursor:pointer">{{item.reference}}</span>
                  <v-icon style="padding-right:5px;padding-left: 10px;">
                    mdi-arrow-right-bold
                  </v-icon>
                  <span>
                    <country-flag :title="getLanguageText(item.event_data.language)" style="border:1px solid #a3a2a2" :country='getFlagForLang(item.event_data.language)'/> &nbsp; 
                  </span>
                  <span  style="padding-left:5px; padding-right:5px; padding-top: 9px;">
                    {{getLanguageText(item.event_data.language)}}
                  </span>

              </div>
              <div v-else align="left">
                <div v-for="(group,idx) in item.event_data" :key="idx" style="padding-bottom:5px;padding-top:5px" align="left">
                  <v-icon :title="group.files[0].file_extension" :color="resolveHeaderStyle(group.files[0].file_extension)" right>
                    {{resolveIcon(group.files[0].file_extension)}}
                  </v-icon>
                  <v-icon>
                    {{getDocIcons(group)}}
                  </v-icon>
                  <v-icon style="padding-right:5px;padding-left: 10px;">
                    mdi-arrow-right-bold
                  </v-icon>     
                  <span>
                    {{group.doc_type}}
                  </span>   
                </div>
                
              </div>
            </template> 
            <template v-slot:[`item.event_log`]="slotData">
              {{slotData.item.event_log.length}}
              <v-icon style="margin-left:20px" @click="clickColumn(slotData)">
                {{slotData.item.expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'}}
              </v-icon>
            </template>  
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" style="padding:0">

                <v-data-table
                  :headers="detailHeaders"
                  :items="item.event_log"
                  :expanded.sync="expanded"
                  :sort-by="['timestamp']"
                  :sort-asc="[false,true]"                  
                  dense
                  item-key="eIdx"              
                  class="elevation-1"
                >
                  <template v-slot:[`item.event_details`]="{ item }">
                    <div style="display:inline-flex" v-if="item.event_details !== null && item.event_type.toString().indexOf('-complete') === -1">
                      <v-icon @click="openDoc(item.event_details)" :title="item.event_details.reference">
                        mdi-file-document
                      </v-icon> 
                      <span @click="openDoc(item.event_details)" style="padding-left:5px; padding-right:5px; padding-top: 9px;cursor:pointer">{{item.event_details.reference}}</span>
                    </div>
                  </template> 
                
                </v-data-table>

              </td>
            </template>                         
          </v-data-table>
        </v-col>
      </v-row>    
    </v-container>      
    
  </div>
</template>

<script>

import axios from "axios";
import utils from "@/common/utils.js";
import CountryFlag from 'vue-country-flag';

export default {
  name: 'eventGatewayLog',
  data: function() {
    return {
      logHeaders: [
        { text: "Event Type", value: "event_type" },
        { text: "User", value: "user" },
        { text: "Event Data", value: "event_data" },
        { text: "Event Logs", value: "event_log" },
        { text: "Event Time", value: "event_timestamp", align: "start"  },
        { text: "Actions", value: "actions", sortable: false },
        { text: '', value: 'events_expand', sortable: false }
        //{ text: '', value: 'data-table-expand' }
      ],
      detailHeaders: [
        { text: "Event Type", value: "event_type" },
        { text: "Process ID", value: "process" },
        { text: "Event Data", value: "event_details" },
        { text: "Event Message", value: "event_message" },
        { text: "Event Time", value: "timestamp", align: "start"  },
        //{ text: '', value: 'data-table-expand' }
      ],      
      eventLog: [],
      expanded: []
    }
  },
  components: {
    CountryFlag
  },
  computed: {
    translateOptions: function () {
      return utils.translateOptions;
    },   
  },
  created() {
    this.getLogs();
  },  
  methods: { 
    openDoc(item){
      this.$emit("openDocument",{
        system_number: utils.removeTags(item.reference),
        doc_name: utils.removeTags(item.doc_name),
        doc_type: item.doc_type,
      });
    },
    getDocIcons(item){
      if(item.files[0].file_extension === 'xlsx' || item.files[0].file_extension === 'xls'){
        let rowStart = item.files[0].rowStart === undefined ? 1 : Number(item.files[0].rowStart); //start row (excel files)
        let rowEnd = item.files[0].rowEnd === undefined ? 1 : Number(item.files[0].rowEnd); //end row (excel files)     
        let rows = rowEnd - (rowStart-1); 
        if(rows > 9){
          return "mdi-numeric-9-plus-box-multiple";
        }

        return `mdi-numeric-${rows}-box-multiple`;
      } else {
        if(item.files.length > 9){
          return "mdi-numeric-9-plus-box-multiple";
        }

        return `mdi-numeric-${item.files.length}-box-multiple`;
      }

    },
    getFlagForLang(code){
      if (!code || code === "en") {
        return "";
      } else {
        let option = this.translateOptions.find((x) => x.value === code);
        return option ? option.flagCode : "";
      }
    },   
    getLanguageText(code){
        let option = this.translateOptions.find((x) => x.value === code);
        return option ? option.text : "";
    },
    clickColumn(slotData) {
      //const indexRow = slotData.index;
      slotData.item.expanded = !slotData.item.expanded;
      const indexExpanded = this.expanded.findIndex(i => i === slotData.item);
      if (indexExpanded > -1) {
        this.expanded.splice(indexExpanded, 1)
      } else {
        this.expanded.push(slotData.item);
      }
    },
    getEventIcon(evt){
      switch(evt){
        case "Translate":
        case "CopyTranslate":
          return "mdi-earth";
        case "DocCreate":
          return "mdi-plus-box-multiple-outline";
        default:
          return "";
      }
    },    
    resolveIcon(extension){
        switch(extension){
            case "xlsx":
            case "xls":
                return "mdi-microsoft-excel";
            case "doc":
            case "docx":
            case "rtf":
                return "mdi-microsoft-word";
            case "txt":
            case "log":
                return "mdi-file-document-outline";
            case "pdf":
                return "mdi-file-pdf-box";
        }
    }, 
    resolveHeaderStyle(extension){

        switch(extension){
            case "xlsx":
            case "xls":
                return "#3cc542ad";
            case "doc":
            case "docx":
                return "#327bb5c7";
            case "txt":
            case "log":
                return "#ff23138f";
            case "pdf":
                return "#ff23138f";
        }

    },       
    registerGatewayListeners(){
      this.$eventGateway.$on("document-translate-response", this.translateComplete);
    },
    translateComplete(data){
      //this.$eventsGateway.message(`Document Translate Complete - New Doc ${data}!!!`);
      this.$eventGateway.events.push(`Document Translate Complete - New Doc ${data}!!!`);
      //this.triggerNotification(`Document Translate Complete - New Doc ${data}!!!`, "success");
    },
    connectionEvent(){
      //alert(this.$eventGateway.status());
    },
    handleGatewayEvent(event){
      switch(event){
        case "connect":
          this.connectToGateway();
          break;
      }
    },
    connectToGateway(){
      this.useGateway = this.$loginState.user.settings.some(s => s.setting === 'use_event_gateway' && s.value === 'true');
      if(this.useGateway){
        this.$eventGateway.$on("connected", () => {
          this.registerGatewayListeners();
        })
        //alert(this.$eventGateway.connectionState);
        //this.$eventGateway.send("join",{ "roomName":"BulkDocCreationRoom" });
        this.$eventGateway.connect(this.$loginState.user.name);
      }
    },    
    getLogs() {
      axios
        .get(`message/eventsLog`)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            this.eventLog = resp.data.Data.map(x => {
                return { 
                  'data-table-expand':false,
                  ...x
                }
            });
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    }
  }
}
</script>
<style lang="scss" scoped>
</style>