<template>
  <v-row class="justify-center mt-6 mb-6 link-container">
    <draggable
      class="row px-3 justify-center"
      v-model="links"
      group="landinglinks"
      :disabled="!editMode"
    >
      <v-col v-for="(l, li) in links" :key="li" :cols="l.tile_cols">
        <v-hover v-slot="{ hover }">
          <v-card
            :class="{
              'help-card link-container': true,
              'hover': !editMode && hover && l.url,
              draggable: editMode,
            }"
            :to="editMode || l.passedParams || l.isExternal ? '' : l.url"
            :href="!editMode && l.isExternal ? l.url : ''"
            :target="l.open_newtab ? '_blank' : ''"
            :elevation="!editMode && hover && l.url ? 6 : 2"
            @click="linkClick($event, l)"
            @keypress.enter="linkClick($event, l)"
            :ripple="!!(l.url && !editMode)"
          >
            <v-card-text :style="l.height ? `height: ${l.height}` : ''">
              <div v-if="editMode" class="edit-btn" v-show="hover">
                <v-btn icon small title="Delete" @click="deleteTile($event, l)"
                  ><v-icon>delete</v-icon></v-btn
                >
                <v-btn icon small title="Copy" @click="copyTile($event, l)"
                  ><v-icon>content_copy</v-icon></v-btn
                >
                <v-btn icon small title="Settings" @click="editTile($event, l)"
                  ><v-icon>settings</v-icon></v-btn
                >
              </div>
              <template>
                <v-row v-if="l.hero_image" :class="['justify-center', l.image_height ? 'pt-2 pb-4' : 'pt-3 px-3']">
                  <v-img
                    :max-height="l.image_height"
                    :max-width="l.image_width"
                    :src="l.hero_image"></v-img>
                </v-row>
                <v-row class="titlerow">
                  <v-col>
                    <h4>{{ l.title }}</h4>
                  </v-col>
                  <v-col v-if="l.icon" cols="2" class="justify-end">
                    <v-icon
                      :color="l.icon_colour"
                      :class="['pr-1', l.icon]"
                      :style="`font-size: ${l.icon_size || 36}px`"
                      >{{ l.icon.split(" ")[0] }}</v-icon
                    >
                  </v-col>
                  <v-col
                    v-else-if="l.call_to_action"
                    cols="4"
                    class="d-flex justify-end pr-4"
                  >
                    <v-btn outlined color="primary">{{ l.call_to_action }}</v-btn>
                  </v-col>
                </v-row>
                <v-btn
                  v-if="l.icon && l.call_to_action"
                  text
                  color="primary"
                  class="px-0"
                  >{{ l.call_to_action }}
                  <v-icon right>mdi-arrow-right</v-icon></v-btn
                >
              </template>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>
    </draggable>
    <v-btn
      v-if="editMode"
      class="add-btn"
      large
      icon
      title="Add Tile"
      @click="addTile($event)"
      ><v-icon>add_circle_outline</v-icon></v-btn
    >
  </v-row>
</template>

<script>
import draggable from "vuedraggable";
import landingUtils from "./landingUtils.js";

export default {
  name: "landingLinks",
  components: {
    draggable,
  },
  data: function () {
    return {
      iframeUrls: {},
      refreshTimeout: null,
      links: []
    };
  },
  props: {
    definition: { type: Object },
    qaActions: { type: Array },
    editMode: { type: Boolean },
  },
  mounted() {},
  watch: {
    definition: {
      deep: true,
      handler(val) {
        this.links = val.links;
      }
    },
    links(val) {
      const definition = this.definition;
      definition.links = val;
      this.$emit("updated:definition", definition);
    }
  },
  computed: {},
  created() {
    this.links = this.definition.links;
  },
  methods: {
    linkClick(ev, link) {
      landingUtils.handleLandingLinkClick(this, ev, link);
    },
    editTile(ev, t) {
      ev.preventDefault();
      this.$emit("editTile", { row: this.definition, link: t });
    },
    addTile(ev) {
      ev.preventDefault();
      this.$emit("addTile", { row: this.definition });
    },
    copyTile(ev, t) {
      ev.preventDefault();
      this.$emit("copyTile", { row: this.definition, link: t });
    },
    deleteTile(ev, t) {
      ev.preventDefault();
      this.$emit("deleteTile", { row: this.definition, link: t });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";

.link-container {
  position: relative;
}

.edit-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.steps .edit-btn {
  top: -20px;
  right: -20px;
}

.add-btn {
  position: absolute;
  bottom: 0;
  right: -35px;
  z-index: 1;
}

.draggable {
  cursor: move;
}

.help-card {
  border-radius: 10px !important;
  min-width: 200px;
  .titlerow {
    min-height: 100px !important;
    .col {
      display: flex;
      align-items: center;
    }
    h4 {
      font-size: 14px;
      line-height: normal;
      white-space: pre-wrap;
      word-break: normal;
    }
  }
  .v-icon {
    opacity: 0.8;
  }
  &.theme--dark.hover {
    background-color: $secondary-background-dark;
  }
}
</style>