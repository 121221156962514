var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{staticClass:"sectionTitle mb-2",attrs:{"no-gutters":""}},[_c('h2',[_vm._v(" Issues ")])]),_c('v-row',{style:({ fontWeight: 'bold', fontSize: _vm.settings.fontSize || '12px' }),attrs:{"dense":""}},[_c('v-col',{staticStyle:{"cursor":"pointer"},attrs:{"cols":"6"},on:{"click":function($event){return _vm.setSort('name')}}},[(
          this.settings.sortBy && this.settings.sortBy.column === 'name'
        )?_c('span',[(this.settings.sortBy.desc)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-sort-descending")]):_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-sort-ascending")])],1):_vm._e()]),_c('v-col',{staticStyle:{"cursor":"pointer"},attrs:{"cols":"2"},on:{"click":function($event){return _vm.setSort('docCount')}}},[_vm._v("Docs "),(
          this.settings.sortBy && this.settings.sortBy.column === 'docCount'
        )?_c('span',[(this.settings.sortBy.desc)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-sort-descending")]):_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-sort-ascending")])],1):_vm._e()]),_c('v-col',{staticStyle:{"cursor":"pointer"},attrs:{"cols":"2"},on:{"click":function($event){return _vm.setSort('errorCount')}}},[_vm._v("Errors "),(
          this.settings.sortBy && this.settings.sortBy.column === 'errorCount'
        )?_c('span',[(this.settings.sortBy.desc)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-sort-descending")]):_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-sort-ascending")])],1):_vm._e()]),_c('v-col',{staticStyle:{"cursor":"pointer"},attrs:{"cols":"2"},on:{"click":function($event){return _vm.setSort('warningCount')}}},[_vm._v("Warnings "),(
          this.settings.sortBy && this.settings.sortBy.column === 'warningCount'
        )?_c('span',[(this.settings.sortBy.desc)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-sort-descending")]):_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-sort-ascending")])],1):_vm._e()])],1),_c('perfect-scrollbar',{style:({ height: _vm.settings.height - 157 + 'px', padding: '4px', fontSize: _vm.settings.fontSize || '12px' })},_vm._l((_vm.issues),function(i,ii){return _c('v-row',{key:'i' + ii,staticStyle:{"cursor":"pointer"},attrs:{"no-gutters":""},on:{"click":function($event){return _vm.rowSelected(i)}}},[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(_vm._s(i.name)+" "),(_vm.filterIssue === i.name)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.removeFilter.apply(null, arguments)}}},[_vm._v("mdi-filter-remove")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(_vm._s(i.docCount))]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(_vm._s(i.errorCount))]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(_vm._s(i.warningCount))])],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }