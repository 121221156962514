<template>
  <v-container>
    <v-row>
      <v-col cols="2" class="text-right">
        <v-btn @click.stop="regen" :disabled="processing">Regenerate Data</v-btn>
      </v-col>
      <v-col
        >this will regenerate the reporting data. Only use this when application
        usage is low!</v-col
      >
      <v-col cols="1" v-if="processing" class="text-right">
        <v-progress-circular
          :size="50"
          :width="17"
          color="grey"
          indeterminate
        ></v-progress-circular
      ></v-col>
      <v-col v-if="processing">Processing data...</v-col>
    </v-row>
    <ResponseHandler :serviceResponse="response"></ResponseHandler>
  </v-container>
</template>

<script>
import axios from "axios";
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src

export default {
  name: "AdminSystem",
  data: function () {
    return {
      response: null,
      processing: false,
    };
  },
  components: {
    ResponseHandler,
  },
  watch: {},
  created() {},
  methods: {
    regen() {
      this.processing = true;
      axios
        .post("document/regenDocSummary")
        .then((resp) => {
          this.processing = false;
          this.response = resp.data;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.processing = false;
        });
    },
  },
};
</script>