<template>
  <v-container v-if="view">
    <v-row v-if="!view.respondents || view.respondents.length === 0"
      ><v-col><h3>No Evaluators assigned</h3></v-col>
    </v-row>
    <v-row v-else
      ><v-col :cols="readOnly.qCols"><h3>Status Summary</h3></v-col>
      <v-col>
        <v-row>
          <v-col
            v-for="(rs, rsi) in view.documentQuestionnaireStatus
              .questionnaireStatuses"
            :key="'rs_' + rsi"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <div
                    class="responder-stat"
                    :style="{
                      width: (70 * rs.count) / rs.total + '%',
                      backgroundColor: rs.colour,
                      color: rs.colour,
                      cursor:
                        view.documentQuestionnaireStatus.questionnaireStatuses
                          .length > 1
                          ? 'pointer'
                          : '',
                      borderRadius: '5px',
                    }"
                    @click="applyStatusFilter(rs)"
                  >
                    <span>{{ rs.count }}</span>
                  </div>
                  <div
                    class="pl-3 responder-stat"
                    :style="{
                      cursor:
                        view.documentQuestionnaireStatus.questionnaireStatuses
                          .length > 1
                          ? 'pointer'
                          : '',
                    }"
                    @click="applyStatusFilter(rs)"
                  >
                    {{ rs.count }} {{ rs.status }}
                    <v-icon
                      class="pt-0"
                      v-if="
                        view.documentQuestionnaireStatus.questionnaireStatuses
                          .length > 1
                      "
                      >{{
                        rs.filter ? "mdi-filter-remove" : "mdi-filter-outline"
                      }}</v-icon
                    >
                  </div>
                </div>
              </template>
              <div>{{ rs.count }} {{ rs.status }}:</div>
              <div
                v-for="(rp, rpi) in view.respondents.filter(
                  (x) => x.status === rs.status
                )"
                :key="'rs_' + rsi + 'rp' + rpi"
              >
                <div
                  class="responder-stat"
                  :style="{
                    width: '16px',
                    height: '16px',
                    backgroundColor: rs.colour,
                    paddingRight: '10px',
                    color: rs.colour,
                    borderRadius: '7px',
                  }"
                ></div>
                <span style="padding-left: 10px">{{ rp.userName }}</span>
              </div>
            </v-tooltip>
          </v-col>
        </v-row></v-col
      >
      <v-col cols="1" class="d-flex justify-end">
        <v-progress-circular indeterminate size="30" color="primary" v-if="exporting">
        </v-progress-circular>
        <v-menu v-else offset-y bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>download</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="exportToCSV">
              <v-list-item-title>
                Export as CSV
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="exportVersion('pdf')" >
              <v-list-item-title>
                Export as PDF
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="exportVersion('docx')" >
              <v-list-item-title>
                Export as Word
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-row v-if="view.respondents.length">
      <v-col :cols="readOnly.qCols"
        ><h3 v-if="view.respondents.length > 1">
          Evaluators{{ readOnlyResponderTitle }}:
          <v-menu offset-y v-if="readOnly.showPageSize">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" dark v-bind="attrs" v-on="on">
                mdi-dots-vertical-circle-outline
              </v-icon>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title>Responders per page:</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-for="(item, index) in [1, 2, 3, 4, 5]"
                :key="index"
                @click="setReadOnlyPageSize(item)"
              >
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </h3>
        <h3 v-else>Response:</h3>
      </v-col>
      <v-col
        :cols="readOnly.aCols"
        v-for="(r, ri) in view.respondents.filter(
          (x) => x.page === readOnly.page
        )"
        :key="'r' + ri"
        ><v-btn
          v-if="readOnly.pages > 1 && ri === 0"
          x-small
          @click="readOnlyScroll(-1)"
          class="stepper-left"
          :disabled="readOnly.page === 1"
          outlined
          ><v-icon small>mdi-chevron-left</v-icon></v-btn
        >
        <v-btn
          v-if="readOnly.pages > 1 && ri === readOnly.pageSize - 1"
          x-small
          @click="readOnlyScroll(1)"
          class="stepper-right"
          :disabled="readOnly.page === readOnly.pages"
          outlined
          ><v-icon small>mdi-chevron-right</v-icon></v-btn
        >
        <div class="response">
          <div
            class="responder"
            :class="
              r.status === 'Completed'
                ? 'responder-completed'
                : r.status === 'Not Started'
                ? 'responder-not-started'
                : 'responder-in-progress'
            "
            :title="r.user_role + ': ' + r.userName"
          >
            <v-icon small>mdi-account-circle-outline</v-icon>{{ r.userName }}
          </div>
          <div class="status-label">
            {{ r.status_detail }}
            <v-chip
              v-if="r.score_calculation !== 'NONE'"
              label
              class="float-right"
              small
              >Overall Score: {{ r.overall_score }}</v-chip
            >
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-for="s in view.sections" :key="'vs' + s.section_id">
      <v-col>
        <v-row style="background-color: #f5f5f5" @click="showSection(s)"
          ><v-col
            ><h4>{{ s.title }}</h4></v-col
          ><v-col cols="1"
            ><v-icon class="float-right">{{
              s.show ? "mdi-chevron-up" : "mdi-chevron-down"
            }}</v-icon></v-col
          >
        </v-row>
        <v-row
          v-for="q in s.questions.filter((x) => x && s.show)"
          :key="'vs' + s.section_id + 'q' + q.questionIndex"
        >
          <v-col :cols="readOnly.qCols">
            <v-row dense>
              <v-col cols="1">
                <svg v-if="isWithinDialogue" width="22" height="22">
                  <circle cx="11" cy="11" r="11" fill="gray" />
                  <circle cx="11" cy="11" r="9" fill="white" />
                  <text
                    x="11"
                    y="14"
                    text-anchor="middle"
                    fill="gray"
                    font-size="13"
                    font-family="'Martel Sans', sans-serif"
                    font-weight="bold"
                  >
                    {{ q.questionIndex }}
                  </text>
                </svg></v-col
              >
              <v-col
                ><div style="padding-left: 10px">
                  {{ q.question }}
                </div></v-col
              >
            </v-row></v-col
          >
          <v-col
            :cols="readOnly.aCols"
            v-for="(a, ai) in q.answers.filter((x, xi) =>
              isRespondentVisisble(xi)
            )"
            :key="'vs' + s.section_id + 'q' + q.questionIndex + 'a' + ai"
          >
            <div
              v-if="a.value"
              class="answer"
              :class="{ answers_same: q.allMatch }"
            >
              <template v-if="q.control_type === 'MULTI'">
                <v-chip
                  small
                  v-for="(v, vi) in a.value"
                  :key="
                    'vs' +
                    s.section_id +
                    'q' +
                    q.questionIndex +
                    'a' +
                    ai +
                    'v' +
                    vi
                  "
                  >{{ v }}</v-chip
                ></template
              >
              <div style="display: inline-block; width: 100%;">
                <span
                  v-if="q.control_type !== 'MULTI'"
                  :style="{
                    width: a.score ? 'calc(100% - 40px)' : '',
                    display: 'inline-block',
                  }"
                  >{{ a.value }}</span
                >
                <div class="answer-score" v-if="a.score">{{ a.score }}</div>
                <v-tooltip bottom v-if="a.notes">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      style="padding-top: 3px"
                      small
                      class="float-right pr-2"
                      v-bind="attrs"
                      v-on="on"
                      >mdi-message-text</v-icon
                    >
                  </template>
                  <span>{{ a.notes }}</span>
                </v-tooltip>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import dayJS from "dayjs";
import utils from "@/common/utils.js";
import documentExport from "@/common/documentExport.js";

export default {
  name: "DataCaptureQuestionnaireSummary",
  props: {
    data: Object,
    identifier: Object,
    isWithinDialogue: Boolean,
  },
  data: function () {
    return {
      view: null,
      readOnly: {
        pageSize: 5,
        page: 1,
        pages: 1,
        aCols: 3,
        qCols: 3,
        showPageSize: false,
      },
      exporting: false
    };
  },
  components: {},
  computed: {
    readOnlyResponderTitle() {
      let visTotal = this.view.respondents.filter((x) => x.visible).length;
      let visPage = this.view.respondents.filter(
        (x) => this.readOnly.page === x.page
      ).length;
      let start = (this.readOnly.page - 1) * this.readOnly.pageSize + 1;
      let text = start;
      if (visPage > 1) text = `${text} to ${start + visPage - 1}`;
      if (visTotal > 1) text = `${text} of ${visTotal}`;
      if (visTotal < this.view.respondents.length) text += " filtered";
      return ` (${text})`;
    },
  },
  created() {
    this.loadData();
  },
  watch: {
    data: function () {
      this.loadData();
    },
    identifier: function (newVal) {
      if (newVal) this.getData();
    },
    "readOnly.pageSize": function () {
      this.setReadOnlyCols();
    },
  },
  methods: {
    loadData(data) {
      let view;
      if (this.data) {
        view = JSON.parse(JSON.stringify(this.data));
      } else if (this.identifier && data) {
        view = data;
      } else if (this.identifier) {
        this.getData();
      }
      if (view) {
        view.sections.forEach((s) => (s.show = true));
        view.documentQuestionnaireStatus.questionnaireStatuses.forEach(
          (x) => (x.filter = false)
        );
        view.respondents.forEach((x, xi) => {
          x.visible = true;
          x.index = xi;
          x.page = 0;
        });
        this.view = view;
        if (!this.isWithinDialogue) {
          this.readOnly.pageSize = 1;
        } else if (this.view.respondents.length === 1) {
          this.readOnly.pageSize = 1;
        } else if (this.view.respondents.length < 5) {
          this.readOnly.pageSize = this.view.respondents.length;
        }
        this.readOnly.showPageSize = this.isWithinDialogue;
        this.setReadOnlyCols();
        this.setReadOnlyPages();
      }
    },
    getData() {
      let possibleError;
      axios
        .post("workflow/getDocumentQuestionnaires/", this.identifier)
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            this.loadData(resp.data.Data);
          }
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
    showSection(s) {
      s.show = !s.show;
    },
    setReadOnlyCols() {
      switch (this.readOnly.pageSize) {
        case 1:
          this.readOnly.qCols = 6;
          this.readOnly.aCols = 6;
          break;
        case 2:
          this.readOnly.qCols = 4;
          this.readOnly.aCols = 4;
          break;
        case 3:
          this.readOnly.qCols = 3;
          this.readOnly.aCols = 3;
          break;
        case 4:
          this.readOnly.qCols = 4;
          this.readOnly.aCols = 2;
          break;
        case 5:
          this.readOnly.qCols = 2;
          this.readOnly.aCols = 2;
          break;
      }
    },
    setReadOnlyPageSize(size) {
      this.readOnly.pageSize = size;
      this.setReadOnlyPages();
    },
    applyStatusFilter(status) {
      status.filter = !status.filter;
      let visStatus =
        this.view.documentQuestionnaireStatus.questionnaireStatuses
          .filter((x) => x.filter)
          .map((x) => x.status);
      this.view.respondents.forEach((r) => {
        r.visible =
          visStatus.length === 0 ||
          visStatus.length ===
            this.view.documentQuestionnaireStatus.questionnaireStatuses
              .length ||
          visStatus.some((s) => s === r.status);
      });
      this.setReadOnlyPages();
    },
    isRespondentVisisble(index) {
      return (
        this.view.respondents[index].visible &&
        this.view.respondents[index].page === this.readOnly.page
      );
    },
    setReadOnlyPages() {
      let visCount = 0;
      let pageSize = this.readOnly.pageSize;
      this.view.respondents.forEach((r) => {
        if (r.visible) {
          visCount++;
          r.page = Math.floor((visCount - 1) / pageSize) + 1;
          this.readOnly.pages = r.page;
        } else {
          r.page = 0;
        }
      });
      this.readOnlyScroll(0);
    },
    readOnlyScroll(direction) {
      this.readOnly.page = direction === 0 ? 1 : this.readOnly.page + direction;
    },
    exportToCSV() {
      let prep = (text, nocomma) => {
        return `${nocomma ? "" : ","}${utils.csvEscape(
          utils.removeTags(text)
        )}`;
      };
      let lines = ['"Evaluations"', '""'];
      this.view.respondents.forEach((r) => {
        lines[0] += prep(r.user_role + ": " + r.userName);
        lines[1] += prep(r.status_detail);
      });

      this.view.sections.forEach((s) => {
        lines.push(prep(s.title, true));
        s.questions.forEach((q) => {
          let line = prep(`${q.questionIndex} - ${q.question}`, true);
          line += q.answers.map((a) => prep(`${a.value || ""}`)).join("");
          lines.push(line);
        });
      });
      let data = lines.join("\n");
      utils.downloadFile(
        data,
        "EvaluationAnswers.csv",
        "text/csv;encoding:utf-8"
      );
    },
    exportVersion(format) {
      this.exporting = true;
      const params = {
        questionnaire_id: this.view.questionnaire_id,
        doc_id: this.view.doc_id,
        format: format
      };

      axios
        .post("workflow/exportDocumentQuestionnaires", params, { responseType: 'arraybuffer' })
        .then((resp) => {   
          const docName = `${this.view.doc_reference}_${this.view.title.replace(/[^a-z0-9]/gi, '_')}_${dayJS().format("YYYYMMDDHHmm")}.${format}`;
          utils.downloadFile(resp.data, docName, documentExport.getMimeType(format));
          this.exporting = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            alert(err.response ? err.response.data.message || err.response.statusText : err);
          }
          this.exporting = false;
        });
    }
  },
};
</script>
<style scoped lang="scss">
.questionnaireHeader {
  .v-expansion-panel-header {
    padding: 15px;
    margin-top: 5px;
    min-height: 40px !important;
    .active {
      background-color: rgba(68, 107, 235, 0.795) !important;
    }
  }
}

.questionnaireSectionHeader {
  .v-expansion-panel-header {
    padding: 15px;
    margin-top: 5px;
    min-height: 40px !important;
  }

  .v-expansion-panel-content {
    margin-top: 10px;
  }
}

.v-tooltip__content {
  background: rgba(97, 97, 97, 1);
}
.v-tooltip__content.menuable__content__active {
  opacity: 1 !important;
}

.underline {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-bottom: 10px;
  padding-top: 5px;
  padding-right: 10px;

  div {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.375rem;
  }
}
.underline:last-child {
  border-bottom: 0;
}

.response {
  border: solid 1px lightgray;
  border-radius: 10px;
  padding: 10px;
  height: 100%;
}

.responder {
  border: solid 1px lightgray;
  border-radius: 5px;
  display: block;
  height: 24px;
  font-size: 11px;
  //   padding-top: 3px;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  opacity: 70%;
}
.responder i {
  padding-right: 6px;
}
.responder-not-started {
  background-color: rgb(244, 67, 54); /* red */
  color: white;
}
.responder-completed {
  background-color: rgb(127, 193, 130); /* green */
  color: white;
}
.responder-in-progress {
  background-color: rgb(255, 193, 7); /* amber */
  color: black;
}
.responder-not-started i,
.responder-completed i {
  color: white;
}
.answer {
  border: 1px solid rgb(149, 212, 149);
  border-radius: 10px;
  text-align: center;
  min-height: 24px;
}
.answers_same {
  background-color: #dff6df;
}
.responder-incomplete {
  border-color: gray;
}
.responder-non {
  border-color: red;
  border-width: 2px;
}
button.v-btn.stepper-right,
button.v-btn.stepper-left {
  min-width: 20px !important;
  height: 100%;
}
.stepper-right {
  margin-right: -25px;
  float: right;
}
.stepper-left {
  margin-left: -25px;
  float: left;
}
.responder-stat {
  display: inline-flex;
  text-align: center;
  height: 20px;
  font-size: 14px;
  padding-top: 3px;
  padding-left: 8px;
  padding-right: 4px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  opacity: 70%;
}
.status-label {
  padding-top: 6px;
}
.answer-score {
  float: right;
  width: 30px;
  border: 1px green solid;
  border-radius: 5px;
  text-align: center;
  padding-top: 3px;
  margin: 5px;
}
</style>