<template>
  <div>
    <v-menu
      v-model="displayMenu"
      :value="displayMenu"
      scrollable
      :close-on-content-click="false"
      offset-y
      offset-x
      :position-x="posX"
      :position-y="posY"
    >
      <v-card width="575px" max-height="520px" class="rm-context-menu">
        <v-card-title v-if="issueList.length === 1">
          Language Issues: {{issueList[0].short_description}}
          <v-spacer></v-spacer>
          Dictionary:&nbsp;&nbsp;<b>{{spell_check_language_code === "en" ? "UK" : "US"}}</b>&nbsp;&nbsp;
          <v-spacer></v-spacer>
          <v-icon @click="toggleDictionaryDialog(true)" small label="Edit Dictionary">mdi-cog</v-icon>&nbsp;&nbsp;
          <v-icon @click="close" small right>mdi-close</v-icon>
        </v-card-title>
        <v-card-title v-if="issueList.length > 1">
          <v-row>
            <v-col cols="4">Language Issues: {{activeIssue+1}} of {{issueList.length}}</v-col>
            <v-col cols="2" style="display:inline-flex">
              <div style="width:50%"><v-icon title="Show Previous Issue" v-show="activeIssue > 0" color="primary" style="font-size: 18px !important;" @click.prevent="activeIssue--;" small>mdi-chevron-left-circle</v-icon></div>
              <div style="width:50%"><v-icon title="Show Next Issue" @click.prevent="activeIssue++;" color="primary" style="font-size: 18px !important;" v-show="activeIssue !== issueList.length-1" small>mdi-chevron-right-circle</v-icon></div>
            </v-col>
            <v-col cols="4" align="right">Dictionary:&nbsp;&nbsp;<b>{{spell_check_language_code === "en" ? "UK" : "US"}}</b></v-col>
            <v-col cols="2" align="right">
              <v-icon @click="toggleDictionaryDialog(true)" small label="Edit Dictionary">mdi-cog</v-icon>&nbsp;&nbsp;
              <v-icon @click="close" small right>mdi-close</v-icon>
            </v-col>
          </v-row>
        </v-card-title>          
        <v-card-text scrollable>
          <div v-for="(item,idx) in issueList" :key="idx">
            <div v-if="activeIssue === idx">
              <span class="grammarHint" v-html="item.description"></span><br /><br />
              <span v-if="item.type ==='spellcheck'">
                <b>Suggested:</b> &nbsp;&nbsp;
                <span>
                  <v-hover v-slot="{ hover }" open-delay="200">
                    <span
                      @click="
                        performSpellReplace(
                          item.word,
                          item.correction
                        )
                      "
                      style="cursor: pointer"
                      :class="{ 'on-hover': hover }"
                      >{{ item.correction }}</span
                    >
                  </v-hover> </span><br />                
              </span>
              <span v-if="item.alternatives.length">
                <b>Alternatives:</b><br />
                <div class="altContainer" style="overflow-x: hidden; min-height:125px;">
                  <v-row>
                    <v-col cols="3"
                      v-for="(v, i) in item.alternatives"
                      :key="i"
                    >
                    <v-hover v-slot="{ hover }" open-delay="200">
                      <span
                        @click="
                          performSpellReplace(item.word, v)
                        "
                        style="cursor: pointer"
                        :class="{ 'on-hover': hover }"
                        >{{ v }} &nbsp;</span
                      >     
                    </v-hover>
                    </v-col>
                  </v-row>
                </div>
              </span>
              <!--<span  class="languageCorrectionText" v-if="nlpMenu.activeSpellCheck.partcount > 1">
                <v-checkbox dense small label="Update All Occurances?" v-model="nlpMenu.activeSpellCheck.applyAll"></v-checkbox>
              <br/>
              </span>-->
              <!--<span
                v-if="
                  nlpMenu.activeSpellCheck.candidates.length > 0 &&
                  nlpMenu.activeSpellCheck.correction !== ''
                "
              >
                <b>Suggested:</b> &nbsp;&nbsp;
                <span>
                  <v-hover v-slot="{ hover }" open-delay="200">
                    <span
                      @click="
                        performSpellReplace(
                          nlpMenu.activeSpellCheck.word,
                          nlpMenu.activeSpellCheck.correction
                        )
                      "
                      style="cursor: pointer"
                      :class="{ 'on-hover': hover }"
                      >{{ nlpMenu.activeSpellCheck.correction }}</span
                    >
                  </v-hover> </span
                ><br />
                <span v-if="nlpMenu.activeSpellCheck.candidates.length > 1">
                  <b>Alternatives:</b><br />
                  <div class="altContainer">
                    <span
                      v-for="(v, i) in nlpMenu.activeSpellCheck.candidates"
                      :key="i"
                    >
                      <v-hover v-slot="{ hover }" open-delay="200">
                        <span
                          @click="
                            performSpellReplace(nlpMenu.activeSpellCheck.word, v)
                          "
                          style="cursor: pointer"
                          :class="{ 'on-hover': hover }"
                          >{{ v }} &nbsp;</span
                        >
                      </v-hover>
                    </span>
                  </div>
                </span>
              </span>-->
              <span v-if="item.type ==='spellcheck'" >
                <v-row style="padding-top: 15px;">
                  <v-col cols="2">
                    <v-btn @click="addItemToDictionary(item.word)" style="padding-bottom: 5px; margin-top: 5px; display:inline" color="warning" dense small>Ignore</v-btn>
                  </v-col>                    
                  <v-col cols="8" class="grammarHint"><v-text-field label="Custom Correction" v-model="item.customCorrection" dense></v-text-field></v-col>
                  <v-col cols="2">
                    <v-btn @click="performSpellReplace(item.word, item.customCorrection)" style="padding-bottom: 5px; margin-top: 5px; right:5px;" color="primary" dense small>Correct</v-btn>
                  </v-col>               
                </v-row>
              </span>              
              <span v-if="item.type !=='spellcheck' && item.alternatives.length === 0 && (item.type !== 'Long Sentence' && item.type !== 'Short Sentence')" class="grammarHint">
                No Suggestions -
                <v-btn
                  small
                  color="warning"
                  dark
                  @click="performSpellReplace(item.word, '')"
                >
                  <v-icon left> mdi-close </v-icon>
                  Delete
                </v-btn>
              </span>
            </div>
          </div>

        </v-card-text>        
      </v-card>
    </v-menu> 
    <UserDictionary
      :user="user_data"
      :show="showDictionary"
      @close="(val) => toggleDictionaryDialog(val)"
    >
    </UserDictionary>       
  </div>
</template>

<script>
import UserDictionary from "@/components/common/UserDictionary";
import nlpUtils from "@/common/nlpUtils.js";

export default {
  name: 'languageMenu',
  props: {
    show: Boolean,
    xCoord: Number,
    yCoord: Number,
    menu: Object
  },  
  data: function() {
    return {
      displayMenu: false,
      spell_check_language_code: "en",
      posX: 0,
      posY: 0,
      match: {
        short_description: "",
        description: "",
        word: "",
        alternatives:[],
      },
      issueList: [],
      activeIssue:0,
      showDictionary:false,
      user_data: {
        enabled: false,
        checkOnLoad: false,
        dictionary: []
      }
    }
  },
  components: {
    UserDictionary
  },
  watch: {
    menu(obj){
      this.displayMenu = obj.show;
      this.posX = obj.x;
      this.posY = obj.y;
      this.match = {
        ...obj.match,
        alternatives:[],
        description: obj.match?.description.replace("{{word}}", obj.match.word)
      };

      this.activeIssue = 0;

      this.issueList = [];
      obj.issueTypes.forEach(i => {
        if(i.type === "spellcheck"){

          let activeItem = i.spellCheckData.variations ? i.spellCheckData.variations[i.source.idx-1] : i.spellCheckData;

          i.short_description = i.word;
          i.description = activeItem.message;
          i.alternatives = activeItem.candidates;

          this.issueList.push({
            ...i,
            correction: activeItem.correction,
            short_description: i.word,
            description: activeItem.message,
            alternatives: activeItem.candidates,
            source: {
              doc_id: obj.doc_id,
              ...i.source
            }
          })  
        } else {
          this.issueList.push({
            ...i,
            short_description: i.match ? i.word : i.type,
            description:i.match ? i.match?.description.replace("{{word}}", i.match.word) : this.getIssueDescription(i.type),
            alternatives: Array.isArray(i.match?.alternatives) ? i.match?.alternatives.filter(x => x !== "") ?? [] : []
          }) 
        }
      })

      this.activeIssue = 0;

      this.spell_check_language_code = (obj.languageCode && obj.languageCode !== 'en') ? obj.languageCode : 'en';
    },
    show(val){
      this.displayMenu = val;
    },
    xCoord(val){
      this.posX = val;
    },
    yCoord(val){
      this.posY = val;
    }
  },  
  mounted() {
  },
  created() {

  },
  computed: {

  },
  methods: { 
    async addItemToDictionary(val){
      let languageTools = nlpUtils.getLanguageTools(this.$loginState.user);
      let existing = languageTools.spellCheck.options.dictionary.find(x => x.type === 'spellcheck' && x.word === val);

      if(existing){
        existing.active = 1;
      } else {
        languageTools.spellCheck.options.dictionary.push({
          user_text_correction_id:0,
          type: "spellcheck",
          word: val,
          active: 1
        });
      }

      let data = {
          dictionary_user: this.$loginState.user.user_id,
          dictionary: languageTools.spellCheck.options.dictionary
      }
      await nlpUtils.saveDictionary(data).then((resp) => {
          this.displayMenu = false;
          this.updateSpellCheck(resp);
      });
    },
    updateSpellCheck(data){
      this.$loginState.user.languageOptions.spellCheck.options.dictionary = data.dictionary;
      let currentIssue = this.menu.issueTypes[this.activeIssue]
      this.$emit("languageOptionsUpdate", { doc_id: this.menu.doc_id, ...currentIssue.source});        
    },
    toggleDictionaryDialog(val){
      this.showDictionary = val;
      if(val){
        let languageTools = nlpUtils.getLanguageTools(this.$loginState.user);
        this.user_data = {
          user_id: this.$loginState.user.user_id,
          spellCheck: languageTools.spellCheck
        };
        this.$emit("close");
      } else {
        //refresh dictionary spell check stuff
      }
    },    
    close(){
      this.displayMenu = false;
      this.$emit("close");
    },
    getIssueDescription(type){
      switch(type){
        case "Long Sentence":
          return "Sentence is too long - rephrase";
        case "Short Sentence":
          return "Sentence is too short - add more words";
      }
    },
    performSpellReplace(old, item) {
      let reg = new RegExp(old, "gim");
      let result = this.menu.textStatus.text.match(reg);
      if (result) {
        let newText = "";
        let issue = this.menu.issueTypes.find(x => x.word === old);
        if(issue){
          let source = this.menu.issueTypes[this.activeIssue].source;  
          if(issue.type === 'spellcheck'){
            let sourcePart = this.menu.spellCheckData.parts.find(x => x.doc_part_id === source.doc_part_id);
            let part = sourcePart.issues.find(x => x.word.toLowerCase() === old.toLowerCase());
            if(part && sourcePart){    
              let idx = this.menu.issueTypes[this.activeIssue].source.idx;
              let activeItem = part.variations[idx-1];
              if(this.menu.textStatus.text.substring(0, activeItem.offset) === ""){
                item = item[0].toUpperCase() + item.substring(1);
              }
              newText = this.menu.textStatus.text.substring(0, activeItem.offset) + item + this.menu.textStatus.text.substring(activeItem.offset + activeItem.word.length);
            }
          } else {
            let parts = this.menu.textStatus.text.split(result[0]);
            let first = parts.slice(0,issue.idx);
            let end = parts.slice(issue.idx);
            let newStart = first.join(issue.word);
            if(newStart === ""){
              item = item[0].toUpperCase() + item.substring(1);
            }
            newText = newStart + item + end.join(issue.word);   
          }

          this.$emit("doSpellReplace", newText, { doc_id: this.menu.doc_id, ...source, issue: issue}); 
        }


      }
    }
  }
}
</script>
<style>

</style>