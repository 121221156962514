<template>
  <div>
    <v-dialog v-model="open" max-width="800px">
      <v-card v-if="attribute">
        <v-card-title>{{ attribute.title }} Values</v-card-title>
        <v-card-subtitle v-if="unclassified"
          >NOTE: {{ attribute.title }} is not a lookup. To convert to a lookup
          and generate the values click
          <v-btn @click="generate">Generate</v-btn></v-card-subtitle
        >
        <v-card-text>
          <v-simple-table dense fixed-header height="600px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Value</th>
                  <th class="text-left" style="width: 40%">Sort Key</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in values" :key="item.value">
                  <td>
                    {{ item.value }}
                  </td>
                  <td style="width: 40%">
                    <v-text-field
                      v-if="item.editMode"
                      v-model="item.sort_key"
                      dense
                      outlined
                      clearable
                      hide-details
                      @blur="hideEdit(item)"
                      @change="isDirty = true"
                    ></v-text-field>
                    <div v-else @click="showEdit(item)">
                      {{ item.sort_key }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions
          ><v-spacer></v-spacer
          ><v-btn v-if="!unclassified" @click="save" :disabled="!isDirty">Save</v-btn>
          <v-btn @click="close">{{ unclassified ? "Close" : "Cancel" }}</v-btn></v-card-actions
        >
      </v-card>
    </v-dialog>
    <ResponseHandler :serviceResponse="response"></ResponseHandler>
    <!-- <Loading :isVisible="isLoading" /> -->
  </div>
</template>

<script>
import axios from "axios";
import ResponseHandler from "@/components/ResponseHandler";
// import { mapState } from "vuex";

export default {
  name: "attributeValueAdmin",
  components: {
    ResponseHandler,
  },
  props: {
    attribute: { type: Object, required: false },
    rawValues: { type: Array, required: false },
    show: { type: Number, required: true },
  },
  data: function () {
    return {
      open: false,
      unclassified: false,
      isDirty: false,
      values: [],
      response: null,
    };
  },
  watch: {
    show(val) {
      if (val) {
        let values = this.attribute.values;
        this.unclassified = false;
        if (!values.length && this.rawValues.length) {
          values = this.rawValues;
          this.unclassified = true;
        }
        values = JSON.parse(JSON.stringify(values));
        values.forEach((v) => (v.editMode = false));
        this.values = values;
        this.open = true;
      }
    },
  },
  //   computed: {
  //     ...mapState({
  //       isLoading: (state) => state.docs.loading,
  //     }),
  //   },
  //   created() {
  //     this.fetchData();
  //   },
  methods: {
    save() {
      let possibleError = false;
      let data = {
        tpa_id: this.attribute.tpa_id,
        lookup_type: this.attribute.lookup_type,
        lookup_key: this.attribute.lookup_key,
        values: this.values,
      };
      axios
        .post("admin/saveLookupValues/", data)
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            if (resp.data.Data) {
              this.$emit(
                "updated",
                resp.data.Data[0].map((x) => {
                  return {
                    lk_id: x.lk_id,
                    sort_key: x.sort_key,
                    value: x.value1,
                  };
                })
              );
              this.open = false;
            }
          }
          this.response = resp.data;
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
    generate() {
      let possibleError = false;
      let data = {
        tpa_id: this.attribute.tpa_id,
        lookup_type: null,
        lookup_key: this.attribute.title,
        values: this.values,
      };
      axios
        .post("admin/convertToLookupValues/", data)
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            if (resp.data.Data) {
              this.$emit(
                "created",
                resp.data.Data[0].map((x) => {
                  return {
                    lk_id: x.lk_id,
                    sort_key: x.sort_key,
                    value: x.value1,
                  };
                })
              );
              this.open = false;
            }
          }
          this.response = resp.data;
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
    showEdit(item) {
      this.values.forEach((v) => (v.editMode = false));
      item.editMode = true;
    },
    hideEdit(item) {
      item.editMode = false;
    },
    close() {
      this.open = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/vars";
</style>
