<template>
  <div v-if="action">
    <v-card v-if="isBusy" style="min-height: 500px" class="pa-5">
      <v-toolbar fixed dense flat color="transparent">
        <v-toolbar-title>{{ definition.data_capture_name }}</v-toolbar-title>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="5"></v-col>
          <v-col>
            <v-progress-circular indeterminate size="50" color="grey">
            </v-progress-circular>
          </v-col>
          <v-col cols="5"></v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div v-else>
      <v-card
        v-if="action.definition"
        :class="{ 'pa-5': viewType === 'dialogue' }"
      >
        <v-card-title>
          Assign {{ definition.data_capture_name }}
          <v-spacer></v-spacer>
          <v-icon v-if="action.dataCaptureEditAction" @click="triggerEdit">
            mdi-square-edit-outline</v-icon
          >
          <v-icon v-if="action.isDataCaptureViewer && viewType === 'inline'"
            >{{
              action.isDataCaptureHidden ? `mdi-eye` : "mdi-eye-off"
            }}
            mdi-eye</v-icon
          >
          <template v-if="viewType === 'dialogue'">
            <v-spacer></v-spacer>
            <v-btn
              large
              :disabled="!UIstate.canCancel"
              icon
              class="btn-background ml-2"
              @click="cancel"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text
          style="max-height: 800px"
          v-if="!action.isDataCaptureViewer"
        >
          <v-row
            dense
            v-if="
              (viewType === 'inline' || viewType === 'contained') &&
              UIstate.errorMessage
            "
          >
            <v-col cols="12">
              <v-alert dense type="warning">
                {{ UIstate.errorMessage }}</v-alert
              ></v-col
            ></v-row
          >
          <!-- <v-row
            ><v-col cols="2">Current {{ definition.users.role }}s:</v-col>
            <v-col
              ><v-chip
                v-for="(s, si) in definition.users.userlistLinked"
                :key="'s' + si"
                >{{s.user_name}}</v-chip
              ></v-col
            ></v-row
          > -->
          <v-row v-if="definition.use_review_group">
            <v-col
              ><h2>Individual {{ definition.users.role }}s:</h2></v-col
            >
            <v-col
              ><h2>Review Group {{ definition.users.role }}s:</h2></v-col
            >
          </v-row>
          <v-row>
            <v-col
              cols="6"
              v-for="(rg, rgi) in definition.users.reviewGroupsLinked.filter(
                (x) => x.review_group_id < 0
              )"
              :key="'rg' + rgi"
              ><v-chip
                class="ma-2"
                small
                close
                :color="s.deleted ? 'red' : 'blue-grey-lighten-4'"
                :text-color="s.deleted ? 'white' : ''"
                v-for="(s, si) in rg.users"
                :key="'rg' + rgi + 's' + si"
                @click:close="toggleUser(s)"
                ><v-icon left> mdi-account </v-icon>{{ s.user_name }}</v-chip
              ></v-col
            ><template v-if="definition.use_review_group">
              <v-col
                cols="6"
                v-for="(rg, rgi) in definition.users.reviewGroupsLinked.filter(
                  (x) => x.review_group_id > 0
                )"
                :key="'rg' + rgi"
                ><v-list
                  ><v-list-item>
                    <v-list-item-avatar>
                      <v-icon>mdi-account-group</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        rg.review_group_name
                      }}</v-list-item-title>
                      <v-list-item-subtitle
                        ><span>{{
                          rg.users.map((u) => u.user_name).join(", ")
                        }}</span></v-list-item-subtitle
                      >
                    </v-list-item-content></v-list-item
                  ></v-list
                >
              </v-col></template
            ></v-row
          >
          <v-row
            ><v-col cols="6"
              ><v-autocomplete
                v-model="definition.value.selectedUsers"
                :label="
                  'Select ' +
                  definition.users.role +
                  (!!definition.multiple ? 's' : '')
                "
                :items="definition.users.userlistNotLinked"
                item-text="text"
                item-value="user_id"
                outlined
                small-chips
                chips
                clearable
                deletable-chips
                :multiple="!!definition.multiple"
                @input="setUIstate(true)"
              ></v-autocomplete
            ></v-col>
            <v-col v-if="definition.use_review_group">
              <v-autocomplete
                v-model="definition.value.selectedReviewGroup"
                label="Select Review Group"
                outlined
                small-chips
                :items="definition.users.reviewGroups"
                item-text="review_group_name"
                item-value="review_group_id"
                chips
                clearable
                deletable-chips
                @input="setUIstate(true)"
              >
                <template v-slot:selection="data">
                  <v-list-item-avatar>
                    <v-icon>mdi-account-group</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      data.item.review_group_name
                    }}</v-list-item-title>
                    <!-- <v-list-item-subtitle
                      ><span>{{
                        data.item.users.map((u) => u.user_name).join(", ")
                      }}</span></v-list-item-subtitle
                    > -->
                  </v-list-item-content>
                  <!-- <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="remove(data.item)"
                >
                  <v-avatar left>
                    <v-icon>mdi-account-group</v-icon>
                  </v-avatar>
                  {{ data.item.review_group_name }}
                </v-chip> -->
                </template>
                <template v-slot:item="data">
                  <v-list-item-avatar>
                    <v-icon>mdi-account-group</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      data.item.review_group_name
                    }}</v-list-item-title>
                    <v-list-item-subtitle
                      ><span>{{
                        data.item.users.map((u) => u.user_name).join(", ")
                      }}</span></v-list-item-subtitle
                    >
                  </v-list-item-content>
                </template></v-autocomplete
              >
            </v-col>
          </v-row>
          <v-row dense v-if="viewType === 'dialogue'">
            <v-col cols="4">
              <v-alert v-if="UIstate.errorMessage" dense type="warning">
                {{ UIstate.errorMessage }}</v-alert
              ></v-col
            >
            <v-col
              cols="5"
              style="
                text-align: right;
                padding-top: 15px;
                padding-right: 25px;
                font-weight: bold;
              "
              >{{ changesDescription }}</v-col
            >
            <v-col cols="2">
              <v-spacer></v-spacer>
              <v-btn class="mr-2" @click="save()" :disabled="!UIstate.canSave"
                >Save Changes</v-btn
              ></v-col
            >
            <v-col cols="1">
              <v-btn @click="cancel" :disabled="!UIstate.canCancel"
                >Cancel</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
// import axios from "axios";

export default {
  name: "DataCaptureDocumentRole",
  props: {
    action: Object,
    isBusy: Boolean,
    editState: Object,
    docId: Number,
  },
  data: function () {
    return {
      isDirty: false,
      definition: null,
      hasSavedData: false,
      savedData: null,
      viewType: "", // inline, dialogue, contained
      UIstate: {
        canSave: false,
        canCancel: false,
        // canDelete: true,
        errorMessage: "",
      },
    };
  },
  components: {},
  computed: {},
  created() {
    this.init();
  },
  watch: {
    action(newval) {
      if (newval) {
        this.init();
      }
    },
    "editState.doSave"(newval) {
      if (newval) {
        this.save(true);
      }
    },
  },
  methods: {
    init() {
      //   let def = { ...this.action.definition };
      let def = JSON.parse(JSON.stringify(this.action.definition));
      this.savedData = JSON.parse(JSON.stringify(this.action.definition));
      def.users.reviewGroupsLinked.forEach((r) =>
        r.users.forEach((u) => (u.deleted = false))
      );
      def.users.userlistNotLinked = def.users.userlistNotLinked.map(u => {
        u.text = `${u.user_name} <${u.email}>`;
        return u;
      }).sort((a, b) => {
        let aname = a.user_name.toLowerCase();
        let bname = b.user_name.toLowerCase();
        return aname > bname ? 1 : aname < bname ? -1 : 0;
      });
      def.value = {
        selectedUsers: def.multiple ? [] : "",
        deletedUsers: [],
        selectedReviewGroup: def.users.reviewGroupsLinked.find(
          (x) => x.review_group_id > 0
        )?.review_group_id,
      };
      this.savedData.selectedReviewGroup = def.value.selectedReviewGroup;
      this.savedData.currentUserCount =
        def.users.reviewGroupsLinked.find((x) => x.review_group_id < 0)?.users
          .length || 0;
      this.hasSavedData = !!def.value;
      this.definition = def;
      this.isDirty = false;
      this.viewType = this.action.isDataCaptureEditInHeader
        ? "contained"
        : this.action.isDataCaptureEditInPage
        ? "inline"
        : "dialogue";
      this.setUIstate();
    },
    toggleUser(user) {
      user.deleted = !user.deleted;
      if (user.deleted) {
        this.definition.value.deletedUsers.push(user.user_id);
      } else {
        this.definition.value.deletedUsers.splice(
          this.definition.value.deletedUsers.findIndex(
            (x) => x === user.user_id
          )
        );
      }
      this.setUIstate(this.definition.value.deletedUsers.length > 0);
    },
    setUIstate(makeDirty) {
      let message = "";
      if (makeDirty) this.isDirty = true;
      let isValid =
        !this.action.data_capture_mandatory ||
        this.definition.value.selectedReviewGroup ||
        this.definition.value.selectedUsers?.length > 0 ||
        this.savedData.currentUserCount > this.definition.value.deletedUsers.length;
      this.UIstate.canCancel = true;
      // !this.action.data_capture_mandatory || this.hasSavedData;
      message = !isValid
        ? `This document must have a ${this.definition.data_capture_name}`
        : "";
      //   }
      this.UIstate.errorMessage = message;
      this.changesDescription = [
        {
          use: this.definition.value.deletedUsers.length > 0,
          message: `${this.definition.value.deletedUsers.length} ${this.definition.data_capture_name}${
            this.definition.value.deletedUsers.length > 1 ? "s" : ""
          } removed`,
        },
        {
          use: this.definition.value.selectedUsers?.length > 0,
			message: this.definition.multiple ?
				`${this.definition.value.selectedUsers?.length} ${this.definition.data_capture_name}${
            this.definition.value.selectedUsers?.length > 1 ? "s" : ""
          } assigned` : `${this.definition.data_capture_name} assigned`,
        },
        {
          use:
            !!this.definition.value.selectedReviewGroup !==
            !!this.savedData.selectedReviewGroup,
          message: `Review Group updated`,
        },
      ]
        .filter((x) => x.use)
        .map((x) => x.message)
        .join(", ");
      this.UIstate.canSave = this.changesDescription && isValid;

      if (this.editState) {
        this.$emit("updateState", {
          isDirty: this.isDirty,
          canSave: this.UIstate.canSave,
          canCancel: this.UIstate.canCancel,
          isValid: isValid,
        });
      }
    },
    save(forceSave) {
      if (forceSave && forceSave === true) {
        this.$emit("save", this.definition, this.action);
      } else {
        switch (this.viewType) {
          case "inline":
          case "dialogue":
            this.$emit("save", this.definition, this.action);
            this.isDirty = false;
            break;
          case "contained":
            break;
        }
      }
      this.setUIstate();
    },
    cancel() {
      this.$emit("cancel");
      if (this.viewType === "inline") {
        this.definition.value = this.savedData.value;
        this.isDirty = false;
        this.setUIstate();
      }
    },
    triggerEdit() {
      this.$emit("requestEdit", this.action.dataCaptureEditAction);
    },
    toggleHide() {
      this.$emit("toggleHidden");
    },
  },
};
</script>
<style scoped lang="scss">
</style>