var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticStyle:{"max-width":"100%"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-card',{staticClass:"mapCard mt-2"},[_c('v-card-text',[_c('table',{staticStyle:{"border-collapse":"collapse"}},[_c('tr',_vm._l((_vm.dashboards.filter((d) => d.menuTitle)),function(db,dbi){return _c('td',{key:'dbh' + dbi,class:[
                  'card-title',
                  db.subTitle === 'Analysis' ? 'pr-4' : 'px-4',
                ],style:({
                  'font-size': '30px',
                  'border-right': '1px solid lightgrey',
                  'text-align': 'center',
                }),attrs:{"colspan":db.subTitle === 'Analysis' ? '2' : '4'}},[_vm._v(" "+_vm._s(db.menuTitle)+" ")])}),0),_c('tr',_vm._l((_vm.dashboards),function(db,dbi){return _c('td',{key:'dbhs' + dbi,class:[
                  'card-title',
                  _vm.tab === dbi ? 'selTab' : '',
                  'pt-2',
                  db.subTitle === 'Analysis' ? 'pr-4' : 'px-2',
                ],style:({
                  'font-size': '18px',
                  'border-right':
                    db.subTitle === 'Analysis' || db.subTitle === 'Applicants'
                      ? '1px solid lightgrey'
                      : 'none',
                }),attrs:{"colspan":db.subTitle === 'Analysis' ? '2' : '1'},on:{"click":function($event){return _vm.openDashboard(dbi)}}},[_vm._v(" "+_vm._s(db.subTitle)+" ")])}),0)]),_c('v-icon',{staticClass:"float-right",attrs:{"color":"green darken-2","title":"Download Dashboard Data"},on:{"click":_vm.exportToCSV}},[_vm._v("mdi-cloud-download")]),_vm._l((_vm.dashboards),function(db,dbi){return _c('v-row',{key:'db' + dbi,attrs:{"dense":""}},[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.tab === dbi),expression:"tab === dbi"}],attrs:{"cols":"12"}},[_c('v-row',{attrs:{"dense":""}},_vm._l(([1, 2, 3]),function(col){return _c('v-col',{key:'db' + dbi + '_' + col,attrs:{"cols":"4"}},_vm._l((db.charts.filter(
                      (ch) => ch.column === col
                    )),function(ch,chi){return _c('div',{key:'chd' + dbi + '_' + col + '_' + chi},[_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(!db.loaded),expression:"!db.loaded"}],attrs:{"size":90,"width":17,"color":"grey","indeterminate":""}}),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-breadcrumbs',{attrs:{"items":ch.navItems,"divider":">"},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('v-breadcrumbs-item',{attrs:{"link":"","disabled":item.level === ch.drillDownLevel},on:{"click":function($event){return _vm.clickHandler(db, item, ch, true)}}},[_vm._v(_vm._s(item.text))])]}}],null,true)})],1)],1),_c('v-row',{attrs:{"dense":""}},[(ch.type === 'map' && ch.ready)?_c('v-col',[_c('l-map',{ref:ch.ref,refInFor:true,style:({
                            height: ch.height + 'px',
                            width: ch.width + 'px',
                            zIndex: 1,
                          }),attrs:{"options":{ zoomSnap: 0.25 }}},[_c('l-tile-layer',{attrs:{"url":ch.map.url,"attribution":ch.map.attribution}}),(ch.map.geojson)?_c('l-geo-json',{attrs:{"geojson":ch.map.geojson,"options":ch.map.options,"options-style":ch.map.optionsStyle}}):_vm._e()],1)],1):_c('v-col',[_c('div',{attrs:{"id":ch.ref}})])],1)],1)}),0)}),1)],1)],1)})],2)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }