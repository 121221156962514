<template>
  <div>
    <div class="flex-container" v-show="!isLoading && !isDocDataLoading">
      <div
        v-show="$vuetify.breakpoint.smAndUp && !showHierarchies"
        v-if="!qualityMode"
        class="hierarchies-collapsed"
      >
        <v-btn
          fab
          elevation="2"
          x-small
          title="Show Advanced Filters"
          @click="showHierarchies = !showHierarchies"
          absolute
          color="primary"
          class="showHierarchies"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>

      <v-navigation-drawer
        v-if="$vuetify.breakpoint.smAndUp && !qualityMode"
        v-model="showHierarchies"
        absolute
        class="left-col"
      >
        <v-btn
          fab
          elevation="2"
          x-small
          title="Show Hierarchies"
          @click="showHierarchies = !showHierarchies"
          absolute
          right
          color="primary"
          class="showHierarchies"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <v-toolbar-title class="d-flex align-center pl-1 pt-5">
          <span class="ml-2">Filter By Hierarchy</span>
        </v-toolbar-title>

        <HierarchySearch
          v-show="showHierarchies"
          :items="docParts"
          :hierarchyTypes="hierarchyTypes"
          :classifierTypes="classifierTypes"
          @filterChanged="doHierarchyFilter"
        ></HierarchySearch>
      </v-navigation-drawer>
      <v-container
        fluid
        :class="{
          'right-col': true,
          hierarchiesCollapsed: !showHierarchies && $vuetify.breakpoint.smAndUp,
          hierarchiesPinned: showHierarchies && $vuetify.breakpoint.lgAndUp,
        }"
      >
        <v-row class="pl-4 py-4">
          <v-col cols="6">
            <h1 class="title">{{ $route.meta.text }}</h1>
          </v-col>
          <v-col class="text-right" v-if="$loginState.canQA"
            ><v-btn-toggle
              v-model="qualityModeSelected"
              outlined
              color="dark-grey accent-3"
              group
            >
              <v-btn
                rounded
                outlined
                value="content"
                @change="setQualityMode(false)"
              >
                Content Management
              </v-btn>
              <v-btn value="reuse" @change="setQualityMode(true)">
                Reuseable Content
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-card>
          <div class="mx-4">
            <v-row dense class="pt-4">
              <v-col cols="1" class="selectAllCol" v-if="!qualityMode">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-checkbox v-model="selectAll" v-on="{ ...tooltip }" />
                  </template>
                  <span>{{
                    selectAll ? "Clear Selection" : "Select All"
                  }}</span>
                </v-tooltip>
              </v-col>
              <v-col cols="1" v-if="docTypes.length > 1" class="filter-col">
                <v-select
                  v-model="selectedDocType"
                  :items="docTypes"
                  item-text="docType"
                  item-value="docType"
                  return-object
                  label="Document Types"
                  :menu-props="{
                    closeOnContentClick: false,
                    maxHeight: '400',
                    'offset-y': true,
                  }"
                  dense
                  hide-details
                  outlined
                  @change="filterDocType()"
                >
                </v-select>
              </v-col>
              <v-col cols="1" class="filter-col">
                <v-menu
                  v-if="partTypeSelected"
                  offset-y
                  max-height="calc(100vh - 200px)"
                  v-model="sectionMenu"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-on="on"
                      outlined
                      hide-details
                      dense
                      label="Document Sections"
                      readonly
                      :value="partTypeSelected.type"
                      :append-icon="
                        sectionMenu ? 'mdi-menu-up' : 'mdi-menu-down'
                      "
                      class="sectionsSelect"
                      @click:append="sectionMenu = true"
                    >
                    </v-text-field>
                  </template>

                  <v-list dense v-if="selectedDocType">
                    <v-list-item v-if="!selectedDocType.groups.length">
                      <v-list-item-content>
                        <v-list-item-title> No items found </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-group
                      v-for="g in selectedDocType.groups"
                      :key="g.partGroup"
                      no-action
                      v-model="g.active"
                      active-class="group-active"
                      :prepend-icon="g.icon"
                    >
                      <template v-slot:activator>
                        <v-list-item-content>
                          <v-list-item-title>{{ g.partGroup }}</v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <v-list-item
                        v-for="pt in g.sections"
                        :key="pt.tmpl_part_id"
                        @click="filterPartType(pt)"
                        :class="
                          pt.tmpl_part_id == partTypeSelected.tmpl_part_id
                            ? 'v-list-item--active'
                            : ''
                        "
                      >
                        <v-list-item-content>
                          <v-list-item-title>{{ pt.type }} </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-group>
                  </v-list>
                </v-menu>
              </v-col>
              <v-col cols="1" class="filter-col" v-if="!qualityMode">
                <v-select
                  v-model="itemStatusIncluded"
                  :items="itemStatusSummary"
                  item-text="status"
                  item-value="status"
                  return-object
                  label="Flag Issues"
                  multiple
                  outlined
                  hide-details
                  :menu-props="{
                    closeOnContentClick: false,
                    maxHeight: '400',
                    'offset-y': true,
                  }"
                  dense
                  @change="doFilter()"
                >
                  <template v-slot:selection="{ item, index }">
                    <span>{{ getStatusFilterText(item, index) }}</span>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="1" class="filter-col" v-if="!qualityMode">
                <v-select
                  v-model="docStatusIncluded"
                  :items="docStatusSummary"
                  item-text="status"
                  item-value="status"
                  return-object
                  label="Job Statuses"
                  multiple
                  outlined
                  hide-details
                  :menu-props="{
                    closeOnContentClick: false,
                    maxHeight: '400',
                    'offset-y': true,
                  }"
                  dense
                  @change="doFilter()"
                >
                  <template v-slot:selection="{ item, index }">
                    <span>{{ getDocStatusFilterText(item, index) }}</span>
                  </template>
                </v-select>
              </v-col>
              <v-col
                cols="12"
                md="6"
                lg="3"
                class="filter-col pl-2 d-flex justify-end align-center"
              >
                <div class="d-flex align-center">
                  <v-btn
                    large
                    icon
                    class="btn-background ml-2"
                    v-show="!showSearch"
                    @click="showFilterField"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>

                  <v-expand-x-transition>
                    <v-text-field
                      v-show="showSearch"
                      v-model="filterText"
                      ref="filterField"
                      hide-details
                      solo
                      flat
                      label="Filter jobs containing..."
                      prepend-inner-icon="mdi-magnify"
                      append-icon="mdi-close"
                      @input="changefilterText"
                      @keypress.enter="triggerFilter"
                      @click:prepend-inner="triggerFilter"
                      @keyup.esc="clearFilter"
                      @click:append="clearFilter"
                      class="btn-background ml-2 align-center"
                    ></v-text-field>
                  </v-expand-x-transition>
                </div>
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn large icon class="btn-background ml-2" v-on="on">
                      <v-icon> mdi-sort-variant </v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-title class="subtitle-1"
                        >Sort By</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item-group v-model="sortBy" color="primary">
                      <v-list-item
                        v-for="field in [
                          {
                            icon: 'mdi-sort-numeric-descending',
                            value: 'Word Count (desc)',
                          },
                          {
                            icon: 'mdi-sort-numeric-ascending',
                            value: 'Word Count (asc)',
                          },
                          {
                            icon: 'mdi-sort-descending',
                            value: 'Quality (desc)',
                          },
                          {
                            icon: 'mdi-sort-ascending',
                            value: 'Quality (asc)',
                          },
                          {
                            icon: 'mdi-sort-alphabetical-descending',
                            value: 'Alpha (desc)',
                          },
                          {
                            icon: 'mdi-sort-alphabetical-ascending',
                            value: 'Alpha (asc)',
                          },
                          {
                            icon: 'mdi-sort-calendar-descending',
                            value: 'Last Updated (desc)',
                          },
                          {
                            icon: 'mdi-sort-calendar-ascending',
                            value: 'Last Updated (asc)',
                          },
                        ]"
                        :key="field.value"
                        :value="field.value"
                      >
                        <v-list-item-icon>
                          <v-icon>{{ field.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ field.value }}</v-list-item-title>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </div>
          <v-card-text v-if="!parts.length" class="text-center">
            <h2 v-if="itemStatusIncluded.length === 0">
              No Flag Issues selected - Please select at least 1
            </h2>
            <h2 v-else>
              No "{{ partTypeSelected.type }}" Parts with Selected Flag Issues
            </h2>
          </v-card-text>
          <v-card-text v-else>
            <div v-for="p in currentPageItems" :key="'ip' + p.index">
              <v-row dense>
                <v-col cols="1" class="selectCol" v-if="!qualityMode">
                  <v-checkbox v-model="selectedRows" :value="p"> </v-checkbox>
                </v-col>
                <v-col
                  cols="8"
                  class="d-flex align-center flex-grow-1 flex-shrink-0"
                  style="max-width: 100%"
                >
                  <v-row dense>
                    <v-col
                      :cols="qualityMode ? 10 : 12"
                      v-for="(sp, isp) in p.subParts"
                      :key="'ip' + p.index + '_' + isp"
                      :class="{
                        part: p.attributes.length === 0,
                        ListItemRow: sp.type === 'listitem',
                        editing: p.editing,
                      }"
                      @mouseenter="p.hasfocus = true"
                      @mouseleave="p.hasfocus = false"
                    >
                      <v-icon
                        class="edit-icon"
                        v-if="
                          !qualityMode &&
                          !p.attributes.length &&
                          !p.editing &&
                          p.hasfocus
                        "
                        color="#ccc"
                        @click="openTextEdit(p)"
                      >
                        mdi-pencil-box-outline
                      </v-icon>
                      <v-row v-if="p.attributes.length" dense>
                        <v-col
                          v-for="(a, ai) in p.attributes"
                          :key="ai"
                          :cols="a.cols"
                          :class="{
                            part: true,
                            editing: a.editing,
                          }"
                        >
                          <div
                            v-if="!a.editing"
                            @click="editText(p, sp, a)"
                            class="d-flex"
                          >
                            <div
                              class="highlights"
                              v-html="applyReadOnlyHighlights(a.text, a)"
                            />
                          </div>
                          <div v-else class="texthighlight">
                            <TextHighlight
                              hideBorder
                              :padding="5"
                              :value="a.text"
                              :restrict_to_lookup="a.restrict_to_lookup"
                              :rank_attribute="a.rank_attribute"
                              :lookup="lookup"
                              :words="
                                getWordChecksForPart(
                                  tmpl_part_metadata.tmpl_part_id,
                                  a
                                )
                              "
                              :tmpl_part_metadata="tmpl_part_metadata"
                              :placeholder="a.placeholder"
                              :clearable="true"
                              @changed="saveInlineTextEdit($event, p, sp, a)"
                              @save="completeInlineTextEdit(p, sp, a)"
                              @initialised="a.editing = true"
                              @escape="completeInlineTextEdit(p, sp, a)"
                              :restrictedChars="getRestrictedCharsForPart(tmpl_part_metadata)"
                            ></TextHighlight>
                          </div>
                        </v-col>
                      </v-row>
                      <div
                        v-else-if="!p.editing"
                        @click="editText(p, sp)"
                        class="d-flex"
                      >
                        <v-icon x-small v-if="sp.type !== 'text'"
                          >mdi-circle</v-icon
                        >
                        <div
                          class="highlights"
                          v-html="applyReadOnlyHighlights(sp.text)"
                        />
                      </div>
                      <div v-else class="texthighlight">
                        <TextHighlight
                          hideBorder
                          :padding="5"
                          :value="sp.text"
                          :lookup="lookup"
                          :words="
                            getWordChecksForPart(
                              tmpl_part_metadata.tmpl_part_id
                            )
                          "
                          :tmpl_part_metadata="tmpl_part_metadata"
                          :placeholder="tmpl_part_metadata.placeholder"
                          :clearable="true"
                          @changed="saveInlineTextEdit($event, p, sp)"
                          @save="completeInlineTextEdit(p, sp)"
                          @initialised="p.editing = true"
                          @escape="completeInlineTextEdit(p, sp)"
                          :restrictedChars="getRestrictedCharsForPart(tmpl_part_metadata)"
                        ></TextHighlight>
                      </div>
                    </v-col>
                    <v-col cols="2" v-if="qualityMode">
                      <v-select
                        outlined
                        class="roundish"
                        :menu-props="{
                          'offset-y': true,
                        }"
                        :return-object="false"
                        :items="utils.qualities"
                        label="Reusable Content Usage"
                        item-text="text"
                        item-value="value"
                        v-model="p.quality"
                        hide-details
                        @change="checkDirty(p)"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="3" lg="2">
                  <v-row dense>
                    <v-col class="text-center">
                      <span color="blue darken-2">{{ p.WordCount }}</span>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col class="text-center">
                      <span
                        @click="p.showDocs = !p.showDocs"
                        style="cursor: pointer"
                      >
                        <v-icon v-if="p.showDocs">mdi-chevron-up</v-icon>
                        <v-icon v-else>mdi-chevron-down</v-icon>
                        {{ p.documentparts.length }} document{{
                          p.documentparts.length === 1 ? "" : "s"
                        }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col class="text-center">
                      Updated: {{ p.last_updated }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <template v-if="p.showDocs || p.rowDirty">
                <v-row dense v-if="p.showDocs">
                  <v-col cols="9" lg="10">
                    <v-slide-group multiple show-arrows>
                      <v-slide-item
                        v-for="(dp, dpi) in p.documentparts"
                        :key="'ip' + p.index + 'dp' + dpi"
                      >
                        <v-list-item
                          class="doc"
                          @mouseenter="dp.hover = true"
                          @mouseleave="dp.hover = false"
                        >
                          <v-list-item-content>
                            <v-list-item-title class="d-flex align-center">
                              <v-checkbox
                                :disabled="qualityMode"
                                dense
                                v-model="dp.selected"
                                :label="`${dp.system_number} - ${dp.doc_status_text}`"
                                hide-details
                              ></v-checkbox>
                              <v-spacer />
                              <v-btn
                                icon
                                x-small
                                v-show="dp.hover"
                                class="mt-1"
                                @click="openFullDocument(dp)"
                              >
                                <v-icon>mdi-file-document-edit-outline</v-icon>
                              </v-btn>
                            </v-list-item-title>
                            <v-list-item-subtitle>{{
                              dp.doc_name
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-slide-item>
                    </v-slide-group>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="9" lg="10" class="d-flex">
                    <v-btn
                      v-if="!qualityMode"
                      color="primary"
                      text
                      small
                      v-show="
                        p.showDocs && p.documentparts.some((dp) => !dp.selected)
                      "
                      @click="
                        p.documentparts.forEach((dp) => (dp.selected = true))
                      "
                      >Select All</v-btn
                    >
                    <v-btn
                      v-if="!qualityMode"
                      color="primary"
                      text
                      small
                      v-show="
                        p.showDocs && p.documentparts.some((dp) => dp.selected)
                      "
                      @click="
                        p.documentparts.forEach((dp) => (dp.selected = false))
                      "
                      >Clear Selection</v-btn
                    >
                    <v-spacer />
                    <v-btn
                      color="primary"
                      text
                      small
                      v-show="p.rowDirty"
                      @click="cancelEdit(p)"
                      >Cancel</v-btn
                    >
                    <v-btn
                      color="primary"
                      small
                      v-show="p.rowDirty"
                      :disabled="!p.documentparts.some((dp) => dp.selected)"
                      @click="saveRow([p])"
                      >Save</v-btn
                    >
                  </v-col>
                </v-row>
                <v-divider />
              </template>
            </div>
          </v-card-text>
          <v-row dense class="footer-actions primary-background">
            <v-col v-if="selectedRows.length" cols="4" class="body-2"
              >{{ selectedRows.length }} items selected</v-col
            >
            <v-col v-else cols="4">
              <PageDescription
                :totalItems="parts.length"
                :pageSize="itemsPerPage"
                :currentPage="currentPage"
              />
            </v-col>
            <v-col cols="4" class="d-flex justify-center">
              <Pagination
                :totalItems="parts.length"
                :pageSize="itemsPerPage"
                :currentPage="currentPage"
                @pageNavigation="currentPage = $event"
              />
            </v-col>
            <v-col
              v-if="selectedRows.length"
              cols="4"
              class="d-flex justify-end"
            >
              <v-btn
                outlined
                small
                color="primary"
                class="mx-2"
                @click="compareValues"
              >
                <span>Compare Items</span>
              </v-btn>
              <v-btn
                outlined
                small
                color="primary"
                class="mx-2"
                @click="openFindReplace"
              >
                <span>Find &amp; Replace</span>
              </v-btn>
              <v-btn
                v-if="selectedRows.every(r => r.canAdd)"
                small
                color="error"
                outlined
                class="mx-2"
                @click="openDeleteDialogue"
              >
                <span>Delete Selected</span>
              </v-btn>
              <v-btn
                small
                color="primary"
                class="mx-2"
                @click="openEditSelected"
              >
                <span>Edit Selected</span>
              </v-btn>
            </v-col>
            <v-col v-else cols="4" class="d-flex justify-end">
              <v-btn
                color="primary"
                small
                outlined
                class="mx-2"
                @click="exportToCSV"
              >
                <span>Export</span>
              </v-btn>
              <v-menu offset-y z-index="301">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="!$loginState.readOnly"
                    outlined
                    small
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ itemsPerPageText }}
                    <v-icon right>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>

                <v-list dense>
                  <v-list-item
                    v-for="item in itemsPerPageOptions"
                    :key="item.value"
                    @click="itemsPerPage = item.value"
                  >
                    <v-list-item-title>
                      {{ item.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </div>
    <ResponseHandler :serviceResponse="response"></ResponseHandler>
    <TextEdit
      :open="textEdit.show"
      @saved="saveTextEdit"
      @closed="textEdit.show = false"
      :settings="textEdit"
    ></TextEdit>
    <v-dialog v-model="compareDialog" fullscreen scrollable>
      <v-card class="contentManagerCompare">
        <v-card-title class="d-flex justify-space-between"> </v-card-title>
        <v-card-text>
          <v-row dense style="padding-top: 5px">
            <v-col cols="3">
              <v-select
                label="Compare Against"
                @change="changeCompare"
                v-model="compareMaster"
                dense
                outlined
                :items="compareItems"
                item-value="original"
                item-text="original"
                return-object
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.idx }} - {{ data.item.last_updated }}
                </template>
                <template slot="item" slot-scope="data">
                  {{ data.item.idx }} - {{ data.item.last_updated }}
                </template>
              </v-select>
            </v-col>
            <v-col cols="3">
              <v-select
                dense
                outlined
                :items="diffModes"
                item-text="text"
                item-value="value"
                v-model="diffType"
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-btn @click="doCompare">Compare</v-btn>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <h2>Comparing Rows to:</h2>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="1">ID</v-col>
            <v-col cols="1">Last Updated</v-col>
            <v-col cols="5">Original Text</v-col>
            <v-col cols="5">Comparison Result</v-col>
          </v-row>
          <v-row dense>
            <v-col cols="1">
              {{ compareMaster.idx }}
            </v-col>
            <v-col cols="1">
              {{ compareMaster.last_updated }}
            </v-col>
            <v-col cols="5" style="display: inline-flex">
              {{ compareMaster.original }}
            </v-col>
            <v-col cols="5">
              <span>
                <v-btn @click="copyToClipboard(compareMaster.original)"
                  >Copy To Clipboard</v-btn
                >
              </span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <h2>Comparison Results:</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1">ID</v-col>
            <v-col cols="1">Last Updated</v-col>
            <v-col cols="5">Original Text</v-col>
            <v-col cols="5">Comparison Result</v-col>
          </v-row>
          <v-row dense v-for="(item, idx) in activeCompare" :key="idx">
            <v-col cols="1">
              {{ item.idx }}
            </v-col>
            <v-col cols="1">
              {{ item.last_updated }}
            </v-col>
            <v-col cols="5" style="display: inline-flex">
              {{ item.original }}
            </v-col>
            <v-col cols="5">
              <span
                v-if="item.original !== compareMaster.original"
                v-html="item.compareHtml"
              ></span>
              <span v-else>
                <v-btn @click="copyToClipboard(compareMaster.original)"
                  >Copy To Clipboard</v-btn
                >
              </span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="compareDialog = false">
            <span>Close</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editDialog.open" max-width="1200">
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <span class="subtitle-1">
            {{ editDialog.mode }}
          </span>
          <v-btn
            icon
            large
            class="btn-background"
            @click="editDialog.open = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="editDialog.open" style="min-height: 150px">
          <v-row v-if="editDialog.mode == 'Update'">
            <v-col cols="9" class="pl-2">
              <div
                v-for="(sp, isp) in editDialog.subParts"
                :key="isp"
                :class="{
                  part: editDialog.attributes.length === 0,
                  dialogTextHighlight: true,
                  ListItemRow: sp.type === 'listitem',
                  editing: editDialog.editing,
                }"
              >
                <v-icon
                  class="edit-icon"
                  v-if="!editDialog.attributes.length && !editDialog.editing"
                  color="#ccc"
                  @click="openTextEdit(editDialog)"
                >
                  mdi-pencil-box-outline
                </v-icon>
                <v-row v-if="editDialog.attributes.length" dense>
                  <v-col
                    v-for="a in editDialog.attributes"
                    :key="a.tpa_id"
                    :cols="a.cols"
                    :class="{
                      part: true,
                      dialogTextHighlight: true,
                      editing: a.editing,
                    }"
                  >
                    <div
                      v-if="!a.editing"
                      @click="editText(editDialog, sp, a)"
                      class="d-flex"
                    >
                      <div
                        class="highlights"
                        v-html="applyReadOnlyHighlights(a.text, a)"
                      />
                    </div>
                    <div v-else class="texthighlight">
                      <TextHighlight
                        hideBorder
                        :padding="5"
                        :value="a.text"
                        :restrict_to_lookup="a.restrict_to_lookup"
                        :rank_attribute="a.rank_attribute"
                        :lookup="lookup"
                        :words="
                          getWordChecksForPart(tmpl_part_metadata.tmpl_part_id)
                        "
                        :tmpl_part_metadata="tmpl_part_metadata"
                        :placeholder="a.placeholder"
                        :clearable="true"
                        @changed="saveDialogTextEdit($event, a)"
                        @save="
                          completeDialogTextEdit(a);
                          a.editing = false;
                        "
                        @escape="
                          completeDialogTextEdit(a);
                          a.editing = false;
                        "
                        :restrictedChars="getRestrictedCharsForPart(tmpl_part_metadata)"
                      ></TextHighlight>
                    </div>
                  </v-col>
                </v-row>
                <div
                  v-else-if="!editDialog.editing"
                  @click="editText(editDialog, sp)"
                  class="d-flex"
                >
                  <v-icon x-small v-if="sp.type === 'listitem'"
                    >mdi-circle</v-icon
                  >
                  <div
                    class="highlights"
                    v-html="applyReadOnlyHighlights(sp.text)"
                  />
                </div>
                <div v-else class="texthighlight">
                  <TextHighlight
                    hideBorder
                    :padding="5"
                    :value="sp.text"
                    :lookup="lookup"
                    :words="
                      getWordChecksForPart(tmpl_part_metadata.tmpl_part_id)
                    "
                    :tmpl_part_metadata="tmpl_part_metadata"
                    :placeholder="tmpl_part_metadata.placeholder"
                    :clearable="true"
                    @changed="saveDialogTextEdit($event)"
                    @save="
                      completeDialogTextEdit();
                      editDialog.editing = false;
                    "
                    @escape="
                      completeDialogTextEdit();
                      editDialog.editing = false;
                    "
                    :restrictedChars="getRestrictedCharsForPart(tmpl_part_metadata)"
                  ></TextHighlight>
                </div>
              </div>
            </v-col>
            <v-col cols="3" class="d-flex align-center justify-center">
              <div class="text-center">
                {{ editDialog.WordCount }}
                <br />
                {{ editDialog.Status }}
              </div>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="6">
              <v-text-field
                v-model="editDialog.selectedText"
                label="Selected Text"
                outlined
                class="roundish"
                :hint="editDialog.hint"
                persistent-hint
                :rules="[editDialog.rules.required]"
                @change="loadFindReplaceAlternatives"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-combobox
                @update:search-input="editDialog.replacementText = $event"
                :items="editDialog.alternatives"
                label="Alternative"
                outlined
                class="roundish"
                :menu-props="{ class: 'roundish' }"
              ></v-combobox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="mx-6" />
        <v-card-actions class="mx-4 py-8">
          <span class="body-2"
            >{{ editDialog.totalDocs }} Documents Selected</span
          >
          <v-spacer />
          <v-btn
            v-if="editDialog.mode == 'Update'"
            color="primarylight"
            large
            class="px-6 roundish"
            @click="updateAll"
          >
            <span>Update All</span>
          </v-btn>
          <v-btn
            v-else
            color="primarylight"
            large
            class="px-6 roundish"
            @click="findReplaceAll"
          >
            <span>Replace All</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog.open" max-width="800">
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <span class="subtitle-1">
            Delete
          </span>
          <v-btn
            icon
            large
            class="btn-background"
            @click="deleteDialog.open = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="deleteDialog.open">
          <h3>Remove {{ utils.pluralise(partTypeSelected.type) }} from <b>{{ deleteDialog.totalDocs }}</b> documents?</h3>
        </v-card-text>
        <v-divider class="mx-6" />
        <v-card-actions class="mx-4 py-8">
          <span class="body-2"
            >{{ deleteDialog.totalDocs }} Documents Selected</span
          >
          <v-spacer />
          <v-btn
            color="error"
            large
            class="px-6 roundish"
            @click="confirmDelete"
          >
            <span>Confirm Delete</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Loading :isVisible="isLoading || isDocDataLoading" />
    <v-snackbar
      v-model="showErrorSnack"
      :timeout="snackTimeout"
      :color="snackColor"
      :multi-line="errorText.length > 50"
      top
    >
      {{ errorText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
//import draggable from "vuedraggable";
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
import TextEdit from "@/components/cTextEditDialogue"; // @ is an alias to /src
import utils from "@/common/utils.js";
import HierarchySearch from "@/components/cHierarchySearch";
import Pagination from "@/components/cPagination";
import PageDescription from "@/components/cPageDescription";
import TextHighlight from "./cTextHighlight.vue";
import { mapState } from "vuex";
var JsDiff = require("diff");
import moment from "moment";

export default {
  name: "ContentManager",
  components: {
    ResponseHandler,
    TextEdit,
    HierarchySearch,
    Pagination,
    PageDescription,
    TextHighlight,
  },
  props: {},
  data: function () {
    return {
      response: null,
      partTypeSelected: null,
      initialDataFetched: false,
      items: [],
      parts: [],
      cleanItems: [],
      isDirty: false,
      dirtyCount: 0,
      isLoading: false,
      showHierarchies: false,
      docStatusIncluded: [],
      selectedDocType: null,
      allDocTypes: [],
      itemStatusSummary: [],
      itemStatusIncluded: [],
      itemsGroups: [],
      itemsGroupsIncluded: [],
      tempFilterText: "",
      filterText: "",
      textEdit: {
        text: "",
        notes: "",
        label: "text",
        show: false,
        showWordCount: true,
        showNotes: false,
        title: "",
        wordChecks: [],
        //closed: false
      },
      wordChecks: [],
      editInProgress: {
        text: "",
        row: null,
        subParts: null,
        attribute: null,
      },
      dragging: false,
      docParts: [],
      partIdx: 0,
      //hierarchyTypes: [],
      hierarchyFilters: [],
      classifierFilters: [],
      utils: utils,
      sortBy: "Word Count (desc)",
      tmpl_part_metadata: null,
      activeTab: null,
      itemsPerPage: 10,
      itemsPerPageOptions: [
        { text: "5 Rows", value: 5 },
        { text: "10 Rows", value: 10 },
        { text: "20 Rows", value: 20 },
        { text: "40 Rows", value: 40 },
        { text: "All", value: -1 },
      ],
      currentPage: 1,
      showSearch: false,
      sectionSearch: null,
      sectionMenu: false,
      useInline: true,
      lookup: [],
      cachedLookups: [],
      selectedRows: [],
      editDialog: {
        mode: "Update",
        open: false,
        editing: false,
        tab: 0,
        part: {},
        text: "",
        attributes: [],
        subParts: [],
        WordCount: 0,
        Status: "OK",
        selectedText: "",
        replacementText: "",
        totalDocs: 0,
        rules: {
          required: (value) => !!value || "Required.",
        },
        hint: "",
        alternatives: [],
      },
      deleteDialog: {
        open: false,
        totalDocs: 0,
      },
      compareDialog: false,
      compareItems: [],
      compareMaster: {},
      diffModes: [
        { text: "Chars", value: "diffChars" },
        { text: "Words", value: "diffWords" },
      ],
      diffType: "diffWords",
      errorText: "",
      showErrorSnack: false,
      snackColor: "error",
      snackTimeout: 4000,
      qualityMode: false,
      qualityModeSelected: "content",
      passedParams: {}
    };
  },
  created() {
    this.passedParams = this.$passedParams.get();
    this.fetchData();
  },
  watch: {
    docTypes(val) {
      if (
        val &&
        val.length &&
        !this.selectedDocType &&
        !this.initialDataFetched
      ) {
        this.selectedDocType = val.find(dt => this.passedParams.docTypes.includes(dt.docType)) || val[0];
        this.filterDocType();
      }
    },
    sortBy() {
      this.doSort();
    },
  },
  computed: {
    activeCompare() {
      return this.compareItems.filter((x) => x.idx !== this.compareMaster.idx);
    },
    selectAll: {
      // getter
      get: function () {
        return this.selectedRows.length == this.parts.length;
      },
      // setter
      set: function (newValue) {
        this.selectedRows.splice(0);
        if (newValue) this.selectedRows.push(...this.parts);
      },
    },
    currentPageItems() {
      if (this.itemsPerPage < 0) return this.parts;

      const pageFrom = this.itemsPerPage * (this.currentPage - 1);
      const pageTo = this.itemsPerPage * this.currentPage;

      let sortedParts = this.parts;

      return sortedParts.slice(pageFrom, pageTo);
    },
    itemsPerPageText() {
      const option = this.itemsPerPageOptions.find(
        (o) => o.value == this.itemsPerPage
      );
      return option ? option.text : `$(itemsPerPage) Items`;
    },
    docTypes() {
      return this.allDocTypes.filter((dt) =>
        this.activeDocTypes.some((adt) => adt.docType === dt.docType)
      );
    },
    docStatusSummary() {
      return this.activeDocStatuses.filter(
        (s) =>
          s.status.toLowerCase() !== "deleted" &&
          s.status.toLowerCase() !== "archived"
      );
    },
    selectedPartWordChecks() {
      return this.getWordChecksForPart(this.partTypeSelected.tmpl_part_id)
    },
    ...mapState({
      isDocDataLoading: (state) => state.docs.loading,
      docsList: (state) => state.docs.docsList,
      hierarchyTypes: (state) => state.docs.hierarchyTypes,
      classifierTypes: (state) => state.docs.classifierTypes,
      activeDocStatuses: (state) => state.docs.docStatusSummary,
      activeDocTypes: (state) => state.docs.docTypeSummary,
    }),
  },
  methods: {
    getRestrictedCharsForPart(pt){
      return pt.exclusions ? pt.exclusions.restrict_chars : [];
    },
    changeCompare() {
      this.compareItems.forEach((x) => {
        x.compareHtml = "";
      });
    },
    copyToClipboard(textValue) {
      let container;
      let dialog = document.getElementsByClassName(
        "v-dialog--active v-dialog--fullscreen"
      );
      if (dialog && dialog.length) container = dialog[0];
      else container = document.body;

      const textarea = document.createElement("textarea");
      textarea.innerHTML = textValue;
      textarea.value = textarea.innerText;
      textarea.setAttribute("readonly", "");
      textarea.style.position = "absolute";
      textarea.style.left = "-9999px";
      container.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      container.removeChild(textarea);
      this.triggerNotification("Text copied to Clipboard", "success");
    },
    runCompare(source, compare) {
      const diff = JsDiff[this.diffType](source, compare, {
        ignoreWhitespace: false,
      });
      let returnString = "";
      diff.forEach((part) => {
        const color = part.added ? "green" : part.removed ? "red" : "grey";
        let fragment = "";
        switch (color) {
          case "green":
            fragment = `<ins>${part.value}</ins>`;
            break;
          case "red":
            fragment = `<del>${part.value}</del>`;
            break;
          case "grey":
            fragment = `${part.value}`;
            break;
        }
        returnString = returnString += fragment;
      });

      return returnString;
    },
    doCompare() {
      this.compareItems.forEach((x) => {
        x.compareHtml = "";
      });
      let master = this.compareItems.find(
        (x) => x.original === this.compareMaster.original
      );
      this.compareItems
        .filter((x) => x.original !== this.compareMaster.original)
        .forEach((x) => {
          let result = this.runCompare(master.original, x.original);
          x.compareHtml = result;
        });
    },
    compareValues() {
      this.compareItems = this.selectedRows
        .sort(function (a, b) {
          return new Date(b.last_updated) - new Date(a.last_updated);
        })
        .map((x, idx) => {
          return {
            idx: idx,
            original: x.text,
            last_updated: moment(
              Math.max(
                ...x.documentparts.map((element) => {
                  return new Date(element.updated_date);
                })
              )
            ).format("DD MMM YYYY HH:mm:ss"),
            compareHtml: "",
          };
        });
      this.compareMaster = this.compareItems[0];
      this.compareDialog = true;
    },
    triggerNotification(text, type) {
      this.errorText = text;
      this.snackColor = type;
      this.showErrorSnack = true;
    },
    fetchData() {
      this.isLoading = true;

      axios
        .get("document/getTemplatesWithParts")
        .then((resp) => {
          if (resp.data.Status === "OK") {
            const templateParts = resp.data.Data;
            templateParts.forEach((t) => {
              t.parttypes.forEach((pt) => {
                if (pt.group) {
                  let docType = this.allDocTypes.find(
                    (dt) => dt.docType == t.doc_type
                  );
                  if (!docType) {
                    docType = {
                      tmpl_id: t.tmpl_id,
                      docType: t.doc_type,
                      groups: [],
                    };
                    this.allDocTypes.push(docType);
                  }
                  let pg = docType.groups.find((s) => s.partGroup === pt.group);
                  if (!pg) {
                    pg = {
                      partGroup: pt.group,
                      show: true,
                      active: false,
                      sections: [],
                    };
                    switch (pt.group) {
                      case "Other":
                        pg.icon = "mdi-page-layout-footer";
                        break;
                      case "Header":
                        pg.icon = "mdi-page-layout-header";
                        break;
                      case "Branded Content":
                        pg.icon = "mdi-book-open-blank-variant";
                        break;
                      case "Main":
                      default:
                        pg.icon = "mdi-page-layout-body";
                        break;
                    }
                    docType.groups.push(pg);
                  }

                  pg.sections.push(pt);
                }
              });
            });

            this.allDocTypes.forEach((dt) => {
              const main =
                dt.groups.find((s) => s.partGroup === "Main") || dt.groups[0];
              if (main && main.sections.length) {
                main.active = true;
                dt.groups.splice(dt.groups.indexOf(main), 1);
                dt.groups.unshift(main);
              }
            });
          }

          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
        });
    },
    fetchSectionData() {
      this.isLoading = true;

      const id = this.partTypeSelected.tmpl_part_id;

      if (!id) {
        this.isLoading = false;
        return;
      }

      axios
        .get(`document/partsWithDocsForSection/${id}`)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            let partIdx = 0;
            this.cleanItems = resp.data.Data.documentParts.map((x) => {
              let n = JSON.parse(JSON.stringify(x));

              n.tmpl_part_metadata.cardinality = utils.getPartTypeMinMax(
                this.selectedPartWordChecks,
                null,
                n.tmpl_part_metadata
              );

              n.parts.forEach((r) => {
                r.index = partIdx++;
                r.rowDirty = false;
                r.showDocs = false;
                r.editing = false;
                r.hasfocus = false;
                r.rowMessage = "";
                if (r.attributes.length) {
                  r.text = utils.attributesToText(
                    x.tmpl_part_metadata.attributes,
                    r.attributes
                  );
                  r.attributes.forEach((a) => {
                    const ameta =
                      n.tmpl_part_metadata.attributes.find(
                        (am) => am.tpa_id === a.tpa_id
                      ) || {};
                    a.editing = false;
                    (a.isDirty = false), (a.origText = a.text);
                    a.cols = ameta.cols;
                    a.placeholder = ameta.placeholder;
                    a.restrict_to_lookup = ameta.restrict_to_lookup;
                    a.rank_attribute = ameta.rank_attribute;
                    a.skip_checks = ameta.skip_checks;
                  });
                }
                r.origText = r.text;
                utils
                  .setDocPartStatus(r, n.tmpl_part_metadata, this.selectedPartWordChecks)
                  .forEach((rs) => {
                    let ss = this.itemStatusSummary.find(
                      (s) => s.status === rs
                    );
                    if (!ss) {
                      ss = { status: rs, count: 0, show: true };
                      this.itemStatusSummary.push(ss);
                      if (ss.status !== "OK") this.itemStatusIncluded.push(ss);
                    }
                    ss.count++;
                  });
                r.documentparts = r.documentparts
                  .map((dp) => {
                    dp.selected = true;
                    dp.hover = false;
                    const doc =
                      this.docsList.find((d) => d.doc_id == dp.doc_id) || {};
                    return {
                      ...dp,
                      system_number: doc.system_number,
                      doc_name: doc.doc_name,
                      hierarchies: doc.hierarchies,
                      classifiers: doc.classifiers,
                      doc_status: doc.doc_status,
                      doc_status_text: doc.doc_status_text,
                    };
                  })
                  .filter((dp) => dp.system_number);
              });

              n.parts = n.parts.filter((p) => p.documentparts.length);

              return n;
            });

            if (!this.docStatusIncluded.length) {
              this.docStatusIncluded.push(
                this.docStatusSummary.find(
                  (s) => s.status.toLowerCase() === "pending"
                ) || this.docStatusSummary[0]
              );
            }
            this.itemStatusIncluded = this.itemStatusSummary.filter((ss) =>
              this.itemStatusIncluded.includes(ss)
            );
            if (!this.itemStatusIncluded.length) {
              this.itemStatusIncluded = [...this.itemStatusSummary];
            }

            this.partIdx = partIdx;
            if (this.cleanItems.length) {
              this.partTypeSelected = this.cleanItems[0];
              this.tmpl_part_metadata = this.cleanItems[0].tmpl_part_metadata;
            }
            this.loadDefaultSettings();

            this.doFilter();
          }

          this.initialDataFetched = true;
          this.isLoading = false;
          this.response = resp.data;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401)
            this.$emit("sessionExpired", err);
          console.log(err);
          this.initialDataFetched = true;
          this.isLoading = false;
        });
    },
    loadDefaultSettings() {
      let setting = this.$loginState.user.settings.find(
        (s) => s.setting === "content_manager_flag_order"
      );
      if (setting !== undefined) {
        //let newList = [];
        let config = JSON.parse(setting.value);

        this.itemStatusSummary.forEach((s) => {
          let current = config.find(
            (x) => x.status.toLowerCase() === s.status.toLowerCase()
          );
          if (current) {
            s.order = current.order;
          } else {
            s.order = this.itemStatusSummary.length;
          }
        });

        this.itemStatusSummary.sort((a, b) => (a.order > b.order && 1) || -1);

        let defaults = config.filter((x) => x.selected);
        let defaultList = [];
        defaults.forEach((d) => {
          let item = this.itemStatusIncluded.find(
            (x) => x.status.toLowerCase() === d.status.toLowerCase()
          );
          if (item) {
            defaultList.push(item);
          }
          this.itemStatusIncluded = [...defaultList];
        });
      }
    },
    doSort() {
      switch (this.sortBy) {
        case "Last Updated (desc)":
          this.parts = this.parts.sort(function (a, b) {
            return new Date(b.last_updated) - new Date(a.last_updated);
          });
          break;
        case "Last Updated (asc)":
          this.parts = this.parts.sort(function (a, b) {
            return new Date(a.last_updated) - new Date(b.last_updated);
          });
          break;
        case "Word Count (desc)":
          this.parts = this.parts.sort((a, b) =>
            a.word_count < b.word_count
              ? 1
              : a.word_count > b.word_count
              ? -1
              : 0
          );
          break;
        case "Word Count (asc)":
          this.parts = this.parts.sort((a, b) =>
            a.word_count > b.word_count
              ? 1
              : a.word_count < b.word_count
              ? -1
              : 0
          );
          break;
        case "Qualiity (asc)":
          this.parts = this.parts.sort((a, b) =>
            a.quality > b.quality ? 1 : a.quality < b.quality ? -1 : 0
          );
          break;
        case "Qualiity (desc)":
          this.parts = this.parts.sort((a, b) =>
            a.quality < b.quality ? 1 : a.quality > b.quality ? -1 : 0
          );
          break;
        case "Alpha (desc)":
          this.parts = this.parts.sort((a, b) =>
            a.text < b.text ? 1 : a.text > b.text ? -1 : 0
          );
          break;
        case "Alpha (asc)":
        default:
          this.parts = this.parts.sort((a, b) =>
            a.text > b.text ? 1 : a.text < b.text ? -1 : 0
          );
      }
    },
    changefilterText(val) {
      this.tempFilterText = val;
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = null;
      }

      this.searchTimeout = setTimeout(() => this.triggerFilter(), 200);
    },
    showFilterField() {
      this.showSearch = true;
      this.$nextTick(() => {
        if (this.$refs.filterField) this.$refs.filterField.$refs.input.focus();
      });
    },
    clearFilter() {
      this.tempFilterText = "";
      this.triggerFilter();
      this.showSearch = false;
    },
    sectionFilter(item, queryText) {
      if (item.header) return false;

      const text = item ? item.partGroup + item.docType + item.type : "";
      const query = queryText || "";

      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1
      );
    },
    triggerFilter() {
      this.filterText = this.tempFilterText;
      this.doFilter();
    },
    filterDocType() {
      if (this.selectedDocType) {
        this.isLoading = true;
        this.$store
          .dispatch(
            "wordChecks/getTemplateWordChecks",
            this.selectedDocType.tmpl_id
          )
          .then((resp) => {
            this.wordChecks = resp;
            let activeSection;

            if (this.passedParams.section) {
              this.selectedDocType.groups
                .forEach(g => {
                  if (!activeSection) {
                    activeSection = g.sections.find(s => s.type === this.passedParams.section);
                    g.active = !!activeSection;
                  }
                });
            }

            if (!activeSection) {
              const group = this.selectedDocType.groups.find((g) => g.active);
              if (group && group.sections.length)
                activeSection = group.sections[0];
            }

            if (activeSection)
              this.filterPartType(activeSection);
          });
      }
    },
    filterPartType(item) {
      if (!item) return;
      this.partTypeSelected = item;
      this.sectionMenu = false;
      this.fetchSectionData();
      //this.doFilter();
    },
    doHierarchyFilter(hierarchyFilters, classifierFilters) {
      this.hierarchyFilters = hierarchyFilters || [];
      this.classifierFilters = classifierFilters || [];
      this.doFilter();
    },
    doFilter() {
      if (!this.partTypeSelected) {
        this.parts = [];
        return;
      }

      this.selectedRows.splice(0);
      this.currentPage = 1;

      let items = JSON.parse(JSON.stringify(this.cleanItems));

      let hyf = this.hierarchyFilters.filter((f) => f.selected.length);
      if (!this.qualityMode && hyf.length) {
        items.forEach((pt) => {
          pt.parts.forEach((p) => {
            hyf.forEach((hf) => {
              p.documentparts = p.documentparts.filter((dp) =>
                hf.selected.find((s) => {
                  let ht = dp.hierarchies.find((ht) => ht.ht_id === hf.ht_id);
                  return ht && s.id.split("_")[0] == ht.hr_id; // && !s.children
                })
              );
            });
          });
          pt.parts = pt.parts.filter((x) => x.documentparts.length > 0);
        });
      }

      const cf = this.classifierFilters.filter((f) => f.selected.length);
      if (!this.qualityMode && cf.length) {
        items.forEach((pt) => {
          pt.parts.forEach((p) => {
            cf.forEach((cf) => {
              const cvs = cf.selected.map((c) => c.cv_id);
              p.documentparts = p.documentparts.filter(
                (d) =>
                  d.classifiers.some((c) => cvs.includes(c.cv_id)) ||
                  (cvs.includes(0) &&
                    !d.classifiers.some((c) => c.ct_id === cf.ct_id))
              );
            });
          });
          pt.parts = pt.parts.filter((x) => x.documentparts.length > 0);
        });
      }

      if (
        !this.qualityMode &&
        this.itemStatusSummary.length > this.itemStatusIncluded.length
      ) {
        items.forEach((pt) => {
          pt.parts = pt.parts.filter((x) =>
            x.StatusList.some((s) =>
              this.itemStatusIncluded.some((si) => si.status === s)
            )
          );
        });
      }
      if (
        !this.qualityMode &&
        this.docStatusSummary.length > this.docStatusIncluded.length
      ) {
        items.forEach((pt) => {
          pt.parts.forEach((p) => {
            p.documentparts = p.documentparts.filter((dp) =>
              this.docStatusIncluded.find(
                (s) => s.status === dp.doc_status_text
              )
            );
          });
          pt.parts = pt.parts.filter((x) => x.documentparts.length > 0);
        });
      }

      if (this.qualityMode) {
        items.forEach((pt) => {
          pt.parts.forEach((p) => {
            p.documentparts = p.documentparts.filter(
              (dp) => dp.doc_status === "APPROVED"
            );
          });
          pt.parts = pt.parts.filter((x) => x.documentparts.length > 0);
        });
      }

      const filterText = (this.filterText || "").trim().toLowerCase();
      if (filterText) {
        let search = filterText
          .split(" ")
          .filter((x) => x.length)
          .map((x) => x);
        items.forEach((pt) => {
          pt.parts = pt.parts.filter((x) =>
            search.every((s) => x.text.toLowerCase().indexOf(s, 0) >= 0)
          );
          pt.parts.forEach((p) => {
            search.forEach((s) => {
              p.text = p.text.replace(
                new RegExp(s, "gi"),
                (match) => `<mark>${match}</mark>`
              );
            });
          });
        });
      }
      this.docParts = items.flatMap((i) =>
        i.parts.flatMap((p) => p.documentparts)
      );

      this.items = items;
      let pt = items.find(
        (x) => x.tmpl_part_id === this.partTypeSelected.tmpl_part_id
      );
      this.parts = pt ? pt.parts : [];
      this.doSort();
    },
    getCleanParent(parent) {
      return this.cleanItems.find(
        (x) => x.system_number === parent.system_number
      );
    },
    getCleanRow(row, parent) {
      return this.getCleanParent(parent).parts.find((x) => x.id === row.id);
    },
    saveRow(rowsAffected, callback) {
      if (rowsAffected.length == 0) return;

      //const origText = utils.removeTags(row.origText);
      const newText = utils.subpartsToPart(rowsAffected[0].subParts);
      const allDocParts = rowsAffected.flatMap((rows) => rows.documentparts);
      const affectedDocParts = allDocParts.filter((dp) => dp.selected);

      let codeerror = false;
      let data = {
        text: newText,
        attributes: rowsAffected[0].attributes
          .filter((a) => a.isDirty)
          .map((a) => {
            return {
              tpa_id: a.tpa_id,
              text: a.text,
            };
          }),
        documentparts: affectedDocParts.map((dp) => {
          return {
            doc_part_id: dp.doc_part_id,
            attributes: dp.attributes,
          };
        }),
      };
      if (this.qualityMode) {
        data.quality = rowsAffected[0].quality;
      }

      if (!data.documentparts.length) {
        this.response = { Status: "Error", Message: "No documents selected!" };
        return;
      }

      this.isLoading = true;
      axios
        .post("document/savepartdocs/", data)
        .then((resp) => {
          codeerror = true;
          if (resp.data.Status === "OK") {
            if (!this.qualityMode) {
              let newRow = this.partTypeSelected.parts.find(
                (p) => p.text === newText
              );

              if (!newRow) {
                newRow = JSON.parse(JSON.stringify(rowsAffected[0]));
                newRow.index = this.partIdx++;
                newRow.documentparts = [];
                this.partTypeSelected.parts.push(newRow);
              }

              newRow.documentparts =
                newRow.documentparts.concat(affectedDocParts);
              newRow.documentparts.forEach((dp) => (dp.selected = true));
              newRow.origText = newText;
              newRow.text = newText;
              newRow.rowDirty = false;
              newRow.showDocs = false;
              newRow.attributes.forEach((a) => {
                a.origText = utils.removeTags(a.text);
                a.isDirty = false;
              });
              utils.setDocPartStatus(
                newRow,
                this.tmpl_part_metadata,
                this.selectedPartWordChecks
              );

              rowsAffected.forEach((row) => {
                const origText = utils.removeTags(row.origText);
                const origRow = this.partTypeSelected.parts.find(
                  (p) => p.text === origText
                );
                origRow.documentparts = row.documentparts.filter(
                  (dp) => !dp.selected
                );
                if (origRow.documentparts.length === 0) {
                  this.partTypeSelected.parts.splice(
                    this.partTypeSelected.parts.indexOf(origRow),
                    1
                  );
                } else {
                  origRow.documentparts.forEach((dp) => (dp.selected = true));
                  origRow.origText = origText;
                  origRow.text = origText;
                  origRow.rowDirty = false;
                  origRow.showDocs = false;
                  origRow.attributes.forEach((a) => {
                    a.text = a.origText;
                    a.isDirty = false;
                  });
                  utils.setDocPartStatus(
                    origRow,
                    this.tmpl_part_metadata,
                    this.selectedPartWordChecks
                  );
                }
              });

              this.doFilter();
            } else {
              rowsAffected[0].rowDirty = false;
            }
          } else {
            rowsAffected.forEach((row) => (row.rowMessage = resp.data.Message));
          }
          console.log(resp);
          if (callback && typeof callback == "function") callback();
          else {
            this.response = resp.data;
            this.isLoading = false;
          }
          this.editInProgress.row = null;
          this.editInProgress.text = "";
        })
        .catch((err) => {
          if (!codeerror) this.$emit("sessionExpired", err);
          this.isLoading = false;
          rowsAffected.forEach((row) => (row.rowMessage = err));
          console.log(err);
        });
    },
    countWordsText(data) {
      return this.countWords(data) + " words";
    },
    countWords(data) {
      return data.split(" ").filter((x) => x.length).length;
    },
    editText(row, subPart, attr) {
      if (this.qualityMode) return;
      row.attributes.forEach((a) => (a.editing = false));
      this.currentPageItems.forEach((r) => {
        r.editing = false;
        r.attributes.forEach((a) => (a.editing = false));
      });

      if (this.useInline && row.subParts.length === 1) {
        this.editInProgress.active = true;
        this.editInProgress.row = row;
        this.editInProgress.subParts = row.subParts;
        if (attr) {
          this.editInProgress.text = attr.text;
          this.editInProgress.attribute = attr;
          attr.editing = true;
          if (!attr.text || attr.restrict_to_lookup)
            this.prepEditPartLookup(
              this.partTypeSelected.tmpl_part_id,
              true,
              attr.tpa_id
            );
        } else {
          this.editInProgress.text = utils.subpartsToPart(row.subParts);
          row.editing = true;
          if (!row.text || this.tmpl_part_metadata.restrict_to_lookup) {
            this.prepEditPartLookup(this.partTypeSelected.tmpl_part_id, true);
          }
        }
      } else {
        this.openTextEdit(row);
      }
    },
    openTextEdit(row) {
      this.textEdit.text = row.text;
      this.textEdit.subParts = row.subParts;
      this.textEdit.row = row;
      this.textEdit.title = this.partTypeSelected.type;
      this.textEdit.rules = this.tmpl_part_metadata;
      this.textEdit.rules.tp_locked = false;
      this.textEdit.wordChecks = this.getWordChecksForPart(
        this.partTypeSelected.tmpl_part_id
      );
      this.prepEditPartLookup(this.partTypeSelected.tmpl_part_id);
    },
    getWordChecksForPart(tpid, attr) {
      let results = this.wordChecks.filter((w) =>
        w.tmpl_part_types.some((tp) => tp.tp_id === tpid)
      );

      if (attr && attr.skip_checks.length > 0) {
        results = results.filter(x => !attr.skip_checks.includes(x.check_type_label))
      }

      return results;
    },
    prepEditPartLookup(tmpl_part_id, isLocal, attr_id) {
      let existing = this.cachedLookups.find(
        (l) =>
          l.tmpl_part_id === tmpl_part_id &&
          (attr_id ? l.attr_id === attr_id : true)
      );
      if (existing) {
        if (isLocal) {
          this.lookup = existing.data;
        } else {
          this.textEdit.lookup = existing.data;
          this.textEdit.show = true;
        }
      } else {
        this.isLoading = true;
        axios
          .get(
            "document/getTemplatePartValues/" +
              `${tmpl_part_id}_${attr_id ? attr_id : ""}`
          )
          .then((resp) => {
            if (isLocal) {
              this.lookup = resp.data.Data;
            } else {
              this.textEdit.lookup = resp.data.Data;
              this.textEdit.show = true;
            }
            this.cachedLookups.push({
              tmpl_part_id: tmpl_part_id,
              attr_id: attr_id,
              data: resp.data.Data,
            });
            this.isLoading = false;
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              console.log(err);
              this.response = err.response
                ? err.response.data
                : { message: "Unexpected Error" };
            }
            this.isLoading = false;
          });
      }
    },
    cancelEdit(row) {
      row.text = row.origText;
      row.attributes.forEach((a) => {
        a.text = a.origText;
        a.isDirty = false;
        a.editing = false;
      });
      row.rowDirty = false;

      utils.setDocPartStatus(row, this.tmpl_part_metadata, this.selectedPartWordChecks);

      row.editing = false;
      row.showDocs = false;
      this.editInProgress.row = null;
      this.editInProgress.text = "";
    },
    saveTextEdit(newval) {
      let newText;
      let row = this.textEdit.row;
      newText = newval.text;
      if (newval.subParts) {
        newText = utils.subpartsToPart(newval.subParts);
      }

      const origText = utils.removeTags(row.origText || row.text);
      row.origText = origText;
      row.text = newText;
      row.rowDirty = origText != utils.removeTags(newText);

      utils.setDocPartStatus(row, this.tmpl_part_metadata, this.selectedPartWordChecks);

      this.textEdit.show = false;
    },
    saveInlineTextEdit(newval, row, sp, attr) {
      this.editInProgress.text = newval.text;
      if (this.textEditTimeout) {
        clearTimeout(this.textEditTimeout);
        this.textEditTimeout = null;
      }
      this.textEditTimeout = setTimeout(
        () => this.updateInlineText(row, sp, attr),
        200
      );
    },
    completeInlineTextEdit(row, sp, attr) {
      this.updateInlineText(row, sp, attr);
      row.editing = false;
      if (attr) attr.editing = false;
    },
    updateInlineText(row, sp, attr) {
      const newText = this.editInProgress.text;
      if (attr) {
        attr.text = newText;
        attr.isDirty = attr.origText != attr.text;
        row.text = utils.attributesToText(
          this.tmpl_part_metadata.attributes,
          row.attributes
        );
        if (!newText)
          this.prepEditPartLookup(
            this.partTypeSelected.tmpl_part_id,
            true,
            attr.tpa_id
          );
      } else {
        sp.text = newText;
        row.text = newText;
        if (!newText)
          this.prepEditPartLookup(this.partTypeSelected.tmpl_part_id, true);
      }
      row.rowDirty = row.origText != row.text;
      utils.setDocPartStatus(row, this.tmpl_part_metadata, this.selectedPartWordChecks);
    },
    checkDirty(p) {
      p.rowDirty = true;
      //   this.openEditDialog();
    },
    openFindReplace() {
      this.editDialog.mode = "Find & Replace";
      this.openEditDialog();
    },
    openEditSelected() {
      this.editDialog.mode = "Update";
      this.openEditDialog();
    },
    openEditDialog() {
      this.editDialog.text = this.selectedRows[0].text;
      this.editDialog.attributes = JSON.parse(
        JSON.stringify(this.selectedRows[0].attributes)
      );
      //this.prepEditPartLookup(this.partTypeSelected.tmpl_part_id, true);
      this.editDialog.selectedText = "";
      this.editDialog.hint = "";
      this.editDialog.replacementText = "";
      this.editDialog.alternatives.splice(0);
      this.editDialog.totalDocs = this.selectedRows.reduce((counter, row) => {
        counter += row.documentparts.reduce((counter, doc) => {
          if (doc.selected) counter += 1;
          return counter;
        }, 0);
        return counter;
      }, 0);
      this.editDialog.editing = false;
      this.editDialog.open = true;

      utils.setDocPartStatus(
        this.editDialog,
        this.tmpl_part_metadata,
        this.selectedPartWordChecks
      );
    },
    saveDialogTextEdit(newval, attr) {
      this.editInProgress.text = newval.text;

      if (this.textEditTimeout) {
        clearTimeout(this.textEditTimeout);
        this.textEditTimeout = null;
      }
      this.textEditTimeout = setTimeout(
        () => this.completeDialogTextEdit(attr),
        200
      );
    },
    completeDialogTextEdit(attr) {
      const newText = this.editInProgress.text;
      if (attr) {
        attr.text = newText;
        this.editDialog.text = utils.attributesToText(
          this.tmpl_part_metadata.attributes,
          this.editDialog.attributes
        );
        if (!newText)
          this.prepEditPartLookup(
            this.partTypeSelected.tmpl_part_id,
            true,
            attr.tpa_id
          );
      } else {
        this.editDialog.text = newText;
        if (!newText)
          this.prepEditPartLookup(this.partTypeSelected.tmpl_part_id, true);
      }

      utils.setDocPartStatus(
        this.editDialog,
        this.tmpl_part_metadata,
        this.selectedPartWordChecks
      );
    },
    openDeleteDialogue() {
      this.deleteDialog.totalDocs = this.selectedRows.reduce((counter, row) => {
        counter += row.documentparts.reduce((counter, doc) => {
          if (doc.selected) counter += 1;
          return counter;
        }, 0);
        return counter;
      }, 0);
      this.deleteDialog.open = true;
    },
    confirmDelete() {
      const allDocParts = this.selectedRows.flatMap((rows) => rows.documentparts.filter(d => d.selected));

      let data = {
        documentparts: allDocParts.map(p => p.doc_part_id)
      };

      if (!data.documentparts.length) {
        this.response = { Status: "Error", Message: "No documents selected!" };
        return;
      }

      this.isLoading = true;
      axios
        .post("document/removepartdocs/", data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            this.selectedRows.forEach(r => {
              const origText = utils.removeTags(r.origText);
              if (r.documentparts.every(d => d.selected)) {
                const idx = this.partTypeSelected.parts.findIndex(
                  (p) => p.text === origText
                );
                if (idx > -1)
                  this.partTypeSelected.parts.splice(idx, 1);
              } else {
                const orig = this.partTypeSelected.parts.find(
                  (p) => p.text === origText
                );
                if (orig) {
                  orig.documentparts = r.documentparts.filter(d => !d.selected);
                  orig.documentparts.forEach(r => r.selected = true);
                }
              }
            });
            this.doFilter();
          }
          this.response = resp.data;
          this.isLoading = false;
          this.deleteDialog.open = false;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
          this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
        });
    },
    updateReplacementText(currentValue) {
      this.editDialog.replacementText = currentValue;
    },
    loadFindReplaceAlternatives() {
      const word = this.editDialog.selectedText.trim();
      const wordLC = word.toLowerCase();
      const match = this.wordChecks.find((x) => x.word === wordLC);
      if (match) {
        const isUpperCase =
          word.substr(0, 1) === word.substr(0, 1).toUpperCase();
        this.editDialog.alternatives = (match.alternatives || []).map((x) =>
          isUpperCase ? x.substr(0, 1).toUpperCase() + x.substr(1) : x
        );
        this.editDialog.hint = match.category + " bias?";
      } else {
        this.editDialog.hint = "";
        this.editDialog.alternatives.splice(0);
      }
    },
    updateAll() {
      const newText = this.editDialog.text;
      const rowsAffected = [];

      this.selectedRows.forEach((row) => {
        const origText = utils.removeTags(row.origText || row.text);
        row.text = newText;
        row.subParts = this.editDialog.subParts;
        row.attributes.forEach((a) => {
          const updatedAttr = this.editDialog.attributes.find(
            (ua) => ua.tpa_id === a.tpa_id
          );
          const origAttrText = utils.removeTags(a.origText || a.text);
          a.text = updatedAttr.text;
          a.isDirty = utils.removeTags(a.text) !== origAttrText;
        });
        if (origText != utils.removeTags(newText)) {
          rowsAffected.push(row);
        }
      });

      if (rowsAffected.length == 0) return;

      this.editDialog.open = false;

      this.saveRow(rowsAffected);
    },
    findReplaceAll() {
      const selectedText = this.editDialog.selectedText;
      const replacementText = this.editDialog.replacementText || "";
      if (!selectedText) return;

      this.editDialog.open = false;
      let itemsToUpdate = 0;
      let docsToUpdate = 0;
      let itemsUpdated = 0;

      this.selectedRows.forEach((row) => {
        const origText = utils.removeTags(row.origText || row.text);
        const newText = origText.replace(selectedText, replacementText);
        row.origText = origText;
        row.text = newText;
        if (row.subParts.length === 1) {
          row.subParts[0].text = newText;
        }
        row.attributes.forEach((a) => {
          const origAttrText = utils.removeTags(a.origText || a.text);
          a.text = origAttrText.replace(selectedText, replacementText);
          a.isDirty = a.text !== origAttrText;
        });
        if (origText != newText) {
          itemsToUpdate++;
          docsToUpdate += row.documentparts.reduce((counter, doc) => {
            if (doc.selected) counter += 1;
            return counter;
          }, 0);
          const that = this;
          this.saveRow([row], function () {
            itemsUpdated++;
            if (itemsUpdated == itemsToUpdate) {
              that.isLoading = false;
              that.response = {
                Status: "OK",
                Message: `${docsToUpdate} documents on ${itemsUpdated} items updated`,
              };
            }
          });
        }
      });

      if (!itemsToUpdate) {
        this.response = { Status: "Error", Message: "No items updated" };
      }
    },
    openFullDocument(doc) {
      this.$emit("openDocument", {
        system_number: utils.removeTags(doc.system_number),
        doc_name: utils.removeTags(doc.doc_name),
      });
    },
    getStatusFilterText(item, index) {
      if (item && index > 0) return "";
      if (this.itemStatusIncluded.length === this.itemStatusSummary.length) {
        return this.itemStatusSummary.length > 0 ? "All" : "None";
      } else {
        let defList = this.itemStatusSummary.filter((s) => s.status !== "OK");
        if (
          defList.every((s) =>
            this.itemStatusIncluded.some((si) => si.status === s.status)
          )
        ) {
          let text = "";
          text = "All issue statuses";
          if (defList.length < this.itemStatusIncluded.length) {
            text +=
              " (+ " +
              this.itemStatusIncluded
                .filter(
                  (ds) => !defList.some((d) => d === ds.status.toLowerCase())
                )
                .reduce((prev, curr) => {
                  return prev + (prev ? ", " : "") + curr.status;
                }, "");
          }
          return text;
        } else {
          return this.itemStatusIncluded.reduce((prev, curr) => {
            return prev + (prev ? ", " : "") + curr.status;
          }, "");
        }
      }
    },
    getDocStatusFilterText(item, index) {
      if (item && index > 0) return "";
      if (this.docStatusIncluded.length === this.docStatusSummary.length)
        return this.docStatusSummary.length > 0 ? "All" : "None";

      return this.docStatusIncluded.map((s) => s.status).join(", ");
    },
    applyReadOnlyHighlights(text, attr) {

      let wordChecks = this.wordChecks.filter((w) =>
        w.tmpl_part_types.some(
          (tp) => tp.tp_id === this.tmpl_part_metadata.tmpl_part_id
        )
      );
          
      if (attr && attr.skip_checks.length > 0) {
        wordChecks = wordChecks.filter(x => !attr.skip_checks.includes(x.check_type_label))
      }

      let txt = utils.applyHighlights(
        text,
        wordChecks,
        true
      );
      //replace newlines with breaks.
      return txt.highlightedText
        .trim()
        .replace(/^(?:\r\n|\r|\n)|(?:\r\n|\r|\n)$/g, "")
        .replace(/(?:\r\n|\r|\n)/g, "<br />");
    },
    exportToCSV() {
      let header =
        '"Client","Group","Section","Description","Status","Job ID","Recruiter","Job Status"';
      let data = "";
      let hierarchies = [];
      this.items.forEach((g) => {
        g.parts.forEach((p) => {
          p.documentparts.forEach((dp) => {
            dp.hierarchies.forEach((dh) => {
              if (!hierarchies.some((h) => h === dh.ht_name))
                hierarchies.push(dh.ht_name);
            });
            data += `"${g.client_name}","${g.partGroup}","${p.type}",${utils.csvEscape(p.text
              .split("’")
              .join("'"))},"${p.WordCount} - ${p.Status}","${
              dp.system_number
            }",`;
            data += `"${dp.recruiter ? dp.recruiter : ""}","${
              dp.doc_status_text
            }"`;
            hierarchies.forEach((h) => {
              let hv = dp.hierarchies.find((dh) => dh.ht_name === h);
              data += `,"${hv ? hv.hierarchy_text : ""}"`;
            });
            data += "\n";
          });
        });
      });
      hierarchies.forEach((h) => (header += ',"' + h + '"'));
      utils.downloadFile(
        header + "\n" + data,
        "DocParts.csv",
        "text/csv;encoding:utf-8"
      );
    },
    setQualityMode(val) {
      this.qualityMode = val;
      this.doFilter();
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/vars";

.flex-container {
  display: flex;

  .left-col {
    z-index: 3;
    flex: 0 0 400px;
    width: 400px !important;
    height: calc(100vh - 103px) !important;
    position: fixed;
    top: 48px !important;
    overflow: visible !important;
  }
  .right-col {
    transition: padding 200ms;
    padding-bottom: 65px;
    padding-left: 10px;
    &.hierarchiesPinned {
      padding-left: 410px;
    }
  }
}

.hierarchies-collapsed {
  width: 20px;

  min-height: calc(100vh - 103px) !important;
  .showHierarchies {
    position: fixed;
    top: 72px;
    left: 5px;
  }
}

.theme--light .hierarchies-collapsed {
  background-color: white;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.theme--dark .hierarchies-collapsed,
.theme--dark.v-navigation-drawer {
  background-color: $primary-background-dark;
  border-right: 1px solid hsla(0, 0%, 100%, 0.12);
}

.showHierarchies {
  top: 22px;
  right: -15px;
}

.selectAllCol {
  max-width: 40px;
  flex-shrink: 1;
}

.selectCol {
  max-width: 40px;
  flex-shrink: 1;
  padding-top: 12px;
}

.filter-col {
  max-width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
}

.sectionsSelect::v-deep {
  input {
    cursor: pointer;
  }
}

::v-deep {
  .v-list-item.group-active {
    background-color: transparent !important;
  }
  .v-select__selections {
    overflow: hidden;
    white-space: nowrap;
    flex-flow: nowrap;
  }
}

.footer-actions {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin: 0;
  height: 55px;
  z-index: 4;
  color: $input-text-color;

  div {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
  }
}

.btn-background.v-size--large,
.btn-background.v-input {
  height: 44px;
  ::v-deep .v-input__slot {
    background: transparent !important;
  }
}

.buttons {
  margin-top: 35px;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.dragHandle {
  color: blue;
  cursor: grab;
  margin-left: 30%;
}

.part {
  cursor: pointer;
  padding: 0;
  align-items: center;
  position: relative;
  > div {
    margin: 5px 5px 0 5px;
    border: solid #dfdfdf 1px;
    > .v-icon {
      margin: 5px 10px 5px -20px;
    }
  }
  &:not(.ListItemRow) > div {
    min-height: 4em;
  }
  &.editing > div {
    border: solid $selectedfieldborder 1px;
    background-color: $selectedfieldbackground;
  }
  .highlights {
    padding: 15px 5px 15px 20px;
    letter-spacing: 0;
  }
  .edit-icon {
    position: absolute;
    top: 7px;
    right: 7px;
  }
  .texthighlight {
    ::v-deep {
      padding: 10px 5px 10px 14px;
      .clearContent {
        margin-left: -16px !important;
        padding-top: 3px;
      }
      .comboboxHolder {
        margin-top: -15px;
      }
    }
  }
  .hcontainer {
    margin-left: 0;
  }
  &.dialogTextHighlight {
    &.ListItemRow {
      margin-left: 15px;
    }
  }
}

.v-application.theme--dark .part {
  > div {
    border: solid rgba(#dfdfdf, 0.5) 1px;
  }
  &.editing > div {
    background-color: $secondary-background-dark;
  }
}

::v-deep .doc {
  max-width: 300px;
  // border: 1px solid transparent;
  // &:hover {
  //   border: 1px solid rgba(0, 0, 0, 0.12);
  // }
  .v-label {
    font-size: 14px;
    font-weight: bold;
  }
  .v-list-item__subtitle {
    padding-left: 32px;
    cursor: pointer;
  }
}
</style>