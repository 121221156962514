<template>
  <div class="home">
    <Landing
      :qaActions="qaActions"
      @addDocument="$emit('addDocument')"
    ></Landing>
  </div>
</template>

<script>
import Landing from "@/components/landingPages/cHome";
import { mapState } from "vuex";

export default {
  name: "vHome",
  components: {
    Landing,
  },
  props: {
    qaActions: { type: Array, required: true }
  },
  mounted() {},
  computed: {
    ...mapState({
      newUi: (state) => state.theme.newUi,
    })
  },
  methods: {
    sessionExpired(err) {
      this.$emit("sessionExpired", err);
    },
    openDocument(item) {
      this.$emit("openDocument", item);
    },
    copyDocument(item, tmpl_id) {
      this.$emit("copyDocument", item, tmpl_id);
    },
    documentChangedHandler(item) {
      this.$emit("documentChangedHandler", item);
    },
  },
};
</script>