<template>
  <div>
    <v-menu :class="resizerClass"
      v-model="contextMenu.show"
      absolute
      :position-x="context.posX"
      :position-y="context.posY"
      offset-y
      :close-on-content-click="false"
      nudge-width="300"
    >
      <v-card v-if="context.document" :class="resizerClass">
        <v-card-text v-if="contextMenu.busy">
          <v-row>
            <v-col cols="5"></v-col>
            <v-col>
              <v-progress-circular indeterminate size="50" color="grey">
              </v-progress-circular>
            </v-col>
            <v-col cols="5"></v-col>
          </v-row>
        </v-card-text>
        <v-list dense v-else>
          <v-list-item @click="openDocument">
            <v-list-item-icon>
              <v-icon>mdi-book-open</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Open</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="previewEnabled" @click="openPreview">
            <v-list-item-icon>
              <v-icon>mdi-eye</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Preview</v-list-item-title>
          </v-list-item>

          <div v-if="document && document.lifecycle_id">
            <v-list-item
              v-for="aa in contextMenu.listActions"
              :key="
                'd' + context.document.doc_id + 'a' + aa.action_available_id
              "
              dense
              @click="doWorkflowAction(aa)"
              :disabled="
                !!(!aa.allow_errors && context.document.error_count) ||
                !!(!aa.allow_errors && context.document.missing_data) ||
                !!(!aa.allow_warnings && context.document.warning_count)
              "
            >
              <v-list-item-icon>
                <v-icon>{{ getIcon(aa) }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ aa.name }}
              </v-list-item-title>
            </v-list-item>
          </div>
          <!-- <div v-else>
          <v-list-item
            dense
            @click="$emit('copyDocument', document, undefined)"
            v-if="
              $loginState.user.createDocumentOptions.some(
                (a) => a.tmpl_id === document.tmpl_id
              )
            "
          >
            <v-list-item-icon>
              <v-icon>mdi-card-plus-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title> Copy Document </v-list-item-title>
          </v-list-item>

          <v-list-item
            dense
            @click="$emit('sendToTaleo', document)"
            v-if="usesTaleoFeed || this.$loginState.canDemo"
          >
            <v-list-item-icon>
              <v-icon>mdi-transfer-right</v-icon>
            </v-list-item-icon>
            <v-list-item-title> Post </v-list-item-title>
          </v-list-item>

          <v-list-group
            v-if="!$loginState.readOnly && !document.usesWorkflow"
            prepend-icon="mdi-share"
          >
            <template v-slot:activator>
              <v-list-item-title>Share</v-list-item-title>
            </template>
            <div>
              <v-list-item
                v-for="(sec, si) in html_export_sections"
                :key="'dt' + si"
                dense
                @click="docToClipboard(sec)"
              >
                <v-list-item-icon>
                  <v-icon>{{
                    "mdi-numeric-" + (si + 1) + "-box-multiple-outline"
                  }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{
                    sec.replace(/ .*/, "") +
                    (sec.replace(/ .*/, "") === "Section"
                      ? " " + (si + 1)
                      : "") +
                    " to Clipboard"
                  }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                key="dt19"
                dense
                @click="
                  showEmailDialog(false);
                  toggleDocToEmail(document.system_number);
                  contextMenu.show = false;
                "
              >
                <v-list-item-icon>
                  <v-icon>mdi-email-send-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title> Email to... </v-list-item-title>
              </v-list-item>
            </div>
          </v-list-group>

          <v-list-group
            v-if="!document.usesWorkflow"
            :value="false"
            prepend-icon="mdi-download"
          >
            <template v-slot:activator>
              <v-list-item-title>Download</v-list-item-title>
            </template>

            <v-list-item key="dt10" dense @click="generatePDF()">
              <v-list-item-icon>
                <v-icon>mdi-file-pdf-box</v-icon>
              </v-list-item-icon>
              <v-list-item-title> Download PDF</v-list-item-title>
            </v-list-item>
            <v-list-item
              key="dt11"
              dense
              v-if="!$loginState.canDemo"
              @click="generateRTF()"
            >
              <v-list-item-icon>
                <v-icon>mdi-file-word</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Download RTF</v-list-item-title>
            </v-list-item>
            <v-list-item
              key="dt14"
              dense
              v-if="!$loginState.canDemo"
              @click="generateDOCX()"
            >
              <v-list-item-icon>
                <v-icon>mdi-file-word-box-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Download Word</v-list-item-title>
            </v-list-item>
            <v-list-item key="dt12" dense @click="generateDOCX(true)">
              <v-list-item-icon>
                <v-icon>mdi-file-word-box</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Download Word (+Notes)</v-list-item-title>
            </v-list-item>
            <v-list-item
              key="dt16"
              dense
              @click="generateJobShareTemplate()"
              v-if="
                $loginState.user.settings.some(
                  (s) =>
                    s.setting === 'uses_jobsharetemplate' && s.value === 'true'
                ) &&
                (document.doc_type == 'Job Description' ||
                  document.doc_type == 'Job Profile')
              "
            >
              <v-list-item-icon>
                <v-icon>mdi-share-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Export Job Share Template</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </div> -->
        </v-list>
      </v-card>
    </v-menu>
    <v-dialog
      v-model="dataCapture.show"
      scrollable
      max-width="800px"
      persistent :class="resizerClass"
    >
      <DataCaptureForm
        v-if="
          dataCapture.show &&
          (dataCapture.action.definition.isCommentBased ||
            ['picklist', 'form'].some(
              (x) => x === dataCapture.action.definition.method
            ))
        "
        :action="dataCapture.action"
        :isBusy="isLoading"
        :docId="context.document.doc_id"
        @save="saveDataCapture"
        @cancel="cancelDataCapture"
        @toggleHidden="dca.isDataCaptureHidden = !dca.isDataCaptureHidden"
      />
      <DataCaptureDocumentRole
        v-if="
          dataCapture.show &&
          dataCapture.action.definition.method === 'document_role'
        "
        :action="dataCapture.action"
        :isBusy="isLoading"
        :docId="context.document.doc_id"
        @save="saveDataCapture"
        @cancel="cancelDataCapture"
        @toggleHidden="dca.isDataCaptureHidden = !dca.isDataCaptureHidden"
      />
      <DataCaptureHierarchy
        v-else-if="
          dataCapture.show &&
          dataCapture.action.definition.method === 'hierarchy'
        "
        :action="dataCapture.action"
        :documentHierarchies="context.document.hierarchies"
        :isBusy="isLoading"
        @save="saveDataCapture"
        @cancel="cancelDataCapture"
        @toggleHidden="dataCapture.action = !dataCapture.action"
      />
      <DataCaptureRequisitions
        v-else-if="
          dataCapture.show &&
          (dataCapture.action.definition.method === 'requisition' ||
            dataCapture.action.definition.method === 'requisition_new')
        "
        :action="dataCapture.action"
        :isBusy="isLoading"
        @save="saveDataCapture"
        @cancel="cancelDataCapture"
        @toggleHidden="
          dataCapture.action.isDataCaptureHidden =
            !dataCapture.action.isDataCaptureHidden
        "
      />
      <AssignReviewGroup
        v-else-if="
          dataCapture.show &&
          dataCapture.action.definition.method === 'review_group'
        "
        :docId="context.document.doc_id"
        :action="dataCapture.action"
        @save="saveDataCapture"
        @cancel="dataCapture.show = false"
        @close="dataCapture.show = false"
      >
      </AssignReviewGroup>
    </v-dialog>
    <v-dialog
      v-model="emailDialogue.show"
      max-width="800px"
      style="max-height: 600px"
      scrollable :class="resizerClass"
    >
      <v-card>
        <v-card-title class="d-flex align-center">
          <div class="subtitle-1">
            <!-- <span v-if="emailDialogue.multi"
              >Send Documents
              <span v-if="emailDocList.length > 0"
                >({{ emailDocList.length }})</span
              ></span
            > 
            <span v-else>Send Document</span>-->
            <span>Send Document</span>
          </div>
          <v-spacer />
          <v-btn
            icon
            large
            class="btn-background"
            @click="emailDialogue.show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                outlined
                class="roundish mt-2"
                label="Email Address"
                required
                :rules="[rules.required, rules.email]"
                v-model="emailDialogue.value"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end px-6 pb-6">
          <v-btn
            v-if="!emailDialogue.sending"
            large
            color="primarylight"
            class="roundish"
            @click="emailDoc()"
            :disabled="!emailDialogue.value"
            >Send</v-btn
          >
          <v-progress-circular
            v-else
            class="mx-2"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="commentDialogue.show"
      max-width="800px"
      style="max-height: 600px"
      scrollable :class="resizerClass"
    >
			<DocumentComments
            v-if="commentDialogue.show"
				:document="document"
				@closed="hideComments"
        @commentsAdded="commentsAdded(commentDialogue.part)"
			></DocumentComments>
    </v-dialog>
  </div>
</template>

<script>
import utils from "@/common/utils.js";
import axios from "axios";
import DataCaptureForm from "@/components/cDataCaptureForm.vue";
import DataCaptureHierarchy from "@/components/cDataCaptureHierarchy.vue";
import DataCaptureRequisitions from "@/components/cDataCaptureRequisitions.vue";
import DataCaptureDocumentRole from "@/components/cDataCaptureDocumentRole.vue";
import AssignReviewGroup from "@/components/cAssignReviewGroup.vue";
import documentExport from "@/common/documentExport.js";
import DocumentComments from "@/components/comments/CommentsContainer";

export default {
  name: "DocActionsMenu",
  props: {
    context: Object,
    usesTaleoFeed: Boolean,
    previewEnabled: Boolean,
    resizerClass: String,
  },
  data: function () {
    return {
      utils: utils,
      document: null,
      contextMenu: {
        show: false,
        busy: false,
        listActions: [],
        // posX: 0,
        // posY: 0,
      },
      dataCapture: {
        show: false,
        isValid: false,
        value: null,
        action: null,
      },
      emailDialogue: {
        show: false,
        value: null,
        multi: false,
        sending: false,
      },
      commentDialogue: {
        show: false,
        part: null,
        partType: null,
      },
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  components: {
    DataCaptureForm,
    DataCaptureHierarchy,
    DataCaptureRequisitions,
    DataCaptureDocumentRole,
    AssignReviewGroup,
    DocumentComments,
  },
  watch: {
    "context.document": function (newVal) {
      if (newVal) this.init();
    },
    "context.show": function (newVal) {
      if (newVal) this.init();
    },
  },
  mounted() {},
  created() {},
  computed: {},
  methods: {
    init() {
      this.contextMenu.show = true;
      this.document = this.$store.getters["docs/docsList"].find(
        (x) => x.doc_id === this.context.document.doc_id
      );
      this.getDocListActions();
    },
    getDocListActions() {
      if (!this.document || !this.document.lifecycle_id) {
        return;
      }
      if (this.context.JA_DEMO) {
        this.contextMenu.listActions = [
          {
            action_available_id: 1,
            allow_errors: true,
            allow_warnings: true,
            name: "Initiate Review",
          },
          {
            action_available_id: 1,
            allow_errors: true,
            allow_warnings: true,
            name: "Rename",
          },
          {
            action_available_id: 1,
            allow_errors: true,
            allow_warnings: true,
            name: "Make in Job Family Group",
          },
          {
            action_available_id: 1,
            allow_errors: true,
            allow_warnings: true,
            name: "Move Job Family",
          },
          {
            action_available_id: 1,
            allow_errors: true,
            allow_warnings: true,
            name: "Merge",
          },
          {
            action_available_id: 1,
            allow_errors: true,
            allow_warnings: true,
            name: "Delete",
          },
          {
            action_available_id: 1,
            allow_errors: true,
            allow_warnings: true,
            name: "View History",
          },
        ];
        return;
      }
      this.contextMenu.busy = true;

      axios
        .get(`workflow/getDocumentListActions/${this.document.doc_id}`)
        .then((resp) => {
          if (resp.data.Status == "OK") {
            this.contextMenu.listActions = resp.data.Data;
          }
          this.contextMenu.busy = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.triggerNotification(err, "error");
          }
        });
    },
    openDocument() {
      this.$emit("openDocument", this.context.document);
      this.contextMenu.show = false;
      this.contextMenu.busy = false;
    },
    openPreview() {
      this.$emit("openPreview", this.context.document);
      this.contextMenu.show = false;
      this.contextMenu.busy = false;
    },
    doWorkflowAction(action, savedata, dcData) {
      //}, savedata, dcData) {
      if (!this.context.document) return;
      if (action.emailDoc && !this.emailDialogue.value) {
        this.emailDialogue.action = action;
        // this.emailDocList.splice(0);
        this.emailDialogue.multi = false;
        this.emailDialogue.sending = false;
        this.emailDialogue.show = true;
        this.contextMenu.show = false;
        return;
      }
      this.contextMenu.busy = true;
      let dc = this.dataCapture;
      let request = {
        doc_id: this.document.doc_id,
        action_available_id: action.action_available_id,
        lifecycle_role_id: action.lifecycle_role_id,
        document_role_id: action.document_role_id,
        preProcessResponse: null,
        returnHeaderOnly: true,
      };
      let dcReadOnly = null;
      if (savedata) {
        if (action.emailDoc) {
          request.preProcessResponse = { toAddress: this.emailDialogue.value };
        } else if (dcData.inputs && dcData.inputs.length) {
          request.preProcessResponse = dcData;
          dcReadOnly = this.context.document.state.dataCaptureActionsMain.find(
            (x) =>
              x.isDataCaptureViewer &&
              x.data_capture_type_id === action.data_capture_type_id
          );
        } else if (dcData.hr_id || dcData.hr_id_copy) {
          request.preProcessResponse = { ...dcData };
        } else if (action.data_capture_method === "review_group") {
          request.preProcessResponse = { newReviewGroupId: dcData };
        } else if (
          action.data_capture_method === "requisition" ||
          action.data_capture_method === "requisition_new"
        ) {
          request.preProcessResponse = { requisitions: dcData.requisitions };
        } else {
          request.preProcessResponse = dcData.value;
        }
      }

      return axios
        .post("document/processdocumentaction/", request)
        .then((resp) => {
          let result = { redirectURL: "" };
          if (resp.data.Status === "OK") {
            if (resp.data.Data.clipboard_content) {
              this.copyLinkToClipboard(resp.data.Data.clipboard_content);
            }
            if (resp.data.Data.dataCapture) {
              dc.action = action;
              dc.action.definition = resp.data.Data.dataCapture;
              if (
                dc.action.isDataCaptureEditPopup ||
                dc.action.definition.lifecycle_role_id_created ||
                dc.action.definition.isCommentBased ||
                dc.action.definition.isCheckBased
              ) {
                dc.action.definition.value = null;
                dc.show = true;
              }
              this.contextMenu.show = false;
              this.contextMenu.busy = false;
              return;
            } else {
              if (
                dcReadOnly &&
                dcReadOnly.definition &&
                dcReadOnly.definition.inputs?.length
              ) {
                dcReadOnly.definition.inputs.forEach((i) => {
                  let newVal = request.preProcessResponse.find(
                    (r) => r.check_type_id === i.check_type_id
                  );
                  if (newVal) i.value = newVal.value;
                });
              }
              dc.action = null;
              dc.show = false;
              if (resp.data.Data.documents && resp.data.Data.documents.length) {
                this.$store.dispatch(
                  "docs/updateDoc",
                  resp.data.Data.documents[0]
                );
                this.$store.dispatch(
                  "tasks/refresh",
                  resp.data.Data.documents[0].doc_id
                );
              }
              if (action.download_format) {
                documentExport.generateDownload(
                  action.download_format,
                  this.document
                );
              }
              if (resp.data.Data.redirectURL) {
                result.redirectURL = resp.data.Data.redirectURL;
              }
              if (resp.data.Data.copyAction) {
                this.$emit(
                  "copyDocument",
                  { doc_id: this.document.doc_id },
                  resp.data.Data.copyAction.tmpl_id_destination,
                  null,
                  resp.data.Data.copyAction.action_available_id
                );
              }
            }
          } else {
            console.log(resp.data.Message);
          }
          this.closeEmailDialogue();
          this.contextMenu.show = false;
          this.contextMenu.busy = false;
          resp.data.Timeout = -1;
          this.response = resp.data;
          return result;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.closeEmailDialogue();
          this.contextMenu.show = false;
          this.contextMenu.busy = false;
          return false;
        });
    },
    emailDoc() {
      if (this.document && this.document.lifecycle_id) {
        if (this.emailDialogue.action) {
          this.doWorkflowAction(this.emailDialogue.action, true);
        }
        return;
      }
      //   if (this.emailDocList.length === 0) {
      //     this.triggerNotification(
      //       "Please select at least one document",
      //       "warning"
      //     );
      //     return;
      //   }
      //   this.emailDialogue.sending = true;
      //   let data = {
      //     to_address: this.emailDialogue.value,
      //     docs: this.emailDocList,
      //   };

      //   axios
      //     .post("document/emaildoc/", data)
      //     .then((resp) => {
      //       if (resp.data.Status == "OK") {
      //         this.triggerNotification("Email Sent Successfully", "success");
      //         this.emailDialogue.show = false;
      //         this.emailDialogue.value = "";
      //         //this.items =[...this.items];
      //         //this.items = JSON.parse(JSON.stringify(this.items));
      //         this.contextMenu = false;
      //       } else {
      //         this.triggerNotification(resp.data.Message, "error");
      //         this.emailDialogue.show = false;
      //         this.emailDialogue.value = "";
      //         this.contextMenu = false;
      //       }
      //       this.emailDialogue.sending = false;
      //     })
      //     .catch((err) => {
      //       if (err.response && err.response.status === 401) {
      //         this.$emit("sessionExpired", err);
      //       } else {
      //         console.log(err);
      //         this.triggerNotification(err, "error");
      //       }
      //       this.awaitingTaleo = false;
      //     });
    },
    closeEmailDialogue() {
      this.emailDialogue.show = false;
      this.emailDialogue.action = null;
      this.emailDialogue.value = "";
      this.emailDialogue.valid = false;
    },
    saveDataCapture(dataCapture, action) {
      this.doWorkflowAction(action, true, dataCapture);
    },
    copyLinkToClipboard(text) {
      if (this.html_export_sections.some((x) => x === text)) {
        this.docToClipboard(text);
      } else {
        let container;
        let dialog = document.getElementsByClassName(
          "v-dialog--active v-dialog--fullscreen"
        );
        if (dialog && dialog.length) container = dialog[0];
        else container = document.body;

        const textarea = document.createElement("textarea");
        textarea.value = text;
        textarea.setAttribute("readonly", "");
        textarea.style.position = "absolute";
        textarea.style.left = "-9999px";
        container.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        container.removeChild(textarea);
      }
    },
    getIcon(aa) {
      switch (aa.name.split(" ")[0].substring(0, 5).toLowerCase().trim()) {
        case "copy":
          return "mdi-content-copy";
        case "creat":
          return "mdi-card-plus-outline";
        case "post":
          return "mdi-transfer-right";
        case "email":
          return "mdi-email";
        case "share":
          return "mdi-share";
        case "downl":
          return "mdi-download";
        case "expor":
          return "mdi-share-variant";
        case "appro":
          return "mdi-check-outline";
        case "delet":
          return "mdi-delete";
        case "revie":
          return "mdi-account-group";
        case "invit":
        case "add":
          return "mdi-account-plus";
      }
      return "mdi-hammer-wrench";
    },
  },
};
</script>
<style>
</style>