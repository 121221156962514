<template>
  <v-container fluid v-if="selectedDashboard">
    <v-row class="py-4">
      <v-col class="d-flex align-center">
        <h1 class="title">{{ $route.meta.text }}</h1>
      </v-col>
      <v-col class="d-flex justify-end align-center">
        <div v-for="(f, fi) in allFilters" :key="fi">
          <v-chip class="ml-1">
            {{ f.text }}
            <v-icon right @click="removeFilter(f)">mdi-close</v-icon>
          </v-chip>
        </div>
        <div class="ml-2" v-if="dashboardDefinition.layouts && dashboardDefinition.layouts.length !== 0">
          <v-select
            outlined
            v-model="selectedLayout"
            :items="dashboardDefinition.layouts"
            return-object
            dense
            item-text="layout_name"
            item-value="layout_name"
            hide-details
            :menu-props="{ maxHeight: '400', 'offset-y': true }"
          >
          </v-select>
        </div>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col
        v-for="p in selectedDashboard.panels"
        :key="'panel' + p.id"
        :cols="p.cols"
      >
        <v-card class="py-6">
          <v-card-text>
            <v-row class="d-flex justify-center">
              <v-col
                dense
                v-for="(db, dbi) in selectedDashboard.children"
                :key="'panel' + dbi + 'db' + dbi"
                v-show="p.childSelect || childIndex == dbi"
                :cols="p.childColWidth || 12"
                class="px-5"
              >
                <div v-if="db.loaded && (p.showExport || p.dateGroupBy)" class="float-right">
                  <v-btn
                    v-if="p.showExport"
                    class="btn-background"
                    icon
                    @click="exportToCSV"
                    title="Download List"
                  >
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="p.dateGroupBy"
                    @click="configurePanel(p)"
                    class="btn-background ml-2"
                    icon
                    color="grey lighten-1"
                  >
                    <v-icon> mdi-cog </v-icon>
                  </v-btn>
                </div>

                <v-tabs
                  v-if="childIndex == dbi && p.tabs.length > 1"
                  v-model="p.activeTabIndex"
                  class="mb-3"
                >
                  <v-tab v-for="(t, ti) in p.tabs" :key="ti">
                    {{ t.title }}
                  </v-tab>
                </v-tabs>

                <v-row>
                  <v-col
                    v-for="ch in db.charts.filter((c) => c.panel === p.id)"
                    :key="ch.ref"
                    v-show="p.tabs.length < 2 || ch.tab == p.activeTabIndex + 1"
                    :class="{
                      childSelect: p.childSelect,
                      active: dbi == childIndex,
                    }"
                    :cols="ch.colWidth || 12"
                  >
                    <v-breadcrumbs
                      v-if="
                        ch.showBreadcrumbs &&
                        (p.tabs.length < 2 ||
                          (ch.navItems && ch.navItems.length > 1))
                      "
                      :items="ch.navItems"
                      :divider="
                        ch.drillDownDimensions && ch.drillDownDimensions.length
                          ? '>'
                          : '/'
                      "
                    >
                      <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                          link
                          @click="clickHandler(db, item, ch, true)"
                          :disabled="item.level === ch.drillDownLevel"
                          >{{ item.text }}</v-breadcrumbs-item
                        >
                      </template>
                    </v-breadcrumbs>
                    <div @click="openChild(p, dbi)" style="cursor: pointer">
                      <div v-if="!db.loaded" class="d-flex justify-center mt-5">
                        <v-progress-circular
                          :size="70"
                          :width="10"
                          color="primary"
                          indeterminate
                        ></v-progress-circular>
                      </div>
                      <v-col v-else-if="ch.type === 'map' && ch.ready">
                        <l-map
                          :ref="ch.ref"
                          :style="{
                            height: ch.height + 'px',
                            width: ch.width + 'px',
                            zIndex: 1,
                          }"
                          :options="{ zoomSnap: 0.25 }"
                        >
                          <l-tile-layer
                            :url="ch.map.url"
                            :attribution="ch.map.attribution"
                          />
                          <l-geo-json
                            v-if="ch.map.geojson"
                            :geojson="ch.map.geojson"
                            :options="ch.map.options"
                            :options-style="ch.map.optionsStyle"
                          />
                        </l-map>
                      </v-col>
                      <div v-else>
                        <div :id="ch.ref"></div>
                        <h2 class="chartTitle" v-if="!ch.showBreadcrumbs">
                          {{ db.subTitle }}
                        </h2>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="openPanelSettings" max-width="600px">
      <v-card v-if="selectedPanel">
        <v-card-title>
          <span class="headline">Panel Settings</span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            class="btn-background"
            @click="openPanelSettings = false"
          >
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-radio-group v-model="selectedPanel.dateGroupBy" @change="setPanelGroupBy" label="Group By">
              <v-radio label="Month" value="month"></v-radio>
              <v-radio label="Quarter" value="quarter"></v-radio>
              <v-radio label="Year" value="year"></v-radio>
            </v-radio-group>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import utils from "@/common/utils.js";
import countrygeoJSON from "../common/countryGeoJSON";
import dayJS from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import minMax from "dayjs/plugin/minMax";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { latLng } from "leaflet";
import { LMap, LTileLayer, LGeoJson } from "vue2-leaflet";
const d3 = require("d3");
const dimple = require("../common/dimple.js");
export default {
  name: "InsightsDashboard",
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
  },
  props: {},
  data: function () {
    return {
      response: null,
      draw: false,
      countryLookup: [],
      childIndex: 0,
      hbarHeight: 21,
      colours: [
        "#2d99ff", // Blue
        "#2cd9c5", // Green
        "#FF4069", // Red
        "#FF9020", // Orange
        "#FFED6F", // Yellow
        "#BC80BD", // Purple
        "#8DD3C7", // Turquoise
        "#CCEBC5", // Pale Blue
        "#FFFFB3", // Pale Yellow
        "#BEBADA", // Lavender
        "#FCCDE5", // Pink
        "#D9D9D9", // Grey],
      ],
      dimensionColours: [],
      //   mapCol: this.colours
      //     .map(c => new dimple.color(c)),
      //     .map(c => Object.assign({}, c, { region: "" })),
      regionColours: [
        // new dimple.color("#80B1D3"), // Blue
        new dimple.color("#FB8072"), // Red
        new dimple.color("#FDB462"), // Orange
        new dimple.color("#B3DE69"), // Green
        new dimple.color("#FFED6F"), // Yellow
        new dimple.color("#BC80BD"), // Purple
        new dimple.color("#8DD3C7"), // Turquoise
        new dimple.color("#CCEBC5"), // Pale Blue
        new dimple.color("#FFFFB3"), // Pale Yellow
        new dimple.color("#BEBADA"), // Lavender
        new dimple.color("#FCCDE5"), // Pink
        new dimple.color("#D9D9D9"), // Grey
      ].map((c) => Object.assign({}, c, { region: "" })),
      dashboardDefinition: null,
      selectedLayout: null,
      selectedDashboard: null,
      selectedPanel: null,
      openPanelSettings: false
    };
  },
  created() {},
  mounted() {
    dayJS.extend(quarterOfYear);
    dayJS.extend(minMax);
    dayJS.extend(isSameOrBefore);

    this.init();
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  updated() {
    this.redrawVisibleCharts();
  },
  watch: {
    selectedLayout(l) {
      if (l && l.layout_definition) {
        this.selectedDashboard = JSON.parse(l.layout_definition);
      }
    },
    $route() {
      this.init();
    },
    selectedDashboard(db) {
      db.panels.forEach((p) => {
        this.$set(p, "tabs", []);
        this.$set(p, "dateGroupBy", "");
        this.$set(p, "editSettings", false);
      });

      this.prepareDashboard();
      db.children.forEach((cdb) => {
        if (cdb.loaded)
          cdb.charts.forEach((c, i) => {
            const panel = db.panels.find((p) => p.id == c.panel);
            c = this.initialiseChartDef(c, cdb.id, panel);
            this.$set(cdb.charts, i, c);
          });
      });
      this.setupChild();
      this.drawDashboards();
    }
  },
  computed: {
    allFilters() {
      const filters = [];
      this.selectedDashboard.children.forEach((db) => {
        db.charts.forEach((ch) => {
          if (!ch.isSingleSelect && ch.navItems && ch.navItems.length > 1) {
            let filterText = "";
            ch.navItems.forEach((n, i) => {
              if (i == 1) filterText += n.text;
              else if (i > 1)
                filterText += ch.drillDownDimensions.length
                  ? ` > ${n.text}`
                  : ` / ${n.text}`;
            });
            if (!filters.some((f) => f.text == filterText)) {
              filters.push({
                text: filterText,
                dimension: ch.dimension,
                subDimension: ch.subDimension,
                dateGroupBy: ch.dateGroupBy,
              });
            }
          }
        });
      });
      return filters;
    },
  },
  methods: {
    init() {
      axios
        .get("document/getInsightsDefinitions/")
        .then((resp) => {
          if (resp.data.Status === "OK") {
            const dashboardDefinition = resp.data.Data.find(
              (dd) => dd.db_id == this.$route.meta.dashboardId
            );
            this.dashboardDefinition = dashboardDefinition;
            
            if (this.dashboardDefinition.layouts && this.dashboardDefinition.layouts.length !== 0)
              this.selectedLayout = this.dashboardDefinition.layouts[0];

            this.isLoading = false;
          }
          this.response = resp.data;
          //console.log(resp);
        })
        .catch((err) => {
          alert("Error loading dashboard data");
          console.log(err);
          this.isLoading = false;
        });
    },
    redrawVisibleCharts() {
      //draw visible charts (charts don't get drawn correcly if try to draw them while they're hidden)
      this.$nextTick(() => {
        this.selectedDashboard.panels.forEach((p) => {
          const charts = [];
          if (p.childSelect) {
            this.selectedDashboard.children.forEach((db) => {
              charts.push(...db.charts.filter((c) => c.panel === p.id));
            });
          } else {
            const db = this.selectedDashboard.children[this.childIndex];
            charts.push(
              ...db.charts.filter(
                (c) =>
                  c.panel === p.id &&
                  (p.tabs.length < 2 || c.tab == p.activeTabIndex + 1)
              )
            );
          }
          charts
            .filter((c) => c.type !== "map" && c.Chart && c.redraw)
            .forEach((c) => {
              c.Chart.draw(c.redraw);
              c.redraw = false;
            });

          charts
            .filter((c) => c.type === "map")
            .forEach((c) => {
              this.fitMap(c);
            });
        });
      });
    },
    openChild(panel, cdi) {
      if (!panel.childSelect || this.childIndex === cdi) return;

      this.childIndex = cdi;
      this.setupChild();
    },
    configurePanel(p) {
      this.selectedPanel = p;
      this.openPanelSettings = true;
    },
    setupChild() {
      const db = this.selectedDashboard;
      db.panels.forEach((p) => {
        const panelCharts = db.children[this.childIndex].charts.filter(
          (c) => c.panel === p.id
        );
        
        const dateChart = panelCharts.find(c => c.dateGroupBy);
        p.dateGroupBy = dateChart ? dateChart.dateGroupBy : "";

        p.tabs.splice(0);
        for (
          let i = 0;
          i < Math.max(...panelCharts.map((c) => c.tab || 1));
          i++
        ) {
          const chart = panelCharts.find((ch) => ch.tab === i + 1);
          p.tabs.push({
            title: chart ? chart.tabTitle || chart.title : "No charts!",
          });
        }

        if (p.tabs.length <= p.activeTabIndex) p.activeTabIndex = 0;
      });
    },
    setPanelGroupBy() {
      this.allFilters.forEach(f => this.removeFilter(f));
      const panel = this.selectedPanel;
      const db = this.selectedDashboard;
      db.children.forEach(cdb => {
        const charts = cdb.charts.filter(
          (c) => c.panel === panel.id && c.dateGroupBy
        );
        charts.forEach(c => {
          c.dateGroupBy = panel.dateGroupBy;
          const data = this.filterForSelection(cdb);
          this.setChartData(cdb, c, data);
        });
      })
    },
    prepareDashboard() {
      const db = this.selectedDashboard;

      db.children.forEach((cdb) => {
        if (cdb.loaded) return;
        let possibleError = false;
        axios
          .get(cdb.sourceURL)
          .then((resp) => {
            possibleError = true;
            if (resp.data.Status === "OK") {
              cdb.sourceDatasets.forEach((ds) => {
                let data = ds.id ? resp.data.Data[ds.id] : resp.data.Data;
                if (data.length) {
                  if (ds.filter) {
                    data = data.filter(ds.filter);
                  }
                  if (ds.type === "raw") {
                    //   if (dbi === 0)
                    if (data.length) {
                      if (data[0].hierarchies) {
                        if (data[0].hierarchies.ht_id !== 1) {
                          //remaps dimension hierarchies for non-base clients
                          let hierarchies = data[0].hierarchies;
                          let baseHierarchies = [1, 3, 2];
                          baseHierarchies.forEach((bh, i) => {
                            cdb.def.dimensions
                              .filter((d) => d.ht_id === bh)
                              .forEach((ht) => {
                                ht.ht_id = hierarchies[i].ht_id;
                              });
                          });
                        }
                      }
                    }
                    if (data[0].client_name === "Elsevier") {
                      cdb.def.dimensions
                        .filter((d) => d.source === "hierarchy6")
                        .forEach((h6) => {
                          h6.source = "hierarchy3";
                        });
                    }

                    this.initialiseDashboard(db, cdb, data);
                  } else if (ds.type === "country lookup") {
                    this.countryLookup = data;
                  }
                }
              });

              cdb.loaded = true;
              this.drawDashboard(cdb);
            }
            this.response = resp.data;
            //console.log(resp);
          })
          .catch((err) => {
            if (possibleError) {
              alert("Code Error");
            } else if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              alert(err.response ? err.response.data.message : err);
            }
            console.log(err);
          });
      });
    },
    sourceFuncs() {
      return {
        ethnicityCat: function(d) {
          switch (d.dimension) {
            case "White":
            case "Not Disclosed":
            case "Not Declared":
              return d.dimension;
            default:
              return "Underrepresented";
          }
        }
      }
    },
    prepareRowData(rows, definition) {
      let measures = definition.measures;
      let countryLookup = this.countryLookup;
      const sourceFuncs = this.sourceFuncs();
      let processDims = function (dimensions, dataRow, row) {
        let outRow = row || {};
        dimensions.forEach((d) => {
          if (d.ht_id) {
            let htv = dataRow.hierarchies.find((h) => h.ht_id === d.ht_id);
            outRow[d.id] = htv ? htv[d.source] : "";
          } else if (d.sourceFunc && typeof(sourceFuncs[d.sourceFunc]) === "function") {
            outRow[d.id] = sourceFuncs[d.sourceFunc](dataRow);
          } else {
            outRow[d.id] = dataRow[d.source];
          }
          if (!outRow[d.id]) outRow[d.id] = "UNKNOWN " + d.id;
          outRow[d.id] = outRow[d.id].trim();
          if (d.isDate) {
            d[d.id] = dayJS(d[d.id]);
          } else if (d.lookupList) {
            let list =
              d.lookupList === "countryLookup" ? countryLookup : d.lookupList;
            let val = list.find((l) => l[d.lookupSearch] === outRow[d.id]);
            outRow[d.lookupid] = val ? val[d.lookupValue] : null;
          } else if (d.lookupid) {
            outRow[d.lookupid] = dataRow[d.lookupid];
          }
          if (!d.Domain) d.Domain = [];
          if (!d.Domain.some((v) => v.key === outRow[d.id])) {
            d.Domain.push({ key: outRow[d.id], text: outRow[d.id] });
          }
          measures.forEach((m) => {
            outRow[m.id] = m.isCount ? 1 : parseInt(dataRow[m.source]);
          });
          if (d.dimensions && d.dimensions.length) {
            processDims(d.dimensions, dataRow, outRow);
          }
        });
        return outRow;
      };
      return rows.map((r) => {
        return processDims(definition.dimensions, r);
      });
    },
    filterForSelection(dashboard, chart) {
      let data = dashboard.rawData;
      dashboard.charts.forEach((c) => {
        if (c.selected && c.selected.length) {
          if (
            !chart ||
            ((chart.isFilter || chart.isSingleSelect) &&
              chart.name !== c.name &&
              (!chart.linkedChartGroup ||
                chart.linkedChartGroup !== c.linkedChartGroup))
          ) {
            let dimDef = dashboard.def.dimensions.find(
              (dd) => dd.id === c.dimension
            );
            if (dimDef.isDate && c.dateGroupBy) {
              data = data.filter((d) =>
                c.selected.some(
                  (s) => {
                    if (c.dimensionRangeTo) {
                      const range = c.dateRanges.find(r => r.key === s);
                      return range && range.fromDate.isSameOrBefore(dayJS(d[c.dimensionRangeTo], 'day')) && range.toDate.isAfter(dayJS(d[c.dimension]));
                    } else
                      return s === chartHelper.dateToGroupString(c.dateGroupBy, d[c.dimension]);
                  }
                )
              );
            } else {
              data = data.filter((d) =>
                c.selected.some((s) => s === d[c.dimension])
              );
            }
          }
        }
        if (c.drillDownDimensions && c.drillDownDimensions.length) {
          c.drillDownDimensions
            .filter((x) => x.selected)
            .forEach((x) => {
              let dimDef = dashboard.def.dimensions.find(
                (dd) => dd.id === x.id
              );
              if (dimDef.isDate && c.dateGroupBy) {
                data = data.filter(
                  (d) =>
                    x.selected ===
                    chartHelper.dateToGroupString(c.dateGroupBy, d[x.id])
                );
              } else {
                data = data.filter((d) => x.selected === d[x.id]);
              }
            });
        }
      });
      return data;
    },
    getFilterClickFunction(chartType, level, verticalBar) {
      let self = this;
      return function (elem) {
        self[level + chartType + "Filter"] = verticalBar
          ? elem.xValue
          : elem.yValue;
        self["draw" + chartType + "Charts"]();
      };
    },
    initialiseChartDef(chart, dbid, panel) {
      let w = document.body.clientWidth * 0.9;
      const panelChildCols = panel.childColWidth || 12;
      const chartCols = chart.colWidth || 12;
      chart = Object.assign({}, chart, {
        ref:
          chart.measure +
          chart.dimension +
          chart.type +
          dbid +
          "_" +
          chart.index,
        element:
          "#" +
          chart.measure +
          chart.dimension +
          chart.type +
          dbid +
          "_" +
          chart.index,
        width: w * (panel.cols / 12) * (panelChildCols / 12) * (chartCols / 12) - 24,
        height: 0,
        measureTitle: "Items",
        Chart: null,
        Bars: null,
        selected: [],
        navItems: [],
        drillDownLevel: 0,
      });
      chart.drillDownDimensions = chart.drillDownDimensions
        ? chart.drillDownDimensions.map((d) =>
            Object.assign(d, { selected: "" })
          )
        : [];
      chart.navItems.push({
        text: "All " + chart.title,
        level: 0,
        domain: [],
      });
      if (chart.type === "pie") {
        chart.height = 180; //chart.width;
      }
      if (chart.type === "bar") {
        chart.height = chart.width / 2;
      }
      if (chart.type === "map") {
        chart.height = chart.width / 1.5;
        chart.ready = false;
        chart.map = {
          geojson: { type: "FeatureCollection", features: [] }, // geo,
          fillColor: "#e4ce7f",
          //   url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
          url: "http://{s}.basemaps.cartocdn.com/rastertiles/voyager_no_labels_no_buildings/{z}/{x}/{y}.png",
          attribution:
            '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          marker: latLng(47.41322, -1.219482),
          options: {
            onEachFeature(feature, layer) {
              layer.bindTooltip(
                `<div>${feature.properties.name} (${
                  feature[chart.countryRegion]
                }): ${feature[chart.measure]}</div>`,
                { permanent: false, sticky: true }
              );
            },
          },
          optionsStyle: (feature) => {
            let colour = this.regionColours.find(
              (r) => r.region === feature[chart.countryRegion]
            );
            if (!colour) {
              colour = this.regionColours.find((r) => r.region === "");
              colour.region = feature[chart.countryRegion];
            }
            return {
              weight: 1,
              color: "gray",
              opacity: 1,
              fillColor: colour.fill,
              fillOpacity: 0.6,
            };
          },
        };
      }
      return chart;
    },
    drawDashboards() {
      this.selectedDashboard.children.forEach((d) => {
        if (d.loaded) {
          this.drawDashboard(d);
        }
      });
    },
    drawDashboard(dashboard) {
      this.$nextTick(() => {
        let data = this.filterForSelection(dashboard);
        if (!data) return;

        dashboard.charts
          .filter((c) => c.isSingleSelect)
          .forEach((c) => {
            this.setChartData(dashboard, c, data);
          });
        data = this.filterForSelection(dashboard);
        dashboard.charts
          .filter((c) => !c.isSingleSelect)
          .forEach((c) => {
            this.setChartData(dashboard, c, data);
          });
      });
    },
    initialiseDashboard(db, cdb, rawdata) {
      let preppedData = this.prepareRowData(rawdata, cdb.def);
      cdb.rawData = preppedData;

      cdb.charts.forEach((c, i) => {
        const panel = db.panels.find((p) => p.id == c.panel);
        c = this.initialiseChartDef(c, cdb.id, panel);
        this.$set(cdb.charts, i, c);
      });
    },
    drawChart(def, dashboard, data, dimension) {
      switch (def.type) {
        case "hbar": {
          def.showBarLabels = true;
          this.drawHorizontalBarChart(def, dashboard, data, dimension);
          break;
        }
        case "bar": {
          def.showBarLabels = true;
          this.drawVerticalBarChart(def, dashboard, data, dimension);
          break;
        }
        case "pie":
          //   def.height = def.width;
          this.drawPieChart(def, dashboard, data, dimension);
          break;
        case "map":
          this.drawMap(def, dashboard);
          break;
        case "funnel": {
          def.showBarLabels = true;
          this.drawHorizontalFunnelChart(def, dashboard, data, dimension);
          break;
        }
      }
    },
    removeFilter(item) {
      this.selectedDashboard.children.forEach((cdb) => {
        cdb.charts.forEach((ch) => {
          if (
            (ch.dateGroupBy && ch.dateGroupBy === item.dateGroupBy) ||
            ch.dimension === item.dimension
          )
            this.doFilterOrDrilldown(cdb, { level: 0 }, ch, true);
        });
      });
    },
    clickHandler(dashboard, item, def, isUp) {
      this.doFilterOrDrilldown(dashboard, item, def, isUp);

      this.selectedDashboard.children
        .filter((cdb) => cdb != dashboard)
        .forEach((cdb) => {
          cdb.charts.forEach((ch) => {
            if (
              (ch.dateGroupBy && ch.dateGroupBy === def.dateGroupBy) ||
              ch.dimension === def.dimension
            )
              this.doFilterOrDrilldown(cdb, item, ch, isUp);
          });
        });

      this.redrawVisibleCharts();
    },
    doFilterOrDrilldown(dashboard, item, def, isUp) {
      if (def.isSingleSelect && isUp) {
        return;
      } else if (def.isFilter || def.isSingleSelect) {
        this.doFilter(dashboard, item, def, isUp);
      } else if (def.drillDownDimensions && def.drillDownDimensions.length) {
        if (isUp) {
          this.doDrillUp(dashboard, item, def);
        } else {
          this.doDrillDown(dashboard, item, def);
        }
      }
    },
    doFilter(dashboard, item, def, isUp) {
      let redraw = false;
      let selIndex;
      if (isUp) {
        if (
          !def.isSingleSelect &&
          (item.level <= 0 || def.selected.length === 1)
        ) {
          chartHelper.elementSelect(
            d3.select(def.element).selectAll(".dimple-bar"),
            false
          );
          chartHelper.elementSelect(
            d3.select(def.element).selectAll(".dimple-pie"),
            false
          );
          def.selected.splice(0);
          // def.navItems
          //   .filter((n) => n.svgElement)
          //   .forEach((n) => chartHelper.elementSelect(n.svgElement, false));

          def.navItems.splice(0);
          def.navItems.push({ text: def.title + " - ALL", level: -1 });
        } else {
          chartHelper.elementSelect(
            d3
              .select(def.element)
              .selectAll(
                `.dimple-${item.text.toLowerCase().replace(" ", "-")}`
              ),
            false
          );
          selIndex = def.selected.findIndex((x) => x === item.text);
          def.selected.splice(selIndex, 1);
          //const selIndex2 = def.navItems.findIndex((x) => x.text === item.text);
          //chartHelper.elementSelect(def.navItems[selIndex2].svgElement, false);
          def.navItems.splice(selIndex, 1);
        }
      } else {
        let value;
        let shapes;
        switch (def.type) {
          case "hbar":
          case "funnel":
            value = item.yField[0];
            shapes = d3
              .select(def.element)
              .selectAll(
                `rect.dimple-${value.toLowerCase().replace(" ", "-")}`
              );
            break;
          case "bar":
            value = item.xField[0];
            shapes = d3
              .select(def.element)
              .selectAll(
                `rect.dimple-${value.toLowerCase().replace(" ", "-")}`
              );
            break;
          default:
            value = item.aggField[0];
            shapes = d3
              .select(def.element)
              .selectAll(
                `path.dimple-${value.toLowerCase().replace(" ", "-")}`
              );
        }
        if (def.isSingleSelect) {
          dashboard.charts
            .filter((c) =>
              def.linkedChartGroup
                ? c.linkedChartGroup === def.linkedChartGroup
                : c.index === def.index
            )
            .forEach((c) => {
              c.selected.splice(0);
              c.selected.push(value);
              c.navItems.forEach((n) =>
                chartHelper.elementSelect(n.svgElement, false)
              );
              this.processSelectedValues(
                c,
                c.index === def.index ? item.selectedShape : null
              );
              chartHelper.elementSelect(item.selectedShape, true);
            });
        } else {
          selIndex = def.selected.findIndex((x) => x === value);
          if (selIndex < 0) {
            def.selected.push(value);
          } else {
            def.selected.splice(selIndex, 1);
          }
          this.processSelectedValues(def, item.selectedShape);

          chartHelper.elementSelect(shapes, selIndex < 0);
          //if (!def.subDimension)
          //  chartHelper.elementSelect(item.selectedShape, selIndex < 0);
        }
      }
      let fullFilter = this.filterForSelection(dashboard);

      if (redraw) {
        this.setChartData(
          dashboard,
          def,
          this.filterForSelection(dashboard, def)
        );
      }
      dashboard.charts
        .filter((c) => c.name !== def.name)
        .forEach((c) => {
          if (c.type !== "map") {
            this.setChartData(
              dashboard,
              c,
              c.isFilter || c.isSingleSelect
                ? this.filterForSelection(dashboard, c)
                : fullFilter,
              true
            );
          } else {
            this.drawMap(c, dashboard);
          }
        });
    },
    processSelectedValues(chart, element) {
      chart.navItems.splice(0);
      if (chart.selected && chart.selected.length) {
        chart.navItems.push({ text: chart.title, level: -1 });
        chart.selected.forEach((s) => {
          chart.navItems.push({
            text: s,
            level: -2,
            svgElement: element || null,
          });
        });
      } else {
        chart.navItems.push({ text: chart.title + " - ALL", level: -1 });
      }
    },
    deriveDownDimension(chart) {
      return chart.drillDownLevel >= chart.drillDownDimensions.length
        ? chart.drillDownDimensions[chart.drillDownDimensions.length - 1].id
        : chart.drillDownDimensions[chart.drillDownLevel].id;
    },
    deriveCurrentDimension(chart) {
      let ddLevel = chart.drillDownLevel > 0 ? chart.drillDownLevel : 0;
      if (ddLevel > chart.drillDownDimensions.length)
        ddLevel = chart.drillDownDimensions.length;
      return ddLevel > 0
        ? chart.drillDownDimensions[ddLevel - 1].id
        : chart.dimension;
    },
    setChartData(
      dashboard,
      chart,
      data,
      redrawCurrentDimension,
      drillDownValue,
      drillUpLevel
    ) {
      let dimension = chart.dimension;
      if (redrawCurrentDimension)
        dimension = this.deriveCurrentDimension(chart);
      if (drillDownValue) dimension = this.deriveDownDimension(chart);
      if (drillUpLevel >= 0)
        dimension =
          drillUpLevel === 0
            ? chart.dimension
            : chart.drillDownDimensions[drillUpLevel - 1].id;

      let dimDef = dashboard.def.dimensions.find((x) => x.id === dimension);
      if (chart.dataFilter && typeof chart.dataFilter === "function") {
        data = JSON.parse(JSON.stringify(data)).filter(chart.dataFilter);
      } else if (chart.dataFilterDimension && chart.dataFilterValue) {
        data = JSON.parse(JSON.stringify(data)).filter(
          (d) => d[chart.dataFilterDimension] === chart.dataFilterValue
        );
      } else if (chart.dataFilterDimension && chart.dataFilterNotValue) {
        data = JSON.parse(JSON.stringify(data)).filter(
          (d) => d[chart.dataFilterDimension] !== chart.dataFilterNotValue
        );
      }
      let d;
      if (!chart.subDimension) {
        d = chartHelper.groupBy(
          data,
          dimension,
          chart.measure,
          dimDef.isDate ? chart.dateGroupBy : ""
        );
      } else {
        if (chart.dimensionRangeTo) {
          const groupBy = chartHelper.groupByDateRange(
            data,
            chart.dimension,
            chart.dimensionRangeTo,
            [chart.subDimension],
            [chart.measure],
            dimDef.isDate ? chart.dateGroupBy : ""
          );
          d = groupBy.list;
          chart.dateRanges = groupBy.dateRanges;
        } else
          d = chartHelper.groupByKeys(
            data,
            [dimension, chart.subDimension],
            [chart.measure],
            dimDef.isDate ? chart.dateGroupBy : ""
          );
      }
      chart.maxValue = d.reduce((p, c) => {
        return c[chart.measure] > p ? c[chart.measure] : p;
      }, 0);
      chart.seriesLength = d.length;

      if (chart.type === "funnel") {
        d = d.sort((a, b) =>
          a[dimension] > b[dimension] ? 1 : a[dimension] < b[dimension] ? -1 : 0
        );
        let prev = null;
        d.forEach((v) => {
          v.labelPct =
            prev === null ? null : prev === 0 ? 0 : v[chart.measure] / prev;
          v.labelText =
            prev === null ? v[chart.measure] : d3.format(".1%")(v.labelPct);
          prev = v[chart.measure];
        });
      }

      if (redrawCurrentDimension) {
        d3.select(chart.element).selectAll(".barLabel").remove();
        let redraw = d.every(
          (x) =>
            chart.Chart &&
            chart.Chart.data.some((s) => s[dimension] === x[dimension])
        );
        if (redraw) {
          chart.Chart.data.forEach((d, di) => {
            d[chart.measure] = 0;
            if (d.labelText) d.labelText = di === 0 ? "0" : "0%";
            if (d.labelPct) d.labelPct = di === 0 ? null : 0;
          });
          d.forEach((x) => {
            let v = chart.Chart.data.find(
              (cd) =>
                cd[dimension] === x[dimension] &&
                (!chart.subDimension ||
                  cd[chart.subDimension] === x[chart.subDimension])
            );
            if (v) {
              v[chart.measure] = x[chart.measure];
              if (v.labelText) {
                v.labelText = x.labelText;
                v.labelPct = x.labelPct;
              }
            } else {
              chart.Chart.data.push(x);
              chart.Chart.height = chart.Chart.data * this.hbarHeight + 55;
            }
          });
          //chart.Chart.draw(500);
          if (!chart.redraw) {
            if (chart.type === "funnel") chart.redraw = true;
            else chart.redraw = 200;
          }
        } else {
          this.drawChart(chart, dashboard, d, dimension);
        }
      } else if (drillDownValue) {
        chart.drillDownLevel++;
        chart.height = 0;
        chart.navItems.push({
          text: drillDownValue,
          level: chart.drillDownLevel,
          domain: d.map((x) => [
            x[dimension],
            chart.subDimension ? x[chart.subDimension] : null,
          ]),
        });
        this.drawChart(chart, dashboard, d, dimension);
      } else if (drillUpLevel >= 0) {
        chart.drillDownLevel = drillUpLevel;
        chart.height = 0;
        let domain = chart.navItems[drillUpLevel].domain.map((x) => {
          //   let r = { id: x };
          let r = {};
          r[dimension] = x[0];
          if (chart.subDimension) r[chart.subDimension] = x[1];
          r[chart.measure] = 0;
          return r;
        });
        domain.forEach((x) => {
          let val = d.find(
            (v) =>
              v[dimension] === x[dimension] &&
              (!chart.subDimension ||
                v[chart.subDimension] === x[chart.subDimension])
          );
          if (val) x[chart.measure] = val[chart.measure];
        });

        this.drawChart(chart, dashboard, domain, dimension);
      } else {
        chart.navItems[0].domain = d.map((x) => [
          x[dimension],
          chart.subDimension ? x[chart.subDimension] : null,
        ]);
        if (chart.isSingleSelect && chart.navItems.length <= 1) {
          let index =
            chart.defaultSelect < 0
              ? chart.navItems[0].domain.length + chart.defaultSelect
              : chart.defaultSelect;
          let value = chart.navItems[0].domain.sort()[index] ? chart.navItems[0].domain.sort()[index][0] : null;
          if (value)
            chart.selected.push(value);
          this.processSelectedValues(chart);
          //   chart.navItems.splice(0);
          //   chart.navItems.push({ text: chart.title, level: -1 });
          //   chart.navItems.push({ text: value, level: -2, svgElement: null });
        }
        this.drawChart(chart, dashboard, d);
      }
    },
    doDrillDown(dashboard, item, def) {
      const value = def.type === "hbar" ? item.yField[0] : item.seriesValue[0];
      if (def.drillDownLevel > def.drillDownDimensions.length) return;
      else if (def.drillDownLevel === 0) {
        def.selected.length = 0;
        def.selected.push(value);
      } else {
        def.drillDownDimensions[def.drillDownLevel - 1].selected = value;
      }
      let filtered = this.filterForSelection(dashboard);

      dashboard.charts
        .filter((c) => c.name !== def.name)
        .forEach((c) => {
          if (c.type !== "map") {
            this.setChartData(
              dashboard,
              c,
              c.isFilter || c.isSingleSelect
                ? this.filterForSelection(dashboard, c)
                : filtered,
              true
            );
          } else {
            this.drawMap(c, dashboard);
          }
        });
      //   def.data.length = 0;
      this.setChartData(dashboard, def, filtered, false, value);
    },
    doDrillUp(dashboard, item, def) {
      //   const value = item.text;
      if (item.level === 0) {
        def.selected.length = 0;
      }
      def.drillDownDimensions.forEach((dd, ddi) => {
        if (ddi >= item.level - 1) {
          dd.selected = "";
        }
      });

      let filtered = this.filterForSelection(dashboard);

      def.navItems.splice(item.level + 1);

      dashboard.charts
        .filter((c) => c.name !== def.name)
        .forEach((c) => {
          if (c.type !== "map") {
            this.setChartData(
              dashboard,
              c,
              c.isFilter || c.isSingleSelect
                ? this.filterForSelection(dashboard, c)
                : filtered,
              true
            );
          } else {
            this.drawMap(c, dashboard);
          }
        });
      //   def.data.length = 0;
      this.setChartData(dashboard, def, filtered, false, null, item.level);
    },
    assignChartColours(chart) {
      // keep colours consistent across all charts/dashboards
      let getColour = (d, v) => {
        let col = this.dimensionColours.find(
          (dc) => dc.dimension === d && dc.value === v
        );
        if (!col) {
          let index = this.dimensionColours.filter(
            (x) => x.dimension === d
          ).length;
          col = { dimension: d, value: v, colour: this.colours[index] };
          this.dimensionColours.push(col);
        }
        return col.colour;
      };
      if (chart.subDimension || chart.type === "pie") {
        let dim = chart.subDimension || chart.dimension;
        let processed = [];
        chart.Chart.data.forEach((cd) => {
          let value = cd[dim];
          if (processed.indexOf(value) < 0) {
            processed.push(value);
            chart.Chart.assignColor(value, getColour(dim, value));
          }
        });
      } else {
        if (chart.dataFilterDimension && chart.dataFilterValue) {
          chart.Chart.defaultColors = [
            new dimple.color(
              getColour(chart.dataFilterDimension, chart.dataFilterValue)
            ),
          ];
        } else if (chart.dataFilterDimension && chart.dataFilterNotValue) {
          chart.Chart.defaultColors = [
            new dimple.color(
              getColour(
                chart.dataFilterDimension,
                "!" + chart.dataFilterNotValue
              )
            ),
          ];
        }
      }
    },
    drawVerticalBarChart(def, dashboard, data, dimension) {
      let vm = this;
      d3.select(def.element).selectAll("*").remove();
      let svg = dimple.newSvg(def.element, def.width, def.height),
        aChart = new dimple.chart(svg, data),
        axis = def.subAsPercent
          ? aChart.addPctAxis("y", def.measure)
          : aChart.addMeasureAxis("y", def.measure);

      def.Chart = aChart;
      axis.title = def.measureTitle;
      axis.ticks = 5;
      axis.title = "";
      //   axis = aChart.addCategoryAxis("x", "id");
      //   axis.addOrderRule(def.sortProperty ? def.sortProperty : "id");
      axis = aChart.addCategoryAxis("x", dimension || def.dimension);
      axis.addOrderRule(
        def.sortProperty ? def.sortProperty : dimension || def.dimension
      );
      axis.title = ""; //def.xAxisTitle;
      def.Bars = aChart.addSeries(def.subDimension || null, dimple.plot.bar);

      this.assignChartColours(def);

      def.Bars.afterDraw = chartHelper.verticalBarLabels(svg, def, data);
      def.Bars.addEventHandler("click", function (ev) {
        const elem = ev.selectedShape._groups[0][0].__data__;
        vm.clickHandler(dashboard, elem, def, false);
      });
      def.Bars.getTooltipText = (ev) => {
        const elem = ev.srcElement.__data__;
        let percent;
        if (def.subAsPercent) {
          percent = d3.format(".1f")(elem.height * 100);
        } else {
          const xTotal = data
            .filter((d) => d[def.dimension] == elem.x)
            .reduce((a, b) => a + b[def.measure], 0);
          percent = d3.format(".1f")((100 * elem.height) / xTotal);
        }
        if (elem.aggField.length)
          return [
            `${elem.x} - ${elem.aggField[0]}:`,
            `${d3.format(",")(elem.yValue)} (${percent}%)`,
          ];
        else return [`${elem.x}:`, `${d3.format(",")(elem.yValue)}`];
      };
      //chart.addLegend(60, 10, 510, 20, "right");
      aChart.setMargins(32, 30, 10, 55); // (left, top, right, bottom)
      //aChart.draw();
      def.redraw = true;
    },
    drawHorizontalBarChart(def, dashboard, data, dimension) {
      let vm = this;
      d3.select(def.element).selectAll("*").remove();
      let bars = chartHelper.distinctValues(data, [
        dimension || def.dimension,
      ]).length;
      //   if (!def.height) {
      def.height = bars * this.hbarHeight + 55;
      //   }
      let svg = dimple.newSvg(def.element, def.width, def.height),
        aChart = new dimple.chart(svg, data),
        axis = def.subAsPercent
          ? aChart.addPctAxis("x", def.measure)
          : aChart.addMeasureAxis("x", def.measure),
        lMargin = 150;
      def.Chart = aChart;

      axis.title = def.measureTitle;
      axis.ticks = parseInt((def.width - lMargin) / 50);
      axis.title = "";

      axis = aChart.addCategoryAxis("y", dimension || def.dimension);
      axis.addOrderRule(def.measure);
      axis.title = "";
      def.Bars = aChart.addSeries(def.subDimension || null, dimple.plot.bar);

      this.assignChartColours(def);

      def.Bars.afterDraw = chartHelper.horizontalBarLabels(svg, def, data);
      def.Bars.addEventHandler("click", function (ev) {
        const elem = ev.selectedShape._groups[0][0].__data__;
        vm.clickHandler(dashboard, elem, def, false);
      });
      if (def.subAsPercent) {
        def.Bars.getTooltipText = (ev) => {
          //   return [
          //     `${dimension || def.dimension}: ${ev.y}`,
          //     `${def.subDimension}: ${ev.aggField[0]}`,
          //     `${def.measure}: ${d3.format(",")(ev.xValue)} (${d3.format(".1f")(
          //       ev.width * 100
          //     )}%)`
          //   ];
          const elem = ev.srcElement.__data__;
          return [
            `${elem.y} - ${elem.aggField[0]}:`,
            `${d3.format(",")(elem.xValue)} (${d3.format(".1f")(
              elem.width * 100
            )}%)`,
          ];
        };
      } else {
        def.Bars.getTooltipText = (ev) => {
          const elem = ev.srcElement.__data__;
          return [`${elem.y}:`, `${d3.format(",")(elem.xValue)}`];
        };
      }
      //chart.addLegend(60, 10, 510, 20, "right");
      aChart.setMargins(lMargin, 10, 40, 50); // (left, top, right, bottom)
      //aChart.draw();
      def.redraw = true;
    },
    drawHorizontalFunnelChart(def, dashboard, data, dimension) {
      let vm = this;
      let dim = dimension || def.dimension;
      d3.select(def.element).selectAll("*").remove();
      let bars = chartHelper.distinctValues(data, [dim]).length;
      if (!def.height) {
        def.height = bars * this.hbarHeight + 55;
      }
      let svg = dimple.newSvg(def.element, def.width, def.height),
        aChart = new dimple.chart(svg, data),
        axis = aChart.addMeasureAxis("x", def.measure),
        lMargin = 150;
      def.Chart = aChart;
      axis.title = def.measureTitle;
      axis.showGridlines = false;
      axis.hidden = true;

      let yaxis = aChart.addCategoryAxis("y", dim);
      yaxis.addOrderRule(dim, true);
      yaxis.title = "";
      yaxis.hidden = true;

      def.Bars = aChart.addSeries(def.subDimension || null, dimple.plot.bar);

      this.assignChartColours(def);

      def.Bars.afterDraw = chartHelper.funnelLabels(svg, def, data, dashboard);
      // if (def.filterHandler)
      def.Bars.addEventHandler("click", function (ev) {
        const elem = ev.selectedShape._groups[0][0].__data__;
        vm.clickHandler(dashboard, elem, def, false);
      });
      def.Bars.getTooltipText = (ev) => {
        const elem = ev.srcElement.__data__;
        return [`${elem.y}:`, `${d3.format(",")(elem.xValue)}`];
      };
      //chart.addLegend(60, 10, 510, 20, "right");
      aChart.setMargins(lMargin, def.linkedValueTitle ? 5 : 5, 40, 50); // (left, top, right, bottom)
      //aChart.draw();
      def.redraw = true;
    },
    drawPieChart(def, dashboard, data, dimension) {
      let vm = this;
      d3.select(def.element).selectAll("*").remove();
      if (!def.height) {
        def.height = def.width * 1.25;
      }
      let svg = dimple.newSvg(def.element, def.width, def.height),
        pChart = new dimple.chart(svg, data);
      def.Chart = pChart;
      pChart.addMeasureAxis("p", def.measure);

      this.assignChartColours(def);

      //pChart.setBounds(10, "15%", "70%", "90%"); // x,y,w,h
      pChart.setBounds(0, 0, "70%", "100%"); // x,y,w,h
      //   var ring = pChart.addSeries("id", dimple.plot.pie);
      var ring = pChart.addSeries(dimension || def.dimension, dimple.plot.pie);
      ring.innerRadius = "50%";
      //pChart.addLegend("75%", 10, "25%", "50%", "left");
      if (def.showLegend) pChart.addLegend("65%", 0, "35%", "100%", "left");
      def.Bars = ring;

      def.Bars.addEventHandler("click", function (ev) {
        const elem = ev.selectedShape._groups[0][0].__data__;
        vm.clickHandler(dashboard, elem, def, false);
      });
      def.Bars.getTooltipText = (ev) => {
        const elem = ev.srcElement._current;
        return [
          `${elem.aggField[0]}:`,
          `${d3.format(",")(elem.pValue)} (${d3.format(".1f")(
            elem.piePct * 100
          )}%)`,
        ];
      };
      ring.afterDraw = chartHelper.pieLabels(svg, def);

      //pChart.draw();
      def.redraw = true;
    },
    fitMap(def) {
      if (def.ready) {
        this.$refs[def.ref][0].mapObject.flyToBounds(def.bounds);
      }
    },
    drawMap(def, dashboard) {
      let filtered = this.filterForSelection(dashboard);
      let data = chartHelper.groupByKeys(
        filtered,
        [def.countryDim, def.countryCode, def.countryRegion],
        [def.measure]
      );
      let minmax = { minX: 1000, maxX: -1000, minY: 1000, maxY: -1000 };

      def.map.geojson.features = geo.features
        .filter((f) => data.some((d) => d[def.countryCode] === f.id))
        .map((f) => {
          f.geometry.coordinates.forEach((x) => {
            x.forEach((s) => {
              if (s[0][0]) {
                s.forEach((ss) => {
                  minmax.minX = ss[0] < minmax.minX ? ss[0] : minmax.minX;
                  minmax.minY = ss[1] < minmax.minY ? ss[1] : minmax.minY;
                  minmax.maxX = ss[0] > minmax.maxX ? ss[0] : minmax.maxX;
                  minmax.maxY = ss[1] > minmax.maxY ? ss[1] : minmax.maxY;
                });
              } else {
                minmax.minX = s[0] < minmax.minX ? s[0] : minmax.minX;
                minmax.minY = s[1] < minmax.minY ? s[1] : minmax.minY;
                minmax.maxX = s[0] > minmax.maxX ? s[0] : minmax.maxX;
                minmax.maxY = s[1] > minmax.maxY ? s[1] : minmax.maxY;
              }
            });
          });
          return Object.assign(
            {},
            f,
            data.find((c) => c[def.countryCode] === f.id)
          );
        });
      def.bounds = [
        [minmax.maxY, minmax.maxX],
        [minmax.minY, minmax.minX],
      ];
      def.ready = true;
    },
    exportToCSV() {
      let dashboard = this.selectedDashboard.children[this.childIndex];
      let cols = dashboard.def.dimensions.map((d) => {
        return { text: d.display, value: d.id };
      });
      dashboard.def.measures.forEach((m) => {
        cols.push({ text: m.name, value: m.id });
      });
      let data = cols.map((h) => '"' + h.text + '"').join(",") + "\n";
      let filtered = this.filterForSelection(dashboard);
      filtered.forEach((d) => {
        data +=
          cols.map((h) => utils.csvEscape(utils.removeTags(d[h.value]))).join(",") +
          "\n";
      });
      utils.downloadFile(
        data,
        `${dashboard.title}.csv`,
        "text/csv;encoding:utf-8"
      );
    },
  },
};
var geo = countrygeoJSON;
var chartHelper = {
  getBar: function (shape) {
    // Get the shape as a d3 selection
    let s = d3.select(shape),
      rect = {
        x: parseFloat(s.attr("x")),
        y: parseFloat(s.attr("y")),
        width: parseFloat(s.attr("width")),
        height: parseFloat(s.attr("height")),
      };
    return {
      x: isNaN(rect.x) ? 0 : rect.x,
      y: isNaN(rect.y) ? 0 : rect.y,
      width: isNaN(rect.width) ? 0 : rect.width,
      height: isNaN(rect.height) ? 0 : rect.height,
      d3Shape: s,
    };
  },
  horizontalBarLabels: function (svg, chart, allData) {
    return function (shape, data) {
      var rect = chartHelper.getBar(shape);
      if (chart.isSingleSelect)
        chartHelper.preselectElement(rect.d3Shape, chart, data.yValue);
      if (chart.showBarLabels && rect.height >= 8 && rect.width > 40) {
        // Add a text label for the value
        const measure =
          data.xValue > 10000
            ? d3.format(",.1f")(data.xValue / 1000) + "k"
            : data.xValue;
        let percent;
        if (chart.subAsPercent) {
          percent = (data.width * 100).toFixed(0);
        } else {
          const xTotal = allData
            .filter((d) => d[chart.dimension] == data.y)
            .reduce((a, b) => a + b[chart.measure], 0);
          percent = ((100 * data.width) / xTotal).toFixed(0);
        }

        svg
          .append("text")
          .attr("class", "barLabel")
          .attr(
            "x",
            rect.width > 65
              ? rect.x + rect.width - 35
              : rect.x + rect.width - 20
          )
          .attr("y", rect.y + rect.height / 2 + 3.5)
          .style("text-anchor", "middle")
          .style("font-size", "10px")
          .style("opacity", 0.6)
          .style("pointer-events", "none")
          .text(rect.width > 65 ? `${measure} (${percent}%)` : measure);
      }
    };
  },
  funnelLabels: function (svg, chart, labels, dashboard) {
    let getLinkedValue = (dimValue) => {
      let linkedCharts =
        chart.linkedChartColourBands && chart.linkedChartColourBands.length
          ? dashboard.charts.filter(
              (c) =>
                c.linkedChartGroup === chart.linkedChartGroup &&
                c.index !== chart.index
            )
          : [];
      let linkedLabelValues = [];
      if (linkedCharts.length) {
        linkedCharts.forEach((lc) => {
          lc.Chart.data
            .filter(
              (x) => x.labelPct !== null && x[chart.dimension] === dimValue
            )
            .forEach((cd) => {
              let lv = linkedLabelValues.find(
                (x) => x.dimValue === cd[chart.dimension]
              );
              if (!lv) {
                lv = { dimValue: cd[chart.dimension], pctValue: 0 };
                linkedLabelValues.push(lv);
              }
              lv.pctValue += cd.labelPct;
            });
        });
        linkedLabelValues.forEach(
          (x) => (x.pctValue = x.pctValue / linkedCharts.length)
        );
      }
      return {
        value: linkedLabelValues.length ? linkedLabelValues[0].pctValue : null,
        fullWidth: chart.fullWidth,
      };
    };
    return function (shape, data) {
      var rect = chartHelper.getBar(shape);
      if (chart.isSingleSelect)
        chartHelper.preselectElement(rect.d3Shape, chart, data.y);
      let widthPct = chart.maxValue > 0 ? data.xValue / chart.maxValue : 1,
        fullWidth =
          chart.maxValue > 0 ? rect.width / widthPct : chart.width - 75;

      let isLinked = chart.linkedChartGroup && chart.linkedChartGroup > 0;
      let linkedChart = getLinkedValue(data.y);

      if (isLinked && !linkedChart.fullWidth) {
        // make narrower to allow for addional columns
        let reduceTo = (fullWidth - 50) / fullWidth;
        fullWidth = fullWidth * reduceTo;
        rect.width = rect.width * reduceTo;
        chart.fullWidth = fullWidth;
      } else if (linkedChart.fullWidth) {
        let reduceTo = linkedChart.fullWidth / fullWidth;
        fullWidth = fullWidth * reduceTo;
        rect.width = rect.width * reduceTo;
        chart.fullWidth = fullWidth;
      }
      let right = rect.x + fullWidth;

      rect.x += (fullWidth - rect.width) / 2;
      rect.d3Shape.attr("x", rect.x);
      rect.d3Shape.attr("width", rect.width === 0 ? 1 : rect.width);

      if (!chart.subDimension) {
        if (rect.height >= 8) {
          // Add a text label for the value
          let lbl = labels.find((l) => l[chart.dimension] === data.y);
          let label = !lbl ? data.xValue : lbl.labelText;
          let band;
          let calc;
          if (
            linkedChart.value &&
            chart.linkedChartColourBands &&
            chart.linkedChartColourBands.length
          ) {
            if (linkedChart.value) {
              calc = lbl.labelPct / linkedChart.value;
              band = chart.linkedChartColourBands.find(
                (x) =>
                  (x.lowPct || 0) <= calc && (x.highPct || 999999999999) >= calc
              );
            }
          }
          svg
            .append("text")
            .attr("class", "barLabel")
            .attr("x", right + 20)
            .attr("y", rect.y + rect.height / 2 + 3.5)
            .style("text-anchor", "middle")
            .style("font-size", "10px")
            .style("font-family", "sans-serif")
            // .style("fill", band ? band.colour : "")
            // .style("pointer-events", "none")
            .text(label); // .append("title").text(band ? band.colour : "no colour");
          if (calc) {
            svg
              .append("text")
              .attr("class", "barLabel")
              .attr("x", right + 70)
              .attr("y", rect.y + rect.height / 2 + 3.5)
              .style("text-anchor", "middle")
              .style("font-size", "10px")
              .style("font-family", "sans-serif")
              .style("fill", band ? band.colour : "")
              // .style("pointer-events", "none")
              .text((calc * 100).toFixed(1) + "%"); // .append("title").text(band ? band.colour : "no colour");
          }
          svg
            .append("text")
            .attr("class", "barLabel")
            .attr("x", 0)
            .attr("y", rect.y + rect.height / 2 + 3.5)
            .style("text-anchor", "left")
            .style("font-size", "10px")
            .style("font-family", "sans-serif")
            // .style("opacity", 0.6)
            // .style("pointer-events", "none")
            .text(data.y);
          if (chart.linkedValueTitle && lbl.labelPct === null) {
            //   let y = 10;
            chart.linkedValueTitle.split(" ").forEach((t, ti) => {
              svg
                .append("text")
                .attr("class", "barLabel")
                .attr("x", right + 72)
                .attr("y", 10 * (ti + 1)) //rect.y - rect.height)
                .style("text-anchor", "middle")
                .style("font-size", "10px")
                .style("font-family", "sans-serif")
                .text(t);
            });
          }
        }
      }
    };
  },
  verticalBarLabels: function (svg, chart, allData) {
    return function (shape, data) {
      var rect = chartHelper.getBar(shape);
      chartHelper.preselectElement(rect.d3Shape, chart, data.x);
      chartHelper.preselectSelected(chart);
      if (chart.showBarLabels && rect.height > 20) {
        const actualValue =
          data.yValue > 10000
            ? d3.format(",.1f")(data.yValue / 1000) + "k"
            : data.yValue;
        let percent;
        if (chart.subAsPercent) {
          percent = (data.height * 100).toFixed(0);
        } else {
          const xTotal = allData
            .filter((d) => d[chart.dimension] == data.x)
            .reduce((a, b) => a + b[chart.measure], 0);
          percent = ((100 * data.height) / xTotal).toFixed(0);
        }

        svg
          .append("text")
          .attr("class", "barLabel")
          .attr("x", rect.x + rect.width / 2)
          .attr("y", rect.y + 15)
          .style("text-anchor", "middle")
          .style("opacity", 0.6)
          .style("pointer-events", "none")
          .text(actualValue);

        if (rect.height > 40) {
          svg
            .append("text")
            .attr("class", "barLabel")
            .attr("x", rect.x + rect.width / 2)
            .attr("y", rect.y + 30)
            .style("text-anchor", "middle")
            .style("opacity", 0.6)
            .style("pointer-events", "none")
            .text(`(${percent}%)`);
        }
      }
    };
  },
  pieLabels: function (svg, chart) {
    return function (shape, data) {
      var arc = d3
        .arc()
        .outerRadius(data.outerRadius)
        .innerRadius(data.innerRadius);
      chartHelper.preselectElement(arc, chart, data.value);
      chartHelper.preselectSelected(chart);

      const ctm = shape.getCTM();
      const centroid = arc.centroid(data);

      if (centroid[0] && centroid[1] && data.piePct > 0.05) {
        svg
          .append("text")
          // Position text in the centre of the shape
          .attr("x", centroid[0])
          .attr("y", centroid[1])
          .attr("class", "barLabel")
          .attr("transform", function () {
            return "translate(" + ctm.e + "," + ctm.f + ")";
          })
          // Centre align and nicer display
          .style("text-anchor", "middle")
          .style("font-size", "10px")
          .style("opacity", 0.8)
          // Prevent text cursor on hover and allow tooltips
          .style("pointer-events", "none")
          // Display text
          .text((data.piePct * 100).toFixed(0) + "%");
      }
    };
  },
  preselectElement(shape, chart, value) {
    if (
      chart.isSingleSelect &&
      chart.navItems[1].svgElement === null &&
      chart.navItems[1].text === value
    ) {
      chart.navItems[1].svgElement = shape;
      chartHelper.elementSelect(shape, true);
    }
  },
  preselectSelected(chart) {
    chart.selected.forEach((s) => {
      chartHelper.elementSelect(
        d3
          .select(chart.element)
          .selectAll(`.dimple-${s.toLowerCase().replace(" ", "-")}`),
        true
      );
    });
  },
  elementSelect(shape, select) {
    if (shape) {
      shape.style("stroke", select ? "black" : "gray");
      shape.style("stroke-width", select ? "2" : "1");
    }
  },
  dateJSToGroupString(groupBy, dateObj) {
    switch (groupBy) {
      case "year":
        return dateObj.format("YYYY");
      case "quarter":
        return `${dateObj.year()} Q${dateObj.quarter()}`;
      case "month":
      default:
        return dateObj.format("YYYY MM");
    }
  },
  dateToGroupString(groupBy, date) {
    return this.dateJSToGroupString(groupBy, dayJS(date));
  },
  groupBy: function (arr, key, measure, dateGroupBy) {
    const chartHelper = this;
    let list = [];
    arr.forEach(function (item) {
      let dKey = item[key];
      if (dateGroupBy) dKey = chartHelper.dateToGroupString(dateGroupBy, dKey);
      let row = list.filter(function (li) {
        return li[key] === dKey;
      })[0];
      if (!row) {
        row = {};
        row[key] = dKey;
        row[measure] = 0;
        list.push(row);
      }
      row[measure] += item[measure] || 0;
    });
    return list;
  },
  groupByKeys: function (arr, keys, measures, dateGroupBy) {
    const chartHelper = this;
    let list = [];
    arr.forEach(function (item) {
      let key = {},
        keyVal = "";
      keys.forEach(function (k, i) {
        let dKey = item[k];
        if (dateGroupBy && i === 0)
          dKey = chartHelper.dateToGroupString(dateGroupBy, dKey);
        key[k] = dKey;
        keyVal += "|" + dKey + "|";
      });

      let row = list.find(function (li) {
        return li.keyVal === keyVal;
      });
      if (!row) {
        row = { keyVal: keyVal };
        keys.forEach(function (k) {
          row[k] = key[k];
        });
        measures.forEach(function (m) {
          row[m] = item[m] || 0;
        });
        list.push(row);
      } else {
        measures.forEach(function (m) {
          row[m] += item[m] || 0;
        });
      }
    });
    return list;
  },
  groupByDateRange: function (arr, fromDateKey, toDateKey, subKeys, measures, dateGroupBy) {
    const chartHelper = this;
    let minDate = dayJS(arr[0][fromDateKey]);
    let maxDate = dayJS(arr[0][toDateKey]);
    arr.forEach(item => {
      minDate = dayJS.min(minDate, dayJS(item[fromDateKey]));
      maxDate = dayJS.max(maxDate, dayJS(item[toDateKey]));
    });
    if (dateGroupBy === "year") {
      minDate = minDate.month(0).date(1);
    } else if (dateGroupBy === "quarter") {
      const quarter = (minDate.quarter())
      minDate = minDate.month(0).date(1).quarter(quarter);
    } else {
      minDate = minDate.date(1);
    }

    const ranges = [];
    let currDate = minDate;
    while (currDate < maxDate) {
      ranges.push({
        fromDate: currDate,
        toDate: currDate.add(1, dateGroupBy),
        key: chartHelper.dateJSToGroupString(dateGroupBy, currDate)
      });
      currDate = currDate.add(1, dateGroupBy);
    }

    let list = [];
    arr.forEach(function (item) {
      let fromDate = dayJS(item[fromDateKey]),
        toDate = dayJS(item[toDateKey]);

      ranges
        .filter(r => r.fromDate.isSameOrBefore(toDate) && r.toDate.isAfter(fromDate))
        .forEach(r => {
          let key = { fromDate: r.key },
              keyVal = "|" + r.key + "|";
          subKeys.forEach(function (k) {
            let dKey = item[k];
            key[k] = dKey;
            keyVal += "|" + dKey + "|";
          });

          let row = list.find(function (li) {
            return li.keyVal === keyVal;
          });
          if (!row) {
            row = { keyVal: keyVal };
            row[fromDateKey] = r.key;
            subKeys.forEach(function (k) {
              row[k] = key[k];
            });
            measures.forEach(function (m) {
              row[m] = item[m] || 0;
            });
            list.push(row);
          } else {
            measures.forEach(function (m) {
              row[m] += item[m] || 0;
            });
          }
        });
    });
    return {
      dateRanges: ranges,
      list: list
    };
  },
  groupByM: function (arr, key, measures) {
    let list = [];
    arr.forEach(function (item) {
      let row = list.filter(function (li) {
        return li[key] === item[key];
      })[0];
      if (!row) {
        row = {};
        row[key] = item[key];
        measures.forEach(function (m) {
          row[m] = 0;
        });
        list.push(row);
      }
      measures.forEach(function (m) {
        if (item[m] !== 0) row[m] = (row[m] || 0) + item[m];
      });
    });
    return list;
  },
  distinctValues: function (arr, keys) {
    let list = [];
    arr.forEach(function (item) {
      let row = list.filter(function (li) {
        let match = true;
        keys.forEach(function (k) {
          if (li[k] !== item[k]) match = false;
        });
        return match;
      })[0];
      if (!row) {
        row = {};
        keys.forEach(function (k) {
          row[k] = item[k];
        });
        list.push(row);
      }
    });
    return list;
  },
  drawStackedBarChart: function (def, data) {
    // $(def.element + " svg").remove();
    d3.select(def.element).selectAll("*").remove();
    let SVG = dimple.newSvg(def.element, def.width, def.height),
      Chart = new dimple.chart(SVG, data);
    //   series = [];

    let xAxis = Chart.addCategoryAxis("x", def.xAxis),
      yAxis = Chart.addMeasureAxis("y", def.Measures[0].Property);
    //   axis;

    //series.push(Chart.addSeries(def.Measures[0].Property === def.Measures[0].Title ? def.Measures[0].Title + " " : def.Measures[0].Title, dimple.plot.line, [xAxis, yAxis]));
    //series[0].interpolation = "cardinal";
    //series[0].lineMarkers = true

    //for (let m = 1; m < def.Measures.length; m++) {
    //    axis = Chart.addMeasureAxis(yAxis, def.Measures[m].Property);
    //    if (def.yAxisFormat) axis.tickFormat = def.yAxisFormat;
    //    series.push(Chart.addSeries(def.Measures[m].Property === def.Measures[m].Title ? def.Measures[m].Title + " " : def.Measures[m].Title, dimple.plot.line, [xAxis, axis]));
    //    series[m].interpolation = "cardinal";
    //    series[m].lineMarkers = true;
    //}

    def.Bars = Chart.addSeries(def.VerticalGroup, dimple.plot.bar);

    xAxis.title = def.xAxisTitle;
    yAxis.title = def.yAxisTitle;
    if (def.yAxisFormat) yAxis.tickFormat = def.yAxisFormat;

    if (def.filterHandler) def.Bars.addEventHandler("click", def.filterHandler);
    Chart.addLegend(10, 2, def.width, def.width < 600 ? 40 : 25, "right"); // (x, y, width, height, horizontalAlign, series)
    Chart.setMargins(60, 20, 10, 50); // (left, top, right, bottom)
    //Chart.draw();
    def.redraw = true;
    //series.forEach(function (item) {
    //    item.shapes.style("opacity", function (d) {
    //        return (d.y === 0 ? 0 : 0.8);
    //    });
    //});
    def.Chart = Chart;
  },
  drawMultiSeriesChart: function (def, data) {
    // $(def.element + " svg").remove();
    d3.select(def.element).selectAll("*").remove();
    let SVG = dimple.newSvg(def.element, def.width, def.height),
      Chart = new dimple.chart(SVG, data),
      series = [];

    let xAxis = Chart.addCategoryAxis("x", def.xAxis),
      yAxis = Chart.addMeasureAxis("y", def.Measures[0].Property),
      axis;

    series.push(
      Chart.addSeries(
        def.Measures[0].Property === def.Measures[0].Title
          ? def.Measures[0].Title + " "
          : def.Measures[0].Title,
        dimple.plot.line,
        [xAxis, yAxis]
      )
    );
    series[0].interpolation = "cardinal";
    series[0].lineMarkers = true;

    for (let m = 1; m < def.Measures.length; m++) {
      axis = Chart.addMeasureAxis(yAxis, def.Measures[m].Property);
      if (def.yAxisFormat) axis.tickFormat = def.yAxisFormat;
      series.push(
        Chart.addSeries(
          def.Measures[m].Property === def.Measures[m].Title
            ? def.Measures[m].Title + " "
            : def.Measures[m].Title,
          dimple.plot.line,
          [xAxis, axis]
        )
      );
      series[m].interpolation = "cardinal";
      series[m].lineMarkers = true;
    }

    yAxis.title = def.yAxisTitle; // "Sales";
    if (def.yAxisFormat) yAxis.tickFormat = def.yAxisFormat;

    Chart.addLegend(10, 2, def.width, def.width < 600 ? 40 : 25, "right"); // (x, y, width, height, horizontalAlign, series)
    Chart.setMargins(60, 20, 10, 50); // (left, top, right, bottom)
    //Chart.draw();
    def.redraw = true;
    series.forEach(function (item) {
      item.shapes.style("opacity", function (d) {
        return d.y === 0 ? 0 : 0.8;
      });
    });
    def.Chart = Chart;
  },
  getChartSize: function (chart) {
    let win = { w: window.innerWidth, h: window.innerHeight };
    switch (chart) {
      case "AreaMapChart":
        if (win.w > 1200) return { width: win.w * 0.4, height: -1 };
        else if (win.w > 850) return { width: win.w * 0.45, height: -1 };
        else return { width: win.w * 0.9, height: -1 };
      case "EmbeddedMapChart":
        if (win.w > 1100) return { width: 400, height: 150 };
        else return { width: 300, height: 100 };
    }
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/vars";

h2 {
  margin: 0px;
}
.chartTitle {
  text-align: center;
  width: 70%;
  margin-top: 20px;
}

::v-deep .v-breadcrumbs {
  .v-breadcrumbs__item:not(.v-breadcrumbs__item--disabled) {
    cursor: pointer;
  }
}

.card-content {
  padding: 15px 15px 10px;
}

.childSelect {
  border: solid 2px transparent;
  border-radius: 32px;
  min-height: 242px;
}
.v-card.theme--light {
  .childSelect {
    &:hover {
      border-color: $input-border-color;
    }
    &.active {
      border-color: $primary-color-light;
      .chartTitle {
        color: $primary-color-light;
      }
    }
  }
}

.v-card.theme--dark {
  .childSelect {
    &:hover {
      border-color: $input-border-color-dark;
    }
    &.active {
      border-color: $primary-color-dark;
      .chartTitle {
        color: $primary-color-dark;
      }
    }
  }
}

.jvectormap-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  touch-action: none;
}

.mapCard,
.v-breadcrumbs {
  padding: 1px;
}
</style>