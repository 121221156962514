import axios from "axios";

export const state = () => ({
	initialised: false,
	loading: false,
	hierarchies: [],
	hierarchyLookup: {},
	docClassifiers: [],
	docClassifierLookup: {},
	docClassifierRules: [],
	docTypes: [],
	statuses: [],
	lifecycleStatuses: [],
	recruiters: [],
	managers: [],
	docNames: [],
	canAddHierarchy: -1,
	canAddRecruiter: -1,
	tagTypes: [],
	tagClassififcations: [],
	tagCategories: [],
	tagGradingTypes: [],
	tagPermissions: {},
});

export const mutations = {
	initialised(state) {
		state.initialised = true;
		state.loading = true;
	},
	clear(state) {
		state.hierarchies.splice(0);
		state.hierarchyLookup = {};
		state.docClassifiers.splice(0);
		state.docClassifierLookup = {};
		state.docClassifierRules.splice(0);
		state.docTypes.splice(0);
		state.statuses.splice(0);
		state.lifecycleStatuses.splice(0);
		state.recruiters.splice(0);
		state.managers.splice(0);
		state.docNames.splice(0);
		state.tagTypes.splice(0);
		state.tagClassififcations.splice(0);
		state.tagCategories.splice(0);
		state.tagGradingTypes.splice(0);
		state.tagPermissions = {};
	},
	unload(state) {
		state.initialised = false;
		state.loading = false;
		mutations.clear(state);
	},
	replace(state, data) {
		mutations.clear(state);
		state.hierarchies = data.hierarchies;
		state.hierarchyLookup = data.hierarchyLookup;
		state.docClassifiers = data.docClassifiers;
		state.docClassifierLookup = data.docClassifierLookup;
		state.docClassifierRules = data.docClassifierRules;
		state.docTypes = data.docTypes;
		state.statuses = data.statuses;
		state.lifecycleStatuses = data.lifecycleStatuses;
		state.recruiters = data.recruiters;
		state.managers = data.managers;
		state.docNames = data.docNames;
		state.canAddHierarchy = data.canAddHierarchy;
		state.canAddRecruiter = data.canAddRecruiter;
		state.tagTypes = data.tagTypes;
		state.tagClassififcations = data.tagClassififcations;
		state.tagCategories = data.tagCategories;
		state.tagGradingTypes = data.tagGradingTypes;
		state.tagPermissions = data.tagPermissions;
		state.loading = false;
	},
	replaceClassificationRules(state, rules) {
		state.docClassifierRules = rules;
	},
	update_tag_type(state, item) {
		let type = state.tagTypes.find((t) => t.tag_type_id === item.tag_type_id);
		if (type) {
			type.tag_type_name = item.tag_type_name;
		} else {
			state.tagTypes.push({ 
				tag_type_id: item.tag_type_id,
				tag_type_name: item.tag_type_name,
				values: [],
				ratings: [],
				maxRating: 0,
			});
		}
	},
	update_tag_type_rating(state, item) {
		let type = state.tagTypes.find((t) => t.tag_type_id === item.tag_type_id);
		if (type) {
			let rating = type.ratings.find(x => x.tag_type_rating_id === item.tag_type_rating_id);
			if (rating) {
				rating.name = item.name;
				rating.description = item.description;
				rating.sequence = item.sequence;
			} else {
				type.ratings.push(item);
			}
			type.ratings.sort((a, b) => a.sequence > b.sequence ? 1 : a.sequence < b.sequence ? -1 : 0);
			type.maxRating = !type.ratings.length ? 0 : type.ratings[type.ratings.length - 1].sequence;
		}
	},
	update_tag_value(state, item) {
		let type = state.tagTypes.find((t) => t.tag_type_id === item.tag_type_id);
		if (type) {
			if (item.tag_classification_id) {
				let tClass = state.tagClassififcations.find(x => x.tag_classification_id === item.tag_classification_id);
				if (tClass) {
					tClass.name = item.tag_classification_name;
					tClass.description = item.tag_classification_description;
				} else {
					state.tagClassififcations.push({
						tag_classification_id: item.tag_classification_id,
						name: item.tag_classification_name,
						description: item.tag_classification_description,
					});
				}
			}
			if (item.tag_category_id) {
				let tCat = state.tagCategories.find(x => x.tag_category_id === item.tag_category_id);
				if (tCat) {
					tCat.name = item.tag_category_name;
					tCat.description = item.tag_category_description;
				} else {
					state.tagCategories.push({
						tag_category_id: item.tag_category_id,
						name: item.tag_category_name,
						description: item.tag_category_description,
					});
				}
			}
			let tValue = type.values.find(x => x.tag_value_id === item.tag_value_id);
			if (tValue) {
				tValue.value = item.value;
				tValue.description = item.description;
				tValue.tag_classification_id = item.tag_classification_id;
				tValue.tag_classififcation_name = item.tag_classififcation_name;
				tValue.tag_classififcation_description = item.tag_classififcation_description;
				tValue.tag_category_id = item.tag_category_id;
				tValue.tag_category_name = item.tag_category_name;
				tValue.tag_category_description = item.tag_category_description;
			} else {
				type.values.push(item);
			}
			type.values.sort(sortTagValues);
		}
	},
	addTag(state, item) {
		let type = state.tagTypes.find((t) => t.tag_type_id === item.tag_type_id);
		if (type) {
			let val = type.values.find(x => x.tag_value_id <= 0 && x.value === item.value);
			if (val) {
				val.tag_value_id = item.tag_value_id;
			} else if (!type.values.find(x => x.tag_value_id === item.tag_value_id)) {
				type.values.push({
					description: "",
					tag_category_description: "",
					tag_category_id: null,
					tag_category_name: "",
					tag_classification_id: null,
					tag_classififcation_description: "",
					tag_classififcation_name: "",
					tag_type_id: item.tag_type_id,
					tag_value_id: item.tag_value_id,
					value: item.value,
				});
			}
			type.values.sort(sortTagValues);
		}
	},
	addTagClassififcation(state, item) {
		state.tagClassififcations.push(item);
	},
	addTagCategory(state, item) {
		state.tagCategories.push(item);
	},
	updateTagGradingType(state, item) {
		let type = state.tagGradingTypes.find((t) => t.tag_grading_type_id === item.tag_grading_type_id);
		if (type) {
			type.name = item.name;
			type.description = item.description;
		} else {
			state.tagGradingTypes.push({ 
				tag_grading_type_id: item.tag_grading_type_id,
				name: item.name,
				description: item.description,
				values: [],
			});
		}
	},
	updateTagGradingTypeValue(state, item) {
		let type = state.tagGradingTypes.find((t) => t.tag_grading_type_id === item.tag_grading_type_id);
		if (type) {
			let value = type.values.find(x => x.tag_grading_type_value_id === item.tag_grading_type_value_id);
			if (value) {
				value.name = item.name;
				value.description = item.description;
				value.sequence = item.sequence;
			} else {
				type.values.push({
					tag_grading_type_value_id: item.tag_grading_type_value_id,
					name: item.name,
					description: item.description,
					sequence: item.sequence,
				});
			}
		}
	},
};
function sortTagValues(a, b) {
	const av = a.value.toLowerCase();
	const bv = b.value.toLowerCase();
	return av > bv ? 1 : av < bv ? -1 : 0;
}
function buildHierarchyLookup(hierarchies) {
	const lookup = {}
	hierarchies.forEach(h => {
		h.values.forEach(v => {
			lookup['h' + v.value] = {
				ht_id: h.ht_id,
				ht_name: h.ht_name,
				hr_id: v.value,
				hr_code: v.hr_code,
				position: h.position,
				hierarchy_text: v.hierarchy_text,
				hierarchy1: v['level' + h.col1],
				hierarchy2: v['level' + h.col2],
				hierarchy3: v['level' + h.col3],
				hierarchy4: v['level' + h.col4],
				hierarchy5: v['level' + h.col5],
				hierarchy6: v['level' + h.col6],
				region: v['level' + h.region_position],
				country: v['level' + h.country_position],
				area: v['level' + h.area_position]
			}
		});
	});
	return lookup;
}

function buildClassifierLookup(classifiers) {
	const lookup = {}
	classifiers.forEach(c => {
		c.values.forEach(v => {
			lookup['c' + v.cv_id] = {
				ct_id: c.ct_id,
				ct_name: c.name,
				cv_id: v.cv_id,
				value: v.value
			}
		});
	});
	return lookup;
}

function setHierarchies(hierarchies) {
	let cntr = 1;
	hierarchies.forEach((n) => {
		let doneLevels = false;
		n.selectNew = false;
		n.showNewPrompt = false;
		n.searchText = "";
		n.items = [];
		n.itemsActive = [];
		n.itemsNotHidden = [];
		n.levels = [];
		n.h_levels = [];
          let i = 1;
          while (i <= n.linklevel) {
            n.h_levels.push(n[`level${i}_name`]);
            i++;
          }
		n.values.forEach((v) => {
			let ignore = false;
			let i = 1;
			let parent = n.items;
			let parentActive = n.itemsActive;
			let parentNotHidden = n.itemsNotHidden;
			while (i <= n.linklevel) {
				let val = v[`level${i}`];
				if (n[`use_h${i}`]) {
					if (!val) ignore = true;
					if (!ignore) {
						let item = parentNotHidden.find((s) => s.text === val);
						if (!item) {
							item = { text: val, level: i, items: [] };
							if (i === n.linklevel) item.hr_id = v.value;
							item.node_id = v[`hierarchy_node_id_level${i}`];
							parentNotHidden.push(item);
							if (!v.hide_in_picker) parent.push(item);
						}
						parentNotHidden = item.items;
						if (v.hr_active && !v.hide_in_picker) {
							let itemActive = parentActive.find((s) => s.text === val);
							if (!itemActive) {
								itemActive = { text: val, level: i, items: [] };
								if (i === n.linklevel) itemActive.hr_id = v.value;
								itemActive.node_id = v[`hierarchy_node_id_level${i}`];
								parentActive.push(itemActive);
							}
							parentActive = itemActive.items;
						}
					}
					if (!doneLevels) {
						n.levels.push({ index: i, name: n[`level${i}_name`], selected: null, items: [] });
					}
				}
				i++;
			}
			doneLevels = true;
		});
		if (n.levels.length)
			n.levels[0].items = n.items;
		// set up tree picker
		const getItems = (items) => {
			return items
				.filter((x) => x.hr_id || (x.items && x.items.length))
				.map((x) => {
					const hasChildren = x.items && x.items.length;
					const ret = {
						id: hasChildren ? x.text + cntr : x.hr_id,
						node_id: x.node_id,
						label: x.text,
						name: x.text,
					};
					cntr++;
					if (hasChildren) {
						ret.children = getItems(x.items);
					}
					return ret;
				})
				.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0);
		};
		n.treePickerValue = null;
		n.treePickerOptions = getItems(n.items);
		n.treePickerOptionsActive = getItems(n.itemsActive);
		n.treePickerOptionsNotHidden = getItems(n.itemsNotHidden);
		n.allowAddLeafNode = false;
		n.newLeafNodeValue = null;
	});
	return hierarchies;
}

function setupClassificationRules(rules, hierarchies) {
	rules.forEach(dcr => {
		let rowSpecificity = 0;
		hierarchies.forEach(ht => {
			const criteria = dcr.criteria.find(c => c.ht_id === ht.ht_id);
			let specificity = 0;
			if (criteria) {
				if (criteria.h_level6) {
					specificity = 6;
				} else if (criteria.h_level5) {
					specificity = 5;
				} else if (criteria.h_level4) {
					specificity = 4;
				} else if (criteria.h_level3) {
					specificity = 3;
				} else if (criteria.h_level2) {
					specificity = 2;
				} else if (criteria.h_level1) {
					specificity = 1;
				}
			}
			rowSpecificity += specificity;
		});
		dcr.specificity = rowSpecificity;
	})

	rules.sort((a, b) => {
		if (a.specificity === b.specificity)
			return (a.dcr_id > b.dcr_id) ? 1 : -1;
		else
			return (a.specificity < b.specificity) ? 1 : -1;
	});

	return rules;
}

let hierarchyRefreshTimeout;

export const actions = {
	init({ commit, getters }) {
		if (!getters.isInitialised) {
			commit("initialised");
			actions.getAllHierarchies({ commit, getters });
		}
	},
	unload({ commit, getters }) {
		if (getters.isInitialised) {
			if (hierarchyRefreshTimeout)
				clearTimeout(hierarchyRefreshTimeout);

			commit("unload");
		}
	},
	refresh({ commit, getters }) {
		if (!getters.isInitialised || getters.loading) {
			return;
		}

		if (hierarchyRefreshTimeout)
			clearTimeout(hierarchyRefreshTimeout);

		actions.getAllHierarchies({ commit, getters });
	},
	updateClassificationRules({ commit, getters }, rules) {
		if (!getters.isInitialised) return;

		commit("replaceClassificationRules", rules);
	},
	getAllHierarchies({ commit, getters }) {
		if (!getters.isInitialised) return;

		axios
			.get("document/getHierarchyValues/")
			.then((resp) => {
				if (resp.data.Status === "OK") {
					const data = {};
					data.hierarchies = setHierarchies(resp.data.Data.hierarchies);
					data.hierarchyLookup = buildHierarchyLookup(resp.data.Data.hierarchies);
					data.docClassifiers = resp.data.Data.docClassifiers || [];
					data.docClassifierLookup = buildClassifierLookup(resp.data.Data.docClassifiers || []);
					data.docClassifierRules = setupClassificationRules(resp.data.Data.docClassifierRules || [], data.hierarchies);
					data.docTypes = resp.data.Data.docTypes || [];
					data.statuses = resp.data.Data.statuses || [];
					data.lifecycleStatuses = resp.data.Data.lifecycleStatuses || [];
					data.recruiters = resp.data.Data.lookups.filter(
						(x) => x.type === "Recruiter"
					);
					data.managers = resp.data.Data.lookups.filter(
						(x) => x.type === "Manager"
					);
					data.docNames = resp.data.Data.docTypes
						.sort((a, b) => a.tt_id - b.tt_id)
						.map((dt) => dt.tmpl_name);
					data.canAddHierarchy = Number(resp.data.Data.canAddHierarchy);
					data.canAddRecruiter = Number(resp.data.Data.canAddRecruiter);
					data.tagTypes = resp.data.Data.tagTypes || [];
					data.tagClassififcations = resp.data.Data.tagClassififcations || [];
					data.tagCategories = resp.data.Data.tagCategories || [];
					data.tagGradingTypes = resp.data.Data.tagGradingTypes || [];
					data.tagPermissions = resp.data.Data.tagPermissions || {};
					commit("replace", data);
					//refresh every 15 mins
					hierarchyRefreshTimeout = setTimeout(() => actions.getAllHierarchies({ commit, getters }), 15 * 60 * 1000);
				}
			})
			.catch((err) => {
				if (err.response && err.response.status === 401) {
					commit("unload");
				} else {
					//try again in 30 secs
					hierarchyRefreshTimeout = setTimeout(() => actions.getAllHierarchies({ commit, getters }), 30 * 1000);
				}
				console.log(err);
			});
	},
	getNodes({ commit, getters }, ht_id) {
		if (commit && getters)
			return new Promise((resolve, reject) => {
				axios
					.post("hierarchy/HierarchyNodeStructure/", { ht_id: ht_id })
					.then((resp) => {
						resolve(resp);
					})
					.catch((err) => {
						reject(err);
					});
			});
	},
	addTag({ commit }, data) {
		commit("addTag", data);
	},
	saveItem({ commit }, item) {
		if (!getters.isInitialised) return;
		return new Promise((resolve, reject) => {
			axios
				.post(`document/saveItem`, item)
				.then((resp) => {
					if (resp.data.Data.length && resp.data.Data[0].length) commit(`update_${item.entity_type}`, resp.data.Data[0][0]);
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	saveTagValue({ commit }, tag, statusChange) {
		return this.saveItem(commit, tag, "TagValue", statusChange);
		// if (!getters.isInitialised) return;
		// return new Promise((resolve, reject) => {
		// 	axios
		// 		.post("document/saveTag/", tag)
		// 		.then((resp) => {
		// 			if (resp.data.Data.length && resp.data.Data[0].length) commit("updateTag", resp.data.Data[0][0]);
		// 			resolve(resp);
		// 		})
		// 		.catch((err) => {
		// 			reject(err);
		// 		});
		// });
	},
	saveTagType({ commit }, tag, statusChange) {
		return this.saveItem(commit, tag, "TagType", statusChange);
		// if (!getters.isInitialised) return;
		// return new Promise((resolve, reject) => {
		// 	axios
		// 		.post("document/saveTagType/", tag)
		// 		.then((resp) => {
		// 			if (resp.data.Data.length && resp.data.Data[0].length) commit("updateTagType", resp.data.Data[0][0]);
		// 			resolve(resp);
		// 		})
		// 		.catch((err) => {
		// 			reject(err);
		// 		});
		// });
	},
	saveTagData({ commit }, data) {
		if (!getters.isInitialised) return;
		return new Promise((resolve, reject) => {
			axios
				.post(`document/saveTagData/`, data)
				.then((resp) => {
					if (resp.data.Data.length && resp.data.Data[0].length)
						commit(`update${data.transaction}`, resp.data.Data[0][0]);
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	getNodeActions({ commit, getters }, node) {
		if (commit && getters)
			return new Promise((resolve, reject) => {
				axios
					.post("hierarchy/getNodeActions/", node)
					.then((resp) => {
						resolve(resp);
					})
					.catch((err) => {
						reject(err);
					});
			});
	},
};

export const getters = {
	isInitialised: (state) => {
		return state.initialised;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
