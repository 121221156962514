import { render, staticRenderFns } from "./cUploadUsers.vue?vue&type=template&id=4edbb0b2&scoped=true"
import script from "./cUploadUsers.vue?vue&type=script&lang=js"
export * from "./cUploadUsers.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4edbb0b2",
  null
  
)

export default component.exports