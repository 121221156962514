<template>
  <div class="home">
    <DocsWithParts
      v-if="!newUi"
      @sessionExpired="sessionExpired"
      @openDocument="openDocument"
      @copyDocument="copyDocument"
      @documentChangedHandler="documentChangedHandler"
    ></DocsWithParts>

    <DocsWithPartsNew
      v-if="newUi"
      :fullScreen="!myJobs"
      :pageSize="myJobs ? 5 : 0"
      :showFooter="true"
      :filters="{ myJobs: myJobs }"
      :enablePreview="!myJobs"
      @sessionExpired="sessionExpired"
      @openDocument="openDocument"
      @copyDocument="copyDocument"
      @documentChangedHandler="documentChangedHandler"
    ></DocsWithPartsNew>
  </div>
</template>

<script>
import DocsWithParts from "@/components/cDocumentsWithParts"; // @ is an alias to /src
import DocsWithPartsNew from "@/components/cDocumentsWithPartsNew";
import { mapState } from "vuex";

export default {
  name: "vDocumentsWithParts",
  components: {
    DocsWithParts,
    DocsWithPartsNew,
  },
  props: {
  },
  mounted() {},
  computed: {
    myJobs: function () {
      return this.$route.name == "myjobs";
    },
    ...mapState({
      newUi: (state) => state.theme.newUi,
    }),
  },
  methods: {
    sessionExpired(err) {
      this.$emit("sessionExpired", err);
    },
    openDocument(item) {
      this.$emit("openDocument", item);
    },
    copyDocument(item, tmpl_id, uploaded, action_available_id_copy) {
      this.$emit("copyDocument", item, tmpl_id, uploaded, action_available_id_copy);
    },
    documentChangedHandler(item) {
      this.$emit("documentChangedHandler", item);
    },
  },
};
</script>