export const state = () => ({
    screenName: 'Home'
})

export const mutations = {
    replaceName(state, name) {
        state.screenName = name;
    },
}

export const actions = {

}

export const getters = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}