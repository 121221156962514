<template>
<div>    
              <v-card flat class="overflow-hidden">
                <v-app-bar flat absolute color="white" elevate-on-scroll height="75px">
                  <v-row width="100%" dense>
                  <v-col cols="6" dense>
                    <v-btn dense v-for="(part, index) in docParts" :key="index" class="ma-2" :color="part.color" dark @click="setPart(part)" style="bottom: 5px">
                      {{part.dp_name}}
                      <v-icon v-if="activePart === part" right>
                        mdi-check-circle
                      </v-icon>
                    </v-btn>
                  </v-col>    
                  <v-col cols="3" align="right">
                    <v-combobox
                      v-model="selectedLinkedDocs"
                      :items="linkedDocs"
                      label="Create Document(s)"
                      multiple autocomplete="chrome-off"
                      hide-details
                      outlined type='text'
                      searchable="false"
                      dense
                    ></v-combobox>
                  </v-col>                    
                  <v-col cols="3" align="center">
                    <v-badge v-if="selectedFiles.length > 0" :content="selectedFiles.length" offset-x="12" offset-y="12" color="indigo" style="margin-left:25px">
                    <v-btn fab dark small color="indigo" title="Regenerate Preview" @click="processItem()">
                      <v-icon>mdi-file-search</v-icon>
                    </v-btn>
                    </v-badge>
                    
                    <v-btn v-else fab dark small color="indigo" title="Regenerate Preview" @click="processItem()" style="margin-left:25px">
                      <v-icon>mdi-file-search</v-icon>
                    </v-btn>
                    <v-badge v-if="selectedFiles.length > 0" :content="selectedFiles.length" offset-x="12" offset-y="12" color="green" style="margin-left:25px">
                    <v-btn fab dark small color="green" title="Create Docs" @click="createDocsNew()" >
                      <v-icon>mdi-file</v-icon>
                    </v-btn>
                    </v-badge>  
                    <v-btn v-else fab dark small color="green" title="Create Docs" style="margin-left:25px" @click="createDocsNew()" >
                      <v-icon>mdi-file</v-icon>
                    </v-btn>                                                    
                    <v-btn v-show="false" fab dark small color="indigo" @click="openConfigDialog()" title="Configuration" style="margin-left:25px">
                      <v-icon>mdi-wrench</v-icon>
                    </v-btn>                                                   
                    <v-btn fab dark small color="green" @click="saveSettings()" title="Save Settings" style="margin-left:25px">
                      <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                    
                    </v-col>   
                </v-row>                
                </v-app-bar>
                <v-sheet flat class="overflow-y-auto" height="calc(100vh - 100px)">
                  <v-container flat style="height: 100%;" >
                      <v-row style="margin-top:65px">
                        <v-col cols="8">
                          <v-card  @contextmenu="show">
                            <v-card-text class="text-xs-justify" @mouseup="mouseUpEvent($event)">
                              <div v-html="textHtml"></div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col cols="4" class="partsCol">
                          <v-row style="height: calc(100vh - 100px); overflow-y:scroll; padding-bottom:30px">
                            <v-col cols="12">
                              <v-card>
                                  <v-expansion-panels
                                    v-model="panel"
                                    multiple
                                    style="position:relative;"
                                  >
                                    <v-expansion-panel style="overflow-x:hidden" v-for="(part, index) in docParts" :key="index">
                                      <v-expansion-panel-header>{{part.dp_name}}
                                        <template v-slot:actions >
                                            <v-btn :color="part.color" fab x-small dark>{{getSettingContentLength(part.data_name)}}</v-btn>
                                        </template>

                                        </v-expansion-panel-header>
                                      <v-expansion-panel-content>
                                        <v-btn v-for="(item, index) in allSettings[part.data_name]" :key="index" class="ma-2" :color="part.color" dark>
                                          {{item}} 
                                          <v-icon right small @click="deleteItem(part.data_name, item)">mdi-close</v-icon>
                                        </v-btn>                                
                                      </v-expansion-panel-content>
                                    </v-expansion-panel>   
                                    <v-expansion-panel style="overflow-x:hidden">
                                      <v-expansion-panel-header>Document Preview
                                        </v-expansion-panel-header>
                                      <v-expansion-panel-content>
                              <v-row>
                              <v-col cols="12">
                              <div>      
                              <v-row dense>
                                <v-col cols="12" sm="12"  md="12" >
                                  <h4>About Team:</h4>
                                </v-col>                                       
                              </v-row>              
                              <v-row dense>
                                <v-col cols="12" sm="12"  md="12" >
                                {{viewingItem.nlpContent.aboutTeam}}
                                </v-col>                                       
                              </v-row>                 
                              <v-row dense>
                                <v-col cols="12" sm="12"  md="12" >
                                  <h4>Job USP:</h4>
                                </v-col>                                       
                              </v-row>              
                              <v-row dense>
                                <v-col cols="12" sm="12"  md="12" >
                                {{viewingItem.nlpContent.uspText}}
                                </v-col>                                       
                              </v-row>
                              <v-row dense>
                                <v-col cols="12" sm="12"  md="12" >
                                  <h4>Responsibilities:</h4>
                                </v-col>                                       
                              </v-row>
                              <v-row dense>
                                <v-col cols="12" sm="12"  md="12" >
                                  <ul>
                                    <li v-for="item in viewingItem.nlpContent.responsibilities" :key="item.$index">
                                      {{item}}
                                    </li>
                                  </ul>
                                </v-col>                                       
                              </v-row>   
                              <v-row dense>
                                <v-col cols="12" sm="12"  md="12" >
                                  <h4>Requirements:</h4>
                                </v-col>                                       
                              </v-row>
                              <v-row dense>
                                <v-col cols="12" sm="12"  md="12" >
                                  <ul>
                                    <li v-for="item in viewingItem.nlpContent.requirements" :key="item.$index">
                                      {{item}}
                                    </li>
                                  </ul>
                                </v-col>                                       
                              </v-row>  
                              </div>
                            </v-col>
                                      </v-row>
                                      </v-expansion-panel-content>
                                    </v-expansion-panel>  
                            
                            </v-expansion-panels>                                                          
                          </v-card>
                                
                            </v-col>
                          </v-row>
                        </v-col>                        
                      </v-row>   
                  </v-container>
                </v-sheet>
              </v-card>
<v-menu
        v-model="showMenu"
        :position-x="x"
        :position-y="y"
        absolute
        offset-y
      >
        <v-list>
          <v-list-tile
            v-for="(item, index) in items"
            :key="index"
            @click="item.menuAction(item.title)"
          >
<!--TESTING
            works 'style 1'
            @click="menuClick(item.menuAction)" 
            
            testing 'sytle 2'
            @click="item.menuAction(item.title)"
-->

            <v-list-tile-title>{{ item.title }}</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>              
      <v-snackbar
        v-model="showErrorSnack"
        :timeout="snackTimeout"
        :color="snackColor"
        :multi-line="errorText.length > 50"
        top
      >
        <span v-html="errorText"></span>

        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    <Loading :isVisible="isLoading" />
</div>


</template>

<script>
import axios from "axios";
import nlpUtils from "@/common/nlpUtils.js";
import utils from "@/common/utils.js";


export default {
  name: 'documentTraining',
  data: function() {
    return {
        isLoading:false,
        errorText: "",
        showErrorSnack: false,
        snackColor: "error",
        snackTimeout: 4000,        
        tab: null,
        docParts: [
          {
            dp_name: "About the Team",
            icon:"mdi-account-circle-outline",
            color:"#e91e63de",
            data_name: "AboutTeamContent"
          },
          {
            dp_name: "Job USP",
            icon:"mdi-account-circle-outline",
            color:"#8f12a4cf",
            data_name: "UspContent"
          },
          {
            dp_name: "Requirements",
            icon:"mdi-account-circle-outline",
            color:"#3289cfe6",
            data_name: "ReqContent"
          },
          {
            dp_name: "Responsibilities",
            icon:"mdi-account-circle-outline",
            color:"#0e8013e0",
            data_name: "RespContent"
          }
        ],
        selectedText: [],
        textHtml: "", 
        activePart: {},
        allSettings: [],
        panel:[],
        processedItem: {},
        processedDialog: false,
        selectedFiles: [],   
        dialogConfig: false,  
        linkedDocs: [],   
        selectdTranslateLang: "",
        selectedLinkedDocs: [],     
   showMenu: false,
    x: 0,
    y: 0,
    items: [
      { title: 'style 1 title', menuAction: 'style 1' },
      { title: 'style 2 title', menuAction: action => { alert(action) } },
      { title: 'style 3 title', menuAction: action => { this.someFunc(action) } }
    ],                
        viewingItem:{
          file_id:0,
          file_parse:{
            content:"",
            lines:[]
          },
          nlpContent: {
            rawText: "",
            aboutTeam:"",
            jobUSP:"",
            CTA:"",
            location:"",
            reqText:[],
            respText:[],
            textItems:[],
            responsibilities:[],
            requirements: []
          }
        }        
    }
  },
  watch: {
    
  },
  created() {
  },
  async mounted() {
    this.isLoading = true;
    await this.setLinkedDocs();
    await this.loadSettings();
    await this.loadDoc(Number(this.$route.query.id))
    this.textHtml = this.textHtml.replaceAll("<em>","").replaceAll("</em>","")
    this.highlightExisting();
    this.activePart = this.docParts[0];
    
    this.isLoading = false;
  },
  components: {
  },

  computed: {
  },  
  methods:{
    show (e) {
      e.preventDefault()
      alert("stuff");
      this.showMenu = false
      this.x = e.clientX
      this.y = e.clientY
      this.$nextTick(() => {
        this.showMenu = true
      })
    }, 
    saveSettings(){
        this.isLoading = true;
        let newSettings = {};
        newSettings["AboutTeamContent"] = this.allSettings["AboutTeamContent"];
        newSettings["UspContent"] = this.allSettings["UspContent"];
        newSettings["ReqContent"] = this.allSettings["ReqContent"];
        newSettings["RespContent"] = this.allSettings["RespContent"];

        axios
        .post("client/settings/", newSettings)
        .then(resp => {
          if(resp.data.Status == "OK"){
              this.triggerNotification("Settings Saved Successfully","success");
          } else {
              this.triggerNotification(resp.data.Message,"error");
          }
          this.isLoading = false;

        })
        .catch(err => {
              this.triggerNotification(err,"error");
        });    

    },

    highlightExisting(){
      this.activePart = this.docParts[0];
      let config = this.allSettings["AboutTeamContent"];

      config.forEach(c => {
        this.highlightExistingValue(c);
      });

      this.activePart = this.docParts[1];
      config = this.allSettings["UspContent"];

      config.forEach(c => {
        this.highlightExistingValue(c);
      });
      
      this.activePart = this.docParts[2];
      config = this.allSettings["ReqContent"];

      config.forEach(c => {
        this.highlightExistingValue(c);
      });
      
      this.activePart = this.docParts[3];
      config = this.allSettings["RespContent"];

      config.forEach(c => {
        this.highlightExistingValue(c);
      });      

      this.activePart = this.docParts[0];
    },
    highlightExistingValue(value){
        let wrapper = this.generateWrapper(value);
        this.replaceText(value, wrapper);
        let obj = {
          originalText: value,
          wrapperText: wrapper,
          length: 0,
          type: this.activePart.dp_name
        }

        this.selectedText.push(obj);

    },
    async processItem(){
      
      this.isLoading = true;
      let ids = [this.$route.query.id];

      await nlpUtils.processFilesMulti(ids, this.allSettings).then(response => {
          if(response.data.Status == "OK"){
            //this.textHtml = data.data.Data[0].file_items[0].content.htmlContent.replaceAll("<em>","").replaceAll("</em>","");
            this.viewingItem.nlpContent = response.data.Data[0].file_items[0].content;
            
            this.isLoading = false;
          } else {
            this.triggerNotification(response.data.Message,"error");
          }
      });


    },
    deleteItem(list, item){

      let matched = false;
      let tagItem = null;

      this.selectedText.forEach(tag => {
        if(tag.originalText == item){
          matched = true;
          tagItem = tag;
        }
      })

      if(matched){
        this.replaceText(tagItem.wrapperText, tagItem.originalText);
        this.selectedText.splice(this.selectedText.indexOf(tagItem),1);
        //this.removeValueFromSettings(tagItem);
      }

      let idx = this.allSettings[list].indexOf(item);
      this.allSettings[list].splice(idx,1);


    },
    async createDocs(){
      
      this.isLoading = true;
      let data = {
        file_ids: this.$route.query.id,
        doc_types: this.selectedLinkedDocs
      }

      let response = await nlpUtils.createDocs(data);

      if(response.data.Status == "OK"){
        //this.textHtml = data.data.Data[0].file_items[0].content.htmlContent.replaceAll("<em>","").replaceAll("</em>","");
        
        this.triggerNotification("Document created","success");
        this.isLoading = false;
      } else {
        this.triggerNotification(response.data.Message,"error");
      }
    },
    async loadSettings(){
      await nlpUtils.loadSettings().then(response => {
      if(response.statusText == "OK"){

        this.allSettings =JSON.parse(JSON.stringify(response.data));
      } else {
        this.triggerNotification(response.data.Message,"error");
      }
      });
      
    },
    async setLinkedDocs(){
      await nlpUtils.fetchLinkedDocData("External File").then(response => {
      if(response.data.Status == "OK"){
        let itemarray = [];

        response.data.Data.forEach(item => {
          this.selectedLinkedDocs.push(item.tmpl_name);
          itemarray.push(item.tmpl_name);
        })

        this.linkedDocs = itemarray;
      } else {
        this.triggerNotification(response.data.Message,"error");
      }
      });
    },
    openConfigDialog(){
      this.dialogConfig = true;
    },       
    closeConfigDialog(){
      this.dialogConfig = false;
    },   
    closeDialog(){
      this.viewingMultiFile = null;
      this.processedDialog = false;
    },    
    setPart(part){
      this.activePart = part;
    },
    getSettingContentLength(part){
      let settingpart = this.allSettings[part];
      if(settingpart){
        return settingpart.length;
      }

      return 0;
    },
    generateWrapper(text){
      //let wrapper = `<button class="v-btn primary">${text}</button>`;
      let wrapper = `<span style="background-color:${this.activePart.color}" class="v-chip v-chip--label v-chip--removable theme--light v-size--default  white--text"><span class="v-chip__content">${text}<i aria-hidden="true" class="deleteTag v-icon notranslate v-icon--right mdi mdi-close theme--light" style="font-size: 16px; cursor:pointer;"></i></span></span>`;

      return wrapper;
    },
    mouseUpEvent(event){
      switch (event.which) {
        case 1:
            this.getSelection();
            break;
      }
    },
    highlightExists(selected) {
      let found = false;
      this.selectedText.forEach(highlight => {
        if (highlight.originalText.indexOf(selected) > -1) {
          found = true;
        }
      });

      return found;
    },    
    getSelection() {
      const selection = window.getSelection();
      let selectionText = selection.toString();
      if(selectionText !== ""){

        let exists = this.highlightExists(selectionText);
        
        if(!exists){
          const selectionStart = selection.anchorOffset;
          const selectionEnd = selection.focusOffset;
            var range = document.createRange();
            range.setStart(selection.anchorNode, selection.anchorOffset);
            range.setEnd(selection.focusNode, selection.focusOffset);
            var backwards = range.collapsed;
            range.detach();

            var endNode = selection.focusNode, endOffset = selection.focusOffset;
            selection.collapse(selection.anchorNode, selection.anchorOffset);
            if (backwards) {
                selection.modify("move", "backward", "character");
                selection.modify("move", "forward", "word");
                selection.extend(endNode, endOffset);
                selection.modify("extend", "forward", "character");
                selection.modify("extend", "backward", "word");

            } else {
                selection.modify("move", "forward", "character");
                selection.modify("move", "backward", "word");
                selection.extend(endNode, endOffset);
                selection.modify("extend", "backward", "character");
                selection.modify("extend", "forward", "word");
            }
          selectionText = selection.toString();
          //alert(selectionText);
          let wrapper = this.generateWrapper(selectionText);
          this.replaceText(selectionText, wrapper);

          let obj = {
            originalText: selectionText,
            wrapperText: wrapper,
            length: selectionEnd - selectionStart,
            type: this.activePart.dp_name
          }

          this.selectedText.push(obj);
          selection.removeRange(range);

          this.pushValueToSettings(obj);
        }



      } else{
        if(event.target.matches(".deleteTag")){
          let matched = false;
          let tagContent = event.target.parentNode.textContent;
          let tagItem = null;
          this.selectedText.forEach(tag => {
            if(tag.originalText == tagContent){
              matched = true;
              tagItem = tag;
            }
          })

          if(matched){
            this.replaceText(tagItem.wrapperText, tagItem.originalText);
            this.selectedText.splice(this.selectedText.indexOf(tagItem),1);
            this.removeValueFromSettings(tagItem);
          }
        }
      }

    },    
    removeValueFromSettings(obj){
      let settingIdx = -1;
      switch(obj.type){
        case "About the Team":
          settingIdx = this.allSettings.AboutTeamContent.indexOf(obj.originalText);
          this.allSettings.AboutTeamContent.splice(settingIdx,1);
          break;
        case "Job USP":
          settingIdx = this.allSettings.UspContent.indexOf(obj.originalText);
          this.allSettings.UspContent.splice(settingIdx,1);
          break;
        case "Requirements":
          settingIdx = this.allSettings.ReqContent.indexOf(obj.originalText);
          this.allSettings.ReqContent.splice(settingIdx,1);
          break;
        case "Responsibilities":
          settingIdx = this.allSettings.RespContent.indexOf(obj.originalText);
          this.allSettings.RespContent.splice(settingIdx,1);
          break;
      }
    },
    pushValueToSettings(obj){
      switch(obj.type){
        case "About the Team":
          this.allSettings.AboutTeamContent.push(obj.originalText);
          break;
        case "Job USP":
          this.allSettings.UspContent.push(obj.originalText);
          break;
        case "Requirements":
          this.allSettings.ReqContent.push(obj.originalText);
          break;
        case "Responsibilities":
          this.allSettings.RespContent.push(obj.originalText);
          break;
      }
    },
    replaceText(search, replace){
      this.textHtml = this.textHtml.replace(new RegExp(search, "g"), () => {
        return replace;
      });
      
      this.textHtml = JSON.parse(JSON.stringify(this.textHtml));
    },
  
    async loadDoc(id){
      
      this.isLoading = true;
      await nlpUtils.loadDoc([id], null).then(response => {
      if(response.data.Status == "OK"){
        this.textHtml = response.data.Data[0].file_items[0].content.htmlContent.replaceAll("<em>","").replaceAll("</em>","");
        this.viewingItem.nlpContent = response.data.Data[0].file_items[0].content;
        
        this.isLoading = false;
      } else {
        this.triggerNotification(response.data.Message,"error");
      }
      });
    },
    triggerNotification(text, type){
        this.errorText = text;
        this.snackColor = type;
        this.showErrorSnack = true;
    },  
    openDocument(link) {
      this.$emit("openDocument", {
        system_number: utils.removeTags(link.system_number),
        doc_name: utils.removeTags(link.doc_name),
        doc_type: link.doc_type,
      });
    },         
    async createDocsNew(){

      let data = {
        file_ids: [Number(this.$route.query.id)],
        doc_types: this.selectedLinkedDocs
      }

      await nlpUtils.createDocsNew(data).then(response => {
          if(response.data.Status == "OK"){
            this.triggerNotification("Document created","success");
            let targetDoc = response.data.Data[0].newDocuments[0];
            let openDoc = {
              system_number: targetDoc.system_number,
              doc_name: targetDoc.doc_name,
              doc_type: targetDoc.doc_type
            }
            this.openDocument(openDoc);
          } else {
            this.triggerNotification(response.data.Message,"error");
          }
      });
    },    
  }
}
</script>


<style scoped lang="scss">
    .partsCol {
    top: 140px;
    right: 10px;
    position: fixed;
    width: 100%;
    height: 100%;
    .v-card {
      background: transparent;
      border: 0;
      border-radius: 0;
      box-shadow: none;
    }
  }
.portrait.v-card {
  margin: 0 auto;
  max-width: 600px;
  width: 100%;  
  }
</style>