<template>
  <div>
    <v-dialog v-model="dialog" persistent width="1200">
      <v-card v-if="editedItem">
        <v-card-title class="px-8">
          <span class="headline">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <v-btn icon large class="btn-background" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pb-0">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-row dense>
                  <v-text-field
                    v-model="editedItem.hr_code"
                    label="Hierarchy Code"
                    dense
                    outlined
                    class="roundish"
                  ></v-text-field>
                </v-row>
                <v-row dense>
                  <v-select
                    :items="spellCheckLanguages"
                    clearable
                    :required="false"
                    label="Spell Check (other than default)"
                    v-model="editedItem.spell_check_language_code"
                    dense
                    outlined
                    class="roundish"
                  ></v-select>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-6 px-8">
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="close"> Cancel </v-btn>
          <v-btn color="primary" @click="save"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="showErrorSnack"
      :timeout="snackTimeout"
      :color="snackColor"
      :multi-line="errorText.length > 50"
      top
    >
      {{ errorText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "adminHierarchy",
  props: {
    node: Object,
    show: Boolean,
  },
  data: function () {
    return {
      dtItemsPerPage: 20,
      typeRuleOptions: [],
      spellCheckLanguages: ["us"],
      errorText: "",
      showErrorSnack: false,
      snackColor: "error",
      snackTimeout: 4000,
      editedItem: null,
      dialog: false,
      dialogDelete: null,
    };
  },
  components: {},
  watch: {
    show(val) {
      this.dialog = val;
      if (val) this.loadItem();
    },
  },
  computed: {
    formTitle() {
      return `${this.node.level_name}: ${this.node.name}`;
    },
  },
  created: function () {
    if (this.show) this.loadItem();
  },
  methods: {
    loadItem() {
      if (this.node &&  this.node.hr_id) {
        let possibleError = false;
        this.isLoading = true;
        axios
          .get("hierarchy/detail/" + this.node.hr_id.toString())
          .then((resp) => {
            possibleError = true;
            if (resp.data.Data && resp.data.Data.length)
              this.editedItem = resp.data.Data[0];

            this.dialog = true;
          })
          .catch((err) => {
            if (possibleError) {
              alert("Code Error");
            } else if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              alert(err.response ? err.response.data.message : err);
            }
            console.log(err);
            this.isLoading = false;
          });
      }
    },
    triggerNotification(text, type) {
      this.errorText = text;
      this.snackColor = type;
      this.showErrorSnack = true;
    },
    close() {
      this.editedItem = null;
      this.dialog = false;
      this.$emit("close");
    },
    save() {
      let data = this.editedItem;
      axios
        .post("hierarchy/save", data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            this.editedItem = null;
            this.dialog = false;
            this.$emit("saved", data);
            this.triggerNotification("Hierarchy Saved", "success");
          }
          this.response = resp.data;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
</style>