<template>
  <div v-if="nodes">
    <v-row
      v-for="(n, ni) in nodes.filter((x) => x.visible)"
      :key="'nhr' + n.level + '_' + ni"
    >
      <v-col>
        <v-row>
          <v-col v-if="n.level > 1" :cols="n.level - 1"></v-col>
          <v-col cols="3"
            ><v-badge
              v-if="n.changes"
              color="info"
              style="width: calc(100% - 44px)"
              :content="n.changes.length - 1"
              overlap
            >
              <div class="rowHeader" style="padding-top: 5px">{{ n.name }}</div>
            </v-badge>
            <div v-else class="rowHeader">{{ n.name }}</div>
            <v-icon
              v-if="n.changes"
              @click="n.showChanges = !n.showChanges"
              :title="n.showChanges ? 'Hide changes' : 'Show changes'"
              style="padding-left: 20px; margin-top: -15px"
              >{{ n.showChanges ? "mdi-eye-off" : "mdi-eye" }}</v-icon
            ></v-col
          >
          <v-col v-if="n.level < levels" :cols="levels - n.level"></v-col>
          <v-col v-if="n.changes">
            <!-- <v-row
              ><v-col @click="n.showChanges = !n.showChanges"
                >{{ n.showChanges ? "Hide" : "show" }} changes</v-col
              ></v-row
            > -->
            <v-row
              v-for="(c, ci) in n.changes.filter((x) => x && n.showChanges)"
              :key="'nhr' + n.level + '_' + ni + 'c' + ci"
            >
              <v-col><div v-html="c.title"></div></v-col>
              <v-col><div v-html="c.name_html"></div></v-col>
              <v-col><div v-html="c.description_html"></div></v-col>
            </v-row>
          </v-col>
        </v-row>
        <HierarchyTreeNodeAudit
          :nodes="n.nodes"
          :levels="levels"
        ></HierarchyTreeNodeAudit>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "HierarchyTreeNodeAudit",
  props: {
    nodes: [],
    levels: Number,
    showAll: Boolean,
  },
  data: function () {
    return {
      nodeDetails: null,
    };
  },
  components: {},
  watch: {},
  computed: {},
  created: function () {},
  methods: {
    pickNode(node) {
      this.$emit("pickNode", node);
    },
  },
};
</script>

<style scoped lang="scss">
.nodeContainer {
  width: 50%;
  height: 24px;
  padding-left: 6px;
}
.nodeSeleted {
  background-color: rgb(205, 243, 205);
}
.nodePicked {
  background-color: #e8e7e7;
}
.nodeInfoIcon {
  float: right;
  padding-top: 2px;
}
.nodeInner {
  display: block;
  height: 24px;
  font-family: "Martel Sans", sans-serif;
  font-size: 12px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 90%;
  float: left;
}
.node {
  border: solid 1px #004d40;
  border-radius: 5px;
  display: block;
  height: 24px;
  font-family: "Martel Sans", sans-serif;
  font-size: 12px;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  float: right;
}
.nodePickable {
  width: 80%;
}
.nodeNonPickable {
  width: 90%;
}
.nodeExpandable {
  cursor: pointer;
}
.pickable {
  width: 10%;
  float: right;
  cursor: grab;
}
</style>