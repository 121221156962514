<template>
  <div
    :class="{
      hcontainer: true,
      hasActionIcons: showActionIcons,
    }"
    :style="containerStyle"
    @mouseenter="hasHover"
    @mouseleave="lostHover"
    ref="container"
  >
    <div
      class="actionIcons"
      v-show="displaySaveCancel && isDirty && showActionIcons"
      :style="{
        top: cbVisible() ? '20px' : '2px',
        right: cbVisible() ? '10px' : 0,
      }"
    >
      <v-icon
        small
        color="green darken-1"
        class="mr-2"
        @click="clickSave"
        title="Save Changes"
        >mdi-checkbox-outline</v-icon
      >
      <v-icon
        small
        color="red darken-1"
        @click="$emit('escape')"
        title="Cancel Edit"
        >mdi-close-box-outline</v-icon
      >
    </div>
    <div v-if="rank_attribute" class="comboboxHolder">
      {{ selectedText }}
      <!-- <v-select
          v-model="selectedText"
          :items="[1,2,3,4,5,6,7]"
          :label="placeholder"
          @blur="lostFocus"
        >
      </v-select> -->
    </div>
    <div v-else-if="taVisible()">
      <div
        ref="backdrop"
        :class="{ backdrop: true, borderless: hideBorder }"
        :style="contentStyle"
      >
        <div class="highlights" v-html="highlightedText"></div>
      </div>
      <textarea
        :style="contentStyle"
        :class="{ borderless: hideBorder }"
        ref="textarea"
        :placeholder="placeholder"
        v-model="text"
        @keyup.stop="handleInput"
        @input="handleInput"
        @paste="checkRestrictedChar"
        @scroll="handleScroll"
        @contextmenu="handleContextMenu"
        @keydown.exact="checkRestrictions"
        @keydown.exact.shift="checkRestrictions"
        @keydown.ctrl.83.prevent.stop="doSave"
        @keydown.enter="doEnter"
        @keydown.esc.prevent.stop="$emit('escape')"
        @blur="lostFocus"
        @click="toggleLookupMenu"
        v-debounce:1500ms="performNlpChecks"
      ></textarea>
      <v-menu
        offset-y
        v-model="showLookup"
        :max-height="lookupMaxHeight"
        :top="lookupTop"
        :position-y="lookupPosY"
        :position-x="lookupPosX"
        :max-width="lookupWidth"
        :min-width="lookupWidth"
        :open-on-click="false"
        :close-on-content-click="false"
        content-class="elevation-2 userPilotSuggestionsMenu"
        @scroll="onLookupListScroll"
      >
        <v-tooltip bottom v-if="!isLoading && (suggestionType || summarise)">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="refreshAIContent"
              class="regenBtn"
            >
              <v-icon>refresh</v-icon>
            </v-btn>
          </template>
          <span>Generate New Suggestions</span>
        </v-tooltip>

        <v-card
          v-if="isLoading"
          class="d-flex text-center align-center flex-column pt-6"
        >
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
            style="top: 45%"
            class="mb-3"
          ></v-progress-circular>
          <p>
            <span
              v-if="suggestionType && suggestionType.indexOf('Job Title') < 0"
              v-html="config.contentGenerationConfig.disclaimer"
            ></span>
          </p>
        </v-card>
        <v-list v-else>
          <v-list-item
            v-for="(item, index) in filteredLookup"
            :key="index"
            :class="{
              lookupItem: true,
              selected: filteredLookupSelIdx === index,
            }"
            @click="setOrAppendContent(item.varVal)"
          >
            <v-list-item-content>
              <span v-if="item.title" v-text="item.title"></span>
              <span v-else v-html="item.marked"></span>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <div
        v-if="nlpCheckInProgress"
        :style="{
          height: contentStyle.height,
          cursor: 'pointer',
          position: 'absolute',
          right: '50px',
          top: 0,
          zIndex: 4,
        }"
        class="clearContent v-input__append-inner"
      >
        <v-progress-circular
          :width="2"
          :size="25"
          color="green"
          indeterminate
        ></v-progress-circular>
      </div>
      <div
        v-if="clearable && text.length"
        :style="{
          height: contentStyle.height,
          cursor: 'pointer',
          position: 'absolute',
          right: clearRight,
          top: 0,
          zIndex: 4,
        }"
        class="clearContent v-input__append-inner"
        @click="clearContent"
      >
        <!-- marginRight: '45px', -->
        <!-- @mouseenter="hasHover"
        @mouseleave="lostHover" -->
        <v-icon title="Clear content">mdi-close</v-icon>
      </div>
      <v-menu
        v-model="alternative.show"
        :position-x="alternative.x"
        :position-y="alternative.y"
        absolute
        offset-y
      >
        <v-list>
          <v-subheader>
            {{ alternative.title }}
            <v-tooltip bottom v-if="alternative.explanation">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on"
                  >mdi-help-circle-outline</v-icon
                >
              </template>
              <span>{{ alternative.explanation }}</span>
            </v-tooltip>
          </v-subheader>
          <v-divider></v-divider>
          <v-list-item
            v-for="(item, index) in alternative.list"
            :key="index"
            @click="doReplace(item)"
          >
            <v-hover v-slot="{ hover }" open-delay="200">
              <v-list-item-title :class="{ 'on-hover': hover }">{{
                item
              }}</v-list-item-title>
            </v-hover>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu
        :value="spellCheckMenu.show"
        v-model="spellCheckMenu.show"
        :position-x="spellCheckMenu.x"
        :position-y="spellCheckMenu.y"
        max-width="500px"
        min-width="500px"
        max-height="500px"
        min-height="500px"
        scrollable
        absolute
      >
        <v-card>
          <v-card-title style="font-size: 16px"
            >Spell Check: {{ spellCheckMenu.word }}</v-card-title
          >
          <v-divider></v-divider>
          <v-card-title style="font-size: 14px">
            Suggested: &nbsp;
            <v-hover v-slot="{ hover }" open-delay="200">
              <span
                style="cursor: pointer"
                :class="{ 'on-hover': hover }"
                @click="
                  doSpellCheckReplace(
                    spellCheckMenu.word,
                    spellCheckMenu.suggestion
                  )
                "
                >{{ spellCheckMenu.suggestion }}</span
              > </v-hover
            ><br />
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text
            style="
              min-height: 100px;
              max-height: 300px;
              overflow-y: scroll;
              font-size: 14px;
            "
          >
            <b>Alternatives:</b><br />
            <v-row style="padding-top: 10px">
              <v-col
                v-for="(item, index) in spellCheckMenu.candidates"
                :key="index"
                cols="4"
                @click="doSpellCheckReplace(spellCheckMenu.word, item)"
              >
                <v-hover v-slot="{ hover }" open-delay="200">
                  <span
                    style="cursor: pointer"
                    :class="{ 'on-hover': hover }"
                    >{{ item }}</span
                  >
                </v-hover>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-menu>
    </div>
    <div v-else-if="hierarchy">
      <HierarchyTreePicker
        v-if="useTreePicker"
        ref="hierarchyTreePicker"
        v-model="hierarchyTreePickerValue"
        autofocus
        borderless
        :label="hierarchy.label"
        :hint="hierarchy.hint"
        :options="hierarchy.treePickerOptionsToUse"
        :placeholder="hierarchy.label"
        :error="hierarchy.error"
        :textValue="selectedText"
        class="mb-7"
        :readonly="false"
        :clearable="true"
        :dense="true"
        :hierarchyType="hierarchy"
        @lostFocus="lostFocus"
        @nodeAdded="nodeAdded"
      >
      </HierarchyTreePicker>
      <v-autocomplete
        v-else-if="!hierarchy.selectNew"
        class="roundish"
        autofocus
        ref="hierarchyPicker"
        auto-select-first
        :readonly="false"
        :clearable="true"
        :search-input.sync="comboText"
        :return-object="false"
        :items="hierarchy.values"
        item-value="text"
        item-text="text"
        :label="hierarchy.label"
        v-model="selectedText"
        @keyup="searchText($event, hierarchy)"
        @keyup.enter.stop="doEnter"
        @blur="lostFocus"
        :filter="utils.comboFilterPicker"
      >
        <template v-slot:item="{ item }">
          <p style="max-width: 800px">{{ item.text }}</p>
        </template>
        <template v-slot:prepend-item>
          <div
            v-if="hierarchy.showNewPrompt"
            style="padding: 0px 0px 10px 10px; font-size: 14px"
          >
            <div v-if="canAddHierarchy !== 0">
              Press <kbd>Ctrl</kbd> + <kbd>Enter</kbd> to create a new
              {{ hierarchy.label }} <mark>{{ hierarchy.searchText }}</mark> or
              <kbd>Enter</kbd> to pick the highlighted item
            </div>
            <div v-else>
              Press <kbd>Enter</kbd> to pick the highlighted item
            </div>
          </div>
        </template>
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title v-if="canAddHierarchy !== 0">
                No matching results. Press
                <kbd>Ctrl</kbd> + <kbd>enter</kbd> key to create the new
                {{ hierarchy.label }}
              </v-list-item-title>
              <v-list-item-title v-else>
                {{ hierarchy.not_in_list_message }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-autocomplete>
    </div>
    <div v-else-if="restrict_to_lookup && multi_select" class="comboboxHolder">
      <v-select
        v-model="selectedItems"
        :items="lookup"
        item-text="title"
        item-value="varVal"
        label="Select"
        multiple
        @change="selectedItemsChange"
        @blur="selectEnd"
      >
      </v-select>
    </div>
    <div v-else-if="restrict_to_lookup && !allow_adding" class="comboboxHolder">
      <v-autocomplete
        class="inLineEdit"
        autofocus
        v-model="selectedText"
        clearable
        label="Pick from list"
        :search-input.sync="comboText"
        :return-object="false"
        :items="lookup"
        item-text="varVal"
        item-value="varVal"
        @click:clear="clearSelected"
        @keyup.enter.stop="doEnter"
        @keyup="isDirty = true"
        @keydown.esc.stop.prevent="$emit('escape')"
        :filter="filterPicker"
        @blur="lostFocus"
        v-debounce:1500ms="performNlpChecks"
      >
        <template v-slot:item="{ item }">
          <span v-if="item.title">{{ item.title }}</span>
          <p
            v-else
            style="max-width: 800px"
            v-html="(item.varVal || '').replace(/(?:\r\n|\r|\n)/g, '<br />')"
          ></p>
        </template>
      </v-autocomplete>
    </div>
    <div v-else-if="restrict_to_lookup && allow_adding" class="comboboxHolder">
      <v-combobox
        class="inLineEdit"
        clearable
        v-model="selectedText"
        label="Select or Add"
        :search-input.sync="comboText"
        :return-object="false"
        :items="lookup"
        item-text="varVal"
        item-value="varVal"
        @click:clear="clearSelected"
        @keyup.enter.stop="doEnter"
        @keyup="isDirty = true"
        @keydown.esc.stop.prevent="$emit('escape')"
        :filter="filterPicker"
        @blur="lostFocus"
        @change="changeCombo()"
      >
      </v-combobox>
    </div>
    <div v-else-if="restrict_to_date">
      <DatePicker
        v-model="dateValue"
        :css_class="hideBorder ? 'borderless' : ''"
        :hideBorder="hideBorder"
        :placeholder="placeholder"
        :contentStyle="contentStyle"
        @input="saveDate"
        open
        @cancel="$emit('escape')"
      ></DatePicker>
    </div>
    <div v-else-if="use_jd_flow && allow_suggestions">
      <textarea
        :style="contentStyle"
        :class="{ borderless: hideBorder }"
        ref="textarea"
        :placeholder="placeholder"
        v-model="text"
        @keyup.stop="handleInput"
        @input="handleInput"
        @paste="checkRestrictedChar"
        @scroll="handleScroll"
        @contextmenu="handleContextMenu"
        @keydown.ctrl.83.prevent.stop="doSave"
        @keydown.enter="doEnter"
        @keydown.esc.prevent.stop="$emit('escape')"
        @blur="lostFocus"
      ></textarea>
    </div>
    <!-- <div v-else-if="attribute.tag_type_id">
      TAG PICKER: {{ attribute.tags }}
      <TagAttribute
        :attribute="attribute"
        @changed="saveTags"
        @cancel="$emit('escape')"
      ></TagAttribute>
    </div> -->
    <div v-else class="comboboxHolder">
      <v-combobox
        :style="contentStyleCB"
        class="inLineEdit"
        autofocus
        v-model="pickerText"
        clearable
        label="Type to search existing content"
        ref="combo"
        :search-input.sync="comboText"
        :return-object="false"
        :items="lookup"
        item-text="varVal"
        item-value="varVal"
        @keyup.enter.stop="pickValue"
        @keyup="changeCombo($event)"
        @keydown="changeCombo($event)"
        @change="updateCombo"
        @keydown.esc.stop.prevent="$emit('escape')"
        @blur="lostFocus"
        :filter="filterPicker"
      >
        <template v-slot:item="{ item }">
          <p style="max-width: 800px" v-html="item.varVal"></p>
        </template>
      </v-combobox>
    </div>
  </div>
</template>


<script>
import utils from "@/common/utils.js";
import HierarchyTreePicker from "@/components/cHierarchyTreePicker";
// import moment from "moment";
import dayJS from "dayjs";
import axios from "axios";
import nlpUtils from "@/common/nlpUtils.js";
import { mapState } from "vuex";
import DatePicker from "@/components/common/DatePicker";
// import TagAttribute from "@/components/document/TagAttribute";

export default {
  name: "TextHighlight",
  components: {
    HierarchyTreePicker,
    DatePicker,
    // TagAttribute,
  },
  props: {
    padding: { type: Number, default: 2 },
    words: Array,
    value: String,
    placeholder: String,
    clearable: Boolean,
    showActionIcons: Boolean, // indicates inline edit
    hideLookup: Boolean, // always show textarea input
    hideBorder: Boolean,
    refresh: Boolean,
    restrict_to_lookup: Boolean,
    rank_attribute: Boolean,
    restrict_to_date: Boolean,
    restrict_to_htid: Object,
    multi_select: Boolean,
    multi_select_separator: String,
    css: {},
    lookup: Array,
    allow_adding: Boolean,
    css_class_name: String,
    spellCheckData: Object,
    grammarCheckData: String,
    use_jd_flow: Boolean,
    allow_suggestions: Boolean,
    document_language: Object,
    spell_check_language_code: String,
    restrictedChars: Array,
    source: Object,
    suggestionType: String,
    summarise: Boolean,
    docId: Number,
    attribute: Object,
  },
  data: function () {
    return {
      utils: utils,
      highlightedText: "",
      text: this.value,
      newValue: this.value,
      hasExisitingValue: false,
      handleNewValueTimeout: null,
      showLookup: false,
      lookupMaxHeight: 300,
      lookupWidth: 500,
      lookupTop: false,
      lookupPosY: 0,
      lookupPosX: 0,
      lookupsToShow: 20,
      lookupsPageSize: 20,
      filteredLookup: [],
      filteredLookupSelIdx: -1,
      wordChecks: [],
      alternatives: [],
      alternative: {
        show: false,
        list: [],
        x: 0,
        y: 0,
        title: "",
        explanation: "",
        insert: false,
      },
      spellCheckMenu: {
        show: false,
        candidates: [],
        x: 0,
        y: 0,
        title: "",
        explanation: "",
        suggestion: "",
        start: 0,
        end: 0,
        word: "",
      },
      containerStyle: { height: "20px", width: "100%" },
      contentStyle: { height: "20px", width: "100%" },
      contentStyleCB: { width: "100%" },
      reqHeight: 20,
      message: "",
      textStatus: {},
      inFocus: false,
      isIE: !!window.navigator.userAgent
        .toLowerCase()
        .match(/msie|trident\/7|edge/),
      comboText: "",
      pickerText: "",
      selectedItems: [],
      selectedText: this.value,
      isDirty: false,
      processing: false,
      saving: false,
      initialising: false,
      dateValue:
        this.value && this.restrict_to_date
          ? dayJS(this.value).toISOString()
          : "",
      spellCheck: [],
      clearRight: "25px",
      displaySaveCancel: false, // late decision to remove save/cancel buttons for sage
      nlpCheckInProgress: false,
      debounceTimeout: null,
      correctingNlp: false,
      spellCheckEnabled: false,
      isLoading: false,
      hierarchy: null,
      useTreePicker: true,
      hierarchyTreePickerValue: null,
      selectedHr: null,
    };
  },
  computed: {
    ...mapState({
      config: (state) => state.settings.config,
      canAddHierarchy: (state) => state.hierarchies.canAddHierarchy,
    }),
  },
  created() {
    this.initialise();
    this.initialising = true;

    const lookupVals = this.lookup?.map((l) => l.varVal);
    if (
      this.restrict_to_lookup &&
      this.selectedText &&
      !lookupVals.includes(this.selectedText)
    ) {
      this.selectedText = lookupVals.find((v) => {
        const subParts = utils.partToSubParts(v);
        return subParts.length === 1 && subParts[0].text === this.selectedText;
      });
    }

    this.$nextTick(() => {
      this.checkHeight();
      if (this.$refs.textarea) {
        this.$refs.textarea.focus();
        let pos = this.$refs.textarea.selectionEnd;
        this.$refs.textarea.setSelectionRange(pos, pos);
      }
    });
  },
  updated() {
    this.$nextTick(() => {
      this.checkHeight();
      this.processing = false;
      this.initialising = false;
      //   if (this.initialising)
      //     this.initialising = !this.value && !this.restrict_to_lookup;
    });
  },
  watch: {
    value(newvalue) {
      if (newvalue !== this.text) {
        this.text = newvalue;
        this.initialise();
      }
    },
    refresh(newvalue) {
      if (newvalue) {
        this.initialise();
      }
    },
    pickerText(newval) {
      if (newval || newval === null) {
        newval = (newval || "").trim();
        this.pickerText = "";
        this.comboText = "";
        this.handleChange(newval);
        if (this.isDirty) this.doSave();
        else this.$emit("escape");
        newval = "";
      }
    },
    selectedText(newval) {
      if (
        (!this.multi_select &&
          (this.rank_attribute || !this.initialising) &&
          newval) ||
        newval === null
      ) {
        newval = ((newval || "") + "").trim();
        this.handleChange(newval);
        this.doSave();
        newval = "";
      }
    },
    hierarchyTreePickerValue(newval) {
      if (
        !this.initialising &&
        newval &&
        newval !== this.restrict_to_htid.hr_selected?.hr_id
      ) {
        const new_hr = this.hierarchy.values.find((v) => v.value === newval);
        this.isDirty = true;
        this.selectedHr = new_hr;
        this.selectedText = new_hr.text;
      }

      newval = "";
    },
    lookup() {
      this.filterLookup();
    },
    showLookup(val) {
      if (val) {
        this.$nextTick(() => this.setLookupMenuPos());
      }
    },
  },
  methods: {
    selectedItemsChange() {
      this.selectedText = this.selectedItems.join(
        this.multi_select_separator ?? ","
      );
    },
    nodeAdded(data) {
      let new_hr = this.hierarchy.values.find(
        (v) => v.value === data.hierarchy.hr_id
      );
      if (!new_hr) {
        new_hr = data.hierarchy;
        this.hierarchy.values.push(new_hr);
      }
      this.isDirty = true;
      this.selectedHr = new_hr;
      this.selectedText = new_hr.text;
    },
    selectEnd() {
      let newval = this.selectedItems.join(this.multi_select_separator ?? ",");
      if (!this.initialising && newval !== this.text) {
        this.handleChange(newval.trim());
        this.doSave();
      }

      this.$emit("escape");
    },
    expandBounds(value, cursor) {
      if (!value) {
        return;
      }
      let current = value.charAt(cursor);
      if (current === "") {
        return null;
      }
      let startIdx = cursor,
        endIdx = cursor;

      let startBreaker = new RegExp(/\s/, "gim");
      while (startIdx >= 0 && !startBreaker.test(value.charAt(startIdx))) {
        startIdx--;
      }
      let endBreaker = new RegExp(/\s|[.]/, "gim");
      while (endIdx < value.length && !endBreaker.test(value.charAt(endIdx))) {
        endIdx++;
      }

      let selection = value.substring(startIdx + 1, endIdx);
      return {
        start: startIdx + 1,
        end: endIdx,
        word: selection.trim() === "" ? null : selection.trim(),
      };
    },
    getSpellCheckItem(ev) {
      let value = ev.currentTarget.value;

      let cursor = ev.currentTarget.selectionStart;
      let word = this.expandBounds(value, cursor);
      if (word.word === null) {
        return;
      }
      if (this.spellCheckData !== null && this.spellCheckData !== undefined) {
        let part =
          this.spellCheckData.parts.length > 1
            ? this.spellCheckData.parts.find(
                (x) => x.doc_part_id === this.spellCheckData.doc_part_id
              )
            : this.spellCheckData.parts[0];

        if (part) {
          let matchedCorrection = part.issues.find(
            (x) => x.word.toLowerCase() === word.word.toLowerCase()
          );
          if (matchedCorrection) {
            let variant =
              matchedCorrection.variations.length > 1
                ? matchedCorrection.variations.find(
                    (x) => x.offset === word.start
                  )
                : matchedCorrection.variations[0];
            //this.$emit("languageIssue", { ev, word, matchedCorrection: variant });
            return variant;
          }
        }
      }
    },
    applyLanguageCorrection(data) {
      let obj = {
        tmpl_part_id: this.source.tmpl_part_id,
        parts: [
          {
            doc_part_id: this.source.doc_part_id,
            issues: data,
          },
        ],
      };

      let languageOpts = nlpUtils.getLanguageTools(this.$loginState.user);

      let newText = utils.applyLanguageHighlights(
        this.textStatus.highlightedText,
        [obj],
        this.source.tmpl_part_id,
        this.source.doc_part_id,
        languageOpts.spellCheck.options.dictionary
      );
      this.textStatus.highlightedText = newText;
      this.highlightedText = this.textStatus.highlightedText;

      this.$emit("debounce", data);
    },
    performNlpChecks() {
      // grab options and check
      let languageOpts = nlpUtils.getLanguageTools(this.$loginState.user);
      //let spellCheckEnabled = nlpUtils.getSpellCheckSettings(this.$loginState.user);
      if (!languageOpts.spellCheck.enabled || this.showLookup || !this.source) {
        return;
      }

      // start progress runner
      this.nlpCheckInProgress = true;

      // do checks
      nlpUtils
        .doSpellGramCheck(this.text, this.spell_check_language_code)
        .then((resp) => {
          this.nlpCheckInProgress = false;
          if (resp == undefined) return;

          this.applyLanguageCorrection(resp.data.Data);
        });
    },
    initialise() {
      this.hasExisitingValue = !!this.value;
      this.resetHeight();
      this.contentStyle = { ...this.contentStyle, ...this.css };
      this.wordChecks = utils.initialisePartChecks(this.words);
      this.applyHighlights(this.text);
      this.$emit("initialised", this.textStatus);
      let languageOpts = nlpUtils.getLanguageTools(this.$loginState.user);
      this.spellCheckEnabled = languageOpts.spellCheck.enabled;

      if (this.multi_select) {
        this.selectedItems = this.selectedText
          .split(this.multi_select_separator)
          .filter((x) => x);
      }
      this.getHierarchy();

      if (this.suggestionType) this.$nextTick(() => this.generateSuggestions());
      else if (this.summarise) this.$nextTick(() => this.generateSummary());
      else if (!this.hasExisitingValue)
        this.$nextTick(() => this.filterLookup());
    },
    getHierarchy() {
      if (this.restrict_to_htid && this.restrict_to_htid.ht_id) {
        let hierarchy = this.$store.state.hierarchies.hierarchies.find(
          (ht) => ht.ht_id === this.restrict_to_htid.ht_id
        );
        if (hierarchy) {
          hierarchy = JSON.parse(JSON.stringify(hierarchy));
          let hr = this.restrict_to_htid.hr_selected;
          hierarchy.hr_id = hr ? hr.hr_id : null;
          let hItem = hierarchy.values.find((v) => v.value === hierarchy.hr_id);
          hierarchy.missing = hr ? false : true;
          hierarchy.allowAddLeafNode = this.restrict_to_htid.canadd;
          hierarchy.treePickerOptionsToUse =
            hItem && !hItem.hr_active
              ? hierarchy.treePickerOptions
              : hierarchy.treePickerOptionsActive;

          this.selectedText = hr && hItem ? hItem.text : "";
          this.hierarchyTreePickerValue = hr.hr_id;
        }
        this.hierarchy = hierarchy;
        this.$nextTick(() => (this.initialising = false));
      }
    },
    resetHeight() {
      this.setHeight(20);
    },
    checkHeight() {
      if (
        this.$refs.textarea &&
        this.reqHeight < this.$refs.textarea.scrollHeight
      )
        this.setHeight(this.$refs.textarea.scrollHeight + 2);
      if (this.$refs.combo && this.reqHeight < 50) this.setHeight(50);
    },
    setHeight(val) {
      this.reqHeight = val;
      this.containerStyle.height = this.reqHeight + "px";
      this.contentStyle.height = this.reqHeight + "px";
      this.contentStyle.padding = this.padding + "px";
      this.setActionLayout();
    },
    setActionLayout() {
      let paddingRight = this.padding;
      if (this.showActionIcons && this.displaySaveCancel) paddingRight += 50;
      this.contentStyleCB.paddingRight = paddingRight + "px";
      if (this.clearable) paddingRight += 20;
      this.clearRight =
        (this.isDirty ? paddingRight - 20 : this.padding) + "px";
      this.contentStyle.paddingRight = paddingRight + "px";
    },
    getLanguageIssues(ev) {
      let value = ev.currentTarget.value;

      let cursor = ev.currentTarget.selectionStart;
      let word = this.expandBounds(value, cursor);
      if (word.word === null) {
        return;
      }

      let results = [];
      //const fullHighlightRegex = /<mark.+?>.+?<\/mark>$/gm
      const regex = /<mark(.+?)>*(?<!\/)>([\w\s]*)/gm;
      let m;
      while ((m = regex.exec(this.textStatus.highlightedText)) !== null) {
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }

        let fullValue =
          this.textStatus.highlightedText.substr(m.index, m[0].length) +
          "</mark>";
        let dataMatch = m[0].match(/data="(.+)"/);
        let titleMatch = m[0].match(/data-title='(.+)' /);
        let nodeTypeMatch = m[0].match(/data-correction-type='(.+)' /);
        let nodeLangRefMatch = m[0].match(/data-language-reference='(.+)'./);
        let idxMatch = m[0].match(/data-idx="(.+?)" /); //Number(tmpHtml.getAttribute('x'));

        let idx = 1;
        if (
          nodeTypeMatch &&
          nodeTypeMatch[1] === "spellcheck" &&
          nodeLangRefMatch
        ) {
          let split = nodeLangRefMatch[1].split("_");
          idx = Number(split[2]);
        } else {
          idx = idxMatch ? Number(idxMatch[1]) : 1;
        }

        let sourceDetails = null;
        if (nodeLangRefMatch) {
          let lang = nodeLangRefMatch[1].split("_");
          sourceDetails = {
            tmpl_part_id: Number(lang[0]),
            doc_part_id: Number(lang[1]),
            idx: Number(lang[2]),
          };
        } else {
          sourceDetails = {
            idx: idx,
          };
        }

        results.push({
          text: fullValue,
          word: m[2] === "" ? null : m[2],
          html_index: m.index,
          type: dataMatch
            ? dataMatch[1]
            : nodeTypeMatch
            ? nodeTypeMatch[1]
            : null,
          title: titleMatch ? titleMatch[1] : null,
          nodeTypeMatch: nodeTypeMatch ? nodeTypeMatch[1] : null,
          nodeLangRef: nodeLangRefMatch ? nodeLangRefMatch[1] : null,
          idx: idx,
          source: sourceDetails,
        });
      }
      let currentWord = null;
      let tmpSet = results.reverse();
      tmpSet.forEach((x) => {
        if (x.word) {
          currentWord = x.word;
        }

        x.word = currentWord;
        x.cursorMatch = false;
      });

      let returnSet = tmpSet.reverse();

      returnSet.forEach((x) => {
        const matchRegex = new RegExp(x.word, "gmi");
        let mr;
        let matchCount = 0;
        while (
          (mr = matchRegex.exec(this.textStatus.text)) !== null &&
          !x.cursorMatch
        ) {
          if (mr.index === matchRegex.lastIndex) {
            matchRegex.lastIndex++;
          }

          matchCount++;
          if (matchCount === x.idx) {
            x.offset = mr.index;
            if (mr.index === word.start) {
              x.cursorMatch = true;
              matchCount = 0;
            }
          }

          var match;
          if (x.type === "spellcheck") {
            let spellData = this.getSpellCheckItem(ev, word);
            x.spellCheckData = spellData;
          } else {
            switch (x.type) {
              case "word_list":
                match = this.wordChecks.find(
                  (z) => z.word?.toLowerCase() === x.word.toLowerCase()
                );
                break;
              case "sentence_length":
                x.word = null;
                match = this.wordChecks.find(
                  (z) => z.check_function === x.type
                );
                break;
              default:
                match = this.wordChecks.find(
                  (z) => z.check_function === x.type
                );
                break;
            }
          }

          x.match = match;
        }
      });

      //return returnSet.filter(x => (x.type === 'spellcheck' && x.spellCheckData) || (x.type !== 'spellcheck' && x.match) && x.cursorMatch);
      return returnSet.filter((x) => x.cursorMatch);
    },
    handleContextMenu(ev) {
      let start = ev.currentTarget.selectionStart,
        end = ev.currentTarget.selectionEnd,
        value = ev.currentTarget.value,
        regex = new RegExp("^[A-Z]$", "i");
      if (start < end) end--;
      while (start >= 0 && regex.test(value.charAt(start))) {
        start--;
      }
      if (start < 0) start = 0;
      while (end < value.length && regex.test(value.charAt(end))) {
        end++;
      }
      let word = value.substr(start, end - start).trim();
      let wordLC = word.toLowerCase();
      let match = this.wordChecks.find((x) => x.word === wordLC);
      let titleSuffix = " bias?";
      let altTitle = " Alternatives:";
      let caretCoords = {
        x: ev.clientX,
        y: ev.clientY,
      };

      this.alternative.insert = false;

      this.alternative.show = false;
      if (!match) {
        let valueLC = value.toLowerCase();
        let possPhrases = this.wordChecks.filter(
          (x) => x.check_function === "word_list" && x.word.indexOf(wordLC) >= 0
        );
        possPhrases.forEach((p) => {
          if (!match) {
            let posP = valueLC.indexOf(p.word);
            while (posP >= 0) {
              if (posP <= start && posP + p.word.length >= start) {
                match = p;
                start = posP - (posP ? 1 : 0);
                end = posP + p.word.length;
                posP = -1;
              } else {
                posP = valueLC.indexOf(p.word, posP + 1);
              }
            }
          }
        });
      }
      if (!match) {
        let posWord = this.textStatus.highlightedText.indexOf(
          `>${word}</mark>`
        );
        if (posWord) {
          let posData = this.textStatus.highlightedText
            .substring(0, posWord)
            .split('data="');
          if (posData.length > 1) {
            let data = posData[posData.length - 1].split('"')[0];
            if (data !== "word_list") {
              match = this.wordChecks.find((x) => x.check_function === data);
              if (match) {
                titleSuffix = " - " + match.title + ".";
                this.alternative.insert = true;
                altTitle = " Suggestions:";
              }
            }
          }
        }
      }
      if (this.spellCheckEnabled) {
        let items = this.getLanguageIssues(ev);
        if (items.length > 0) {
          this.$emit("languageIssue", {
            element: ev.target,
            word: wordLC,
            coords: caretCoords,
            textStatus: this.textStatus,
            spellCheckData: this.spellCheckData ?? null,
            issueTypes: items,
            ev: ev,
          });
          ev.preventDefault();
          return;
        }
      }
      if (match) {
        const isUpperCase =
          word.substr(0, 1) === word.substr(0, 1).toUpperCase();
        this.alternative.start = start;
        this.alternative.end = end;
        this.alternative.x = ev.clientX;
        this.alternative.y = ev.clientY;
        this.alternative.list = (match.alternatives || [])
          .map((x) =>
            isUpperCase ? x.substr(0, 1).toUpperCase() + x.substr(1) : x
          )
          .filter((x) => x);
        this.alternative.title =
          match.category +
          titleSuffix +
          (match.alternatives.length ? altTitle : "");
        this.alternative.explanation = match.explanation || "";
        this.$nextTick(() => {
          this.alternative.show = true;
        });
        ev.preventDefault();
      }
    },
    doSpellCheckReplace(old, item) {
      let reg = new RegExp(old, "gim");
      let result = this.textStatus.text.match(reg);
      if (result) {
        this.textStatus.text = this.textStatus.text.replace(reg, item);

        let htmlReg = new RegExp(
          "<mark class='nlpData'>" + old + "</mark>",
          "gim"
        );
        let htmlResult = this.textStatus.highlightedText.match(htmlReg);
        if (htmlResult) {
          this.textStatus.highlightedText =
            this.textStatus.highlightedText.replace(htmlReg, item);
          this.$emit("doSpellCheckReplace", old, item);
          this.$emit("changed", this.textStatus);
          this.$emit("save");
        }
      }
      //this.textStatus.text = this.textStatus.text.replace(old,item);
      //this.textStatus.highlightedText = this.textStatus.highlightedText.replace("<mark class='nlpData'>"+old+"</mark>",item);

      // need to re focus on text after fixing suggestion or we break UI flow
      const editable = this.$refs.textarea.parentNode.parentNode.parentNode;
      if (editable) {
        setTimeout(
          () =>
            editable.childNodes[0] ? editable.childNodes[0].click() : undefined,
          250
        );
        setTimeout(
          () =>
            editable.querySelector("textarea")
              ? editable.querySelector("textarea").focus()
              : undefined,
          300
        );
      }
    },
    doReplace(item) {
      let text = this.text;
      if (this.alternative.insert) {
        text =
          text.substr(
            0,
            this.alternative.start + (this.alternative.start > 0 ? 1 : 0)
          ) +
          item +
          " " +
          text.substr(this.alternative.start);
      } else {
        text =
          text.substr(
            0,
            this.alternative.start + (this.alternative.start > 0 ? 1 : 0)
          ) +
          item +
          (text.length >= this.alternative.end
            ? text.substr(this.alternative.end)
            : "");
      }
      this.handleChange(text);
    },
    hasHover() {
      this.inFocus = true;
    },
    lostHover() {
      this.inFocus = false;
    },
    clearContent() {
      if (this.$refs.textarea) {
        this.$refs.textarea.value = "";
        this.$refs.textarea.focus();
      }
      this.hasExisitingValue = false;
      this.handleInput();
    },
    checkRestrictedChar(ev) {
      const text = (ev.originalEvent || ev).clipboardData.getData("text/plain");
      let replacement = text;
      if (
        this.restrictedChars.length &&
        [...text].some((v) => this.restrictedChars.includes(v))
      ) {
        ev.stopPropagation();
        ev.preventDefault();
        this.restrictedChars.forEach((c) => {
          replacement = replacement.replace(
            new RegExp(utils.escapeRegExpString(c), "gi"),
            ""
          );
        });
        const pos = ev.target.selectionStart;
        const end = ev.target.selectionEnd;
        let newValue = "";
        if (pos === 0 && end === this.text.length) {
          newValue = replacement;
        } else {
          let selectRange = end - pos;
          newValue = [
            ev.target.value.slice(0, pos),
            replacement,
            ev.target.value.slice(pos + selectRange),
          ].join("");
        }
        ev.target.value = newValue;
      }
    },
    checkRestrictions(ev) {
      if (!ev?.key) {
        return false;
      }

      if (this.restrictedChars.length && this.restrictedChar(ev.key)) {
        ev.preventDefault();
        return;
      }
    },
    restrictedChar(key) {
      if (!key) {
        return false;
      }

      return this.restrictedChars.indexOf(key) > -1;
    },
    handleInput(ev) {
      if (this.handleLookupNavigation(ev)) return;

      this.processing = !this.$refs.textarea.value;
      this.handleChange(this.$refs.textarea.value);
    },
    handleLookupNavigation(ev) {
      if (!this.showLookup || !ev) return false;

      if (ev.key === "ArrowDown") {
        ev.preventDefault();
        if (this.filteredLookupSelIdx < this.filteredLookup.length - 1)
          this.filteredLookupSelIdx++;
        return true;
      } else if (ev.key === "ArrowUp") {
        ev.preventDefault();
        if (this.filteredLookupSelIdx > 0) this.filteredLookupSelIdx--;
        return true;
      }

      return false;
    },
    handleChange(newValue) {
      if (newValue !== this.value) {
        this.isDirty = true;
        this.newValue = newValue;

        //debounce for performance
        if (this.handleNewValueTimeout)
          clearTimeout(this.handleNewValueTimeout);
        this.handleNewValueTimeout = setTimeout(
          () => this.handleNewValue(),
          200
        );
      }
    },
    handleNewValue() {
      this.setActionLayout();
      this.applyHighlights(this.newValue);
      this.lookupsToShow = this.lookupsPageSize;
      this.filterLookup();
      this.handleScroll();
      this.handleNewValueTimeout = null;
      this.textStatus.hr = this.selectedHr;
      this.$emit("changed", this.textStatus);
    },
    toggleLookupMenu() {
      if (this.filteredLookup.length) {
        this.showLookup = !this.showLookup;
      }
    },
    setLookupMenuPos() {
      //set lookup size/position
      const containerRect = this.$refs.container.getBoundingClientRect();
      this.lookupWidth = containerRect.right - containerRect.left + 32;
      this.lookupPosX = containerRect.left - 5;
      const vh = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      );

      //show below textara unless textarea is at bottom of page
      if (containerRect.bottom + this.lookupMaxHeight + 2 < vh) {
        this.lookupPosY = containerRect.bottom + 2;
        this.lookupTop = false;
      } else {
        this.lookupPosY = containerRect.top - 2;
        this.lookupTop = true;
      }
    },
    onLookupListScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (!this.suggestionType && scrollTop + clientHeight >= scrollHeight) {
        this.lookupsToShow += this.lookupsPageSize;
        this.filterLookup();
      }
    },
    refreshAIContent() {
      if (this.suggestionType) this.generateSuggestions();
      else if (this.summarise) this.generateSummary();
    },
    generateSuggestions() {
      const data = {
        contentType: "templatePart",
        contentTpId: this.source.tmpl_part_id,
        language: this.document_language?.text,
        spelling: this.spell_check_language_code,
        docId: this.docId,
      };
      this.isLoading = true;
      this.showLookup = true;
      axios
        .post("externalContent/generate", data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            this.filteredLookup = resp.data.Data?.content?.map((d) => {
              return {
                title: d,
                varVal: d,
              };
            });
            this.showLookup = true;
          } else {
            this.response = { message: "Unexpected Error" };
            this.showLookup = false;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    generateSummary() {
      const data = {
        contentType: "summarise",
        language: this.document_language?.text,
        text: this.text,
      };
      this.isLoading = true;
      this.showLookup = true;
      axios
        .post("externalContent/generate", data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            this.filteredLookup = resp.data.Data?.content?.map((d) => {
              return {
                title: d,
                varVal: d,
              };
            });
            this.showLookup = true;
          } else {
            this.response = { message: "Unexpected Error" };
            this.showLookup = false;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    filterLookup() {
      if (
        this.hasExisitingValue ||
        !this.lookup ||
        typeof this.newValue === "object"
      )
        return; //lookup prop may not be set

      if (!this.showLookup) this.lookupsToShow = this.lookupsPageSize;

      let queryText = (this.newValue || "")
        .toLowerCase()
        .split(" ")
        .filter((x) => x.length)
        .map((x) => x);

      this.filteredLookup = (
        !queryText || queryText.length === 0
          ? this.lookup
          : this.lookup.filter((l) => {
              const text =
                (l.title || "").toLowerCase() + (l.varVal || "").toLowerCase();
              return queryText.every((s) => text.toLowerCase().indexOf(s) >= 0);
            })
      )
        .slice(0, this.lookupsToShow)
        .map((l) => {
          const item = {
            ...l,
            marked: (l.varVal || "").replace(/(?:\r\n|\r|\n)/g, "<br />"),
          };
          queryText.forEach((q) => {
            item.marked = item.marked.replace(
              new RegExp(utils.escapeRegExpString(q), "gi"),
              (match) => `<mark>${match}</mark>`
            );
          });
          return item;
        });

      this.showLookup = false;
      if (this.filteredLookup.length) {
        this.filteredLookupSelIdx = -1;
        this.$nextTick(() => {
          this.showLookup = true;
        });
      }
    },
    applyHighlights(text) {
      if (typeof this.newValue === "object") return;
      text = text ? text.split("•\t").join("") : "";
      this.textStatus = utils.applyHighlights(
        text,
        this.wordChecks,
        false,
        this.isIE
      );

      this.highlightedText = this.textStatus.highlightedText.replace(
        /&/g,
        "&amp;"
      );

      if (this.spellCheckData !== null && this.spellCheckData !== undefined) {
        let languageOpts = nlpUtils.getLanguageTools(this.$loginState.user);
        this.textStatus.highlightedText = utils.applyLanguageHighlights(
          this.textStatus.highlightedText,
          [this.spellCheckData],
          this.source.tmpl_part_id,
          this.source.doc_part_id,
          languageOpts.spellCheck.options.dictionary
        );
      }
      //console.log(4444);

      this.highlightedText = this.textStatus.highlightedText;
    },
    searchText($event, hrchy) {
      this.isDirty = true;
      let ctl = this.$refs["hierarchyPicker"];
      if ([13, 37, 38, 39, 40].indexOf($event.keyCode) >= 0) return;
      if (ctl.lazySearch) {
        if ($event.keyCode !== 13) hrchy.searchText = ctl.lazySearch.trim();
        let match = ctl.filteredItems.find(
          (f) => f.text.toLowerCase() === ctl.lazySearch.toLowerCase()
        );
        if (match) {
          ctl.selectItem(match);
          hrchy.hierarchy_text = match.text;
          hrchy.hr_id = match.hr_id;
          hrchy.showNewPrompt = false;
        } else {
          hrchy.showNewPrompt =
            ctl.filteredItems.length &&
            (!hrchy.hierarchy_text ||
              hrchy.hierarchy_text.toLowerCase() !==
                hrchy.searchText.toLowerCase());
        }
      } else {
        hrchy.hierarchy_text = "";
        hrchy.showNewPrompt = false;
      }
    },
    handleScroll() {
      if (this.$refs.backdrop) {
        this.$refs.backdrop.scrollTop = this.$refs.textarea.scrollTop;
        this.$refs.backdrop.scrollLeft = this.$refs.textarea.scrollLeft;
      }
    },
    setOrAppendContent(val) {
      if (
        !this.suggestionType ||
        this.config.contentGenerationConfig.replaceValueWithPartSuggestion
      )
        this.setValue(val);
      else this.setValue(`${this.text} ${val}`);
    },
    setValue(val) {
      val = this.text === "" ? val.trim() : val;
      this.text = val;
      this.newValue = val;
      this.applyHighlights(this.newValue);
      this.$emit("changed", this.textStatus);
      this.doSave();
    },
    doEnter(ev) {
      if (
        this.showLookup &&
        this.filteredLookupSelIdx >= 0 &&
        this.filteredLookupSelIdx < this.filteredLookup.length
      ) {
        ev.preventDefault();
        ev.stopPropagation();
        this.showLookup = false;
        this.setOrAppendContent(
          this.filteredLookup[this.filteredLookupSelIdx].varVal
        );
      } else if (
        (this.showActionIcons && !ev.ctrlKey && !ev.shiftKey) ||
        (!this.showActionIcons && ev.ctrlKey)
      ) {
        ev.preventDefault();
        ev.stopPropagation();
        this.doSave();
      }
    },
    lostFocus(ev) {
      if (
        this.showActionIcons &&
        !this.alternative.show &&
        !this.inFocus &&
        !this.processing &&
        !this.saving &&
        !this.initialising &&
        //!this.correctingNlp &&
        !(
          ev &&
          ev.relatedTarget &&
          ev.relatedTarget.classList &&
          (ev.relatedTarget.classList.contains("lookupItem") ||
            ev.relatedTarget.classList.contains("regenBtn"))
        )
      ) {
        if (this.cbVisible()) {
          this.handleChange(this.comboText);
        }
        if (this.isDirty) {
          this.doSave();
        } else {
          this.$emit("escape");
        }
      }

      this.processing = false;
      this.initialising = false;
    },
    saveDate() {
      this.handleChange(this.dateValue);
      this.doSave();
    },
    saveTags(newValue) {
      this.handleChange(newValue);
      this.doSave();
    },
    taVisible() {
      return (
        this.hideLookup ||
        (!this.restrict_to_lookup &&
          !this.restrict_to_date &&
          !this.allow_adding &&
          !this.rank_attribute &&
          !this.hierarchy &&
          !this.attribute?.tag_type_id)
        // && (this.text?.length || (this.lookup && this.lookup.length === 0))
      );
    },
    cbVisible() {
      return !(
        this.taVisible() ||
        this.restrict_to_lookup ||
        this.restrict_to_date ||
        this.hierarchy ||
        (this.use_jd_flow && this.allow_suggestions)
      );
    },
    clickSave() {
      if (!this.cbVisible() || (!this.text && !this.comboText)) this.doSave();
    },
    doSave() {
      const trimmed = this.newValue.trim();
      if (this.newValue !== trimmed) {
        this.newValue = trimmed;
        this.handleNewValue();
      }

      if (this.saving) return;

      if (this.handleNewValueTimeout) {
        this.handleNewValue();
        clearTimeout(this.handleNewValueTimeout);
      }

      if (this.allow_adding && this.isDirty) {
        this.$emit("addNewLookup");
      }

      this.resetHeight();
      this.saving = true;
      this.isDirty = false;
      this.$emit("save");
    },
    pickValue() {
      this.$refs.combo.isMenuActive = false;
    },
    updateCombo() {
      this.comboText = "";
    },
    changeCombo(ev) {
      if (ev && ev.key === "Escape") return;
      this.isDirty = true;
    },
    filterPicker(item, queryText, itemText) {
      if (item.header) return false;
      const text =
        (item.title || "").toLowerCase() + (itemText || "").toLowerCase();

      let search = (queryText || "")
        .toLowerCase()
        .split(" ")
        .filter((x) => x.length)
        .map((x) => x);
      return (
        !queryText ||
        queryText.length === 0 ||
        search.every((s) => text.indexOf(s) >= 0)
      );
    },
    clearSelected() {
      this.selectedText = "";
      this.isDirty = true;
      this.handleChange("");
    },
  },
};
</script>
<style scoped lang="scss">
.v-list-item {
  min-height: 24px;
}
.hcontainer,
.backdrop,
textarea {
  width: 100%;
}
.highlights {
  white-space: pre-wrap;
}

.highlights,
textarea {
  letter-spacing: 0px;
}

.comboboxHolder {
  width: 100%;
  float: left;
}

.hcontainer.hasActionIcons {
  .comboboxHolder {
    width: calc(100% - 30px);
  }
}

.hcontainer {
  display: block;
  margin: 0 auto;
  transform: translateZ(0);
  -webkit-text-size-adjust: none;
  position: relative;
}

// .actionIcons {
//   padding: 0 300px 30px 0;
//   position: absolute;
//   left: 0;
//   bottom: -75px;

//   z-index: 4;
//   .v-btn {
//     border-radius: 10px;
//   }
//   // .v-icon {
//   //   font-size: 28px !important;
//   // }
// }

.actionIcons {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4;
  .v-icon {
    font-size: 20px !important;
  }
}

.backdrop {
  position: absolute;
  z-index: 1;
  border: 1px solid #cec6d3;
  border-radius: 5px;
  overflow: auto;
  pointer-events: none;
  transition: transform 1s;
}

.backdrop.borderless {
  border: none;
}

.highlights {
  white-space: pre-wrap;
  word-wrap: break-word;
  color: transparent;
}

textarea {
  display: block;
  position: absolute;
  z-index: 2;
  margin: 0;
  border: 1px solid #cfcad1;
  border-radius: 5px;
  color: #444;
  background-color: transparent;
  overflow: auto;
  resize: none;
  transition: transform 1s;
}

.v-application.theme--dark {
  textarea {
    color: #fff;
  }
}

textarea.borderless {
  border: none;
}

::v-deep .lookupItem {
  cursor: pointer;
  &:hover,
  &.selected {
    &.theme--light {
      background-color: #eee;
    }
    &.theme--dark {
      background-color: #333;
    }
  }
  span {
    font-size: 14px;
    line-height: 1.5;
  }
  mark {
    color: inherit;
    border-radius: 5px;
    background-color: rgba(#0372ee, 0.3);
  }
}

mark {
  border-radius: 5px;
  color: transparent;
  background-color: #b1d5e5;
}

textarea:focus {
  outline: none;
  /* box-shadow: 0 0 0 2px #c6aada; */
}

.on-hover {
  font-weight: bold;
}

.regenBtn {
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
}
</style>
<style>
.inLineEdit .v-text-field__details {
  display: none;
}
</style>