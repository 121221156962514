<template>
  <div class="home">
    <ChartDashboard
      v-if="!newUi"
    ></ChartDashboard>

    <Insights
      v-if="newUi"
    ></Insights>
  </div>
</template>

<script>
import ChartDashboard from "@/components/cDDashboard"; // @ is an alias to /src
import Insights from "@/components/cInsights";
import { mapState } from "vuex";

export default {
  name: "vHome",
  components: {
    ChartDashboard,
    Insights,
  },
  mounted() {},
  computed: {
    ...mapState({
      newUi: (state) => state.theme.newUi,
    }),
  },
  methods: {
    sessionExpired(err) {
      this.$emit("sessionExpired", err);
    },
    openDocument(item) {
      this.$emit("openDocument", item);
    },
    copyDocument(item, tmpl_id) {
      this.$emit("copyDocument", item, tmpl_id);
    },
    documentChangedHandler(item) {
      this.$emit("documentChangedHandler", item);
    },
  },
};
</script>