<template>
  <v-container fluid>
    <div style="display: none">
      <!-- Sacrificial fields to remove annoying Chrome habit of auto-filling fields -->
      <input type="text" />
      <input type="password" />
    </div>
    <!-- <v-tabs dense v-if="!isLoading">
      <v-tab
        v-for="(item, i) in layouts"
        :key="i"
        @click="changeLayout(item)"
        >{{ item.name }}</v-tab
      >
    </v-tabs> -->

    <v-row v-if="!isLoading" class="py-4">
      <v-col cols="3" class="d-flex align-center">
        <h1 class="title">{{ $route.meta.text }}</h1>
      </v-col>
      <v-col cols="9" class="d-flex justify-end align-center">
        <div v-for="(f, fi) in allFilters" :key="fi">
          <v-chip class="ml-1" small style="height: 35px">
            {{ f.view.label || f.view.name }}: {{ f.description }}
            <v-icon right @click="removeFilter(f.view)">mdi-close</v-icon>
          </v-chip>
        </div>
        <!-- <v-btn v-if="views.some((v) => v.filterCriteria.length)" icon @click="clearFilter()" title="Reset Filters">
          <v-icon>mdi-filter-remove-outline</v-icon>
        </v-btn> -->
        <div class="ml-2" v-if="layouts.length > 1">
          <v-select
            outlined
            v-model="layout"
            :items="layouts"
            return-object
            dense
            item-text="name"
            item-value="name"
            hide-details
            :menu-props="{ maxHeight: '400', 'offset-y': true }"
            @change="changeLayout()"
          >
          </v-select>
        </div>
      </v-col>
    </v-row>

    <div v-if="!isLoading && layout">
      <v-row dense>
        <!-- <v-col
          v-for="s in unusedViews()"
          :key="s.name"
          style="max-width: 300px"
        >
          <v-combobox
            v-model="s.filter"
            :items="s.data"
            item-text="key"
            item-value="key"
            :label="s.label || s.name"
            outlined
            dense
            clearable
            @change="prepFilter(s)"
            hide-details
          ></v-combobox>
        </v-col> -->
        <v-col v-for="s in multiSelectViews.filter(f => f.data.length > 1)" :key="s.name"
          style="max-width: 300px">
          <v-combobox
            v-model="s.filter"
            :items="s.data"
            item-text="key"
            item-value="key"
            :label="s.label || s.name"
            multiple
            outlined
            dense
            clearable
            
            @change="prepMultiSelectFilter(s)"
            hide-details
          >
            <template v-slot:selection="{ item, index }">
              {{ item && index === 0 ? s.filter.length === 1 ? item.key : `${s.filter.length} options selected` : '' }}
            </template>
          </v-combobox>
        </v-col>
      </v-row>
      <v-row v-for="(r, ri) in layout.rows" :key="'r' + ri" dense>
        <v-col
          v-for="(c, ci) in r.columns"
          :key="'r' + ri + 'c' + ci"
          cols="12"
          :md="c.colWidth < 6 ? 6 : c.colWidth"
          :lg="c.colWidth"
          class="panel-container"
        >
          <v-row
            v-for="(v, vi) in c.items"
            :key="'r' + ri + 'c' + ci + 'v' + vi"
            dense
          >
            <v-col>
              <v-card>
                <v-card-text v-if="v.tabSet && v.items && v.items.length">
                  <v-row>
                    <v-col class="tabHeaders">
                      <v-tabs v-model="v.activeIndex">
                        <v-tab v-for="(t, ti) in v.items.filter(t => views.some(s => s.name === t.section))" :key="ti">
                          {{ views.find(s => s.name === t.section)?.label || t.label || t.section }}
                        </v-tab>
                      </v-tabs>
                    </v-col>
                    <v-col cols="12" class="tabContent">
                      <v-tabs-items v-model="v.activeIndex">
                        <v-tab-item
                          v-for="(t, ti) in v.items.filter(t => views.some(s => s.name === t.section))"
                          :key="ti"
                          class="dashboardSection pt-3"
                        >
                          <DocumentDashboardDocList
                            v-if="t.docList"
                            :settings="t.settings"
                            :documents="documents"
                            :hierarchyTypes="hierarchyTypes"
                            :flexCategories="flexCategories"
                            :lifecycleStatuses="visibleLifecycleStatuses"
                            @openDocument="openDocument"
                            @changeSort="changeSort"
                          >
                          </DocumentDashboardDocList>
                          <DocumentDashboardPartIssues
                            v-else-if="t.issueList"
                            :issues="issues"
                            :filterIssue="filterIssue"
                            :settings="t.settings"
                            :options="viewsAvailable"
                            @rowSelected="addIssueFilter"
                            @changeView="changeView(t, $event)"
                            @removeFilter="removeIssueFilter($event)"
                            @changeSort="changeSort"
                          >
                            ></DocumentDashboardPartIssues
                          >
                          <DocumentDashboardPieChart
                            v-else-if="t.pieChart"
                            :section="t"
                            :settings="t.settings"
                            :options="viewsAvailable"
                            @rowSelected="addViewFilter"
                            @changeView="changeView(t, $event)"
                            @removeFilter="removeFilter(t.view, $event)"
                            @changeSort="changeSort"
                            @showMeasure="showMeasure(t.settings, $event)"
                          ></DocumentDashboardPieChart>
                          <DocumentDashboardView
                            v-else-if="t.view"
                            :section="t"
                            :settings="t.settings"
                            :options="viewsAvailable"
                            @rowSelected="addViewFilter"
                            @changeView="changeView(t, $event)"
                            @removeFilter="removeFilter(t.view, $event)"
                            @changeSort="changeSort"
                          ></DocumentDashboardView>
                        </v-tab-item>
                      </v-tabs-items>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text v-else-if="v.items && v.items.length">
                  <v-row>
                    <v-col
                      v-for="(t, ti) in v.items.filter(t => views.some(s => s.name === t.section))"
                      :style="{
                        width: t.settings.width ? t.settings.width : 'auto',
                      }"
                      :key="ti"
                    >
                      <DocumentDashboardDocList
                        v-if="t.docList"
                        :settings="t.settings"
                        :documents="documents"
                        :hierarchyTypes="hierarchyTypes"
                        :flexCategories="flexCategories"
                        :lifecycleStatuses="visibleLifecycleStatuses"
                        @openDocument="openDocument"
                        @changeSort="changeSort"
                      >
                      </DocumentDashboardDocList>
                      <DocumentDashboardPartIssues
                        v-else-if="t.issueList"
                        :issues="issues"
                        :filterIssue="filterIssue"
                        :settings="t.settings"
                        :options="viewsAvailable"
                        @rowSelected="addIssueFilter"
                        @changeView="changeView(t, $event)"
                        @removeFilter="removeIssueFilter($event)"
                        @changeSort="changeSort"
                      >
                        ></DocumentDashboardPartIssues
                      >
                      <DocumentDashboardPieChart
                        v-else-if="t.pieChart"
                        :section="t"
                        :settings="t.settings"
                        :options="viewsAvailable"
                        @rowSelected="addViewFilter"
                        @changeView="changeView(t, $event)"
                        @removeFilter="removeFilter(t.view, $event)"
                        @changeSort="changeSort"
                        @showMeasure="showMeasure(t.settings, $event)"
                      ></DocumentDashboardPieChart>
                      <DocumentDashboardView
                        v-else-if="t.view"
                        :section="t"
                        :settings="t.settings"
                        :options="viewsAvailable"
                        @rowSelected="addViewFilter"
                        @changeView="changeView(t, $event)"
                        @removeFilter="removeFilter(t.view, $event)"
                        @changeSort="changeSort"
                      ></DocumentDashboardView>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text v-else-if="v.docList" class="dashboardSection">
                  <DocumentDashboardDocList
                    :settings="v.settings"
                    :documents="documents"
                    :hierarchyTypes="hierarchyTypes"
                    :flexCategories="flexCategories"
                    :lifecycleStatuses="visibleLifecycleStatuses"
                    @openDocument="openDocument"
                    @changeSort="changeSort"
                  >
                  </DocumentDashboardDocList>
                </v-card-text>
                <v-card-text v-else-if="v.issueList" class="dashboardSection">
                  <DocumentDashboardPartIssues
                    :issues="issues"
                    :filterIssue="filterIssue"
                    :settings="v.settings"
                    :options="viewsAvailable"
                    @rowSelected="addIssueFilter"
                    @changeView="changeView(v, $event)"
                    @removeFilter="removeIssueFilter($event)"
                    @changeSort="changeSort"
                  >
                    ></DocumentDashboardPartIssues
                  >
                </v-card-text>
                <v-card-text v-else-if="v.pieChart" class="dashboardSection">
                  <DocumentDashboardPieChart
                    :section="v"
                    :settings="v.settings"
                    :options="viewsAvailable"
                    @rowSelected="addViewFilter"
                    @changeView="changeView(v, $event)"
                    @removeFilter="removeFilter(v.view, $event)"
                    @changeSort="changeSort"
                    @showMeasure="showMeasure(v.settings, $event)"
                  ></DocumentDashboardPieChart>
                </v-card-text>
                <v-card-text v-else-if="v.view" class="dashboardSection">
                  <DocumentDashboardView
                    :section="v"
                    :settings="v.settings"
                    :options="viewsAvailable"
                    @rowSelected="addViewFilter"
                    @changeView="changeView(v, $event)"
                    @removeFilter="removeFilter(v.view, $event)"
                    @changeSort="changeSort"
                  ></DocumentDashboardView>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <ResponseHandler :serviceResponse="response"></ResponseHandler>
    <Loading :isVisible="isLoading" />
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
//import draggable from "vuedraggable";
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
import DocumentDashboardView from "@/components/cDocumentDashboardView";
import DocumentDashboardPieChart from "@/components/cDocumentDashboardPieChart";
import DocumentDashboardDocList from "@/components/cDocumentDashboardDocList";
import DocumentDashboardPartIssues from "@/components/cDocumentDashboardPartIssues";
// import CheckSummary from "@/components/cCheckSummary";
import utils from "@/common/utils.js";
import { mapState } from "vuex";
// import HierarchySearch from "@/components/cHierarchySearch";

export default {
  name: "DocumentDashboard",
  components: {
    ResponseHandler,
    DocumentDashboardView,
    DocumentDashboardPieChart,
    DocumentDashboardPartIssues,
    // CheckSummary,
    DocumentDashboardDocList,
  },
  props: {},
  data: function () {
    return {
      response: null,
      rawData: null,
      filterCriteria: [],
      view: null,
      views: [],
      multiSelectViews: [],
      documents: [],
      issues: [],
      filterIssue: null,
      isLoading: true,
      utils: utils,
      scoreStyle: "linear",
      viewsAvailable: null,
      layout: null,
      measures: [
        {
          name: "Docs",
          //   scoreBand: null,
          catIndex: null,
          isCount: true,
        },
        {
          name: "Overall Score",
          //   scoreBand: "single",
          catIndex: null,
          isMean: true,
          isPercent: true,
          isDocScore: true,
          doColourBand: true,
          source: "summary.score",
        },
        {
          name: "Status Age",
          //   scoreBand: "ages",
          catIndex: null,
          isMean: true,
          isPercent: false,
          source: "daysInStatus",
        },
      ],
      layouts: [],
      colourBands: [
        { from: 80, to: 101, colour: "green lighten-2" },
        { from: 50, to: 80, colour: "amber lighten-1" },
        { from: 0, to: 50, colour: "red lighten-2" },
      ],
      scoreBands: [
        {
          name: "default",
          ranges: [
            { title: "Very Low", from: -1, to: 20, sequence: 1 },
            { title: "Low", from: 20, to: 40, sequence: 2 },
            { title: "Medium", from: 40, to: 60, sequence: 3 },
            { title: "High", from: 60, to: 80, sequence: 4 },
            { title: "Very High", from: 80, to: 99, sequence: 5 },
            { title: "No Issues", from: 99, to: 100, sequence: 6 },
          ],
        },
        {
          name: "single",
          ranges: [{ title: "-", from: -1, to: 100, sequence: 1 }],
        },
        {
          name: "ages",
          ranges: [
            { title: "< 10 days", from: -1, to: 10, sequence: 5 },
            { title: "10 to 14 days", from: 10, to: 14, sequence: 3 },
            { title: "15 to 30 days", from: 14, to: 30, sequence: 2 },
            { title: "> 30 days", from: 30, to: 10000, sequence: 1 },
          ],
        },
      ],
      hierarchyTypes: [],
      flexCategories: [],
      lifecycleStatuses: [],
      visibleLifecycleStatuses: [],
      flexOutcomeColours: [
        { value: "Easy", colour: "#4caf50" },
        { value: "Flex", colour: "#4caf50" },
        { value: "Moderate", colour: "#ff9800" },
        { value: "Field", colour: "#ff9800" },
        { value: "Hard", colour: "#f44336" },
        { value: "Office", colour: "#f44336" },
        { value: "Unknown", colour: "#2d99ff" },
      ],
    };
  },
  created() {
    if (!this.hierarchiesLoading)
      this.fetchData();
  },
  computed: {
    allFilters() {
      const filters = [];
      this.views.forEach((v) => {
        filters.push(
          ...v.filterCriteria.map((fc) => {
            return {
              view: v,
              description: fc.levels ? fc.levels.join(' > ') : fc.values.join(', '),
            };
          })
        );
      });
      return filters;
    },
    ...mapState({
      docList: (state) => state.docs.docsList,
      hierarchies: (state) => state.hierarchies.hierarchies,
      hierarchiesLoading: (state) => state.hierarchies.loading,
    }),
  },
  watch: {
    $route() {
      this.switchDashDef();
    },
    hierarchiesLoading(val) {
      if (!val)
        this.fetchData();
    }
  },
  methods: {
    fetchData() {
      this.isLoading = true;

      this.hierarchyTypes = this.hierarchies.map(h => {
        return {
          ht_id: h.ht_id,
          name: h.ht_name,
          label: h.label,
          column: "h" + h.ht_id,
          levels: h.levels.length,
        }
      });

      axios
        .get("document/getDocumentStates/")
        .then((resp) => {
          if (resp.data.Status === "OK") {
            this.dashDefs = resp.data.Data.dashDef;
            let sections = [
              {
                name: "Document Type",
                column: "doc_type",
              },
              { name: "Status", column: "doc_status" },
              {
                name: "Overall Score",
                measure: "Overall Score",
                scoreBand: this.scoreBands.find((sb) => sb.name === "single")
                  .ranges,
              },
              {
                name: "Overall Quality",
                measure: "Overall Score",
                scoreBand: this.scoreBands.find((sb) => sb.name === "default")
                  .ranges,
              },
              {
                name: "Status Age",
                measure: "Status Age",
                hideFilter: true,
                scoreBandGroupBy: "doc_status",
                scoreBand: this.scoreBands.find((sb) => sb.name === "ages")
                  .ranges,
              },
              {
                name: "Status Quality",
                measure: "Overall Score",
                hideFilter: true,
                scoreBandGroupBy: "doc_status",
                scoreBand: this.scoreBands.find((sb) => sb.name === "default")
                  .ranges,
              },
              { name: "Owner", column: "owner_name" },
              {
                name: "Created By",
                column: "created_by",
              },
            ];
            
            resp.data.Data.categories.forEach((c, ci) => {
              let measure = {
                name: c.category.trim(),
                catIndex: ci,
                isMean: true,
                isPercent: true,
                isDocScore: true,
                doColourBand: true,
              };
              this.measures.push(measure);
              sections.push({
                name: measure.name + " Score",
                measure: measure.name,
                scoreBand: this.scoreBands.find((sb) => sb.name === "default")
                  .ranges,
              });
            });

            const flexCategories =
              resp.data.Data.flexCategories?.map((c, ci) => {
                return {
                  flexSecId: c.afs_id,
                  column: "flexOutcome" + ci,
                  name: c.section_name.trim(),
                };
              }) || [];
            this.flexCategories = flexCategories;
            this.measures.push(
              ...flexCategories.map((c) => {
                return {
                  name: c.name,
                  flexSecId: c.flexSecId,
                  isFlexScore: true,
                  isMean: true,
                  isPercent: true,
                  doColourBand: true,
                };
              })
            );

            sections.forEach((s, si) => {
              s.column = s.scoreBand ? "s_band" + si : s.column || null;
              s.data = [];
            });

            this.measures.forEach((m, mi) => {
              m.measureColumn = "m_score" + mi;
              m.countColumn = "m_count" + mi;
            });

            const lifecycleStatuses =
              resp.data.Data.lifecycleStatuses?.map((s, si) => {
                return {
                  status: s.status.trim(),
                  column: "statusScore" + si,
                  name: s.status.trim() + " Score",
                };
              }) || [];
            this.lifecycleStatuses = lifecycleStatuses;
            this.measures.push(
              ...lifecycleStatuses.map((c) => {
                return {
                  name: c.name,
                  measureColumn: c.column,
                  isStatusScore: true,
                  isMean: true,
                  isPercent: true,
                  doColourBand: true,
                };
              })
            );

            if (this.scoreBands.length) {
              resp.data.Data.documents.forEach((d) => {
                // omly needed for DEMO - delete after
                if (this.$loginState.user.client === "Demo") {
                  let band = this.colourBands.find(
                    (b) => d.summary.score >= b.from && d.summary.score < b.to
                  );
                  d.summary.scoreColour = band ? band.colour : "red";
                  d.summary.categories.forEach((c) => {
                    let catband = this.colourBands.find(
                      (b) => c.score >= b.from && c.score < b.to
                    );
                    c.scoreColour = catband ? catband.colour : "red";
                  });
                }
                // omly needed for DEMO - delete after --- END

                d.showSummary = false;
                // d.page = 1;

                this.measures.filter(m => !m.isStatusScore).forEach((m) => {
                  let val;
                  let colour;
                  let info;
                  let cat = d.summary.categories.find(
                    (x) => x.category === m.name
                  );
                  if (m.catIndex >= 0 && cat) {
                    val = cat.score;
                    colour = cat.scoreColour;
                  } else if (m.flexSecId) {
                    const flexCat = d.flexCategories?.find(
                      (c) => c.afs_id === m.flexSecId
                    ) ||
                      d.summary.categories.find(
                        (c) => c.category === "Flexible Working"
                      ) || { score: 100, scoreColour: "green " };
                    val = flexCat.score;
                    colour = flexCat.scoreColour;
                    if (flexCat.comment)
                      info = `RoleMapper Assessment: <b>${flexCat.outcome}</b><br />User Comment: <b>${flexCat.comment}</b>`;
                  } else {
                    val =
                      m.source === "summary.score"
                        ? d.summary.score
                        : d[m.source];
                    if (val === undefined)
                      val = m.isPercent ? 100 : 0;
                    colour =
                      m.source === "summary.score" ? d.summary.scoreColour : "";
                  }
                  d[m.measureColumn] = val;
                  d[m.measureColumn + "Colour"] = colour;
                  d[m.measureColumn + "Info"] = info;
                });

                sections.forEach((s) => {
                  let m = s.measure
                    ? this.measures.find((x) => x.name === s.measure)
                    : null;
                  if (m && s.scoreBand) {
                    let val = d[m.measureColumn];
                    let band = s.scoreBand.find(
                      (b) => val > b.from && val <= b.to
                    );
                    if (band) {
                      d[s.column] = band.title;
                    } else {
                      d[s.column] = "Unclassified Score";
                    }
                    if (s.scoreBandGroupBy) {
                      d[s.column + "GBDetail"] = d[s.column];
                      d[s.column + "GBHeader"] = d[s.scoreBandGroupBy];
                      d[s.column] = d[s.scoreBandGroupBy] + " - " + d[s.column];
                    }
                  }
                });
                d.hierarchies.forEach((h) => {
                  let hry = this.hierarchyTypes.find(
                    (ht) => ht.ht_id === h.ht_id
                  );
                  if (hry) {
                    let val = h["hierarchy" + h.levels] || "";
                    val = val.trim();
                    if (val.length === 0) {
                      for (let i = parseInt(h.levels); i > 0; i--) {
                        let hval = h["hierarchy" + i] || "";
                        hval = hval.trim();
                        h["hierarchy" + i] = hval || "UNCLASSIFIED";
                      }
                    }
                    d[hry.column] =
                      (!val.length ? "UNCLASSIFIED - " : "") + h.hierarchy1;
                  }
                });
                flexCategories.forEach((f) => {
                  const flexCat = d.flexCategories?.find(
                    (c) => c.afs_id === f.flexSecId
                  ) || { outcome: "Unknown" };
                  d[f.column] = flexCat.outcome;
                  d[f.column + "Colour"] = flexCat.outcomeColour;
                });
              });
            }
            if (resp.data.Data.documents.length) {
              this.hierarchyTypes.forEach((h) => {
                sections.push({
                  name: h.name,
                  label: h.label,
                  data: [],
                  column: "hierarchy",
                  ht_id: h.ht_id,
                  bottomLevel: Number(h.levels),
                  currentLevel: Number(h.levels),
                  allowDrilldown: true,
                });
              });

              sections.push({
                name: "Flex Completed",
                data: [],
                column: "flexCompleted",
                isFlex: true,
              });
              sections.push(
                ...flexCategories.map((c) => {
                  return {
                    name: c.name,
                    data: [],
                    column: c.column,
                    isFlex: true,
                    dimensionColours: this.flexOutcomeColours,
                  };
                })
              );
            }

            this.rawData = resp.data.Data.documents;
            sections.forEach((s) => {
              s.filterCriteria = [];
              s.filter = null;
            });
            this.views = sections;

            this.switchDashDef();

            this.viewsAvailable = {
              views: sections
                .filter((s) => s.data.length > 0)
                .map((s) => s.name),
            };
            this.view = 0;
            this.isLoading = false;
          }
          this.response = resp.data;
          //console.log(resp);
        })
        .catch((err) => {
          alert("Error loading dashboard data");
          console.log(err);
          this.isLoading = false;
        });
    },
    switchDashDef() {
      this.layouts.splice(0);
      let dashDef = this.dashDefs.find(
        (dd) => dd.db_id == this.$route.meta.dashboardId
      );
      if (!dashDef) dashDef = this.dashDefs[0];
      dashDef.layouts.forEach((l) => {
        this.layouts.push(JSON.parse(l.layout_definition));
      });
      this.layouts.forEach((l) => {
        if (l.preFilters) {
          l.preFilters.forEach((p) => {
            // set name for hierarchies
            if (p.ht_id) {
              let sec = this.views.find((s) => s.ht_id === p.ht_id);
              if (sec) p.section = sec.name;
            }
          });
        }
        l.rows.forEach((r) => {
          r.columns.forEach((c) => {
            c.items.forEach((v) => {
              if (v.items && v.items.length) {
                this.$set(v, "activeIndex", 0);
                v.items.forEach((t) => {
                  this.setupItem(t, this.views);
                });
              } else {
                this.setupItem(v, this.views);
              }
            });
          });
        });
      });
      let initLayout = this.layouts[0];
      this.changeLayout(initLayout, this.views);
    },
    setupItem(item, sections) {
      // set name for hierarchies
      if (item.ht_id) {
        let sec = sections.find((s) => s.ht_id === item.ht_id);
        if (sec) item.section = sec.name;
      }
      item.view = sections.find((s) => s.name === item.section);
      if (!item.issueList) {
        let measures = JSON.parse(JSON.stringify(this.measures));
        measures = measures
          .filter(
            (m) =>
              !item.settings.hideDocScoreMeasures ||
              !m.isDocScore ||
              m.name === "Flexible Working"
          )
          .filter(
            (m) => !item.settings.hideFlexScoreMeasures || !m.isFlexScore
          );
        if (item.settings.hideDocScoreMeasures) {
          let flexibleWorking = measures.find(
            (m) => m.name == "Flexible Working"
          );
          if (flexibleWorking) flexibleWorking.name = "Overall Score";
        }
        item.settings.measures = measures;
      }
    },
    doFilter() {
      let sections = this.views;
      let getSortFunc = function (def) {
        let converter;
        switch (def.datatype) {
          case "number":
            converter = function (x) {
              return Number(x);
            };
            break;
          case "date":
            converter = function (x) {
              return moment(x, "D MMM YYYY");
            };
            break;
          default:
            converter = function (x) {
              return (x || "").toString().toLowerCase();
            };
        }
        if (!def.desc) {
          return function (a, b) {
            let aa = converter(a[def.column]);
            let bb = converter(b[def.column]);
            return aa > bb ? 1 : bb > aa ? -1 : 0;
          };
        } else {
          return function (a, b) {
            let aa = converter(a[def.column]);
            let bb = converter(b[def.column]);
            return aa < bb ? 1 : bb < aa ? -1 : 0;
          };
        }
      };
      let sortBy = function (a, b) {
        return a.orderKey > b.orderKey ? 1 : b.orderKey > a.orderKey ? -1 : 0;
      };

      sections.forEach((s) => {
        s.data = [];
      });

      let data = this.rawData.filter((x) => {
        let include = true;

        if (this.layout.compulsaryFilters && this.layout.compulsaryFilters.length) {
          this.layout.compulsaryFilters.forEach(f => {
            if (!f.values.includes(x[f.column]))
              include = false;
          })
        }

        return include;
      });

      this.multiSelectViews.forEach((s) => {
        if (s.data.length === 0) {
          data.forEach((d) => {
            let value;
            if (s.view.ht_id) {
              let hi = d.hierarchies.find((h) => h.ht_id === s.view.ht_id);
              value = hi ? hi[s.view.column + s.view.currentLevel] : "";
            } else if (s.view.scoreBandGroupBy) {
              value = d[s.view.column + "GBHeader"];
            } else {
              value = d[s.view.column] || "";
            }
            if (!value || value.trim() === "") {
              value = "";
            }
            if (value) {
              let sectionRow = s.data.find((o) => o.key === value);
              if (!sectionRow) {
                s.data.push({
                  key: value,
                  orderKey: value,
                });
              }
            }
          });

          let secSort = this.getSortColumn(s.name);
          s.data.sort(secSort ? getSortFunc(secSort) : sortBy);
        }
      });

      // filter
      data = data.filter((x) => {
        let include = true;

        if (this.layout.compulsaryFilters && this.layout.compulsaryFilters.length) {
          this.layout.compulsaryFilters.forEach(f => {
            if (!f.values.includes(x[f.column]))
              include = false;
          })
        }

        if (this.filterIssue && include) {
          include =
            x.summary.warnings.some(
              (w) => w.short_description === this.filterIssue
            ) ||
            x.summary.errors.some(
              (w) => w.short_description === this.filterIssue
            );
        }
        let hasOr = false;
        let orInclude = false;
        sections.forEach((s) => {
          if (include || hasOr) {
            s.filterCriteria.forEach((c) => {
              if (include || c.isOr) {
                if (c.isOr) hasOr = true;
                let value;
                if (c.ht_id) {
                  let h = x.hierarchies.find((h) => h.ht_id === c.ht_id);
                  let index = 0;
                  if (
                    (!c.levels || c.levels.length < 2) &&
                    c.values &&
                    c.values.length > 0
                  ) {
                    value = h ? h[c.column + c.bottomLevel] || "" : "";
                    include = c.values.indexOf(value) >= 0;
                  } else {
                    for (let i = c.bottomLevel; i >= c.currentLevel; i--) {
                      if (include) {
                        value = h ? h[c.column + i] || "" : "";
                        include = value === c.levels[index];
                        index++;
                      }
                    }
                  }
                } else {
                  value = x[c.column] || "";
                  include = c.values.indexOf(value) >= 0;
                }
                if (include && c.isOr) orInclude = true;
              }
            });
          }
        });
        if (orInclude) include = true;
        return include;
      });

      let issueCats = [];
      // summarise
      sections.forEach((s, si) => {
        data.forEach((d) => {
          if (si === 0) {
            d.summary.warnings.forEach((w) => {
              let issue = issueCats.find(
                (ic) => ic.name === w.short_description
              );
              if (!issue) {
                issue = {
                  name: w.short_description,
                  docCount: 0,
                  docIds: [],
                  warningCount: 0,
                  errorCount: 0,
                };
                if (!this.filterIssue || this.filterIssue === issue.name)
                  issueCats.push(issue);
              }
              if (!issue.docIds.includes(d.doc_id)) {
                issue.docIds.push(d.doc_id);
                issue.docCount++;
              }
              issue.warningCount += w.warningCount;
            });
            d.summary.errors.forEach((w) => {
              let issue = issueCats.find(
                (ic) => ic.name === w.short_description
              );
              if (!issue) {
                issue = {
                  name: w.short_description,
                  docCount: 0,
                  docIds: [],
                  warningCount: 0,
                  errorCount: 0,
                };
                if (!this.filterIssue || this.filterIssue === issue.name)
                  issueCats.push(issue);
              }
              if (!issue.docIds.includes(d.doc_id)) {
                issue.docIds.push(d.doc_id);
                issue.docCount++;
              }
              issue.errorCount += w.errorCount;
            });
          }
          let value;
          let valueGBDet;
          if (s.ht_id) {
            let hi = d.hierarchies.find((h) => h.ht_id === s.ht_id);
            value = hi ? hi[s.column + s.currentLevel] : "";
          } else if (s.scoreBandGroupBy) {
            value = d[s.column + "GBHeader"];
            valueGBDet = d[s.column + "GBDetail"];
          } else {
            value = d[s.column] || "";
          }
          if (!value || value.trim() === "") {
            value = "";
          }
          let sectionRow = s.data.find((o) => o.key === value);
          if (!sectionRow) {
            sectionRow = {
              key: value,
              orderKey: value,
              GBDetails: [],
            };
            this.measures.forEach((m) => {
              if (m.isCount || m.isMean) {
                sectionRow[m.measureColumn] = 0;
              }
              if (m.isMean) {
                sectionRow[m.countColumn] = 0;
                sectionRow["total" + m.measureColumn] = 0;
              }
              if (m.doColourBand)
                sectionRow[m.measureColumn + "Colour"] = "red";
            });
            if (s.scoreBand && s.scoreBand.length) {
              let sb = s.scoreBand.find((sb) => sb.title === sectionRow.key);
              if (sb) {
                sectionRow.orderKey = sb.sequence;
              }
            }
            s.data.push(sectionRow);
          }
          let sbDetailRow;
          if (s.scoreBandGroupBy) {
            sbDetailRow = sectionRow.GBDetails.find(
              (o) => o.key === valueGBDet
            );
            if (!sbDetailRow) {
              sbDetailRow = {
                key: valueGBDet,
                orderKey: valueGBDet,
              };
              this.measures.forEach((m) => {
                if (m.isCount || m.isMean) {
                  sbDetailRow[m.measureColumn] = 0;
                }
                if (m.isMean) {
                  sbDetailRow[m.countColumn] = 0;
                  sbDetailRow["total" + m.measureColumn] = 0;
                }
                if (m.doColourBand)
                  sbDetailRow[m.measureColumn + "Colour"] = "red";
              });
              if (s.scoreBand && s.scoreBand.length) {
                let sb = s.scoreBand.find((sb) => sb.title === sbDetailRow.key);
                if (sb) {
                  sbDetailRow.orderKey = sb.sequence;
                }
              }
              sectionRow.GBDetails.push(sbDetailRow);
            }
          }
          this.measures.forEach((m) => {
            if (m.isCount) {
              sectionRow[m.measureColumn]++;
              if (sbDetailRow) sbDetailRow[m.measureColumn]++;
            } else if (m.isMean) {
              sectionRow[m.countColumn]++;
              sectionRow["total" + m.measureColumn] += d[m.measureColumn];
              sectionRow[m.measureColumn] = (
                sectionRow["total" + m.measureColumn] /
                sectionRow[m.countColumn]
              ).toFixed(0);
              if (sbDetailRow) {
                sbDetailRow[m.countColumn]++;
                sbDetailRow["total" + m.measureColumn] += d[m.measureColumn];
                sbDetailRow[m.measureColumn] = (
                  sbDetailRow["total" + m.measureColumn] /
                  sbDetailRow[m.countColumn]
                ).toFixed(0);
              }
            }
            if (s.orderBy && s.orderBy === m.measureColumn) {
              sectionRow.orderKey = Number(sectionRow[m.measureColumn]);
              if (sbDetailRow)
                sbDetailRow.orderKey = Number(sbDetailRow[m.measureColumn]);
            }
            if (m.doColourBand) {
              let band = this.colourBands.find(
                (b) =>
                  sectionRow[m.measureColumn] >= b.from &&
                  sectionRow[m.measureColumn] < b.to
              );
              sectionRow[m.measureColumn + "Colour"] = band
                ? band.colour
                : "red";
              if (sbDetailRow) {
                let band = this.colourBands.find(
                  (b) =>
                    sbDetailRow[m.measureColumn] >= b.from &&
                    sbDetailRow[m.measureColumn] < b.to
                );
                sbDetailRow[m.measureColumn + "Colour"] = band
                  ? band.colour
                  : "red";
              }
            }
          });
        });
      });
      
      sections.forEach((s) => {
        let secSort = this.getSortColumn(s.name);
        s.data.sort(secSort ? getSortFunc(secSort) : sortBy);
        if (s.scoreBandGroupBy) {
          s.data.forEach((d) => {
            d.GBDetails.sort(sortBy);
          });
        }
      });

      this.views = sections;
      let docSort = this.getSortColumn(null, true);
      if (docSort) {
        data.sort(getSortFunc(docSort));
      }
      let issueSort = this.getSortColumn(null, false, true);
      if (issueSort) {
        issueCats.sort(getSortFunc(issueSort));
      }
      this.documents = data;
      this.issues = issueCats;
    },
    removeFilter(view, levelIndex) {
      if (levelIndex > 0) {
        view.filterCriteria[0].levels.splice(levelIndex);
        this.doFilter();
      } else {
        this.clearFilter(view);
      }
    },
    clearFilter(view, skipFilter) {
      if (view) {
        view.currentLevel = view.bottomLevel;
        view.filterCriteria.splice(0);
        view.filter = null;
      } else {
        this.views.forEach((v) => {
          v.filterCriteria.splice(0);
          v.currentLevel = v.bottomLevel;
          v.filter = null;
        });
        this.filterIssue = null;
      }
      this.multiSelectViews?.filter(v => v.name === view.name)?.forEach(v => v.filter = null);
      if (!skipFilter)
        this.doFilter();
    },
    prepMultiSelectFilter(view) {
      const filter = [...view.filter];
      this.clearFilter(view.view, true);
      view.filter = filter;
      view.view.filter = filter;
      this.prepFilter(view.view);
    },
    prepFilter(section) {
      if (section.filter) {
        if (Array.isArray(section.filter)) {
          if (section.filter.length === 0)
            this.clearFilter(section);
          else
            this.addFilter(section, null, section.filter.map(f => f.key), true);
        } else {
          this.addFilter(section, section.filter.key);
        }
        this.doFilter();
      } else {
        this.clearFilter(section);
      }
    },
    addViewFilter(view, value) {
      this.addFilter(view, value);
      this.doFilter();
    },
    addFilter(view, value, valList, isOr) {
      let cond = null;
      cond = view.filterCriteria.find(
        (f) => view.ht_id && f.ht_id === view.ht_id
      );
      if (cond && cond.levels) {
        cond.levels.push(value);
        cond.currentLevel--;
      } else {
        let val = value ? value : valList ? valList[0] : "";
        view.filterCriteria.push({
          column: view.column,
          values: valList || [value],
          value: view.ht_id ? val : null,
          ht_id: view.ht_id,
          bottomLevel: view.bottomLevel,
          currentLevel: view.currentLevel,
          levels: view.ht_id && !valList ? [val] : null,
          isOr: isOr,
        });
        view.isOrFilter = isOr;
        if (valList && valList.length === 1) view.filter = val;
      }
      if (!isOr && view.currentLevel && view.currentLevel > 1) {
        view.currentLevel--;
      }
    },
    changeView(item, name) {
      item.section = name;
      item.view = this.views.find((v) => v.name === name);
    },
    changeLayout(item, sections) {
      if (item) this.layout = item;
      else item = this.layout;

      this.views.forEach((v) => {
        v.filterCriteria.splice(0);
        v.currentLevel = v.bottomLevel;
        v.filter = null;
        v.isOrFilter = false;
      });
      this.filterIssue = null;

      if (item.preFilters) {
        item.preFilters.forEach((pf) => {
          let view = this.views.find((v) => v.name === pf.section);
          if (view) {
            this.addFilter(view, null, pf.values, pf.isOr);
          }
        });
      }

      if (item.hiddenStatusScores)
        this.visibleLifecycleStatuses = this.lifecycleStatuses.filter(s => !item.hiddenStatusScores.includes(s.status));
      else
        this.visibleLifecycleStatuses = this.lifecycleStatuses;

      this.multiSelectViews = this.views.filter(
        (v) =>
          !this.layout.hideFilters &&
          (this.layout.showFlexFilters || !v.isFlex) &&
          !v.hideFilter &&
          (!this.layout.hideMeasureFilters || !v.measure) &&
          (!this.layout.hiddenFilters || !this.layout.hiddenFilters.includes(v.name))
      ).map(v => {
        return {
          filter: null,
          data: [],
          name: v.name,
          label: v.label,
          view: v
        }
      });
      this.doFilter(sections);
    },
    addIssueFilter(issue) {
      this.filterIssue = issue.name;
      this.doFilter();
    },
    removeIssueFilter() {
      this.filterIssue = null;
      this.doFilter();
    },
    getSortColumn(section, docList, issueList) {
      let sortBy;
      this.layout.rows.forEach((r) => {
        r.columns.forEach((c) => {
          c.items.forEach((v) => {
            if (!sortBy)
              sortBy = this.getItemSortColumn(v, section, docList, issueList);
            if (v.items)
              v.items.forEach((t) => {
                if (!sortBy)
                  sortBy = this.getItemSortColumn(
                    t,
                    section,
                    docList,
                    issueList
                  );
              });
          });
        });
      });
      return sortBy;
    },
    getItemSortColumn(item, section, docList, issueList) {
      if (
        item.settings &&
        item.settings.sortBy &&
        (!section || section === item.section) &&
        (!docList || item.docList === docList) &&
        (!issueList || item.issueList === issueList)
      ) {
        if (item.settings.sortBy.measure) {
          let m = this.measures.find(
            (m) => m.name === item.settings.sortBy.measure
          );
          if (m)
            return {
              column: m.measureColumn,
              desc: item.settings.sortBy.desc,
              datatype: "number",
            };
        } else {
          return item.settings.sortBy;
        }
      }
      return undefined;
    },
    setMeasures() {
      this.layout.rows.forEach((r) => {
        r.columns.forEach((c) => {
          c.items.forEach((v) => {
            v.settings.measures = JSON.parse(JSON.stringify(this.measures));
            if (v.items)
              v.items.forEach((t) => {
                t.settings.measures = JSON.parse(JSON.stringify(this.measures));
              });
          });
        });
      });
      this.doFilter();
    },
    unusedViews() {
      let usedViews = [];
      this.layout.rows.forEach((r) => {
        r.columns.forEach((c) => {
          c.items.forEach((v) => {
            usedViews.push(v.section);
            if (v.items)
              v.items.forEach((t) => {
                usedViews.push(t.section);
              });
          });
        });
      });
      return this.views.filter(
        (v) =>
          !this.layout.hideFilters &&
          (this.layout.showFlexFilters || !v.isFlex) &&
          !usedViews.some((u) => u === v.name) &&
          (v.data.length > 1 || v.filter) &&
          !v.hideFilter &&
          !v.isOrFilter &&
          (!this.layout.hideMeasureFilters || !v.measure) &&
          (!this.layout.hiddenFilters || !this.layout.hiddenFilters.includes(v.name)) &&
          !this.multiSelectViews.some(mv => mv.view === v)
      );
    },
    openDocument(doc, openFlex) {
      let docItem = this.docList.find(x => x.doc_id === doc.doc_id);
      this.$emit("openDocument", {
        system_number: utils.removeTags(doc.reference),
        doc_name: utils.removeTags(doc.doc_name),
        doc_type: doc.doc_type,
        openFlex: openFlex,
        viewAction: docItem?.viewAction ?? null,
      });
    },
    getDocListMeasures(view) {
      let d = view.settings.measures.filter((x) =>
        view.settings.activeMeasures.some((m) => m === x.name)
      );
      return d;
    },
    changeSort(settings, item) {
      if (item.name) {
        if (settings.sortBy && settings.sortBy.measure === item.name) {
          settings.sortBy.desc = !settings.sortBy.desc;
        } else {
          settings.sortBy = { measure: item.name, desc: false };
        }
      } else if (item.ht_id) {
        if (settings.sortBy && settings.sortBy.column === item.column) {
          settings.sortBy.desc = !settings.sortBy.desc;
        } else {
          settings.sortBy = {
            column: item.column,
            desc: false,
            datatype: "string",
          };
        }
      } else {
        if (settings.sortBy && settings.sortBy.column === item) {
          settings.sortBy.desc = !settings.sortBy.desc;
        } else {
          settings.sortBy = {
            column: item,
            desc: false,
            datatype: item.toLowerCase().indexOf("count") >= 0
              ? "number"
              : item.toLowerCase().indexOf("date") >= 0
                ? "date"
                : "",
          };
        }
      }
      this.doFilter();
    },
    showMeasure(settings, measure) {
      let item = settings.activeMeasures.findIndex(
        (m) => m === measure.name
      );
      if (item >= 0) settings.activeMeasures.splice(item, 1);
      else settings.activeMeasures.push(measure.name);
    }
  },
};
</script>
<style scoped lang="scss">
.panel-container {
  max-width: 100%;
  flex-grow: 1 !important;
}
.tabHeaders {
  max-width: calc(100% - 200px);
  z-index: 1;
  
  .v-tab {
    text-transform: none;
    font-size: 16px;
  }
}

::v-deep .tabContent {
  position: relative;
  top: -70px;
  .sectionTitle {
    h2 {
      display: none;
    }
    margin-bottom: 10px;
  }
}
</style>
<style>
.doc .v-list-item__content {
  padding: 4px 0px;
}
.doc .v-list-item__title {
  font-size: 14px;
}
.doc .v-list-item__subtitle {
  font-size: 12px;
}
.v-input--selection-controls {
  margin-top: 6px;
  padding-top: 1px;
}
.v-messages {
  min-height: 1px;
}
</style>