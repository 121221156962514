import axios from "axios";

export default {
  install: function install(Vue, options = {}) {
    if (!options.disabled && (!options.key || options.key.length === 0)) {
      console.warn("Please enter a Zendesk Web Widget Key");
    }

    const disabledLogger = function (method, ...args) {
      console.log("Zendesk is disabled, you called:", { method, args });
    };

    if (options.disabled) {
      window.zE = disabledLogger;
    }

    window.zESettings = options.settings;

    Vue.prototype.$zendesk = new Vue({
      data: {
        isEnabled: !!options.key,
        isLoaded: false,
        isLoggedIn: false,
        isVisible: false,
        isOpen: false,
      },
      created() {
        if (!options.disabled) {
          this.load();
        }
      },
      methods: {
        load() {
          if (!options.key) {
            return;
          }

          if (this.isLoaded) {
            return;
          }

          const script = document.createElement("script");
          script.type = "text/javascript";
          script.async = true;
          script.id = "ze-snippet";
          script.src =
            "https://static.zdassets.com/ekr/snippet.js?key=" + options.key;

          if (options.nonce) {
            script.setAttribute("nonce", options.nonce);
          }

          delete window.zE;
          const first = document.getElementsByTagName("script")[0];
          first.parentNode.insertBefore(script, first);

          const root = this;
          script.onload = (event) => {
            this.isLoaded = true;

            if (options.hideOnLoad) {
              this.hide();
            }

            this.$emit("loaded", event);

            window.zE("messenger:on", "open", () => {
              root.isOpen = true;
              root.$emit("open");
            });

            window.zE("messenger:on", "close", () => {
              root.isOpen = false;
              root.$emit("close");
            });

            this.login();
          };
        },
        login() {
          const root = this;
          if (this.isLoaded && this.$loginState.isAuthenticated) {
            const tags = [this.$loginState.user.client];

            if (this.$loginState.user.environment)
              tags.push(this.$loginState.user.environment);

            if (this.$loginState.user.permission_groups?.length)
              tags.push(this.$loginState.user.permission_groups.join(","));

            window.zE("messenger:set", "conversationTags", tags);

            window.zE("messenger", "loginUser", function (callback) {
              axios.get("auth/zendeskMessagingToken").then((resp) => {
                if (!resp.data) return;
                root.isLoggedIn = true;
                const jwt = resp.data.jwt;
                callback(jwt);
                root.show();
                root.open();
              }).catch((e) => {
                console.log(e);
                root.isLoggedIn = true;
                root.show();
                root.open();
              });
            });
          }
        },
        logout() {
          if (this.isLoaded) {
            this.isLoggedIn = false;
            window.zE("messenger", "logoutUser");
          }
          this.hide();
        },
        hide() {
          this.isVisible = false;
          if (this.isLoaded) window.zE("messenger", "hide");
        },
        show() {
          this.isVisible = true;
          if (this.isLoaded) window.zE("messenger", "show");
          else this.load();
        },
        open() {
          if (this.isLoaded) window.zE("messenger", "open");
          else this.load();
        },
        close() {
          if (this.isLoaded) window.zE("messenger", "close");
        },
        toggle() {
          if (!this.isEnabled) return;

          if (!this.isLoaded) this.load();
          else if (!this.isLoggedIn) this.login();
          else if (!this.isVisible) this.show();
          else if (!this.isOpen) this.open();
          else this.hide();
        },
      },
      zE: {
        get: function get() {
          return window.zE;
        },
      },
    });
  },
};
