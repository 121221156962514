<template>
  <v-container fluid class="px-6">
    <h4>
      Hierarchy Types can be set to be hidden for documents based on this template:
    </h4>
    <v-container v-if="hierarchyTypes">
      <v-divider class="mb-2" />
      <v-row class="mb-1">
        <v-col cols="2"><h4>Hierarchy Type</h4></v-col>
        <v-col cols="2"><h4>Hidden?</h4></v-col>
        <v-col cols="5"><h4>Default Hierarchy</h4></v-col>
        <v-col cols="2"><h4>Hide Default Value?</h4></v-col>
        <v-col cols="1"></v-col>
      </v-row>
      <v-divider class="mb-2" />
      <v-row
        v-for="ht in hierarchyTypes"
        :key="'e' + ht.ht_id"
        @click="edit(ht)"
        style="cursor: pointer"
      >
        <v-col cols="2">{{ ht.ht_name }}</v-col>
        <v-col cols="2">{{ ht.hr_id_default ? "Yes" : "No" }}</v-col>
        <v-col cols="5">{{
          ht.hr_id_default ? ht.hidden_default_title : ""
        }}</v-col>
        <v-col cols="2">{{
          ht.hide_in_picker ? "Hidden in Picker" : ""
        }}</v-col>
        <v-col cols="1"
          ><v-btn small outlined @click="edit(ht)">Edit</v-btn></v-col
        >
      </v-row>
    </v-container>

    <v-dialog v-model="showEdit" max-width="800px">
      <v-card v-if="hierarchyTypeHidden">
        <v-card-title>
          Pick a default {{ hierarchyTypeHidden.link_title }} value to hide
          {{ hierarchyTypeHidden.ht_name }} for this template
          <v-spacer />
          <v-btn icon @click="showEdit = false"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <HierarchyTreePicker
                v-model="hierarchyTypeHidden.hr_id_default"
                :label="hierarchyTypeHidden.ht_name"
                :options="currentHTValues"
                placeholder="default"
                dense
              >
              </HierarchyTreePicker>
            </v-col>
            <v-col cols="6">
              <v-switch
                v-model="hierarchyTypeHidden.hide_in_picker"
                inset
                label="Hide the default value in Tree Pickers"
              ></v-switch>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pr-6 pb-4">
          <v-btn
            v-if="hierarchyTypeHidden.tmpl_hierarchy_hidden_id"
            color="primary"
            @click="deleteItem"
            >Unhide</v-btn
          ><v-spacer></v-spacer>
          <v-btn color="primary" @click="save">Save</v-btn>
          <v-btn color="primary" @click="showEdit = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Loading :isVisible="isLoading" />
    <ResponseHandler :serviceResponse="response"></ResponseHandler>
  </v-container>
</template>

<script>
import axios from "axios";
import ResponseHandler from "@/components/ResponseHandler";
import { mapState } from "vuex";
import HierarchyTreePicker from "@/components/cHierarchyTreePicker";

export default {
  name: "cTemplateBuilderHierarchyHidden",
  components: {
    ResponseHandler,
    HierarchyTreePicker,
  },
  props: {
    value: { type: Number, required: false },
  },
  data: function () {
    return {
      isLoading: false,
      response: null,
      hierarchyTypes: [],
      hierarchyTypeHidden: null,
      showEdit: false,
      currentHTValues: [],
      hierarchies: [],
    };
  },
  mounted() {},
  created() {
    if (!this.hierarchiesLoading) this.init();
  },
  watch: {
    value() {
      this.init();
    },
  },
  computed: {
    ...mapState({
      hierarchiesLoading: (state) => state.hierarchies.loading,
    }),
    saveEEnabled() {
      if (!this.hierarchyTypeHidden) return false;

      if (!this.hierarchyTypeHidden.hr_id_default) return false;

      return true;
    },
  },
  methods: {
    sessionExpired(err) {
      this.$emit("sessionExpired", err);
    },
    init() {
      let possibleError = false;
      this.isLoading = true;
      if (
        !this.$store.state.hierarchies.loading &&
        this.$store.state.hierarchies.hierarchies.length
      ) {
        this.hierarchies = JSON.parse(
          JSON.stringify(this.$store.state.hierarchies.hierarchies)
        );
      }
      axios
        .get("templateBuilder/templateHierarchyHidden/" + this.value)
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            const data = resp.data.Data;

            this.hierarchyTypes = data;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
    edit(item) {
      this.hierarchyTypeHidden = JSON.parse(JSON.stringify(item));
      this.hierarchyTypeHidden.hide_in_picker =
        Number(this.hierarchyTypeHidden.hide_in_picker) > 0;
      this.showEdit = true;
      this.currentHTValues =
        this.hierarchies.find((x) => x.ht_id === this.hierarchyTypeHidden.ht_id)
          ?.treePickerOptionsNotHidden || [];
    },
    save() {
      if (!this.hierarchyTypeHidden) return;
      let possibleError = false;
      this.isLoading = true;
      axios
        .post(
          "templateBuilder/saveTemplateHierarchyHidden",
          this.hierarchyTypeHidden
        )
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            if (!this.hierarchyTypeHidden.tmpl_hierarchy_hidden_id) {
              this.hierarchyTypeHidden.tmpl_hierarchy_hidden_id =
                resp.data.Data.tmpl_hierarchy_hidden_id;
            }
            this.hierarchyTypeHidden.hidden_default_title =
              resp.data.Data.hidden_default_title;
            let index = this.hierarchyTypes.findIndex(
              (x) => x.ht_id === this.hierarchyTypeHidden.ht_id
            );
            this.hierarchyTypes.splice(index, 1, this.hierarchyTypeHidden);
            this.showEdit = false;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
    deleteItem() {
      if (confirm(`Unhide ${this.hierarchyTypeHidden.ht_name} Hierarchy Type?`)) {
        this.hierarchyTypeHidden.hr_id_default = null;
        this.hierarchyTypeHidden.hide_in_picker = false;
        this.hierarchyTypeHidden.hidden_default_title = null;
        this.save();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";

.draghandle {
  cursor: move;
}
</style>