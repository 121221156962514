<template>
  <div>
    <v-row
      dense
      @keydown="keyDown"
      tabindex="0"
      @blur="lostFocus"
      ref="editContainer"
    >
      <v-col @click="editMode = editable">
        <v-row v-for="(tg, tgi) in tag_gradings" :key="'tg' + tgi">
          <v-col cols="2" v-if="!tg.hide_name">{{ tg.name }}</v-col>
          <v-col
            cols="2"
            v-if="!tg.tags.length && !editMode"
            :class="attribute.placeholder ? 'placeholder' : 'placeholder-empty'"
            >{{ attribute.placeholder || "|" }}</v-col
          >
          <v-col cols="3" v-if="editMode">
            <v-combobox
              v-model="selectedTags"
              :items="definition.values"
              item-value="tag_value_id"
              item-text="value"
              :label="'Select ' + definition.tag_type_name + '...'"
              hint="type to search"
              :search-input.sync="search"
              multiple
              dense
              outlined
              flat
              small-chips
              hide-details
              persistent-hint
              hide-selected
              :hide-no-data="!search"
              @change="checkNew(tg)"
              @keydown="keyDown"
              :filter="tagListSearch"
              @blur="lostFocus"
            >
              <template v-slot:no-data>
                <v-list-item v-if="allowAdd">
                  <span class="subheading">Hit ENTER to create </span>
                  <v-chip label small>{{ search }}</v-chip>
                  <span class="subheading"> or ESCAPE to remove text</span>
                </v-list-item>
                <v-list-item v-else>
                  <span class="subheading"
                    >No matching items found. Hit ESCAPE to remove text</span
                  >
                </v-list-item>
              </template>
              <template v-slot:selection="{}"> </template>
              <template v-slot:item="{ item }">
                <v-chip dark label small>{{ tagListText(item) }}</v-chip>
              </template>
            </v-combobox></v-col
          >
          <v-col
            v-on:drop="moveTag($event, null, tg)"
            v-on:dragover="allowDrop($event)"
          >
            <v-chip
              small
              label
              class="mr-2 px-2 mb-2"
              tabindex="0"
              v-for="(t, ti) in tg.tags"
              :key="'tg' + tgi + 't_' + ti"
              :title="
                editMode && allowEditTag
                  ? 'Click to edit ' + definition.tag_type_name + ' details'
                  : null
              "
              @blur="lostFocus"
              @click="editMode && allowEditTag ? editTag(t) : null"
              v-on:drop="moveTag($event, t, tg)"
              v-on:dragover="allowDrop($event)"
            >
              <v-icon
                v-if="editMode"
                small
                @click.stop="removeItem(t, tg)"
                title="Click to Remove"
                class="pr-2"
                >mdi-delete</v-icon
              >
              <span
                class="pr-2 drag-item"
                v-on:dragstart="dragStart($event, t)"
                v-on:dragend="dragEnd($event)"
                :draggable="allowEditTag || editMode"
                :style="{ cursor: dragItem === t ? 'grabbing' : 'grab' }"
                >{{ t.value }}</span
              >
              <div
                v-if="showRating && !editMode"
                style="
                  height: 100%;
                  float: right;
                  position: relative;
                  border: 3px solid transparent;
                "
              >
                <div
                  v-for="i in definition.maxRating"
                  :key="'d' + i"
                  :style="{
                    height: (i * 100) / definition.maxRating + '%',
                    backgroundColor: t.rating_number >= i ? 'green' : null,
                    border: t.rating_number < i ? 'red solid 1px' : null,
                    marginLeft: i > 1 ? '1px' : '6px',
                    top: 100 - (i * 100) / definition.maxRating + '%',
                  }"
                  style="width: 5px; float: left; position: relative"
                ></div>
              </div>
              <v-menu v-if="showRating && editMode">
                <template v-slot:activator="{ on, attrs }">
                  <div
                    @click.stop
                    v-bind="attrs"
                    v-on="on"
                    title="Click to change Rating"
                    style="
                      height: 100%;
                      float: right;
                      position: relative;
                      border: 3px solid transparent;
                    "
                  >
                    <div
                      v-for="i in definition.maxRating"
                      :key="'d' + i"
                      :style="{
                        height: (i * 100) / definition.maxRating + '%',
                        backgroundColor:
                          (t.rating_number || 0) >= i ? 'green' : null,
                        border:
                          (t.rating_number || 0) < i ? 'red solid 1px' : null,
                        marginLeft: i > 1 ? '1px' : '6px',
                        top: 100 - (i * 100) / definition.maxRating + '%',
                      }"
                      style="width: 5px; float: left; position: relative"
                    ></div>
                  </div>
                </template>
                <v-list ref="menuContainer">
                  <v-list-item
                    v-if="t.tag_type_rating_id"
                    dense
                    @click="updateRating(t, null)"
                    ><v-list-item-icon
                      ><v-icon>mdi-layers-remove</v-icon></v-list-item-icon
                    >
                    <v-list-item-title
                      >Remove Rating</v-list-item-title
                    ></v-list-item
                  >
                  <v-list-item
                    dense
                    v-for="r in definition.ratings"
                    :key="r.tag_type_rating_id"
                    @click="updateRating(t, r)"
                    :style="
                      r.tag_type_rating_id === t.tag_type_rating_id
                        ? 'backgroundColor: #e7f2f6;'
                        : null
                    "
                  >
                    <v-list-item-title>{{
                      r.sequence + ":   " + r.name
                    }}</v-list-item-title
                    ><v-list-item-icon v-if="r.description">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }"
                          ><v-icon v-bind="attrs" v-on="on" class="float-right"
                            >mdi-information-outline</v-icon
                          >
                        </template>
                        <span>{{ r.description }}</span>
                      </v-tooltip></v-list-item-icon
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-chip></v-col
          ><v-col cols="1" v-if="editMode && tg.tags.length > 1">
            <v-icon
              title="Remove all tags"
              @click="removeAll(tg)"
              class="float-right"
              >mdi-delete</v-icon
            ></v-col
          >
        </v-row>
      </v-col>
    </v-row>
    <ResponseHandler :serviceResponse="response"></ResponseHandler>

    <Loading :isVisible="isLoading" />
    <TagValueEdit
      :tag="tagForEdit"
      :show="editTagTrigger"
      @saved="tagSaved"
      @cancel="cancelEditTag"
    ></TagValueEdit>
  </div>
</template>
  
  <script>
// import axios from "axios";
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
// import utils from "@/common/utils.js";
import { mapState } from "vuex";
import TagValueEdit from "../document/TagValueEdit.vue";

export default {
  name: "TagAttribute",
  components: {
    ResponseHandler,
    TagValueEdit,
  },
  props: {
    attribute: Object,
    editable: Boolean,
  },
  data: function () {
    return {
      response: null,
      tagForEdit: null,
      editTagTrigger: 0,
      allowAdd: false,
      allowEditTag: false,
      allowEditClassification: false,
      allowEditCategory: false,
      showRating: false,
      showClassification: false,
      showCategory: false,
      usesNotes: false,
      isLoading: false,
      //   utils: utils,
      definition: null,
      search: "",
      selectedTags: [],
      tag_gradings: [],
      editMode: false,
      dragItem: null,
    };
  },
  watch: {
    editMode(val) {
      if (val && this.$refs.editContainer) {
        this.$refs.editContainer.focus();
      }
    },
  },
  computed: {
    ...mapState({
      tagTypes: (state) => state.hierarchies.tagTypes,
      tagClassififcations: (state) => state.hierarchies.tagClassififcations,
      tagCategories: (state) => state.hierarchies.tagCategories,
      tagGradings: (state) => state.hierarchies.tagGradings,
      tagPermissions: (state) => state.hierarchies.tagPermissions,
    }),
  },
  created() {
    //   this.fetchData();
    let def = JSON.parse(JSON.stringify(this.tagTypes.find(
      (x) => x.tag_type_id === this.attribute.tag_type_id
    )));
	def.values = def.values.filter(x => x.active);
	def.ratings = def.ratings.filter(x => x.active);
	this.definition = def;
    this.selectedTags = JSON.parse(JSON.stringify(this.attribute.tags));
    this.tag_gradings = JSON.parse(JSON.stringify(this.attribute.tag_gradings));
    this.allowAdd = this.tagPermissions.addTag;
    this.allowEditTag = this.tagPermissions.editTag;
    this.allowEditClassification = this.tagPermissions.editClassification;
    this.allowEditCategory = this.tagPermissions.editCategory;
    this.showClassification = this.$loginState.user.settings.some(
      (s) => s.setting === "tagging_classification_used" && s.value !== "false"
    );
    this.showCategory = this.$loginState.user.settings.some(
      (s) => s.setting === "tagging_categorisation_used" && s.value !== "false"
    );
    this.showRating =
      this.definition.maxRating > 0 &&
      this.$loginState.user.settings.some(
        (s) => s.setting === "tagging_rating_used" && s.value !== "false"
      );
    this.usesNotes = this.$loginState.user.settings.some(
      (s) => s.setting === "tagging_notes_used" && s.value !== "false"
    );
  },
  methods: {
    editTag(tag) {
      this.tagForEdit = tag;
      this.editTagTrigger++;
    },
    cancelEditTag() {
      this.tagForEdit = null;
    },
    tagSaved(updated) {
      let tag = this.selectedTags.find(
        (x) => x.tag_value_id === updated.tag_value_id
      );
      if (tag) {
        tag.value = updated.value;
        this.tag_gradings.some((g) =>
          g.tags.some((t) => {
            if (t.tag_value_id === updated.tag_value_id) {
              t.value = updated.value;
              return true;
            } else {
              return false;
            }
          })
        );
      }
      this.$refs.editContainer.focus();
      this.save(updated);
      this.tagForEdit = null;
    },
    tagListSearch(item, queryText) {
      const text = `${item.value} ${item.tag_classififcation_name || ""} ${
        item.tag_category_name || ""
      }`.toLowerCase();
      let search = (queryText || "")
        .toLowerCase()
        .replace(/,/g, " ")
        .split(" ")
        .filter((x) => x.length)
        .map((x) => x);
      return (
        !queryText ||
        queryText.length === 0 ||
        search.every((s) => text.indexOf(s) >= 0)
      );
    },
    tagListText(item) {
      return (
        item.value +
        (this.showClassification && item.tag_classififcation_name
          ? " / " + item.tag_classififcation_name
          : "") +
        (this.showCategory && item.tag_category_name
          ? " / " + item.tag_category_name
          : "")
      );
    },
    updateRating(item, rating) {
      item.tag_type_rating_id = rating?.tag_type_rating_id;
      item.rating = rating?.name;
      item.rating_number = rating?.sequence;
      this.save();
    },
    checkNew(grading) {
      let newTag = this.selectedTags.find((t) => t.tag_value_id === undefined);
      if (!this.allowAdd && newTag) {
        this.selectedTags = this.selectedTags.filter((t) => t.tag_value_id);
        this.search = "";
        return;
      }
      let addedTag = this.selectedTags.find(
        (x) => !this.attribute.tags.some((o) => o.tag_value_id === x.tag_value_id)
      );
      addedTag.tag_grading_type_value_id = grading.tag_grading_type_value_id;
      this.selectedTags = this.selectedTags.map((t) => {
        if (t.tag_value_id !== undefined) {
          return t;
        } else {
          let val = t.toLowerCase();
          let existing = this.definition.values.find(
            (cv) => cv.value.toLowerCase() === val
          );
          let newVal = {
            tag_value_id: existing
              ? existing.tag_value_id
              : (this.definition.values.filter((x) => x.tag_value_id < 0)
                  .length +
                  1) *
                -1,
            value: existing ? existing.value : t,
            tag_type_id: this.attribute.tag_type_id,
          };
          if (!existing) this.definition.values.push(newVal);
          return newVal;
        }
      });
      this.tag_gradings
        .find(
          (g) =>
            g.tag_grading_type_value_id === grading.tag_grading_type_value_id
        )
        .tags.push(addedTag);
      this.save();
    },
    keyDown(event) {
      if (event.key === "Escape") {
        this.$emit("cancel");
        this.editMode = false;
      }
    },
    lostFocus(event) {
      if (
        !this.tagForEdit &&
        event.relatedTarget &&
        !this.$refs.editContainer.contains(event.relatedTarget) &&
        (!this.$refs.menuContainer?.length ||
          !this.$refs.menuContainer.some((x) =>
            x.$el.contains(event.relatedTarget)
          ))
      ) {
        this.editMode = false;
      }
    },
    removeAll(grading) {
      grading.tags.forEach((t) => {
        const ind = this.selectedTags.findIndex(
          (x) => x.tag_value_id === t.tag_value_id
        );
        this.selectedTags.splice(ind, 1);
      });
      grading.tags.splice(0, grading.tags.length);
      this.save();
    },
    removeItem(tag, grading) {
      this.selectedTags = this.selectedTags.filter(
        (x) => x.tag_value_id !== tag.tag_value_id
      );
      grading.tags = grading.tags.filter(
        (x) => x.tag_value_id !== tag.tag_value_id
      );
      this.save();
    },
    save(updatedTag) {
      //compare with original
      let currentTags = [];
      this.tag_gradings.forEach((g) => {
        g.tags.forEach((t, ti) => {
          let ret = {
            tag_type_id: this.attribute.tag_type_id,
            dpa_id: this.attribute.dpa_id,
            dpa_tag_id: t.dpa_tag_id,
            tag_value_id: t.tag_value_id,
            tag_type_rating_id: t.tag_type_rating_id || null,
            tag_grading_type_value_id: t.tag_grading_type_value_id || null,
            notes: t.notes || null,
            value: t.value,
            sequence: ti + 1,
            change: "",
          };
          let original = this.attribute.tags.find(
            (x) => x.tag_value_id === t.tag_value_id
          );
          if (!original) ret.change = "added";
          else if (
            ret.sequence !== original.sequence ||
            ret.tag_type_rating_id !== original.tag_type_rating_id ||
            ret.tag_grading_type_value_id !== original.tag_grading_type_value_id
          ) {
            ret.change = "updated";
            ret.dpa_tag_id = original.dpa_tag_id;
            ret.notes = original.notes;
          } else if (
            updatedTag?.tag_value_id === t.tag_value_id &&
            updatedTag?.value !== t.value
          ) {
            ret.change = "updated";
            ret.value = updatedTag.value;
          }
          currentTags.push(ret);
        });
      });
      let data = {
        tpa_id: this.attribute.tpa_id,
        dpa_id: this.attribute.dpa_id,
        deletedTags: this.attribute.tags.filter(
          (o) =>
            !this.selectedTags.some((c) => o.tag_value_id === c.tag_value_id)
        ),
        currentTags: currentTags,
      };
      this.search = "";
      this.$emit("changed", data);
      this.$refs.editContainer.focus();
    },
    // setDragCursor: function (value) {
    //   const html = document.getElementsByTagName("html").item(0);
    //   html.classList.toggle("grabbing", value);
    // },
    dragStart: function (event, item) {
      //   this.setDragCursor(true);
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.effectAllowed = "move";
      this.dragItem = item;
    },
    dragEnd: function () {
      //   this.setDragCursor(false);
    },
    allowDrop: function (event) {
      if (!this.dragItem || this.dragItem.tpa_id !== this.attribute.tpa_id)
        event.preventDefault();
    },
    moveTag: function (event, targetTag, newGrading) {
      if (!newGrading || !this.dragItem) {
        event.preventDefault();
      } else {
        const prevGrading = this.tag_gradings.find(
          (g) =>
            g.tag_grading_type_value_id ===
            this.dragItem.tag_grading_type_value_id
        );
        const targetIndex = targetTag
          ? newGrading.tags.findIndex(
              (x) => x.tag_value_id === targetTag.tag_value_id
            )
          : prevGrading.tag_grading_type_value_id ===
            newGrading.tag_grading_type_value_id
          ? newGrading.tags.length - 1
          : newGrading.tags.length;
        const oldIndex = prevGrading.tags.findIndex(
          (x) => x.tag_value_id === this.dragItem.tag_value_id
        );
        if (
          oldIndex !== targetIndex ||
          prevGrading.tag_grading_type_value_id !==
            newGrading.tag_grading_type_value_id
        ) {
          this.dragItem.tag_grading_type_value_id =
            newGrading.tag_grading_type_value_id;
          prevGrading.tags.splice(oldIndex, 1);
          newGrading.tags.splice(targetIndex, 0, this.dragItem);
          this.save();
        }
        event.stopPropagation();
        this.dragItem = null;
      }
    },
  },
};
</script>
  <style scoped>
.v-list-item__action {
  margin: 3px 0;
}
.v-label {
  font-size: 0.9rem;
}
div.cellClick {
  width: 100%;
  cursor: pointer;
}
.placeholder {
  color: #ed8a8a;
  cursor: pointer;
  font-style: italic;
  font-weight: 300 !important;
}
.placeholder-empty {
  color: transparent;
  cursor: pointer;
}
.drag-item {
  cursor: grab;
  /* border: solid white 2px;
  border-radius: 5px;
  margin: -2px; */
}
.drag-item:hover {
  background-color: gray;
  color: white;
}
.grabbing * {
  cursor: grabbing;
}
</style>
  