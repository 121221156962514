<template>
  <v-container fluid class="px-6">
    <h4>
      Hierarchy values not copied to the new document when an existing document
      is copied:
    </h4>
    <v-container v-if="exclusions">
      <v-divider class="mb-2" />
      <v-row class="mb-1">
        <v-col cols="2"><h4>Type</h4></v-col>
        <v-col cols="5"><h4>Hierarchy Value</h4></v-col>
        <v-col cols="2"><h4>Apply to Copy</h4></v-col>
        <v-col cols="2"><h4>Apply to Link Copy</h4></v-col>
        <v-col cols="1"
          ><v-btn color="primary" outlined @click="addExclusion"
            >Add New</v-btn
          ></v-col
        >
      </v-row>
      <v-divider class="mb-2" />
      <v-row
        v-for="exc in exclusions"
        :key="'e' + exc.template_hierarchy_copy_exclusion_id"
        @click="editExclusion(exc)"
        style="cursor: pointer"
      >
        <v-col cols="2">{{ exc.ht_name }}</v-col>
        <v-col cols="5">{{ exc.hierarchy_text }}</v-col>
        <v-col cols="2">{{ exc.apply_to_copy ? "Yes" : "-" }}</v-col>
        <v-col cols="2">{{ exc.apply_to_link_copy ? "Yes" : "-" }}</v-col>
        <v-col cols="1"
          ><v-btn small outlined @click="editExclusion(exc)">Edit</v-btn></v-col
        >
      </v-row>
      <v-divider v-if="exclusions.length" />
    </v-container>

    <v-dialog v-model="showEdit" max-width="800px">
      <v-card v-if="exclusion">
        <v-card-title>
          {{ exclusion.template_hierarchy_copy_exclusion_id ? "Edit" : "New" }}
          Exclusion
          <v-spacer />
          <v-btn icon @click="showEdit = false"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="2">
              <v-select
                label="Hierarchy Type"
                :items="hierarchyTypes"
                item-text="ht_name"
                item-value="ht_id"
                v-model="exclusion.ht_id"
                :error="!exclusion.ht_id"
                @change="pickHT"
                :disabled="!!exclusion.template_hierarchy_copy_exclusion_id"
              ></v-select>
            </v-col>
            <v-col cols="10">
              <v-radio-group v-model="exclusion.specificHierarchy" row :disabled="!!exclusion.template_hierarchy_copy_exclusion_id">
                <v-radio label="Any Value" value="0"></v-radio>
                <v-radio label="Specific Value" value="1"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="2"> </v-col>
            <v-col cols="10">
              <v-autocomplete
                v-if="exclusion.specificHierarchy === '1'"
                label="Hierarchy Value"
                :items="currentHTValues"
                item-text="hierarchy_text"
                item-value="value"
                v-model="exclusion.hr_id"
                :error="!exclusion.hr_id"
                :disabled="
                  !!exclusion.template_hierarchy_copy_exclusion_id ||
                  !exclusion.ht_id
                "
              ></v-autocomplete>
            </v-col>
            <v-col cols="2"> </v-col>
            <v-col cols="4">
              <v-switch
                v-model="exclusion.apply_to_copy"
                inset
                label="Apply to Copy"
              ></v-switch>
            </v-col>
            <v-col cols="4">
              <v-switch
                v-model="exclusion.apply_to_link_copy"
                inset
                label="Apply to Link Copy"
              ></v-switch>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions v-if="exclusion" class="d-flex justify-end pr-6 pb-4">
          <v-btn
            v-if="exclusion.template_hierarchy_copy_exclusion_id"
            color="primary"
            @click="deleteExclusion"
            >Delete</v-btn
          ><v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="saveExclusion"
            :disabled="!saveExclusionEnabled"
            >Save</v-btn
          >
          <v-btn color="primary" @click="showEdit = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Loading :isVisible="isLoading" />
    <ResponseHandler :serviceResponse="response"></ResponseHandler>
  </v-container>
</template>

<script>
import axios from "axios";
import ResponseHandler from "@/components/ResponseHandler";
import { mapState } from "vuex";

export default {
  name: "cTemplateBuilderHierarchyCopy",
  components: {
    ResponseHandler,
  },
  props: {
    value: { type: Number, required: false },
  },
  data: function () {
    return {
      isLoading: false,
      response: null,
      exclusions: [],
      hierarchyTypes: [],
      exclusion: null,
      showEdit: false,
      currentHTValues: [],
    };
  },
  mounted() {},
  created() {
    if (!this.hierarchiesLoading) this.init();
  },
  watch: {
    value() {
      this.init();
    },
  },
  computed: {
    ...mapState({
      hierarchiesLoading: (state) => state.hierarchies.loading,
    }),
    saveExclusionEnabled() {
      if (!this.exclusion) return false;

      if (!this.exclusion.hr_id && this.exclusion.specificHierarchy === "1")
        return false;

      if (!this.exclusion.apply_to_link_copy && !this.exclusion.apply_to_copy)
        return false;

      return true;
    },
  },
  methods: {
    sessionExpired(err) {
      this.$emit("sessionExpired", err);
    },
    init() {
      let possibleError = false;
      this.isLoading = true;
      if (
        !this.$store.state.hierarchies.loading &&
        this.$store.state.hierarchies.hierarchies.length
      ) {
        this.hierarchyTypes = JSON.parse(
          JSON.stringify(this.$store.state.hierarchies.hierarchies)
        );
      }
      axios
        .get("templateBuilder/templateHierarchyCopyExclusions/" + this.value)
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            const data = resp.data.Data;

            data.forEach((d) => {
              this.decorateExclusion(d);
            });

            this.exclusions = data;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
    decorateExclusion(exc) {
      this.hierarchyTypes.some((x) => {
        if (exc.hr_id) {
          let hr = x.values.find((v) => v.value === exc.hr_id);
          if (hr) {
            exc.ht_id = x.ht_id;
            exc.ht_name = x.ht_name;
            exc.hierarchy_text = hr.hierarchy_text;
            exc.specificHierarchy = "1";
            return true;
          }
        } else {
          if (x.ht_id === exc.ht_id) {
            exc.ht_name = x.ht_name;
            exc.specificHierarchy = "0";
            exc.hierarchy_text = "ANY VALUE";
            return true;
          }
        }
      });
    },
    pickHT() {
      let ht = this.hierarchyTypes.find(
        (x) => x.ht_id === this.exclusion.ht_id
      );
      this.currentHTValues = ht ? ht.values : [];
    },
    editExclusion(exc) {
      this.exclusion = exc;
      this.showEdit = true;
      this.pickHT();
    },
    addExclusion() {
      this.exclusion = {
        template_hierarchy_copy_exclusion_id: null,
        hr_id: null,
        ht_id: null,
        ht_name: "",
        hierarchy_text: "",
        apply_to_link_copy: false,
        apply_to_copy: false,
        tmpl_id: this.value,
        specificHierarchy: "0",
      };
      this.showEdit = true;
    },
    saveExclusion() {
      if (!this.saveExclusionEnabled) return;
      if (!this.exclusion) return;
      if (!this.exclusion.specificHierarchy) this.exclusion.hr_id = null;
      let possibleError = false;
      let isNew = !this.exclusion.template_hierarchy_copy_exclusion_id;
      this.isLoading = true;
      axios
        .post(
          "templateBuilder/saveTemplateHierarchyCopyExclusion",
          this.exclusion
        )
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            this.decorateExclusion(this.exclusion);
            if (isNew) {
              this.exclusion.template_hierarchy_copy_exclusion_id =
                resp.data.Data.template_hierarchy_copy_exclusion_id;
              this.exclusions.push(this.exclusion);
            }
            this.showEdit = false;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
    deleteExclusion() {
      if (confirm(`Remove Exclusion?`)) {
        const request = {
          template_hierarchy_copy_exclusion_id:
            this.exclusion.template_hierarchy_copy_exclusion_id,
        };
        let possibleError = false;
        this.isLoading = true;
        axios
          .post("templateBuilder/deleteTemplateHierarchyCopyExclusion", request)
          .then(() => {
            possibleError = true;
            const idx = this.exclusions.findIndex(
              (x) =>
                x.template_hierarchy_copy_exclusion_id ===
                request.template_hierarchy_copy_exclusion_id
            );
            if (idx >= 0) this.exclusions.splice(idx, 1);
            this.isLoading = false;
            this.showEdit = false;
          })
          .catch((err) => {
            this.isLoading = false;
            if (possibleError) {
              alert("Code Error");
            } else if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              alert(err.response ? err.response.data.message : err);
            }
            console.log(err);
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";

.draghandle {
  cursor: move;
}

.exclusions {
  max-width: 800px;
  margin: 0 auto;
}
</style>