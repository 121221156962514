<template>
  <div>
    <v-container
      style="max-width: 100% !important;"
      class="no-print"
    >
      <v-row dense>
        <v-col :cols="showHierarchy ? '3' :'1'" :class="{ narrowIcons : !showHierarchy }">
          <div>
            <HierarchySearch
              v-show="showHierarchy"
              :items="filteredItems"
              :hierarchyTypes="hierarchyTypes"
              @filterChanged="doHierarchyFilter"
            ></HierarchySearch>
            <div
              class="mt-4"
              v-show="showHierarchy"
              @click="showHierarchy = !showHierarchy"
              style="font-size:14px; cursor:pointer; color:blue"
            >Hide Hierarchy</div>
            <v-icon
              v-show="!showHierarchy"
              @click="showHierarchy = !showHierarchy"
              style="cursor:pointer;"
              title="Show Hierarchy"
            >mdi-page-layout-sidebar-left</v-icon>
          </div>
        </v-col>
        <v-col :cols="showHierarchy ? '9' :'11'">
          <v-row dense>
            <v-col cols="1" style="max-width: 90px">
              <div
                class="viewType"
                style="max-width: 60px; margin: 0; padding: 0"
              >
                <v-icon
                  @click="changeView('cards')"
                  :class="
                    viewSelect === 'cards' ? 'iconSelected' : 'iconPassive'
                  "
                  >mdi-view-grid</v-icon
                >
                <v-icon
                  @click="changeView('table')"
                  :class="
                    viewSelect === 'table' ? 'iconSelected' : 'iconPassive'
                  "
                  style="z-index: 100"
                  >mdi-view-list</v-icon
                >
              </div>
              <v-select
                v-if="viewSelect === 'cards'"
                style="font-size: 10px; margin-top: -18px"
                :items="cardSelect"
                item-text="text"
                item-value="value"
                v-model="maxCards"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :value="filterText"
                dense
                flat
                clearable
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="type text and enter to filter jobs..."
                class="hidden-sm-and-down docFilter"
                @input="changefilterText"
                @keypress.enter="triggerFilter"
                @click:clear="clearFilter"
              />
            </v-col>
            <v-col cols="3">
              <v-select
                v-model="docTypeIncluded"
                :items="docTypeSummary"
                item-text="docType"
                item-value="docType"
                return-object
                label="Document Types Included"
                multiple
                v-if="docTypeSummary.length > 1"
                :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
                dense
                @change="doFilter()"
              ></v-select>
            </v-col>
            <v-col cols="3" class="statusSelect">
              <v-select
                v-model="docStatusIncluded"
                :items="docStatusSummary"
                item-text="status"
                item-value="status"
                return-object
                label="Job Statuses Included"
                multiple
                :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
                dense
                @change="doFilter()"
              >
                <template v-slot:selection="{ item, index }">
                  <span>{{ getStatusFilterText(item, index) }}</span>
                </template>
              </v-select>
            </v-col>

            <v-col cols="1" style="max-width: 90px;display:flex;padding-bottom: 40px;padding-left: 14px;">
              
            <v-toolbar-items class="hidden-sm-and-down" v-if="useTranslation">
                <v-menu style="height:150px; overflow-y:scroll; top:100px" offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn dark icon v-on="on" style="margin-right:20px">

                        <country-flag v-if="selectedLanguageFilter.text != 'All' && selectedLanguageFilter.text !=='English'" :country='selectedLanguageFilter.flagCode' size='normal'/>
                        <div  v-if="selectedLanguageFilter.text == 'All'"  >
                          <img src="../assets/world.png" width="25px">
                        </div>
                      <div  v-if="selectedLanguageFilter.text == 'English'"  >
                          <img src="../assets/Anglosphere.png" width="28px" height="20px">
                        </div>                        
                    </v-btn>
                  </template>
                  <v-card  style="height:550px; overflow-y:scroll" >
                    <v-list dense>
                      <v-subheader>Language Filter</v-subheader>
                      <v-divider></v-divider>

                      <v-list-item
                        v-for="(language, index)  in translateOptions"
                        :key="index"
                        @click="selectLanguageFilter(language)"
                      >
                        <v-list-item-icon>
                          <country-flag v-if="language.text !=='All' && language.text !=='English'"  :country='language.flagCode' size='normal'/>
                          <img v-if="language.text=='All'" style="margin-right: 5px;" src="../assets/world.png" width="25px">
                          <img v-if="language.text=='English'" style="margin-right: 0px;" src="../assets/Anglosphere.png" width="28px" height="20px">
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title><template v-text="language.text"></template></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>


                    </v-list>
                  </v-card>
                </v-menu>
              </v-toolbar-items>

              <v-icon
                @click="exportToCSV"
                class="float-right exportDocs"
                color="green darken-2"
                title="Download List"
                >mdi-cloud-download</v-icon
              >
            </v-col>
          </v-row>
          <div v-if="items.length" class="no-print docList">
            <div
              v-if="viewSelect === 'cards'"
              style="margin-top: -35px; margin-left: -1px"
            >
              <v-row dense wrap>
                <v-col
                  v-for="j in filteredItems"
                  :key="'j' + j.system_number"
                  cols="3"
                  style="padding: 5px"
                >
                  <v-card
                    style="padding-top: 8px"
                    @click="openDocument(j)"
                    v-if="j.index <= maxCards"
                  >
                    <v-row dense>
                      <v-col>
                        <v-list dense>
                          <v-list-item
                            class="data-content"
                            style="padding-right: 0; padding-top: 5px"
                          >
                            <PartTypeIcon
                              :partType="j.doc_type_abbrev"
                              :file_info="j.file_data"
                            ></PartTypeIcon>
                            <v-list-item-title>
                              <v-list-item-subtitle
                                style="
                                  padding-left: 5px;
                                  font-size: 15px;
                                  display: inline-flex;
                                  padding-top: 13px;
                                "
                              ><div v-html="j.system_number"></div></v-list-item-subtitle>
                              <country-flag v-if="useTranslation" style="margin-left: 0px;" :country='getFlagForLang(j.language_code)' class="customFlag" size='small'/>
                              <v-chip
                               v-if="j.doc_type_abbrev != 'Ef'"
                                dense
                                class="ma-2 status-chip"
                                :color="
                                  j.doc_status === 'APPROVED'
                                    ? 'green'
                                    : j.doc_status === 'DRAFT'
                                    ? 'red'
                                    : 'orange'
                                "
                                label
                                outlined
                                style="
                                  display: inline-flex;
                                  float: right;
                                  margin-right: 0;
                                "
                                >{{ j.doc_status_text }}</v-chip
                              >
                              <v-chip
                               v-if="j.doc_type_abbrev == 'Ef'"
                                dense
                                class="ma-2 status-chip"
                                label
                                outlined
                                :color="resolveHeaderStyle(j.file_data.file_extension)"
                                style="
                                  display: inline-flex;
                                  float: right;
                                  margin-right: 0;
                                "
                                >{{j.file_data.file_extension.toUpperCase()}}</v-chip
                              >                              
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                style="font-size: 15px; height: 18px"
                              ><div v-html="j.doc_name"></div></v-list-item-title>
                              <div
                                style="
                                  font-size: 12px;
                                  font-style: italic;
                                  font-weight: 300;
                                  padding-bottom: 8px;
                                "
                                v-html="j.advert_job_title"
                              ></div>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item
                            style="
                              margin-top: -8px;
                              height: 145px;
                              overflow: auto;
                              display: flow-root;
                            "
                            class="data-value-2"
                          ><div v-html="
                              j.description
                                .replaceAll('<br>', '.')
                                .replaceAll(
                                  'font-size:18px',
                                  'font-size:13px'
                                ) || 'No job description.'
                            "></div></v-list-item>
                          <div class="data-content" style="margin-top: 2px">
                            <span class="data-label">{{ cvLabel2 }}:</span>
                            <span
                              v-html="j.listview2 || '-'"
                              class="data-value-2"
                            ></span>
                          </div>
                          <div class="data-content">
                            <span class="data-label">{{ cvLabel3 }}:</span>
                            <span
                              v-html="j.listview3.substring(0, 42) || '-'"
                              class="data-value-2"
                            ></span>
                          </div>
                          <div class="data-content">
                            <span class="data-label">Recruiter:</span>
                            <span
                              v-html="j.recruiter || '-'"
                              class="data-value-2"
                            ></span>
                          </div>
                          <div class="data-content">
                            <span class="data-label">Created:</span>
                            <span
                              v-html="j.created_by + ' - '"
                              class="data-value-2"
                            ></span>
                            <span class="data-value-2">{{
                              j.job_created_at | dateDoMMMYY
                            }}</span>
                          </div>
                        </v-list>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </div>
            <v-data-table
              :headers="dtHeaders"
              dense
              :items="filteredItems"
              sort-by="system_number"
              sort-desc
              class="elevation-1"
              v-if="viewSelect === 'table'"
              :footer-props="{ 'items-per-page-options': [10, 20, 30, 50, -1] }"
              :items-per-page="dtItemsPerPage"
              @click:row="openDocument"
              @update:options="dtOptionChange"
            >
              <template v-slot:[`item.system_number`]="{ item }">
                <PartTypeIcon
                  v-if="docTypeSummary.length > 1"
                  :partType="item.doc_type_abbrev"
                  :file_info="item.file_data"
                ></PartTypeIcon>
                <span
                  v-html="item.system_number"
                  style="font-size: 0.8rem"
                ></span>
                 <country-flag v-if="useTranslation"  style="margin-left: 0px;" :country='getFlagForLang(item.language_code)' class="customFlag" size='small'/>
              </template>
              <template v-slot:[`item.doc_name`]="{ item }">
                <span v-html="item.doc_name" style="font-size: 0.8rem"></span>
              </template>
              <template v-slot:[`item.doc_status_text`]="{ item }">
                <span
                  v-html="item.doc_status_text"
                  style="font-size: 0.7rem"
                ></span>
              </template>
              <template v-slot:[`item.listview2`]="{ item }">
                <span v-html="item.listview2" style="font-size: 0.7rem"></span>
              </template>
              <template v-slot:[`item.listview3`]="{ item }">
                <span v-html="item.listview3" style="font-size: 0.7rem"></span>
              </template>
              <template v-slot:[`item.recruiter`]="{ item }">
                <span v-html="item.recruiter" style="font-size: 0.6rem"></span>
              </template>
              <template v-slot:[`item.job_updated_at`]="{ item }">
                <span
                  v-html="item.job_updated_at"
                  style="font-size: 0.6rem"
                ></span>
              </template>
              <template v-slot:[`item.job_created_at`]="{ item }">
                <span
                  v-html="item.job_created_at"
                  style="font-size: 0.6rem"
                ></span>
              </template>
              <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">Reset</v-btn>
              </template>
            </v-data-table>
          </div>
        </v-col>
      </v-row>

      <ResponseHandler :serviceResponse="response"></ResponseHandler>

      <Loading :isVisible="isLoading" />

    <v-dialog v-model="languageDialog" max-width="1500px" min-height="700px">
      <v-card>
        <v-card-title class="justify-center">
          <div class="justify-center">Language Options:</div>
        </v-card-title>
        <v-card-text class="justify-center">  

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeLanguageDialog">Close</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    </v-container>
  </div>
</template>

<script>
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
import HierarchySearch from "@/components/cHierarchySearch"; // @ is an alias to /src
import PartTypeIcon from "@/components/cPartTypeIcon"; // @ is an alias to /src
import utils from "@/common/utils.js";
import moment from "moment";
import CountryFlag from 'vue-country-flag'
import { mapState } from 'vuex';

export default {
  name: "checkJP",
  components: {
    ResponseHandler,
    HierarchySearch,
    PartTypeIcon,
    CountryFlag
  },
  props: {},
  data: function () {
    return {
      response: null,
      languageDialog: false,
      items: [],
      isDirty: false,
      dirtyCount: 0,
      docStatusIncluded: [],
      docTypeIncluded: [],
      //   itemStatusSummary: [],
      itemStatusIncluded: [],
      hierarchyFilters: [],
      tempFilterText: "",
      filterText: "",
      selDoc: "",
      viewSelect: "",
      dtItemsPerPage: 20,
      dtHeaders: [
        {
          text: "System Number",
          align: "start",
          value: "system_number",
        },
        { text: "Job Title", value: "doc_name" },
        { text: "Status", value: "doc_status_text" },
        { text: "Location", value: "listview2" },
        { text: "Cost Center", value: "listview3" },
        { text: "Recruiter", value: "recruiter" },
        { text: "Updated", value: "job_updated_at" },
        {
          text: "Created",
          value: "job_created_at",
          sort: (a, b) =>
            moment(a, "D MMM YYYY").isAfter(moment(b, "D MMM YYYY"))
              ? 1
              : moment(a, "D MMM YYYY").isBefore(moment(b, "D MMM YYYY"))
              ? -1
              : 0,
        },
      ],
      selectedLanguageFilter:{text:"All",value:"-",flagCode:""},
      translateOptions:[
        {text:"All",value:"-",flagCode:""},
        {text:"Afrikaans",value:"af",flagCode:"na"},
        {text:"Albanian",value:"sq",flagCode:"al"},
        {text:"Arabic",value:"ar",flagCode:"eg"},
        {text:"Chinese (Simplified)",value:"zh",flagCode:"cn"},
        {text:"Chinese (Traditional)",value:"zh-TW",flagCode:"cn"},
        {text:"Croatian",value:"hr",flagCode:"hr"},
        {text:"Czech",value:"cs",flagCode:"cz"},
        {text:"Danish",value:"da",flagCode:"dk"},
        {text:"Dutch",value:"nl",flagCode:"nl"},
        {text:"English",value:"en",flagCode:""},
        {text:"French",value:"fr",flagCode:"fr"},
        {text:"German",value:"de",flagCode:"de"},
        {text:"Greek",value:"el",flagCode:"gr"},
        {text:"Italian",value:"it",flagCode:"it"},
        {text:"Japanese",value:"ja",flagCode:"jp"},
        {text:"Korean",value:"ko",flagCode:"kr"},
        {text:"Polish",value:"pl",flagCode:"pl"},
        {text:"Portuguese",value:"pt",flagCode:"pt"},
        {text:"Romanian",value:"ro",flagCode:"ro"},
        {text:"Russian",value:"ru",flagCode:"ru"},
        {text:"Spanish",value:"es",flagCode:"es"},
        {text:"Spanish (Mexico)",value:"es-MX",flagCode:"mx"},
        {text:"Swedish",value:"sv",flagCode:"se"},
        {text:"Thai",value:"th",flagCode:"th"},
        {text:"Vietnamese",value:"vi",flagCode:"vn"},
        {text:"Welsh",value:"cy",flagCode:"gb-wls"},

      ],      
      csvHeaders: [],
      cvLabel1: "Job Title",
      cvLabel2: "Location",
      cvLabel3: "Cost Center",
      dialog: false,
      options: { itemsPerPage: 15 },
      genPDF: false,
      ignoreTVInputEvent: false,
      defaultStatusFilterList: "approved,pre-draft,pending,draft",
      filteredItems:[],
      maxCards: 24,
      cardSelect: [
        // {text: '16 cards', value: 16},
        { text: "24 cards", value: 24 },
        { text: "32 cards", value: 32 },
        { text: "64 cards", value: 64 },
        //{ text: "128 cards", value: 128 },
        // { text: "256 cards", value: 256 },
        // { text: "512 cards", value: 512 },
        // { text: "All cards", value: 10000 },
      ],
      showHierarchy: true,
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    isLoading(val) {
      if (!val) this.fetchData();
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    ...mapState({
      isLoading: (state) => state.docs.loading,
      cleanItems: (state) => state.docs.docsList,
      hierarchyTypes: (state) => state.docs.hierarchyTypes,
      docStatusSummary: (state) => state.docs.docStatusSummary,
      docTypeSummary: (state) => state.docs.docTypeSummary,
      useTranslation: (state) => state.docs.useTranslation,
    }),
  },
  created() {
    this.fetchData();
    this.viewSelect = localStorage.getItem("dlview") || "cards";
    this.dtItemsPerPage = Number(localStorage.getItem("dtRPP")) || 20;
    this.$emit("documentChangedHandler", this.documentChanged);
  },
  methods: {
    LanguageDialog(){
      this.languageDialog = true;
    },
    closeLanguageDialog(){
      this.languageDialog = false;
    },    
    selectLanguageFilter(lang){
      this.selectedLanguageFilter = lang;
      var filteredItems = [];
      if(this.selectedLanguageFilter.text === "All"){
        filteredItems = this.items;
      } else {
        this.items.forEach( item => {
          if(item.language_code == this.selectedLanguageFilter.value){
            filteredItems.push(item);
          }
        })
      }

      this.filteredItems = [...new Set(filteredItems)];
      this.filteredItems = JSON.parse(JSON.stringify(this.filteredItems));
    },
    getFlagCode(item){
      return item.item.flagCode;
    },
    getFlagForLang(item){
      let option = this.translateOptions.find(x => x.value == item);
      return option.flagCode;
    },
      resolveHeaderStyle(extension){

          switch(extension){
              case "xlsx":
              case "xls":
                  return "#3cc542ad";
              case "doc":
              case "docx":
                  return "#327bb5c7";
              case "txt":
              case "log":
                  return "#ff23138f";
              case "pdf":
                  return "#ff23138f";
          }

      },    
    documentChanged(doc) {
      this.$store.dispatch("docs/updateDoc", doc);
      this.doFilter();
    },
    fetchData() {
      if (this.isLoading)
        return;

      this.hierarchyTypes.forEach((ht) => {
        let hdr = this.dtHeaders.find(
          (hd) => hd.value === "listview" + ht.position
        );
        if (hdr) hdr.text = ht.label;
        if (this["cvLabel" + ht.position])
          this["cvLabel" + ht.position] = ht.label;
      });

      if (this.$loginState.readOnly) {
        this.docStatusIncluded.push(
          this.docStatusSummary.find(
            (s) => s.status.toLowerCase() === "approved"
          ) || this.docStatusSummary[0]
        );
      } else {
        let defaultStatuses = utils.getDefaultStatuses(this.$loginState);
        var checkDefaultStatusesExist = false;
        defaultStatuses.forEach((ds) => {
          if (
            this.cleanItems.find(
              (itm) => itm.doc_status.toLowerCase() === ds.status.toLowerCase()
            )
          ) {
            checkDefaultStatusesExist = true;
          }
        });
        if (defaultStatuses.length && checkDefaultStatusesExist) {
          this.docStatusIncluded = defaultStatuses;
        } else {
          this.docStatusIncluded = this.docStatusSummary.filter(
            (s) =>
              this.defaultStatusFilterList.indexOf(s.status.toLowerCase()) >= 0
          );
        }
      }

      let docFound = false;
      if (this.$passedParams.docTypes.length) {
        // passed default docType
        this.$passedParams.docTypes.forEach((dt) => {
          let dtFound = this.docTypeSummary.find((dst) => dst.docType === dt);
          if (dtFound) {
            this.docTypeIncluded.push(dtFound);
            docFound = true;
          }
        });
      }
      if (!docFound) {
        this.docTypeIncluded = this.$loginState.getSetting("dwpDocTypes", []);
        if (!Array.isArray(this.docTypeIncluded)) this.docTypeIncluded = [];
        if (this.docTypeIncluded.length === 0)
          this.docTypeIncluded.push(this.docTypeSummary[0]);
      }
      this.doHierarchyFilter();
    },
    getStatusFilterText(item, index) {
      if (item && index > 0) return "";
      if (this.docStatusIncluded.length === this.docStatusSummary.length) {
        return this.docStatusSummary.length > 0 ? "All" : "None";
      } else {
        let defList = this.defaultStatusFilterList.split(",");
        if (
          defList.every((s) =>
            this.docStatusIncluded.some((si) => si.status.toLowerCase() === s)
          )
        ) {
          let text = "";
          text = "Default statuses";
          if (defList.length < this.docStatusIncluded.length) {
            text +=
              " (+ " +
              this.docStatusIncluded
                .filter(
                  (ds) => !defList.some((d) => d === ds.status.toLowerCase())
                )
                .reduce((prev, curr) => {
                  return prev + (prev ? ", " : "") + curr.status;
                }, "");
          }
          return text;
        } else {
          return this.docStatusIncluded.reduce((prev, curr) => {
            return prev + (prev ? ", " : "") + curr.status;
          }, "");
        }
      }
    },
    changefilterText(val) {
      this.tempFilterText = val;
    },
    changeView(val) {
      this.viewSelect = val;
      localStorage.setItem("dlview", val);
    },
    clearFilter() {
      this.tempFilterText = "";
      this.triggerFilter();
    },
    triggerFilter() {
      this.filterText = this.tempFilterText;
      this.doFilter();
    },
    doHierarchyFilter(filters) {
      this.hierarchyFilters = filters || [];
      this.doFilter();
    },
    doFilter() {
      utils.setDefaultStatuses(this.$loginState, this.docStatusIncluded);

      const params = {
        filterText: (this.filterText || "").trim().toLowerCase(),
        docStatusIncluded: this.docStatusIncluded,
        docTypeIncluded: this.docTypeIncluded,
        hierarchyFilters: this.hierarchyFilters
      }
      this.items = this.$store.getters["docs/filter"](params);
      
      this.filteredItems = JSON.parse(JSON.stringify(this.items ));
      
      this.selectLanguageFilter(this.selectedLanguageFilter);
      this.$loginState.saveSetting("dwpDocTypes", this.docTypeIncluded);
    },
    openDocument(item) {
      this.$emit("openDocument", {
        system_number: utils.removeTags(item.system_number),
        doc_name: utils.removeTags(item.doc_name),
        doc_type: item.doc_type,
      });
    },
    dtOptionChange(value) {
      localStorage.setItem("dtRPP", value.itemsPerPage);
      this.dtItemsPerPage = value.itemsPerPage;
    },
    exportToCSV() {
      this.csvHeaders = JSON.parse(JSON.stringify(this.dtHeaders));
      this.csvHeaders.push({
        text: "Created By",
        value: "created_by",
      });
      let data =
        this.csvHeaders.map((h) => '"' + h.text + '"').join(",") + "\n";
      this.items.forEach((d) => {
        data +=
          this.csvHeaders
            .map((h) => utils.csvEscape(utils.removeTags(d[h.value])))
            .join(",") + "\n";
      });
      utils.downloadFile(data, "Documents.csv", "text/csv;encoding:utf-8");
    },
  },
};
</script>
<style scoped>
.buttons {
  margin-top: 35px;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.dragHandle {
  color: rgba(56, 106, 163, 0.801);
  cursor: grab;
  margin-left: -40px;
  /* margin-left: 30%; */
}
.status-chip {
  font-size: 0.7rem;
  margin-left: -8px;
  height: 25px;
}
.drag-handle,
.number-part {
  max-width: 20px;
  padding: 6px 0;
}
.data-content {
  margin-top: -5px;
  padding-top: 0;
}
.data-content-2 {
  margin-top: -7px;
  min-height: auto !important;
  max-height: auto;
  padding-bottom: 0;
}
.data-label {
  font-size: 0.6rem;
  padding: 0 5px 0 16px;
  color: darkgray;
}
.data-value {
  font-size: 0.9rem;
}
.data-value-2 {
  font-size: 0.75rem;
  font-family: "Arial Narrow", "Open Sans", Calibri;
  font-style: italic;
  padding-bottom: 10px;
}
.editIcon {
  position: relative;
  left: 870px;
  bottom: 14px;
  font-size: 1.1rem;
  color: lightgrey;
}
.editIcon2 {
  position: relative;
  left: 824px;
  bottom: 18px;
  font-size: 1.1rem;
  color: lightgrey;
}
.addIcon {
  position: relative;
  left: -40px;
  bottom: 3px;
  font-size: 1.3rem;
  color: lightgrey;
}
.workflowIcon {
  position: relative;
  left: -88px;
  bottom: 74px;
  font-size: 1.7rem;
  color: lightgrey;
}
.notesIcon {
  position: relative;
  left: 840px;
  bottom: 18px;
  font-size: 1.1rem;
  color: rgba(56, 106, 163, 0.801);
}
.pdfIcon {
  position: relative;
  left: 55px;
  top: -2px;
}
.notes {
  position: relative;
  left: 70px;
  top: -5px;
}
.editIcon:hover,
.editIcon2:hover,
.addIcon:hover,
.notesIcon:hover {
  color: black !important;
}
.errorComment {
  font-style: italic;
  font-size: 0.75rem;
  position: relative;
  left: 169px;
  min-width: 150px;
}
.errorComment2 {
  font-style: italic;
  font-size: 0.75rem;
  position: relative;
  left: 122px;
  min-width: 105px;
}
.CallToAction {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 1rem;
  margin-top: -6px;
  color: rgb(73, 80, 87);
}
.AdvertJobTitle {
  display: flex;
  justify-content: center;
  font-size: 1.8rem;
  margin-bottom: 5px;
  color: rgb(73, 80, 87);
}
.AboutTeamHdr,
.JobUSPHdr,
.ReqHdr,
.RespHdr,
.FlexibleWork,
.WithUsHdr,
.ForYouHdr,
.AboutUsHdr,
.JoinUsHdr {
  font-weight: 700;
  font-size: 1.1rem;
  padding-bottom: 0;
  color: rgb(73, 80, 87);
}
.WorkingPattern {
  line-height: 18px;
}
.AdvertJobTitleGutter,
.CallToActionGutter,
.AbouttheTeamGutter,
.JobUSPGutter,
.ResponsibilityGutter,
.RequirementGutter,
.FlexibleWorkGutter,
.WorkingPatternGutter {
  color: #ce8705ba;
}
.JobUSPHdrRow,
.RespIntroRow,
.ResponsibilityRow,
.RequirementRow,
.FlexibleWorkRow,
.FlexIntroRow,
.WorkingPatternRow,
.WithUsRow,
.ForYouRow,
.AboutUsRow,
.JoinUsRow {
  margin-top: -10px;
}
.AbouttheTeamRow,
.JobUSPRow {
  margin-top: -20px;
}
.JobUSPHdrGutter,
.RespIntroGutter,
.AboutTeamHdrGutter,
.ReqHdrGutter,
.RespHdrGutter,
.FlexIntroGutter,
.WithUsHdrGutter,
.ForYouHdrGutter,
.AboutUsHdrGutter,
.JoinUsHdrGutter,
.WithUsGutter,
.JoinUsGutter,
.AboutUsGutter,
.ForYouGutter {
  display: none !important;
}
.JobUSPHdrError,
.RespIntroError,
.AboutTeamHdrError,
.ReqHdrError,
.FlexIntroError,
.WithUsHdrError,
.ForYouHdrError,
.AboutUsHdrError,
.JoinUsHdrError {
  display: none !important;
}
.headerCell {
  padding: 10px 10px 10px 0;
}
.headerCellSmall {
  padding: 0 10px;
  font-size: 0.75rem;
  color: #946206da;
}
@media print {
  .AdvertJobTitle {
    font-size: 1.5rem !important;
  }
  .CallToAction {
    margin-top: -25px;
    font-size: 0.8rem !important;
  }
  .JobUSPHdrRow,
  .RespIntroRow,
  .WithUsRow,
  .WithUsHdr,
  .FlexIntro,
  .WorkingPattern,
  .ForYouHdr,
  .ForYouRow,
  .AboutUsHdr,
  .AboutUsRow {
    margin-top: -30px;
  }
  .list-group {
    margin-top: -30px;
  }
}

.iconPassive {
  color: lightgrey !important;
}

.iconSelected {
  border-bottom: 1px solid gray;
}

</style>
<style>
div.v-treeview-node__label {
  font-size: 12px;
}
.v-treeview-node__toggle {
  max-width: 10px !important;
}
.v-treeview-node__root {
  padding: 0 !important;
  min-height: 25px !important;
}
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
  .no-print-border,
  .no-print-border * {
    border: none !important;
    box-shadow: none !important;
  }
  .printFullWidth,
  .printFullWidth * {
    max-width: 100% !important;
    flex: none;
    padding-right: 6px !important;
  }
  .v-main {
    padding-top: 0;
  }
  .mainContent {
    padding: 0 !important;
  }
}
.v-input__icon--append-outer .v-icon {
  color: grey;
  font-size: 18px;
}

.customFlag{
  transform: scale(.35) !important;
    -ms-transform: scale(.35) !important;
    -webkit-transform: scale(.35) !important;
    -moz-transform: scale(.35) !important;
}

.narrowIcons {
    max-width: 27px;
    padding-top: 7px !important;
    padding-left: 0 !important;
}
</style>
