<template>
  <div><!--
      @click="$emit('openDocumentID', item.value)"
      :title="'Click to open ' + catalogueDocType.docType"
	-->
    <div
      v-if="item.doc_id"
                    class="rowHeader"  :style="item.style"
                    :title="item.title + ': Click to view ' + catalogueDocType.docType"
                    @contextmenu="$emit('openDocActionsMenuContext', $event, item)"
    >
                    <DocSummary
                      :doc_id="item.doc_id"
                      :text="item.title"
                      @openDocument="$emit('openDocument', $event)"
                      @copyDocument="$emit('copyDocument', $event)"
                    ></DocSummary>
      <!-- <span :title="item.title">{{ item.title }}</span> -->
      <v-badge
        color="red"
        v-if="item.match_count && 1 === 0"
        :content="item.match_count"
        offset-y="10"
      >
        <v-icon
          class="float-fight"
          color="blue"
          small
          title="click to compare similar docments"
          @click="$emit('showMatches', item)"
          >mdi-content-duplicate</v-icon
        >
      </v-badge>
    </div>
    <v-checkbox
      v-else-if="item.isSelectable"
      :label="item.title"
      style="margin-top: 0; text-align: left"
      @click="$emit('selectJob', item)"
    >
      <!-- v-model="item.selected" -->
    </v-checkbox>
    <div v-else-if="indentNode" style="position: relative">
      <div
        style="
          margin-left: 50%;
          border-left: 1px solid grey;
          height: 10px;
          width: 1px;
          position: relative;
          top: 0;
        "
      ></div>
      <cHierarchyNodeDisplay
        v-if="item.node"
        :node="item.node"
        @action="$emit('doMenuAction', $event, item, axis)"
      ></cHierarchyNodeDisplay>
    </div>
    <cHierarchyNodeDisplay
      v-else-if="item.node"
      :node="item.node"
      displayBorder
      :customStyle="item.style"
      @action="$emit('doMenuAction', $event, item, axis)"
    ></cHierarchyNodeDisplay>
    <div class="rowHeader" v-else :title="item.column_name + ': ' + item.title" :style="item.style">
      {{ item.title }}
    </div>
    <v-row v-if="item.children" no-gutters>
      <template v-for="(c, ci) in item.children">
        <v-col :key="'ch' + ci" :style="c.colCSS" style="margin-top: 12px">
          <template v-for="(rh, rhi) in c.rowHeadings">
            <cHierarchyViewColumnHeading
              :item="rh"
              :axis="axis"
              :indentNode="indentNode"
              :catalogueDocType="catalogueDocType"
              :key="'ch' + ci + 'rh' + rhi"
              @openDocumentID="$emit('openDocumentID', $event)"
              @openDocActionsMenuContext="
                $emit('openDocActionsMenuContext', $event)
              "
              @showMatches="$emit('showMatches', $event)"
              @selectJob="$emit('selectJob', $event)"
              @doMenuAction="$emit('doMenuAction', $event)"
            ></cHierarchyViewColumnHeading
          ></template>
        </v-col>
        <div
          v-if="c.showLine"
          :key="'bh' + ci"
          class="gridline"
        ></div></template
    ></v-row>
  </div>
</template>
<script>
import cHierarchyNodeDisplay from "@/components/hierarchy/cHierarchyNodeDisplay";
import DocSummary from "@/components/common/DocViewPopUp";
export default {
  name: "cHierarchyViewColumnHeading",
  props: {
    item: Object,
    indentNode: Boolean,
    axis: String,
    catalogueDocType: Object,
  },
  data: function () {
    return {};
  },
  components: {
    cHierarchyNodeDisplay,
	DocSummary,
  },
  watch: {},
  computed: {},
  created: function () {},
  methods: {},
};
</script>
  <style scoped lang="scss">
.gridline {
  width: 1px;
  background-color: lightgray;
  position: relative;
  margin-bottom: -20px;
  margin-top: 12px;
}
</style>