<template>
  <v-container>
    <v-row no-gutters class="sectionTitle mb-2">
      <v-col cols="4" class="d-flex align-center">
        <h2>
          {{ documents.length }} Document{{ documents.length === 1 ? "" : "s" }}
        </h2></v-col
      >
      <v-col cols="8" class="d-flex align-center justify-end">
        <!-- <v-pagination
          dense
          class="pagination"
          v-model="docPage"
          :length="docPageCount"
          :total-visible="7"
        ></v-pagination> -->
        <Pagination
          class="pagination"
          :totalItems="documents.length"
          :pageSize="docsPerPage"
          :currentPage="docPage"
          :maxPages=4
          @pageNavigation="docPage = $event"
        />
        <v-menu offset-y z-index="301">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="btn-background ml-5 px-3"
              style="width: auto;"
              icon
              v-bind="attrs"
              v-on="on"
            >
              {{ docsPerPageText }}
              <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item
              v-for="item in docsPerPageOptions"
              :key="item.value"
              :value="item.value"
              @click="docsPerPage = item.value; docPage = 1"
            >
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          class="btn-background ml-2"
          icon
          @click="exportToCSV"
          title="Download List">
          <v-icon>mdi-download</v-icon>
        </v-btn>
        <v-dialog v-model="editSettings" max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs"
              class="btn-background ml-2"
              icon
              v-on="on"
              color="grey lighten-1">
              <v-icon>
                mdi-cog
              </v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Panel Settings</span>
              <v-spacer></v-spacer>
              <v-btn icon class="btn-background" @click="editSettings = false">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row dense>
                  <v-col>Show/Hide Columns</v-col>
                </v-row>
                <v-row dense v-for="(c, ci) in allColumns" :key="'c' + ci">
                  <v-col>
                    <v-switch
                      dense
                      v-model="activeColumns"
                      inset
                      :value="c.name"
                      :label="c.name"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>Show/Hide Metrics</v-col>
                </v-row>
                <v-row dense v-for="(m, mi) in allMeasures" :key="'m' + mi">
                  <v-col>
                    <v-switch
                      dense
                      v-model="activeMeasures"
                      inset
                      :value="m.name"
                      :label="m.name"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row dense :style="{ fontWeight: 'bold', fontSize: settings.fontSize || '12px' }" ref="header">
      <v-col v-for="col in columns" :key="col.value" @click="setSort(col.value)" :style="{ cursor: 'pointer', textAlign: col.isFlex ? 'center' : 'left' }"
        >{{ col.name }}
        <span v-if="settings.sortBy && settings.sortBy.column === col.value">
          <v-icon small v-if="settings.sortBy.desc">mdi-sort-descending</v-icon>
          <v-icon small v-else>mdi-sort-ascending</v-icon></span
        ></v-col
      >
      <v-col
        v-for="(h, hi) in getHierarchies()"
        :key="'h' + hi"
        @click="setSort(h)"
        style="cursor: pointer"
        >{{ h.label }}
        <span v-if="settings.sortBy && settings.sortBy.column === h.column">
          <v-icon small v-if="settings.sortBy.desc">mdi-sort-descending</v-icon>
          <v-icon small v-else>mdi-sort-ascending</v-icon></span
        >
      </v-col>
      <v-col
        v-for="(m, mi) in measures"
        :key="'mh' + mi"
        style="text-align: center; cursor: pointer;"
        
        @click="setSort(m)"
      >
        {{ m.name }}
        <v-icon small v-if="m.sort === 'asc'">mdi-sort-ascending</v-icon>
        <v-icon small v-if="m.sort === 'desc'">mdi-sort-descending</v-icon>
      </v-col>
    </v-row>
    <!-- <perfect-scrollbar :style="{ height: rowsHeight, padding: '4px', fontSize: settings.fontSize || '12px'  }"> -->
    <div :style="{ minHeight: rowsHeight, padding: '4px', fontSize: settings.fontSize || '12px'  }">
      <v-row
        no-gutters
        v-for="(d, di) in getPageDocs()"
        :key="'d' + di"
        @click="openDocument(d)"
        @mouseenter="hoverIdx = di"
        @mouseleave="hoverIdx = null"
        :class="{ 'doc-row': true, 'doc-expanded': d.showSummary }"
      >
        <v-col v-for="col in columns" :key="col.value" :class="{ 'ellipses': !col.isFlex, 'd-flex': col.isFlex, 'justify-center': col.isFlex }">
          <template v-if="col.isFlex">
            <v-chip
              v-if="d[col.value] && d[col.value] !== 'Unknown'"
              :outlined="!d.showSummary && hoverIdx !== di"
              :color="d[col.value + 'Colour']"
              style="width: 90px;"
              class="mb-1 d-flex justify-center"
              small
            >
              <span :style="{ fontSize: '12px', color: d.showSummary || hoverIdx === di ? 'initial' : 'gray' }">
                {{ d[col.value] }}
              </span>
            </v-chip>
          </template>
          <template v-else>
            {{ d[col.value] }}
          </template>
        </v-col>
        <v-col
          v-for="(h, hi) in getHierarchies()"
          :key="'d' + di + 'dh' + hi"
          class="ellipses"
          >{{ d[h.column] }}
        </v-col>
        <v-col
          v-for="(m, mi) in measures"
          :key="'d' + di + 'm' + mi"
          :class="{ 'text-center': true, 'expand' : !settings.disableSummary }"
          @click="toggleSummary($event, d)"
        >
          <v-icon v-if="!settings.disableSummary && !d.showSummary" small style="float: right; width: 10%"
            >mdi-chevron-down</v-icon
          >
          <v-icon v-if="!settings.disableSummary && d.showSummary" small style="float: right; width: 10%"
            >mdi-chevron-up</v-icon
          >
          <v-tooltip bottom :disabled="!d[m.measureColumn + 'Info']" max-width="500">
            <template v-slot:activator="{ on, attrs }">
              <div v-if="m.isPercent" :style="{ float: 'left', width: settings.disableSummary ? '100%' : '90%' }" class="d-flex justify-center">
                <template v-if="d[m.measureColumn] !== undefined">
                  <v-progress-circular
                    v-if="
                      settings.scoreStyle === 'circular' ||
                      settings.scoreStyle === 'circular-large'
                    "
                    :rotate="90"
                    :size="settings.scoreStyle === 'circular' ? 45 : 70"
                    :width="settings.scoreStyle === 'circular' ? 10 : 20"
                    :value="d[m.measureColumn]"
                    :color="d[m.measureColumn + 'Colour']"
                    class="mr-1 mb-1 mt-1"
                    v-bind="attrs"
                    v-on="on"
                    ><span style="font-size: 13px; color: gray">
                      {{ d[m.measureColumn] }}</span
                    > </v-progress-circular
                  >
                  <v-chip
                    :outlined="!d.showSummary && hoverIdx !== di"
                    v-else-if="settings.scoreStyle === 'chip'"
                    :color="d[m.measureColumn + 'Colour']"
                    class="mb-1 d-flex justify-center"
                    style="width: 56px;"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span :style="{ fontSize: '12px', color: d.showSummary || hoverIdx === di ? 'initial' : 'gray' }">
                      {{ d[m.measureColumn] }}&percnt;
                    </span>
                  </v-chip>
                  <v-progress-linear
                    v-else
                    :height="15"
                    :value="d[m.measureColumn]"
                    :color="d[m.measureColumn + 'Colour']"
                    class="mr-1 mb-1 mt-1"
                    v-bind="attrs"
                    v-on="on"
                    ><template v-slot:default="{ value }">
                      <strong>{{ Math.ceil(value) }}%</strong>
                    </template>
                  </v-progress-linear>
                </template>
              </div>
              <span v-else v-bind="attrs" v-on="on">{{ d[m.measureColumn] }}</span>
            </template>
            <span v-html="d[m.measureColumn + 'Info']"></span>
          </v-tooltip>
        </v-col>

        <v-col cols="12" v-if="d.showSummary" class="doc-detail">
          <CheckSummary
            :summary="d.summary"
            @clicked="d.showSummary = false"
          ></CheckSummary>
        </v-col>
      </v-row>
    </div>
    <!-- </perfect-scrollbar> -->
  </v-container>
</template>
<script>
import CheckSummary from "@/components/cCheckSummary";
import Pagination from "@/components/cPagination";
import utils from "@/common/utils.js";
export default {
  name: "DocumentDashboardDocList",
  components: { CheckSummary, Pagination },
  props: {
    section: Object,
    documents: Array,
    hierarchyTypes: Array,
    settings: Object,
    flexCategories: Array,
    lifecycleStatuses: Array,
  },
  data: function () {
    return {
      response: null,
      barHeight: 15,
      docPageCount: 0,
      docPage: 1,
      headerHeight: 0,
      docsPerPage: 10,
      rowsHeight: "100px",
      isSorting: false,
      hoverIdx: null,
      editSettings: false,
      docColumns: [
        { value: "doc_type", name: 'Type' },
        { value: "reference", name: 'Reference' },
        { value: "doc_status", name: 'Status' },
        { value: "doc_name", name: 'Title' },
        { value: "owner_name", name: 'Owner' },
        { value: "created_date", name: 'Created' }
      ],
      activeColumns: [],
      activeMeasures: [],
      docsPerPageOptions: [
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "30", value: 30 },
        { text: "50", value: 50 },
        { text: "All", value: -1 },
      ],
      atsColumns: []
    };
  },
  updated() {
    //this.setHeaderHeight();
  },
  created() {
    if (this.settings.activeColumns && this.settings.activeColumns.length)
      this.activeColumns = this.settings.activeColumns;
    else
      this.activeColumns = this.docColumns.map(c => c.name);

    if (this.settings.activeMeasures && this.settings.activeMeasures.length)
      this.activeMeasures = this.settings.activeMeasures;

    if (this.settings.showStatusScores && this.lifecycleStatuses && this.lifecycleStatuses.length)
      this.activeMeasures.push(...this.lifecycleStatuses.map(s => s.name));

    this.atsDataExists();
  },
  mounted() {
    this.setHeaderHeight();
  },
  watch: {
    documents() {
    },
  },
  computed: {
    allColumns() {
      return [
        ...this.docColumns,
        ...this.atsColumns,
        ...this.flexCategories.map(f => {
          return {
            value: f.column,
            name: f.name,
            isFlex: true
          }
        })
      ];
    },
    docsPerPageText() {
      let option = this.docsPerPageOptions.find(o => o.value == this.docsPerPage);
      if (option)
        return option.text;
      else 
        return this.docsPerPage;
    },
    columns() {
      return this.allColumns.filter(c => this.activeColumns.some(a => a === c.name));
    },
    measures() {
      let d = this.settings.measures.filter((x) =>
        this.activeMeasures.some((m) => m === x.name)
      );
      d.forEach((m) => {
        let sort =
          this.settings.sortBy && this.settings.sortBy.measure === m.name
            ? this.settings.sortBy
            : null;
        m.sort = sort ? (sort.desc ? "desc" : "asc") : "";
      });
      return d;
    },
    allMeasures() {
      return this.settings.measures.filter(m => m.name !== "Docs");
    }
  },
  methods: {
    atsDataExists(){
      const usesAtsData = this.$loginState.user.settings.find((s) => s.setting === 'uses_ats_datareporting');
      if(usesAtsData !== undefined){
        let settingData = JSON.parse(usesAtsData.value);
        settingData.forEach(x => {
          this.atsColumns.push(x);
          this.activeColumns.push(x.name);
        })
      }
    },  
    setHeaderHeight() {
      this.headerHeight = this.$refs.header
        ? this.$refs.header.offsetHeight + 141
        : 161;
      this.rowsHeight = (this.settings.height - this.headerHeight) + "px";
      const rowHeight = this.settings.scoreStyle === 'chip' ? 28 : 24;
      this.docsPerPage = Math.floor((this.settings.height - this.headerHeight) / rowHeight);
      this.docPageCount = parseInt(this.documents.length / this.docsPerPage) + 1;
      if (this.isSorting) this.docPage = 1;
      this.isSorting = false;
    },
    getPageDocs() {
      if (this.docsPerPage < 0)
        return this.documents;
      else
        return this.documents.filter((d,di) => (parseInt(di / this.docsPerPage) + 1) === this.docPage);
    },
    openDocument(doc) {
      const openFlex = this.settings.openFlexInDocument;
      this.$emit("openDocument", doc, openFlex);
    },
    getHierarchies() {
      if (this.settings.showHierarchies) {
        return this.hierarchyTypes;
      } else return [];
    },
    toggleSummary(ev, d) {
      if (!this.settings.disableSummary) {
        d.showSummary = !d.showSummary;
        ev.preventDefault();
        ev.stopPropagation();
      }
    },
    setSort(item) {
      this.isSorting = true;
      this.$emit("changeSort", this.settings, item);
    },
    exportToCSV() {
      let data = this.allColumns.map(c => `"${c.name}"`).join(",");
      data += "," + this.allMeasures.map((h) => `"${h.name}"`).join(",") + "\n";
      this.documents.forEach((d) => {
        data += this.allColumns.map(c => utils.csvEscape(d[c.value])).join(",");
        data += "," + this.allMeasures.map((h) => utils.csvEscape(d[h.measureColumn])).join(",") + "\n";
      });
      utils.downloadFile(data, "Documents.csv", "text/csv;encoding:utf-8");
    },
  },
};
</script>
<style scoped>
.container {
  padding: 6px;
}
h2 {
  margin: 0px;
}

.doc-row {
  cursor: pointer;
}

.doc-expanded {
  border-bottom: solid 1px lightgray;
}

.expand {
  cursor: help;
}

.ellipses-start,
.ellipses {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellipses-start {
  direction: rtl;
  text-align: left;
  padding-right: 10px;
}
.v-chip {
  cursor: inherit;
}
</style>