<template>
  <div :class="alignRight ? 'float-right' : ''">
    <div v-if="text" @click.stop="getDoc" class="doc-display">
      <span>{{ text }}</span>
    </div>
    <v-icon
      v-else-if="icon"
      color="indigo"
      dark
      small
      @click.stop="getDoc"
      title="Click for quick view"
      >mdi-arrow-expand-all</v-icon
    >
    <Resizable
      v-if="show"
      class="resizable"
      ref="resizableComponent"
      :dragSelector="dragSelector"
      :active="handlers"
      :max-width="checkEmpty(maxW)"
      :max-height="checkEmpty(maxH)"
      :min-width="checkEmpty(minW)"
      :min-height="checkEmpty(minH)"
      :width="width"
      :height="height"
      :left="posX"
      :top="posY"
      :clickClasses="['allow_click']"
      :closeTrigger="closeTrigger"
      @mount="eHandler"
      @resize:move="eHandler"
      @resize:start="eHandler"
      @resize:end="saveResize"
      @drag:move="eHandler"
      @drag:start="eHandler"
      @drag:end="saveMove"
      @close="close"
      @click="detailClick"
      @blur="close('blur')"
    >
      <v-card style="border: solid 1px blue">
        <v-toolbar dense>
          <div class="drag-container" style="width: calc(100% - 100px)">
            <v-progress-circular
              v-if="busy"
              indeterminate
              size="50"
              color="grey"
            >
            </v-progress-circular>
            <v-toolbar-title v-else-if="document">
              <v-chip label outlined small>{{ document.system_number }}</v-chip
              ><span class="body-1"> {{ document.doc_name }}</span
              ><DocStatus
                :doc_id="document.doc_id"
                sideAddendum
                style="margin-left: 10px; min-width: 100px"
              ></DocStatus
            ></v-toolbar-title>
          </div>
          <v-icon
            title="Document actions"
            class="allow_click ml-2"
            @click.stop="openDocActionsMenuContext($event, document)"
            >mdi-dots-vertical</v-icon
          >
          <v-spacer class="drag-container"></v-spacer
          ><v-icon
            class="allow_click"
            title="Quick View Settings"
            @click.stop="showSettings"
            ref="openSettingsDialog"
            >mdi-cog-outline</v-icon
          ><v-icon class="allow_click ml-2" title="Close" @click.stop="doClose"
            >mdi-close</v-icon
          >
        </v-toolbar>
        <v-card-text
          :style="{ height: height - 48 + 'px', overflowY: 'scroll' }"
        >
		<v-row v-if="documents.length > 1" ref="tabContainer">
			<v-col>
				<v-btn-toggle dense v-model="docMainIndex">
				<v-btn  v-for="(dt, dti) in documents.filter((d,di) => di === 0 )" :key="'ddt' + dti" @click="changeDoc(0)">
              {{ dt.doc_type }}</v-btn 
            >
			</v-btn-toggle>
				<v-btn-toggle dense v-model="docLinkedIndex" class="float-right">
					<v-label class="mr-2 mt-3">Links:</v-label>
				<v-btn  v-for="(dt, dti) in documents.filter((d,di) => di > 0)" :key="'dt' + dti" @click="changeDoc(dti + 1)">
              <v-icon left>mdi-link-variant</v-icon>{{ dt.doc_type }}</v-btn 
            >
			</v-btn-toggle>
			</v-col>
		</v-row>
          <v-container v-if="document">
            <v-row
              v-for="(pt, pti) in document.partTypes.filter((x) =>
                partsVisible.some((pv) => pv === x.pt_name)
              )"
              :key="'pt' + pti"
              dense
            >
              <v-col
                ><v-row
                  dense
                  v-for="(p, pi) in pt.parts"
                  :key="'pt' + pti + 'p' + pi"
                  :class="pt.partClass"
                >
                  <v-col v-if="p.usesAttributes"
                    ><v-row dense>
                      <v-col
                        v-for="(a, ai) in p.attributes"
                        :key="'pt' + pti + 'p' + pi + 'a' + ai"
                        :cols="a.cols"
                        ><span
                          v-html="a.text"
                          :class="{
                            ListPart:
                              ai === 0 &&
                              (pt.moveable || pt.type === 'listitem'),
                          }"
                        ></span
                      ></v-col> </v-row
                  ></v-col>
                  <v-col v-else
                    ><v-row
                      dense
                      v-for="(s, si) in p.sub_parts"
                      :key="'pt' + pti + 'p' + pi + 's' + si"
                    >
                      <v-col
                        ><span
                          v-html="s.text"
                          :class="{
                            ListPart: pt.moveable || s.type === 'listitem',
                          }"
                        ></span
                      ></v-col> </v-row></v-col
                  ><v-col v-if="pt.uses_essential_flag">
                    <v-chip small class="float-right mb-1"
                      >{{ p.essential }}
                    </v-chip></v-col
                  >
                </v-row></v-col
              >
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <!-- </v-menu> -->
    </Resizable>
    <DocActionsMenu
      resizerClass="allow_click"
      :context="docActionsMenuContext"
      @openDocument="openDocument(docActionsMenuContext.document)"
      @copyDocument="$emit('copyDocument', docActionsMenuContext.document)"
    ></DocActionsMenu>
    <v-menu
      v-model="settingsDialog.show"
      absolute
      :position-x="settingsDialog.posX"
      :position-y="settingsDialog.posY"
      offset-x
      :close-on-content-click="false"
    >
      <v-card class="allow_click">
        <v-card-actions
          >Visible Sections:<v-spacer></v-spacer
          ><v-btn small outlined color="info" @click="showParts(true)"
            >Show All</v-btn
          >
          <v-btn small outlined color="info" @click="showParts(false)"
            >Hide All</v-btn
          >
          <v-btn color="blue darken-1" small outlined @click="saveSettings">
            Save
          </v-btn></v-card-actions
        >
        <v-divider></v-divider>
        <v-card-text style="height: 400px; overflow: scroll" class="pt-1">
          <v-card color="basil" flat class="pt-1">
            <v-card-text class="py-1">
              <v-switch
                v-for="(pv, pti) in settingsDialog.partsVisible"
                :key="'pv' + pti"
                v-model="pv.visible"
                hide-details
                :label="pv.pt_name"
              ></v-switch
            ></v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>
  
  <script>
import utils from "@/common/utils.js";
import DocStatus from "@/components/common/DocStatus";
import DocActionsMenu from "@/components/common/DocActionsMenu";
import Resizable from "@/components/common/Resizable";

export default {
  name: "DocViewPopUp",
  props: {
    doc_id: Number,
    alignRight: Boolean,
    text: String,
    icon: Boolean,
    trigger: Event,
  },
  components: {
    DocStatus,
    DocActionsMenu,
    Resizable,
  },
  data: function () {
    const tW = 800;
    const tH = 800;
    return {
      utils: utils,
      document: null,
      documents: [],
      allPartTypes: null,
      partsVisible: [],
      title: null,
      show: false,
      posX: 100,
      posY: 50,
      width: tW,
      height: tH,
      positionHorizontal: "left",
      positionVertical: "top",
      sizeWidth: "medium",
      sizeHeight: "large",
      busy: false,
      docActionsMenuContext: {
        show: false,
        busy: false,
        document: null,
        posX: 0,
        posY: 0,
      },
      settingsDialog: {
        show: false,
        posX: 0,
        posY: 0,
        partsVisible: [],
      },
      handlers: ["r", "rb", "b", "lb", "l", "lt", "t", "rt"],
      left: 100, //`calc( 50% - ${tW / 2}px)`,
      top: 100, //`calc(50% - ${tH / 2}px)`,
      maxW: 1000,
      maxH: 800,
      minW: 400,
      minH: 200,
      fit: true,
      event: "",
      dragSelector: ".drag-container",
      closeTrigger: 0,
      docMainIndex: 0,
      docLinkedIndex: -1,
    };
  },
  watch: {
    // "context.documents": function (newVal) {
    //   if (newVal) this.init();
    // },
    trigger: function (newVal) {
      if (newVal) {
        this.document = null;
        this.getDoc(newVal);
      }
    },
    document: function (newVal) {
      if (newVal) {
        this.initDocument();
      }
    },
  },
  mounted() {},
  created() {},
  computed: {},
  methods: {
    eHandler(data) {
      this.width = data.width;
      this.height = data.height;
      this.posX = data.left;
      this.posY = data.top;
      this.event = data.eventName;
    },
    saveResize(data) {
      this.width = data.width;
      this.height = data.height;
      this.saveDrag();
    },
    saveMove(data) {
      this.posX = data.left;
      this.posY = data.top;
      this.saveDrag();
    },
    saveDrag() {
      this.$loginState.saveSetting(`docViewSettings_layout`, {
        width: this.width,
        height: this.height,
        posX: this.posX,
        posY: this.posY,
      });
    },
    close(source) {
      if (source === "blur") this.show = false;
      else this.show = false;
    },
    doClose() {
      this.closeTrigger++;
      //   this.show = false;
    },
    detailClick(event) {
		if (this.$refs.tabContainer.contains(event.target)) return;
      event.stopPropagation();
    },
    checkEmpty(value) {
      return typeof value !== "number" ? 0 : value;
    },
    getDoc(event) {
      this.initLayout(event);
      this.show = true;
      if (!this.document) {
        this.busy = true;
        this.$store
          .dispatch("docs/getDocumentSummary", this.doc_id)
          .then((resp) => {
            this.documents = resp.data.Data.documents;
            this.document = this.documents[0];
            this.initDocument(event);
          })
          .then(() => {
            this.busy = false;
          }),
          (error) => {
            console.error(error);
          };
      } else {
        this.initDocument(event);
      }
    },
    changeDoc(index) {
		this.document = this.documents[index];
		this.initDocument();
		if (index === 0) {
			this.docMainIndex = 0;
			this.docLinkedIndex = -1;
		} else {
			this.docMainIndex = -1;
			this.docLinkedIndex = index - 1;			
		}
    },
    initDocument() {
      if (this.document) {
        let settings = this.$loginState.getSetting(
          `docViewSettings_${this.document.doc_type}`,
          null
        );
        this.partsVisible = this.document.partTypes
          .filter(
            (p) =>
              !settings?.partsVisible?.length ||
              settings.partsVisible.some((pv) => pv === p.pt_name)
          )
          .map((p) => p.pt_name);
      }
    },
    initLayout(event) {
      let settings = this.$loginState.getSetting(
        `docViewSettings_layout`,
        null
      );
      let width = event.view.outerWidth;
      let height = event.view.outerHeight;
      const defaultWidth = width / 2 - 100;
      const defaultHeight = height - 200;
      this.width = settings?.width || defaultWidth;
      this.height = settings?.height || defaultHeight;
      this.posX = settings?.posX || 50;
      this.posY = settings?.posY || 50;
    },
    openDocActionsMenuContext(event, document) {
      event.preventDefault();
      this.docActionsMenuContext.document = this.getDocById(document.doc_id);
      this.docActionsMenuContext.posX = event.clientX;
      this.docActionsMenuContext.posY = event.clientY;
      this.docActionsMenuContext.show = false;
      this.$nextTick(() => {
        this.docActionsMenuContext.show = true;
      });
    },
    getDocById(doc_id) {
      return this.$store.getters["docs/docsList"].find(
        (x) => x.doc_id === doc_id
      );
    },
    showSettings(event) {
      this.settingsDialog.show = true;
      this.settingsDialog.posX = event.clientX;
      this.settingsDialog.posY = event.clientY;
      this.settingsDialog.partsVisible = this.document.partTypes.map((p) => {
        return {
          pt_name: p.pt_name,
          visible: this.partsVisible.some((pv) => pv === p.pt_name),
        };
      });
    },
    showParts(show) {
      this.settingsDialog.partsVisible.forEach((x) => (x.visible = show));
    },
    saveSettings() {
      this.settingsDialog.show = false;
      this.partsVisible = this.settingsDialog.partsVisible
        .filter((x) => x.visible)
        .map((x) => x.pt_name);
      this.$loginState.saveSetting(
        `docViewSettings_${this.document.doc_type}`,
        {
          partsVisible: this.partsVisible,
        }
      );
    },
    openDocument(doc) {
      this.$emit("openDocument", doc);
    },
  },
};
</script>
<style scoped>
.block {
  height: 100%;
  width: 100%;
  background-color: aqua;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
  <style>
.HeaderPart,
.CentralHeaderPart {
  font-weight: 700;
  font-size: 18px;
}

.CentralTitle,
.Title {
  font-size: 1.625rem;
  font-weight: bold;
  line-height: 1.6;
}

.DocumentHdr {
  font-size: 1.25rem;
  font-style: italic;
}

.CentralHeaderPart,
.CentralTitle {
  text-align: center;
}

.AttrHeaderPart {
  font-weight: bold;
  font-size: 1rem;
  line-height: normal;
}

.ListPart {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
}

.drag-container {
  /* width: 100%; */
  min-height: 30px;
  /* text-align: center; */
  cursor: move;
}
.doc-display {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;
}
</style>