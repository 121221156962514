<template>
  <div v-if="document.comment_count != 0">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-badge v-if="document.comment_count > 1" :content="document.comment_count" :value="document.comment_count"
          color="blue" overlap bordered>
          <v-btn icon @click.stop="showSummary"><v-icon v-bind="attrs" v-on="on">chat</v-icon></v-btn>
        </v-badge>
        <v-btn v-else icon @click.stop="showSummary"><v-icon v-bind="attrs" v-on="on">chat</v-icon></v-btn>
      </template>
      <div class="text-center">
        <h3>{{ document.comment_count }} {{ document.comment_count > 1 ? utils.pluralise('Comment') : 'Comment' }}</h3>
        <span v-if="document.commenters">By {{ document.commenters }}</span>
      </div>
    </v-tooltip>
    <v-dialog v-model="dialog.show">
      <v-card>
        <v-card-title class="d-flex align-center">
          <div class="subtitle-1">
            Comments on {{ document.system_number }} - {{ document.doc_name }}
          </div>
          <v-spacer />
          <DocStatus class="mr-8" :document="document" @click="openDocument"></DocStatus>
          <v-btn icon large class="btn-background" @click="dialog.show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="d-flex justify-center pb-4" style="max-height: calc(90vh - 200px); overflow-y: auto;">
          <v-progress-circular v-if="dialog.loading" size="50" indeterminate color="primary"></v-progress-circular>
          <v-simple-table v-else-if="dialog.data && dialog.data.length" style="width: 100%">
            <thead>
              <tr>
                <th>Subject</th>
                <th>Comment</th>
                <th>Created By</th>
                <th>Role</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(c) in dialog.data" :key="c.document_comment_id">
                <td>{{ c.subject }}</td>
                <td>{{ c.comment }}</td>
                <td>{{ c.user_name }}</td>
                <td>{{ c.role }}</td>
                <td>{{ c.created_date }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-4">
          <v-btn color="primary" @click="openDocument">Open Document</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import utils from "@/common/utils.js";
import DocStatus from "@/components/common/DocStatus.vue";

export default {
  name: "CommentsCount",
  props: {
    document: Object
  },
  data: function () {
    return {
      utils: utils,
      dialog: {
        show: false,
        loading: false,
        data: null
      }
    };
  },
  components: { 
    DocStatus
  },
  watch: {
  },
  mounted() {
  },
  created() { },
  computed: {},
  methods: {
    showSummary() {
      this.dialog.show = true;
      this.dialog.loading = true;
      this.dialog.data = null;

      axios
        .get(`workflow/getAllDocComments/${this.document.doc_id}`)
        .then((resp) => {
          if (resp.data.Status == "OK") {
            this.dialog.data = resp.data.Data;
          }
          this.dialog.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.$emit("error", err);
          this.dialog.loading = false;
        });
    },
    openDocument() {
      this.dialog.show = false;
      this.$emit('openDocument', this.document);
    }
  },
};
</script>
<style></style>