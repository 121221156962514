<template>
  <v-container fluid>
    <v-card v-if="dataSetTypes.length">
      <v-row class="my-2 mx-1">
        <v-col cols="5" md="4" lg="3">
          <v-select
            label="Dataset"
            v-model="filters.dataSet"
            :items="dataSetTypes"
            item-text="name"
            item-value="dlt_id"
            clearable
            outlined
            dense
            hide-details
          >
          </v-select>
        </v-col>
        <v-col cols="5" md="4" lg="3">
          <v-text-field
            label="Search"
            v-model="filters.text"
            clearable
            outlined
            dense
            hide-details
            append-icon="search"
          >
          </v-text-field>
        </v-col>
        <!-- <v-col class="d-flex justify-end">
          <v-btn icon @click="init()"><v-icon>refresh</v-icon></v-btn>
        </v-col> -->
      </v-row>
      <v-data-table
        :headers="headers"
        dense
        :items="filteredFiles"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        :items-per-page="dtItemsPerPage"
        :page.sync="currentPage"
        hide-default-footer
        @click:row="editFile"
      >
        <template v-slot:[`item.status`]="{ item }">
          <v-chip outlined small :color="statusColour(item.status)"
            >{{ item.status }}
            <v-progress-circular
              v-if="item.status === 'PROCESSING'"
              indeterminate
              color="primary"
              size="20"
              class="ml-2"
            ></v-progress-circular>
          </v-chip>
        </template>
      </v-data-table>
      <v-row class="mt-2 mx-1">
        <v-col cols="3">
          <v-btn color="primary" outlined small @click="showAddFile"
            >Add File</v-btn
          >
        </v-col>
        <v-col class="text-center">
          <Pagination
            :totalItems="files.length"
            :pageSize="dtItemsPerPage"
            :currentPage="currentPage"
            @pageNavigation="currentPage = $event"
          />
        </v-col>
        <v-col cols="3" class="text-right">
          <v-menu offset-y z-index="301">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="!$loginState.readOnly"
                outlined
                small
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                {{ itemsPerPageText }}
                <v-icon right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>

            <v-list dense>
              <v-list-item
                v-for="item in itemsPerPageOptions"
                :key="item.value"
                @click="setPageSize(item.value)"
              >
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- <v-btn
            color="primary"
            class="ml-2"
            outlined
            small
            @click="exportToCSV()"
            >Export to CSV</v-btn
          > -->
        </v-col>
      </v-row>
    </v-card>

    <v-row
      class="mt-6 d-flex justify-center"
      v-if="!isLoading && dataSetTypes.length === 0"
    >
      <v-alert width="350" type="error" text>
        No reporting data sets defined.
      </v-alert>
    </v-row>

    <v-dialog v-model="addFile" persistent width="800">
      <v-card>
        <v-card-title class="px-6">
          <span class="headline">Upload File</span>
          <v-spacer></v-spacer>
          <v-btn icon large class="btn-background" @click="addFile = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-alert type="info" text dense>
            Please upload an XLS spreadsheet containing the data to be imported.
            Data will only be loaded from the first tab on the spreadsheet and
            the first row is expected to contain column headings.
          </v-alert>
          <v-row dense>
            <v-col cols="5" dense>
              <v-file-input
                v-model="inputFile"
                :accept="fileTypes"
                ref="inputFile"
                @change="selectFile"
                label="Select File"
              ></v-file-input>
            </v-col>
            <v-col cols="5" dense>
              <v-select
                label="DataSet"
                v-model="dataSetType"
                :items="dataSetTypes"
                item-text="name"
                item-value="dlt_id"
              >
              </v-select>
            </v-col>
            <v-col cols="2" dense class="d-flex justify-end align-center">
              <v-btn
                dense
                class="btn"
                color="primary"
                :disabled="!selectedFiles.length > 0 || filesUploading"
                @click="uploadMulti"
              >
                Upload
              </v-btn>
            </v-col>
          </v-row>
          <v-row
            dense
            v-for="(progressInfo, index) in progressInfos"
            :key="index"
          >
            <v-col cols="8">
              <div>
                <span>{{ progressInfo.fileName }}</span>
              </div>
            </v-col>
            <v-col cols="4" v-if="progressInfo" style="display: none">
              <div width="100%">
                <v-progress-linear
                  max="100"
                  height="15"
                  color="success"
                  v-model="progressInfo.percentage"
                >
                  <template>
                    {{ Math.ceil(progressInfo.percentage) }}%
                  </template>
                </v-progress-linear>
              </div>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12" sm="12" md="12" align="center">
              <div v-if="filesUploading === true" align="center">
                <v-progress-circular
                  :width="3"
                  color="green"
                  indeterminate
                ></v-progress-circular>
                &nbsp; Please Wait... Upload in Progress
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="file.show" persistent width="1200">
      <v-card v-if="file && file.details">
        <v-card-title class="px-6">
          <span class="headline">{{ file.details.file_name }}</span>
          <v-btn icon @click="downloadFile(file.dlf_id)"
            ><v-icon>download</v-icon></v-btn
          >
          <v-spacer></v-spacer>
          <v-btn icon large class="btn-background" @click="file.show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-row>
            <v-col>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Dataset</v-list-item-title>
                  <v-list-item-subtitle>{{
                    file.details.data_set_type
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Status</v-list-item-title>
                  <v-list-item-subtitle>{{
                    file.details.status
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>File Size</v-list-item-title>
                  <v-list-item-subtitle>{{
                    file.details.file_size
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Total Lines</v-list-item-title>
                  <v-list-item-subtitle>{{
                    file.details.metadata.totalLines.toLocaleString()
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Created Date</v-list-item-title>
                  <v-list-item-subtitle>{{
                    file.details.created_date
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col v-if="file.details.imported_date">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Imported Date</v-list-item-title>
                  <v-list-item-subtitle>{{
                    file.details.imported_date
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <template v-if="file.details.definition.columns">
            <v-simple-table>
              <colgroup>
                <col span="1" style="width: 20%" />
                <col span="1" style="width: 30%" />
                <col span="1" style="width: 30%" />
                <col span="1" style="width: 20%" />
              </colgroup>
              <thead>
                <tr>
                  <th>Dataset Column</th>
                  <th>Source Column</th>
                  <th>Sample Data</th>
                  <th>Issues</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(c, ci) in file.dataSetColumns" :key="'col' + ci">
                  <td class="caption">
                    {{ c.label }}
                    <span v-if="c.required" class="red--text">*</span>
                  </td>
                  <td>
                    <v-select
                      dense
                      placeholder="Select source column"
                      v-model="c.sourceColumn"
                      :items="file.fileColumns"
                      item-text="text"
                      return-object
                      hide-details
                      clearable
                      @change="c.error = validate(c)"
                    >
                    </v-select>
                  </td>
                  <td class="caption">
                    <span
                      v-if="c.sourceColumn && c.sourceColumn.samples.length"
                    >
                      {{ c.sourceColumn.samples[0] }}
                    </span>
                    <!-- <template v-if="c.sourceColumn">
                      <div v-for="(s, si) in c.sourceColumn.samples" :key="si">
                        {{ s }}
                      </div>
                    </template> -->
                  </td>
                  <td class="red--text caption">
                    {{ c.error }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </template>
        </v-card-text>
        <v-card-actions class="px-6">
          <v-btn color="error" outlined @click="deleteFile"> Delete </v-btn>
          <v-btn
            color="error"
            outlined
            v-if="file.details.status !== 'UPLOADED'"
            @click="rollbackFile"
          >
            Roll Back
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="file.show = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            @click="importFile"
            :disabled="file.details.status !== 'UPLOADED'"
          >
            Import
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="showErrorSnack"
      :timeout="snackTimeout"
      :color="snackColor"
      :multi-line="errorText.length > 50"
      top
      :style="padTop"
    >
      {{ errorText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="showErrorSnack = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <Loading :isVisible="isLoading" />
  </v-container>
</template>

<script>
import axios from "axios";
//import utils from "@/common/utils.js";
import Pagination from "@/components/cPagination";

export default {
  name: "fileUpload",
  props: {},
  components: {
    Pagination,
  },
  data() {
    return {
      dataSetTypes: [],
      dataSetType: null,
      filesUploading: false,
      isLoading: false,
      fileUploadFormData: {},
      selectedFiles: [],
      inputFile: null,
      progressInfos: [],
      addFile: false,
      message: "",
      errorText: "",
      showErrorSnack: false,
      snackColor: "error",
      snackTimeout: 4000,
      padTop: "padding-top:0px",
      fileTypes: ".xls,.xlsx,.ods",
      headers: [
        { text: "File", align: "start", value: "file_name" },
        { text: "Dataset", align: "start", value: "data_set_type" },
        { text: "File Size", align: "start", value: "file_size" },
        { text: "Status", align: "start", value: "status" },
        { text: "Created By", align: "start", value: "created_by" },
        { text: "Created Date", align: "start", value: "created_date" },
        { text: "Imported Date", align: "start", value: "imported_date" },
      ],
      filters: {
        dataSet: null,
      },
      files: [],
      sortBy: "created_date",
      sortDesc: true,
      dtItemsPerPage: 20,
      itemsPerPageOptions: [
        { text: "10 Rows", value: 10 },
        { text: "20 Rows", value: 20 },
        { text: "30 Rows", value: 30 },
        { text: "50 Rows", value: 50 },
      ],
      currentPage: 1,
      file: {
        show: false,
        dlf_id: null,
        details: null,
        dataSetColumns: [],
        fileColumns: [],
      },
    };
  },
  watch: {},
  computed: {
    itemsPerPageText() {
      const option = this.itemsPerPageOptions.find(
        (o) => o.value == this.dtItemsPerPage
      );
      return option ? option.text : `${this.dtItemsPerPage} Items`;
    },
    filteredFiles() {
      let filtered = this.files;
      if (this.filters.dataSet)
        filtered = filtered.filter((f) => f.dlt_id === this.filters.dataSet);

      if (this.filters.text)
        filtered = filtered.filter((f) =>
          f.file_name.toLowerCase().includes(this.filters.text)
        );

      return filtered;
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.isLoading = true;
      axios
        .get("reportData/reportDataLoad")
        .then((resp) => {
          if (resp.data.Status === "OK") {
            this.dataSetTypes = resp.data.Data.dataSetTypes;
            if (this.dataSetTypes.length !== 0 && !this.dataSetType)
              this.dataSetType = this.dataSetTypes[0].dlt_id;

            this.files = resp.data.Data.files;
            this.isLoading = false;
          }
        })
        .catch((err) => {
          this.triggerNotification("Error - " + err, "error");
          this.message = "Could not upload the file:" + err;
          this.isLoading = false;
        });
    },
    statusColour(status) {
      switch (status) {
        case "IMPORTED":
          return "success";
        case "PROCESSING":
          return "warning";
        case "ERROR":
          return "error";
        case "UPLOADED":
        default:
          return "primary";
      }
    },
    showAddFile() {
      this.progressInfos = [];
      this.selectedFiles = [];
      this.inputFile = null;
      if (this.filters.dataSet) this.dataSetType = this.filters.dataSet;
      this.addFile = true;
    },
    selectFile() {
      this.progressInfos = [];
      this.selectedFiles = [];
      let allowed = this.fileTypes.split(",");
      if (event.target.files) {
        for (let i = 0; i < event.target.files.length; i++) {
          let allow = allowed.some((x) =>
            event.target.files[i].name.includes(x)
          );
          if (allow) {
            this.selectedFiles.push(event.target.files[i]);
            this.progressInfos[i] = {
              percentage: 0,
              fileName: event.target.files[i].name,
            };
          }
        }
      }
    },
    uploadMulti() {
      this.filesUploading = true;
      let formData = new FormData();

      for (var i = 0; i < this.selectedFiles.length; i++) {
        let file = this.selectedFiles[i];
        formData.append("files[]", file);
      }

      formData.append("dataSetType", this.dataSetType);

      let options = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      try {
        axios
          .post("reportData/uploadReportData", formData, options)
          .then((resp) => {
            this.addFile = false;
            this.filesUploading = false;
            if (resp.data.Data.length) {
              this.files.push(...resp.data.Data);
              this.editFile(resp.data.Data[0]);
            }
          })
          .catch((err) => {
            this.triggerNotification("Error uploading - " + err, "error");
            this.message = "Could not upload the file:" + err;
            this.filesUploading = false;
          });
      } catch (err) {
        console.log(err);
      }
    },
    triggerNotification(text, type) {
      this.errorText = text;
      this.snackColor = type;
      this.showErrorSnack = true;
    },
    editFile(file) {
      this.file.dlf_id = file.dlf_id;
      this.file.fileColumns.splice(0);
      this.file.dataSetColumns.splice(0);
      this.isLoading = true;
      axios
        .get("reportData/reportDataFileDetails/" + file.dlf_id)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            const data = resp.data.Data;
            this.file.details = data;
            this.file.fileColumns.push(...data.metadata.columns);
            this.file.dataSetColumns.push(
              ...data.definition.columns
                .filter((c) => !c.generated)
                .map((c) => {
                  const savedSource = data.metadata.mapping.find(
                    (m) => m.name === c.name
                  )?.sourceColumn;
                  this.$set(
                    c,
                    "sourceColumn",
                    this.file.fileColumns.find(
                      (fc) => fc.value === savedSource
                    ) ||
                      this.file.fileColumns.find((fc) =>
                        c.source.includes(fc.value)
                      )
                  );
                  this.$set(c, "error", this.validate(c));
                  return c;
                })
            );
            this.file.show = true;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.triggerNotification(
            "Error reading file details - " + err,
            "error"
          );
          this.message = "Could not upload the file:" + err;
          this.isLoading = false;
        });
    },
    validate(c) {
      if (c.required && !c.sourceColumn) return "Is Required";

      if (c.sourceColumn) {
        if (c.isDate && !c.sourceColumn.isDate) return "Must be a date";
        else if (
          c.enum &&
          c.enum.length &&
          !c.sourceColumn.samples.every((s) =>
            c.enum.map((e) => e.toLowerCase()).includes((s || "").toLowerCase())
          )
        )
          return `Must be a ${c.enum.join("/")} value`;
      }

      return "";
    },
    importFile() {
      const request = {
        dlf_id: this.file.dlf_id,
        mapping: this.file.dataSetColumns.map((c) => {
          return {
            name: c.name,
            sourceColumn: c.sourceColumn?.value,
          };
        }),
      };
      this.isLoading = true;
      axios
        .post("reportData/import", request)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            const idx = this.files.findIndex(
              (f) => f.dlf_id === request.dlf_id
            );
            if (idx >= 0) this.files.splice(idx, 1, resp.data.Data);

            if (resp.data.Data.status === "PROCESSING") {
              this.pollForChanges(resp.data.Data.dlf_id);
            }

            this.file.show = false;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.triggerNotification("Error importing data - " + err, "error");
          this.message = "Could not upload the file:" + err;
          this.isLoading = false;
        });
    },
    pollForChanges(id) {
      setTimeout(() => {
        axios
          .get("reportData/fileheader/" + id)
          .then((resp) => {
            const idx = this.files.findIndex((f) => f.dlf_id === id);
            if (idx >= 0) this.files.splice(idx, 1, resp.data.Data);

            if (resp.data.Data.status === "PROCESSING") this.pollForChanges(id);
          })
          .catch(() => {
            this.pollForChanges(id);
          });
      }, 5000);
    },
    rollbackFile() {
      if (!confirm("Delete all data relating to this import file?")) return;

      const request = {
        dlf_id: this.file.dlf_id,
      };
      this.isLoading = true;
      axios
        .post("reportData/rollback", request)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            const idx = this.files.findIndex(
              (f) => f.dlf_id === request.dlf_id
            );
            if (idx >= 0) this.files.splice(idx, 1, resp.data.Data);

            this.file.show = false;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.triggerNotification(
            "Error rolling back import - " + err,
            "error"
          );
          this.message = "Could not upload the file:" + err;
          this.isLoading = false;
        });
    },
    deleteFile() {
      if (!confirm("Delete this import file and any related data?")) return;

      const request = {
        dlf_id: this.file.dlf_id,
      };
      this.isLoading = true;
      axios
        .post("reportData/delete", request)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            const idx = this.files.findIndex(
              (f) => f.dlf_id === request.dlf_id
            );
            if (idx >= 0) this.files.splice(idx, 1);

            this.file.show = false;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.triggerNotification("Error deleting import - " + err, "error");
          this.message = "Could not upload the file:" + err;
          this.isLoading = false;
        });
    },
    downloadFile(fileId) {
      let possibleError = false;
      this.isLoading = true;
      axios
        .get("reportData/download/" + fileId, { responseType: "text" })
        .then((resp) => {
          possibleError = true;
          window.open(resp.data, "_blank");
          this.isLoading = false;
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
          this.isLoading = false;
        });
    },
  },
};
</script>
<style scoped>
::v-deep .v-data-table tbody tr {
  cursor: pointer;
}
</style>