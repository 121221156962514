<template>
  <v-container fluid class="px-12">
    
    <v-row class="mt-6 justify-center">
      <v-col cols="12" md="6" lg="4" xl="3" v-for="(c, ci) in customerList" :key="'c' + ci">
        <v-hover v-slot="{ hover }">
          <v-card class="help-card"
            :elevation="hover ? 6 : 2"
            :class="{ 'hover': hover }"
            @click="impersonate(c)">
            <v-card-text >
              <h3>{{ c.client_name }}</h3>
              <div class="text-right">
                <v-btn color="primary" text>Go to customer <v-icon right>mdi-arrow-right</v-icon></v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>

    <Loading :isVisible="isLoading || customersLoading" />
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "vCustomers",
  components: {
  },
  props: {
    qaActions: { type: Array, required: true },
    editMode: { type: Boolean }
  },
  data: function () {
    return {
      isLoading: false
    };
  },
  mounted() {
  },
  created() {
  },
  watch: {
  },
  computed: {
    ...mapState({
      customersLoading: (state) => state.customers.loading,
      customerList: (state) => state.customers.customerList
    }),
  },
  methods: {
    impersonate(customer) {
      if (customer.internal_user_id) {
        this.account = false;
        this.$emit("impersonate", {
          impersonateUserId: customer.internal_user_id
        });
      }
    },
    sessionExpired(err) {
      this.$emit("sessionExpired", err);
    },
    openDocument(item) {
      this.$emit("openDocument", item);
    },
    copyDocument(item, tmpl_id) {
      this.$emit("copyDocument", item, tmpl_id);
    },
    documentChangedHandler(item) {
      this.$emit("documentChangedHandler", item);
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";

.help-card {
  border-radius: 10px !important;
  min-width: 200px;
  cursor: pointer;
  h3 {
    font-size: 20px;
  }
  .v-icon {
    opacity: 0.8;
  }
  &.theme--dark.hover {
    background-color: $secondary-background-dark;
  }
}
</style>