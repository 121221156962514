<template>
  <div>
    <v-dialog v-model="dialog" persistent width="1200">
      <v-card v-if="editedItem && hierarchyType">
        <v-card-title class="px-8">
          <span class="headline">Create {{ hierarchyType.label }}</span>
          <v-spacer></v-spacer>
          <v-btn icon large class="btn-background" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pb-0" style="min-height: 250px">
          <v-container>
            <v-row>
              <v-col cols="12" :sm="useSuggestions ? 4 : 6" class="mt-7">
                <v-row dense v-for="level in toplevels" :key="level.level">
                  <v-combobox
                    v-model="editedItem['level' + level.level]"
                    @change="loadSuggestions"
                    :items="level.distinctValues"
                    item-text="label"
                    return-object
                    :label="level.label"
                    outlined
                    class="roundish"
                    dense
                  ></v-combobox>
                </v-row>
              </v-col>
              <v-col cols="12" :sm="useSuggestions ? 4 : 6" v-if="childLevel" class="pt-0">
                <v-row>
                  <v-col>
                    <h3 class="ml-2">Included {{ childLevel.label }}s</h3>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0">
                    <v-text-field
                      outlined
                      dense
                      :label="`New ${childLevel.label}`"
                      class="roundish"
                      v-model="newChildText"
                      v-on:keyup.enter="addChild(newChildText)"
                      hide-details
                      append-icon="add"
                      @click:append="addChild(newChildText)">
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="editedItem.newChildren.length">
                  <v-col>
                    <v-list dense class="py-0">
                      <template v-for="(n, ni) in editedItem.newChildren">
                        <v-list-item 
                          :key="`ni${ni}`">
                          <v-list-item-content>
                            <v-list-item-title>{{ n.label }}</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-icon>
                            <v-btn small icon title="Remove" @click="removeChild(ni)">
                              <v-icon>delete</v-icon>
                            </v-btn>
                          </v-list-item-icon>
                        </v-list-item>
                        <v-divider
                            v-if="ni < editedItem.newChildren.length - 1"
                            :key="ni"
                          ></v-divider>
                      </template>
                    </v-list>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="4" v-if="useSuggestions && childLevel" class="pt-0">
                <v-row>
                  <v-col>
                    <h3 class="ml-2">Suggestions</h3>
                  </v-col>
                  <v-col class="d-flex justify-end align-start">
                    <v-btn icon small v-if="suggestionsPageNo > 1" @click="suggestionsPrevPage"><v-icon>chevron_left</v-icon></v-btn>
                    <v-btn icon small v-if="suggestionsPageNo * suggestionsPageSize < suggestions.length" @click="suggestionsNextPage"><v-icon>chevron_right</v-icon></v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="suggestions.length">
                  <v-col class="pt-0">
                    <v-list dense class="py-0">
                      <template v-for="(n, ni) in suggestionsPage">
                        <v-list-item 
                          :key="'li' + ni">
                          <v-list-item-content>
                            <v-list-item-title>{{ n.label }}</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-icon>
                            <v-btn small icon title="Add" @click="addChildSuggestion(n)">
                              <v-icon>add</v-icon>
                            </v-btn>
                          </v-list-item-icon>
                        </v-list-item>
                        <v-divider
                            v-if="ni < suggestions.length - 1"
                            :key="'dv' + ni"
                          ></v-divider>
                      </template>
                    </v-list>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-6 px-8">
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="close"> Cancel </v-btn>
          <v-btn color="primary" @click="save" :disabled="!canSave"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="showErrorSnack"
      :timeout="snackTimeout"
      :color="snackColor"
      :multi-line="errorText.length > 50"
      top
    >
      {{ errorText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <Loading :isVisible="isLoading" />
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "adminHierarchyAdd",
  props: {
    ht_id: Number,
    show: Boolean,
  },
  data: function () {
    return {
      dtItemsPerPage: 20,
      typeRuleOptions: [],
      spellCheckLanguages: ["us"],
      errorText: "",
      showErrorSnack: false,
      snackColor: "error",
      snackTimeout: 4000,
      editedItem: null,
      defaultItem: {
        hr_id: 0,
        level1: "",
        level2: "",
        level3: "",
        level4: "",
        level5: "",
        level6: "",
        newChildren: []
      },
      suggestedLevels: [],
      suggestions: [],
      suggestionsPageSize: 10,
      suggestionsPageNo: 1,
      newChildText: "",
      dialog: false,
      isLoading: false
    };
  },
  components: {},
  watch: {
    show(val) {
      this.dialog = val;
      if (val) this.loadItem();
    },
  },
  computed: {
    ...mapState({
      hierarchiesLoading: (state) => state.hierarchies.loading,
      hierarchies: (state) => state.hierarchies.hierarchies,
    }),
    formTitle() {
      return this.editedItem.hr_id ? "Edit Hierarchy" : "Add Hierarchy";
    },
    hierarchyType() {
      if (!this.editedItem) return null;

      return this.hierarchies.find((ht) => ht.ht_id === this.ht_id);
    },
    useSuggestions() {
      return this.hierarchyType?.ht_name === "Job" && this.$loginState.user.settings.some(
            (s) =>
              s.setting === 'hierarchyadd_usesuggestions' && s.value === "true"
          );
    },
    suggestionsPage() {
      return this.suggestions.filter((s, si) =>
        si >= this.suggestionsPageSize * (this.suggestionsPageNo - 1) &&
        si < this.suggestionsPageSize * this.suggestionsPageNo);
    },
    levels() {
      let allLevels = [1, 2, 3, 4, 5, 6];
      let levels = [];
      if (!this.hierarchyType) return levels;

      allLevels
        .filter(l => this.hierarchyType["use_h" + l])
        .forEach((l) => {
          const distinctValues = [];
          const levelPrev = this.editedItem["level" + (l - 1)];

          if (this.useSuggestions)
            distinctValues.push(...this.suggestedLevels
              .filter(s => s.level === l && s.label
                && (!levelPrev || levelPrev.suggestion_id === s.suggestion_id_parent)));

          distinctValues.push(
            ...[...new Set(this.hierarchyType.values
              .filter(h => h["level" + l] && (!levelPrev || h["level" + (l - 1)] === levelPrev.label))
              .map(h => h["level" + l]))]
            .filter(v => !distinctValues.some(dv => dv.label === v))
            .map(v => { return { label: v } }));

          distinctValues.sort((a, b) => (a.label > b.label) ? 1 : -1);

          levels.push({
            level: l,
            label: this.hierarchyType["level" + l + "_name"],
            distinctValues: distinctValues
          });
        });

      return levels;
    },
    toplevels() {
      return this.levels.filter((l, li) => li < this.levels.length - 1);
    },
    childLevel() {
      return this.levels.length ? this.levels[this.levels.length - 1] : null;
    },
    canSave() {
      return this.toplevels.every(l => this.editedItem["level" + l.level]) && (this.editedItem.newChildren.length || this.newChildText);
    }
  },
  created: function () {
    if (this.show) {
      this.dialog = true;
      this.loadItem();
    }
  },
  methods: {
    loadItem() {
      this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
      this.suggestedLevels.splice(0);
      this.suggestions.splice(0);
      if (this.useSuggestions) {
        axios
          .post("hierarchy/getSuggestedLevels", { levels: this.toplevels.map(l => l.level) })
          .then((resp) => {
            if (resp.data.Status === "OK") {
              this.suggestedLevels = resp.data.Data;
            }
            this.response = resp.data;
            this.isLoading = false;
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              console.log(err);
              this.response = err.response
                ? err.response.data
                : { message: "Unexpected Error" };
            }
            this.isLoading = false;
          });
      }
      return;
    },
    loadSuggestions() {
      if (!this.useSuggestions || this.toplevels.some(l => !this.editedItem[`level${l.level}`]))
        return;

      const data = {
        parent: this.editedItem["level" + (this.childLevel.level - 1)]
      };
      axios
        .post("hierarchy/getSuggestions", data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            const currentValues = this.childLevel?.distinctValues || [];
            this.suggestions = resp.data.Data
              .filter(s => !currentValues.some(v => v.label === s.label));
          }
          this.response = resp.data;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    suggestionsNextPage() {
      if (this.suggestionsPageNo * this.suggestionsPageSize < this.suggestions.length)
        this.suggestionsPageNo++;
    },
    suggestionsPrevPage() {
      if (this.suggestionsPageNo > 1)
        this.suggestionsPageNo--;
    },
    addChildSuggestion(suggestion) {
      if (suggestion && !this.editedItem.newChildren.some(c => c.label === suggestion.label)) {
        this.editedItem.newChildren.push(suggestion);
      }
    },
    addChild() {
      if (this.newChildText && !this.editedItem.newChildren.some(c => c.label === this.newChildText)) {
        this.editedItem.newChildren.push({ suggestion_id: null, label: this.newChildText });
        this.newChildText = "";
      }
    },
    removeChild(idx) {
      this.editedItem.newChildren.splice(idx, 1);
    },
    triggerNotification(text, type) {
      this.errorText = text;
      this.snackColor = type;
      this.showErrorSnack = true;
    },
    close() {
      this.editedItem = null;
      this.dialog = false;
      this.$emit("close");
    },
    save() {
      if (!this.hierarchyType || !this.canSave) return;

      if (this.newChildText)
        this.addChild();

      let data = this.editedItem;
      data.ht_id = this.ht_id;
      this.isLoading = true;

      axios
        .post("document/createHierarchyGroupAndDocuments", data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            this.editedItem = null;
            this.dialog = false;
            this.$store.dispatch("hierarchies/refresh");
            this.$emit("saved", {
              action: "add",
              newNode: resp.data.Data,
            });
            this.triggerNotification("Hierarchy Saved", "success");
          }
          this.response = resp.data;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
// .displayBorder {
//   margin-top: 5px;
//   border: solid 1px #004d40;
//   border-radius: 5px;
//   height: 24px;
//   font-size: 12px;
//   padding-left: 6px;
//   padding-right: 6px;
//   font-weight: bold;
//   white-space: nowrap;
//   width: 100%;
// }
</style>