<template>
  <div
    v-if="displayType === 'bar'"
    :class="'bar '"
    @click="clicked($event)"
    @contextmenu="openContextMenu($event)"
    :title="deriveTitle()"
  >
    <div class="inner" :style="`width: ${barWidthPct.toFixed(2)}%`"></div>
    <div :class="barIsSmall ? 'valueOffset' : 'value'">
      {{ formatValue() }}
    </div>
  </div>
  <div
    v-else
    :class="deriveClass()"
    @click="clicked($event)"
    @contextmenu="openContextMenu($event)"
    :title="deriveTitle()"
  >
    {{ formatValue() }}
  </div>
</template>
	
<script>
export default {
  name: "cHierarchyViewMeasureCount",
  props: {
    item: Object,
    measure: Object,
    selectable: Boolean,
    backColour: String,
    textColour: String,
    isTitle: Boolean,
    longTitle: Boolean,
    highlightSmall: Boolean,
    displayType: { type: String, default: "chip" },
  },
  data: function () {
    return {};
  },
  components: {},
  watch: {},
  computed: {
    propName() {
      return (
        this.measure.type + (this.measure.aggregation === "mean" ? "_mean" : "")
      );
    },
    title() {
      return this.longTitle ? this.measure.title : this.measure.abbreviation;
    },
    barWidthPct() {
      const value = Number(this.item[this.propName]) || 0;
      return (100 * value) / this.measure.max_value;
    },
    barIsSmall() {
      if (!this.$parent.$el) return true;

      return (this.$parent.$el.clientWidth * this.barWidthPct) / 100 < 50;
    },
  },
  created: function () {},
  methods: {
    formatValue() {
      if (this.isTitle) return this.title;
      const value = this.item[this.propName];
      if (this.measure.format === "pct")
        return `${(Number(value) || 0).toFixed(2)}%`;
      else if (value === undefined) return "";
      else if (value) return this.numberWithCommas(value);
      else return value;
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    deriveValue() {
      return this.isTitle ? this.title : this.item[this.propName];
    },
    deriveTitle() {
		let measureTitle = this.measure?.title;
      if (this.item && this.measure.aggregation === "mean") {
        let value = this.item[this.measure.type + "_std_dev"];
        let valueCount = this.item[this.measure.type + "_mean_value_count"];
        return valueCount
          ? `Mean ${measureTitle} (standard deviation of ${value.toFixed(
              0
            )} based on ${valueCount} document${valueCount > 1 ? "s" : ""})`
          : null;
      } else {
        let title = measureTitle + (this.selectable
          ? `: Click to ${(this.item.selected ? "un" : "")} select item. Right click to see linked documents.`
          : "");

        if (this.item && this.measure.isMatchCount) {
          title = `Click to view ${this.measure.description}.`;
        }
        return title;
      }
    },
    deriveClass() {
      let value = this.deriveValue();
      value = value === 0 ? "0" : value || "";
      if (this.isTitle)
        return (
          this.measure.type + (this.isTitle && this.longTitle ? " long " : "")
        );
      else if (
        !value ||
        (value === "0" && this.measure.aggregation_exclude_zero)
      )
        return "_hidden";
      else {
        const valNum = Number(value) || 0;
        const isSmall = this.highlightSmall ? valNum < 5 : false;
        let data = "";
        let ret =
          this.measure.type +
          (value === "0" ? " _zero" : "") +
          (isSmall ? " _small" : "") +
          (this.selectable && this.item[this.propName]
            ? this.item.selected
              ? " _selected"
              : " _selectable"
            : "") +
          (this.backColour && this.measure.aggregation !== "mean"
            ? " " + this.backColour
            : "") +
          (this.textColour && this.measure.aggregation !== "mean"
            ? " text--" + this.textColour
            : "") +
          (this.measure.alignRight ? " float-right" : "") +
          (this.measure.aggregation === "mean"
            ? " " + this.item[this.measure.type + "_category"]
            : "");
        data = ret;
        return ret || data;
      }
    },
    openContextMenu(e) {
      if (
        this.item &&
        (this.measure.type === "job_count" || this.measure.isMatchCount)
      )
        this.$emit("showDocList", e, this.item, this.measure);
    },
    clicked(e) {
      if (this.selectable) this.$emit("click");
      if (this.item && this.measure.isMatchCount)
        this.$emit("showDocList", e, this.item, this.measure);
    },
  },
};
</script>
	
<style scoped lang="scss">
._hidden {
  display: none;
}
div {
  max-width: 100px;
}
.bar {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  font-size: 16px;
  > .inner {
    max-width: 100%;
    height: 20px;
    background-color: rgb(231, 230, 230);
    border: solid #c2c1c1 1px;
    border-radius: 5px;
  }
  > .valueOffset {
    padding-left: 5px;
  }
  > .value {
    position: absolute;
    top: 0;
  }
}
.score,
.salary {
  text-align: right;
  border: solid grey 1px;
  border-radius: 8px;
  font-size: 12px;
  padding-right: 5px;
  /* padding: 4px 2px 3px 0; */
  overflow: hidden;
  margin-left: 5px;
  white-space: nowrap;
}
.employee_count,
.employee_percentage,
.job_count,
.similar_content_same_name,
.different_content_same_name,
.similar_content_different_name,
.leaf_node_count {
  text-align: center;
  border-radius: 16px;
  font-size: 13px;
  color: black;
  max-width: 85px;
  min-width: 20px;
  padding: 0 8px;
  display: inline-block;
  line-height: 19px;
}
.employee_count,
.employee_percentage {
  border: solid #c2c1c1 3px;
  background-color: rgb(231, 230, 230);
}
.employee_count._zero,
.employee_percentage._zero {
  border: solid red 3px;
  background-color: rgb(241, 158, 158);
}
.employee_percentage._small {
  border: solid rgb(253, 205, 114) 3px;
  background-color: rgb(255, 239, 198);
}
.job_count._zero {
  background-color: rgb(241, 158, 158);
  border: solid rgb(241, 158, 158) 3px;
}
.job_count {
  background-color: #deebf7;
  border: solid #deebf7 3px;
}
._selected {
  border: dotted blue 3px;
}
._selectable,
._selected {
  cursor: grab;
}
._selectable:hover,
._selected:hover {
  background-color: #4c779e;
  border-color: #4c779e;
  color: white;
}
.leaf_node_count {
  border: solid gray 2px;
}

._low {
  background-color: #ff6161;
  color: white;
}
._mid {
  background-color: lightgreen;
}
._high {
  background-color: green;
  color: white;
}
.different_content_same_name {
  background-color: #009688;
  border: solid #73b6f5 3px;
  color: white;
  cursor: pointer;
}
.similar_content_same_name {
  background-color: #3f51b5;
  border: solid #73b6f5 3px;
  color: white;
  cursor: pointer;
}
.similar_content_different_name {
  background-color: #9a3fb5;
  border: solid #73b6f5 3px;
  color: white;
  cursor: pointer;
}
.similar_content_same_name:hover,
.different_content_same_name:hover,
.similar_content_different_name:hover {
  background-color: #5d748a;
  color: white;
}
.long {
  max-width: unset;
}
</style>