<template>
  <v-container>
    <v-row class="sectionTitle">
      <v-col cols="8" class="d-flex align-center pt-0">
        <h2>{{ section.label || section.section }}</h2>
      </v-col>
      <v-col cols="4" class="d-flex justify-end pt-0">
        <v-btn
          class="btn-background"
          icon
          @click="exportToCSV"
          title="Download List">
          <v-icon>mdi-download</v-icon>
        </v-btn>
        
        <v-dialog v-model="editSettings" max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs"
              class="btn-background ml-2"
              icon
              v-on="on"
              color="grey lighten-1">
              <v-icon>
                mdi-cog
              </v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Panel Settings</span>
              <v-spacer></v-spacer>
              <v-btn icon class="btn-background" @click="editSettings = false">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-container>
                <!-- <v-row dense>
                  <v-col>
                    <v-select
                      :items="options.views"
                      v-model="selectedView"
                      label="View"
                      outlined
                      dense
                      :menu-props="{ 'offset-y': true }"
                      @change="changeView"
                    ></v-select>
                  </v-col>
                </v-row> -->
                <v-row dense>
                  <v-col>Show/Hide Metrics</v-col>
                </v-row>
                <v-row dense v-for="(m, mi) in settings.measures" :key="'m' + mi">
                  <v-col>
                    <v-switch
                      dense
                      v-model="activeMeasures"
                      inset
                      :value="m.name"
                      :label="m.name"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row no-gutters v-if="settings.headerPosition !== 'bottom'" dense :style="{ fontWeight: 'bold', fontSize: settings.fontSize || '12px' }" ref="header">
      <v-col v-if="settings.titleColWidth !== 0" :cols="settings.titleColWidth || '2'"
        ><h2 @click="setSort(false)" style="cursor: pointer">
          <v-icon
            small
            v-if="
              settings.sortBy &&
              settings.sortBy.column === 'key' &&
              !settings.sortBy.desc
            "
            >mdi-sort-ascending</v-icon
          >
          <v-icon
            small
            v-if="
              settings.sortBy &&
              settings.sortBy.column === 'key' &&
              settings.sortBy.desc
            "
            >mdi-sort-descending</v-icon
          >
        </h2>
      </v-col>
      <v-col
        v-for="(m, mi) in getMeasures()"
        :key="'mh' + mi"
        style="text-align: center; cursor: pointer"
        @click="setSort(m)"
      >
        {{ m.name }}
        <v-icon small v-if="m.sort === 'asc'">mdi-sort-ascending</v-icon>
        <v-icon small v-if="m.sort === 'desc'">mdi-sort-descending</v-icon>
      </v-col>
    </v-row>
    <div v-if="section.view.allowDrilldown">
      <div
        v-for="(fc, fci) in section.view.filterCriteria"
        :key="'fc' + fci"
      >
          <v-row
            no-gutters
            v-for="(l, li) in fc.levels"
            :key="'fc' + fci + 'l' + li"
          >
            <v-col :style="{ paddingLeft: li * 10 + 'px' }">
              <v-icon small v-if="li > 0">mdi-subdirectory-arrow-right</v-icon>
              {{ l }}
              <v-icon small @click.stop="removeFilter(li)"
                >mdi-filter-remove</v-icon
              ></v-col
            >
            <!-- <v-col></v-col>
                <v-col></v-col>
                <v-col
                  v-for="(c, ci) in views[view].data[0].categories"
                  :key="'c' + ci"
                >
                </v-col> -->
          </v-row>
      </div>
    </div>
    <perfect-scrollbar :style="{ height: rowsHeight, padding: '4px', fontSize: settings.fontSize || '12px' }">
      <v-row
        v-for="(d, di) in section.view.data.filter(
          (d) =>
            !section.viewFilter ||
            !section.view.filterCriteria ||
            d.key === section.viewFilter
        )"
        :key="'d' + di"
        no-gutters
        @click="rowSelected(d.key, !!section.view.scoreBandGroupBy)"
        @mouseenter="hoverIdx = di"
        @mouseleave="hoverIdx = null"
        :style="{ cursor: !!section.view.scoreBandGroupBy ? '' : 'pointer' }"
      >
        <v-col v-if="!section.view.scoreBandGroupBy">
          <v-row no-gutters :class="{'my-1': settings.scoreStyle === 'chip'}">
            <v-col
              v-if="settings.titleColWidth !== 0"
              :style="{
                paddingLeft:
                  section.view.filterCriteria.length &&
                  section.view.filterCriteria[0].levels
                    ? section.view.filterCriteria[0].levels.length * 10 + 'px'
                    : '0px',
              }"
              :cols="settings.titleColWidth || '2'"
              >{{ d.key
              }}<v-icon
                v-if="
                  section.view.filterCriteria.length &&
                  !section.view.allowDrilldown
                "
                small
                @click.stop="removeFilter(0)"
                >mdi-filter-remove</v-icon
              >
            </v-col>
            <v-col
              v-for="(m, mi) in getMeasures()"
              :key="'m' + mi"
              style="text-align: center"
            >
              <div v-if="m.isPercent" class="d-flex justify-center">
                <v-progress-circular
                  v-if="
                    settings.scoreStyle === 'circular' ||
                    settings.scoreStyle === 'circular-large' ||
                    settings.scoreStyle === 'circular-xlarge'
                  "
                  :rotate="90"
                  :size="settings.scoreStyle === 'circular' ? 45 : settings.scoreStyle === 'circular-xlarge' ? 120 : 70"
                  :width="settings.scoreStyle === 'circular' ? 5 : settings.scoreStyle === 'circular-xlarge' ? 10 : 8"
                  :value="d[m.measureColumn]"
                  :color="d[m.measureColumn + 'Colour']"
                  class="mr-1 mb-1 mt-1"
                  ><span :style="{ 'fontSize': settings.scoreStyle === 'circular-xlarge' ? '20px' : '13px',
                    'fontWeight': settings.scoreStyle === 'circular-xlarge' ? 'bold' : 'normal',
                    'color': 'gray'}">
                    {{ d[m.measureColumn] }}</span
                  > </v-progress-circular>
                <v-chip
                  :outlined="di !== hoverIdx"
                  v-else-if="settings.scoreStyle === 'chip'"
                  :color="d[m.measureColumn + 'Colour']"
                  class="d-flex justify-center"
                  style="width: 56px;"
                  small
                >
                  <span :style="{ fontSize: '13px', color: di === hoverIdx ? 'initial' : 'gray' }">
                    {{ d[m.measureColumn] }}&percnt;
                  </span>
                </v-chip>
                <v-progress-linear
                  v-else
                  :height="barHeight"
                  :value="d[m.measureColumn]"
                  :color="d[m.measureColumn + 'Colour']"
                  class="mr-1 mb-1 mt-1"
                  ><template v-slot:default="{ value }">
                    <strong>{{ Math.ceil(value) }}%</strong>
                  </template>
                </v-progress-linear>
              </div>
              <span v-else>{{ d[m.measureColumn] }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-else>
          <v-row no-gutters>
            <v-col style="font-weight: 600">{{ d.key }}</v-col>
          </v-row>
          <v-row
            v-for="(sd, sdi) in d.GBDetails"
            :key="'d' + di + 'sd' + sdi"
            no-gutters
            @click="rowSelected(d.key + ' - ' + sd.key)"
            style="cursor: pointer"
          >
            <v-col
              style="padding-left: 20px"
              :cols="settings.titleColWidth || '2'"
              >{{ sd.key
              }}<v-icon
                v-if="
                  section.view.filterCriteria.length &&
                  !section.view.allowDrilldown
                "
                small
                @click.stop="removeFilter(0)"
                >mdi-filter-remove</v-icon
              >
            </v-col>
            <v-col
              v-for="(m, mi) in getMeasures()"
              :key="'m' + mi"
              style="text-align: center"
            >
              <div v-if="m.isPercent">
                <v-progress-circular
                  v-if="
                    settings.scoreStyle === 'circular' ||
                    settings.scoreStyle === 'circular-large'
                  "
                  :rotate="90"
                  :size="settings.scoreStyle === 'circular' ? 45 : 70"
                  :width="settings.scoreStyle === 'circular' ? 10 : 20"
                  :value="sd[m.measureColumn]"
                  :color="sd[m.measureColumn + 'Colour']"
                  class="mr-1 mb-1 mt-1"
                  ><span style="font-size: 13px; color: gray">
                    {{ sd[m.measureColumn] }}</span
                  > </v-progress-circular
                ><v-progress-linear
                  v-else
                  :height="barHeight"
                  :value="sd[m.measureColumn]"
                  :color="sd[m.measureColumn + 'Colour']"
                  class="mr-1 mb-1 mt-1"
                  ><template v-slot:default="{ value }">
                    <strong>{{ Math.ceil(value) }}%</strong>
                  </template>
                </v-progress-linear>
              </div>
              <span v-else>{{ sd[m.measureColumn] }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </perfect-scrollbar>
    <v-row no-gutters v-if="settings.headerPosition === 'bottom'" dense :style="{ fontWeight: 'bold', fontSize: settings.fontSize || '12px' }" ref="header">
      <v-col v-if="settings.titleColWidth !== 0" :cols="settings.titleColWidth || '2'">
      </v-col>
      <v-col
        v-for="(m, mi) in getMeasures()"
        :key="'mh' + mi"
        style="text-align: center"
      >
        {{ m.name }}
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import utils from "@/common/utils.js";
export default {
  name: "DocumentDashboardView",
  components: {},
  props: {
    section: Object,
    options: Object,
    settings: Object
  },
  data: function () {
    return {
      response: null,
      barHeight: 15,
      editSettings: false,
      selectedView: null,
      headerHeight: 0,
      hoverIdx: null,
      activeMeasures: []
    };
  },
  updated() {
    this.selectedView = this.section.view.name;
  },
  created() {
    window.addEventListener('resize', this.setHeaderHeight);
    this.activeMeasures = this.settings.activeMeasures;
  },
  mounted() {
    this.setHeaderHeight();
  },
  destroyed() {
    window.removeEventListener('resize', this.setHeaderHeight);
  },
  watch: {
    settings(val) {
      if (val) {
        this.activeMeasures = val.activeMeasures;
      }
    }
  },
  computed: {
    rowsHeight() {
      return (this.settings.height - this.headerHeight) + "px";
    },
  },
  methods: {
    setHeaderHeight() {
      this.headerHeight = this.$refs.header
        ? this.$refs.header.offsetHeight + 145
        : 165;
    },
    rowSelected(value, ignore) {
      if (ignore) return;
      if (
        this.section.view.filterCriteria.length &&
        !this.section.view.filterCriteria[0].ht_id
      ) {
        this.removeFilter(0);
      } else if (
        this.section.view.data.length > 1 &&
        (this.section.view.allowDrilldown ||
          !this.section.view.filterCriteria.length)
      )
        this.$emit("rowSelected", this.section.view, value);
    },
    changeView(name) {
      this.$emit("changeView", name || this.selectedView);
    },
    removeFilter(level) {
      this.$emit("removeFilter", level);
    },
    getMeasures() {
      let d = this.settings.measures.filter((x) =>
        this.activeMeasures.some((m) => m === x.name)
      );
      d.forEach((m) => {
        let sort =
          this.settings.sortBy && this.settings.sortBy.measure === m.name
            ? this.settings.sortBy
            : null;
        m.sort = sort ? (sort.desc ? "desc" : "asc") : "";
      });
      return d;
    },
    setSort(item) {
      if (item && this.section.view.scoreBandGroupBy) return;
      this.$emit("changeSort", this.settings, item || "key");
    },
    exportToCSV() {
      let data = `"${this.section.section}",`;
      let cols = this.getMeasures();
      data += cols.map((c) => `"${c.name}"`).join(",") + "\n";
      this.section.view.data.forEach((d) => {
        //utils.removeTags(d[h.measureColumn])
        data += `${utils.csvEscape(d.key)},`;
        data += cols.map((c) => utils.csvEscape(d[c.measureColumn])).join(",") + "\n";
      });
      utils.downloadFile(
        data,
        `${this.section.section}.csv`,
        "text/csv;encoding:utf-8"
      );
    },
  },
};
</script>
<style scoped>
.row--dense > .col,
.row--dense > [class*="col-"] {
  padding: 1px;
}
.container {
  padding: 6px;
}
h2 {
  margin: 0px;
}
.v-chip {
  cursor: inherit;
}
</style>