<template>
  <div>
    <ResponseHandler :serviceResponse="response"></ResponseHandler>

    <Loading :isVisible="isLoading" />

    <v-dialog v-model="addFile" persistent max-width="1200">
      <v-card>
        <v-card-title class="px-6">
          <span class="headline">Upload Users</span>
          <v-spacer></v-spacer>
          <v-select 
            v-if="mappedUploadData.length"
            label="Show"
            dense
            v-model="uploadDataFilter"
            outlined
            hide-details
            :items="['All', 'Valid Only', 'Issues Only']"
            style="max-width: 150px;"
            class="mr-4">
          </v-select>
          <v-btn icon large class="btn-background" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <template v-if="!uploadData">
            <v-alert type="info" text dense>
              Please upload an XLS spreadsheet containing the data to be imported.
              Data will only be loaded from the first tab on the spreadsheet and
              the first row is expected to contain column headings.
            </v-alert>
            <v-row dense>
              <v-col cols="8" dense>
                <v-file-input
                  v-model="inputFile"
                  :accept="fileTypes"
                  ref="inputFile"
                  @change="selectFile"
                  label="Select File"
                ></v-file-input>
              </v-col>
              <v-col cols="4" dense class="d-flex justify-end align-center">
                <v-btn
                  dense
                  class="btn"
                  color="primary"
                  :disabled="!selectedFiles.length > 0 || filesUploading"
                  @click="uploadMulti"
                >
                  Upload
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              dense
              v-for="(progressInfo, index) in progressInfos"
              :key="index"
            >
              <v-col cols="8">
                <div>
                  <span>{{ progressInfo.fileName }}</span>
                </div>
              </v-col>
              <v-col cols="4" v-if="progressInfo" style="display: none">
                <div width="100%">
                  <v-progress-linear
                    max="100"
                    height="15"
                    color="success"
                    v-model="progressInfo.percentage"
                  >
                    <template>
                      {{ Math.ceil(progressInfo.percentage) }}%
                    </template>
                  </v-progress-linear>
                </div>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="12" sm="12" md="12" align="center">
                <div v-if="filesUploading === true" align="center">
                  <v-progress-circular
                    :width="3"
                    color="green"
                    indeterminate
                  ></v-progress-circular>
                  &nbsp; Please Wait... Upload in Progress
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-else-if="!processing">
            <v-alert v-if="invalidUploadData.length !== 0" type="warning" text>
              There are {{ invalidUploadData.length }} rows with validation issues that cannot be imported.
            </v-alert>
            <v-data-table
              :headers="filteredUploadDataHeaders"
              dense
              :sort-by="sortBy"
              :sort-desc="sortDesc"
              :items="filteredMappedUploadedData"
              hide-default-header
            >
              <template v-slot:header="{ props }">
                <thead class="v-data-table-header">
                  <tr>
                    <th
                      v-for="(head, hi) in props.headers"
                      :key="'h' + hi"
                      @click="setUploadSort(head)"
                      :class="head.sortable === false ? '' : 'sortable'"
                    >
                      <template>
                        <span>
                          {{ head.text }}
                        </span>
                        <v-icon small v-if="head.sortable !== false && head.value === sortBy">{{
                          sortDesc ? "arrow_downward" : "arrow_upward"
                        }}</v-icon>

                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on: tooltip }">
                                <v-icon v-if="head.source" small v-on="{ ...on, ...tooltip }" v-bind="attrs" class="ml-2">settings</v-icon>
                                <v-icon v-else small v-on="{ ...on, ...tooltip }" v-bind="attrs" color="warning" class="ml-2">warning</v-icon>
                              </template>
                              <span v-if="head.source">Change source column</span>
                              <span v-else>Select a source column</span>
                            </v-tooltip>
                          </template>
                          <v-list dense>
                            <v-list-item
                              v-for="(uh, uhi) in uploadData.headers"
                              :key="'uh' + uhi"
                              @click="setUploadSource(head, uh)"
                            >
                              <v-list-item-title>
                                {{ uh }}
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td v-for="col in filteredUploadDataHeaders" :key="col.value">
                    <span v-html="item[col.value].value" style="font-size: 0.8rem;"></span>
                    <v-tooltip bottom v-if="item[col.value].issues.length !== 0">
                      <template v-slot:activator="{ on: tooltip }">
                        <v-icon small v-on="{ ...tooltip }" color="warning" class="ml-1">warning</v-icon>
                      </template>
                      <div v-html="item[col.value].issues.join('<br />')"></div>
                    </v-tooltip>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-row>
              <v-col class="d-flex">
                <v-btn color="primary" outlined @click="cancelUpload">
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-checkbox
                  v-if="showSSOOnlyToggle"
                  v-model="uploadSSOOnly"
                  label="SSO Only"
                  class="mr-4"
                ></v-checkbox>
                <v-checkbox
                  :disabled="uploadSSOOnly"
                  v-model="uploadSendEmail"
                  label="Send Account Setup Email"
                  class="mr-4"
                ></v-checkbox>
                <v-btn color="primary"
                  :disabled="validUploadData.length === 0"
                  @click="createUsers">
                  Import {{ validUploadData.length }} Users
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <div v-else>
            <v-progress-linear
              color="primary"
              height="25"
              :value="createProgressPct"
            >
              <strong>Processing {{ createProgress }} of {{ validUploadData.length }}</strong>
            </v-progress-linear>
            <v-alert type="error" v-if="createErrors.length !== 0" class="mt-4" text>
              <span v-html="createErrors.join('<br />')"></span>
            </v-alert>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
import { mapState } from "vuex";
//import utils from "@/common/utils.js";

export default {
  name: "checkJP",
  components: {
    ResponseHandler,
  },
  props: {
    permissionGroups: { type: Array },
    ssoEnabled: { type: Boolean },
  },
  data: function () {
    return {
      response: null,
      isLoading: false,
      addFile: false,
      inputFile: null,
      fileTypes: ".xls,.xlsx,.ods,.csv",
      selectedFiles: [],
      filesUploading: false,
      progressInfos: [],
      uploadData: null,
      uploadDataHeaders: [
        { text: "First Name", value: "firstname", defaultSource: "first", source: null, sort: this.sortByProperty("value") },
        { text: "Last Name", value: "lastname", defaultSource: "last", source: null, sort: this.sortByProperty("value") },
        { text: "Username", value: "username", defaultSource: "user", source: null, sort: this.sortByProperty("value") },
        { text: "Email", value: "email", defaultSource: "email", source: null, sort: this.sortByProperty("value") },
        { text: "Permission", value: "permission", defaultSource: "perm", source: null, sort: this.sortByProperty("value") },
      ],
      sortBy: "lastname",
      sortDesc: false,
      mappedUploadData: [],
      uploadDataFilter: 'All',
      uploadSSOOnly: false,
      uploadSendEmail: true,
      processing: false,
      createProgress: 0,
      createErrors: [],
    };
  },
  watch: {
    uploadSSOOnly(val) {
      if (val) {
        this.uploadSendEmail = false;
      }
    },
    useEmailForUsername() {
      this.mapAndvalidateUploadData();
    }
  },
  computed: {
    ...mapState({
      config: (state) => state.settings.config,
    }),
    validUploadData() {
      return this.mappedUploadData.filter(d => !this.filteredUploadDataHeaders.some(h => !h.source || d[h.value].issues.length));
    },
    invalidUploadData() {
      return this.mappedUploadData.filter(d => this.filteredUploadDataHeaders.some(h => !h.source || d[h.value].issues.length));
    },
    filteredMappedUploadedData() {
      if (this.uploadDataFilter === 'Issues Only')
        return this.invalidUploadData;
      else if (this.uploadDataFilter === 'Valid Only')
        return this.validUploadData;
      else
        return this.mappedUploadData;
    },
    ssoUseEmail() {
      return this.config.settings.some(s => s.setting === 'user_admin_store_email_as_username_for_sso' && s.value === 'true');
    },
    useEmailForUsername() {
      return this.ssoEnabled && this.ssoUseEmail && this.uploadSSOOnly;
    },
    showSSOOnlyToggle() {
      if (!this.ssoEnabled)
        return false;

      const allowNonSSOUserCreation = (this.config.settings.find(s => s.setting === 'allow_non_sso_user_creation')?.value || 'true') === 'true';

      return (this.$loginState.isInternal || allowNonSSOUserCreation);
    },
    filteredUploadDataHeaders() {
      return this.uploadDataHeaders.filter(h => h.value !== 'username' || !this.useEmailForUsername);
    },
    createProgressPct() {
      return Math.round(100 * this.createProgress / this.validUploadData.length);
    }
  },
  created() {
    this.uploadSSOOnly = this.ssoEnabled;
    this.showAddFile();
  },
  methods: {
    close() {
      this.addFile = false;
      this.$emit("closed");
    },
    uploadMulti() {
      this.filesUploading = true;
      let formData = new FormData();

      for (var i = 0; i < this.selectedFiles.length; i++) {
        let file = this.selectedFiles[i];
        formData.append("files[]", file);
      }

      formData.append("dataSetType", this.dataSetType);

      let options = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      try {
        axios
          .post("user/uploadUsers", formData, options)
          .then((resp) => {
            this.filesUploading = false;
            if (resp.data.Data) {
              this.uploadData = resp.data.Data;
              this.uploadDataHeaders.forEach(h => {
                h.source = this.uploadData.headers.find(sh => sh.toLowerCase().includes(h.defaultSource));
              });
              this.mapAndvalidateUploadData();
            }
          })
          .catch((err) => {
            this.triggerNotification("Error uploading - " + err, "error");
            this.message = "Could not upload the file:" + err;
            this.filesUploading = false;
          });
      } catch (err) {
        console.log(err);
      }
    },
    showAddFile() {
      this.cancelUpload();
      this.addFile = true;
    },
    cancelUpload() {
      this.progressInfos.splice(0);
      this.selectedFiles.splice(0);
      this.mappedUploadData.splice(0);
      this.uploadData = null;
      this.inputFile = null;
    },
    selectFile() {
      this.progressInfos.splice(0);
      this.selectedFiles.splice(0);
      let allowed = this.fileTypes.split(",");
      if (event.target.files) {
        for (let i = 0; i < event.target.files.length; i++) {
          let allow = allowed.some((x) =>
            event.target.files[i].name.includes(x)
          );
          if (allow) {
            this.selectedFiles.push(event.target.files[i]);
            this.progressInfos[i] = {
              percentage: 0,
              fileName: event.target.files[i].name,
            };
          }
        }
      }
    },
    async mapAndvalidateUploadData() {
      if (!this.uploadData || !this.uploadData.data || this.uploadData.data.length === 0)
        return;

      this.mappedUploadData.splice(0);

      const mappedUploadData = this.uploadData.data.map(d => {
        const output = {};
        this.uploadDataHeaders.forEach(h => {
          const val = h.source ? (d[h.source] || '').trim() : '';
          const issues = [];
          if (h.source)
            switch (h.text) {
              case "First Name":
              case "Last Name":
              case "Username":
                if (!val)
                  issues.push(`Missing`);
                break;
              case "Email":
                if (!val)
                  issues.push(`Missing`);
                else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val))
                  issues.push(`Invalid email address`);
                break;
              case "Permission":
                if (!val)
                  issues.push(`Missing`);
                else if (!this.permissionGroups.some(pg => pg.name.toLowerCase() === val.toLowerCase()))
                  issues.push(`Invalid permission, must be one of:<br />${this.permissionGroups.map(pg => pg.name).join(', ')}`);
                break;
            }
          output[h.value] = {
            value: val,
            issues: issues
          }
        });
        return output;
      });

      const usernames = this.useEmailForUsername ?
        mappedUploadData.filter(d => d.email?.value).map(d => d.email.value)
        : mappedUploadData.filter(d => d.username?.value).map(d => d.username.value);
      if (usernames.length !== 0)
        await axios
          .post("user/uniquenamecheckmultiple/", usernames)
          .then((resp) => {
            if (resp.data.Status === "OK") {
              resp.data.Data.forEach(u => {
                if (this.useEmailForUsername)
                  mappedUploadData
                    .filter(d => d.email?.value && u.username && d.email.value?.toLowerCase() === u.username.toLowerCase())
                    .forEach(d => {
                      d.email.issues.push('Email is already in use');
                    });
                else
                  mappedUploadData
                    .filter(d => d.username?.value && u.username && d.username.value?.toLowerCase() === u.username.toLowerCase())
                    .forEach(d => {
                      d.username.issues.push('Username is already in use');
                    });
              })
            }
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              console.log(err);
              this.response = err.response
                ? err.response.data
                : { message: "Unexpected Error" };
            }
            this.isLoading = false;
          });

      this.mappedUploadData.push(...mappedUploadData);
    },
    setUploadSource(head, source) {
      head.source = source;
      this.mapAndvalidateUploadData();
    },
    setUploadSort(head) {
      if (head.sortable === false) return;

      if (this.sortBy === head.value) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortBy = head.value;
      }
    },
    sortByProperty(property) {
      return function (a, b) {
        if (a[property] > b[property]) return 1;
        else if (a[property] < b[property]) return -1;

        return 0;
      };
    },
    async createUsers() {
      this.processing = true;
      this.createProgress = 0;
      const errors = [];
      for (let index = 0; index < this.validUploadData.length; index++) {
        const row = this.validUploadData[index];
        const data = {
          isNew: true,
          bulkaction: true,
          first_name: row.firstname.value,
          last_name: row.lastname.value,
          username: this.useEmailForUsername ? row.email.value : row.username.value,
          email: row.email.value,
          sso_only: this.uploadSSOOnly,
          permissionGroups: [ this.permissionGroups.find(pg => pg.name.toLowerCase() === row.permission.value.toLowerCase()).permission_group_id ],
          sendAccountSetupEmail: this.uploadSendEmail && !this.uploadSSOOnly,
        }
        await axios.post("auth/saveUser/", data).then((resp) => {
          if (resp.data.Status !== 'OK') {
            errors.push(`Failed to create ${data.first_name} ${data.last_name}: ${resp.data.Message}`);
          }
        }).catch((err) => {
          console.log(err);
          errors.push(`Failed to create ${data.first_name} ${data.last_name}: Unexpected error`);
        });
        this.createProgress = index + 1;
      }
      this.$emit("finished");
      if (errors.length === 0) {
        this.processing = false;
        this.close();
      } else {
        this.createErrors = errors;
      }
    }
  },
};
</script>
<style scoped lang="scss">
</style>
