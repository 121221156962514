<template>
  <div class="home">
     <PartsWithDocuments v-if="!newUi" @sessionExpired="sessionExpired"  @openDocument="openDocument"></PartsWithDocuments>
     <ContentManager v-else @sessionExpired="sessionExpired"  @openDocument="openDocument"></ContentManager>
  </div>
</template>

<script>
import PartsWithDocuments from "@/components/cPartsWithDocuments.vue";
import ContentManager from '@/components/cContentManager.vue';
import { mapState } from 'vuex';

export default {
  name: "vPartsWithDocuments",
  components: {
    PartsWithDocuments,
    ContentManager
  },
  mounted() {
  },
  computed: {
    ...mapState({
      newUi: (state) => state.theme.newUi
    })
  },
  methods: {
    sessionExpired(err) {
      this.$emit("sessionExpired", err);
    },
    openDocument(doc) {
      this.$emit("openDocument", doc);
    }
  }
};
</script>