<template>
  <v-dialog v-model="show" persistent max-width="980px" @keydown.esc="close()">
    <v-card v-if="source">
      <v-toolbar fixed dense color="grey lighten-5">
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title v-if="source">{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text @click="close">Close</v-btn>
      </v-toolbar>
      <v-card-text dense>
        <!-- <v-row dense v-if="source && source.issues.length">
          <v-col cols="4">
            <v-expansion-panels>
              <v-expansion-panel
                v-for="(e, ei) in source.issues"
                :key="'e' + ei"
              >
                <v-expansion-panel-header>
                  <span>{{ e.title }}</span>
                  <v-badge
                    :content="e.errorCount"
                    color="red"
                    inline
                    overlap
                    v-if="e.errorCount"
                  >
                  </v-badge>
                  <v-badge
                    :content="e.warningCount"
                    color="amber"
                    inline
                    overlap
                    v-if="e.warningCount"
                  >
                  </v-badge>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row dense v-for="(w, wi) in source.warnings.filter(x => x.category + ' - ' + x.short_description === e.title)" :key="'w_' + ei + '_' + wi">
                    <v-col
                      cols="2"
                    >
					<div
                          :style="{
                            float: 'left',
                            width: '20px',
                            height: '20px',
                            'background-color': w.colour,
                          }"
                        ></div>
                    </v-col>
					<v-col cols="10" >                   
                        <v-badge
                          color="amber"
                          :content="w.count"
                          inline
                          overlap
                          style="color: slategray; font-size: 12px"
                        >
                          <div
                            v-html="w.description"
                          ></div>
                        </v-badge>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <v-row dense v-if="source && source.issues.length">
          <v-col cols="4" v-for="(e, ei) in source.issues" :key="'e' + ei">
            {{ e.title }}
            <v-badge
              :content="e.errorCount"
              color="red"
              inline
              overlap
              v-if="e.errorCount"
            >
            </v-badge>
            <v-badge
              :content="e.warningCount"
              color="amber"
              inline
              overlap
              v-if="e.warningCount"
            >
            </v-badge>
          </v-col>
        </v-row> -->
        <v-row wrap dense style="padding-top: 10px" v-if="source">
          <v-col cols="12" v-if="source.parts">
            <v-row
              dense
              v-for="(p, pi) in source.parts.filter(
                (x) => x.status.errors.length || x.status.warnings.length
              )"
              :key="'p' + pi"
            >
              <v-col cols="12">
                <v-row dense>
                  <v-col cols="2">
                    <v-icon @click="p.show = !p.show">{{
                      p.show ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                    <span>{{ p.part }}</span>
                  </v-col>
                  <v-col cols="10">
                    <v-row
                      dense
                      v-for="(e, ei) in p.status.errors"
                      :key="'e' + ei"
                    >
                      <v-col cols="5">
                        <v-badge
                          :content="e.count"
                          color="red"
                          left
                          inline
                          overlap
                        >
                          <label
                            >{{ e.category }} - {{ e.short_description }}</label
                          ></v-badge
                        >
                      </v-col>
                      <v-col cols="7"
                        ><span v-html="e.description"></span
                      ></v-col>
                    </v-row>
                    <v-row
                      dense
                      v-for="(w, wi) in p.status.warnings"
                      :key="'w' + wi"
                    >
                      <v-col cols="5">
                        <v-badge
                          :content="w.count"
                          color="amber"
                          left
                          inline
                          overlap
                        >
                          {{ w.category }} - {{ w.short_description }}
                        </v-badge>
                      </v-col>
                      <v-col cols="7"
                        ><span v-html="w.description"></span
                      ></v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row v-if="p.show">
                  <v-col cols="1"></v-col>
                  <v-col cols="10"
                    ><v-sheet elevation="5" style="padding: 10px"
                      ><span v-html="p.status.highlightedText"></span></v-sheet
                  ></v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <ResponseHandler :serviceResponse="response"></ResponseHandler>
    <Loading :isVisible="isLoading" />
  </v-dialog>
</template>

<script>
import axios from "axios";
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
import utils from "@/common/utils.js";

export default {
  name: "DocumentCheckStatus",
  components: {
    ResponseHandler,
  },
  props: {
    open: null,
    source: null,
  },
  data: function () {
    return {
      show: false,
      title: "",
      response: null,
      isLoading: false,
      utils: utils,
    };
  },
  updated() {},
  watch: {
    open: {
      immediate: true,
      handler(val) {
        if (val && this.source) {
          this.show = true;
          //   this.fetchData();
          this.title = "Document Check - ";
          //   let errorCount = this.source.errors.reduce((p, c) => p + c.count, 0);
          //   let warningCount = this.source.warnings.reduce((p, c) => p + c.count,0);
          let errorCount = this.source.issues.reduce(
            (p, c) => p + c.errorCount,
            0
          );
          let warningCount = this.source.issues.reduce(
            (p, c) => p + c.warningCount,
            0
          );
          if (errorCount)
            this.title +=
              errorCount +
              " Error" +
              (errorCount > 1 ? "s" : "") +
              (warningCount ? " and " : "");
          if (warningCount)
            this.title +=
              warningCount + " Warning" + (warningCount > 1 ? "s" : "");
          else if (!errorCount) this.title += "No issues found";
          this.source.parts.forEach((p) => this.$set(p, "show", false));
        }
      },
    },
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      axios
        .get("document/getHierarchyValues/")
        .then((resp) => {
          if (resp.data.Status === "OK") {
            resp.data.Data.hierarchies.forEach((h) => {
              let hr = this.document.hierarchies.find(
                (dh) => dh.ht_id === h.ht_id
              );
              h.hr_id = hr ? hr.hr_id : null;
              h.hierarchy_text = hr
                ? h.values.find((v) => v.value === hr.hr_id).text
                : null;
              h.missing = hr ? false : true;
            });
            this.hierarchies = resp.data.Data.hierarchies;
            this.statuses = resp.data.Data.statuses;
            this.recruiters = resp.data.Data.lookups.filter(
              (x) => x.type === "Recruiter"
            );
            this.managers = resp.data.Data.lookups.filter(
              (x) => x.type === "Manager"
            );
            this.errMessage = "";
          } else {
            this.errMessage = resp.data.message;
          }
          this.isLoading = false;
          //console.log(resp);
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    close() {
      this.show = false;
      this.$emit("closed");
    },
  },
};
</script>
<style scoped>
</style>
