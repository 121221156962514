<template>
  <div>
            <div style="display:none;">
        <!-- Sacrificial fields to remove annoying Chrome habit of auto-filling fields -->
          <input type="text">
          <input type="password">
    </div>
    <v-container
      fluid
      class="no-print"
    >
      <v-row>
        <v-col cols="1"></v-col>
        <v-col cols="9">
          <v-text-field
            v-model="filterText"
            dense
            flat
            clearable
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="type text and enter to filter users..."
            class="hidden-sm-and-down"
            @input="doFilter"
            @keypress.enter="doFilter"
            @click:clear="clearFilter"
          />
        </v-col>
        <v-col cols="1"></v-col>
        <v-col cols="1" class="d-flex align-center justify-center">
          <v-icon
            @click="exportToCSV"
            class="float-right"
            color="green darken-2"
            title="Download List"
          >mdi-cloud-download</v-icon>
        </v-col>
      </v-row>
      <div v-if="items.length" class="no-print mt-6">
        <v-data-table
          :headers="headers"
          dense
          :items="items"
          sort-by="name"
          class="elevation-1 py-3"
          :footer-props="{'items-per-page-options':[10, 20, 30, 50, -1]}"
          :items-per-page="dtItemsPerPage"
          @update:options="dtOptionChange"
        >
          <template v-slot:[`item.name`]="{ item }">
            <span v-html="item.name" style="font-size:0.7rem"></span>
          </template>
          <template v-slot:[`item.first_name`]="{ item }">
            <span v-html="item.first_name" style="font-size:0.7rem"></span>
          </template>
          <template v-slot:[`item.last_name`]="{ item }">
            <span v-html="item.last_name" style="font-size:0.7rem"></span>
          </template>
          <template v-slot:[`item.email`]="{ item }">
            <span v-html="item.email" style="font-size:0.7rem"></span>
          </template>
          <template v-slot:[`item.username`]="{ item }">
            <span v-html="item.username" style="font-size:0.7rem"></span>
          </template>
          <template v-slot:[`item.last_login`]="{ item }">
            <span v-html="item.last_login" style="font-size:0.7rem"></span>
          </template>
          <template v-slot:[`item.first_login`]="{ item }">
            <span v-html="item.first_login" style="font-size:0.7rem"></span>
          </template>
          <template v-slot:[`item.logins`]="{ item }">
            <span v-html="item.logins" style="font-size:0.7rem"></span>
          </template>
          <template v-slot:[`item.week_num`]="{ item }">
            <span v-html="item.week_num" style="font-size:0.7rem"></span>
          </template>
          <template v-slot:[`item.year_num`]="{ item }">
            <span v-html="item.year_num" style="font-size:0.7rem"></span>
          </template>
          <template v-slot:[`item.country_name`]="{ item }">
            <span v-html="item.country_name" style="font-size:0.7rem"></span>
          </template>
          <template v-slot:[`item.region_name`]="{ item }">
            <span v-html="item.region_name" style="font-size:0.7rem"></span>
          </template>
          <template v-slot:[`item.cost_center`]="{ item }">
            <span v-html="item.cost_center" style="font-size:0.7rem"></span>
          </template>
          <template v-slot:[`item.cost_center_number`]="{ item }">
            <span v-html="item.cost_center_number" style="font-size:0.7rem"></span>
          </template>
          <template v-slot:[`item.group_name`]="{ item }">
            <span v-html="item.group_name" style="font-size:0.7rem"></span>
          </template>
          <template v-slot:[`item.organization`]="{ item }">
            <span v-html="item.organization" style="font-size:0.7rem"></span>
          </template>
          <template v-slot:[`item.sub_division`]="{ item }">
            <span v-html="item.sub_division" style="font-size:0.7rem"></span>
          </template>
          <template v-slot:[`item.division`]="{ item }">
            <span v-html="item.division" style="font-size:0.7rem"></span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small title="Edit User Details + Permission" @click="editDetails(item)">mdi-pencil</v-icon>
            <v-icon small @click="setPassword(item)" title="Set Password" v-if="item.sso_last_login == 0">mdi-key-change</v-icon>
            <!-- <v-icon small @click="setPermission(item)" title="Set Permission Level">mdi-security</v-icon> -->
            <v-icon small @click="setInactive(item)" title="Set User Inactive" v-if="item.sso_last_login == 0">mdi-delete</v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">Reset</v-btn>
          </template>
        </v-data-table>
      </div>

      <ResponseHandler :serviceResponse="response"></ResponseHandler>

      <Loading :isVisible="isLoading" />


    </v-container>
  </div>
</template>

<script>
import axios from "axios";
//import draggable from "vuedraggable";
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
import utils from "@/common/utils.js";

export default {
  name: "checkJP",
  components: {
    ResponseHandler
    // draggable
  },
  props: {},
  data: function() {
    return {
      response: null,
      items: [],
      cleanItems: [],
      hierarchies: [],
      recruiters: [],
      managers: [],
      utils: utils,
      isDirty: false,
      pwdDialogue: false,
      permissionDialogue: false,
      newPermission: null,
      newPassword: "",
      currentUID: "",
      currentUName: "",
      isLoading: false,
      tempFilterText: "",
      filterText: "",
      geoHeaders: [
        { text: "First Name", value: "first_name" },
        { text: "Last Name", value: "last_name" },
        { text: "Week", value: "week_num" },
        { text: "Year", value: "year_num" },
        { text: "Country", value: "country_name" },
        { text: "Region", value: "region_name" },
        { text: "Cost Center", value: "cost_center_number" },
        { text: "Cost Center Name", value: "cost_center" },
        { text: "Group", value: "group_name" },
        { text: "Organization", value: "organization" },
        { text: "Sub Division", value: "sub_division" },
        { text: "Division", value: "division" },
        { text: "Logins", value: "logins" },
      ],
      dtItemsPerPage: 20,
      options: { itemsPerPage: 15 },
      tab: null,
      tabItems: [
        {name:'User Maintenance', text: ''},
        {name:'Usage Report', text: 'Usage'},
      ]
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  created() {
    this.fetchData();
    this.dtItemsPerPage = Number(localStorage.getItem("dtUL")) || 20;
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      let possibleError = false;

          this.cleanItems = [];
          this.headers = this.geoHeaders;
          axios
            .get("user/user_logins/")
            .then(resp => {
              possibleError = true;
              if (resp.data.Status === "OK") {
                this.cleanItems = resp.data.Data.map(x => {
                  let n = JSON.parse(JSON.stringify(x));
                  n.match = `${n.first_name} ${n.last_name} ${n.country_name} ${n.region_name} ${n.week_num} ${n.year_num} ${n.division} ${n.sub_division} ${n.organization}`
                    .toLowerCase()
                    .split(" ")
                    .filter(x => x.length)
                    .reduce((p, c) => p + c + "|", "|");

                  return n;
                });
                this.doFilter();
                this.isLoading = false;
              }
              this.response = resp.data;
            })
            .catch(err => {
              if (possibleError) {
                alert("Code Error");
              } else if (err.response && err.response.status === 401) {
                this.$emit("sessionExpired", err);
              } else {
                alert(err.response ? err.response.data.message : err);
              }
              console.log(err);
              this.isLoading = false;
            });

    },
    doFilter() {
      const filterText = (this.filterText || "").trim().toLowerCase();

      let users = JSON.parse(JSON.stringify(this.cleanItems));

      if (filterText) {
        let search = filterText
          .split(" ")
          .filter(x => x.length)
          .map(x => x);

        users = users.filter(d =>
          search.every(s => d.match.indexOf(s, 0) >= 0)
        );
        users.forEach(d => {
          search.forEach(s => {
            let searchArray = [];
            if(this.tab === 1) {
              searchArray = ["first_name", "last_name", "week_num", "year_num", "country_name", "cost_center", "group_name", "organization", "region_name","division","sub_division"]
            } else {
              searchArray = ["name", "email", "username"]
            }
            searchArray.forEach(col => {
              let val = d[col] || "",
                posMs = val.indexOf("<mark>"),
                posMe = val.indexOf("</mark>"),
                posPrev = 0,
                output = "";
              while (posMs >= 0) {
                if (posMs - posPrev > 0) {
                  output += val
                    .substring(posPrev, posMs)
                    .replace(
                      new RegExp(s, "gi"),
                      match => `<mark>${match}</mark>`
                    );
                }
                output += val.substring(posMs, posMe + 7);
                posPrev = posMe + 7;
                posMs = val.indexOf("<mark>", posMe);
                posMe = val.indexOf("</mark>", posMs);
              }
              if (posPrev < val.length) {
                output += val
                  .substring(posPrev, val.length)
                  .replace(
                    new RegExp(s, "gi"),
                    match => `<mark>${match}</mark>`
                  );
              }
              d[col] = output;
            });
          });
        });
      }
      this.items = users;
    },
    dtOptionChange(value) {
      localStorage.setItem("dtUL", value.itemsPerPage);
      this.dtItemsPerPage = value.itemsPerPage;
    },
    
    exportToCSV() {

        let data = this.geoHeaders.map(h => '"' + h.text + '"').join(",") + "\n";
        this.items.forEach(d => {
          data +=
            this.geoHeaders
              .map(h => utils.csvEscape(utils.removeTags(d[h.value])))
              .join(",") + "\n";
        });
        utils.downloadFile(data, "Users.csv", "text/csv;encoding:utf-8");
      
    },
    clearFilter() {
      this.filterText = "";
      this.doFilter();
    },
    
    sortByProperty(property){  
      return function(a,b){  
          if(a[property] > b[property])  
            return 1;  
          else if(a[property] < b[property])  
            return -1;  
      
          return 0;  
      }  
    }
  }
};
</script>
<style scoped></style>
