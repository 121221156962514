<template>
  <div>
    <v-btn v-if="displayMode === undefined" :disabled="uploadingDisabled" large color="success" class="roundish"  @click="initialiseUpload()">
      Upload New Document
    </v-btn>
    <v-btn v-if="displayMode === 'small'" :disabled="uploadingDisabled" fab x-small color="success" class="roundish" title="Upload New Document"  @click="initialiseUpload()">
      <v-icon>mdi-cloud-upload</v-icon>
    </v-btn>

    <v-dialog v-model="fileUploadDialog" max-width="1200px" style="z-index:998">
    <v-card>
        <v-card-title class="justify-center">
        </v-card-title>
        <v-card-text>
        <v-row dense>
            <v-col cols="7" sm="7"  md="7"  dense>
                <v-file-input :accept="fileTypes" v-if="uploadReady && !allowMultipleUpload" ref="inputFile" dense @change="selectFile" label="Select File"></v-file-input>
              
                <v-file-input :accept="fileTypes" v-if="uploadReady && allowMultipleUpload" ref="inputFile" multiple dense @change="selectFile" label="Select File(s)"></v-file-input>
            </v-col>
            <v-col cols="3" sm="3"  md="3" dense align="center">
                <v-btn  dense class="btn success" :disabled="!selectedFiles.length > 0 || filesUploading" @click="uploadMulti">
                  Upload
                </v-btn>
            </v-col>
            <v-col cols="2" sm="2"  md="2" dense align="center" v-if="enableTranslate">
                <v-btn  dense class="btn primary" @click="openOptionsDialog">
                  <v-icon>
                    mdi-cog
                  </v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row v-if="enablePrivateFlag" dense>
            <v-col cols="12" sm="12" md="12" dense align="center">
                <v-checkbox v-model="uploadPrivate" :label="selectedFiles.length > 1 ? 'are these documents confidential?' : 'is this a confidential document?'"></v-checkbox>
            </v-col>
        </v-row>
        <v-row dense v-for="(progressInfo, index) in progressInfos" :key="index">
            <v-col cols="6" sm="6"  md="6">
                <div>
                    <span>{{progressInfo.fileName}}</span>
                </div>
            </v-col>
            <v-col cols="5" sm="5"  md="5" v-if="progressInfo" style="display:none">
                <div width="100%">
                    <v-progress-linear max="100" height="15" color="success" v-model="progressInfo.percentage">
                        <template>
                            {{ Math.ceil(progressInfo.percentage) }}%
                        </template>
                    </v-progress-linear>
                </div>
            </v-col>            
        </v-row>
        <v-row  align="center">
          <v-col cols="12" sm="12" md="12" align="center">
            <div  v-if="filesUploading === true" align="center">
              <v-progress-circular
                :width="3"
                color="green"
                indeterminate
              ></v-progress-circular>   &nbsp; Please Wait... Upload in Progress           
            </div>
          </v-col>
        </v-row>
        </v-card-text>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">Close</v-btn>
        <v-spacer></v-spacer>
        </v-card-actions>
    </v-card>
    </v-dialog>

    <v-dialog v-model="fileUploadOptionsDialog" max-width="1200px">
    <v-card>
        <v-card-title class="justify-center">
        </v-card-title>
        <v-card-text>
        <v-row dense>
          <v-select v-model="selectdTranslateLang" :items="translateOptions" label="Target Language">
            <template slot="item" slot-scope="item">
              <div>
                <country-flag :country='getFlagCode(item)' size='normal'/>&nbsp;&nbsp;{{item.item.text}}
              </div>
            </template>
            <template v-slot:selection="item">
                <country-flag :country='getFlagCode(item)' size='normal'/>&nbsp;&nbsp;{{item.item.text}}
            </template>   
          </v-select>
        </v-row>
        </v-card-text>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeOptions">Close</v-btn>
        <v-btn class="success" text @click="setOptions()">Set Options</v-btn>
        <v-spacer></v-spacer>
        </v-card-actions>
    </v-card>
    </v-dialog>    
      <v-snackbar v-model="showErrorSnack" :timeout="snackTimeout" :color="snackColor" :multi-line="errorText.length > 50" top :style="padTop">

          {{ errorText }}

          <template v-slot:action="{ attrs }">
            <v-btn
              color="blue"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
      </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import CountryFlag from 'vue-country-flag'
import utils from "@/common/utils.js";

  export default {
  name: 'fileUpload',
    props: {
        fileUploadData: Object,
        uploadedCallback: Function,
        uploadDisabled: Boolean,
        multiple: String,
        useTranslation: Boolean,
        displayMode: String,
        openDialogue: Boolean,
        validationFunction: Function
    },
  components: {
    CountryFlag
  },
    data(){
      return {
        filesUploading: false,
        fileUploadDialog: false,
        fileUploadOptionsDialog: false,
        uploadReady: true,
        selectdTranslateLang:"",
        uploadingDisabled: true,
        file: '',
        enableTranslate: false,
        fileUploadFormData: {},
        selectedFiles: [],
        progressInfos: [],
        message: "",
        fileInfos: [], 
        errorText: "",
        showErrorSnack:false,
        snackColor: "error",
        snackTimeout: 4000,
        allowMultipleUpload: false,
        padTop:"padding-top:0px",
        uploadPrivate: false,
        fileTypes: ".doc,.docx,.xls,.xlsx,.pdf",
        enablePrivateFlag: true,
      }
    },
    watch: { 
        fileUploadData: function(data) {
            this.setFileUploadData(data);
        },
        uploadDisabled: function(data){
          this.uploadingDisabled = data;
        },
        useTranslation: function(data){
          this.enableTranslate = data;
        },
        fileUploadDialog (val) {
            val || this.close()
        },
        fileUploadOptionsDialog (val) {
            val || this.closeOptions()
        },
        multiple: function(data){
          this.allowMultipleUpload = data == "true";
          if(this.allowMultipleUpload){
            this.padTop="padding-top:50px";
          }
        },
        openDialogue(val) {
          if (val) {
            this.initialiseUpload()
          }
        }
    },
    computed: {
      translateOptions: function () {
        return utils.translateOptions;
      },
    },
    created() {
      this.uploadingDisabled = this.uploadDisabled;
    },
    methods: {
      setOptions(){
        this.closeOptions();
      },
      getFlagCode(item){
        return item.item.flagCode;
      },
      openOptionsDialog(){
        this.fileUploadOptionsDialog = true;
      },
      closeOptions(){
          this.fileUploadOptionsDialog = false;
      },
      selectFile() {
        this.progressInfos = [];
        this.selectedFiles = [];
        let allowed = this.fileTypes.split(",");
        if(event.target.files){
          for (let i = 0; i < event.target.files.length; i++) {
            let allow = allowed.some(x => event.target.files[i].name.includes(x));
            if(allow){
              this.selectedFiles.push(event.target.files[i]);
              this.progressInfos[i] = { percentage: 0, fileName: event.target.files[i].name };
            }
          }  
        }
 
      },     
      uploadMulti(){
        this.filesUploading = true;
        let formData = new FormData();

        for( var i = 0; i < this.selectedFiles.length; i++ ){
            let file = this.selectedFiles[i];
            formData.append('files[]', file);
        }
      
        if(this.selectdTranslateLang !== "" || this.fileUploadFormData.selectdTranslateLang){
            formData.append('targetLang', this.selectdTranslateLang || this.fileUploadFormData.selectdTranslateLang);
        }

        formData.append('private', this.uploadPrivate);

        if(this.allowMultipleUpload || this.fileUploadFormData.createFiles){
          formData.append('createFiles', this.fileUploadFormData.createFiles);
        }

        if(this.fileUploadFormData.doc_type){
          formData.append('docType', this.fileUploadFormData.doc_type);
        }

        if(this.fileUploadFormData.action){
          formData.append('action', JSON.stringify(this.fileUploadFormData.action));
        }

        if(this.fileUploadFormData.hierarchies){
          formData.append('hierarchies', JSON.stringify(this.fileUploadFormData.hierarchies));
        } else {
          formData.append('hierarchies', JSON.stringify([]));
        }

        if(this.fileUploadFormData.parseDoc !== undefined){
          formData.append('parseDoc', this.fileUploadFormData.parseDoc);
        }

        if(this.fileUploadFormData.hr_ids !== undefined)
        {
          this.fileUploadFormData.hr_ids.forEach(item=> {
              formData.append("hr_id", item);
          });

        }

        if (this.fileUploadFormData.recruiter) {
          formData.append('recruiter', this.fileUploadFormData.recruiter);
        }

        if (this.fileUploadFormData.extraFields) {
          formData.append('extraFields', JSON.stringify(this.fileUploadFormData.extraFields));
        }

        if(this.fileUploadFormData.dataCaptures !== undefined){
          formData.append('dataCaptures', JSON.stringify(this.fileUploadFormData.dataCaptures));
        }

        if(this.fileUploadFormData.link_doc_id !== undefined){
          formData.append('link_doc_id', this.fileUploadFormData.link_doc_id);
        }

        if(this.fileUploadFormData.docClassifiers !== undefined){
          formData.append('docClassifiers', JSON.stringify(this.fileUploadFormData.docClassifiers));
        }

        let options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        try {
          axios.post( '/file/uploadMulti',formData, options)
          .then(resp => {

              this.filesUploading = false;
              this.uploadedCallback(resp.data);
              this.close();
          })
          .catch(err => {
              this.triggerNotification("Error uploading - " + err,"error");
              this.message = "Could not upload the file:" + err;
          });

        } catch (err) {
          console.log(err);
        }
      },
      close(){
          this.fileUploadDialog = false;
          this.$emit("close");
      },
      initialiseUpload(){
          if (this.validationFunction && !this.validationFunction()) {
            return false;
          }
          this.fileUploadDialog = true;
          let config = this.$loginState.user.settings.find(x => x.setting === "file_upload_types");
          if(config){
            this.fileTypes = config.value;
          }

          this.enablePrivateFlag = this.$loginState.user.settings.some(x => x.setting === "file_upload_use_private_flag" && x.value === "true");
      },
      triggerNotification(text, type){
          this.errorText = text;
          this.snackColor = type;
          this.showErrorSnack = true;
      },
      setFileUploadData(data){
          this.fileUploadFormData = JSON.parse(JSON.stringify(data));
          this.uploadReady = false;
          this.$nextTick(() => {
            this.uploadReady = true;
          });
          this.selectedFiles = [];
          this.progressInfos = [];
      },
    }
  }
</script>