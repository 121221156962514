<template>
  <div>
    <v-container>
      <v-row wrap v-if="!isLoading">
        <v-col lg="4" sm="12">
          <v-card>
            <v-card-text v-if="!isLoading" class="pb-0">
              <v-row wrap>
                <v-col cols="4" class="pb=0" style="font-size:20px;font-weight:500;color: rgba(0,0,0,.87)">Issues by Type</v-col>
                <v-spacer/>
                <v-col cols="4"
                    v-if="docRegions.length > 1 && divisionSelected === 'All'"
                >
                  <v-select
                    v-model="regionSelected"
                    :items="docRegions"
                    return-object
                    label="Filter by Region"
                    dense
                    @change="filterByRegionDivision()"
                    class="pb-0"
                  ></v-select>
                </v-col>
                <v-col cols="4"
                    v-if="docDivisions.length > 1 && regionSelected === 'All' && false"
                >
                  <v-select
                    v-model="divisionSelected"
                    :items="docDivisions"
                    return-object
                    :label="$loginState.user.client !== 'Akamai'? 'Filter by Org' : 'Filter by Division'"
                    dense
                    @change="filterByRegionDivision()"
                    class="pb-0"
                  ></v-select>
                </v-col>
                <v-col md="6" class="pt-0" style="margin-top:-25px;padding:0">
                  <div v-for="(issue, index) in govFilteredData.filter(i => i.issueType !== 'Parts')" :key="'issue' + index" style="font-size:19px;padding:12px 5px">
                    <div class="pb-1" style="cursor:pointer;text-shadow:1px 1px lightgrey"
                      @click="filterByType(issue.issueType)"
                    >{{issue.issueType}}</div>
                    <ul>
                      <li v-for="(cat, cIndex) in issue.categories" :key="cat.category + 'cat' + cIndex"
                        style="font-size:12px;padding:4px 0;cursor:pointer"
                        @click="filterByCat(cat.category)"
                      >
                        {{cat.category}} ({{cat.docTots}} document<span v-if="cat.docTots !== 1">s</span>
                        <span v-if="issue.issueType !== 'Status'">
                        , {{cat.totIssues}} issue<span v-if="cat.totIssues !== 1">s</span>
                        </span>)
                      </li>
                    </ul>
                  </div>
                </v-col>
                <v-col md="6" class="pt-0" style="margin-top:-25px;padding:0">
                  <div v-for="(issue, index) in govFilteredData.filter(i => i.issueType === 'Parts')" :key="'issue' + index" style="font-size:19px;padding:12px 5px">
                    <div class="pb-1" style="cursor:pointer;text-shadow:1px 1px lightgrey"
                      @click="filterByType(issue.issueType)"
                    >{{issue.issueType}}</div>
                    <ul>
                      <li v-for="(cat, cIndex) in issue.categories" :key="cat.category + 'cat' + cIndex"
                        style="font-size:12px;padding:4px 0;cursor:pointer"
                        @click="filterByCat(cat.category)"
                      >
                        {{cat.category}} ({{cat.docTots}} document<span v-if="cat.docTots !== 1">s</span>
                        <span v-if="issue.issueType !== 'Status'">
                        , {{cat.totIssues}} issue<span v-if="cat.totIssues !== 1">s</span>
                        </span>)
                      </li>
                    </ul>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="4" sm="12">
          <v-card>
            <v-card-title>Document Quality</v-card-title>
            <v-card-text>
              <v-data-table
              :headers="quHeaders"
              dense
              :items="documents"
              sort-by="quality"
              class="elevation-1"
              :footer-props="{'items-per-page-options':[8, 16, 32, 64]}"
              :items-per-page="8"
              >
                <template v-slot:[`item.reference`]="{ item }">
                  <span @click="filterByDoc(item)" style="cursor:pointer;padding:0 10px" v-html="item.reference"></span>
                </template>
                <template v-slot:[`item.docStatus`]="{ item }">
                  <span @click="filterByDoc(item)" style="cursor:pointer;padding:0 10px" v-html="item.docStatus"></span>
                </template>
                <template v-slot:[`item.sumIssues`]="{ item }">
                  <span @click="filterByDoc(item)" style="cursor:pointer;padding:0 10px" v-html="item.sumIssues"></span>
                </template>
                <template v-slot:[`item.quality`]="{ item }">
                  <span @click="filterByDoc(item)" style="cursor:pointer;padding:0 10px">{{ Math.round(item.quality*100) + '%' }}</span>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="4" sm="12">
          <v-card>
            <v-card-title>Recruiter Quality</v-card-title>
            <v-card-text>
              <v-data-table
              :headers="reHeaders"
              dense
              :items="recruiters"
              sort-by="avgQuality"
              class="elevation-1"
              :footer-props="{'items-per-page-options':[8, 16, 32, 64]}"
              :items-per-page="8"
              >
                <template v-slot:[`item.recruiter`]="{ item }">
                  <span @click="filterByRecruiter(item)" style="cursor:pointer;padding:0 10px" v-html="item.recruiter"></span>
                </template>
                <template v-slot:[`item.docTots`]="{ item }">
                  <span @click="filterByRecruiter(item)" style="cursor:pointer;padding:0 10px" v-html="item.sumDocs + '/' + (regionSelected === 'All' ? item.docTots : item.regTots)"></span>
                </template>
                <template v-slot:[`item.sumIssues`]="{ item }">
                  <span @click="filterByRecruiter(item)" style="cursor:pointer;padding:0 10px" v-html="item.sumIssues"></span>
                </template>
                <template
                  v-slot:[`item.avgQuality`]="{ item }"
                >
                  <span @click="filterByRecruiter(item)" style="cursor:pointer;padding:0 10px" v-html="Math.round(item.avgQuality*100) + '%'"></span>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="12">
          <v-card>
            <v-card-title>
              <v-row>
                <v-col cols="11">
                  Details
                  <span v-if="regionSelected !== 'All' || divisionSelected !== 'All'">(</span>
                  <span v-if="regionSelected !== 'All'"> {{regionSelected}}</span>
                  <span v-if="regionSelected !== 'All' && divisionSelected !== 'All'"> / </span>
                  <span v-if="divisionSelected !== 'All'"> {{divisionSelected}}</span>
                  <span v-if="regionSelected !== 'All' || divisionSelected !== 'All'"> )</span>
                  <span v-if="filterText"> - Filtered by {{filterText}}</span>
                  <v-icon  v-if="filterText" class="pl-2" color="red" @click="filterOff">mdi-close-circle-outline</v-icon>
                </v-col>
                <v-col cols="1">
                  <v-icon
                    @click="exportToCSV"
                    class="float-right"
                    color="green darken-2"
                    title="Download List"
                  >mdi-cloud-download</v-icon>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-data-table
              :headers="isHeaders"
              dense
              :items="filteredDetails"
              sort-by="numberOfIssues"
              sort-desc
              class="elevation-1"
              :footer-props="{'items-per-page-options':[7, 14, 28, 56]}"
              :items-per-page="7"
              @click:row="openDocument"
              >
                <template v-slot:[`item.category`]="{ item }">
                  <span v-html="item.category" style="font-size:0.7rem"></span>
                </template>
                <template v-slot:[`item.reference`]="{ item }">
                  <span v-html="item.reference" style="font-size:0.7rem"></span>
                </template>
                <template v-slot:[`item.docStatus`]="{ item }">
                  <span v-html="item.docStatus" style="font-size:0.7rem"></span>
                </template>
                <template v-slot:[`item.docName`]="{ item }">
                  <span v-html="item.docName" style="font-size:0.7rem"></span>
                </template>
                <template v-slot:[`item.issueText`]="{ item }">
                  <p style="max-width: 520px;font-size:0.6rem;">{{item.issueText}}</p>
                </template>
                <template v-slot:[`item.recruiter`]="{ item }">
                  <span v-html="item.recruiter" style="font-size:0.7rem"></span>
                </template>
                <template v-slot:[`item.createdBy`]="{ item }">
                  <span v-html="item.createdBy" style="font-size:0.7rem"></span>
                </template>
                <template v-slot:[`item.updatedDate`]="{ item }">
                  <span v-html="item.updatedDate" style="font-size:0.7rem"></span>
                </template>
                <template v-slot:[`item.numberOfIssues`]="{ item }">
                  <span v-html="item.numberOfIssues" style="font-size:0.7rem"></span>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <ResponseHandler :serviceResponse="response"></ResponseHandler>

      <Loading :isVisible="isLoading" />
      <v-dialog v-model="showDocument" fullscreen hide-overlay transition="dialog-bottom-transition">
        <DocumentFullNEW
        v-if="$loginState.user &&
          $loginState.user.settings.some(
            (s) => s.setting === 'document_viewer' && s.value.indexOf('docFull') === 0
          )
        "
          :documentId="selectedDocumentId"
          :isCopied="docCopied"
        :useInline="$loginState.user.settings.some(
            (s) => s.setting === 'document_viewer' && s.value === 'docFull'
          )"
          @copyDocument="copyDocument"
          @openDocument="openDocument"
          @close="closeDocument"
          @closeDocument="closeDocument"
          @documentHeaderSaved="documentHeaderSaved"
          @sessionExpired="sessionExpired"
        ></DocumentFullNEW>
        <DocumentFull
        v-else
          :documentId="selectedDocumentId"
          :isCopied="docCopied"
          @copyDocument="copyDocument"
          @openDocument="openDocument"
          @close="closeDocument"
          @closeDocument="closeDocument"
          @documentHeaderSaved="documentHeaderSaved"
          @sessionExpired="sessionExpired"
        ></DocumentFull>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import DocumentFullNEW from "@/components/cDocumentFullNEW";
import DocumentFull from "@/components/cDocumentFull";
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
import utils from "@/common/utils.js";

export default {
  name: "QualityGovernance",
  components: {
    ResponseHandler,
    DocumentFullNEW,
    DocumentFull
    // draggable
  },
  props: {},
  data: function() {
    return {
      response: null,
      filterType: false,
      items: [],
      isLoading: false,
      documents: [],
      docRegions: ["All"],
      regionSelected: 'All',
      docDivisions: ["All"],
      divisionSelected: 'All',
      docStatusSummary: [],
      docStatusIncluded: [],
      docTypeSummary: [],
      docTypeIncluded: [],
      tempFilterText: "",
      filterText: "",
      selDoc: "",
      isItemsPerPage: 8,
      isHeaders: [
        { text: "category", value: "category" },
        { text: "Ref", value: "reference" },
        { text: "Status", value: "docStatus" },
        { text: "Job Title", value: "docName" },
        { text: "Description", value: "issueText" },
        { text: "Recruiter", value: "recruiter" },
        { text: "Created By", value: "createdBy" },
        { text: "No of Issues", value: "numberOfIssues" },
        { text: "Last Update", value: "updatedDate"}
      ],
      quHeaders: [
        { text: "Ref", value: "reference" },
        { text: "Status", value: "docStatus" },
        { text: "Issues", value: "sumIssues" },
        { text: "Quality", value: "quality" }
      ],
      reHeaders: [
        { text: "Owner", value: "recruiter" },
        { text: "Docs", value: "docTots" },
        { text: "Issues", value: "sumIssues" },
        { text: "Quality", value: "avgQuality" }
      ],
      cvLabel1: "Job Title",
      cvLabel2: "Location",
      cvLabel3: "Cost Center",
      dialog: false,
      options: { itemsPerPage: 15 },
      genPDF: false,
      ignoreTVInputEvent: false,
      defaultStatusFilterList: "approved,pre-draft,pending,draft",
      details: [],
      filteredDetails: [],
      recruiters: [],
      showDocument: false,
      selectedDocumentId: null,
      docCopied: null,
      govData: [],
      govFilteredData: [],
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      let possibleError = false;
      axios
        .get("document/getGovernanceData/")
        .then(resp => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            this.govData = resp.data.Data;
            this.govData.sort((a,b) => a.issueType > b.issueType ? -1 : 0);
            this.prepareLists();
            this.prepareData();
            this.isLoading = false;
          }
          this.response = resp.data;
          //console.log(resp);
        })
        .catch(err => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
          this.isLoading = false;
        });
    },
    prepareLists() {
      this.govData.forEach(type => {
        type.categories.forEach(cat => {
          let regions = {};
          let divisions = {};
          cat.parts.forEach(pt => {
              if(!(pt.region in regions)) {
                regions[pt.region] = 1;
                this.docRegions.push(pt.region)                
              }
              if(!(pt.division in divisions)) {
                divisions[pt.division] = 1;
                this.docDivisions.push(pt.division)                
              }
          })
        })
      this.docDivisions.sort();
      })
    },
    prepareData() {
      this.govFilteredData = JSON.parse(JSON.stringify(this.govData));
      this.details = [];
      this.documents = [];
      let that = this;
      this.govFilteredData.forEach(type => {
        type.categories.forEach(cat => {
          let sumIssues = 0;
          let documents = [];
          cat.parts.forEach(pt => {
            if (pt.region === this.regionSelected || this.regionSelected === 'All')
            {
              sumIssues += type.issueType !== 'Status' ? +Number(pt.numberOfIssues) : 0;
              if (!that.documents.find(d => d.reference === pt.reference)) {that.documents.push(
                {
                  reference: pt.reference,
                  docType: pt.docType,
                  recruiter: pt.recruiter,
                  createdBy: pt.createdBy,
                  docTots: pt.docTots,
                  regTots: pt.regTots,
                  divTots: pt.divTots,
                  sumIssues: 0,
                  quality: 1,
                  docStatus: pt.docStatus,
                  geoLevel1: pt.geoLevel1,
                  geoLevel2: pt.geoLevel2,
                  jobLevel1: pt.jobLevel1,
                  jobLevel2: pt.jobLevel2,
                  orgLevel1: pt.orgLevel1,
                  orgLevel2: pt.orgLevel2,
                  orgLevel3: pt.orgLevel3,
                  orgLevel4: pt.orgLevel4,
                  region: pt.region,
                  country: pt.country,
                  division: pt.division,
                  subDivision: pt.subDivision
                }
              )}
              if (!documents[pt.reference]) {documents.push(
                {
                  reference:pt.reference,
                  docType: pt.docType,
                  recruiter: pt.recruiter,
                  createdBy: pt.createdBy,
                  docTots: pt.docTots,
                  regTots: pt.regTots,
                  divTots: pt.divTots,
                  sumIssues: 0,
                  quality: 1,
                  docStatus: pt.docStatus,
                  geoLevel1: pt.geoLevel1,
                  geoLevel2: pt.geoLevel2,
                  jobLevel1: pt.jobLevel1,
                  jobLevel2: pt.jobLevel2,
                  orgLevel1: pt.orgLevel1,
                  orgLevel2: pt.orgLevel2,
                  orgLevel3: pt.orgLevel3,
                  orgLevel4: pt.orgLevel4,
                  region: pt.region,
                  country: pt.country,
                  division: pt.division,
                  subDivision: pt.subDivision
                }
              )}
              let currDoc = this.documents.find(doc => doc.reference === pt.reference);
              let currCatDoc = documents.find(doc => doc.reference === pt.reference);
              if (currDoc) {
                if(cat.category === 'Blanks') {
                  currDoc.quality = currDoc.quality - (0.1 * Number(pt.numberOfIssues));
                  currDoc.sumIssues += Number(pt.numberOfIssues);
                  currCatDoc.sumIssues += Number(pt.numberOfIssues);
                } else {
                  if (type.issueType !== 'Status') {
                    currDoc.quality = currDoc.quality - (0.05 * Number(pt.numberOfIssues));
                    currDoc.sumIssues += Number(pt.numberOfIssues);
                    currCatDoc.sumIssues += Number(pt.numberOfIssues);
                  }
                }
                if(currDoc.quality < 0) {currDoc.quality = 0}
              }
              this.details.push({
                issueType: type.issueType,
                reference: pt.reference,
                docType: pt.docType,
                issueText: pt.issueText,
                category: cat.category,
                docName: pt.docName,
                docStatus: pt.docStatus,
                recruiter: pt.recruiter,
                createdBy: pt.createdBy,
                docTots: pt.docTots,
                regTots: pt.regTots,
                divTots: pt.divTots,
                numberOfIssues: pt.numberOfIssues,
                updatedDate: pt.updatedDate,
                region: pt.region,
                country: pt.country,
                division: pt.division,
                subDivision: pt.subDivision
              })
            }
          });
          cat.totIssues = sumIssues;
          cat.docTots = documents.length;
        })
        type.categories = type.categories.filter(cat => cat.docTots); // remove cats with no doc issues
      })
      this.docDivisions.sort();
      this.filteredDetails = this.details.filter(dt => dt.docStatus !== 'Deleted' && (dt.docStatus !== 'Draft' || dt.issueType !== 'In Draft'));
      this.recruiters = [];
      this.documents.forEach(doc => {
        let currRec = this.recruiters.find(rec => rec.recruiter === doc.recruiter);
        if (!currRec) {
          this.recruiters.push({
            recruiter: doc.recruiter,
            docTots: doc.docTots,
            regTots: doc.regTots,
            divDocs: doc.divTots,
            sumDocs: 0,
            sumIssues: 0,
            totQuality: 0,
            avgQuality: 1,
            recDocs: []
          })
          currRec = this.recruiters.find(rec => rec.recruiter === doc.recruiter);
          if(doc.docStatus !== 'DRAFT' && doc.sumIssues) {
            currRec.sumDocs += 1;
            currRec.recDocs.push(doc.reference);
          }
        } else {
          let recDoc = currRec.recDocs[doc.reference];
          if (!recDoc && doc.docStatus !== 'DRAFT' && doc.sumIssues) {
            let recDoc = currRec.recDocs.find(rd => rd === doc.reference);
            if(!recDoc) {
              currRec.sumDocs += 1;
              currRec.recDocs.push(doc.reference);
            }                  
          }
        }
        if(doc.docStatus !== 'DRAFT' && doc.sumIssues) {
          if(currRec.recruiter === 'Unknown') {
            console.log(currRec);
            console.log(doc)
          }
          currRec.sumIssues += +doc.sumIssues;
          currRec.totQuality += +doc.quality;
          currRec.avgQuality = (currRec.totQuality + ((this.regionSelected === 'All' ? currRec.docTots : currRec.regTots) - currRec.sumDocs))/(this.regionSelected === 'All' ? currRec.docTots : currRec.regTots);
        }
      })
    },
    filterByRegionDivision() {
      this.prepareData();
    },
    filterByType(fType) {
      this.filterText = 'Type: "' + fType + '"';
      this.filteredDetails = this.details.filter(dt => dt.issueType === fType);
    },
    filterByDoc(item) {
      this.filterText = 'Doc: "' + item.reference + '"';
      this.filteredDetails = this.details.filter(dt => dt.reference === item.reference);
    },
    filterByRecruiter(item) {
      this.filterText = 'Recruiter: "' + item.recruiter + '"';
      this.filteredDetails = this.details.filter(dt => dt.recruiter === item.recruiter);
    },
    filterByCat(fCat) {
      this.filterText = 'Category: ' + fCat;
      this.filteredDetails = this.details.filter(dt => dt.category === fCat);
    },
    filterOff() {
      this.filterText = '';
      this.filteredDetails = this.details.filter(dt => dt.docStatus !== 'Deleted');
    },
    exportToCSV() {
      let data = this.isHeaders.map(h => '"' + h.text + '"').join(",") + "\n";
      this.filteredDetails.forEach(d => {
        data +=
          this.isHeaders
            .map(h => utils.csvEscape(utils.removeTags(d[h.value])))
            .join(",") + "\n";
      });
      utils.downloadFile(data, "GovernanceList.csv", "text/csv;encoding:utf-8");
    },
    delayedApprovals() {
      if(this.filterType) { // delayed Approvals
        this.data.fromXdays = -1;
        this.data.toXdays = 45;
        this.data.status = 'PENDING_APPROVAL';
        this.selDays = 45;
        this.oldStatuses = this.docStatusIncluded;
        this.docStatusIncluded = [];
        let pendingOnly = this.docStatusSummary.find(st => st.status === 'Pending');
        this.docStatusIncluded.push(pendingOnly);
        this.fetchData();
      } else { // recently Changed
        this.data.fromXdays = -1;
        this.data.toXdays = 21;
        this.data.status = 'All';
        this.selDays = 21;
        this.docStatusIncluded = this.oldStatuses;
        this.fetchData();
      }
    },
    documentOpened(item) {
      this.recentDocs = utils.addRecentDoc(this.$loginState, item);
    },
    openDocument(doc) {
      this.selectedDocumentId = doc.reference || doc.system_number || doc.id;
      this.refDocument = null;
      this.showDocument = true;
      this.docCopied = false;
      this.documentOpened({
        system_number: doc.reference || doc.system_number || doc.id,
        doc_name: doc.docName || doc.title,
        doc_type: doc.docType
      });
    },
    closeDocument() {
      this.selectedDocumentId = null;
      this.showDocument = false;
      this.docCopied = false;
    },
    documentHeaderSaved(item) {
      if (this.notifyDocumentChange) {
        this.notifyDocumentChange(item);
      }
    },
    copyDocument(item, tmpl_id) {
      this.isLoading = true;
      let data = {
        source_doc_id: item.doc_id,
        tmpl_id: tmpl_id || item.tmpl_id
      };

      axios
        .post("document/copyDocument/", data)
        .then(resp => {
          if (resp.data.Status === "OK") {
            let doc = resp.data.Data[0][0];
            this.openDocument(doc, !doc.groupMemberAdded);
          } else {
            alert(resp.data.Message);
          }
          this.response = resp.data;
          console.log(resp);
          this.isLoading = false;
        })
        .catch(err => {
          if (err.response && err.response.status === 401) {
            this.sessionExpired(err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    sessionExpired(err) {
      console.log(err);
      this.$loginState.reEnterPassword = true;
      this.showLogin();
    },
  }
};
</script>
<style scoped>
.buttons {
  margin-top: 35px;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.dragHandle {
  color: rgba(56, 106, 163, 0.801);
  cursor: grab;
  margin-left: -40px;
  /* margin-left: 30%; */
}
.status-chip {
  font-size: 0.7rem;
  margin-left: -8px;
  height: 25px;
}
.drag-handle,
.number-part {
  max-width: 20px;
  padding: 6px 0;
}
.data-content {
  margin-top: -5px;
  padding-top: 0;
}
.data-content-2 {
  margin-top: -7px;
  min-height: auto !important;
  max-height: auto;
  padding-bottom: 0;
}
.data-label {
  font-size: 0.6rem;
  padding: 0 5px 0 16px;
  color: darkgray;
}
.data-value {
  font-size: 0.9rem;
}
.data-value-2 {
  font-size: 0.78rem;
  font-style: italic;
  padding-bottom: 10px;
}
.editIcon {
  position: relative;
  left: 870px;
  bottom: 14px;
  font-size: 1.1rem;
  color: lightgrey;
}
.editIcon2 {
  position: relative;
  left: 824px;
  bottom: 18px;
  font-size: 1.1rem;
  color: lightgrey;
}
.addIcon {
  position: relative;
  left: -40px;
  bottom: 3px;
  font-size: 1.3rem;
  color: lightgrey;
}
.workflowIcon {
  position: relative;
  left: -88px;
  bottom: 74px;
  font-size: 1.7rem;
  color: lightgrey;
}
.notesIcon {
  position: relative;
  left: 840px;
  bottom: 18px;
  font-size: 1.1rem;
  color: rgba(56, 106, 163, 0.801);
}
.pdfIcon {
  position: relative;
  left: 55px;
  top: -2px;
}
.notes {
  position: relative;
  left: 70px;
  top: -5px;
}
.editIcon:hover,
.editIcon2:hover,
.addIcon:hover,
.notesIcon:hover {
  color: black !important;
}
.errorComment {
  font-style: italic;
  font-size: 0.75rem;
  position: relative;
  left: 169px;
  min-width: 150px;
}
.errorComment2 {
  font-style: italic;
  font-size: 0.75rem;
  position: relative;
  left: 122px;
  min-width: 105px;
}
.CallToAction {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 1rem;
  margin-top: -6px;
  color: rgb(73, 80, 87);
}
.AdvertJobTitle {
  display: flex;
  justify-content: center;
  font-size: 1.8rem;
  margin-bottom: 5px;
  color: rgb(73, 80, 87);
}
.AboutTeamHdr,
.JobUSPHdr,
.ReqHdr,
.RespHdr,
.FlexibleWork,
.WithUsHdr,
.ForYouHdr,
.AboutUsHdr,
.JoinUsHdr {
  font-weight: 700;
  font-size: 1.1rem;
  padding-bottom: 0;
  color: rgb(73, 80, 87);
}
.WorkingPattern {
  line-height: 18px;
}
.AdvertJobTitleGutter,
.CallToActionGutter,
.AbouttheTeamGutter,
.JobUSPGutter,
.ResponsibilityGutter,
.RequirementGutter,
.FlexibleWorkGutter,
.WorkingPatternGutter {
  color: #ce8705ba;
}
.JobUSPHdrRow,
.RespIntroRow,
.ResponsibilityRow,
.RequirementRow,
.FlexibleWorkRow,
.FlexIntroRow,
.WorkingPatternRow,
.WithUsRow,
.ForYouRow,
.AboutUsRow,
.JoinUsRow {
  margin-top: -10px;
}
.AbouttheTeamRow,
.JobUSPRow {
  margin-top: -20px;
}
.JobUSPHdrGutter,
.RespIntroGutter,
.AboutTeamHdrGutter,
.ReqHdrGutter,
.RespHdrGutter,
.FlexIntroGutter,
.WithUsHdrGutter,
.ForYouHdrGutter,
.AboutUsHdrGutter,
.JoinUsHdrGutter,
.WithUsGutter,
.JoinUsGutter,
.AboutUsGutter,
.ForYouGutter {
  display: none !important;
}
.JobUSPHdrError,
.RespIntroError,
.AboutTeamHdrError,
.ReqHdrError,
.FlexIntroError,
.WithUsHdrError,
.ForYouHdrError,
.AboutUsHdrError,
.JoinUsHdrError {
  display: none !important;
}
.headerCell {
  padding: 10px 10px 10px 0;
}
.headerCellSmall {
  padding: 0 10px;
  font-size: 0.75rem;
  color: #946206da;
}
@media print {
  .AdvertJobTitle {
    font-size: 1.5rem !important;
  }
  .CallToAction {
    margin-top: -25px;
    font-size: 0.8rem !important;
  }
  .JobUSPHdrRow,
  .RespIntroRow,
  .WithUsRow,
  .WithUsHdr,
  .FlexIntro,
  .WorkingPattern,
  .ForYouHdr,
  .ForYouRow,
  .AboutUsHdr,
  .AboutUsRow {
    margin-top: -30px;
  }
  .list-group {
    margin-top: -30px;
  }
}
</style>
<style>
@media (max-width: 1960px) {
  .container {
    max-width:100% !important;
  }
}
div.v-treeview-node__label {
  font-size: 12px;
}
.v-treeview-node__toggle {
  max-width: 10px !important;
}
.v-treeview-node__root {
  padding: 0 !important;
  min-height: 25px !important;
}
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
  .no-print-border,
  .no-print-border * {
    border: none !important;
    box-shadow: none !important;
  }
  .printFullWidth,
  .printFullWidth * {
    max-width: 100% !important;
    flex: none;
    padding-right: 6px !important;
  }
  .v-main {
    padding-top: 0;
  }
  .mainContent {
    padding: 0 !important;
  }
}
.v-input__icon--append-outer .v-icon {
  color: grey;
  font-size: 18px;
}
</style>
