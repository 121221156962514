<template>
  <div>
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      dense
      class="no-print"
      v-if="$loginState.isAuthenticated"
    >
      <v-menu left bottom v-if="!$loginState.noAccess">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" title="Recent Documents" class="histBtn">
            <v-icon v-if="recentDocs.length">mdi-history</v-icon>
            <v-icon v-else color="grey lighten-2">mdi-history</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            @click="$emit('addDocument')"
            v-if="$loginState.user.createDocumentDialogOptions.length"
          >
            <v-list-item-icon>
              <v-icon>mdi-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Create New {{ $loginState.user.createDocumentDialogOptions.length === 1 ? $loginState.user.createDocumentDialogOptions[0].tmpl_name : 'Document' }}</v-list-item-title>
          </v-list-item>
          <v-subheader>RECENTLY VIEWED</v-subheader>
          <v-list-item
            v-for="d in recentDocs"
            :key="d.id"
            @click="$emit('openDocument', d)"
          >
            <v-list-item-icon style="margin-right: 8px">
              <img
                src="@/assets/ja-icon.png"
                style="width: 30px"
                v-if="d.type === 'Job Advert' || !d.type"
              />
              <img
                src="@/assets/jp-icon.png"
                style="width: 30px"
                v-if="d.type === 'Job Profile'"
              />
              <img
                src="@/assets/jd-icon.png"
                style="width: 30px"
                v-if="d.type === 'Job Description'"
              />
              <img
                src="@/assets/if-icon.png"
                style="width: 30px"
                v-if="d.type === 'Intake Flow'"
              />
            </v-list-item-icon>
            <v-list-item-title>{{
              d.id.substring(3, 12) + " - " + d.title
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-divider vertical />
      <v-btn
        v-if="
          $loginState.user &&
          $loginState.user.createDocumentDialogOptions.length &&
          ($route.meta.text !== 'Home' || screenName !== 'Home')
        "
        text
        :title="'Create New ' + ($loginState.user.createDocumentDialogOptions.length === 1 ? $loginState.user.createDocumentDialogOptions[0].tmpl_name : 'Document')"
        @click="$emit('addDocument')"
        class="createBtn"
      >
        Create
        <!-- <v-icon>mdi-plus</v-icon> -->
      </v-btn>
      <v-divider vertical />
      <v-menu left bottom v-if="!$loginState.noAccess && qaActions.length > 1">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" class="menuBtn">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            class="ml-0"
            link
            v-for="n in qaActions"
            :key="n.name"
            :to="n.name"
          >
            <v-list-item-title>{{ n.meta.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer" /> -->
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <span class="hidden-sm-and-down">{{
          $loginState.isAuthenticated
            ? $route.meta.text !== "Home"
              ? $route.meta.text
              : screenName
            : "RoleMapper"
        }}</span>
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-title
        class="ml-0 pr-4"
        style="font-style: italic; font-size: 14px; color: #bd6b16"
        v-if="
          $loginState.user.client !== 'Scottish Parliament' &&
          $loginState.user.client !== 'Akamai' &&
          $loginState.user.client !== 'Baker Hughes'
        "
        >{{ $loginState.user.client }}</v-toolbar-title
      >
      <v-toolbar-items class="hidden-sm-and-down">
        <!-- <v-btn text v-if="$loginState.isAuthenticated">{{$loginState.user.name}}</v-btn> -->
        <v-divider vertical />
        <v-btn v-if="$zendesk.isEnabled" :title="$zendesk.isVisible && $zendesk.isOpen ? 'Hide Help' : 'Request Help'" icon @click="$zendesk.toggle()">
          <v-icon>mdi-chat-question-outline</v-icon>
        </v-btn>
        <v-btn
          icon
          v-if="
            $loginState.user &&
            $loginState.user.settings.some(
              (s) =>
                s.setting === 'user_settings' && s.value.indexOf('allow') === 0
            )
          "
          @click.stop="$emit('openUserSettings')"
        >
          <v-icon title="Settings"> mdi-cog-outline </v-icon>
        </v-btn>
        <v-divider vertical />
        <v-btn
          text
          v-if="!$loginState.isAuthenticated"
          @click.stop="showLogin()"
          >Login</v-btn
        >
        <v-btn text v-else-if="!$loginState.sso" @click="$emit('logout')"
          >Logout</v-btn
        >
      </v-toolbar-items>
      <!-- <v-img  src="../src/assets/logo.png" aspect-ratio="1" style="background-color:whaite; max-width:200px;"/> -->
    </v-app-bar>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "HeaderOld",
  components: {},
  props: {
    recentDocs: { type: Array, required: true },
    qaActions: { type: Array, required: true },
  },
  data: function () {
    return {
    };
  },
  updated() {},
  watch: {},
  mounted() {},
  computed: {
    showWalkthrough() {
      return this.$route.name === "docswithparts" || this.$route.name === "alljobs";
    },
    ...mapState({
      screenName: (state) => state.header.screenName,
    })
  },
  methods: {},
};
</script>
<style scoped>
</style>