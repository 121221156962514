<template>
  <div>
    <v-card v-if="questionnaire_id">
      <v-card-title
        >Questionnaire Definition<v-spacer></v-spacer
        ><v-btn @click="$emit('close')">Close</v-btn></v-card-title
      >
      <v-card-text>
        <v-container v-if="questionnaire">
          <v-row>
            <v-col cols="3">
              <v-text-field
                v-model="questionnaire.questionnaireTitle"
                hint="The name to display on the data capture form"
                label="Data Capture Title"
              ></v-text-field>
            </v-col>
            <v-col cols="1"></v-col>
            <v-col cols="5">
              <v-text-field
                v-model="questionnaire.completion_text"
                label="Complete Button Text"
                hint="The text to display on the button when the form is complete"
              ></v-text-field>
            </v-col>
            <v-col cols="1"></v-col>
            <v-col cols="2"
              ><v-select
                label="Score Calculation Type:"
                v-model="questionnaire.score_calculation"
                :items="['NONE', 'SUM', 'AVERAGE']"
              ></v-select
            ></v-col>
          </v-row>
          <v-row
            v-for="(s, sIndex) in questionnaire.sections"
            :key="`s${sIndex}`"
          >
            <v-col cols="2"
              ><v-chip outlined label :color="sectionColour(s)">{{
                s.title
              }}</v-chip></v-col
            >
            <v-col>
              <v-row
                v-for="(q, qindex) in s.questions"
                :key="`s${sIndex}q${qindex}`"
              >
                <v-col cols="5" @click="editQuestion_(q)"
                  ><v-chip :color="sectionColour(q)" text-color="white" label>{{
                    q.sectionIndex + " - " + q.questionIndex
                  }}</v-chip
                  ><v-chip label v-if="q.question_category">{{
                    q.question_category
                  }}</v-chip
                  ><v-chip
                    label
                    v-if="q.scoring_question"
                    title="scoring question"
                    ><v-icon>mdi-counter</v-icon></v-chip
                  ><v-chip
                    label
                    v-if="q.scoring_question && q.display_score"
                    title="score is displayed"
                    ><v-icon>mdi-scoreboard</v-icon></v-chip
                  >
                  <span class="pl-4">{{ q.question }}</span></v-col
                >
                <v-col>
                  <v-row
                    v-for="(a, aindex) in q.answers"
                    :key="`s${sIndex}q${qindex}a${aindex}`"
                    dense
                  >
                    <v-col
                      ><div
                        style="
                          border: 1px solid gray;
                          border-radius: 5px;
                          padding: 0 5px;
                          text-align: center;
                        "
                      >
                        <span v-if="a.value">{{ a.value }}</span
                        ><v-icon v-else>mdi-text-box</v-icon>
                      </div></v-col
                    >
                    <v-col
                      cols="1"
                      v-if="questionnaire.score_calculation !== 'NONE'"
                    >
                      <v-chip label v-if="q.scoring_question">{{
                        a.score
                      }}</v-chip>
                    </v-col>
                    <v-col cols="1">
                      <v-icon v-if="!a.isEndAnswer"
                        >mdi-arrow-right-bold-outline</v-icon
                      >
                    </v-col>
                    <v-col cols="3"
                      ><v-chip
                        v-for="(nq, nqi) in a.next_questions" :key="`s${sIndex}q${qindex}a${aindex}nq${nqi}`"
                        :color="sectionColour(nq)"
                        text-color="white"
                        label class="mr-1" small
                        >{{
                          nq.sectionIndex +
                          " - " +
                          nq.questionIndex
                        }}</v-chip
                      ><v-chip v-if="a.isEndAnswer" color="red" text-color="white" label small
                        >END</v-chip
                      ></v-col
                    >
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-dialog v-model="editQuestion.show" persistent width="800">
      <v-card v-if="editQuestion.question">
        <v-card-title class="text-h5">
          <v-row>
            <v-col cols="9" v-if="editQuestion.isNew"> Add Question </v-col>
            <v-col cols="9" v-else> Edit Question </v-col>
            <v-col>
              <v-btn disabled color="primary" @click="saveQuestion"
                >Save</v-btn
              ></v-col
            >
            <v-col> <v-btn @click="closeEditQuestion">Close</v-btn></v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="9">
                <v-textarea
                  v-model="editQuestion.question.question"
                  label="Question Text"
                  outlined
                  auto-grow
                  rows="1"
                  hide-details
                ></v-textarea>
              </v-col>
              <v-col
                ><v-select
                  label="Type"
                  hide-details
                  outlined
                  :items="['SELECT', 'SWITCH', 'RADIO', 'TEXT']"
                  v-model="editQuestion.question.control_type"
                ></v-select
              ></v-col>
            </v-row>
            <v-row v-if="editQuestion.question.control_type !== 'TEXT'"
              ><v-col>
                <v-row dense>
                  <v-col cols="7"
                    ><h3>Answers</h3>
                    <v-icon
                      color="blue"
                      @click="addAnswer"
                      style="padding-left: 10px; cursor: pointer"
                      >mdi-plus-circle</v-icon
                    ></v-col
                  >
                  <v-col cols="s"></v-col>
                </v-row>
                <v-row
                  dense
                  v-for="(a, index) in editQuestion.question.answers"
                  :key="`editA_${index}`"
                >
                  <v-col cols="1" align="center" justify="center" fill-height>
                    <v-icon
                      @click="reOrder('up', index)"
                      style="margin: 0 auto; top: 25%"
                      v-if="index > 0"
                      >mdi-arrow-up-bold</v-icon
                    >
                    <v-icon
                      @click="reOrder('down', index)"
                      style="margin: 0 auto; top: 25%"
                      v-if="index != editQuestion.question.answers.length - 1"
                      >mdi-arrow-down-bold</v-icon
                    >
                  </v-col>
                  <v-col
                    ><v-textarea
                      v-model="a.value"
                      outlined
                      hide-details
                      dense
                      auto-grow
                      rows="1"
                    ></v-textarea
                  ></v-col>
                  <v-col cols="4"
                    ><v-select
                      outlined
                      :items="editQuestion.nextList"
                      dense
                      hide-details
                      label="Next Question"
                    ></v-select
                  ></v-col>
                  <v-col cols="1">
                    <v-btn
                      @click="deleteAnswer(a.questionnaire_answer_id)"
                      icon
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col></v-row
            >
            <v-row v-else
              ><v-col
                ><v-select
                  outlined
                  dense
                  hide-details
                  label="Next Question"
                ></v-select
              ></v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
// import dayjs from "dayjs";
// import ResponseHandler from "@/components/ResponseHandler";
// import utils from "@/common/utils.js";

export default {
  name: "QuestionnareDefinition",
  components: {
    // ResponseHandler,
    // UserDetail,
  },
  props: {
    questionnaire_id: Number,
  },
  data: function () {
    return {
      questionnaire: null,
      editQuestion: {
        show: false,
        isNew: false,
        question: null,
        nextList: null,
      },
    };
  },
  created() {
    this.loadData();
  },
  watch: {
    questionnaire_id: function () {
      this.loadData();
    },
  },
  methods: {
    sectionColour(item) {
      return ["teal", "indigo", "blue-grey", "blue", "brown", "purple"][
        (item.sectionIndex || 1) - 1
      ];
    },
    closeEditQuestion() {
      this.editQuestion.show = false;
    },
    editQuestion_(q) {
      this.editQuestion.show = true;
      this.editQuestion.isNew = false;
      this.editQuestion.question = q;
      let nextList = [];

      this.editQuestion.nextList = nextList;
    },
    loadData() {
      let possibleError = false;

      axios
        .get("workflow/getQuestionnaireStructure/" + this.questionnaire_id)
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            let data = resp.data.Data;
            let findQuestion = (id) => {
              let q = null;
              data.sections.some((s) => {
                q = s.questions.find((q) => q.questionnaire_question_id === id);
                return !!q;
              });
              return q;
            };
            data.sections.forEach((s) => {
              s.questions.forEach((q) => {
                q.answers
                  .filter((a) => a.next_questions?.length)
                  .forEach((a) => {
					let nqs = []; // using map cahnges order!!
                    a.next_questions.forEach(nq => nqs.push(findQuestion(
                      nq.questionnaire_question_id_next
                    )));
					a.next_questions = nqs;
                  });
              });
            });

            this.questionnaire = data;
          } else {
            this.response = resp;
          }
          //console.log(resp);
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
    addAnswer() {
      this.editQuestion.question.answers.push({
        type: "Text",
        value: "",
        order: this.editQuestion.question.answers.length + 1,
      });
    },
    reOrder(direction, idx) {
      let item = this.editQuestion.question.answers[idx];
      let currentOrder = item.order;
      if (direction === "down") {
        let next = this.editQuestion.question.answers[idx + 1];
        item.order = next.order;
        next.order = currentOrder;
      } else {
        let prev = this.editQuestion.question.answers[idx - 1];
        item.order = prev.order;
        prev.order = currentOrder;
      }
      this.editQuestion.question.answers.sort((a, b) => {
        return a.order - b.order;
      });
      this.editQuestion.question.answers = JSON.parse(
        JSON.stringify(this.editQuestion.question.answers)
      );
    },
    deleteAnswer(id) {
      let section = this.questionnaire.sections.find(
        (x) =>
          x.questionnaire_section_id === this.editQuestion.question.section_id
      );
      if (section) {
        let question = section.questions.find(
          (x) => x.questionnaire_question_id === id
        );
        if (question) {
          let idx = section.questions.indexOf(question);
          section.questions.splice(idx, 1);
        }
      }
    },
    getQuestionList() {
      this.allQuestionList = [];

      this.selectedDataCapture.sections.forEach((s) => {
        let qOrder = 0;
        s.questions.forEach((q) => {
          let aOrder = 0;
          let answers = q.answers.map((a) => {
            return {
              order: aOrder,
              value: a.value,
            };
          });
          this.allQuestionList.push({
            section: s.id,
            question: q.question,
            id: q.id,
            answers: answers,
            order: qOrder,
          });
          qOrder++;
        });
      });
    },
  },
};
</script>
<style scoped lang="scss">
</style>
