<template>
    <div>
        <v-tooltip bottom v-if="!loading && data.suggestionTpId">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs"  v-on="on"
              @click="generateSuggestions"
              class="regenBtn">
              <v-icon>refresh</v-icon>
            </v-btn>
          </template>
          <span>Generate New Suggestions</span>
        </v-tooltip>
        <v-card v-if="loading" class="d-flex text-center align-center flex-column pt-6">
            <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
                style="top: 45%"
                class="mb-3"
            ></v-progress-circular>
            <p>
                <span v-if="data.suggestionTpId" v-html="config.contentGenerationConfig.disclaimer"></span>
            </p>
        </v-card>
        <v-list v-else>
            <v-list-item
                v-for="(suggestion, index) in lookupData"
                :key="index"
                :class="{
                    lookupItem: true,
                    selected: selectedLookupIdx === index,
                }"
                @mouseover="selectedLookupIdx = index;"
                @click="setOrAppendAIContent(suggestion.varVal)"
            >
                <v-list-item-content>
                <span v-if="suggestion.title" v-text="suggestion.title"></span>
                <span v-else v-html="suggestion.marked"></span>
                </v-list-item-content>
            </v-list-item>
        </v-list>        
    </div>
</template>
    
<script>
import viewUtils from './utils';
import { mapState } from "vuex";

export default {
    name: 'AIMenu',
    props: {
        data: Object
    },  
    data: function() {
    return {
        loading: true,
        suggestionTpId: null,
        lookupMaxHeight: 300,
        lookupWidth: 500,
        lookupTop: false,
        lookupPosY: 0,
        lookupPosX: 0,
        lookupsToShow: 20,
        lookupsPageSize: 20,
        lookupData: [],
        selectedLookupIdx: -1,
        documentLanguage: { text: "English", value: "en", flagCode: "gb" },
    }
    },
    components: {
    },
    watch: {
        data(newVal){
            if(newVal){
                this.init(newVal);
            }
        }
    },  
    mounted() {

    },
    created() {
        if(this.data){
            this.init(this.data);
        }
    },
    computed: {
        ...mapState({
            config: (state) => state.settings.config
        }),
    },
    methods: {
        setOrAppendAIContent(val) {
            if (this.config.contentGenerationConfig.replaceValueWithPartSuggestion)
                this.setValue(val);
            else
                this.setValue(`${this.data.text} ${val}`);
        },
        setValue(val){
            this.$emit("setValue", val);
        },
        init(val){
            
            switch(val.function){
                case "suggestions":
                    this.generateSuggestions();
                    break;
                case "summarise":
                    this.generateSummary();
                    break;
            }

        },
        async generateSuggestions() {
            this.loading = true;

            this.isLoading = true;
            this.showLookup = true;
            const data = {
                contentType: 'templatePart',
                contentTpId: this.data.suggestionTpId,
                language: this.data.docLanguage?.text ?? "English",
                spelling: this.data.spelling,
                docId: this.data.docId,
            }
            let result = await viewUtils.generateExternalContent(data);
            this.loading = false;
            this.lookupData = result.data.Data?.content?.map(d => {
              return {
                title: d,
                varVal: d
              }
            });
        },
        async generateSummary() {
            this.loading = true;
            const data = {
                contentType: "summarise",
                language: this.data.docLanguage?.text,
                spelling: this.data.spelling,
                text: this.data.text
            }
            this.isLoading = true;
            this.showLookup = true;
            
            let result = await viewUtils.generateExternalContent(data);
            this.loading = false;
            this.lookupData = result.data.Data?.content?.map(d => {
              return {
                title: d,
                varVal: d
              }
            });

        },    
    }
}
</script>
<style scoped lang="scss">
    .regenBtn {
        position:fixed;
        z-index: 1;
        top: 0;
        right: 0;
    }
</style>