<template>
  <thead>
    <tr>
      <th
        v-for="(h, hi) in preColumns"
        :key="'prc' + hi"
        style="text-transform: uppercase"
      >
        <h3>
          {{ h }}
        </h3>
      </th>
      <th
        v-for="(h, hi) in headers"
        :key="h.value"
        style="cursor: pointer; text-transform: uppercase"
      >
        <h3 @click="setSort(h)">
          <v-icon v-if="h.value === sortColumn.name">{{
            sortColumn.descending ? "mdi-arrow-down-thin" : "mdi-arrow-up-thin"
          }}</v-icon>
          {{ h.text }}
          <v-menu
            v-if="h.filterSettings"
            v-model="h.filterSettings.show"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
            @input="openlistViewFilter(h.filterSettings)"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="blue" small v-bind="attrs" v-on="on" icon>
                <v-icon small>
                  {{
                    h.filterSettings.isActive
                      ? "mdi-filter-settings"
                      : "mdi-filter-outline"
                  }}</v-icon
                >
              </v-btn>
            </template>

            <v-card>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ h.text }} Filters</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      small
                      @click="filterClear(h.filterSettings)"
                      class="mx-2"
                    >
                      Clear
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-action>
                    <v-btn small @click="h.filterSettings.show = false">
                      Close
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-action>
                    <v-btn small @click="filterHeading(h.filterSettings)">
                      Apply
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      ><v-text-field
                        placeholder="type to filter values"
                        hide-details
                        append-icon="mdi-check-all"
                        @click:append="filterSearchAll(h.filterSettings)"
                        @input="filterSearch(h.filterSettings)"
                        dense
                        clearable
                        v-model="h.filterSettings.searchText"
                      ></v-text-field
                    ></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action v-if="h.filterSettings.pages > 1">
                    <v-pagination
                      v-model="h.filterSettings.page"
                      :length="h.filterSettings.pages"
                      :total-visible="7"
                    ></v-pagination>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <v-divider></v-divider>
              <!-- <v-list style="max-height: 100px; overflow: auto">
                <v-list-item
                  dense
                  v-for="(v, vi) in h.filterSettings.values.filter(
                    (x) => !x.visible && x.selected
                  )"
                  :key="'head' + hi + 'vs' + vi"
                >
                  <v-list-item-content>
                    <v-switch
                      v-model="v.selected"
                      color="blue"
                      :label="`${v.text} (${v.count})`"
                    ></v-switch>
                  </v-list-item-content>
                </v-list-item>
              </v-list> -->
              <v-list
                style="max-height: 400px; overflow: none"
                @wheel.stop="filterScroll($event, h.filterSettings)"
              >
                <v-list-item
                  dense
                  v-for="(v, vi) in h.filterSettings.values.filter(
                    (x) => x.visible && x.page === h.filterSettings.page
                  )"
                  :key="'head' + hi + 'v' + vi"
                >
                  <v-list-item-content>
                    <v-switch
                      v-model="v.selected"
                      color="blue"
                      :label="`${v.text} (${v.count})`"
                    ></v-switch>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </h3>
      </th>
      <th
        v-for="(h, hi) in postColumns"
        :key="'poc' + hi"
        style="text-transform: uppercase; min-width: 130px"
      >
        <h3>
          {{ h }}
        </h3>
      </th>
    </tr>
  </thead>
</template>

<script>
import utils from "@/common/utils.js";

export default {
  name: "TableColumnHeaders",
  props: {
    preColumns: [],
    dataColumns: [],
    postColumns: [],
    rows: [],
    stateKey: Number,
    presetFilters: [],
  },
  data: function () {
    return {
      utils: utils,
      show: false,
      headers: [],
      sortColumn: { name: null, descending: false },
    };
  },
  components: {},
  watch: {
    stateKey: function (newVal) {
      if (newVal) this.init();
    },
  },
  mounted() {},
  created() {
    if (this.dataColumns.length) this.init();
  },
  computed: {},
  methods: {
    init() {
      this.headers = this.dataColumns.map((c) => {
        let preset = this.presetFilters?.find((x) => x.value === c.value);
        let isActive = false;
        let isNumber = false;
        let getSort = () => {
          if (isNumber) {
            return (a, b) => (a > b ? 1 : a < b ? -1 : 0);
          } else {
            return (a, b) => {
              const at = a.text?.toLowerCase().trim() || "";
              const bt = b.text?.toLowerCase().trim() || "";
              return at > bt ? 1 : at < bt ? -1 : 0;
            };
          }
        };
        let values = this.distinctValues(this.rows, [c.value])
          .map((x, xi) => {
            let col = c.value;
            let text = x[col];
            if (text === undefined || text === null) {
              text = "";
            }
            let searchText = text;
            let selected =
              preset &&
              preset.filterSettings.values.some(
                (p) => p.selected && p.value === text
              );
            if (selected) isActive = true;
            isNumber = c.type === "Number" || typeof searchText === "number";
            return {
              text: text,
              value: text,
              selected: selected,
              visible: true,
              available: true,
              count: x._count,
              searchValues: [
                isNumber ? searchText || 0 : searchText.toLowerCase(),
              ],
              page: parseInt(xi / 7) + 1,
            };
          })
          .sort(getSort());
        return {
          text: c.text,
          value: c.value,
          filterSettings: {
            show: false,
            column: c.value,
            values: values,
            isActive: isActive,
            mostRecent: false,
            searchText: "",
            page: 1,
            pages: parseInt((values.length - 1) / 7) + 1,
          },
        };
      });
    },
    setSort(column) {
      if (this.sortColumn.name !== column.value) {
        this.sortColumn.name = column.value;
        this.sortColumn.isNumber = column.type === "Number";
        this.sortColumn.descending = false;
      } else {
        this.sortColumn.descending = !this.sortColumn.descending;
      }
      this.$emit("sortby", this.sortColumn);
    },
    openlistViewFilter(filterSettings) {
      if (!filterSettings.show) {
        filterSettings.values
          .filter((v) => v.selected && !v.applied)
          .forEach((v) => (v.selected = false));
        filterSettings.values
          .filter((v) => !v.selected && v.applied)
          .forEach((v) => (v.selected = true));
        return;
      }
      filterSettings.values
        .filter((v) => !v.selected && v.applied)
        .forEach((v) => (v.applied = false));
      if (filterSettings.mostRecent) return;
      let usedValues = this.distinctValues(this.rows, [
        filterSettings.column,
      ]).map((x) => x[filterSettings.column]);
      let valueCount = 0;
      if (
        usedValues.length === filterSettings.values.length ||
        (this.activeFilterCount === 1 && filterSettings.isActive)
      ) {
        valueCount = filterSettings.values.length;
        filterSettings.values.forEach((x, xi) => {
          x.visible = true;
          x.page = parseInt(xi / 7) + 1;
        });
      } else {
        filterSettings.values.forEach((v) => {
          v.visible = usedValues.some((uv) => uv === v.text);
          v.page = v.visible ? parseInt(valueCount / 7) + 1 : 0;
          if (v.visible) valueCount++;
        });
      }
      filterSettings.pages = parseInt((valueCount - 1) / 7) + 1;
      filterSettings.page = 1;
    },
    filterScroll(data, filterSettings) {
      if (data) {
        let page = filterSettings.page + (data.deltaY >= 0 ? 1 : -1);
        if (page > filterSettings.pages || page < 1) return;
        filterSettings.page = page;
      }
    },
    setFilterValue(item, column) {
      if (column && column.filterSettings) {
        let value = item[column.value];
        column.filterSettings.values.forEach((x) => {
          x.selected = x.value === value;
        });
        this.filterHeading(column.filterSettings);
      }
    },
    filterHeading(filterSettings) {
      filterSettings.values
        .filter((v) => v.selected)
        .forEach((v) => (v.applied = true));
      filterSettings.isActive = filterSettings.values.some((x) => x.selected);
      filterSettings.show = false;
      let activeCount = 0;
      this.headers
        .filter((c) => c.filterSettings)
        .forEach((c) => {
          c.filterSettings.mostRecent = false;
          if (c.filterSettings.isActive) activeCount++;
        });
      filterSettings.mostRecent = filterSettings.isActive;
      this.activeFilterCount = activeCount;
      this.$emit("filterApplied", this.headers);
    },
    filterSearchAll(filterSettings) {
      if (filterSettings.searchText) {
        let newVal = !filterSettings.values.every(
          (x) => !x.visible || x.selected
        );
        filterSettings.values.forEach((x) => {
          if (x.visible || !newVal) x.selected = newVal;
        });
      } else {
        let newVal = !filterSettings.values.every((x) => x.selected);
        filterSettings.values.forEach((x) => {
          x.selected = newVal;
        });
      }
    },
    filterSearch(filterSettings) {
      let index = 0;
      let search = (filterSettings.searchText || "")
        .toLowerCase()
        .split(" ")
        .filter((x) => x);
      filterSettings.values.forEach((x) => {
        const visible =
          !search.length ||
          search.every((s) => x.searchValues.some((v) => v.indexOf(s) >= 0));
        x.visible = visible;
        if (visible) {
          x.page = parseInt(index / 7) + 1;
          index++;
        } else {
          x.page = 0;
        }
      });
      filterSettings.pages = parseInt((index - 1) / 7) + 1;
      filterSettings.page = 1;
    },
    filterClear(filterSettings) {
      filterSettings.searchText = "";
      filterSettings.values.forEach((x, xi) => {
        x.visible = true;
        x.selected = false;
        x.applied = false;
        x.page = parseInt(xi / 7) + 1;
      });
      filterSettings.pages =
        parseInt((filterSettings.values.length - 1) / 7) + 1;
      this.filterHeading(filterSettings);
    },
    distinctValues: function (arr, keys) {
      let list = [];
      arr.forEach(function (item) {
        let row = list.filter(function (li) {
          let match = true;
          keys.forEach(function (k) {
            if (li[k] !== item[k]) match = false;
          });
          return match;
        })[0];
        if (!row) {
          row = { _count: 1 };
          keys.forEach(function (k) {
            row[k] = item[k];
          });
          list.push(row);
        } else {
          row._count++;
        }
      });
      return list;
    },
  },
};
</script>
<style>
</style>