<template>
  <v-menu
    ref="menu"
    v-model="dateRangeMenu"
    :close-on-content-click="false"
    :return-value.sync="dateRange"
    transition="slide-y-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateRangeText"
        :label="label"
        dense
        hide-details
        readonly
        outlined
        v-bind="attrs"
        v-on="on"
        :append-icon="dateRangeText ? 'mdi-close' : ''"
        @click:append="clearDateFilter"
      >
      </v-text-field>
    </template>
    <v-date-picker v-model="dateRange" no-title scrollable range>
      <v-btn text @click="clearDateFilter"> Clear </v-btn>
      <v-btn text @click="dateRangeMenu = false"> Cancel </v-btn>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="doDateFilter"> OK </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "DateRangePicker",
  props: {
    DateRange: [],
    label: String,
  },
  data: function () {
    return {
      dateRangeMenu: false,
      dateRange: [],
    };
  },
  components: {},
  created() {
    //   this.dateRange = this.DateRange.map(x => x);
  },
  watch: {
    DateRange(newVal) {
      this.dateRange = newVal;
    },
  },
  computed: {
    dateRangeText() {
      const range = this.dateRange;
      if (!range.length) return "";
      if (range.length == 1) return dayjs(range[0]).format("DD MMM YYYY");

      return range
        .map((d) => {
          return dayjs(d).format("DD MMM");
        })
        .join(" - ");
    },
  },
  methods: {
    clearDateFilter() {
      this.dateRange.splice(0);
      this.dateRangeMenu = false;
      this.$emit("updated", this.dateRange);
    },
    doDateFilter() {
      this.$refs.menu.save(this.dateRange);
      this.$emit("updated", this.dateRange);
    },
  },
};
</script>
<style scoped lang="scss">
</style>