import axios from 'axios';
//import dayjs from "dayjs";

export const state = () => ({
	initialised: false,
	isLoading: false,
	taskList: [],
	listUpdated: null,
})

export const mutations = {
	initialised(state) {
		state.initialised = true;
	},
	loading(state) {
		state.isLoading = true;
	},
	loaded(state) {
		state.isLoading = false;
	},
	clear(state) {
		state.taskList.splice(0);
		state.listUpdated = null;
	},
	unload(state) {
		state.initialised = false;
		mutations.clear(state);
	},
	replace(state, data) {
		mutations.clear(state);
		state.taskList = data;
	},
	remove(state, task) {
		state.taskList = state.taskList.filter(x => x.document_task_id !== task.document_task_id);
		state.listUpdated = new Date();
	},
	update(state, task) {
		let index = state.taskList.findIndex(x => x.document_task_id !== task.document_task_id);
		if (index >= 0) {
			state.taskList.splice(index, 1, task);
		} else {
			state.taskList.push(task);
		}
		state.listUpdated = new Date();
		state.isLoading = false;
	},
}

let refreshTimeout;

export const actions = {
	init({ commit, getters }) {
		if (!getters.isInitialised) {
			commit("initialised");
			actions.getOpenTasks({ commit, getters });
		}
	},
	unload({ commit, getters }) {
		if (getters.isInitialised) {
			if (refreshTimeout)
				clearTimeout(refreshTimeout);
			commit("unload");
		}
	},
	refresh({ commit, getters }, doc_id) {
		if (!getters.isInitialised || getters.isLoading) {
			return;
		}
		if (refreshTimeout)
			clearTimeout(refreshTimeout);
		actions.getOpenTasks({ commit, getters }, doc_id);
	},
	getOpenTasks({ commit, getters }) {
		if (!getters.isInitialised) return;
		commit('loading');
		axios
			.get("workflow/tasklist/")
			.then(resp => {
				if (resp.data.Status === "OK") {
					resp.data.Data.forEach((d) => {
						d.match =
							`${d.reference} ${d.doc_name} ${d.doc_type} ${d.doc_status_text} ${d.task_status} ${d.task_type} `
								.toLowerCase()
								.split(" ")
								.filter((x) => x.length)
								.reduce((p, c) => p + c + "|", "|");
					});
					commit('replace', resp.data.Data);

					//refresh every 5 mins
					refreshTimeout = setTimeout(() => actions.getOpenTasks({ commit, getters }), 300000);
				} else {
					this.$emit("sessionExpired", resp.data.message);
					this.errMessage = resp.data.message;
				}
				commit('loaded');
			})
			.catch((err) => {
				if (err.response && err.response.status === 401) {
					commit("unload");
				} else {
					//try again in 30 secs
					refreshTimeout = setTimeout(() => actions.getOpenTasks({ commit, getters }), 30000);
				}
				console.log(err);
			});
	},
	clearAllTasks({ commit }) {
		commit('replace', []);
	},
}

export const getters = {
	isInitialised: (state) => {
		return state.initialised;
	},
	isLoading: (state) => {
		return state.isLoading;
	},
	list: (state) => {
		return state.taskList;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}