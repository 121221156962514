import axios from "axios";

export const state = () => ({
	initialised: false,
	loading: false,
	list: [],
});

export const mutations = {
	initialised(state) {
		state.initialised = true;
		state.loading = true;
	},
	clear(state) {
		state.list.splice(0);
	},
	loading(state) {
		state.loading = true;
	},
	unload(state) {
		state.initialised = false;
		state.loading = false;
		mutations.clear(state);
	},
	replace(state, data) {
		mutations.clear(state);
		state.list = data;
		state.loading = false;
	}
};

export const actions = {
	init({ commit, getters }) {
		if (!getters.isInitialised) {
			commit("initialised");
			actions.getFiles({ commit, getters });
		}
	},
	unload({ commit }) {
		commit("unload");
	},
	reload({ commit, getters }) {
		commit("loading");
		actions.getFiles({ commit, getters });
	},
	getFiles({ commit, getters }) {
		if (!getters.isInitialised) return;

		axios
			.get("file/plist/")
			.then((resp) => {
				commit("replace", resp.data);
			})
			.catch((err) => {
				console.log("fetch error");
				if (err.response && err.response.status === 401) {
					commit("unload");
				} else {
					//try again in 30 secs
					setTimeout(() => actions.getFiles({ commit, getters }), 300 * 1000);
				}
				console.log(err);
			});
	},
};

export const getters = {
	isInitialised: (state) => {
		return state.initialised;
	},
	list: (state) => {
		return state.list;
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
