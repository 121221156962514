<template>
  <v-container fluid class="px-6">
    <v-expansion-panels v-if="sourceTemplate" v-model="activePanel">
      <v-expansion-panel v-for="(link, i) in templateLinks" :key="i" class="px-4 py-2">
        <v-expansion-panel-header>
          <v-row>
            <v-col class="text-subtitle-1">
              {{ sourceTemplate.tmpl_name }}s can have
              <b>{{ link.maximum == 0 ? "any number of" : `a maximum of ${link.maximum}` }}</b>
              {{ link.template ? `${link.template.tmpl_name}s` : "..." }}
            </v-col>
            <v-col class="text-caption mt-1">
              {{ link.notes }}
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pb-6">
          <v-row>
            <v-col cols="3">
              <v-select
                v-if="!link.tl_id"
                label="Linked Template"
                :items="templates"
                item-text="tmpl_name"
                return-object
                v-model="link.template"
                :error="!link.template"
              >
              </v-select>
              <div v-else>
                <span class="caption">Linked Template</span>
                <p>
                  {{ link.template.tmpl_name }}
                </p>
              </div>
            </v-col>
            <v-col cols="5" class="d-flex align-center justify-space-around">
              <v-text-field type="number" label="Maximum" v-model="link.maximum" style="max-width: 60px"></v-text-field>
              <v-switch label="Essential Only" v-model="link.essential_only"></v-switch>
            </v-col>
            <v-col cols="4">
              <v-text-field label="Notes" v-model="link.notes">
              </v-text-field>
            </v-col>
          </v-row>
          <div class="copyrules">
            <h5>Copy Override Rules <v-btn color="primary" icon @click="addRule(link)" class="mr-2"><v-icon>mdi-plus-circle</v-icon></v-btn></h5>
            <v-simple-table v-if="link.rules.length !== 0">
              <thead>
                <tr>
                  <th>Destination Part</th>
                  <th>Rule</th>
                  <th>Lock Resultant Parts</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(rule, pi) in link.rules" :key="pi">
                  <td>{{ rule.dest_tp.tp_name }}</td>

                  <td v-if="rule.type === 'IGNORE'">Ignore </td>
                  <td v-else-if="rule.type === 'USE_DEFAULT'"> Use default text </td>
                  <td v-else-if="rule.type === 'COPY_FROM'"> Copy from: {{ rule.source_tp ? rule.source_tp.tp_name : "" }}</td>
                  <td v-else-if="rule.type === 'MERGE_PARTS'"> Merge parts: {{ rule.mergeParts.filter(mp => mp.source_tp).map(mp => mp.source_tp.tp_name).join('; ') }} </td>
                  <td v-else></td>

                  <td>{{ rule.lock_resultant_parts ? "True" : "False" }}</td>

                  <td class="text-right">
                    <v-btn icon @click="editRule(link, rule)"><v-icon>mdi-pencil</v-icon></v-btn>
                    <v-btn icon @click="deleteRule(link, rule)"><v-icon>mdi-delete</v-icon></v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
          <v-row>
            <v-col class="d-flex align-center justify-end">
              <v-btn color="red" outlined @click="deleteLink(link)" class="mr-2">Delete</v-btn>
              <v-btn color="primary" @click="saveLink(link)" :disabled="!link.template">Save</v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-row class="mt-4">
      <v-col class="text-right">
        <v-btn color="primary" outlined @click="addLink">Add New</v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="showEditRule" max-width="1000px">
      <v-card>
        <v-card-title>
          Edit Rule
          <v-spacer />
          <v-btn icon @click="showEditRule = false"
            ><v-icon>close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text v-if="copyRule && templateLink">
          <v-row>
            <v-col cols="3">
              <v-select
                label="Destination Part"
                :items="templateLink.template.parts"
                item-text="tp_name"
                return-object
                v-model="copyRule.dest_tp"
                :error="!copyRule.dest_tp"
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-select
                label="Rule Type"
                :items="ruleTypes"
                item-text="text"
                item-value="value"
                v-model="copyRule.type"
                :error="!copyRule.type"
              ></v-select>
            </v-col>
            <v-col cols="3" v-if="copyRule.type === 'COPY_FROM'">
              <v-select
                label="Source Part"
                :items="sourceTemplate.parts"
                item-text="tp_name"
                return-object
                v-model="copyRule.source_tp"
                :error="!copyRule.source_tp"
              ></v-select>
            </v-col>
            <v-col cols="3" class="d-flex align-center">
              <v-switch
                label="Lock Resultant Parts"
                v-model="copyRule.lock_resultant_parts">
              </v-switch>
            </v-col>
            <v-col cols="6" v-if="copyRule.type === 'MERGE_PARTS'">
              <h4>Source Parts <v-btn color="primary" icon @click="addMergePart"><v-icon>mdi-plus-circle</v-icon></v-btn></h4>
              <v-list>
                <v-divider></v-divider>
                <Draggable
                  v-model="copyRule.mergeParts"
                  draghandle=".draghandle"
                  >
                  <div v-for="(mp, ai) in copyRule.mergeParts" :key="'mp' + ai">
                    <v-list-item v-show="!mp.deleted">
                      <v-list-item-icon class="draghandle pt-2">
                        <v-icon>mdi-drag</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-select
                            label="Source Part"
                            :items="sourceTemplate.parts"
                            item-text="tp_name"
                            return-object
                            v-model="mp.source_tp"
                            hide-details
                            :error="!mp.source_tp"
                          ></v-select>
                        </v-list-item-title>
                        <!-- <v-list-item-subtitle v-text="attr.default_text || attr.placeholder"></v-list-item-subtitle> -->
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon @click="deleteMergePart(mp)"><v-icon>mdi-delete</v-icon></v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider
                      v-show="!mp.deleted"
                      :key="'ad' + ai"
                    ></v-divider>
                  </div>
                </Draggable>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions v-if="copyRule" class="d-flex justify-end pr-6 pb-4">
          <v-btn color="primary" @click="saveRule" :disabled="!saveRuleEnabled">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Loading :isVisible="isLoading" />
    <ResponseHandler :serviceResponse="response"></ResponseHandler>
  </v-container>
</template>

<script>
import axios from "axios";
import ResponseHandler from "@/components/ResponseHandler";
import Draggable from "vuedraggable";

export default {
  name: "cTemplateBuilderLinks",
  components: {
    Draggable,
    ResponseHandler,
  },
  props: {
    value: { type: Number, required: false },
  },
  data: function () {
    return {
      isLoading: false,
      response: null,
      activePanel: null,
      templateLinks: [],
      templates: [],
      sourceTemplate: null,
      templateLink: null,
      copyRule: null,
      showEditRule: false,
      ruleTypes: [
        { value: "COPY_FROM", text: "Copy from" },
        { value: "IGNORE", text: "Ignore" },
        { value: "USE_DEFAULT", text: "Use default text" },
        { value: "MERGE_PARTS", text: "Merge parts" },
      ]
    };
  },
  mounted() {},
  created() {
    if (!this.hierarchiesLoading) this.init();
  },
  watch: {
    value() {
      this.init();
    },
  },
  computed: {
    saveRuleEnabled() {
      if (!this.copyRule)
        return false;

      if (!this.copyRule.dest_tp || !this.copyRule.type)
        return false;

      if (this.copyRule.type === "COPY_FROM" && !this.copyRule.source_tp)
        return false;

      if (this.copyRule.type === "MERGE_PARTS" && (
        this.copyRule.mergeParts.some(mp => !mp.source_tp) || !this.copyRule.mergeParts.some(mp => mp.source_tp)))
        return false;

      return true;
    }
  },
  methods: {
    sessionExpired(err) {
      this.$emit("sessionExpired", err);
    },
    init() {
      let possibleError = false;
      this.isLoading = true;
      axios
        .get("templateBuilder/templateLinksDetails/" + this.value)
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            const data = resp.data.Data;
            const links = data.links;
            this.templates = data.templates;
            this.sourceTemplate = this.templates.find(t => t.tmpl_id === this.value);

            links.forEach(l => {
              l.template = this.templates.find(t => t.tmpl_id === l.tmpl_id_linked) || {};
              l.rules.forEach(r => {
                r.dest_tp = l.template.parts.find(tp => tp.tp_id === r.tp_id);
                r.source_tp = r.source_tp_id ? this.sourceTemplate.parts.find(tp => tp.tp_id === r.source_tp_id) : null;
                r.mergeParts.forEach(mp => {
                  mp.source_tp = mp.source_tp_id ? this.sourceTemplate.parts.find(tp => tp.tp_id === mp.source_tp_id) : null;
                  return mp;
                })
              })
            });

            this.templateLinks = links;
            
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
    editRule(link, rule) {
      this.templateLink = link;
      this.copyRule = rule;
      this.showEditRule = true;
    },
    addMergePart() {
      this.copyRule.mergeParts.push({
        mp_id: null,
        source_tp: null
      })
    },
    addLink() {
      this.templateLinks.push({
        tl_id: null,
        template: null,
        maximum: 0,
        essential_only: 0,
        notes: "",
        rules: [],
      });
      this.activePanel = this.templateLinks.length - 1;
    },
    saveLink(link, callback) {
      if (!link.template)
        return;

      const request = {
        tl_id: link.tl_id,
        tmpl_id_master: this.value,
        tmpl_id_linked: link.template.tmpl_id,
        maximum: link.maximum,
        essential_only: link.essential_only,
        notes: link.notes
      }
      let possibleError = false;
      this.isLoading = true;
      axios
        .post("templateBuilder/saveTemplateLink", request)
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            link.tl_id = resp.data.Data.tl_id;
            if (callback && typeof(callback) === "function")
              callback();
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
    deleteLink(link) {
      if (confirm(`Remove the ability to create ${link.template.tmpl_name}s from ${this.sourceTemplate.tmpl_name}s?`)) {
        const request = {
          tl_id: link.tl_id
        }
        let possibleError = false;
        this.isLoading = true;
        axios
          .post("templateBuilder/deleteTemplateLink", request)
          .then(() => {
            possibleError = true;
            const idx = this.templateLinks.indexOf(link);
            if (idx >= 0)
              this.templateLinks.splice(idx, 1);
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            if (possibleError) {
              alert("Code Error");
            } else if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              alert(err.response ? err.response.data.message : err);
            }
            console.log(err);
          });
      }
    },
    addRule(link) {
      const vm = this;
      const callback = function() {
        vm.templateLink = link;
        vm.copyRule = {
          tcr_id: null,
          dest_tp: null,
          source_tp: null,
          notes: "",
          type: "",
          mergeParts: [],
          lock_resultant_parts: 0
        }
        vm.showEditRule = true;
      }
      if (link.tl_id)
        callback();
      else
        this.saveLink(link, callback);
    },
    saveRule() {
      if (!this.saveRuleEnabled)
        return;

      const request = {
        tl_id: this.templateLink.tl_id,
        tcr_id: this.copyRule.tcr_id,
        type: this.copyRule.type,
        tp_id: this.copyRule.dest_tp.tp_id,
        source_tp_id: this.copyRule.source_tp ? this.copyRule.source_tp.tp_id : null,
        lock_resultant_parts: this.copyRule.lock_resultant_parts,
        mergeParts: this.copyRule.mergeParts.filter(mp => mp.source_tp)
          .map((mp, i) => {
            return {
              mp_id: mp.mp_id,
              source_tp_id: mp.source_tp.tp_id,
              part_order: i + 1
            };
          })
      }
      const isNew = this.templateLink.rules.indexOf(this.copyRule) < 0;
      let possibleError = false;
      this.isLoading = true;
      axios
        .post("templateBuilder/saveCopyRule", request)
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            this.copyRule.tcr_id = resp.data.Data.tcr_id;
            this.copyRule.mergeParts.splice(0);
            this.copyRule.mergeParts.push(...resp.data.Data.mergeParts.map(mp => {
              mp.source_tp = mp.source_tp_id ? this.sourceTemplate.parts.find(tp => tp.tp_id === mp.source_tp_id) : null;
              return mp;
            }));
            if (isNew) {
              this.templateLink.rules.push(this.copyRule);
            }
            this.showEditRule = false;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
    deleteRule(templateLink, copyRule) {
      if (confirm("Delete copy rule?")) {
        const request = {
          tl_id: templateLink.tl_id,
          tcr_id: copyRule.tcr_id,
          mergeParts: copyRule.mergeParts.filter(mp => mp.mp_id)
            .map((mp) => {
              return {
                mp_id: mp.mp_id
              };
            })
        }

        let possibleError = false;
        this.isLoading = true;
        axios
          .post("templateBuilder/deleteCopyRule", request)
          .then((resp) => {
            possibleError = true;
            if (resp.data.Status === "OK") {
              const idx = templateLink.rules.indexOf(copyRule);
              if (idx >= 0)
                templateLink.rules.splice(idx, 1);
            }
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            if (possibleError) {
              alert("Code Error");
            } else if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              alert(err.response ? err.response.data.message : err);
            }
            console.log(err);
          });
      }
    },
    deleteMergePart(mp) {
      const idx = this.copyRule.mergeParts.indexOf(mp);
      if (idx >= 0)
        this.copyRule.mergeParts.splice(idx, 1);
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";

.draghandle {
  cursor: move;
}

.copyrules {
  max-width: 800px;
  margin: 0 auto;
}
</style>