var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.doc)?_c('div',{staticStyle:{"display":"inline-table"}},[(_vm.doc.pending_status_addendum)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-badge',{attrs:{"content":_vm.doc.pending_status_addendum,"value":_vm.doc.pending_status_addendum,"color":"blue","overlap":"","bordered":"","offset-x":_vm.sideAddendum ? 10 : null,"offset-y":_vm.sideAddendum ? 20 : null}},[_c('v-chip',_vm._g(_vm._b({staticClass:"status-chip",attrs:{"dense":"","small":!_vm.xSmall,"x-small":_vm.xSmall,"label":"","color":_vm.utils.resolveHeaderStyle(_vm.doc)},on:{"click":function($event){return _vm.$emit('click')}}},'v-chip',attrs,false),on),[_vm._v(_vm._s(_vm.utils.resolveStatusChipText(_vm.doc)))])],1)]}}],null,false,3822163587)},[_c('v-container',{style:({
        minWidth:
          _vm.doc.lifecycle_status_non_responders &&
          _vm.doc.lifecycle_status_responders
            ? '400px'
            : '200px',
      })},[_c('v-row',[(_vm.doc.lifecycle_status_non_responders)?_c('v-col',[_c('h3',[_vm._v("Waiting for:")])]):_vm._e(),(_vm.doc.lifecycle_status_responders)?_c('v-col',[_c('h3',[_vm._v("Completed by:")])]):_vm._e()],1),_c('v-row',[(_vm.doc.lifecycle_status_non_responders)?_c('v-col',_vm._l((_vm.doc.lifecycle_status_non_responders.split(',')),function(r){return _c('v-row',{key:r,attrs:{"dense":""}},[_c('v-col',[_vm._v(_vm._s(r))])],1)}),1):_vm._e(),(_vm.doc.lifecycle_status_responders)?_c('v-col',_vm._l((_vm.doc.lifecycle_status_responders.split(',')),function(r){return _c('v-row',{key:r,attrs:{"dense":""}},[_c('v-col',[_vm._v(_vm._s(r))])],1)}),1):_vm._e()],1)],1)],1):_c('v-chip',{staticClass:"status-chip",attrs:{"dense":"","small":!_vm.xSmall,"x-small":_vm.xSmall,"label":"","color":_vm.utils.resolveHeaderStyle(_vm.doc)},on:{"click":function($event){return _vm.$emit('click')}}},[_vm._v(_vm._s(_vm.utils.resolveStatusChipText(_vm.doc)))])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }