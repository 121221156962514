<template>
    <v-snackbar
        v-model="showErrorSnack"
        :timeout="snackTimeout"
        :color="snackColor"
        :multi-line="errorText.length > 50"
        top
    >
    <span v-html="errorText"></span>

    <template v-slot:action="{ attrs }">
        <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="showErrorSnack = false"
        >
            Close
        </v-btn>
    </template>
    </v-snackbar>
</template>
<script>

export default {
  name: 'SnackBarNotification',
  props: {
    notification: Object
  },  
  data: function() {
    return {
      errorText: "",
      showErrorSnack: false,
      snackColor: "error",
      snackTimeout: 4000,
    }
  },
  components: {

  },
  watch: {
    notification(val){
        this.triggerNotification(val.text, val.type, val.timeout);
    }
  },  
  mounted() {

  },
  created() {

  },
  computed: {

  },
  methods: { 
    triggerNotification(text, type, timeout) {
      this.errorText = text;
      this.snackColor = type;
      this.snackTimeout = timeout || 4000;
      this.showErrorSnack = true;
    },
  }
}
</script>
<style>
</style>