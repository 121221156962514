<template>
  <div v-resize="calcHeaderSpace">
    <v-container
      v-if="docLoaded"
      fluid
      class="doccontainer"
      :class="{ containerNoPad: newView }"
    >
      <v-app-bar fixed ref="appbar">
        <input type="hidden" id="document_type" :value="document.doc_type" />
        <input
          type="hidden"
          id="document_status"
          :value="document.doc_status"
        />
        <input
          type="hidden"
          id="document_status_text"
          :value="document.doc_status_text"
        />
        <div ref="appbar-close" class="d-flex align-center">
          <v-btn text @click="closeDocument()" title="Close Document">
            <v-icon left>mdi-arrow-left</v-icon><span>Back</span>
          </v-btn>
          <v-divider
            vertical
            :class="$refs['appbar-buttons']?.isOverflowing ? 'mr-0' : 'mr-3'"
          />
        </div>
        <v-sheet :max-width="headerSettings.buttonsMaxWidth" class="mx-auto">
          <v-slide-group ref="appbar-buttons" :show-arrows="true">
            <v-slide-item v-slot="{ toggle }">
              <div>
                <v-tooltip bottom :disabled="!isCopyActionDisabled(false)">
                  <template v-slot:activator="{ on, attrs }"
                    ><div v-on="on">
                      <v-btn
                        v-bind="attrs"
                        v-if="
                          canCopySame &&
                          !hasLinks &&
                          (!canCopyOther || document.doc_status === 'DELETED')
                        "
                        outlined
                        color="primary"
                        :disabled="isCopyActionDisabled(false)"
                        @click="
                          toggle;
                          copyDocument();
                        "
                        :class="[
                          'userPilot' + document.doc_type_abbrev,
                          'userPilot' +
                            document.doc_status_text.replace(/\s+/g, ''),
                        ]"
                      >
                        <span>Copy</span>
                      </v-btn>
                    </div></template
                  >
                  <span>{{ isCopyActionDisabled(true) }}</span>
                </v-tooltip>
              </div>
            </v-slide-item>
            <v-slide-item v-slot="{ toggle }">
              <div>
                <v-menu
                  v-if="
                    (canCopyOther || hasLinks) &&
                    document.doc_status !== 'DELETED' &&
                    !showWizard
                  "
                  v-model="copymenu"
                  :close-on-content-click="false"
                  offset-y
                  @click="toggle"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      :class="[
                        'userPilot' + document.doc_type_abbrev,
                        'userPilot' +
                          document.doc_status_text.replace(/\s+/g, ''),
                        'userPilotCreate',
                      ]"
                    >
                      <span>
                        {{
                          !docTypes.some((dt) => dt.canCreate)
                            ? "Links"
                            : "Create"
                        }}</span
                      >
                      <v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>

                  <v-list dense>
                    <template v-if="canCopySame">
                      <v-tooltip
                        bottom
                        :disabled="!isCopyActionDisabled(false)"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div v-on="on">
                            <v-list-item
                              v-bind="attrs"
                              @click="linkDocumentAction(null, 'copy')"
                              :disabled="isCopyActionDisabled(false)"
                            >
                              <v-list-item-icon>
                                <v-icon>mdi-content-copy</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>
                                Copy {{ linkDef.tmpl_name }}
                              </v-list-item-title>
                            </v-list-item>
                          </div>
                        </template>
                        <span>{{ isCopyActionDisabled(true) }}</span>
                      </v-tooltip></template
                    >

                    <v-list-item
                      class="subtitle-1"
                      v-if="
                        docTypes.filter((x) => x.canCreate || x.docs.length > 0)
                          .length === 0
                      "
                    >
                      <v-list-item-title>
                        No Linked Documents
                      </v-list-item-title>
                    </v-list-item>
                    <div
                      v-for="(dt, dti) in docTypes.filter(
                        (x) =>
                          x.tmpl_id != document.tmpl_id &&
                          (x.canCreate || x.docs.length > 0)
                      )"
                      :key="'dt_' + dti"
                      class="userPilotCreateJAExpand"
                      dense
                    >
                      <v-list-item
                        v-if="dt.docs.length === 1 && !dt.canCreate"
                        @click="linkDocumentAction(dt.docs[0], 'view')"
                      >
                        <v-list-item-icon>
                          <v-icon>mdi-text-box-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          {{ dt.tmpl_name }} {{ dt.docs[0].reference }}
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-group
                        offset-y
                        v-if="
                          dt.docs.length > 1 ||
                          (dt.docs.length > 0 && dt.canCreate)
                        "
                        prepend-icon="mdi-folder-outline"
                      >
                        <template v-slot:activator>
                          <v-list-item-title
                            >{{ dt.tmpl_name }}s</v-list-item-title
                          >
                        </template>

                        <v-list-item
                          dense
                          v-for="(d, di) in dt.docs"
                          :key="'tv' + dti + '_' + di"
                          @click="linkDocumentAction(d, 'view')"
                        >
                          <v-list-item-icon>
                            <v-icon>mdi-text-box-outline</v-icon>
                          </v-list-item-icon>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-list-item-title v-bind="attrs" v-on="on">{{
                                d.reference
                              }}</v-list-item-title>
                            </template>
                            <span>{{ d.doc_name }}</span>
                          </v-tooltip>
                        </v-list-item>
                        <v-list-item
                          v-if="
                            dt.canCreate &&
                            !$loginState.user.settings.some(
                              (s) =>
                                s.setting === 'create_linked_not_embedded' &&
                                s.value === 'true'
                            )
                          "
                          @click="linkDocumentAction(dt, 'link')"
                          :disabled="isCopyActionDisabled(false, dt.tmpl_id)"
                          class="userPilotCreateJA"
                        >
                          <v-list-item-icon>
                            <v-icon>mdi-text-box-plus-outline</v-icon>
                          </v-list-item-icon>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-list-item-title v-bind="attrs" v-on="on"
                                >Create
                              </v-list-item-title>
                            </template>
                            <span>{{ isCopyActionDisabled(true, dt.tmpl_id) }}</span>
                          </v-tooltip>
                        </v-list-item>
                      </v-list-group>
                      <template
                        v-if="
                          dt.canCreate &&
                          (dt.docs.length == 0 ||
                            $loginState.user.settings.some(
                              (s) =>
                                s.setting === 'create_linked_not_embedded' &&
                                s.value === 'true'
                            ))
                        "
                      >
                        <v-tooltip
                          bottom
                          :disabled="!isCopyActionDisabled(false, dt.tmpl_id)"
                        >
                          <template v-slot:activator="{ on, attrs }"
                            ><div v-on="on">
                              <v-list-item
                                v-bind="attrs"
                                @click="linkDocumentAction(dt, 'link')"
                                :disabled="isCopyActionDisabled(false, dt.tmpl_id)"
                              >
                                <v-list-item-icon>
                                  <v-icon>mdi-text-box-plus-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>
                                  Create {{ dt.tmpl_name }}
                                </v-list-item-title>
                              </v-list-item>
                            </div>
                          </template>
                          <span>{{ isCopyActionDisabled(true, dt.tmpl_id) }}</span>
                        </v-tooltip>
                      </template>
                    </div>
                    <v-list-group
                      offset-y
                      v-if="consolidatedDocumentGroup.length > 0"
                      prepend-icon="mdi-folder-outline"
                    >
                      <template v-slot:activator>
                        <v-list-item-title>Source Documents</v-list-item-title>
                      </template>

                      <v-list-item
                        dense
                        v-for="d in consolidatedDocumentGroup"
                        @click="linkDocumentAction(d, 'view')"
                        :key="d.reference"
                      >
                        <v-list-item-icon>
                          <v-icon>mdi-text-box-outline</v-icon>
                        </v-list-item-icon>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-list-item-title v-bind="attrs" v-on="on">{{
                              d.reference
                            }}</v-list-item-title>
                          </template>
                          <span>{{ d.doc_name }}</span>
                        </v-tooltip>
                      </v-list-item>
                    </v-list-group>
                  </v-list>
                </v-menu>
              </div>
            </v-slide-item>
            <v-slide-item v-slot="{ toggle }">
              <div>
                <v-tooltip
                  v-if="!showWizard && (usesTaleoFeed || $loginState.canDemo)"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      color="primary"
                      v-on="on"
                      v-bind="attrs"
                      @click="
                        toggle;
                        sendToTaleo();
                      "
                    >
                      <span>Post</span>
                    </v-btn>
                  </template>
                  <span v-if="$loginState.user.client === 'Akamai'">
                    Post to ACE
                  </span>
                  <span v-else> Post to ATS </span>
                </v-tooltip>
              </div>
            </v-slide-item>
            <v-slide-item v-slot="{ toggle }">
              <div>
                <v-menu
                  v-if="!document.state.uses_workflow"
                  v-model="exportMenu"
                  @click="toggle"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined color="primary" v-bind="attrs" v-on="on">
                      <span>Download</span>
                      <v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item dense>
                      <v-list-item-title class="subtitle-1">
                        Download Options
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item key="dt10" @click="generatePDF()">
                      <v-list-item-icon>
                        <v-icon>mdi-file-pdf-box</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title> Download PDF </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      key="dt11"
                      v-if="!this.$loginState.canDemo"
                      @click="generateRTF()"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-file-word</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title> Download RTF </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      key="dt14"
                      v-if="!this.$loginState.canDemo"
                      @click="generateDOCX()"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-file-word-box-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title> Download Word </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      key="dt12"
                      @click="generateDOCX(true)"
                      v-if="showWordCommentsExport()"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-file-word-box</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        Download Word (+Notes)
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      key="dt13"
                      dense
                      v-if="
                        this.usesInterviewTemplate ||
                        this.$loginState.user.client ===
                          'Scottish Parliament' ||
                        this.$loginState.user.client === 'Baker Hughes' ||
                        (this.$loginState.user.client === 'Demo' &&
                          (document.doc_type == 'Job Description' ||
                            document.doc_type == 'Job Profile' ||
                            document.doc_type == 'Job Advert'))
                      "
                      @click="generateInterviewTemplate()"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-view-list-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        Export Interview Template
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      key="dt15"
                      dense
                      v-if="
                        this.$loginState.user.client === 'Akamai' ||
                        this.$loginState.user.client === 'Elsevier' ||
                        this.$loginState.user.client === 'Demo' ||
                        ((this.$loginState.user.client === 'Conduent' ||
                          this.$loginState.user.client === 'Conduent') &&
                          document.doc_type == 'Job Description')
                      "
                      @click="generateIntakeFlow()"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-account-reactivate</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        <span v-if="this.$loginState.user.client === 'Akamai'"
                          >Export Launch Template</span
                        >
                        <span
                          v-else-if="
                            this.$loginState.user.client === 'Conduent'
                          "
                          >Intake Form</span
                        >
                        <span v-else
                          >Export Intake Flow</span
                        ></v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item
                      key="dt16"
                      dense
                      @click="generateJobShareTemplate()"
                      v-if="
                        $loginState.user.settings.some(
                          (s) =>
                            s.setting === 'uses_jobsharetemplate' &&
                            s.value === 'true'
                        ) &&
                        (document.doc_type == 'Job Description' ||
                          document.doc_type == 'Job Profile')
                      "
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-share-variant</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title
                        >Export Job Share Template</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item
                      key="dt17"
                      dense
                      @click="generateScreeningTemplate()"
                      v-if="
                        this.$loginState.user.client === 'Conduent' &&
                        document.doc_type == 'Job Advert'
                      "
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-share-variant</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title
                        >Export Screening Template</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-slide-item>
            <v-slide-item v-slot="{ toggle }">
              <div>
                <v-menu
                  offset-y
                  v-if="html_export_sections && !document.state.uses_workflow"
                  @click="toggle"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined color="primary" v-bind="attrs" v-on="on">
                      <span>{{
                        html_export_sections.length ? "Export" : "Share"
                      }}</span>
                      <v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-title class="subtitle-1">
                        {{ html_export_sections.length ? "Export" : "Share" }}
                        Options
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-for="(sec, si) in html_export_sections"
                      :key="'dt' + si"
                      @click="docToClipboard(sec)"
                    >
                      <v-list-item-icon>
                        <v-icon>{{
                          "mdi-numeric-" + (si + 1) + "-box-multiple-outline"
                        }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        {{
                          sec.replace(/ .*/, "") +
                          (sec.replace(/ .*/, "") === "Section"
                            ? " " + (si + 1)
                            : "")
                        }}
                        to
                        {{
                          currentClipboardFormat !== undefined &&
                          currentClipboardFormat.target
                            ? currentClipboardFormat.target
                            : "Clipboard"
                        }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      key="dt19"
                      dense
                      @click="
                        emailDialogue.show = true;
                        exportMenu = false;
                      "
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-email-send-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title> Email to... </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-slide-item>
            <template v-for="(m, mi) in document.state.actionMenus">
              <v-slide-item
                :key="'m' + mi"
                v-if="m.action_menu_id"
                v-slot="{ toggle }"
              >
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click="toggle"
                      :class="[
                        'userPilot' + document.doc_type_abbrev,
                        'userPilot' +
                          document.doc_status_text.replace(/\s+/g, ''),
                        'userPilot' + m.name.replace(/\s+/g, ''),
                      ]"
                    >
                      <span>{{ m.name }}</span>
                      <v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-title class="subtitle-1">
                        Actions Available
                      </v-list-item-title>
                    </v-list-item>
                    <template v-for="(aa, ai) in m.actionsAvailable"
                      ><div :key="'m' + mi + 'aa' + ai">
                        <v-tooltip
                          bottom
                          :disabled="!isActionDisabled(aa) && !aa.tooltip"
                        >
                          <template v-slot:activator="{ on, attrs }"
                            ><div v-on="on">
                              <v-list-item
                                @click="doStateAction(aa)"
                                :disabled="isActionDisabled(aa)"
                                v-bind="attrs"
                              >
                                <v-list-item-icon>
                                  <v-icon>{{ getActionIcon(aa) }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>
                                  {{ aa.name }}
                                </v-list-item-title>
                              </v-list-item>
                            </div>
                          </template>
                          <span
                            >{{ aa.tooltip }}
                            {{ isActionDisabled(aa, true) }}</span
                          >
                        </v-tooltip>
                      </div></template
                    >
                  </v-list>
                </v-menu>
              </v-slide-item>
              <v-slide-item v-else :key="'mb' + mi" v-slot="{ toggle }">
                <div class="d-flex flex-nowrap">
                  <v-tooltip
                    bottom
                    v-for="(aa, ai) in m.actionsAvailable"
                    :key="'mb' + mi + 'aa' + ai"
                    :disabled="!isActionDisabled(aa) && !aa.tooltip"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div v-on="on">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          color="primary"
                          @click="
                            toggle;
                            doStateAction(aa);
                          "
                          :disabled="isActionDisabled(aa)"
                          :class="[
                            'userPilot' + document.doc_type_abbrev,
                            'userPilot' +
                              document.doc_status_text.replace(/\s+/g, ''),
                            'userPilot' + aa.name.replace(/\s+/g, ''),
                          ]"
                        >
                          <span>{{ aa.name }} </span>
                        </v-btn>
                      </div>
                    </template>
                    <span
                      >{{ aa.tooltip }} {{ isActionDisabled(aa, true) }}</span
                    >
                  </v-tooltip>
                </div>
              </v-slide-item>
            </template>
          </v-slide-group>
        </v-sheet>
        <v-spacer />
        <div
          ref="appbar-headerinfo"
          v-if="!headerSettings.hideHeaderInfo"
          class="hidden-md-and-down d-flex align-center"
        >
          <v-divider vertical class="mr-2" />
          <div @click="openHeader">
            <v-row dense class="headerData">
              <v-col cols="12"> Name: {{ document.doc_name }} </v-col>
              <v-col :cols="dataColWidth"> ID: {{ documentId }} </v-col>
              <v-col :cols="dataColWidth" v-if="geographyHierarchy">
                {{ geographyHierarchy }}
              </v-col>
              <v-col :cols="dataColWidth" v-if="busOrgHierarchy">
                {{ busOrgHierarchy }}
              </v-col>
            </v-row>
          </div>
        </div>
        <div ref="appbar-feedback" v-if="!headerSettings.hideFeedback" class="hidden-md-and-down d-flex align-center">
          <v-divider vertical class="mr-2" />
          <v-btn
            small color="#DB0066"
            class="userPilotFeedbackBtn" @click="triggerFeedback"
          >
            Feedback
          </v-btn>
        </div>
        <div ref="appbar-icons" class="d-flex align-center">
          <v-divider vertical class="mr-2" />
          <v-tooltip v-if="headerSettings.hideHeaderInfo" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="openHeader"
                title="Document Details"
              >
                <v-icon>assignment</v-icon>
              </v-btn>
            </template>
            <span>Document Details</span>
          </v-tooltip>
          <v-tooltip
            v-if="useTranslation && documentLanguage.flagCode === 'gb'"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="translateTextDialog"
                title="Translate"
              >
                <v-badge
                  floating
                  :content="documentLanguage.value.toUpperCase()"
                >
                  <v-icon>mdi-earth</v-icon>
                </v-badge>
              </v-btn>
            </template>
            <span>View Translations</span>
          </v-tooltip>
          <v-tooltip
            v-if="useTranslation && documentLanguage.flagCode !== 'gb'"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="translateTextDialog"
                title="Translate"
              >
                <v-badge
                  floating
                  :content="documentLanguage.value.toUpperCase()"
                  class="flag-badge"
                >
                  <country-flag
                    class="my-0"
                    :country="documentLanguage.flagCode"
                  />
                </v-badge>
              </v-btn>
            </template>
            <span>View Translations</span>
          </v-tooltip>
          <v-tooltip bottom v-if="enable_flow">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="toggleView">
                <v-icon
                  v-if="!use_jd_flow"
                  aria-label="View Flow Mode"
                  v-bind="attrs"
                  v-on="on"
                  >mdi-format-list-checks</v-icon
                >
                <v-icon
                  v-if="use_jd_flow"
                  aria-label="View Document Mode"
                  v-bind="attrs"
                  v-on="on"
                  >mdi-file-document-outline</v-icon
                >
              </v-btn>
            </template>
            <span v-if="!use_jd_flow">View Flow Mode</span>
            <span v-if="use_jd_flow">View Document Mode</span>
          </v-tooltip>
          <v-tooltip bottom v-if="this.document.use_database_view && !newView">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                @click="toggleDatabaseView"
                aria-label="View Database"
              >
                <v-icon v-bind="attrs" v-on="on">mdi-database-cog</v-icon>
              </v-btn>
            </template>
            <span>View Database</span>
          </v-tooltip>
          <v-tooltip bottom v-if="document.state.canUploadFiles">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="uploadDatabaseDoc" aria-label="Upload File">
                <v-icon v-bind="attrs" v-on="on">mdi-cloud-upload</v-icon>
              </v-btn>
            </template>
            <span>Upload File</span>
            <FileUploader
              @close="openUploadDialogue = false"
              :fileUploadData="fileUploadFormData"
              :uploadedCallback="filesUploadedCallbackDatabase"
              :useTranslation="useTranslation"
              multiple="false"
              displayMode="hidden"
              :openDialogue="openUploadDialogue"
            />
          </v-tooltip>
          <v-tooltip bottom v-if="document.how_to_text && hintsVisible">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                @click="showHint(null, false, document.how_to_text)"
                aria-label="See What / How"
              >
                <v-icon v-bind="attrs" v-on="on"
                  >mdi-help-circle-outline</v-icon
                >
              </v-btn>
            </template>
            <span>See What / How</span>
          </v-tooltip>
          <v-tooltip bottom v-if="document.why_text && hintsVisible">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                @click="showHint(null, true, document.why_text)"
                v-bind="attrs"
                v-on="on"
                aria-label="View Insights"
              >
                <v-icon>mdi-lightbulb-outline</v-icon>
              </v-btn>
            </template>
            <span>View Insights</span>
          </v-tooltip>
          <v-tooltip bottom v-if="document.lifecycle_id">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="openActivity"
                class="userPilotAuditHistory"
                aria-label="Audit History"
              >
                <v-icon>track_changes</v-icon>
              </v-btn>
            </template>
            <span>Audit History</span>
          </v-tooltip>
          <v-tooltip bottom v-else-if="headerHistory.length">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="showheaderHistory"
                aria-label="Header History"
              >
                <v-icon>mdi-history</v-icon>
              </v-btn>
            </template>
            <span>Show Header History</span>
          </v-tooltip>
          <v-chip
            small
            class="mx-1 status-chip"
            label
            @click="openHeader"
            v-for="c in document.classifiers"
            :key="c.cv_id"
            >{{ c.value }}</v-chip
          >
          <v-tooltip bottom v-if="document.lifecycle_id">
            <template v-slot:activator="{ on, attrs }">
              <DocStatus
                :document="document"
                v-bind="attrs"
                v-on="on"
                :class="[
                  'mx-1',
                  'status-chip',
                  'userPilot' + document.doc_status_text.replace(/\s+/g, ''),
                ]"
                role="button"
                @click="openActivity"
              ></DocStatus>
            </template>
            <span>Audit History {{ document.pending_status }}</span>
          </v-tooltip>
        </div>
      </v-app-bar>
      <div v-if="newView" class="viewHolder">
        <DocumentHolder
          :displayMethod="'modal'"
          :data="current_doc_view"
          :readOnly="editDisabled"
          :paddingOffset="'65px'"
          @doStateAction="(aa) => doStateAction(aa)"
          @updateQuestionnaireAnswer="updateQuestionnaireAnswer"
          @checkSummaryUpdated="checkSummaryUpdated"
          @hierarchyUpdated="saveHierarchy"
          @hideComments="hideComments"
          @openDocument="$emit('openDocument', $event)"
        ></DocumentHolder>
      </div>
      <v-row v-else dense :style="[use_jd_flow ? 'height:100vh;' : '']">
        <v-col
          cols="1"
          v-if="showFlowPanel"
          style="height: calc(100vh-100px); padding-top: 75px"
        >
          <v-card flat plain color="transparent">
            <v-list color="transparent">
              <v-list-item-group
                v-model="selectedJdFlowIdx"
                color="transparent"
              >
                <v-list-item
                  transparent
                  v-for="(item, key) in jd_flow_sections"
                  :key="key"
                  @click="selectedSectionChange(item.header)"
                  :class="[
                    'flow-font',
                    'flow_section',
                    item.complete ? 'flow_complete' : '',
                  ]"
                >
                  <v-list-item-icon
                    v-if="item.complete"
                    style="margin-right: 5px"
                  >
                    <v-icon
                      :class="[
                        'flow_complete',
                        item.header === selectedJdFlowItem
                          ? 'flow_section_active'
                          : '',
                      ]"
                      small
                      >mdi-check</v-icon
                    >
                  </v-list-item-icon>
                  <v-list-item-content class="flow-font">
                    <v-list-item-title
                      :class="[
                        item.complete ? 'flow_complete' : '',
                        item.header === selectedJdFlowItem
                          ? 'flow_section_active'
                          : '',
                      ]"
                      >{{ item.header }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>
        <v-col
          :cols="getMainPanelWidth"
          :class="{
            editorcolumn: true,
            norightpanel: !showRightPanel,
            flowPanelClass: showFlowPanel,
          }"
        >
          <EditSection
            ref="editSection"
            v-if="!showWizard"
            :documentId="documentId"
            :data="{
              document,
              docStatus,
              docHierarchies,
              partsHistory,
              wordChecks,
              documentLanguage,
            }"
            :readOnly="editDisabled"
            :isCopied="isCopied"
            :isNew="isNew"
            :useInline="useInline"
            :hightLightIssue="hightLightIssue"
            :selectdTranslateLang="selectdTranslateLang"
            :openFlex="openFlex"
            :docPartUpdateData="updateDocPartData"
            @checkSummaryUpdated="checkSummaryUpdated"
            @showPartHistory="showPartHistory"
            @showDeletedPartHistory="showDeletedPartHistory"
            @showSuggestions="showSuggestionsPanel"
            @showDatabase="showDatabase"
            :use_jd_flow="use_jd_flow"
            :active_section="selectedJdFlowItem"
            :next_section="nextSection"
            :prev_section="prevSection"
            :jd_flow_sections="jd_flow_sections"
            :show_suggestions="showSuggestions"
            :show_database="showDatabasePanel"
            @sectionDone="markSectionDone"
            @updateDone="clearSelection"
            :isDataDrag="
              isDataDrag &&
              ((showDatabasePanel && databaseSelectionState.data !== '') ||
                showSuggestions)
            "
            :nlpActions="nlpActions"
            @dragFinish="dragEnd"
            :tempEssentials="document.use_temp_essentials"
            :revertTrigger="revertTrigger"
            @triggerNotification="triggerNotification"
            :headerUpdated="headerUpdated"
            @hierarchyUpdated="saveHierarchy"
          />
          <DocumentFullWizard
            v-else
            ref="wizard"
            :documentId="documentId"
            :docClosing="docClosing"
            :data="{
              document,
              docStatus,
              docHierarchies,
              partsHistory,
              wordChecks,
              wizardSettings,
            }"
            :actionsAvailable="
              document && document.state ? document.state.actionsAvailable : []
            "
            @openDocFull="closeWizard"
            @docUpdated="docUpdated"
            @doStateAction="doWizardStateAction"
          >
          </DocumentFullWizard>
        </v-col>
        <v-col
          :class="[
            'editsection',
            'no_print_border',
            'printFullWidth',
            'flowDesignActionPanel',
          ]"
          cols="3"
          v-if="showSuggestions"
        >
          <v-card flat>
            <v-card-title>
              Suggestions
              <v-btn
                small
                @click="suggestionsFilterDialog = true"
                color="success"
                style="margin-left: 20px"
                v-if="suggestionTypes.length > 1"
              >
                <v-icon class="pr-2">mdi-filter-plus</v-icon>Filter Settings
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn small icon @click="closeSuggestionsPanel">
                <v-icon>mdi-close-thick</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-title>
              <v-row>
                <v-col cols="4" v-if="suggestionSourceItems.length > 1">
                  <v-select
                    dense
                    outlined
                    multiple
                    placeholder="Source"
                    v-model="suggestionsSourceIncluded"
                    :items="suggestionSourceItems"
                    @change="filterSuggestions"
                    :menu-props="{
                      closeOnContentClick: false,
                      maxHeight: '400',
                      'offset-y': true,
                    }"
                  >
                    <template v-slot:selection="{ item, index }">
                      {{ getSuggestionSourceFilterText(item, index) }}
                    </template>
                  </v-select>
                </v-col>
                <v-col :cols="suggestionSourceItems.length > 1 ? 8 : 12">
                  <v-text-field
                    dense
                    solo
                    append-icon="mdi-magnify"
                    placeholder="Filter Suggestions"
                    v-model="suggestionFilterText"
                    @keyup="filterSuggestions"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-title>
            <v-row dense style="margin-left: 15px; margin-right: 15px">
              <v-col dense cols="12">
                <v-subheader
                  dense
                  class="pl-0"
                  style="height: 40px; font-size: 13px"
                >
                  Suggestion Quality Filter
                </v-subheader>
                <v-slider
                  dense
                  v-model="suggestionQualityThreshold"
                  :thumb-size="20"
                  :color="
                    suggestionQualityThreshold >= 80
                      ? 'green'
                      : suggestionQualityThreshold < 80 &&
                        suggestionQualityThreshold > 40
                      ? 'orange'
                      : 'red'
                  "
                  thumb-label="always"
                  @change="filterSuggestions()"
                ></v-slider>
              </v-col>
            </v-row>
            <v-card-text>
              <div
                v-if="suggestionsLoading"
                style="height: 100%; top: 50%"
                align="center"
              >
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                  style="top: 45%"
                ></v-progress-circular>
              </div>
              <div v-else>
                <div v-if="filteredSuggestions.length === 0">
                  No Suggestions!
                </div>
                <v-list subheader three-line flat v-else>
                  <v-list-item-group v-model="selectedSuggestions" multiple>
                    <v-list-item
                      class="suggestionItem draggableText"
                      v-for="(item, i) in filteredSuggestions"
                      :key="'suggestion_' + i"
                      style="cursor: move"
                      @click="selectSuggestion(item)"
                    >
                      <template v-slot:default="">
                        <v-list-item-action
                          style="padding-top: 0px; margin-right: 16px"
                        >
                          <v-checkbox
                            color="primary"
                            v-model="item.selected"
                            @click="selectSuggestion(item)"
                          ></v-checkbox>
                        </v-list-item-action>

                        <v-list-item-content
                          style="padding-top: 0px"
                          @dragstart="dragStartSuggestion(item, $event)"
                          @dragend="dragEnd"
                          draggable
                        >
                          <v-list-item-title class="suggestionTextTitle">{{
                            item.type
                          }}</v-list-item-title>
                          <v-list-item-subtitle class="suggestionText">{{
                            item.varVal
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action
                          v-if="suggestionSourceItems.length > 1"
                          style="display: inline; margin-top: 10px"
                        >
                          <v-chip
                            class="ma-2"
                            :color="
                              item.source === 'Internal'
                                ? 'green'
                                : 'deep-purple accent-4'
                            "
                            outlined
                            x-small
                          >
                            {{ item.source }}
                            <span
                              style="font-size: 11px; padding-left: 2px"
                              :class="getSuggestionQualityColor(item.quality)"
                              >{{ item.quality }}%</span
                            >
                          </v-chip>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          :class="[
            'editsection',
            'no_print_border',
            'printFullWidth',
            'flowDesignDatabasePanel',
          ]"
          cols="5"
          v-if="showDatabasePanel"
        >
          <ContentDatabase
            :document="document"
            :sourceFile="sourceDoc"
            :isNew="false"
            @dragEnd="dragEnd"
            @dragStart="dragStart"
            @closeDatabasePanel="showDatabasePanel = false"
            @triggerNotification="triggerNotification"
            @sourceFileDeleted="sourceDoc = null"
          ></ContentDatabase>
        </v-col>
        <v-col
          class="scorecolumn"
          :style="scorecolumnStyle"
          cols="3"
          v-if="showRightPanel && (!showDatabasePanel || !showSuggestionsPanel)"
        >
          <div
            v-for="(dca, dci) in document.state.dataCaptureActionsMain"
            :key="'dca' + dci"
          >
            <DataCaptureQuestionnaire
              v-if="dca.data_capture_method === 'questionnaire'"
              :action="dca"
              @saveAnswer="updateQuestionnaireAnswer"
              @actionTriggered="doStateAction"
              @toggleHidden="dca.isDataCaptureHidden = !dca.isDataCaptureHidden"
            />
            <DataCaptureRequisitions
              v-else-if="
                dca.data_capture_method === 'requisition' ||
                dca.data_capture_method === 'requisition_new'
              "
              :action="dca"
              :isBusy="isLoading"
              @save="saveDataCapture"
              @requestEdit="doStateAction"
              @toggleHidden="dca.isDataCaptureHidden = !dca.isDataCaptureHidden"
            />
            <DataCaptureForm
              v-else-if="dca.data_capture_method === 'form'"
              :action="dca"
              :isBusy="isLoading"
              :docId="document.doc_id"
              @save="saveDataCapture"
              @requestEdit="doStateAction"
              @toggleHidden="dca.isDataCaptureHidden = !dca.isDataCaptureHidden"
            />
            <DataCaptureDocumentRole
              v-else-if="dca.data_capture_method === 'document_role'"
              :action="dca"
              :isBusy="isLoading"
              :docId="document.doc_id"
              @save="saveDataCapture"
              @requestEdit="doStateAction"
              @toggleHidden="dca.isDataCaptureHidden = !dca.isDataCaptureHidden"
            />
            <AssignReviewGroup
              v-else-if="dca.data_capture_method === 'review_group'"
              :docId="document.doc_id"
              :action="dca"
              @save="saveDataCapture"
            >
            </AssignReviewGroup>
          </div>
          <v-card
            class="scorecard"
            v-if="
              showScoring &&
              !history &&
              !ptDeleteHistory &&
              !headerHistoryShow &&
              !showSuggestions &&
              !showDatabasePanel
            "
          >
            <v-card-title class="scoretitle">
              <v-progress-circular
                v-if="
                  documentIssueDisplay === 'score' ||
                  documentIssueDisplay === 'score_header_only'
                "
                :rotate="90"
                :size="45"
                :width="5"
                :value="checkSummary.score"
                :color="checkSummary.scoreColour"
                class="mr-1 mb-1 mt-1"
                aria-label="Overall Score"
                ><span>{{ checkSummary.score.toFixed(0) }}</span>
              </v-progress-circular>
              <div class="inclusionHeading">{{ document.score_title }}</div>
              <v-spacer></v-spacer
              ><v-btn
                icon
                small
                @click="allCheckSummaryPanels"
                v-if="
                  checkSummaryPanels.length < checkSummary.categories.length
                "
                aria-label="Expand all"
              >
                <v-icon>mdi-chevron-double-down</v-icon>
              </v-btn>
              <v-btn
                icon
                small
                @click="closeCheckSummaryPanels"
                v-if="
                  checkSummaryPanels.length === checkSummary.categories.length
                "
                aria-label="Collapse all"
              >
                <v-icon>mdi-chevron-double-up</v-icon>
              </v-btn></v-card-title
            >
            <v-card-text dense style="padding: 0">
              <v-expansion-panels
                accordion
                multiple
                flat
                v-model="checkSummaryPanels"
              >
                <v-expansion-panel
                  class="scoresection"
                  v-for="(i, ii) in checkSummary.categories"
                  :key="'i' + ii"
                >
                  <v-expansion-panel-header
                    :disable-icon-rotate="i.score == '100'"
                  >
                    <span class="sectionText">
                      <v-badge
                        v-if="
                          (documentIssueDisplay === 'score_header_only' ||
                            documentIssueDisplay === 'basic') &&
                          i.errorCount
                        "
                        :content="i.errorCount"
                        color="red"
                        inline
                        overlap
                        style="float: right"
                      >
                      </v-badge
                      ><v-badge
                        v-if="
                          (documentIssueDisplay === 'score_header_only' ||
                            documentIssueDisplay === 'basic') &&
                          i.warningCount
                        "
                        :content="i.warningCount"
                        color="amber"
                        inline
                        overlap
                        style="float: right"
                      >
                      </v-badge
                      >{{ i.category.trim() + " " }}
                      <v-progress-circular
                        v-if="documentIssueDisplay === 'score'"
                        :rotate="90"
                        :size="30"
                        :width="4"
                        :value="i.score"
                        :color="i.scoreColour"
                        class="float-right"
                        :aria-label="`${i.category} score`"
                        ><span style="font-size: 11px"> {{ i.score }}</span>
                      </v-progress-circular> </span
                    ><template v-if="i.score == '100'" v-slot:actions>
                      <v-icon> mdi-check </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content v-if="checkSummaryView === 1">
                    <v-row
                      dense
                      v-for="(c, ci) in i.titles"
                      :key="'i' + ii + 'ci' + ci"
                    >
                      <v-col>
                        <v-row dense class="scoreDetail">
                          <v-col cols="1"></v-col>
                          <v-col cols="11"
                            ><span style="font-size: 13px">{{
                              c.title
                            }}</span></v-col
                          >
                        </v-row>
                        <v-row
                          dense
                          class="scoreDetail"
                          v-for="(e, ei) in checkSummary.errors.filter(
                            (x) =>
                              x.category === i.category && x.title === c.title
                          )"
                          :key="'e_' + ii + '_' + ei"
                        >
                          <v-col cols="1"></v-col>
                          <v-col
                            cols="11"
                            @mouseenter="highlightSection(e)"
                            @mouseleave="unhighlightSection"
                            @click="scrollToSection(e)"
                            class="sectionItem"
                          >
                            <v-badge
                              color="red"
                              :content="e.count"
                              inline
                              overlap
                              style="color: slategray; font-size: 12px"
                              :aria-label="e.description"
                            >
                              <div
                                :style="{
                                  'min-width': '22px',
                                  height:
                                    e.effect === 'highlight' ? '22px' : '',
                                  'margin-right': '10px',
                                  'background-color':
                                    e.effect === 'highlight'
                                      ? e.colour
                                      : 'transparent',
                                  'border-bottom':
                                    e.effect.indexOf('dotted') >= 0
                                      ? 'dotted 3px ' + e.colour
                                      : e.effect.indexOf('solid') >= 0
                                      ? 'solid 3px ' + e.colour
                                      : '',
                                }"
                              ></div>
                              <div v-html="e.description"></div>
                            </v-badge>
                          </v-col>
                        </v-row>
                        <v-row
                          dense
                          class="scoreDetail"
                          v-for="(w, wi) in checkSummary.warnings.filter(
                            (x) =>
                              x.category === i.category && x.title === c.title
                          )"
                          :key="'w_' + ii + '_' + wi"
                        >
                          <v-col cols="1"></v-col>
                          <v-col
                            cols="11"
                            @mouseenter="highlightSection(w)"
                            @mouseleave="unhighlightSection"
                            @click="scrollToSection(w)"
                            class="sectionItem"
                          >
                            <v-badge
                              color="amber"
                              :content="w.count"
                              inline
                              overlap
                              style="color: slategray; font-size: 12px"
                              :aria-label="w.description"
                            >
                              <div
                                :style="{
                                  'min-width': '22px',
                                  height:
                                    w.effect === 'highlight' ? '22px' : '',
                                  'margin-right': '10px',
                                  'background-color':
                                    w.effect === 'highlight'
                                      ? w.colour
                                      : 'transparent',
                                  'border-bottom':
                                    w.effect.indexOf('dotted') >= 0
                                      ? 'dotted 3px ' + w.colour
                                      : w.effect.indexOf('solid') >= 0
                                      ? 'solid 3px ' + w.colour
                                      : '',
                                }"
                              ></div>
                              <div v-html="w.description"></div>
                            </v-badge>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                  <v-expansion-panel-content
                    v-if="checkSummaryView === 2"
                    style="margin-top: -12px"
                  >
                    <v-row
                      dense
                      class="scoreDetail"
                      v-for="(t, ti) in i.titles"
                      :key="'i' + ii + 'ti' + ti"
                    >
                      <v-col cols="1">
                        <div
                          :style="{
                            'margin-top': '3px',
                            height:
                              t.effect === 'highlight' || t.effect === 'border'
                                ? '18px'
                                : '12px',
                            'background-color':
                              t.effect === 'highlight'
                                ? t.colour
                                : 'transparent',
                            'border-bottom':
                              t.effect.indexOf('dotted') >= 0
                                ? 'dotted 3px ' + t.colour
                                : t.effect.indexOf('solid') >= 0
                                ? 'solid 3px ' + t.colour
                                : t.effect === 'border'
                                ? 'solid 1px ' + t.colour
                                : '',
                            'border-top':
                              t.effect === 'border'
                                ? 'solid 1px ' + t.colour
                                : '',
                            'border-left':
                              t.effect === 'border'
                                ? 'solid 1px ' + t.colour
                                : '',
                            'border-right':
                              t.effect === 'border'
                                ? 'solid 1px ' + t.colour
                                : '',
                            'border-radius':
                              t.effect === 'border' ? '4px ' : '',
                          }"
                        >
                          <v-icon v-if="t.effect.substr(0, 5) === 'icon-'">{{
                            t.effect.substr(5)
                          }}</v-icon>
                        </div>
                      </v-col>
                      <v-col cols="11">
                        <span style="font-size: 13px; margin-right: 10px">{{
                          t.title
                        }}</span>
                        <span
                          v-for="(d, di) in t.issues"
                          :key="'i' + ii + 'ti' + ti + 'di' + di"
                        >
                          <v-tooltip bottom v-if="d.errorCount > 0">
                            <template v-slot:activator="{ on, attrs }"
                              ><v-btn
                                x-small
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @mouseenter="highlightSection(d, true)"
                                @mouseleave="unhighlightSection"
                                @click="scrollToSection(d)"
                                class="sectionItem"
                                :aria-label="d.description"
                              >
                                <v-icon color="red" style="font-size: 18px"
                                  >mdi-alert</v-icon
                                >
                                <span
                                  v-if="d.errorCount > 1"
                                  style="
                                    margin-top: -12px;
                                    margin-left: -5px;
                                    font-size: 9px;
                                    text-transform: none;
                                  "
                                  >x{{ d.errorCount }}</span
                                ></v-btn
                              >
                            </template>
                            <div style="max-width: 300px">
                              <span v-html="d.description"></span>
                            </div>
                          </v-tooltip>
                          <v-tooltip bottom v-if="d.warningCount > 0">
                            <template v-slot:activator="{ on, attrs }"
                              ><v-btn
                                x-small
                                text
                                v-bind="attrs"
                                v-on="on"
                                @mouseenter="highlightSection(d)"
                                @mouseleave="unhighlightSection"
                                @click="scrollToSection(d)"
                                class="sectionItem"
                                :aria-label="d.description"
                              >
                                <v-icon color="grey" style="font-size: 18px"
                                  >mdi-alert</v-icon
                                >
                                <span
                                  v-if="d.warningCount > 1"
                                  style="
                                    margin-top: -12px;
                                    margin-left: -5px;
                                    font-size: 9px;
                                    text-transform: none;
                                  "
                                  >x{{ d.warningCount }}</span
                                ></v-btn
                              >
                            </template>
                            <div style="max-width: 300px">
                              <span v-html="d.description"></span>
                            </div>
                          </v-tooltip>
                        </span>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                  <v-expansion-panel-content
                    v-if="checkSummaryView === 3"
                    style="margin-top: -12px"
                  >
                    <v-row
                      dense
                      class="scoreDetail"
                      v-for="(t, ti) in i.titles"
                      :key="'i' + ii + 'ti' + ti"
                    >
                      <v-col cols="1">
                        <div
                          :style="{
                            'margin-top': '3px',
                            height: t.effect === 'highlight' ? '18px' : '12px',
                            'background-color':
                              t.effect === 'highlight'
                                ? t.colour
                                : 'transparent',
                            'border-bottom':
                              t.effect.indexOf('dotted') >= 0
                                ? 'dotted 3px ' + t.colour
                                : t.effect.indexOf('solid') >= 0
                                ? 'solid 3px ' + t.colour
                                : '',
                          }"
                        ></div>
                      </v-col>
                      <v-col cols="8">
                        <span style="font-size: 13px; margin-right: 10px">{{
                          t.title
                        }}</span>
                      </v-col>
                      <v-col cols="3">
                        <div
                          style="
                            width: 100%;
                            border: solid #f0e8e8 1px;
                            height: 19px;
                          "
                        >
                          <div
                            v-for="n in t.errorCount"
                            :key="'i' + ii + 'ti' + ti + 'n' + n"
                            :style="{
                              width: 100 / checkSummary.maxIssues - 2 + '%',
                              'background-color': 'red',
                              margin: '1%',
                              float: 'left',
                              height: '15px',
                            }"
                          ></div>
                          <div
                            v-for="n in t.warningCount"
                            :key="'i' + ii + 'ti' + ti + 'n' + n"
                            :style="{
                              width: 100 / checkSummary.maxIssues - 2 + '%',
                              'background-color': 'orange',
                              margin: '1%',
                              float: 'left',
                              height: '15px',
                            }"
                          ></div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
            <div
              style="padding-top: 15px"
              v-if="document.matches.length > 0 && showSimilaritiesPanel"
            >
              <DocumentSimilarityPanel :matches="document.matches" @initCompare="initCompare" @quickView="quickView"></DocumentSimilarityPanel>
            </div>
          </v-card>
          <DocumentComments
            v-if="commentDialogue.show"
            :document="document"
            @closed="hideComments"
            @commentsAdded="commentsAdded(commentDialogue.part)"
          ></DocumentComments>
          <v-card
            v-if="history"
            max-height="calc(100vh - 70px)"
            class="overflow-y-auto"
          >
            <v-card-title>
              {{ history.name }} {{ collabHistory ? "Activity" : "History" }}
              <v-spacer></v-spacer>
              <v-btn
                small
                icon
                @click="
                  history = null;
                  unhighlightSection();
                "
                ><v-icon>mdi-close-thick</v-icon></v-btn
              >
              <v-tabs
                centered
                background-color="transparent"
                v-if="collabHistory"
                v-model="comment.tab"
              >
                <v-tab>Comments</v-tab>
                <v-tab
                  v-if="
                    history.collabSummary.filter((x) => x.type !== 'comment')
                      .length
                  "
                  >Changes</v-tab
                >
              </v-tabs></v-card-title
            >
            <v-card-text v-if="collabHistory">
              <v-tabs-items
                v-model="comment.tab"
                style="background-color: transparent !important"
              >
                <v-tab-item>
                  <DocumentComments
                    :document="document"
                    :doc_part_id="comment.part.doc_part_id"
                    @saved="comment.part.hasComments = true"
                    hideHeader
                  ></DocumentComments>
                </v-tab-item>
                <v-tab-item
                  v-if="
                    history.collabSummary.filter((x) => x.type !== 'comment')
                      .length
                  "
                >
                  <v-timeline dense style="max-height: 100%">
                    <!-- <v-timeline-item
                  small
                  v-if="document.state.docPartCommentAction"
                >
                  <template v-slot:icon>
                    <v-avatar color="primary" size="30">
                      <span class="white--text">{{ userInitials() }}</span>
                    </v-avatar>
                  </template>
                  <v-row dense
                    ><v-col>
                      <v-textarea
                        v-model="comment.text"
                        auto-grow
                        hide-details
                        outlined
                        rows="1"
                        placeholder="Add your comment..."
                        @input="comment.isDirty = true"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row dense v-show="comment.isDirty">
                    <v-col cols="3">
                      <v-btn
                        small
                        color="primary"
                        @click="commentHandler('save_new')"
                        >Save</v-btn
                      >
                    </v-col>
                    <v-col cols="3">
                      <v-btn small @click="commentHandler('cancel_new')"
                        >Cancel</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-timeline-item> -->
                    <v-timeline-item
                      small
                      v-for="(h, hi) in history.collabSummary.filter(
                        (x) => x.type !== 'comment'
                      )"
                      :key="'ch' + hi"
                    >
                      <template v-slot:icon>
                        <v-avatar
                          :color="h.type === 'comment' ? 'primary' : 'teal'"
                          size="30"
                        >
                          <span class="white--text">{{ h.user_initials }}</span>
                        </v-avatar>
                      </template>
                      <v-card v-if="h.type === 'comment'" class="elevation-2">
                        <v-card-text>
                          <v-icon size="22" class="mr-2">
                            mdi-comment-text-outline
                          </v-icon>
                          <strong
                            >{{ h.data.created_date }}:
                            {{ h.user_name }}: </strong
                          ><br />
                          <q>{{ h.data.comment }}</q></v-card-text
                        >
                      </v-card>
                      <v-sheet
                        v-else
                        rounded
                        elevation="2"
                        style="padding: 2px 12px"
                      >
                        <p class="font-weight-normal overflow-hidden">
                          <v-icon size="22" class="mr-2">
                            mdi-pencil-outline
                          </v-icon>
                          <strong>{{ h.data.version_collab_desc }}</strong>
                          <v-chip
                            small
                            v-if="h.data.latest"
                            class="float-right"
                            outlined
                            label
                          >
                            Latest Version</v-chip
                          >
                          <v-btn
                            v-else-if="
                              !h.data.latest && document.state.canEditDocParts
                            "
                            class="float-right mb-1 mr-1"
                            small
                            @click="
                              revertTrigger = {
                                rc: h.data,
                                processed: false,
                                part: comment.part,
                                partType: comment.partType,
                              }
                            "
                            >Revert To</v-btn
                          >
                        </p>
                        <p class="overflow-hidden">
                          {{ h.data.previous }}
                          <v-chip
                            class="float-right"
                            small
                            color="primary"
                            v-if="h.data.previous_is_essential !== null"
                          >
                            {{
                              h.data.previous_is_essential
                                ? "Essential"
                                : "Not Essential"
                            }}
                          </v-chip>
                        </p>
                      </v-sheet>
                    </v-timeline-item>
                  </v-timeline>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
            <v-card-text v-else>
              <v-timeline dense style="max-height: 100%">
                <v-timeline-item
                  small
                  v-for="(h, hi) in history.items"
                  :key="'h' + hi"
                >
                  <v-row dense>
                    <v-col class="d-flex justify-space-between">
                      <h5 class="subtitle-2">
                        {{ h.version_desc }}
                      </h5>
                      <v-btn
                        v-if="h.canRevert && document.state.canEditDocParts"
                        x-small
                        icon
                        title="Revert"
                        @click="revertChange(h)"
                        ><v-icon>mdi-undo-variant</v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-row
                    dense
                    v-for="(c, ci) in h.changes"
                    :key="'h' + hi + 'c' + ci"
                  >
                    <v-col cols="3">
                      <strong class="histText">{{ c.title }}</strong>
                    </v-col>
                    <v-col>
                      <span class="histText" v-html="c.desc"></span>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
          </v-card>
          <v-card
            v-if="ptDeleteHistory"
            max-height="800"
            class="overflow-y-auto"
          >
            <v-card-title v-if="ptDeleteHistory.length"
              >{{ ptDeleteHistory[0].name }} Delete History
              <v-spacer></v-spacer>
              <v-btn
                small
                icon
                @click="
                  ptDeleteHistory = null;
                  unhighlightSection();
                "
                ><v-icon>mdi-close-thick</v-icon></v-btn
              >
            </v-card-title>
            <v-card-title v-else
              >No Deleted {{ ptDeleteHistoryPT.type }} Rows
              <v-spacer></v-spacer>
              <v-btn
                small
                icon
                @click="
                  ptDeleteHistory = null;
                  unhighlightSection();
                "
                ><v-icon>mdi-close-thick</v-icon></v-btn
              >
            </v-card-title>
            <v-card-text v-if="ptDeleteHistory.length">
              <v-timeline dense>
                <v-timeline-item
                  small
                  v-for="(dh, hi) in ptDeleteHistory"
                  :key="'dh' + hi"
                >
                  <v-row dense>
                    <v-col>
                      <h5 class="subtitle-2">
                        {{ dh.items[0].version_desc }}
                      </h5>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" class="d-flex justify-space-between">
                      <span class="histText" v-html="dh.items[0].text"></span>
                      <v-btn
                        icon
                        v-if="
                          dh.items[0].canRevert &&
                          document.state.canEditDocParts &&
                          (document.state.allow_errors ||
                            ptDeleteHistoryPT.parts.length <
                              ptDeleteHistoryPT.cardinality.maxError)
                        "
                        x-small
                        title="Undo"
                        @click="revertDelete(dh)"
                        ><v-icon>mdi-undo-variant</v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
          </v-card>
          <v-card
            v-if="headerHistoryShow"
            max-height="800"
            class="overflow-y-auto"
          >
            <v-card-title
              >Header History
              <v-spacer></v-spacer>
              <v-btn
                small
                icon
                @click="
                  headerHistoryShow = false;
                  unhighlightSection();
                "
                ><v-icon>mdi-close-thick</v-icon></v-btn
              >
            </v-card-title>
            <v-card-text v-if="headerHistoryShow && headerHistory.length">
              <v-timeline dense>
                <v-timeline-item
                  small
                  v-for="(hh, hi) in headerHistory"
                  :key="'hh' + hi"
                >
                  <v-row dense>
                    <v-col>
                      <h5 class="subtitle-2">
                        {{ hh.version_desc }}
                      </h5>
                    </v-col>
                  </v-row>
                  <v-row
                    dense
                    v-for="(hc, hci) in hh.changes"
                    :key="'hh' + hi + 'c' + hci"
                  >
                    <v-col cols="4">
                      <h5 v-html="hc.title"></h5>
                    </v-col>
                    <v-col cols="8" class="d-flex justify-space-between">
                      <span class="histText" v-html="hc.desc"></span>
                      <v-btn
                        icon
                        v-if="hh.canRevert && document.state.canEditDocParts"
                        x-small
                        title="Revert"
                        @click="revertHierarchy(hc)"
                        ><v-icon>mdi-undo-variant</v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <ResponseHandler :serviceResponse="response"></ResponseHandler>

      <DocumentHeader
        :open="showHeaderDetail"
        :source="document"
        :checks="checkSummary"
        @saved="documentHeaderSaved"
        @saveDataCapture="saveDataCapture"
        @closed="documentHeaderClosed"
      ></DocumentHeader>
      <DocumentActivities
        :open="showActivity"
        :doc_id="document.doc_id"
        @closed="activityClosed"
        @openDocument="$emit('openDocument', $event)"
        @copyDocument="$emit('copyDocument', $event)"
      ></DocumentActivities>
      <v-dialog v-model="reqDialogue.show" max-width="900px">
        <v-card>
          <v-card-title class="d-flex align-center">
            <div class="subtitle-1">
              Send Content To
              {{
                $loginState.user.settings.some(
                  (s) =>
                    s.setting === "IntegrationType" && s.value === "OracleHCR"
                )
                  ? "Oracle HCR"
                  : "Taleo"
              }}
            </div>
            <v-spacer />
            <v-btn
              icon
              large
              class="btn-background"
              @click="reqDialogue.show = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-row dense>
              <v-col cols="5">
                <v-text-field
                  outlined
                  class="roundish"
                  v-model="reqDialogue.value"
                  :items="taleoReqData"
                  label="Requisition Number"
                ></v-text-field>
              </v-col>
              <v-col cols="2" style="padding-top: 15px; padding-left: 35px">
                <v-checkbox
                  v-model="reqDialogue.USA"
                  label="US Job?"
                ></v-checkbox>
              </v-col>
              <v-col
                v-if="exportSections.length > 0"
                cols="5"
                style="padding-top: 15px; padding-left: 35px"
              >
                <v-checkbox
                  v-for="(es, indx) in exportSections"
                  :key="indx"
                  v-model="reqDialogue.exportSection"
                  :items="exportSections"
                  :value="es.tmpl_export_section_id"
                  :label="`Include Compensation ${es.section}`"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col
                cols="6"
                v-if="awaitingTaleo"
                style="padding-top: 20px; padding-left: 25px"
              >
                <v-progress-circular
                  :width="3"
                  color="green"
                  indeterminate
                ></v-progress-circular>
                Exporting to
                {{
                  $loginState.user.settings.some(
                    (s) =>
                      s.setting === "IntegrationType" && s.value === "OracleHCR"
                  )
                    ? "Oracle HCR"
                    : "Taleo"
                }}....
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex justify-end px-6 pb-4">
            <v-btn
              large
              color="primarylight"
              class="roundish"
              @click="sendDataToTaleo()"
              :disabled="!reqDialogue.value || awaitingTaleo"
              >Send</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="emailDialogue.show" max-width="500px">
        <v-card>
          <v-card-title class="d-flex align-center">
            <div class="subtitle-1">Send Document</div>
            <v-spacer />
            <v-btn
              icon
              large
              class="btn-background"
              @click="closeEmailDialogue"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form ref="emailDocForm" v-model="emailDialogue.valid">
              <v-text-field
                outlined
                class="roundish"
                label="Email Address"
                autofocus
                @keypress.esc="closeEmailDialogue"
                @keypress.enter="emailDoc"
                required
                :rules="[rules.required, rules.email]"
                v-model="emailDialogue.value"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions class="d-flex justify-end px-6 pb-4">
            <v-btn
              v-if="!emailDialogue.sending"
              color="primary"
              @click="emailDoc"
              :disabled="!emailDialogue.valid"
              >Send</v-btn
            >
            <v-progress-circular
              v-else
              class="mx-2"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="translateDialog" max-width="1100px" min-height="700px">
        <v-card>
          <v-card-title class="d-flex align-center">
            <div class="subtitle-1">Translation Options</div>
            <v-spacer />
            <v-btn
              icon
              large
              class="btn-background"
              @click="closeTranslateDialog"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="justify-center">
            <v-row dense v-if="document.language_code != 'en'">
              <v-col cols="2" md="2" sm="2" dense>
                <h3>Original Document:</h3>
              </v-col>
              <v-col cols="7" class="justify-left" dense>
                <div
                  dense
                  v-for="lang in filterLanguageDocs('en')"
                  :key="lang.doc_id"
                >
                  <a
                    dense
                    @click="openTranslatedDoc(lang)"
                    v-if="lang.language_code !== 'en'"
                  >
                    <country-flag
                      :country="getLangCodeFlag(lang.language_code)"
                      :title="getLangCodeName(lang.language_code)"
                      size="normal"
                      class="my-0"
                    />
                    <span
                      dense
                      style="padding-left: 20px; vertical-align: top"
                      >{{ lang.system_number }}</span
                    >
                  </a>
                  <a
                    dense
                    @click="openTranslatedDoc(lang)"
                    v-if="lang.language_code == 'en'"
                  >
                    <img
                      style="margin-right: 0px"
                      src="../assets/Anglosphere.png"
                      width="28px"
                      height="20px"
                    />
                    <span
                      dense
                      style="padding-left: 20px; vertical-align: top"
                      >{{ lang.system_number }}</span
                    >
                  </a>
                </div>
              </v-col>
            </v-row>
            <v-row class="justify-center" dense>
              <v-col cols="6" dense>
                <h3>
                  Existing Translated Documents ({{
                    filterLanguageDocs(null).length
                  }})
                </h3>
              </v-col>
              <v-col cols="6" dense>
                <h3>Create New Translation</h3>
              </v-col>
            </v-row>
            <v-row style="padding-top: 5px" class="justify-center" dense>
              <v-col cols="6" dense>
                <div
                  v-for="lang in filterLanguageDocs(null)"
                  :key="lang.doc_id"
                >
                  <a
                    @click="openTranslatedDoc(lang)"
                    class="d-flex align-center"
                  >
                    <country-flag
                      :country="getLangCodeFlag(lang.language_code)"
                      :title="getLangCodeName(lang.language_code)"
                      size="normal"
                      class="my-0"
                    />
                    <span style="padding-left: 20px; vertical-align: top">{{
                      lang.system_number
                    }}</span>
                  </a>
                </div>
              </v-col>
              <v-col cols="6" class="d-flex align-start">
                <div class="d-flex align-center" style="width: 100%">
                  <v-select
                    class="roundish"
                    outlined
                    v-model="selectdTranslateLang"
                    :items="translateOptions"
                    label="Target Language"
                    :menu-props="{ 'offset-y': true }"
                    hide-details
                  >
                    <template slot="item" slot-scope="item">
                      <div
                        v-if="item.item.value !== 'en'"
                        class="d-flex align-center"
                      >
                        <country-flag
                          :country="getFlagCode(item)"
                          size="normal"
                          class="my-0"
                        />
                        <span style="padding-left: 20px">{{
                          item.item.text
                        }}</span>
                      </div>
                      <div
                        v-if="item.item.value == 'en'"
                        class="d-flex align-center"
                      >
                        <img
                          style="margin-right: 0px"
                          src="../assets/Anglosphere.png"
                          width="28px"
                          height="20px"
                        /><span style="padding-left: 26px">{{
                          item.item.text
                        }}</span>
                      </div>
                    </template>
                    <template v-slot:selection="item">
                      <div
                        v-if="item.item.value !== 'en'"
                        class="d-flex align-center"
                      >
                        <country-flag
                          :country="getFlagCode(item)"
                          size="normal"
                          class="my-0"
                        /><span style="padding-left: 20px">{{
                          item.item.text
                        }}</span>
                      </div>
                      <div
                        v-if="item.item.value == 'en'"
                        class="d-flex align-center"
                      >
                        <img
                          style="margin-right: 0px"
                          src="../assets/Anglosphere.png"
                          width="28px"
                          height="20px"
                        /><span style="padding-left: 26px">{{
                          item.item.text
                        }}</span>
                      </div>
                    </template>
                  </v-select>
                  <v-btn
                    large
                    color="primarylight"
                    :disabled="isTranslating"
                    right
                    class="roundish ml-2"
                    @click="copyAndTranslateDoc(selectdTranslateLang)"
                    >Create</v-btn
                  >
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="7"></v-col>
              <v-col cols="5" v-if="isTranslating">
                <v-progress-circular
                  :width="3"
                  color="green"
                  indeterminate
                ></v-progress-circular>
                Document Creating...Please Wait
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"
                ><i style="color: red"
                  >Please note; The accuracy and quality of the translation will
                  be dependent on the quality of the document in its original
                  language.</i
                ></v-col
              >
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="confirmCopyShow" max-width="700px">
        <v-card>
          <v-card-actions>
            <div class="pa-2">Copy Confirmation</div>
            <v-spacer></v-spacer>
            <v-btn text @click="confirmCopyShow = false" color="primary"
              >Cancel</v-btn
            >
            <v-btn
              text
              class="userPilotCopyDocument"
              @click="linkDocumentAction(null, 'copy')"
              >Copy Document</v-btn
            >
          </v-card-actions>
          <v-card-text>
            <v-row>
              <v-col>{{ confirmCopy }}</v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="hint.show" scrollable max-width="800px">
        <v-card>
          <v-card-title
            ><v-icon>{{ hint.icon }}</v-icon
            >{{ hint.title }}<v-spacer></v-spacer
            >{{ hint.subtitle }}</v-card-title
          >
          <v-divider></v-divider>
          <v-card-text style="max-height: 800px">
            <div v-html="hint.text"></div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dataCapture.show"
        scrollable
        max-width="80%"
        persistent
      >
        <DataCaptureForm
          v-if="
            dataCapture.show &&
            (dataCapture.action.definition.isCommentBased ||
              ['picklist', 'form'].some(
                (x) => x === dataCapture.action.definition.method
              ))
          "
          :action="dataCapture.action"
          :isBusy="isLoading"
          :docId="document.doc_id"
          @save="saveDataCapture"
          @cancel="cancelDataCapture"
          @toggleHidden="dca.isDataCaptureHidden = !dca.isDataCaptureHidden"
        />
        <DataCaptureDocumentRole
          v-else-if="
            dataCapture.show &&
            dataCapture.action.definition.method === 'document_role'
          "
          :action="dataCapture.action"
          :isBusy="isLoading"
          :docId="document.doc_id"
          @save="saveDataCapture"
          @cancel="cancelDataCapture"
          @toggleHidden="dca.isDataCaptureHidden = !dca.isDataCaptureHidden"
        />
        <DataCaptureHierarchy
          v-else-if="
            dataCapture.show &&
            dataCapture.action.definition.method === 'hierarchy'
          "
          :action="dataCapture.action"
          :documentHierarchies="document.hierarchies"
          :isBusy="isLoading"
          @save="saveDataCapture"
          @cancel="cancelDataCapture"
          @toggleHidden="dataCapture.action = !dataCapture.action"
        />
        <DataCaptureRequisitions
          v-else-if="
            dataCapture.show &&
            (dataCapture.action.definition.method === 'requisition' ||
              dataCapture.action.definition.method === 'requisition_new')
          "
          :action="dataCapture.action"
          :isBusy="isLoading"
          @save="saveDataCapture"
          @cancel="cancelDataCapture"
          @toggleHidden="
            dataCapture.action.isDataCaptureHidden =
              !dataCapture.action.isDataCaptureHidden
          "
        />
        <AssignReviewGroup
          v-else-if="
            dataCapture.show &&
            dataCapture.action.definition.method === 'review_group'
          "
          :docId="document.doc_id"
          :action="dataCapture.action"
          @save="saveDataCapture"
          @cancel="dataCapture.show = false"
          @close="dataCapture.show = false"
        >
        </AssignReviewGroup>
        <DataCaptureQuestionnaire
          v-else-if="
            dataCapture.show &&
            dataCapture.action.data_capture_method === 'questionnaire'
          "
          :action="dataCapture.action"
          @saveAnswer="updateQuestionnaireAnswer"
          @actionTriggered="doStateAction"
          @close="dataCapture.show = false"
          @toggleHidden="
            dataCapture.action.isDataCaptureHidden =
              !dataCapture.action.isDataCaptureHidden
          "
        />
      </v-dialog>
      <CheckStatus
        :source="checkSummary"
        :open="showCheckStatus"
        @closed="closeCheckStatus"
      />
      <v-dialog v-model="suggestionsFilterDialog" max-width="800px">
        <v-card>
          <v-card-title style="margin-bottom: 10px"
            >Suggestions Filter Options:</v-card-title
          >
          <v-card-text style="height: 250px">
            <v-row>
              <v-col cols="4">Doc Parts:</v-col>
              <v-col cols="8">
                <v-select
                  v-model="suggestionPartFilter"
                  :items="suggestionTypes"
                  item-text="type"
                  item-value="type"
                  return-object
                  label=""
                  multiple
                  dense
                  hide-details
                  outlined
                  @change="filterSuggestions()"
                >
                  <template v-slot:selection="{ item, index }">
                    {{ getSuggestionTypeFilterText(item, index) }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" @click="suggestionsFilterDialog = false"
              >Close</v-btn
            >
          </v-card-actions>
          <v-divider></v-divider>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogLinks"
        scrollable
        max-width="1500px"
        min-height="1200px"
      >
        <v-card>
          <v-card-title class="justify-right">
            <v-row dense>
              <v-col cols="6" sm="6" md="6">
                <div align="center" class="justify-center">
                  Select External Files or Upload New
                </div>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <FileUploader
                  :fileUploadData="fileUploadFormData"
                  multiple="true"
                  :uploadDisabled="fileUploadDisabled"
                  :uploadedCallback="filesUploadedCallback"
                />
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text style="min-height: 500px; max-height: 500px">
            <v-divider></v-divider>
            <v-row v-if="!extDocsLoading" dense>
              <v-col
                cols="2"
                sm="2"
                md="2"
                v-for="(file, index) in fileItems"
                :key="index"
              >
                <v-card
                  class="mx-auto docList flex-column"
                  elevation="8"
                  height="250px"
                >
                  <v-app-bar
                    dense
                    dark
                    :color="resolveHeaderStyle(file.file_extension)"
                  >
                    <v-checkbox
                      v-model="file.selected"
                      :disabled="file.disabled"
                      @change="selectFiles(file.file_id, $event)"
                      style="padding-top: 7px"
                    ></v-checkbox>

                    <v-spacer></v-spacer>

                    <span center>
                      <b>{{ file.file_extension.toUpperCase() }}</b>
                      <v-icon right>
                        {{ resolveIcon(file.file_extension) }}
                      </v-icon>
                    </span>
                  </v-app-bar>

                  <v-spacer></v-spacer>
                  <v-card-subtitle>
                    <span>
                      <b>{{ file.file_name }}</b></span
                    >
                  </v-card-subtitle>
                  <v-card-text>
                    <span
                      v-html="'Created by: ' + file.created_by_user"
                      style="font-size: 0.6rem"
                    ></span>
                  </v-card-text>
                  <v-card-actions
                    style="position: absolute; bottom: 0; width: 100%"
                  >
                    <v-row>
                      <v-col cols="6" sm="6" md="6" align="left" dense> </v-col>
                      <v-col cols="6" sm="6" md="6" align="right" dense>
                        <v-icon
                          style="padding-right: 15px; padding-top: 7px"
                          @click="downloadFile(file.file_id)"
                          :color="resolveHeaderStyle(file.file_extension)"
                        >
                          mdi-cloud-download
                        </v-icon>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-if="extDocsLoading" style="height: 100%">
              <v-col
                cols="12"
                sm="12"
                md="12"
                style="margin: auto"
                align="center"
              >
                <v-progress-circular
                  :width="5"
                  color="green"
                  indeterminate
                ></v-progress-circular>
                Loading External File List...Please Wait
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
            <v-btn class="primary" text @click="linkExtDoc"
              >Link Documents ({{ selectedFiles.length }})</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar
        v-model="showErrorSnack"
        :timeout="snackTimeout"
        :color="snackColor"
        :multi-line="errorText.length > 50"
        top
      >
        <span v-html="errorText"></span>

        <template v-slot:action="{ attrs }">
          <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="showErrorSnack = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <div v-if="openTask.show">
        <v-snackbar
          v-model="openTask.data"
          color="orange darken-2"
          timeout="10000"
          multi-line
          top
          ><b>Task: </b>
          <span v-html="openTask.data.task_name"></span>
          <div v-if="openTask.data.comment">
            <b>Comment: </b>
            <span v-html="openTask.data.comment"></span>
          </div>

          <template v-slot:action="{ attrs }">
            <v-btn
              color="blue"
              text
              v-bind="attrs"
              @click="openTask.show = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </div>
      <div v-else-if="document.lifecycle_status_message">
        <v-snackbar
          v-model="document.lifecycle_status_message"
          timeout="5000"
          multi-line
          top
          ><b>NOTE: </b>
          <span v-html="document.lifecycle_status_message"></span>

          <template v-slot:action="{ attrs }">
            <v-btn
              text
              v-bind="attrs"
              @click="document.lifecycle_status_message = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </div>
      <div v-else-if="document.state.messageAction">
        <v-snackbar
          v-model="document.state.messageAction.action_message"
          timeout="-1"
          multi-line
          top
          ><b>NOTE: </b>
          <span v-html="document.state.messageAction.action_message"></span>

          <template v-slot:action="{ attrs }">
            <v-btn
              text
              v-bind="attrs"
              @click="doStateAction(document.state.messageAction)"
            >
              {{ document.state.messageAction.name }}
            </v-btn>
            <v-btn
              text
              v-bind="attrs"
              @click="document.state.messageAction.action_message = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </div>
      <NlpMenu
        :docData="nlp_annotationSections"
        @closeMenu="closeMenu()"
        :x="x"
        :y="y"
        :showMenu="showMenu"
        @updateDocPart="updateDocPartFromSource"
        :settings="nlpOptions"
        @setNlpAction="updateNlpActions"
      />
    </v-container>
    <Loading :isVisible="isLoading || isExportGenerating" />
    <v-dialog v-model="fullCompareView.show" style="background-color: white">
      <v-card>
        <v-card-title>
          Document Comparison
          <v-spacer></v-spacer>
          <v-switch
            v-model="fullCompareView.highlightChanges"
            label="Highlight Differences"
            class="mr-4"
          ></v-switch>
          <v-btn
            icon
            large
            class="btn-background"
            @click="fullCompareView.show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <DocumentVersionCompare
            class="pt-6 px-2"
            style="height: 100%"
            v-if="fullCompareView.show"
            :compareList="fullCompareView.items"
            :highlightChanges="fullCompareView.highlightChanges"
            hideCompareVersion
            multiDoc
            showWorkflowActions
            @openDocument="$emit('openDocument', $event)"
            @copyDocument="$emit('copyDocument', $event)"
          >
          </DocumentVersionCompare>
        </v-card-text>
      </v-card>
    </v-dialog>    
    <DocSummary
      :doc_id="documentSummary.doc_id"
      :trigger="documentSummary.trigger"
      @openDocument="$emit('openDocument', $event)"
      @copyDocument="$emit('copyDocument', $event)"
    ></DocSummary>
  </div>
</template>

<script>
import axios from "axios";
//import draggable from "vuedraggable";
import EditSection from "@/components/cDocumentFullEditSection";
import DocumentFullWizard from "@/components/cDocumentFullWizard";
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
// import TextEdit from "@/components/cTextEditDialogue"; // @ is an alias to /src
// import RoleFlex from "@/components/cRoleFlex"; // @ is an alias to /src
import DocumentHeader from "@/components/cDocumentHeader"; // @ is an alias to /src
import CheckStatus from "@/components/cDocumentCheckStatus";
import utils from "@/common/utils.js";
import nlpUtils from "@/common/nlpUtils.js";
import documentExport from "@/common/documentExport.js";
import { asBlob as html2docx } from "html-docx-js-typescript";
import ScrollTo from "vue-scrollto";
import FileUploader from "./cFileUpload.vue";
//import TextHighlight from "./cTextHighlight.vue";
import CountryFlag from "vue-country-flag";
import { mapState } from "vuex";
import NlpMenu from "@/components/cNlpMenu";
import DocumentActivities from "@/components/audit/cDocumentActivities";
// import DocumentComments from "@/components/cDocumentComments";
import DocumentComments from "@/components/comments/CommentsContainer";
import moment from "moment";
import DataCaptureQuestionnaire from "@/components/cDataCaptureQuestionnaire.vue";
import DataCaptureForm from "@/components/cDataCaptureForm.vue";
import DataCaptureHierarchy from "@/components/cDataCaptureHierarchy.vue";
import DataCaptureRequisitions from "@/components/cDataCaptureRequisitions.vue";
import AssignReviewGroup from "@/components/cAssignReviewGroup.vue";
import DocumentHolder from "@/components/document/Document_Holder.vue";
import DataCaptureDocumentRole from "@/components/cDataCaptureDocumentRole.vue";
import DocStatus from "@/components/common/DocStatus.vue";
import ContentDatabase from "@/components/common/ContentDatabase";
import DocumentSimilarityPanel from "@/components/document/DocumentSimilarityPanel.vue";
import DocumentVersionCompare from "@/components/audit/cDocumentVersionCompare";
import DocSummary from "@/components/common/DocViewPopUp";

export default {
  name: "DocumentFullNew",
  components: {
    ResponseHandler,
    EditSection,
    DocumentFullWizard,
    // TextEdit,
    // RoleFlex,
    DocumentHeader,
    //draggable,
    CheckStatus,
    FileUploader,
    //TextHighlight,
    CountryFlag,
    NlpMenu,
    DocumentActivities,
    DocumentComments,
    DataCaptureQuestionnaire,
    DataCaptureForm,
    DataCaptureHierarchy,
    DataCaptureRequisitions,
    AssignReviewGroup,
    DataCaptureDocumentRole,
    DocumentHolder,
    DocStatus,
    ContentDatabase,
    DocumentSimilarityPanel,
    DocumentVersionCompare,
    DocSummary
  },
  props: {
    item: null,
    documentId: null,
    viewAction: null,
    isCopied: null,
    isCopiedLink: Boolean,
    isNew: Boolean,
    useInline: Boolean,
    openFlex: Boolean,
    viewSource: Boolean,
  },
  data: function () {
    return {
      nlpUtils: nlpUtils,
      newView: true,
      currentView: null,
      headerSettings: {
        hideFeedback: false,
        hideHeaderInfo: false,
        buttonsMaxWidth: 1000,
      },
      leftPanel: "sections",
      rightPanel: "score",
      showLeft: true,
      isWarning: true,
      sectionParts: [],
      bannerText: "Test words",
      viewData: {},
      doc_view_config: null,
      current_doc_view: null,

      response: null,
      document: null,
      openTask: { show: false, data: null },
      docLoaded: false,
      docHierarchies: [],
      hierarchyTypes: [],
      wizardSettings: null,
      showWizard: false,
      dialogLinks: false,
      isLoading: false,
      isExportGenerating: false,
      editInProgress: {
        active: false,
        pt: null,
        part: null,
        subpart: null,
        isAddPart: false,
        parent: null,
      },
      textEdit: {
        text: "",
        notes: "",
        label: "text",
        show: false,
        showWordCount: true,
        showNotes: true,
        title: "",
        rules: null,
        lookup: [],
        wordChecks: [],
        allow_errors: false,
        //closed: false
      },
      wordChecks: [],
      roleFlex: {
        documentId: "",
        show: false,
        docPart: {},
      },
      dragging: false,
      draggedParent: null,
      pdfing: false,
      showHeaderDetail: false,
      showActivity: false,
      showCheckStatus: false,
      checkSummary: null,
      flowCheckSummary: null,
      checkSummaryPanels: [],
      checkSummaryView: 2,
      html_export_sections: [],
      exportMenu: false,
      docTypes: [],
      linkDef: null,
      canCopySame: false,
      canCopyOther: false,
      hasLinks: false,
      copymenu: false,
      reqDialogue: {
        show: false,
        value: null,
        exportSection: 0,
      },
      emailDialogue: {
        show: false,
        value: null,
        valid: false,
        action: null,
        sending: false,
      },
      usesTaleoFeed: false,
      usesInterviewTemplate: false,
      isInterviewTemplate: false,
      usesIntakeFlow: false,
      dpScrolled: [],
      confirmCopy: "",
      confirmCopyShow: false,
      fileUploadFormData: {},
      fileUploadDisabled: false,
      history: null,
      collabHistory: null,
      comment: {
        dp_id: null,
        part: null,
        partType: null,
        text: "",
        isDirty: false,
        isNew: false,
        tab: null,
      },
      ptDeleteHistory: null,
      partsHistory: [],
      headerHistory: [],
      headerHistoryShow: false,
      docStatus: null,
      useTranslation: false,
      translateDialog: false,
      selectdTranslateLang: {},
      documentIssueDisplay: "",
      documentLanguage: { text: "English", value: "en", flagCode: "gb" },
      isTranslating: false,
      alternateLanguageDocuments: [],
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      lookup: [],
      hint: { show: false, icon: "", title: "", subtitle: "", text: "" },
      hintsVisible: true,
      hightLightIssue: null,
      taleoReqData: [],
      awaitingTaleo: false,
      errorText: "",
      showErrorSnack: false,
      snackColor: "error",
      snackTimeout: 4000,
      connectingToTaleo: false,
      fileItems: [],
      filteredFileItems: [],
      selectedFiles: [],
      extDocsLoading: false,
      scrollbarWidth: 15,
      dataCapture: {
        show: false,
        isValid: false,
        value: null,
        action: null,
      },
      use_jd_flow: false,
      jd_flow_sections: [],
      selectedJdFlowItem: "",
      selectedJdFlowIdx: 0,
      showSuggestions: false,
      partSuggestions: [],
      selectMultipleSuggestions: false,
      suggestionsLoading: true,
      selectedSuggestions: [],
      updateDocPartData: [],
      showDatabasePanel: false,
      databaseLoading: false,
      currentSuggestionPart: "",
      filteredSuggestions: [],
      suggestionFilterText: "",
      nextSection: "",
      prevSection: "",
      enableDocCompare: true,
      displayDocMode: false,
      showMenu: false,
      x: 0,
      y: 0,
      tempNlpText: "",
      showNlpOptions: false,
      spellCheckEnabled: false,
      nlp_annotationSections: [],
      nlpOptions: [],
      nlpExclusions: [],
      uploadedDocLink: [],
      docClosing: false,
      enable_flow: false,
      isDataDrag: false,
      nlpActions: [],
      databaseDocTypeFilter: [],
      suggestionPartFilter: [],
      tempNlpHtml: "",
      suggestionsFilterDialog: false,
      defaultStatusFilterList: "approved,pre-draft,pending,draft",
      openUploadDialogue: false,
      databaseTab: 0,
      databaseLibraryFilter: "",
      databaseExternalFilters: {
        text: "",
        showMenu: false,
        includedContractTypes: [],
        availableContractTypes: [
          { text: "Permanent", value: "permanent" },
          { text: "Contract", value: "contract" },
          { text: "Temp", value: "temp" },
        ],
        includedPTFT: [],
        availablePTFT: [
          { text: "Full Time", value: "fullTime" },
          { text: "Part Time", value: "partTime" },
        ],
        minSalary: "",
        maxSalary: "",
        location: "",
        distance: 10,
        distances: [
          { text: "10 miles", value: 10 },
          { text: "20 miles", value: 20 },
          { text: "50 miles", value: 50 },
          { text: "50 miles", value: 100 },
        ],
      },
      contentGenerationSettings: null,
      generateRequest: {
        jobTitle: "",
        jobFamily: "",
        jobLevel: "",
      },
      generatedData: {
        description: null,
        skills: null,
      },
      databaseFilterTimeout: null,
      databaseRelatedHeaders: [
        { text: "Source", value: "source" },
        { text: "Type", value: "relationship" },
        { text: "Job Title", value: "doc_name" },
        { text: "", value: "actions", sortable: false },
      ],
      databaseDocHeaders: [
        { text: "Source", value: "source" },
        { text: "Job Title", value: "doc_name" },
        { text: "", value: "actions", sortable: false },
      ],
      databaseViewMode: "list",
      currentClipboardFormat: {},
      commentDialogue: {
        show: false,
        part: null,
        partType: null,
      },
      revertTrigger: null,
      suggestionTypes: [],
      databaseUploadDisabled: true,
      databaseUploadMulti: "true",
      databaseAvailableDocTypes: [],
      databaseAvailableStatuses: [],
      sourceDoc: null,
      editDisabled: false,
      headerUpdated: null,
      databaseSelectionState: {
        mouseDown: false,
        mouseUp: false,
        mouseMove: false,
        dragStart: false,
        dragEnd: false,
        data: "",
      },
      suggestionSourceItems: ["Internal"],
      suggestionsSourceIncluded: [],
      externalContentSource: "local",
      suggestionQualityThreshold: 80,
      spell_check_language_code: "en",
      exportSections: [],
      consolidatedDocumentGroup: [],
      menu: false,
      similarityTypeSelect: [
        "similar_content_different_name",
        "similar_content_same_name",
        "different_content_same_name",
      ],
      fullCompareView: { show: false, highlightChanges: false, items: [] },
      documentSummary: {
        trigger: null,
        doc_id: null,
      },  
    };
  },
  mounted() {},
  async created() {
    if (!this.item) {
      this.fetchData();
    } else {
      this.document = JSON.parse(JSON.stringify(this.item));
      this.docLoaded = true;
      this.enable_flow = this.document.enable_flow_design;
      this.use_jd_flow = this.enable_flow && this.isNew ? true : false;
      this.showDatabasePanel = false;
      this.showSuggestions = false;
      this.loadSettings();
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getScrollbarWidth);
  },
  updated() {
    this.$nextTick(function () {
      window.addEventListener("resize", this.getScrollbarWidth);
      this.getScrollbarWidth();
    });
    if (this.item) {
      this.response = null;
      this.document = JSON.parse(JSON.stringify(this.item));
      this.docLoaded = true;
    }
  },
  computed: {
    getSimilarityDocs() {
      if (this.document.matches.length === 0) return [];
      else {
        return this.document.matches.filter((obj) =>
          this.similarityTypeSelect.some((prop) => obj[prop] === true)
        );
      }
    },
    showSimilaritiesPanel() {
      return this.$loginState.user.settings.some(
        (s) => s.setting === "document_show_similarities" && s.value === "true"
      );
    },
    getMainPanelWidth() {
      let colWidth = this.showRightPanel ? 9 : 12;

      if (this.use_jd_flow) {
        colWidth = this.showFlowPanel ? colWidth - 1 : colWidth;
        colWidth = this.showDatabasePanel ? colWidth - 2 : colWidth;
      } else {
        colWidth = this.showDatabasePanel ? colWidth - 2 : colWidth;
      }

      return colWidth;
    },
    showFlowPanel() {
      if (this.use_jd_flow === true) {
        if (this.showSuggestions || this.showDatabasePanel) {
          return false;
        }
        return true;
      }

      return false;
    },
    showScoring() {
      if (
        !this.document ||
        this.showWizard ||
        !this.documentIssueDisplay ||
        !this.checkSummary ||
        !this.checkSummary.categories.length
      )
        return false;

      return !!this.document.state.showActions?.some((x) => x.showScoring);
      //   switch (this.document.show_scoring) {
      //     case "NEVER":
      //       return false;
      //     case "ALWAYS":
      //       return true;
      //     default:
      //     case "EDITORS_ONLY":
      //       return this.document.state.canEditDocParts;
      //   }
    },
    showDocumentComments() {
      if (
        !this.document ||
        this.showWizard ||
        !this.documentIssueDisplay ||
        !this.checkSummary ||
        !this.checkSummary.categories.length
      )
        return false;

      return !!this.document.state.showActions?.some(
        (x) => x.showDocumentComments
      );
    },
    showRightPanel() {
      return (
        this.showScoring ||
        this.history ||
        this.ptDeleteHistory ||
        this.headerHistoryShow ||
        this.showSuggestions ||
        this.showDatabasePanel ||
        (this.document.state.dataCaptureActionsMain &&
          this.document.state.dataCaptureActionsMain.length) ||
        this.commentDialogue.show
      );
    },
    translateOptions: function () {
      return utils.translateOptions;
    },
    scorecolumnStyle: function () {
      if (!this.newUi) return "";

      if (this.scrollbarWidth >= 4)
        return `right: ${this.scrollbarWidth}px; padding: 0 0 0 4px; overflow-y: auto;`;
      else return `right: 0;`;
    },
    geographyHierarchy() {
      const hType = this.hierarchyTypes?.find(
        (ht) =>
          ht.position === 2 &&
          !this.document.hiddenHierarchyTypes.some((h) => h === ht.ht_id)
      );
      const hValue = hType
        ? this.document?.hierarchies?.find((h) => h.ht_id === hType.ht_id)
        : undefined;
      if (!hValue || !hValue.hierarchy1) return undefined;

      return `${hType.label}: ${hValue.hierarchy1}`;
    },
    busOrgHierarchy() {
      const hType = this.hierarchyTypes?.find(
        (ht) =>
          ht.position === 3 &&
          !this.document.hiddenHierarchyTypes.some((h) => h === ht.ht_id)
      );
      const hValue = hType
        ? this.document?.hierarchies?.find((h) => h.ht_id === hType.ht_id)
        : undefined;
      if (!hValue || !hValue.hierarchy1) return undefined;

      if (
        this.$loginState.user.client === "Baker Hughes" ||
        hType.use_h2 === 0 ||
        !hValue.hierarchy2
      )
        return `${hType.label}: ${hValue.hierarchy1}`;
      else return `${hType.label}: ${hValue.hierarchy2} / ${hValue.hierarchy1}`;
    },
    ...mapState({
      config: (state) => state.settings.config,
      newUi: (state) => state.theme.newUi,
      statuses: (state) => state.hierarchies.statuses,
      docTypeSummary: (state) => state.docs.docTypeSummary,
      docStatusSummary: (state) => state.docs.docStatusSummary,
    }),
    externalContentMaxSimilarity() {
      return this.config.externalContentConfig?.maxSimilarity || 0.8;
    },
    databaseFileTypes() {
      const param =
        this.config.settings.find(
          (s) => s.setting === "document_database_file_types"
        )?.value || "docx";
      return param.split("|");
    },
    dataColWidth() {
      if (this.geographyHierarchy && this.busOrgHierarchy) return 4;
      else if (this.geographyHierarchy || this.busOrgHierarchy) return 6;
      else return 12;
    },
  },
  watch: {
    documentId(newval) {
      if (newval) {
        this.response = null;
        this.fetchData();
      }
    },
    dialogLinks(val) {
      val || this.close();
    },
    "relatedDoc.loaded"(val) {
      if (val === false) {
        (this.relatedDoc.item = null),
          (this.relatedDoc.originalDocText = ""),
          (this.relatedDoc.unmodifiedDocText = ""),
          (this.relatedDoc.optimiseText = null);
        this.relatedDoc.showOptimise = false;
      }
    },
    "libraryDoc.loaded"(val) {
      if (val === false) {
        (this.libraryDoc.item = null),
          (this.libraryDoc.originalDocText = ""),
          (this.libraryDoc.unmodifiedDocText = ""),
          (this.libraryDoc.optimiseText = null);
        this.libraryDoc.showOptimise = false;
      }
    },
    "externalDoc.loaded"(val) {
      if (val === false) {
        (this.externalDoc.item = null),
          (this.externalDoc.originalDocText = ""),
          (this.externalDoc.unmodifiedDocText = ""),
          (this.externalDoc.optimiseText = null);
        this.externalDoc.showOptimise = false;
      }
    },
    databaseTabObj(val) {
      if (
        val.id === "externalContent" &&
        this.databaseExternalDocs.length === 0
      ) {
        this.loadExternalContentList();
      } else if (
        ["jdGenerator", "skillsGenerator"].includes(val.id) &&
        !this.contentGenerationSettings
      ) {
        axios
          .get("externalContent/generateSettings/" + this.document.doc_id)
          .then((resp) => {
            if (resp.data.Status === "OK" && resp.data.Data) {
              const settings = resp.data.Data;
              this.generateRequest.jobTitle = settings.defaultJobTitle;
              this.generateRequest.jobFamily = settings.defaultJobFamily;
              this.generateRequest.jobLevel = settings.defaultJobLevel;
              this.contentGenerationSettings = settings;
            }
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              alert(err.response ? err.response.data.message : err);
            }
            console.log(err);
          });
      }
    },
    databaseTabs(val) {
      if (val) {
        const currentTab = val[this.databaseTab];
        if (!currentTab.show) this.databaseTab = val.findIndex((t) => t.show);
      }
    },
    docLoaded(val) {
      if (val) window.setTimeout(() => this.calcHeaderSpace(), 50);
    },
    "document.state.actionMenus"() {
      window.setTimeout(() => this.calcHeaderSpace(), 50);
    },
  },
  methods: {
    quickView(id, event){
      this.documentSummary.doc_id = id;
      this.documentSummary.trigger = event;
    },
    initCompare(data){
      let allList = [this.document.doc_id, ...data];
      let compareList = allList.map(d => {
        return { doc_id: d }
      });
      
      this.fullCompareView.items = compareList;
      this.fullCompareView.show = true;
    },    
    calcHeaderSpace() {
      const appbar = this.$refs["appbar"];
      const close = this.$refs["appbar-close"];
      const btns = this.$refs["appbar-buttons"];
      const icons = this.$refs["appbar-icons"];
      const hinfoWidth = 400;
      const feedbackWidth = 118;

      if (!appbar || !btns || !icons) return;

      const closeWidth = close.clientWidth;
      const btnsWidth = btns.$el.scrollWidth;
      const iconsWidth = icons.clientWidth;
      const necessaryWidth = closeWidth + btnsWidth + iconsWidth;
      const totalWidth = appbar.$el.clientWidth;

      this.headerSettings.buttonsMaxWidth =
        totalWidth - iconsWidth - closeWidth - 20;

      if (totalWidth - necessaryWidth > hinfoWidth + feedbackWidth) {
        this.headerSettings.hideFeedback = false;
        this.headerSettings.hideHeaderInfo = false;
      } else if (totalWidth - necessaryWidth > hinfoWidth) {
        this.headerSettings.hideFeedback = true;
        this.headerSettings.hideHeaderInfo = false;
      } else {
        this.headerSettings.hideFeedback = true;
        this.headerSettings.hideHeaderInfo = true;
      }
    },
    triggerFeedback() {
      if (window.userpilot) {
        window.userpilot.trigger("survey:f5KzPV_rS5");
      }
    },
    selectSuggestion(item) {
      item.selected = !item.selected;
    },
    loadDocView() {
      this.newView = true;
      this.current_doc_view = {
        document: this.document,
        docStatus: this.docStatus,
        docHierarchies: this.docHierarchies,
        partsHistory: this.partsHistory,
        wordChecks: this.wordChecks,
        sourceFile: null,
        docLanguage: this.documentLanguage,
        showComments: false,
      };
      /*this.$nextTick(() => {
          this.document = {
            ...this.document,
            doc_view_config: {...this.doc_view_config}
          };
          this.document = JSON.parse(JSON.stringify(this.document));
          this.current_doc_view = {
            document: {...this.document},
            docStatus: {...this.docStatus},
            docHierarchies: {...this.docHierarchies},
            partsHistory: {...this.partsHistory},
            wordChecks: {...this.wordChecks},
          }          
      });*/
    },
    async loadSettings() {
      this.sourceDoc = null;
      this.showDatabasePanel = false;

      this.newView = false;
      if (this.doc_view_config) {
        this.loadDocView();
      }

      this.usesDatabaseView();
      this.getClipboardExportFormat();

      this.getPartSections(this.document);

      await this.initNlpOptions();
    },
    getDatabaseViewDocTypes() {
      let setting = this.$loginState.user.settings.find(
        (s) => s.setting === "document_database_view_doc_types"
      );
      if (setting !== undefined) {
        return setting.value.split("|");
      } else {
        return [];
      }
    },
    usesDatabaseView() {
      let loadLinksSetting = this.$loginState.user.settings.some(
        (s) =>
          s.setting === "document_database_view_load_links" &&
          s.value === "true"
      );

      this.databaseViewExternalDocs = this.$loginState.user.settings.some(
        (s) =>
          s.setting === "document_database_view_allow_external" &&
          s.value === "true"
      );

      if (this.document.uploadedDocLink.length) {
        this.sourceDoc = {
          doc_type: "External File",
          file_data: {
            file_id: this.document.uploadedDocLink[0].file_id,
            row_id: this.document.uploadedDocLink[0].row_id,
            file_name: this.document.uploadedDocLink[0].file_name,
          },
        };
      }

      if (!loadLinksSetting) {
        this.showDatabasePanel = false;
      }

      if (
        this.document.use_database_view &&
        this.uploadedDocLink.length > 0 &&
        (loadLinksSetting || this.isNew) &&
        ((this.document.state.uses_workflow &&
          this.document.state.canEditDocParts) ||
          !this.document.state.uses_workflow)
      ) {
        this.showDatabasePanel = true;
        this.showDatabase();
        this.sourceDoc = {
          doc_type: "External File",
          file_data: {
            file_id: this.document.uploadedDocLink[0].file_id,
            row_id: this.document.uploadedDocLink[0].row_id,
            file_name: this.document.uploadedDocLink[0].file_name,
          },
        };

        if (this.doc_view_config && this.newView) {
          this.current_doc_view.sourceFile = this.sourceDoc;
        }
        //this.compareFileInline(this.sourceDoc);
      }
    },
    getMatchColour(val) {
      let number = Number(val);
      if (number <= 25) {
        return "red !important";
      } else if (number >= 75) {
        return "green !important";
      } else {
        return "orange !important";
      }
    },
    getSourceColor(doc) {
      switch (doc.source) {
        case "Internal":
          return "green";
        case "Upload":
          return "blue";
        case "External":
        default:
          return "orange";
      }
    },
    getChipColor(doc) {
      switch (doc.doc_status) {
        case "APPROVED":
          return "green";
        case "PENDING_APPROVAL":
          return "red";
        case "DRAFT":
        case "PRE-DRAFT":
          return "orange";
        case "ARCHIVED":
        case "TEMPLATE":
          return "blue";
        default:
          return "gray";
      }
    },
    getStatusFilterText(item, index) {
      if (item && index > 0) return "";
      if (
        this.databaseDocStatusIncluded.length === this.docStatusSummary.length
      ) {
        return this.docStatusSummary.length > 0 ? "All" : "None";
      } else {
        let defList = this.defaultStatusFilterList.split(",");
        if (
          defList.every((s) =>
            this.databaseDocStatusIncluded.some(
              (si) => si.status.toLowerCase() === s
            )
          )
        ) {
          let text = "";
          text = "Default";
          if (defList.length < this.databaseDocStatusIncluded.length) {
            text += ` (+ ${
              this.databaseDocStatusIncluded.length - defList.length
            })`;
          }
          return text;
        } else {
          return this.databaseDocStatusIncluded.reduce((prev, curr) => {
            return prev + (prev ? ", " : "") + curr.status;
          }, "");
        }
      }
    },
    getSuggestionQualityColor(val) {
      if (val >= 80) {
        return "suggestionQualityHigh";
      }
      if (val > 40 && val < 80) {
        return "suggestionQualityMed";
      }

      return "suggestionQualityLow";
    },
    getSuggestionSourceFilterText(item, index) {
      if (item && index > 0) return "";
      if (
        this.suggestionsSourceIncluded.length ===
        this.suggestionSourceItems.length
      )
        return this.suggestionSourceItems.length > 0 ? "All" : "None";

      if (this.suggestionsSourceIncluded.length > 2)
        return `${this.suggestionsSourceIncluded.length} items`;

      return this.suggestionsSourceIncluded.map((i) => i).join(", ");
    },
    getSourceFilterText(item, index) {
      if (item && index > 0) return "";
      if (this.databaseDocSource.length === this.databaseSourceItems.length)
        return this.databaseSourceItems.length > 0 ? "All Sources" : "None";

      if (this.databaseDocSource.length > 1)
        return `${this.databaseDocSource.length} Sources`;

      return this.databaseDocSource.map((i) => i).join(", ");
    },
    getDocTypeFilterText(item, index) {
      if (item && index > 0) return "";
      if (this.databaseDocTypeFilter.length === this.docTypeSummary.length)
        return this.docTypeSummary.length > 0 ? "All" : "None";

      if (this.databaseDocTypeFilter.length > 2)
        return `${this.databaseDocTypeFilter.length} items`;

      return this.databaseDocTypeFilter.map((i) => i.docType).join(", ");
    },
    getSuggestionTypeFilterText(item, index) {
      if (item && index > 0) return "";
      if (this.suggestionPartFilter.length === this.suggestionTypes.length)
        return this.suggestionTypes.length > 0 ? "All" : "None";

      if (this.suggestionPartFilter.length > 2)
        return `${this.suggestionPartFilter.length} items`;

      return this.suggestionPartFilter.map((i) => i).join(", ");
    },
    dateDoMMMYY(value) {
      if (!value) return "-";
      else return moment(value, "D MMM YYYY").format("Do MMM YYYY");
    },
    getClipboardExportFormat() {
      let format = "HTML";

      let setting = this.$loginState.user.settings.find(
        (s) => s.setting === "document_copy_format"
      );
      if (setting !== undefined) {
        let docTypes = setting.value.split("|").map((a) => {
          let parts = a.split(":");
          if (parts.length === 3) {
            return { doc_type: parts[0], format: parts[1], target: parts[2] };
          } else {
            return { doc_type: parts[0], format: parts[1] };
          }
        });

        let currentFormat = docTypes.find(
          (x) => x.doc_type === this.document.doc_type
        );
        if (currentFormat !== undefined) {
          this.currentClipboardFormat = currentFormat;
          return currentFormat.format;
        }
      }

      return format;
    },
    copyLinkToClipboard(text) {
      if (this.html_export_sections.some((x) => x === text)) {
        this.docToClipboard(text);
      } else {
        let container;
        let dialog = document.getElementsByClassName(
          "v-dialog--active v-dialog--fullscreen"
        );
        if (dialog && dialog.length) container = dialog[0];
        else container = document.body;

        const textarea = document.createElement("textarea");
        textarea.value = text;
        textarea.setAttribute("readonly", "");
        textarea.style.position = "absolute";
        textarea.style.left = "-9999px";
        container.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        container.removeChild(textarea);
      }
    },
    preventSelectionClear(event) {
      event.stopPropagation();
    },
    updateNlpActions(item) {
      this.nlpActions = item;
    },
    clearSelection() {
      if (window.getSelection) {
        if (window.getSelection().empty) {
          window.getSelection().empty();
        } else if (window.getSelection().removeAllRanges) {
          window.getSelection().removeAllRanges();
        }
      } else if (document.selection) {
        document.selection.empty();
      }
    },
    markSectionDone(direction) {
      let section = this.jd_flow_sections.find(
        (x) => x.header === this.selectedJdFlowItem
      );
      let idx = this.jd_flow_sections.indexOf(section);
      this.jd_flow_sections[idx].complete = true;
      this.navigateSection(direction);
    },
    getPrevSection() {
      if (this.use_jd_flow === true) {
        let section = this.jd_flow_sections.find(
          (x) => x.header === this.selectedJdFlowItem
        );
        let idx = this.jd_flow_sections.indexOf(section);
        if (idx > -1) {
          if (idx === 0) {
            this.prevSection = "";
          } else {
            this.prevSection = this.jd_flow_sections[idx - 1].header;
          }
        } else {
          this.prevSection = "";
        }
      }
    },
    navigateSection(direction) {
      let section = this.jd_flow_sections.find(
        (x) => x.header === this.selectedJdFlowItem
      );
      let idx = this.jd_flow_sections.indexOf(section);

      if (idx === 0) {
        this.prevSection = "";
        this.getNextSection();
        if (direction === "Fwd") {
          this.selectedSectionChange(this.nextSection);
        }
      } else if (idx === this.jd_flow_sections.length) {
        this.getPrevSection();
        this.nextSection = "";
        if (direction === "Back") {
          this.selectedSectionChange(this.prevSection);
        }
      } else {
        this.getNextSection();
        this.getPrevSection();
        if (direction === "Back") {
          this.selectedSectionChange(this.prevSection);
        } else {
          this.selectedSectionChange(this.nextSection);
        }
      }
    },
    toggleDatabaseView() {
      this.showDatabasePanel = !this.showDatabasePanel;
      if (this.showDatabasePanel) {
        //this.displayDocMode = true;
        this.showDatabase();
      }
    },
    toggleView() {
      this.use_jd_flow = !this.use_jd_flow;
      if (this.use_jd_flow) {
        if (this.jd_flow_sections.length === 0) {
          this.getPartSections(this.document);
        }
        this.getRelevantChecks(this.selectedJdFlowItem);
      } else {
        this.showSuggestions = false;
        this.showDatabasePanel = false;
        this.getRelevantChecks("");
      }
    },
    getNextSection() {
      if (this.use_jd_flow === true) {
        let section = this.jd_flow_sections.find(
          (x) => x.header === this.selectedJdFlowItem
        );
        let idx = this.jd_flow_sections.indexOf(section);
        if (idx > -1) {
          if (idx === this.jd_flow_sections.length - 1) {
            this.nextSection = "";
          } else {
            this.nextSection = this.jd_flow_sections[idx + 1].header;
          }
        } else {
          this.nextSection = "";
        }
      }
    },
    filterSuggestions() {
      this.filteredSuggestions = [];
      let filteredSuggestions = [];
      this.partSuggestions.forEach((p) => {
        let docTypeIncl = this.suggestionPartFilter.indexOf(p.type) > -1;
        let sourceMatches =
          this.suggestionsSourceIncluded.indexOf(
            p.source === "Internal" ? "Internal" : "External"
          ) > -1;

        let filterText = false;
        let matchesText = false;
        let qualityThreshold = p.quality >= this.suggestionQualityThreshold;

        if (this.suggestionFilterText !== "") {
          filterText = true;
        }

        if (filterText) {
          let searchItems = `${p.source} ${p.varVal}`;
          let idx = searchItems
            .toLowerCase()
            .indexOf(this.suggestionFilterText.toLowerCase());
          if (idx > -1) {
            matchesText = true;
          }
        } else {
          matchesText = true;
        }

        if (docTypeIncl && matchesText && sourceMatches && qualityThreshold) {
          filteredSuggestions.push(p);
        }
      });

      this.filteredSuggestions = JSON.parse(
        JSON.stringify(filteredSuggestions)
      );
    },
    toggleDatabaseDisplay() {
      if (this.databaseTabDoc && this.databaseTabDoc.loaded) {
        this.databaseTabDoc.loaded = false;
      }
      this.databaseUploadDisabled = true;
      this.databaseUploadMulti = "false";
      this.$nextTick(() => {
        this.databaseUploadDisabled = false;
        this.databaseUploadMulti = "true";
      });
    },
    async showDatabase() {
      this.showDatabasePanel = true;
    },
    closeSuggestionsPanel() {
      this.showSuggestions = false;
      this.unhighlightSection();
      this.selectedSectionChange(this.selectedJdFlowItem);
    },
    closeDatabasePanel() {
      this.showDatabasePanel = false;
    },
    loadSuggestions(ids) {
      let data = ids.map((a) => {
        return {
          tmpl_part_id: a.tmpl_part_id,
          type: a.type,
          doc_id: this.document.doc_id,
          lang: this.document.language_code,
        };
      });

      //this.currentSuggestionPart = ids[0].tmpl_part_id;
      let possibleError = false;
      this.isLoading = true;
      axios
        .post("document/getPartSuggestions/", data)
        .then((resp) => {
          possibleError = true;
          this.partSuggestions = resp.data;

          this.suggestionTypes = [
            ...new Set(this.partSuggestions.map((item) => item.type)),
          ];
          this.suggestionPartFilter = [
            ...new Set(this.partSuggestions.map((item) => item.type)),
          ];

          this.filterSuggestions();
          this.isLoading = false;
          this.suggestionsLoading = false;
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
          this.isLoading = false;
        });
    },
    showSuggestionsPanel(parts) {
      this.databaseViewExternalDocs = this.$loginState.user.settings.some(
        (s) =>
          s.setting === "document_database_view_allow_external" &&
          s.value === "true"
      );

      this.suggestionFilterText = "";
      this.suggestionQualityThreshold = 80;

      if (this.databaseViewExternalDocs) {
        if (this.suggestionSourceItems.indexOf("External") === -1) {
          this.suggestionSourceItems.push("External");
        }
        if (this.suggestionsSourceIncluded.indexOf("External") === -1) {
          this.suggestionsSourceIncluded.push("External");
        }
      }

      let ids = parts.map((a) => {
        return { tmpl_part_id: a.tmpl_part_id, type: a.type };
      });
      this.suggestionsLoading = true;
      this.resetRightPanel();
      this.selectedSuggestions = [];
      this.selectMultipleSuggestions = false;
      this.showSuggestions = true;
      this.loadSuggestions(ids);
    },
    selectedSectionChange(key) {
      this.selectedJdFlowItem = key;
      let section = this.jd_flow_sections.find(
        (x) => x.header === this.selectedJdFlowItem
      );
      let idx = this.jd_flow_sections.indexOf(section);
      this.selectedJdFlowIdx = idx;
      this.getRelevantChecks(key);
      this.getNextSection();
      this.getPrevSection();
      let sectionHasSuggestions = this.sectionHasSuggestions();

      if (this.showSuggestions) {
        if (sectionHasSuggestions) {
          let ids = this.document.parttypes
            .filter(
              (x) =>
                x.tmpl_part_metadata.part_section === this.selectedJdFlowItem &&
                x.tmpl_part_metadata.suggestion_part === true
            )
            .map((a) => {
              return { tmpl_part_id: a.tmpl_part_id, type: a.type };
            });
          this.loadSuggestions(ids);
        } else {
          this.partSuggestions = [];
          this.filteredSuggestions = [];
        }
      }
    },
    sectionHasSuggestions() {
      let targetParts = this.document.parttypes.filter(
        (x) => x.tmpl_part_metadata.part_section === this.selectedJdFlowItem
      );
      let suggestions = targetParts.filter(
        (x) => x.tmpl_part_metadata.suggestion_part === true
      );
      if (suggestions !== undefined && suggestions.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    getRelevantChecks(key) {
      if (this.use_jd_flow === true) {
        if (this.wordChecks !== null) {
          let section = this.jd_flow_sections.find((x) => x.header === key);
          if (section) {
            let section_items = section.parts;
            let sectionIds = section_items.map((pt) => pt.tmpl_part_id);

            let newChecks = this.wordChecks.filter((x) => {
              let partTypes = x.tmpl_part_types.map((pt) => pt.tp_id);
              if (partTypes.some((item) => sectionIds.includes(item))) {
                return x;
              }
            });

            this.checkSummary = utils.documentCheckSummary(
              this.document,
              newChecks,
              this.checkSummaryView,
              sectionIds
            );

            this.flowCheckSummary = utils.documentCheckSummary(
              this.document,
              newChecks,
              this.checkSummaryView,
              sectionIds
            );

            this.$nextTick(() => {
              this.checkSummaryPanels = [];
            });
          }
        }
      } else {
        this.checkSummary = utils.documentCheckSummary(
          this.document,
          this.wordChecks,
          this.checkSummaryView,
          undefined
        );
      }
    },
    getPartSections(doc) {
      if (this.use_jd_flow === true) {
        let allParts = doc.parttypes.filter(
          (x) => x.tmpl_part_metadata.part_section !== null
        );
        let partGroups = [];
        allParts.reduce((groups, item) => {
          let section = partGroups.find(
            (x) => x.header == item.tmpl_part_metadata.part_section
          );
          if (section === undefined) {
            section = {
              header: item.tmpl_part_metadata.part_section,
              parts: [],
              complete: false,
            };
            section.parts.push(item);
            partGroups.push(section);
          } else {
            section.parts.push(item);
          }
          return groups;
        }, {});
        partGroups.push({
          header: "Full " + this.document.doc_type,
          parts: allParts,
        });
        this.jd_flow_sections = partGroups;
        this.selectedSectionChange(allParts[0].tmpl_part_metadata.part_section);
      }
    },
    generateContent(contentType) {
      if (this.databaseLoading) return;

      const optimse = ["optimise", "skills_extract"].includes(contentType);

      if (
        optimse &&
        this.databaseTabDoc &&
        this.databaseTabDoc.optimiseText &&
        this.databaseTabDoc.optimiseContentType === contentType &&
        !this.databaseTabDoc.showOptimise
      ) {
        this.databaseTabDoc.showOptimise = true;
        return;
      }

      const data = {
        contentType: contentType,
        ...this.generateRequest,
        language: this.documentLanguage?.text,
        spelling: this.spell_check_language_code,
        text: optimse ? this.databaseTabDoc?.originalDocText : undefined,
      };
      this.databaseLoading = true;
      this.generatedData[contentType] = null;
      axios
        .post("externalContent/generate", data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            if (optimse && this.databaseTabDoc) {
              this.databaseTabDoc.optimiseText = resp.data.Data;
              this.databaseTabDoc.optimiseContentType = contentType;
              this.databaseTabDoc.showOptimise = true;
            } else {
              this.generatedData[contentType] = resp.data.Data;
            }
          } else {
            this.response = resp.data;
          }
          this.databaseLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
          this.databaseLoading = false;
        });
    },
    async initNlpOptions() {
      this.displayDocMode = false;
      this.nlpOptions - [];
      let nlpOptions = nlpUtils.getNlpActions(this.$loginState.user.settings);

      this.nlpOptions = [...new Set(nlpOptions)];

      /*await nlpUtils.getFiles().then((response) => {
        if (response.status == 200) {
          this.fileList = response.data;
          this.selectDoc = true;
        } else {
          this.triggerNotification(response.data.Message, "error");
        }
      });*/

      /*if(nlpOptions !== []){
        let docAnnotation = nlpOptions.find((s) => s.text === 'EnableDocCompare');
        this.enableDocCompare = docAnnotation.enabled;
      }*/

      /*if(this.document.linkedDocs !== undefined){
        let linkedExtFile = this.document.linkedDocs.find(x => x.tt_name === "External File");
        if(linkedExtFile !== undefined){
          this.selectDoc = false;
          await nlpUtils.getDocText(linkedExtFile.file_data.file_id).then(response => {
            if(response.data.Status == "OK"){
              this.originalDocText = response.data.Data[0].htmlContent.replaceAll("<em>","").replaceAll("</em>","");
              this.unmodifiedDocText = response.data.Data[0].htmlContent.replaceAll("<em>","").replaceAll("</em>","");
              this.setParts();
            } else {
              this.triggerNotification(response.data.Message,"error");
            }
          });  
        }

      } else {

      }*/
    },
    async updateDocPartFromSource(part) {
      let baseText = this.tempNlpText;
      let counter = 0;
      if (part.actions.length > 0) {
        part.actions.forEach(async (action) => {
          switch (action.type) {
            case "summarise":
              await nlpUtils
                .doSummarise(baseText, action.params.max_sent)
                .then((resp) => {
                  baseText = resp.data.Data.summary;
                  counter++;
                });
              break;
          }

          if (counter === part.actions.length) {
            this.updateDocPartData = [
              {
                part: part,
                text: baseText,
                html: this.tempNlpHtml,
              },
            ];
          }
        });
      } else {
        this.updateDocPartData = [
          {
            part: part,
            text: baseText,
            html: this.tempNlpHtml,
          },
        ];
      }
    },
    checkSummaryUpdated(checkSummary) {
      if (this.use_jd_flow === true) {
        this.getRelevantChecks(this.selectedJdFlowItem);
        this.closeCheckStatus();
      } else {
        this.checkSummary = checkSummary;
        this.document.error_count = this.checkSummary.errors.length;
        this.document.warning_count = this.checkSummary.warnings.length;
        this.$store.dispatch("docs/updateDoc", this.document);
      }
      if (this.checkSummary.categories && this.checkSummary.categories.length) {
        this.allCheckSummaryPanels();
      }
    },
    filterLanguageDocs(target) {
      if (target) {
        return this.alternateLanguageDocuments.filter(
          (lang) => lang.language_code === target
        );
      } else {
        return this.alternateLanguageDocuments.filter(
          (lang) => lang.language_code !== "en"
        );
      }
    },
    showWordCommentsExport() {
      if (
        this.$loginState.user.client === "Sage" &&
        (this.document.doc_type === "Flex Assess" ||
          this.document.doc_type === "Flex Apply")
      ) {
        return false;
      }

      return true;
    },
    getLangCodeFlag(item) {
      let targetLang = utils.translateOptions.find((x) => x.value == item);
      return targetLang ? targetLang.flagCode : "";
    },
    getLangCodeName(item) {
      let targetLang = utils.translateOptions.find((x) => x.value == item);
      return targetLang ? targetLang.text : "";
    },
    openTranslatedDoc(item) {
      this.closeTranslateDialog();
      this.$emit("openDocument", {
        system_number: utils.removeTags(item.system_number),
        doc_name: utils.removeTags(item.doc_name),
        doc_type: item.doc_type,
        viewAction: item.viewAction,
      });
    },
    copyAndTranslateDoc(item) {
      const createOption = this.$loginState.user.createDocumentOptions.find(
        (a) => a.tmpl_id === this.document.tmpl_id
      );

      if (!createOption) {
        this.triggerNotification("No permission to create document", "error");
        this.closeTranslateDialog();
        return;
      }

      const copyOption = this.document.state.copyActions.find(
        (a) =>
          a.tmpl_id_copy_destination === this.document.tmpl_id &&
          a.allow_translate === 1
      );

      if (!copyOption) {
        this.triggerNotification("No permission to copy document", "error");
        this.closeTranslateDialog();
        return;
      }

      this.isTranslating = true;
      let action;
      if (createOption.lifecycles && createOption.lifecycles.length === 1) {
        action = createOption.lifecycles[0];
      } else if (
        createOption.lifecycles &&
        createOption.lifecycles.length > 1
      ) {
        action = createOption.lifecycles.find((x) => x.isDefault);
      }

      let useGateway = this.$loginState.user.settings.some(
        (s) => s.setting === "use_event_gateway" && s.value === "true"
      );
      if (useGateway) {
        this.$eventGateway.events.push("Translation Request Received");
      }
      let targetLang = utils.translateOptions.find((x) => x.value == item);
      this.documentLanguage = targetLang;
      let queryTargetLang = this.selectdTranslateLang
        ? this.selectdTranslateLang
        : "";

      let data = {
        doc_data: {
          source_doc_id: this.document.doc_id,
          tmpl_id: this.document.tmpl_id,
          action: action,
          action_available_id_copy: copyOption.action_available_id,
        },
        targetLang: queryTargetLang,
      };

      axios
        .post("document/copyDocAndTranslate/", data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            let useGateway = this.$loginState.user.settings.some(
              (s) => s.setting === "use_event_gateway" && s.value === "true"
            );
            if (useGateway) {
              this.triggerNotification(
                "Document Translate Request Queued",
                "success"
              );
              this.closeTranslateDialog();
              this.isTranslating = false;
            } else {
              this.closeTranslateDialog();
              this.isTranslating = false;
              let doc = resp.data.Data.documents[0];
              this.$emit("openDocument", doc);
            }
          } else {
            alert(resp.data.Message);
          }
          this.response = resp.data;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.sessionExpired(err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    getFlagCode(item) {
      return item.item.flagCode;
    },

    translateTextDialog() {
      this.translateDialog = true;
    },
    closeTranslateDialog() {
      this.translateDialog = false;
    },
    translateText(item) {
      let targetLang = utils.translateOptions.find((x) => x.value == item);
      this.documentLanguage = targetLang;
      this.closeTranslateDialog();
      this.fetchData(this.selectdTranslateLang);
    },
    uploadDatabaseDoc() {
      this.setupUploadData();
      this.fileUploadFormData.link_doc_id = this.document.doc_id;
      this.openUploadDialogue = true;
    },
    setupUploadData() {
      this.fileUploadFormData = {
        hr_ids: [],
        doc_id: [],
        parseDoc: false,
      };

      this.fileUploadDisabled = true;
      this.createFiles = false;

      this.$nextTick(() => {
        this.fileUploadDisabled = false;
        this.fileUploadFormData.createFiles = false;
        this.fileUploadFormData = JSON.parse(
          JSON.stringify(this.fileUploadFormData)
        );
      });
    },
    fetchData(targetLang) {
      this.isLoading = true;
      this.docLoaded = false;
      this.openUploadDialogue = false;
      this.showActivity = false;
      this.resetRightPanel();
      this.consolidatedDocumentList = [];
      let viewAction = this.viewAction
        ? JSON.parse(JSON.stringify(this.viewAction))
        : null;
      utils
        .fetchDocumentData(
          this.documentId,
          this.statuses,
          targetLang,
          this.selectdTranslateLang,
          viewAction,
          this.$store
        )
        .then(async (resp) => {
          if (resp.Status !== "OK") this.closeDocument(resp);
          else {
            this.document = resp.document;
            this.alternateLanguageDocuments = resp.alternateLanguageDocuments;
            this.documentLanguage = resp.documentLanguage;
            this.docStatus = resp.docStatus;
            this.docHierarchies = resp.docHierarchies;
            this.hierarchyTypes = resp.hierarchyTypes;
            this.wizardSettings = resp.wizardSettings;
            this.showWizard = resp.wizardSettings?.open_by_default === true;
            this.headerHistory = resp.headerHistory;
            this.documentIssueDisplay = resp.documentIssueDisplay;
            this.html_export_sections = resp.html_export_sections;
            this.isInterviewTemplate = resp.isInterviewTemplate;
            this.partsHistory = resp.partsHistory;
            this.usesTaleoFeed = resp.usesTaleoFeed;
            this.useTranslation =
              (!this.document.state.uses_workflow && resp.useTranslation) ||
              (this.document.state.uses_workflow &&
                this.document.state.copyActions.some((x) => x.allow_translate));
            this.wordChecks = resp.wordChecks;
            this.nlpExclusions = resp.nlpFunctionExclusions;
            this.enable_flow = this.document?.enable_flow_design;
            this.uploadedDocLink = resp.uploadedDocLink;
            this.spell_check_language_code = resp.spell_check_language_code;
            this.exportSections = resp.exportSections;
            this.doc_view_config = resp.doc_view_config;
            this.exportConfig = resp.exportConfig;
            this.consolidatedDocumentGroup = resp.consolidatedDocumentGroup;

            this.use_jd_flow = this.enable_flow && this.isNew ? true : false;
            this.showDatabasePanel = false;
            this.showSuggestions = false;
            this.setupDocTypes(resp);
            this.loadSettings();
            this.docLoaded = true;
            if (viewAction && viewAction.task) {
              this.openTask.data = viewAction.task;
              this.openTask.show = true;
            } else if (resp.openTasks && resp.openTasks.length) {
              this.openTask.data = resp.openTasks[0];
              this.openTask.show = true;
            } else {
              this.openTask.show = false;
            }
            // if hierarchies not present or copy same doc type show the header
            if (
              resp.hierarchyTypes.length >
                resp.docHierarchies.filter((x) => x.hr_id).length ||
              (this.isCopied && !this.isCopiedLink)
            ) {
              this.openHeader();
            }
            this.checkForTriggerAction();
            if (
              !resp.document.state.showActions?.some((x) => x.showScoring) &&
              resp.document.state.showActions?.some(
                (x) => x.showDocumentComments
              )
            ) {
              this.showComments();
            }
          }
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.$emit(
              "documentLoadError",
              err.response ? err.response.data : { message: "Unexpected Error" }
            );
          }
          this.isLoading = false;
        });
    },
    checkForTriggerAction() {
      if (this.document.state.triggerDCAction) {
        // mandatory data missing
        this.doStateAction(this.document.state.triggerDCAction);
        this.editDisabled = true;
        this.triggerNotification(
          `The document cannot be edited until ${this.document.state.triggerDCAction.name} is entered`,
          "error",
          10000
        );
      } else if (this.document.state.roleCaptureTriggerAction) {
        // role missing
        this.doStateAction(this.document.state.roleCaptureTriggerAction);
        this.triggerNotification(
          `The document requires ${this.document.state.roleCaptureTriggerAction.name}`,
          "info",
          10000
        );
      } else {
        this.editDisabled = false;
      }
    },
    setupDocTypes() {
      this.docTypes = JSON.parse(
        JSON.stringify(this.$store.state.hierarchies.docTypes)
      );
      this.linkDef = this.docTypes.find(
        (x) => x.tmpl_id === this.document.tmpl_id
      );
      this.docTypes.forEach((dt) => {
        dt.docs = this.document.linkedDocs.filter(
          (ld) => ld.tmpl_id === dt.tmpl_id
        );
        dt.hasLinks = this.linkDef.linked_templates?.some(
          (x) => x.tmpl_id === dt.tmpl_id && dt.docs.length
        );
        dt.canCreate =
          this.$loginState.user.createDocumentOptions.some(
            (a) => a.tmpl_id === dt.tmpl_id
          ) &&
          (dt.tmpl_id === this.document.tmpl_id ||
            this.linkDef.linked_templates?.some(
              (x) =>
                x.tmpl_id === dt.tmpl_id &&
                (x.max_links === 0 || dt.docs.length < x.max_links)
            ));
        if (dt.canCreate && this.document.state.uses_workflow) {
          dt.createAction = this.document.state.copyActions?.find(
            (x) => x.tmpl_id_copy_destination === dt.tmpl_id
          );
          if (!dt.createAction) dt.canCreate = false;
        }
      });
      this.canCopySame = this.docTypes.some(
        (d) => d.tmpl_id === this.document.tmpl_id && d.canCreate
      );
      this.canCopyOther = this.docTypes.some(
        (d) => d.tmpl_id != this.document.tmpl_id && d.canCreate
      );
      this.hasLinks = this.docTypes.some(
        (d) => d.tmpl_id != this.document.tmpl_id && d.hasLinks
      );
      this.docStatus = this.statuses.find(
        (x) => x.status === this.document.doc_status
      );
      this.textEdit.allow_errors = this.document.state.allow_errors;
    },
    resolveHeaderStatusStyle(item) {
      return utils.resolveHeaderStyle(item);
    },
    revertChange(item) {
      this.$refs.editSection.revertChange(item);
    },
    revertDelete(item) {
      this.$refs.editSection.revertDelete(item);
    },
    saveHierarchy(hc) {
      this.isLoading = true;
      let data = {
        doc_id: this.document.doc_id,
        system_number: this.document.system_number,
        ht_id: hc.ht_id,
        hr_id: hc.hr_id,
        viewAction: this.document.viewAction,
      };

      axios
        .post("document/saveDocumentHierarchy/", data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            this.documentHeaderSaved(resp.data);
          } else {
            resp.data.Message;
          }
          this.response = resp.data;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    revertHierarchy(hc) {
      this.isLoading = true;
      let data = {
        doc_id: this.document.doc_id,
        system_number: this.document.system_number,
        ht_id: hc.ht_id,
        hr_id: hc.oldValue,
      };

      axios
        .post("document/saveDocumentHierarchy/", data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            this.documentHeaderSaved(resp.data);
          } else {
            resp.data.Message;
          }
          this.response = resp.data;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    rFlex(pt, documentId) {
      this.roleFlex.show = true;
      this.roleFlex.documentId = documentId;
      this.roleFlex.docPart = pt;
    },
    openCheckStatus() {
      this.showCheckStatus = true;
    },
    setCheckSummary() {
      this.checkSummary = utils.documentCheckSummary(
        this.document,
        this.wordChecks,
        this.checkSummaryView
      );
      if (this.checkSummary.categories && this.checkSummary.categories.length) {
        this.allCheckSummaryPanels();
      }
    },
    allCheckSummaryPanels() {
      this.checkSummaryPanels = this.checkSummary.categories.map((c, i) => i);
    },
    closeCheckSummaryPanels() {
      this.checkSummaryPanels = [];
    },
    closeCheckStatus() {
      this.showCheckStatus = false;
    },
    openActivity() {
      this.showActivity = true;
    },
    activityClosed() {
      this.showActivity = false;
    },
    openHeader() {
      this.showHeaderDetail = true;
    },
    documentHeaderClosed() {
      this.showHeaderDetail = false;
    },
    documentRefreshed(responseData) {
      const data = utils.setupDocument(responseData.documents, this.wordChecks);
      if (data) {
        this.document = data.document;
        // this.docHierarchies = data.docHierarchies;
        // this.headerHistory = data.headerHistory;
        this.html_export_sections = data.html_export_sections;
        // this.usesTaleoFeed = data.usesTaleoFeed;
        // this.isInterviewTemplate = data.isInterviewTemplate;

        if (data.document.doc_view_config) {
          this.loadDocView();
        } else {
          this.newView = false;
          this.current_doc_view = null;
        }

        this.setupDocTypes();
        this.setCheckSummary();
        data.document.error_count = this.checkSummary.errors.length;
        data.document.warning_count = this.checkSummary.warnings.length;
        this.$store.dispatch("docs/updateDoc", data.document);
        // this.checkForTriggerAction();
        this.headerUpdated = Date.now();
      }
    },
    documentHeaderSaved(responseData) {
      const data = utils.setupDocument(
        responseData.Data.documents,
        this.wordChecks
      );

      if (data) {
        this.document = data.document;
        this.docHierarchies = data.docHierarchies;
        this.headerHistory = data.headerHistory;
        this.html_export_sections = data.html_export_sections;
        this.usesTaleoFeed = data.usesTaleoFeed;
        this.isInterviewTemplate = data.isInterviewTemplate;

        if (data.document.doc_view_config) {
          this.loadDocView();
        } else {
          this.newView = false;
          this.current_doc_view = null;
        }

        this.setupDocTypes();
        this.setCheckSummary();
        data.document.error_count = this.checkSummary.errors.length;
        data.document.warning_count = this.checkSummary.warnings.length;
        this.$store.dispatch("docs/updateDoc", data.document);
        this.checkForTriggerAction();
        this.headerUpdated = Date.now();
      }

      this.showHeaderDetail = false;
      this.$emit("documentHeaderSaved", this.document);
      this.response = responseData;
    },
    async closeDocument(data) {
      if (this.document && this.checkSummary && this.checkSummary.score) {
        this.$store.dispatch("docs/updateScore", {
          doc_id: this.document.doc_id,
          overall_score: this.checkSummary.score,
          overall_score_color: this.checkSummary.scoreColour,
        });
      }
      this.closeDatabasePanel();
      this.docClosing = true;
      this.editInProgress.active = false;
      this.editInProgress.pt = null;
      this.editInProgress.part = null;
      this.editInProgress.subpart = null;
      this.showErrorSnack = false;
      if (data) this.$emit("documentLoadError", data);
      else this.$emit("closeDocument", this.document);
      this.docLoaded = false;
      this.docClosing = false;
    },
    closeAndRemoveFromList(data) {
      if (this.document && this.document.doc_id)
        this.$store.dispatch("docs/removeDoc", this.document.doc_id);
      this.closeDocument(data);
    },
    close() {
      this.isLoading = false;
      this.isExportGenerating = false;
      this.dialogLinks = false;
    },
    copyCheck() {
      let text = "";
      if (this.checkSummary) {
        if (this.checkSummary.errors.length) {
          text = `${this.checkSummary.errors.length} error${
            this.checkSummary.errors.length > 1 ? "s" : ""
          }`;
        }
        if (this.checkSummary.warnings.length) {
          if (text) text += " and ";
          text += `${this.checkSummary.warnings.length} warning${
            this.checkSummary.warnings.length > 1 ? "s" : ""
          }`;
        }
        if (text) {
          this.confirmCopy = `The document has ${text}. Are you sure you want to copy it?`;
          this.confirmCopyShow = true;
          return false;
        }
      }
      return true;
    },
    doCopy(copyAction) {
      this.confirmCopyShow = false;
      this.$emit(
        "copyDocument",
        this.document,
        copyAction.tmpl_id_destination,
        null,
        copyAction.action_available_id
      );
    },
    copyDocument() {
      if (this.copyCheck()) {
        this.linkDocumentAction(null, "copy");
      }
    },
    filesUploadedCallbackDatabase(data) {
      this.$store.dispatch("files/reload");
      if (!this.showDatabasePanel) {
        this.toggleDatabaseView();
      }

      if (data.length) {
        this.sourceDoc = {
          doc_type: "External File",
          file_data: {
            file_id: data[0].file_id,
            row_id: null,
            file_name: data[0].file_data.file_name,
          },
        };
        if (this.doc_view_config && this.newView && this.current_doc_view) {
          this.current_doc_view.sourceFile = this.sourceDoc;
        }
      }
    },
    filesUploadedCallback(data) {
      this.$store.dispatch("files/reload");
      //this.$emit("copyDocument", this.document, this.tmpTargetDocTmplId, data);
      data.forEach((file) => {
        file.file_data.selected = true;
      });
    },
    setDocsLinked() {
      this.document.linkedDocs.forEach((doc) => {
        if (doc.tmpl_name == "External File") {
          let tmpId = doc.file_data.file_id;
          let fileItem = this.fileItems.find((x) => x.file_id == tmpId);
          if (fileItem) {
            fileItem.selected = true;
            fileItem.disabled = true;
          }
        }
      });

      this.fileItems = JSON.parse(JSON.stringify(this.fileItems));
    },
    linkDocumentAction(docType, action) {
      this.copymenu = false;
      this.confirmCopyShow = false;
      if (!docType && this.document.state.uses_workflow) {
        docType = {
          createAction: this.document.state.copyActions.find(
            (x) => x.tmpl_id_copy_destination === this.document.tmpl_id
          ),
        };
      }
      if (action) {
        switch (action) {
          case "copy":
          case "link":
            if (docType && docType.createAction) {
              this.doStateAction(docType.createAction);
            } else if (docType && docType.tmpl_name == "External File") {
              this.tmpTargetDocTmplId = docType.tmpl_id;
              this.dialogLinks = true;
              this.setupUploadData();
              return;
            } else {
              this.$emit(
                "copyDocument",
                this.document,
                docType ? docType.tmpl_id : null
              );
            }
            break;
          case "view":
            this.$emit("openDocument", docType);
            break;
        }
      } else {
        switch (docType.Action.toLowerCase()) {
          case "copy":
            if (docType && docType.createAction) {
              this.doStateAction(docType.createAction);
            } else {
              this.$emit("copyDocument", this.document, docType.tmpl_id);
            }
            break;
          case "view":
            this.$emit("openDocument", docType);
            break;
        }
      }
    },
    validateForCopy: function () {},
    generatePDF() {
      this.isExportGenerating = true;
      const serverSidePdfs = this.config.settings.some(
        (s) => s.setting === "document_serverside_pdf" && s.value === "true"
      );
      documentExport
        .generateDocumentExport("PDF", this.document, serverSidePdfs)
        .then(() => {
          this.isExportGenerating = false;
        });
      this.exportMenu = false;
    },
    newWorkingPattern(html, wordCount) {
      let newVal = utils.partToSubParts(html);
      let wpPartType = this.document.parttypes.find(
        (pt) => pt.type === "Working Pattern"
      );
      wpPartType.parts[0].subParts = newVal;
      wpPartType.parts[0].text = html;
      wpPartType.wordCount = wordCount;
    },
    highlightSection(issue) {
      this.hightLightIssue = issue;
    },
    unhighlightSection() {
      this.hightLightIssue = null;
    },
    scrollToSection(issue) {
      let options = {
        container: ".v-dialog--active.v-dialog--fullscreen", //this.$refs.doccontainer, //"#container",
        easing: "ease-in",
        offset: -150,
        force: true,
        cancelable: true,
        // onStart: function (element) {
        //   // scrolling started
        // },
        // onDone: function (element) {
        //   // scrolling is done
        // },
        // onCancel: function () {
        //   // scrolling has been interrupted
        // },
        x: false,
        y: true,
      };
      let next;
      let thisSet = [];
      let processing = true;
      while (processing) {
        issue.items.forEach((t) => {
          t.instances.forEach((i) => {
            i.partsAffected.forEach((pa) => {
              if (this.dpScrolled.indexOf(pa) >= 0) {
                thisSet.push(pa);
              } else if (!next) {
                next = pa;
                thisSet.push(pa);
              }
            });
          });
        });
        if (
          this.dpScrolled.length === thisSet.length &&
          thisSet.length > 0 &&
          this.dpScrolled[thisSet.length - 1] === thisSet[thisSet.length - 1]
        ) {
          this.dpScrolled.splice(0);
          thisSet.splice(0);
        } else {
          processing = false;
        }
      }
      this.dpScrolled = thisSet;
      ScrollTo.scrollTo("#dp" + next, 500, options);
    },
    showPartHistory(p, pt, collab) {
      this.resetRightPanel();
      this.displayDocMode = false;
      this.collabHistory = !!collab;
      this.comment.dp_id = p.doc_part_id;
      this.comment.part = collab ? p : null;
      this.comment.partType = collab ? pt : null;
      this.history = this.partsHistory
        ? this.partsHistory.find((pa) => pa.key === p.doc_part_id)
        : null;
      this.unhighlightSection();
      this.hightLightIssue = {
        items: [{ instances: [{ partsAffected: [p.doc_part_id] }] }],
      };
    },
    showDeletedPartHistory(pt) {
      this.resetRightPanel();
      this.ptDeleteHistory = this.partsHistory
        ? this.partsHistory.filter((pa) => pa.isDeleted && pa.name === pt.type)
        : null;
      this.unhighlightSection();
    },
    showheaderHistory() {
      this.resetRightPanel();
      this.headerHistoryShow = true;
      this.unhighlightSection();
    },
    showHint(pt, why, text) {
      this.hint.text =
        text ||
        (why
          ? pt.tmpl_part_metadata.why_text
          : pt.tmpl_part_metadata.how_to_text);
      this.hint.icon = why ? "mdi-lightbulb-outline" : "mdi-help";
      this.hint.title = why ? "Insights" : "How/Why?";
      this.hint.subtitle = pt ? pt.type : "Document";
      this.hint.show = true;
    },
    partHasHover(p) {
      p.hover = true;
    },
    partLostHover(p) {
      p.hover = false;
    },
    emailDoc() {
      if (!this.$refs.emailDocForm.validate()) {
        return;
      }

      if (this.emailDialogue.action) {
        this.emailDialogue.sending = true;
        this.doStateAction(this.emailDialogue.action, true);
        return;
      }

      this.emailDialogue.sending = true;
      let data = {
        to_address: this.emailDialogue.value,
        docs: [this.document.system_number],
      };

      axios
        .post("document/emaildoc/", data)
        .then((resp) => {
          if (resp.data.Status == "OK") {
            this.triggerNotification(
              "Document Emailed Successfully",
              "success"
            );
            this.closeEmailDialogue();
            this.exportMenu = false;
          } else {
            this.triggerNotification(
              resp.data?.Message || "Error sending email",
              "error"
            );
            this.closeEmailDialogue();
            this.exportMenu = false;
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.triggerNotification(err, "error");
          }
          this.isLoading = false;
        });
    },
    closeEmailDialogue() {
      this.emailDialogue.show = false;
      this.emailDialogue.action = null;
      this.emailDialogue.value = "";
      this.emailDialogue.valid = false;
      this.emailDialogue.sending = false;
    },
    sendToTaleo() {
      if (this.$loginState.canDemo) {
        this.response = {
          Message: "Document posted to Workday",
          Data: null,
          Status: "OK",
          RowsUpdated: 0,
        };
        this.exportMenu = false;
      } else {
        if (this.document.doc_status === "APPROVED") {
          this.reqDialogue = {
            show: true,
            value: null,
            exportSection: 0,
          };
        } else {
          this.triggerNotification(
            "Only documents in Approved status can be Exported",
            "error"
          );
        }
      }
    },
    async sendDataToTaleo() {
      if (!this.reqDialogue.value) {
        alert("Requisition Number must be entered");
        return;
      }
      this.awaitingTaleo = true;

      let exportSectionId =
        this.exportSections.length && this.reqDialogue.exportSection
          ? this.reqDialogue.exportSection
          : 0;

      let mm = `<br><p><b>Build your career at Akamai</b></p><p>Our ability to shape digital life today relies on developing exceptional people like you. The kind that can turn impossible into possible. We’re doing everything we can to make Akamai a great place to work. A place where you can learn, grow and have a meaningful impact. </p>`;
      mm += `<br><p>With our company moving so fast, it’s important that you’re able to build new skills, explore new roles, and try out different opportunities. There are so many different ways to build your career at Akamai, and we want to support you as much as possible. We have all kinds of development opportunities available, from programs such as GROW and Mentoring, to internal events like the APEX Expo and tools such as Linkedin Learning, all to help you expand your knowledge and experience here.</p>`;
      mm += `<br><p><b>Learn more</b></p><p>Not sure if this job is the right match for you or want to learn more about the job before you apply? Schedule a 15-minute exploratory call with the Recruiter and they would be happy to share more details. Here is the contact info for the recruiter assigned to this position:</p><br>`;

      let intHtml = await documentExport.docToHTML(
        this.document,
        "internal",
        "HTML",
        false,
        this.reqDialogue.USA
      );
      let extHtml = await documentExport.docToHTML(
        this.document,
        "external",
        "HTML",
        false,
        this.reqDialogue.USA
      );

      let data = {
        ats_reference: this.reqDialogue.value,
        htmlInt:
          intHtml
            .replace(/‘/g, "'")
            .replace(/’/g, "'")
            .replace(/[^\000-\177]/g, "") + mm,
        htmlExt: extHtml
          .replace(/‘/g, "'")
          .replace(/’/g, "'")
          .replace(/[^\000-\177]/g, ""),
        reference: this.document.system_number,
        title: this.document.parttypes
          .find(
            (t) =>
              t.type === "Job Posting Title" || t.type === "Advert Job Title"
          )
          .parts[0].text.replace(/&/g, "&amp;"),
        usp: this.document.parttypes
          .find((t) => t.type === "Job USP")
          .parts[0].text.replace(/&/g, "&amp;"),
        USA: this.reqDialogue.USA,
        doc_id: this.document.doc_id,
        exportSectionId: exportSectionId,
      };

      axios
        .post("client/pingtaleo/", data)
        .then((resp) => {
          if (resp.data.code == "OK" || resp.data.Status == "OK") {
            this.triggerNotification(resp.data.Message, "success");
          } else {
            if (this.integrationType == "SOAP") {
              if (
                resp.data.ErrorMessage !== undefined &&
                resp.data.ErrorMessage !== "undefined" &&
                resp.data.ErrorMessage !== ""
              ) {
                this.triggerNotification(resp.data.ErrorMessage, "error");
              } else {
                this.triggerNotification(resp.data.Message, "warning");
              }
            } else {
              this.triggerNotification(
                resp.data.Message == ""
                  ? resp.data.ErrorMessage
                  : resp.data.Message,
                "error"
              );
            }
          }
          this.isLoading = false;
          this.awaitingTaleo = false;
          this.reqDialogue.show = false;
          this.reqDialogue.USA = false;
          this.reqDialogue.value = "";
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.triggerNotification(err, "error");
          }
          this.isLoading = false;
          this.awaitingTaleo = false;
        });
    },
    closeWizard(document) {
      this.document = document;
      this.showWizard = false;
    },

    triggerNotification(text, type, timeout) {
      this.errorText = text;
      this.snackColor = type;
      this.snackTimeout = timeout || 4000;
      this.showErrorSnack = true;
    },
    postToTaleo() {
      if (!this.reqDialogue.value) {
        alert("Requisition Number must be entered");
        return;
      }
      let mm = `<br><p><b>Build your career at Akamai</b></p><p>Our ability to shape digital life today relies on developing exceptional people like you. The kind that can turn impossible into possible. We’re doing everything we can to make Akamai a great place to work. A place where you can learn, grow and have a meaningful impact. </p>`;
      mm += `<br><p>With our company moving so fast, it’s important that you’re able to build new skills, explore new roles, and try out different opportunities. There are so many different ways to build your career at Akamai, and we want to support you as much as possible. We have all kinds of development opportunities available, from programs such as GROW and Mentoring, to internal events like the APEX Expo and tools such as Linkedin Learning, all to help you expand your knowledge and experience here.</p>`;
      mm += `<br><p><b>Learn more</b></p><p>Not sure if this job is the right match for you or want to learn more about the job before you apply? Schedule a 15-minute exploratory call with the Recruiter and they would be happy to share more details. Here is the contact info for the recruiter assigned to this position:</p><br>`;
      let data = {
        ats_reference: this.reqDialogue.value,
        htmlInt:
          documentExport.docToHTML(
            this.document,
            "internal",
            false,
            false,
            this.reqDialogue.USA
          ) + mm,
        htmlExt: documentExport.docToHTML(
          this.document,
          "external",
          false,
          false,
          this.reqDialogue.USA
        ),
        reference: this.document.system_number,
        title: this.document.parttypes.find((t) => t.tmpl_part_id === 6)
          .parts[0].text,
        USA: this.reqDialogue.USA,
      };

      axios
        .post("client/pingtaleo/", data)
        .then((resp) => {
          this.response = resp.data;
          this.isLoading = false;
          this.reqDialogue.show = false;
          this.reqDialogue.USA = false;
          this.reqDialogue.value = "";
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    docToClipboard(section) {
      let format = this.currentClipboardFormat;

      documentExport.docToClipboard(
        this.document,
        section,
        //document.getElementById("app"),
        format.format
      );
      this.exportMenu = false;
      this.response = {
        Status: "OK",
        Message: format.format + " copied to clipboard",
      };
    },
    getActionIcon(aa) {
      if (aa.emailDoc) {
        return "mdi-email";
      } else {
        switch (aa.download_format) {
          case "PDF":
            return "mdi-file-pdf-box";
          case "WORD":
            return "mdi-file-word-box-outline";
          case "WORD_NOTES":
            return "mdi-file-word-box";
          case "RTF":
            return "mdi-file-word";
          case "INTERVIEW_TMPL":
            return "mdi-view-list-outline";
          case "INTAKE_FLOW":
            return "mdi-account-reactivate";
          case "JOBSHARE_TMPL":
            return "mdi-share-variant";
          default:
            return (
              "mdi-alpha-" + aa.name.substr(0, 1).toLowerCase() + "-box-outline"
            );
        }
      }
    },
    generateDownload(aa) {
      switch (aa.download_format) {
        case "PDF":
          this.generatePDF();
          break;
        case "WORD_NOTES":
          this.generateDOCX(true);
          break;
        case "RTF":
          this.generateRTF();
          break;
        case "INTERVIEW_TMPL":
          this.generateInterviewTemplate();
          break;
        case "INTAKE_FLOW":
          this.generateIntakeFlow();
          break;
        case "JOBSHARE_TMPL":
          this.generateJobShareTemplate();
          break;
        case "SCREENING_TMPL":
          this.generateScreeningTemplate();
          break;
        case "WORD":
        default:
          this.generateDOCX();
          break;
      }
    },
    generateRTF(section) {
      this.isExportGenerating = true;
      documentExport
        .generateDocumentExport("RTF", this.document, section)
        .then(() => {
          this.isExportGenerating = false;
        });
      this.exportMenu = false;
    },
    generateDOCX(full) {
      this.isExportGenerating = true;
      documentExport
        .generateDocumentExport("DOCX", this.document, full)
        .then(() => {
          this.isExportGenerating = false;
        });
      this.exportMenu = false;
    },
    generateJobShareTemplate() {
      documentExport.generateJobShareTemplate(this.document);
      this.exportMenu = false;
    },
    generateInterviewTemplate() {
      let n_s = '<p style="color: gray;"><i>',
        n_e = "</i></p>";
      let html = `<!DOCTYPE  html><html><body>
	  <style>
		.pagebreak { page-break-before: always !important; }
    .cell { padding-top: 30px; padding-bottom: 30px; padding-left: 15px; }
		.cell_p1 { padding-top: 30px; padding-bottom: 30px; padding-left: 15px; min-width: 30%; max-width: 30%; width: 30%;}
		.cell_p4 { padding-top: 30px; padding-bottom: 30px; padding-left: 15px; min-width: 70%; max-width: 70%; width: 70%; }
		.cell_5 { padding-top: 30px; padding-bottom: 30px; padding-left: 15px; min-width: 20%; max-width: 20%; width: 20%; }
		.page_heading { font-weight: bold; font-size: 20pt; font-family: calibri; }
		.cell_heading { font-weight: bold; font-size: 13pt; }
		.ivSection { font-weight: bold; font-size: 13pt; font-family: calibri; min-height: 500px; }
		.cell_hint { font-style: italic; color: gray; }
		table, th, td { border: 1px solid black; border-collapse: collapse; }
		td { padding: 5px; }
	  </style>
	  <span class="page_heading">Interview Template - ${this.document.doc_name}</span>
	  <div><br/><br/><br/><br/></div>
	  <table style="width: 100%">
	  <tbody>
		<tr>
			<td class="cell_p1"><span class="cell_heading">Name of Candidate:</span></td>
			<td class="cell_p4">&nbsp;</td>
		</tr>
		<tr>
			<td class="cell_p1"><span class="cell_heading">Job Role:</span></td>
			<td class="cell_p4"><span class="cell_heading">${this.document.doc_name}</span></td>
		</tr>
		<tr>
			<td class="cell_p1"><span class="cell_heading">Interviewer(s):</span></td>
			<td class="cell_p4">&nbsp;</td>
		</tr>
		<tr>
			<td class="cell_p1"><span class="cell_heading">Date / Time of Interview:</span></td>
			<td class="cell_p4">&nbsp;</td>
		</tr>
	  </tbody>
	  </table>`;
      let reqSummary = `
	  	<p class="pagebreak"><span class="page_heading">Interview Template - ${this.document.doc_name}</span></p>
	  	<table style="width: 100%"><caption>Overall Summary</caption>
			<tr>
				<td colspan="8">&nbsp;</td>
				<td><span class="cell_heading">Rating</span></td>
			</tr>`;
      this.document.parttypes
        .find((pt) => pt.type === "Requirement")
        .parts.filter((p) => p.is_essential)
        .forEach((p) => {
          reqSummary += `
			<tr>
				<td colspan="8">${p.text}</td>
				<td>&nbsp;</td>
			</tr>`;
          html += `
	  <p class="pagebreak"><span class="page_heading">Interview Template - ${
      this.document.doc_name
    }</span></p>
	  <table>
		<tr>
			<td><span class="cell_heading">Requirement:</span><br/><span class="cell_hint">(Any skill/knowledge/experience that is essential for the role)</span></td>
			<td colspan="3"><span class="cell_heading">${p.text}</span></td>
		</tr>
		<tr>
			<td colspan="4"><span class="cell_heading">Indicators:</span> <span class="cell_hint">(what does good look like for this requirement)</span>
			<ul class="ivSection">
      ${p.childParts
        .filter((c) => c.dp_name === "Indicator")
        .reduce((p, c) => {
          return p + `<li>${c.text}</li>`;
        }, "")}
			</ul></td>
		</tr>
		<tr>
			<td colspan="4"><span class="cell_heading">Interview Questions:</span>
			<ul class="ivSection">
      ${p.childParts
        .filter((c) => c.dp_name === "Question")
        .reduce((p, c) => {
          return p + `<li>${c.text}</li>`;
        }, "")}
			</ul></td>
		</tr>
		<tr>
			<td colspan="4"><span class="cell_heading">Evidence:</span> <span class="cell_hint">(summarise evidence from your notes)</span><br/>&nbsp;<br/>&nbsp;<br/>&nbsp;<br/>&nbsp;</td>
		</tr>
		<tr>
			<td colspan="4"><span class="cell_heading">Rating</span></td>
		</tr>
		<tr>
			<td style="width: 25%; text-align: center;">1</td>
			<td style="width: 25%; text-align: center;">2</td>
			<td style="width: 25%; text-align: center;">3</td>
			<td style="width: 25%; text-align: center;">4</td>
		</tr>
		<tr>
			<td style="width: 25%; text-align: center;">${n_s}No evidence (not competent or experienced)${n_e}</td>
			<td style="width: 25%; text-align: center;">${n_s}Some evidence (some competence or experience)${n_e}</td>
			<td style="width: 25%; text-align: center;">${n_s}Good evidence (good competence or experience)${n_e}</td>
			<td style="width: 25%; text-align: center;">${n_s}Excellent evidence (excellent competence or experience)${n_e}</td>
		</tr>
	  </table>`;
        });
      reqSummary += `
			<tr>
				<td colspan="8" style="text-align: right;">TOTAL</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td colspan="9" >Additional notes:<br/>&nbsp;<br/>&nbsp;<br/>&nbsp;</td>
			</tr>
			<tr>
				<td colspan="9" >Decision:<br/>&nbsp;<br/>&nbsp;<br/>&nbsp;</td>
			</tr>
			</table>`;
      html +=
        reqSummary +
        `
	  	<p class="pagebreak"><span class="page_heading">How to use this Interview Template</span></p>
		<table style="width: 100%">
	  <tbody>
		<tr>
			<td style="width: 34%;"><span class="cell_heading">Preparing for the Interview</span></td>
			<td style="width: 22%;"><span class="cell_heading">Conducting the Interview</span></td>
			<td style="width: 22%;"><span class="cell_heading">Scoring the Interview</span></td>
			<td style="width: 22%;"><span class="cell_heading">Avoiding Bias</span></td>
		</tr>
		<tr>
			<td style="vertical-align: top; font-size: 10pt; width: 34%;">
				1.	Decide on which of the essential requirements for the role you are going to assess in the interview<br/><br/>
				2.	Write interview questions for each requirement and add these to the interview template.  These should consist of an opening question and probing questions. <br/><br/>
				3.	Think about what good looks like for this requirement and add these to the interview template as indicators<br/>
				<br/>
				For example:<br/>
				<b>Requirement:</b> <br/>
				"Experience managing complex global projects"<br/>
				<br/>
				<b>Indicators:</b><br/>
				-	Has managed multiple, complex projects previously<br/>
				-	Can describe each stage of the project management lifecycle<br/>
				-	Demonstrates various methods to keep stakeholders updated<br/>
				<br/>
				<b>Opening question:</b> <br/>
				"Tell me about a time when you have managed a complex project with multiple stakeholders"<br/>
				<br/>
				<b>Probing questions:</b><br/>
				"What was your specific role on the project?"<br/>
				"How did you manage the complexity?"<br/>
				"How did you communicate with the stakeholders?"<br/>
				"What feedback did you receive?"<br/>
			</td>
			<td style="vertical-align: top; font-size: 10pt; width: 22%;">
				<b>At the start of the interview:</b>
				<ul>
					<li>Meet and greet the candidate and introduce those present</li>
					<li>Explain that the interview is a two -way exchange of information.</li>
					<li>Indicate how long the interview is likely to last</li>
					<li>Explain that the interview is structured around essential requirements for the job and that you will be looking for specific examples of what the candidate has done</li>
					<li>Explain that you will be taking notes</li>
				</ul>
				<b>During the interview:</b>
				<ul><li>Take notes on evidence relating to each requirement and question</li></ul>
				<b>At the end of the interview:</b>
				<ul><li>Give the candidate the opportunity to ask any questions and explain to them the next steps in the process</li></ul>
			</td>
			<td style="vertical-align: top; font-size: 10pt; width: 22%;">
				<ul>
					<li>As soon as possible after the interview, review your notes and confirm any evidence against each requirement</li>
					<li>Assign a rating to each requirement</li>
					<li>If more than one interviewer is present, each person should score the interview separately</li>
					<li>Use the ratings from the interview to decide whether the candidate should go forward to the next stage of the process</li>
					<li>Make sure that your notes explain the reason for your decision</li>
				</ul>
			</td>
			<td style="vertical-align: top; font-size: 10pt; width: 22%;">
				<ul>
					<li>Make sure you have an understanding of the essential requirements for the role and that you are basing your interview around these</li>
					<li>If more than one interviewer is present, each person should score the interview first before discussing to avoid priming / Groupthink</li>
					<li>Be aware of our unconscious tendency to favour people who remind us of ourselves (in-group bias)</li>
					<li>Try not to put too much weight on first impressions, whether these are negative or positive.  Make sure that evidence from the whole interview is taken into account</li>
					<li>Assessors allow the same time and stick to the script to avoid 'intuition' or 'gut' feel</li>
					<li>Don’t compare candidates to each other.  Make sure you are assessing each candidate fairly against the requirements for the job, not against each other</li>
				</ul>
			</td>
		</tr>
	  </tbody>
		</table>
	  </body></html>`;
      html = html.replace(/’/g, "'");
      html = html.replace(/•/g, "");

      html2docx(html, {
        orientation: "landscape",
        margins: { top: 520, bottom: 520, left: 500, right: 500 },
      }).then((data) => {
        utils.downloadFile(
          data,
          this.document.system_number +
            " - " +
            this.document.doc_name +
            " - Interview Template.docx",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        );
      });
      this.exportMenu = false; // close export menu
    },
    recordIntakeFlow() {
      axios
        .post("client/recordTransaction/", {
          transaction_type: "intake_flow",
          attribute: "doc_id",
          attribute_id: this.document.doc_id,
        })
        .then((resp) => {
          if (resp.data.Status !== "OK") {
            resp.data.Message;
            this.response = resp.data;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    generateScreeningTemplate() {
      let html = `<!DOCTYPE  html>
      <html><body>
      <style>
        .pagebreak { page-break-before: always !important; }
        .cell_p0 { min-width: 15%; max-width: 15%; width: 15%;}
        .cell_p1 { padding: 5px; pmin-width: 20%; max-width: 20%; width: 20%;}
        .cell_p2 { padding: 5px; min-width: 25%; max-width: 25%; width: 25%;}
        .cell_p3 { padding: 5px; min-width: 30%; max-width: 30%; width: 30%;}
        .cell_p4 { padding: 5px; min-width: 50%; max-width: 50%; width: 50%; }
        .cell_p75 { padding: 5px; min-width: 75%; max-width: 75%; width: 75%; }
        .cell_p80 { padding: 5px; min-width: 80%; max-width: 80%; width: 80%; }
        .cell_across { padding: 0; min-width: 100%; max-width: 100%; width: 100%; }
        .page_heading { font-weight: bold; font-size: 14pt; text-decoration: underline; text-align: center;}
        .section_heading { padding-top: 8px; padding-bottom: 8px; padding-left: 15px; margin:0; font-weight: bold; text-align: center;}
        .cell_heading { padding:0; font-weight: bold; background-color: #BFBFBF; }
        .ivSection { font-weight: bold; font-family: calibri; min-height: 500px; }
        .cell_hint { font-style: italic; color: gray; }
        .bold { font-weight: bold;  }
        table, th, td { border: 1px solid black; border-collapse: collapse; margin-top: 20px; padding-top: 20px; }
        td { padding: 0 5px; }
        * { font-family: calibri; font-size: 9pt; }
      </style>
      <div class="page_heading">${this.$loginState.user.client} Screening Template</div> 
      <div class="page_heading">${this.document.doc_name}</div> 
      <table style="width: 100%;">
      <tr style="height: 14px">
        <td class="cell_across" colspan="2" style="background-color: #BFBFBF; color: white;">
        <div class="section_heading">INTERVIEW INFORMATION</div>
        </td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p2 bold"><div class="bold">Recruiter:</div></td>
        <td class="cell_p75">${this.document.recruiter}</td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p2 bold"><div class="bold">Candidate Name</div></td>
        <td class="cell_p75"></td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p2 bold"><div class="bold">Source of Candidate</div></td>
        <td class="cell_p75"></td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p2 bold"><div class="bold">Date of Interview</div></td>
        <td class="cell_p75 cell_hint"></td>
      </tr>
      </table>
        <br/><br/>
      <table style="width: 100%">
      <tr style="height: 14px">
        <td class="cell_across" colspan="2" style="background-color: #BFBFBF; color: white;">
        <div class="section_heading">INTRODUCTION</div>
        </td>
      </tr>
      <tr style="height: 14px">
        <td colspan="2">
        <div class="cell_hint">
        Explain the structure of the interview <br/>
        About the Company: Do they know the company? If not explain at a high level what the company does<br/>
        About the Team and the Role: provide high level overview of the team, the role and reason for hire (scroll to bottom on template to see information)
        </div>
        </td>
      </tr>	
      <tr style="height:20px">
        <td class="cell_p4 bold"><div class="bold">CURRENT SITUATION</div></td>
        <td class="cell_p4 cell_hint"><div class="cell_hint">What are your drivers for new role? What would motivate you to make a change?</div></td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p4 bold"><div class="bold">OTHER OPPORTUNITIES</div></td>
        <td class="cell_p4 cell_hint"><div class="cell_hint">Are you looking at other opportunities? Are you interviewing elsewhere? If yes, what stage are you at and how you feel about the opportunity? Compared to this one?</div> </td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p4 bold"><div class="bold">SALARY</div></td>
        <td class="cell_p4 cell_hint"><div class="cell_hint">What are your salary/compensation expectations? </div></td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p4 bold"><div class="bold">NOTICE PERIOD</div></td>
        <td class="cell_p4 cell_hint"><div class="cell_hint">What is your availability to start a new role? What is your notice period?</div></td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p4 bold"><div class="bold">NON-COMPETE</div></td>
        <td class="cell_p4 cell_hint"><div class="cell_hint">Do you know of any active non-compete or non-solicitation clauses that might hinder you from joining the company?</div></td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p4 bold"><div class="bold">RIGHT TO WORK</div></td>
        <td class="cell_p4 cell_hint"><div class="cell_hint">Do you have the relevant right to work in the country of hiring?</div></td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p4 bold"><div class="bold">LOCATION & TRAVEL</div></td>
        <td class="cell_p4 cell_hint"><div class="cell_hint">Provide any information on location and travel commitments (scroll to bottom on template to see information) </div></td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p4 bold"><div class="bold">WORKING PATTERN</div></td>
        <td class="cell_p4 cell_hint"><div class="cell_hint">Provide any information on flexible working pattern available and ask candidate for preferred way of working </div></td>
      </tr>
      </table>
        <br/><br/>
      <table style="width: 100%">
      <tr style="height: 14px">
        <td class="cell_across" colspan="2" style="background-color: #BFBFBF; color: white;">
        <div class="section_heading">CANDIDATE PROFILE</div>
        </td>
      </tr>
      <tr style="height: 14px">
        <td colspan="2">
        
        </td>
      </tr>		
      <tr style="height:20px">
        <td class="cell_p4 bold"><div class="bold">CAREER SUMMARY</div></td>
        <td class="cell_p4 cell_hint"><div class="cell_hint">Ask the candidate to spend 5 minute to bring their career history to life (talk through career history and job roles at a high level, probe on why left each role)</div></td>
      </tr>
      </table>
        <br/><br/>
      <table style="width: 100%">
      <tr style="height: 14px">
        <td class="cell_across" colspan="2" style="background-color: #BFBFBF; color: white;">
        <div class="section_heading">JOB RELATED EXPERIENCE</div>
        </td>
      </tr>`;

      this.document.parttypes
        .find((pt) => pt.type === "Requirement")
        .parts.filter((p) => p.is_essential)
        .forEach((p) => {
          html += `
            <tr style="height:20px">
              <td class="cell_p4 bold">${p.text}</td>
              <td class="cell_p4 cell_hint"><div class="cell_hint">Additional information to understand this requirement and gather insights on screening questions</div></td>
            </tr>
            <tr style="height:20px">
              <td colspan="2" class="cell_p4 cell_hint"><div class="cell_hint">Ask the candidate to provide examples of relevant experience or capabilities aligned to specific screening requirements above</div></td>
            </tr>`;
        });

      html += `

      </table>
        <br/><br/>
      <table style="width: 100%">
      <tr style="height: 14px">
        <td class="cell_across" colspan="2" style="background-color: #BFBFBF; color: white;">
        <div class="section_heading">INTERVIEW & NEXT STEPS</div>
        </td>
      </tr>
      <tr style="height: 14px">
        <td colspan="2">
        <div class="cell_hint">
        Explain the next steps and, if they were successful, what the format of the interview process would be  <br/>
        (scroll to bottom on template to see information) 
        </div>
        </td>
      </tr>	
      <tr style="height:20px">
        <td class="cell_p4 bold"><div class="bold">AVAILABILITY FOR INTERVIEW</div></td>
        <td class="cell_p4 cell_hint"><div class="cell_hint">What is your availability to interview? Are there any dates that will not work for you?</div></td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p4 bold"><div class="bold">REASONABLE ADJUSTMENTS</div></td>
        <td class="cell_p4 cell_hint"><div class="cell_hint">Ask the candidate if they require any reasonable adjustments for the interview</div></td>
      </tr>
      </table>
        <br/><br/>
      <table style="width: 100%">
      <tr style="height: 14px">
        <td class="cell_across" colspan="2" style="background-color: #BFBFBF; color: white;">
        <div class="section_heading">ABOUT THE JOB</div>
        </td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p2 bold"><div class="bold">Job USP Copy</div></td>
        <td class="cell_p75">${
          this.document.parttypes.find((pt) => pt.type === "Job USP").parts[0]
            .text
        }</td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p2 bold"><div class="bold">Job Information</div></td>
        <td class="cell_p75 cell_hint"><div class="cell_hint">Add any more insight into the Job. Additional unique selling points. Why should anyone apply for the position? What will the role holder take ownership for? What is the opportunity to make a difference?</div></td>
      </tr>	
      </table>
      <br/><br/>
      <table style="width: 100%">
      <tr style="height: 14px">
        <td class="cell_across" colspan="2" style="background-color: #BFBFBF; color: white;">
        <div class="section_heading">RESPONSIBILITIES</div>
        </td>
      </tr>`;

      this.document.parttypes
        .find((pt) => pt.type === "Responsibility")
        .parts.forEach((p) => {
          html += `
            <tr style="height:20px">
              <td class="cell_p4 bold">${p.text}</td>
              <td class="cell_p4 cell_hint"><div class="cell_hint">Add any more information to bring the day to day role to life</div></td>
            </tr>`;
        });

      html += `</table>
      <br/><br/>
      <table style="width: 100%">
      <tr style="height: 14px">
        <td class="cell_across" colspan="2" style="background-color: #BFBFBF; color: white;">
        <div class="section_heading">FLEXIBLE WORKING, LOCATION & TRAVEL REQUIREMENTS</div>
        </td>
      </tr>`;

      let flexParts = this.document.parttypes.filter(
        (pt) =>
          pt.type === "Working Pattern" ||
          pt.type === "Flex Work Details" ||
          pt.type === "Work Pattern Details"
      );
      if (flexParts.length > 0) {
        flexParts.forEach((fp) => {
          fp.parts.forEach((p) => {
            html += `
                <tr style="height:20px">
                  <td class="cell_p4 bold">${p.text.replace(
                    /[\u{0080}-\u{FFFF}]/gu,
                    ""
                  )}</td>
                  <td class="cell_p4 cell_hint"><div class="cell_hint">Additional information to understand the flexible working options or bring it to life.</div></td>
                </tr>`;
          });
        });
      }

      html += `
      <tr style="height:20px">
        <td class="cell_p4 bold"><div class="bold">Location:</div></td>
        <td class="cell_p4 cell_hint"><div class="cell_hint">Any additional information on the location requirements for the role</div></td>
      </tr>	
      <tr style="height:20px">
        <td class="cell_p4 bold"><div class="bold">Travel Requirement:</div></td>
        <td class="cell_p4 cell_hint"><div class="cell_hint">Any additional information on the travel requirements for the role</div></td>
      </tr>		
      </table>	
      <p class="pagebreak">&nbsp;</p>
      <table style="width: 100%">
      <tr style="height: 14px">
        <td class="cell_across" colspan="5" style="background-color: #BFBFBF; color: white;">
        <div class="section_heading">INTERVIEW PROCESS</div>
        </td>
      </tr>
      <tr style="height: 14px">
        <td class="cell_p1" style="background-color: #BFBFBF; color: white;" align="center">Stage</td>
        <td class="cell_p1" style="background-color: #BFBFBF; color: white;" align="center">Format</td>
        <td class="cell_p1" style="background-color: #BFBFBF; color: white;" align="center">Duration</td>
        <td class="cell_p1" style="background-color: #BFBFBF; color: white;" align="center">Location</td>
        <td class="cell_p1" style="background-color: #BFBFBF; color: white;" align="center">Dates Available</td>
      </tr>	
      <tr style="height: 14px">
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
      </tr>		
      <tr style="height: 14px">
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
      </tr>		
      <tr style="height: 14px">
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
      </tr>		
      <tr style="height: 14px">
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
      </tr>		
      <tr style="height: 14px">
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
      </tr>	
      </table>
      </body></html>`;

      html2docx(html, {
        orientation: "portrait",
        margins: { top: 500, bottom: 500, left: 500, right: 500 },
      }).then((data) => {
        utils.downloadFile(
          data,
          this.document.system_number +
            " - " +
            this.document.doc_name +
            " - Screening Template.docx",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        );
      });
      this.exportMenu = false; // close export menu
    },
    generateIntakeConduent() {
      //let hdrColour = "#366091";
      //let lblColour = "#DBE5F1";

      let html = `<!DOCTYPE  html>
      <html><body>
      <style>
        .pagebreak { page-break-before: always !important; }
        .cell_p0 { min-width: 15%; max-width: 15%; width: 15%;}
        .cell_p1 { padding: 5px; pmin-width: 20%; max-width: 20%; width: 20%;}
        .cell_p2 { padding: 5px; min-width: 25%; max-width: 25%; width: 25%;}
        .cell_p3 { padding: 5px; min-width: 30%; max-width: 30%; width: 30%;}
        .cell_p4 { padding: 5px; min-width: 50%; max-width: 50%; width: 50%; }
        .cell_p75 { padding: 5px; min-width: 75%; max-width: 75%; width: 75%; }
        .cell_p80 { padding: 5px; min-width: 80%; max-width: 80%; width: 80%; }
        .cell_across { padding: 0; min-width: 100%; max-width: 100%; width: 100%; }
        .page_heading { font-weight: bold; font-size: 14pt; text-decoration: underline; text-align: center;}
        .section_heading { padding-top: 8px; padding-bottom: 8px; padding-left: 15px; margin:0; font-weight: bold; text-align: center;}
        .cell_heading { padding:0; font-weight: bold; background-color: #BFBFBF; }
        .ivSection { font-weight: bold; font-family: calibri; min-height: 500px; }
        .cell_hint { font-style: italic; color: gray; }
        .bold { font-weight: bold;  }
        table, th, td { border: 1px solid black; border-collapse: collapse; margin-top: 20px; padding-top: 20px; }
        td { padding: 0 5px; }
        * { font-family: calibri; font-size: 9pt; }
      </style>
      <div class="page_heading">INTAKE TEMPLATE</div> 
      <table style="width: 100%;">
      <tr style="height: 14px">
        <td class="cell_across" colspan="2" style="background-color: #BFBFBF; color: white;">
        <div class="section_heading">POSITION INFORMATION</div>
        </td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p2 bold"><div class="bold">Requisition ID</div></td>
        <td class="cell_p75"></td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p2 bold"><div class="bold">Recruiter:</div></td>
        <td class="cell_p75">${this.document.recruiter}</td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p2 bold"><div class="bold">Hiring Manager</div></td>
        <td class="cell_p75"></td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p2 bold"><div class="bold">Internal Job Title:</div></td>
        <td class="cell_p75">${
          this.document.parttypes.find((pt) => pt.type === "Internal Job Title")
            ?.parts[0].text || this.document.doc_name
        }</td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p2 bold"><div class="bold">Advertising Job Title:</div></td>
        <td class="cell_p75 cell_hint"><div class="cell_hint">What job title should be used to post externally (what will be recognised in the external market)?</div></td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p2 bold"><div class="bold">Salary and Package:</div></td>
        <td class="cell_p75 cell_hint"><div class="cell_hint">Salary Band. Min, Max Salary. Package Details.</div></td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p2 bold"><div class="bold">Reason for Hire:</div></td>
        <td class="cell_p75 cell_hint"><div class="cell_hint">E.g. replacement for leaver, replacement for internal move, newly created role</div></td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p2 bold"><div class="bold">Reporting to:</div></td>
        <td class="cell_p75 cell_hint"><div class="cell_hint">Who the role will report to</div></td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p2 bold"><div class="bold">Training information</div></td>
        <td class="cell_p75 cell_hint"><div class="cell_hint">Is there training? Duration of training. Onsite/ virtual/hybrid?</div></td>
      </tr>
      <tr style="height:20px">
        <td class="cell_p2 bold"><div class="bold">Probationary Period</div></td>
        <td class="cell_p75 cell_hint"><div class="cell_hint">Is there a formal probationary period and, if so, how long is it?</div></td>
      </tr>
      </table>
      <br/><br/>
      <table style="width: 100%">
      <tr style="height: 14px">
        <td class="cell_across" colspan="2" style="background-color: #BFBFBF; color: white;">
        <div class="section_heading">ABOUT THE JOB</div>
        </td>
      </tr>`;

      if (this.document.parttypes.some((pt) => pt.type === "Job Purpose"))
        html += `<tr style="height:20px">
          <td class="cell_p2 bold"><div class="bold">Job USP Copy</div></td>
          <td class="cell_p75">${
            this.document.parttypes.find((pt) => pt.type === "Job Purpose")
              .parts[0].text
          }</td>
        </tr>`;

      html += `<tr style="height:20px">
        <td class="cell_p2 bold"><div class="bold">Job Information</div></td>
        <td class="cell_p75 cell_hint"><div class="cell_hint">Add any more insight into the Job. Additional unique selling points. Why should anyone apply for the position? What will the role holder take ownership for? What is the opportunity to make a difference?</div></td>
      </tr>	
      </table>
      <br/><br/>
      <table style="width: 100%">
      <tr style="height: 14px">
        <td class="cell_across" colspan="2" style="background-color: #BFBFBF; color: white;">
        <div class="section_heading">RESPONSIBILITIES</div>
        </td>
      </tr>`;

      this.document.parttypes
        .find((pt) => pt.type === "Responsibility")
        ?.parts.forEach((p) => {
          html += `
            <tr style="height:20px">
              <td class="cell_p4 bold">${p.text}</td>
              <td class="cell_p4 cell_hint"><div class="cell_hint">Add any more information to bring the day to day role to life</div></td>
            </tr>`;
        });

      html += `</table>
      <br/><br/>
      <table style="width: 100%">
      <tr style="height: 14px">
        <td class="cell_across" colspan="2" style="background-color: #BFBFBF; color: white;">
        <div class="section_heading">JOB POSTING & SCREENING REQUIREMENTS</div>
        </td>
      </tr>`;

      this.document.parttypes
        .find((pt) => pt.type === "Requirement")
        ?.parts.filter((p) => p.is_essential)
        .forEach((p) => {
          html += `
            <tr style="height:20px">
              <td class="cell_p4 bold">${p.text}</td>
              <td class="cell_p4 cell_hint"><div class="cell_hint">Additional information to understand this requirement and gather insights on screening questions</div></td>
            </tr>`;
        });

      html += `</table>
      <br/><br/>
      <table style="width: 100%">
      <tr style="height: 14px">
        <td class="cell_across" colspan="2" style="background-color: #BFBFBF; color: white;">
        <div class="section_heading">FLEXIBLE WORKING, LOCATION & TRAVEL REQUIREMENTS</div>
        </td>
      </tr>`;

      let flexParts = this.document.parttypes.filter(
        (pt) =>
          pt.type === "Working Pattern" ||
          pt.type === "Flex Work Details" ||
          pt.type === "Work Pattern Details"
      );
      if (flexParts.length > 0) {
        flexParts.forEach((fp) => {
          fp.parts.forEach((p) => {
            html += `
                <tr style="height:20px">
                  <td class="cell_p4 bold">${p.text.replace(
                    /[\u{0080}-\u{FFFF}]/gu,
                    ""
                  )}</td>
                  <td class="cell_p4 cell_hint"><div class="cell_hint">Any additional information on the location requirements for the role</div></td>
                </tr>`;
          });
        });
      }

      html += `
      <tr style="height:20px">
        <td class="cell_p4 bold"><div class="bold">Technology, Certification, requirements (etc.)</div></td>
        <td class="cell_p4 cell_hint"><div class="cell_hint">Any additional information on the location requirements for the role</div></td>
      </tr>	
      <tr style="height:20px">
        <td class="cell_p4 bold"><div class="bold">Location:</div></td>
        <td class="cell_p4 cell_hint"><div class="cell_hint">Any additional information on the location requirements for the role</div></td>
      </tr>	
      <tr style="height:20px">
        <td class="cell_p4 bold"><div class="bold">Travel Requirement:</div></td>
        <td class="cell_p4 cell_hint"><div class="cell_hint">Any additional information on the location requirements for the role</div></td>
      </tr>		
      </table>
      <p class="pagebreak">&nbsp;</p>
      <table style="width: 100%">
      <tr style="height: 14px">
        <td class="cell_across" colspan="5" style="background-color: #BFBFBF; color: white;">
        <div class="section_heading">INTERVIEW PROCESS</div>
        </td>
      </tr>
      <tr style="height: 14px">
        <td class="cell_p1" style="background-color: #BFBFBF; color: white;">Stage</td>
        <td class="cell_p1" style="background-color: #BFBFBF; color: white;">Format</td>
        <td class="cell_p1" style="background-color: #BFBFBF; color: white;">Duration</td>
        <td class="cell_p1" style="background-color: #BFBFBF; color: white;">Location</td>
        <td class="cell_p1" style="background-color: #BFBFBF; color: white;">Dates Available</td>
      </tr>	
      <tr style="height: 14px">
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
      </tr>		
      <tr style="height: 14px">
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
      </tr>		
      <tr style="height: 14px">
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
      </tr>		
      <tr style="height: 14px">
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
      </tr>		
      <tr style="height: 14px">
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
      </tr>	
      </table>
      <br/><br/>
      <table style="width: 100%">
      <tr style="height: 14px">
        <td class="cell_across" colspan="4" style="background-color: #BFBFBF; color: white;">
        <div class="section_heading">INTERVIEWERS</div>
        </td>
      </tr>
      <tr style="height: 14px">
        <td class="cell_p0" style="background-color: #BFBFBF; color: white;">No</td>
        <td class="cell_p1" style="background-color: #BFBFBF; color: white;">Name</td>
        <td class="cell_p1" style="background-color: #BFBFBF; color: white;">Position</td>
        <td class="cell_p1" style="background-color: #BFBFBF; color: white;">Stage Involved</td>
      </tr>	
      <tr style="height: 14px">
        <td class="cell_p0">1</td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
      </tr>	
      <tr style="height: 14px">
        <td class="cell_p0">2</td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
      </tr>	
      <tr style="height: 14px">
        <td class="cell_p0">3</td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
      </tr>	
      <tr style="height: 14px">
        <td class="cell_p0">4</td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
        <td class="cell_p1"></td>
      </tr>	
      </table>
      <br/><br/>
      <table style="width: 100%">
      <tr style="height: 14px">
        <td class="cell_across" colspan="2" style="background-color: #BFBFBF; color: white;">
        <div class="section_heading">COMMUNICATION PLAN (HIRING MANAGER & RECRUITER) </div>
        </td>
      </tr>
      <tr style="height: 14px">
        <td class="cell_p0">Agreed Plan</td>
        <td class="cell_p1 cell_hint">
          <div class="cell_hint">
          Frequency (e.g weekly update)<br/>
          Method (e.g. set time to update or email) <br/>
          Agreed turnaround time for reviewing CVs <br/>
          Hold dates for interview
          </div>
        </td>
      </tr>	
      </table>
      </body></html>`;
      html = html.replace(/’/g, "'");
      html = html.replace(/•/g, "");

      html2docx(html, {
        orientation: "portrait",
        margins: { top: 500, bottom: 500, left: 500, right: 500 },
      }).then((data) => {
        utils.downloadFile(
          data,
          this.document.system_number +
            " - " +
            this.document.doc_name +
            " - Intake Template.docx",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        );
      });
      this.exportMenu = false; // close export menu
      this.recordIntakeFlow();
    },
    generateIntakeFlow() {
      if (
        this.$loginState.user.client === "Conduent" ||
        this.$loginState.user.client === "RoleMapper"
      ) {
        return this.generateIntakeConduent();
      }

      this.recordIntakeFlow();
      let hdrColour = "#366091";
      let lblColour = "#DBE5F1";

      if (this.$loginState.user.client === "Elsevier") {
        hdrColour = "orange";
        lblColour = "#F1F0EF";
      }

      let html = `
        <!DOCTYPE  html><html><body>
          <style>
            .pagebreak { page-break-before: always !important; }
            .cell_p0 { min-width: 15%; max-width: 15%; width: 15%;}
            .cell_p1 { padding: 5px; pmin-width: 20%; max-width: 20%; width: 20%;}
            .cell_p2 { padding: 5px; min-width: 25%; max-width: 25%; width: 25%;}
            .cell_p3 { padding: 5px; min-width: 30%; max-width: 30%; width: 30%;}
            .cell_p4 { padding: 5px; min-width: 50%; max-width: 50%; width: 50%; }
            .cell_across { padding: 0; min-width: 100%; max-width: 100%; width: 100%; }
            .page_heading { font-weight: bold; font-size: 14pt; text-decoration: underline; text-align: center;}
            .section_heading { padding-top: 8px; padding-bottom: 8px; padding-left: 15px; margin:0; font-weight: bold; text-align: center;}
            .cell_heading { padding:0; font-weight: bold; background-color: ${lblColour} }
            .ivSection { font-weight: bold; font-family: calibri; min-height: 500px; }
            .cell_hint { font-style: italic; color: gray; }
            table, th, td { border: 1px solid black; border-collapse: collapse; }
            td { padding: 0 5px; }
            * { font-family: calibri; font-size: 9pt; }
          </style>`;
      if (
        this.$loginState.user.client === "Akamai" ||
        this.$loginState.user.client === "staging"
      ) {
        html += `<div class="page_heading">Launch Meeting Plan</div>`;
      } else {
        if (this.$loginState.user.client === "Elsevier") {
          html += `<div class="page_heading">Attraction Strategy Meeting</div>`;
        } else {
          html += `<div class="page_heading">Intake Flow</div>`;
        }
      }
      html += ` <table style="width: 100%">
            <tr style="height: 14px">
              <td class="cell_across" colspan="4" style="background-color: ${hdrColour}; color: white;">
                <div class="section_heading">POSITION INFORMATION</div>
              </td>
            </tr>
            <tr style="height:20px">`;
      if (this.$loginState.user.client === "Elsevier") {
        html += `<td class="cell_p1 cell_heading" style="background-color: ${lblColour}">Reason for the Position:</td>`;
      } else {
        html += `<td class="cell_p1 cell_heading" style="background-color: ${lblColour}">Date of Call/Meeting:</td>`;
      }
      html += `<td class="cell_p2">&nbsp;</td>
              <td class="cell_p1 cell_heading" style="background-color: ${lblColour}">Recruiter:</td>
              <td class="cell_p2">${this.$loginState.user.name}</td>
            </tr>
            <tr>
              <td class="cell_p1 cell_heading" style="background-color: ${lblColour}">Position Title:</td>
              <td class="cell_p2">${this.document.doc_name}</td>
              <td class="cell_p1 cell_heading" style="background-color: ${lblColour}">Requisition #:</td>
              <td class="cell_p2">&nbsp;</td>
            </tr>
            <tr>
              <td class="cell_p1 cell_heading" style="background-color: ${lblColour}">Job Posting Title:</td>
              <td class="cell_p2">${
                this.document.parttypes.find(
                  (pt) =>
                    pt.type === "Advert Job Title" ||
                    pt.type === "Job Posting Title" ||
                    pt.type === "Título de Trabajo del Anuncio" ||
                    pt.type === "Título de Trabajo"
                )?.parts[0].text || this.document.doc_name
              }</td>
              <td class="cell_p4 cell_heading" style="background-color: ${lblColour}" colspan="2">Is the Job title appropriate & relevant for attraction?</td>
            </tr>
            <tr>
              <td class="cell_p1 cell_heading" style="background-color: ${lblColour}">Hiring Manager:</td>
              <td class="cell_p2">&nbsp;</td>`;
      if (this.$loginState.user.client === "Elsevier") {
        html += `<td class="cell_p1 cell_heading" style="background-color: ${lblColour}">Position Type:</td>`;
      } else {
        html += `<td class="cell_p1 cell_heading" style="background-color: ${lblColour}">Business Group/Function:</td>`;
      }
      html += `<td class="cell_p2">&nbsp;</td>
            </tr>
            <tr>
              <td class="cell_p1 cell_heading" style="background-color: ${lblColour}">`;
      if (
        this.$loginState.user.client === "Akamai" ||
        this.$loginState.user.client === "staging"
      ) {
        html += `Mid-point budget:</td>
              <td class="cell_p2">&nbsp;<br></td>
              <td class="cell_p1 cell_heading" style="background-color: ${lblColour}">Sales roles: % split, bonus % & type</td>
              <td class="cell_p2">&nbsp;<br></td>`;
      } else {
        if (this.$loginState.user.client === "Elsevier") {
          html += `Salary and Package:<br>Range Min/Max<br>Package Details</td>
              <td class="cell_p2" colspan="3">&nbsp;<br><br><br></td>`;
        } else {
          html += `Salary:</td>
              <td class="cell_p2" colspan="3">&nbsp;<br></td>`;
        }
      }
      html += `
            </tr>
            <tr style="height:50px">`;
      if (this.$loginState.user.client === "Elsevier") {
        html += `<td class="cell_p1 cell_heading" style="background-color: ${lblColour}">Relocation and Right to Work:</td>
              `;
      } else {
        html += `<td class="cell_p1 cell_heading" style="background-color: ${lblColour}">Relocations Considered?</td>
              `;
      }
      html += `<td class="cell_p2" colspan="3">&nbsp;<br></td>
            </tr>`;

      if (this.document.parttypes.some((pt) => pt.type === "Job USP"))
        html += `<tr style="height: 10px">
                <td class="cell_across" colspan="4" style="background-color: ${hdrColour}; color: white;">
                  <div class="section_heading">JOB USP</div>
                </td>
              </tr>
              <tr style="height:90px">
                <td colspan="4">${
                  this.document.parttypes.find((pt) => pt.type === "Job USP")
                    .parts[0].text
                }</td>
              </tr>`;

      html += `<tr style="height:60px">
              <td class="cell_p4 cell_heading" style="background-color: ${lblColour}" colspan="2">Additional unique selling points (to build on above to discuss with candidate) - why should anyone apply for the position?</td>
              <td colspan="2">&nbsp;</td>
            </tr>
        <tr style="height: 10px">
          <td class="cell_across" colspan="6" style="background-color: ${hdrColour}; color: white;">
            <div class="section_heading">FURTHER NOTES/INFORMATION</div>
          </td>
        </tr>
        <tr style="height:80px">
          <td colspan="6">&nbsp;</td>
        </tr>
          </table>
          <p class="pagebreak">&nbsp;</p>
          <table style="width: 100%">
            <tr style="height: 14px">
              <td class="cell_across" colspan="10" style="background-color: ${hdrColour}; color: white;">
                <div class="section_heading">RESPONSIBILITIES</div>
              </td>
            </tr>
            <tr>
              <td class="cell_p4 cell_heading" colspan="7" style="background-color: ${lblColour}">Responsibilities</td>
              <td class="cell_p4 cell_heading" colspan="3" style="background-color: ${lblColour}">Any more information to bring the role to life</td>
            </tr>`;
      let respSummary = "";
      let noResps =
        this.document.parttypes.find((pt) => pt.type === "Responsibility")
          ?.parts.length || 0;
      this.document.parttypes
        .find((pt) => pt.type === "Responsibility")
        ?.parts.forEach((p) => {
          respSummary += `
            <tr>
              <td colspan="7">${p.text}</td>
            </tr>`;
        });
      html +=
        respSummary +
        `
              <tr style="height:60px">`;
      if (this.$loginState.user.client === "Elsevier") {
        html += `
        <td class="cell_p4 cell_heading" style="background-color: ${lblColour}" colspan="7">Describe what you look for in a candidate?</td>`;
      } else {
        html += `
        <td class="cell_p4 cell_heading" style="background-color: ${lblColour}" colspan="7">What are the key challenges someone may face in this role in the first 3-6 months?</td>`;
      }
      html += `
                <td colspan="3">&nbsp;</td>
              </tr>`;
      if (noResps < 6) {
        html += `
            <tr style="height: 10px">
              <td class="cell_across" colspan="10" style="background-color: ${hdrColour}; color: white;">
                <div class="section_heading">FURTHER NOTES/INFORMATION</div>
              </td>
            </tr>
            <tr style="height:100px">
              <td colspan="10">&nbsp;</td>
            </tr>`;
      }
      html += `
          </tbody>
          </table>
          <p class="pagebreak">&nbsp;</p>
          <table style="width: 100%">`;

      if (this.document.parttypes.some((pt) => pt.type === "About the Team"))
        html += `<tr style="height: 14px">
                <td class="cell_across" colspan="6" style="background-color: ${hdrColour}; color: white;">
                  <div class="section_heading">ABOUT THE TEAM</div>
                </td>
              </tr>
              <tr style="height:70px">
                <td colspan="6">${
                  this.document.parttypes.find(
                    (pt) => pt.type === "About the Team"
                  ).parts[0].text
                }</td>
              </tr>`;

      html += `<tr style="height:30px">
              <td class="cell_p4 cell_heading" style="background-color: ${lblColour}" colspan="3">`;
      if (this.$loginState.user.client === "Elsevier") {
        html += `Additional Team Info:</td>
              <td colspan="3">&nbsp;</td>
            </tr>`;
      } else {
        html += `Who are the key stakeholders / team / department this person will work with and in what capacity?</td>
              <td colspan="3">&nbsp;</td>
            </tr>
            <tr style="height:30px">
              <td class="cell_p4 cell_heading" style="background-color: ${lblColour}" colspan="3">Department/Team Structure</td>
              <td colspan="3">&nbsp;</td>
            </tr>`;
      }
      html += `
            <tr style="height: 10px">
              <td class="cell_across" colspan="6" style="background-color: ${hdrColour}; color: white;">
                <div class="section_heading">POSITION REQUIREMENTS</div>
              </td>
            </tr>
            <tr>
              <td class="cell_p4 cell_heading" colspan="3" style="background-color: ${lblColour}">Essential Requirements</td>
              <td class="cell_p4 cell_heading" colspan="3" style="background-color: ${lblColour}">`;
      if (this.$loginState.user.client === "Elsevier") {
        html += `Critical Screening Questions (associated with Requirement)`;
      } else {
        html += `Telephone/Pre-Screening Criteria / Questions`;
      }
      html += `</td>
            </tr>`;
      let reqSummary = "";
      let noReqs =
        this.document.parttypes.find((pt) => pt.type === "Requirement")?.parts
          .length || 0;
      this.document.parttypes
        .find((pt) => pt.type === "Requirement")
        ?.parts.filter((p) => p.is_essential)
        .forEach((p) => {
          reqSummary += `
        <tr>
          <td colspan="3">${p.text}</td>
          <td colspan="3">&nbsp;</td>
        </tr>`;
        });
      html += reqSummary;
      if (noReqs < 6) {
        html += `
            <tr style="height: 10px">
              <td class="cell_across" colspan="10" style="background-color: ${hdrColour}; color: white;">
                <div class="section_heading">FURTHER NOTES/INFORMATION</div>
              </td>
            </tr>
            <tr style="height:150px">
              <td colspan="10">&nbsp;</td>
            </tr>`;
      }
      html += `
          </table>
          <p class="pagebreak">&nbsp;</p>`;
      if (this.$loginState.user.client === "Elsevier") {
        html += `
      <table style="width: 100%">
        <tr style="height: 10px">
          <td class="cell_across" colspan="12" style="background-color: ${hdrColour}; color: white;">
            <div class="section_heading">FLEXIBLE WORKING</div>
          </td>
        </tr>
        <tr>
          <td colspan="6" class="cell cell_heading" style="background-color: ${lblColour}">
            Flexible Working Patterns
          </td>
          <td class="cell cell_heading" style="background-color: ${lblColour}" colspan="6">Any additional comments on Flexible Working Patterns (Mon-Fri, Shifts etc)</td>
        </tr>
        <tr>
          <td colspan="6" style="min-height:50px">${
            this.document.parttypes
              .find((pt) => pt.type === "Working Pattern")
              // eslint-disable-next-line vue/no-parsing-error
              ?.parts[0].text.replace(/[\u{0080}-\u{FFFF}]/gu, "") || ""
          }</td>
          <td colspan="6">&nbsp;</td>
        </tr>
        <tr>
          <td colspan="6" class="cell cell_heading" style="height:45px;background-color: ${lblColour}">Location and Travel Requirement</td>
          <td colspan="6">&nbsp;</td>
        </tr>
      </table>
      <br>
      <table style="width: 100%">
        <tr style="height: 10px">
          <td class="cell_across" colspan="12" style="background-color: ${hdrColour}; color: white;">
            <div class="section_heading">SOURCING STRATEGY</div>
          </td>
        </tr>
        <tr>
          <td class="cell cell_heading" colspan="4" style="background-color: ${lblColour}">Internals and Referrals</td>
          <td class="cell cell_heading" colspan="4" style="background-color: ${lblColour}">Alternate Job Titles and Keywords</td>
          <td class="cell cell_heading" colspan="4" style="background-color: ${lblColour}">Qualification</td>
        </tr>
        <tr style="height:70px">
          <td colspan="4">
            <ul>
              <li>Internal Applicants</li>
              <li>Referrals</li>
              <li>Internal Communities</li>
              <li>Promotion through ERG’s</li>
              <li>Hot Jobs/New Jobs</li>
            </ul>
          </td>
          <td colspan="4">
            <ul>
              <li>Internal Applicants</li>
              <li>Referrals</li>
              <li>Internal Communities</li>
              <li>Promotion through ERG’s</li>
              <li>Hot Jobs/New Jobs</li>
            </ul>
          </td>
          <td colspan="4">
            <ul>
              <li>Internal Applicants</li>
              <li>Referrals</li>
              <li>Internal Communities</li>
              <li>Promotion through ERG’s</li>
              <li>Hot Jobs/New Jobs</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td class="cell cell_heading" colspan="4" style="background-color: ${lblColour}">Target Competitors</td>
          <td class="cell cell_heading" colspan="4" style="background-color: ${lblColour}">Job Boards</td>
          <td class="cell cell_heading" colspan="4" style="background-color: ${lblColour}">Associations and Social Media Networks</td>
        </tr>
        <tr style="height:70px">
          <td colspan="4">
            <ul>
              <li>Opex Exhibitions Services</li>
              <li>Afar Exhibition Services</li>
              <li>Barclays</li>
              <li>BBC</li>
              <li>HSBC</li>
              <li>CNN</li>
              <li>Department for Transport</li>
            </ul>
          </td>
          <td colspan="4">
            <ul>
              <li>Indeed.com</li>
              <li>CareerBuilder Diversity</li>
              <li>Vercida</li>
              <li>LinkedIn</li>
            </ul>
          </td>
          <td colspan="4">
            <ul>
              <li>Warrier Forum</li>
              <li>Inbound.org</li>
              <li>Moz Community</li>
              <li>Growth Hackers</li>
              <li>Social Media channel search using key words / title / alternative titles</li>
            </ul>
          </td>
        </tr>
      </table>
      <p class="pagebreak">&nbsp;</p>
      <div style="color:orange; font-weight:bold">Interview Panel</div>
      <table style="width: 100%">
        <tr>
          <td class="cell cell_heading" colspan="1" style="background-color: ${lblColour}">No.</td>
          <td class="cell cell_heading" colspan="3" style="background-color: ${lblColour}">Name</td>
          <td class="cell cell_heading" colspan="4" style="background-color: ${lblColour}">Position</td>
          <td class="cell cell_heading" colspan="4" style="background-color: ${lblColour}">Stage Involved</td>
        </tr>
        <tr>
          <td class="cell" colspan="1">1</td>
          <td class="cell" colspan="3">&nbsp;</td>
          <td class="cell" colspan="4">&nbsp;</td>
          <td class="cell" colspan="4">&nbsp;</td>
        </tr>
        <tr>
          <td class="cell" colspan="1">2</td>
          <td class="cell" colspan="3">&nbsp;</td>
          <td class="cell" colspan="4">&nbsp;</td>
          <td class="cell" colspan="4">&nbsp;</td>
        </tr>
        <tr>
          <td class="cell" colspan="1">3</td>
          <td class="cell" colspan="3">&nbsp;</td>
          <td class="cell" colspan="4">&nbsp;</td>
          <td class="cell" colspan="4">&nbsp;</td>
        </tr>
        <tr>
          <td class="cell" colspan="1">4</td>
          <td class="cell" colspan="3">&nbsp;</td>
          <td class="cell" colspan="4">&nbsp;</td>
          <td class="cell" colspan="4">&nbsp;</td>
        </tr>
      </table>
      <br>
      <div style="color:orange; font-weight:bold">Agreed Interview Process</div>
      <table style="width: 100%">
        <tr>
          <td class="cell_5 cell_heading" style="background-color: ${lblColour}">Stage</td>
          <td class="cell_5 cell_heading" style="background-color: ${lblColour}">Format</td>
          <td class="cell_5 cell_heading" style="background-color: ${lblColour}">Duration</td>
          <td class="cell_5 cell_heading" style="background-color: ${lblColour}">Location</td>
          <td class="cell_5 cell_heading" style="background-color: ${lblColour}">Dates Available</td>
        </tr>
        <tr>
          <td class="cell_5" style="max-width:20%">Interview 1 (Hiring Manager Interview)</td>
          <td class="cell_5">&nbsp;</td>
          <td class="cell_5">&nbsp;</td>
          <td class="cell_5">&nbsp;</td>
          <td class="cell_5">&nbsp;</td>
        </tr>
        <tr>
          <td class="cell_5" style="max-width:20%">Interview 2 (Technical Interview)</td>
          <td class="cell_5">&nbsp;</td>
          <td class="cell_5">&nbsp;</td>
          <td class="cell_5">&nbsp;</td>
          <td class="cell_5">&nbsp;</td>
        </tr>
        <tr>
          <td class="cell_5" style="max-width:20%">Interview 3 (Validation Interview)</td>
          <td class="cell_5">&nbsp;</td>
          <td class="cell_5">&nbsp;</td>
          <td class="cell_5">&nbsp;</td>
          <td class="cell_5">&nbsp;</td>
        </tr>
      </table>
      <br>
      <div style="color:orange; font-weight:bold">Communication Plan</div>
      <table style="width: 100%">
        <tr>
          <td class="cell cell_heading" colspan="2" style="background-color: ${lblColour}">&nbsp;</td>
          <td class="cell cell_heading" colspan="1" style="background-color: ${lblColour}">Frequency</td>
          <td class="cell cell_heading" colspan="2" style="background-color: ${lblColour}">Day(s)</td>
          <td class="cell cell_heading" colspan="1" style="background-color: ${lblColour}">Time</td>
          <td class="cell cell_heading" colspan="2" style="background-color: ${lblColour}">Method</td>
          <td class="cell cell_heading" colspan="4" style="background-color: ${lblColour}">Contents</td>
        </tr>
        <tr>
          <td class="cell" colspan="2">Best Practice</td>
          <td class="cell" colspan="1">Weekly</td>
          <td class="cell" colspan="2">Mon or Fri</td>
          <td class="cell" colspan="1">Optional</td>
          <td class="cell" colspan="2">Email followed by a phone call</td>
          <td class="cell" colspan="4">
            <ul>
              <li>Sourcing Update</li>
              <li>Any Challenges</li>
              <li>Market Insight</li>
              <li>Role Alignment</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td class="cell" colspan="2">Agreed Plan</td>
          <td class="cell" colspan="1"></td>
          <td class="cell" colspan="2"></td>
          <td class="cell" colspan="1"></td>
          <td class="cell" colspan="2"></td>
          <td class="cell" colspan="4">
            &nbsp;
          </td>
        </tr>
      </table>
      `;
      } else {
        html += `
      <table style="width: 100%">
        <tr style="height: 10px">
          <td class="cell_across" colspan="2" style="background-color: ${hdrColour}; color: white;">
            <div class="section_heading">INSIGHT AND MARKET INTELLIGENCE</div>
          </td>
        </tr>
        <tr style="height:60px">
          <td class="cell_p4 cell_heading" style="background-color: ${lblColour}">
            Are there alternative job titles / common in competitor market? TA to make suggestions
          </td>
          <td>&nbsp;</td>
        </tr>
        <tr style="height:50px">
          <td class="cell_p4 cell_heading" style="background-color: ${lblColour}">
            Any movements in the market that you are aware of? Any opportunity? 
          </td>
          <td>&nbsp;</td>
        </tr>
        <tr style="height: 10px">
          <td class="cell_across" colspan="2" style="background-color: ${hdrColour}; color: white;">
            <div class="section_heading">SOURCING & ATTRACTION STRATEGY</div>
          </td>
        </tr>
        <tr style="height:70px">
          <td class="cell_p4 cell_heading" style="background-color: ${lblColour}">Please provide any details of target competitors/ local communities/groups</td>
          <td>&nbsp;</td>
        </tr>
        <tr style="height:70px">
          <td class="cell_p4 cell_heading" style="background-color: ${lblColour}">Have you asked for referrals from relevant team/networks? Discuss LinkedIn, sharing posts etc.</td>
          <td>&nbsp;</td>
        </tr>
        <tr style="height: 10px">
          <td class="cell_across" colspan="2" style="background-color: ${hdrColour}; color: white;">
            <div class="section_heading">ANY OTHER COMMENTS/NOTES</div>
          </td>
        </tr>
        <tr style="height:150px">
          <td colspan="2">&nbsp;</td>
        </tr>
      </table>
      <p class="pagebreak">&nbsp;</p>
      <table style="width: 100%">
        <tr style="height: 10px">
          <td class="cell_across" colspan="2" style="background-color: ${hdrColour}; color: white;">
            <div class="section_heading">INTERVIEW & ASSESSMENT</div>
          </td>
        </tr>
        <tr style="height:150px">
          <td class="cell_p4 cell_heading" style="background-color: ${lblColour}">
          How many interviews will there be and what format will these be in (phone, face-to-face, presentation)?<br>
          How long will each interview last?<br>
          Have you already considered who you would like in the interview process and what’s the diversity of the panel?<br>
          Do they know they will be involved?<br>
        `;
      }
      if (
        this.$loginState.user.client === "Akamai" ||
        this.$loginState.user.client === "staging"
      ) {
        html += `<b>If appropriate</b> - please discuss if HackerRank should support this recruitment (this link should be sent as pre work)
                  https://docs.google.com/presentation/d/15LPYM5ZelFaN1DV1JXZziyD62iW6VENdkBXNRIfL6lc/edit?usp=sharing`;
      }
      if (this.$loginState.user.client !== "Elsevier") {
        html += `</td>
            <td>&nbsp;</td>
          </tr>
          <tr style="height: 10px">
            <td class="cell_across" colspan="2" style="background-color: ${hdrColour}; color: white;">
              <div class="section_heading">ANY OTHER COMMENTS/NOTES</div>
            </td>
          </tr>
          <tr style="height:200px">
            <td colspan="2">&nbsp;</td>
          </tr>
          </table>
        </body></html>`;
      } else {
        html += `</body></html>`;
      }
      html = html.replace(/’/g, "'");
      html = html.replace(/•/g, "");

      html2docx(html, {
        orientation: "landscape",
        margins: { top: 500, bottom: 500, left: 500, right: 500 },
      }).then((data) => {
        utils.downloadFile(
          data,
          this.document.system_number +
            " - " +
            this.document.doc_name +
            " - Intake Flow.docx",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        );
      });
      this.exportMenu = false; // close export menu
    },
    // NOTHING CALLS THIS SO COMMENTING FOR NOW
    // saveDocHeader(status) {
    //   this.isLoading = true;
    //   this.document.doc_status = status;
    //   this.setupDocTypes();
    //   let data = {
    //     system_number: this.document.system_number,
    //     doc_id: this.document.doc_id,
    //     doc_status: status,
    //     doc_status_text: this.docStatus.text,
    //     recruiter: this.document.recruiter,
    //     contact: this.document.contact,
    //     approval_requested_date: this.document.approval_requested_date,
    //     start_date: this.document.start_date,
    //     quality: this.document.quality,
    //     hierarchies: this.document.hierarchies,
    //   };

    //   axios
    //     .post("document/saveDocumentHeader/", data)
    //     .then((resp) => {
    //       if (resp.data.Status === "OK") {
    //         this.docHierarchies = this.document.hierarchies;
    //         this.$store.dispatch("docs/updateDoc", resp.data.Data.documents[0]);
    //         this.$emit("saved", resp.data);
    //       } else {
    //         alert(resp.data.Message);
    //       }
    //       this.response = resp.data;
    //       this.isLoading = false;
    //       this.show = false;
    //       this.document = null;
    //     })
    //     .catch((err) => {
    //       if (err.response && err.response.status === 401) {
    //         this.$emit("sessionExpired", err);
    //       } else {
    //         console.log(err);
    //         this.response = err.response
    //           ? err.response.data
    //           : { message: "Unexpected Error" };
    //       }
    //       this.isLoading = false;
    //     });
    // },
    docUpdated(data) {
      this.docHierarchies = data.hierarchies;
      this.document = data;
    },
    getScrollbarWidth() {
      const dialog = document.getElementsByClassName(
        "v-dialog--fullscreen v-dialog--active"
      );
      let scrollbarWidth = 15;
      if (dialog && dialog.length)
        scrollbarWidth = dialog[0].offsetWidth - dialog[0].clientWidth;
      this.scrollbarWidth = scrollbarWidth;
    },
    resolveIcon(extension) {
      switch (extension) {
        case "xlsx":
        case "xls":
          return "mdi-microsoft-excel";
        case "doc":
        case "docx":
          return "mdi-microsoft-word";
        case "txt":
        case "log":
          return "mdi-file-document-outline";
        case "pdf":
          return "mdi-file-pdf-box";
      }
    },
    resolveHeaderStyle(extension) {
      switch (extension) {
        case "xlsx":
        case "xls":
          return "#3cc542ad";
        case "doc":
        case "docx":
          return "#327bb5c7";
        case "txt":
        case "log":
          return "#ff23138f";
        case "pdf":
          return "#ff23138f";
      }
    },
    selectFiles(fileId) {
      let file = this.fileItems.find((f) => f.file_id == fileId);
      if (file.selected) {
        let originDocId = file.docLinks.find(
          (x) => x.doc_type == "External File"
        );
        if (originDocId) {
          let tmpData = {
            file_id: file.file_id,
            doc_id: originDocId.doc_id,
          };
          this.selectedFiles.push(tmpData);
        }
      } else {
        let fileSelected = this.selectedFiles.find((f) => f.file_id == fileId);
        if (fileSelected) {
          let idx = this.selectedFiles.indexOf(fileSelected);
          if (idx >= 0) {
            this.selectedFiles.splice(idx, 1);
          }
        }
      }

      this.selectedFiles = JSON.parse(JSON.stringify(this.selectedFiles));
    },
    linkExtDoc() {
      let docIds = [];

      this.selectedFiles.forEach((file) => {
        docIds.push(file);
      });

      let data = {
        doc_id: this.document.doc_id,
        file_ids: docIds,
      };

      axios
        .post("file/linkFilesToDoc/", data)
        .then((resp) => {
          if (resp.data == true) {
            this.triggerNotification(
              "External Document Link Created Successfully",
              "success"
            );
            this.fetchData();
          } else {
            this.triggerNotification(resp.data, "error");
          }
          this.isLoading = false;
          this.dialogLinks = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    show(e) {
      if (nlpUtils.showContextMenu(this.$loginState.user.settings)) {
        if (this.tempNlpText !== "") {
          e.preventDefault();
          this.showMenu = false;
          this.x = e.clientX;
          this.y = e.clientY;
          this.$nextTick(() => {
            this.showMenu = true;
          });
        }
      }
    },
    closeMenu() {
      this.showMenu = false;
    },
    mouseUpEvent(event, isSourceSection) {
      switch (event.which) {
        case 1:
          this.databaseSelectionState.mouseUp = true;
          this.databaseSelectionState.mouseDown = false;
          this.databaseSelectionState.mouseMove = false;
          this.databaseSelectionState.dragStart = false;
          this.databaseSelectionState.dragEnd = false;
          this.getSelection(isSourceSection);
          break;
      }
    },
    showSource() {},
    getSelectionHtml() {
      var html = "";
      if (typeof window.getSelection != "undefined") {
        var sel = window.getSelection();
        if (sel.rangeCount) {
          var container = document.createElement("div");
          for (var i = 0, len = sel.rangeCount; i < len; ++i) {
            container.appendChild(sel.getRangeAt(i).cloneContents());
          }
          html = container.innerHTML;
        }
      } else if (typeof document.selection != "undefined") {
        if (document.selection.type == "Text") {
          html = document.selection.createRange().htmlText;
        }
      }
      return html;
    },
    getSelection(isSourceSection) {
      let selection = window.getSelection();
      let selectionText = selection.toString();
      if (selectionText !== "") {
        //const selectionStart = selection.anchorOffset;
        //const selectionEnd = selection.focusOffset;
        selection = window.getSelection();
        this.originalDocText = this.unmodifiedDocText;
        selectionText = selection.toString();
        this.showNlpOptions = isSourceSection;
        var range = document.createRange();
        range.setStart(selection.anchorNode, selection.anchorOffset);
        range.setEnd(selection.focusNode, selection.focusOffset);
        var backwards = range.collapsed;
        range.detach();

        var endNode = selection.focusNode,
          endOffset = selection.focusOffset;
        selection.collapse(selection.anchorNode, selection.anchorOffset);
        if (backwards) {
          selection.modify("move", "backward", "character");
          selection.modify("move", "forward", "word");
          selection.extend(endNode, endOffset);
          selection.modify("extend", "forward", "character");
          selection.modify("extend", "backward", "word");
        } else {
          selection.modify("move", "forward", "character");
          selection.modify("move", "backward", "word");
          selection.extend(endNode, endOffset);
          selection.modify("extend", "backward", "character");
          selection.modify("extend", "forward", "word");
        }
        selectionText = selection.toString();

        this.tempTextNode = selection.anchorNode;
        this.tempNlpText = selectionText;
        this.tempNlpHtml = this.getSelectionHtml();
        this.registerNewSelection();
      } else {
        this.databaseSelectionState.data = "";
        this.tempNlpText = "";
      }
    },
    makeSelection(event) {
      switch (event.which) {
        case 1:
          this.databaseSelectionState.mouseMove = true;
          setTimeout(() => {
            if (
              !this.databaseSelectionState.dragStart &&
              this.databaseSelectionState.mouseDown
            ) {
              this.databaseSelectionState.data = "";
            }
          }, 50);

          break;
      }
    },
    registerClick(event) {
      switch (event.which) {
        case 1:
          this.databaseSelectionState.mouseDown = true;
          this.databaseSelectionState.mouseUp = false;
          this.databaseSelectionState.dragStart = false;
          this.databaseSelectionState.dragEnd = false;
          break;
      }
    },
    registerNewSelection() {
      if (this.externalContentSimilarity > this.externalContentMaxSimilarity) {
        this.response = {
          Status: "Error",
          Message: "Document too similar, further content cannot be copied",
        };
        window.getSelection()?.removeAllRanges();
      } else {
        this.databaseSelectionState = {
          mouseDown: false,
          mouseUp: false,
          mouseMove: false,
          dragStart: false,
          dragEnd: false,
          data: this.tempNlpText,
        };
      }
    },
    dragEnd() {
      this.databaseSelectionState.mouseDown = false;
      this.databaseSelectionState.dragStart = false;
      this.databaseSelectionState.dragEnd = true;
      this.isDataDrag = false;
      this.filteredSuggestions.forEach((x) => {
        x.selected = false;
      });
      this.filteredSuggestions = JSON.parse(
        JSON.stringify(this.filteredSuggestions)
      );
    },
    dragStart(event) {
      if (event && event.dataTransfer) {
        this.databaseSelectionState.dragStart = true;
        this.databaseSelectionState.mouseMove = false;
        this.databaseSelectionState.data = event.dataTransfer;
        this.isDataDrag = true;
      }
    },
    dragStartSuggestion(item, event) {
      let items = this.filteredSuggestions
        .filter((x) => x.selected)
        .map((x) => {
          return { varVal: x.varVal };
        });

      if (items.length) {
        let text = "";
        items.forEach((x) => {
          text += "<p>" + x.varVal + "</p>";
        });

        let data = JSON.stringify({
          text: item.varVal,
          html: text,
        });
        event.dataTransfer.setData("object", data);
        this.isDataDrag = true;
      } else {
        this.filteredSuggestions;
        let data = JSON.stringify({
          text: item.varVal,
          html: "",
        });
        event.dataTransfer.setData("object", data);
        this.isDataDrag = true;
      }
      //event.dataTransfer.dropEffect = "move";
    },
    isCopyActionDisabled(getMessage, tmpl_id) {
      tmpl_id = tmpl_id || this.document.tmpl_id;
      let action = this.document.state.copyActions.find(
        (x) => x.tmpl_id_copy_destination === tmpl_id
      );
      if (action) {
        return this.isActionDisabled(action, getMessage);
      } else {
        return getMessage ? "Copy Action not found in workflow." : true;
      }
    },
    isActionDisabled(action, getMessage) {
      if (!this.document || !this.checkSummary) return false;
      if (this.document.state.missing_data && !action.allow_errors) {
        return getMessage
          ? `${action.name} is not permitted while ${this.document.state.triggerDCAction.name} is missing`
          : true;
      } else if (this.checkSummary.errors.length && !action.allow_errors) {
        return getMessage
          ? `${action.name} is not permitted while the document has inclusivity & quality errors`
          : true;
      } else if (this.checkSummary.warnings.length && !action.allow_warnings) {
        return getMessage
          ? `${action.name} is not permitted while the document has inclusivity & quality warnings`
          : true;
      } else {
        return getMessage ? "" : false;
      }
    },
    updateQuestionnaireAnswer(qa) {
      this.doStateAction(qa.action, true, qa);
    },
    saveDataCapture(dataCapture, action) {
      this.doStateAction(action, true, dataCapture);
    },
    cancelDataCapture() {
      this.dataCapture.show = false;
    },
    doWizardStateAction(request) {
      this.doStateAction(request.action, !!request.data, null, request.data);
    },
    async doStateAction(action, savedata, dcData, wizardData) {
      let result = await this.doStateAction_(
        action,
        savedata,
        dcData,
        wizardData
      );
      if (result && result.redirectURL) {
        window.open(result.redirectURL);
      }
    },
    async doStateAction_(action, savedata, dcData, wizardData) {
      if (!this.document) return;
      let notPermitted = this.isActionDisabled(action, true);
      if (notPermitted) {
        alert(notPermitted);
        return false;
      }
      // general comments are blocked for now so this won't be used
      if (action.isDocumentComment) {
        this.showComments();
        return;
      }
      if (action.emailDoc && !this.emailDialogue.value) {
        this.emailDialogue.action = action;
        this.emailDialogue.show = true;
        this.emailDialogue.sending = false;
        return;
      }
      this.isLoading = true;
      let dc = this.dataCapture;
      let request = {
        doc_id: this.document.doc_id,
        action_available_id: action.action_available_id,
        lifecycle_role_id: action.lifecycle_role_id,
        document_role_id: action.document_role_id,
        preProcessResponse: null,
      };
      let dcReadOnly = null;
      if (savedata) {
        if (wizardData) {
          request.preProcessResponse = wizardData;
        } else if (action.emailDoc) {
          request.preProcessResponse = { toAddress: this.emailDialogue.value };
        } else if (dcData.inputs && dcData.inputs.length) {
          request.preProcessResponse = dcData;
          dcReadOnly = this.document.state.dataCaptureActionsMain.find(
            (x) =>
              x.isDataCaptureViewer &&
              x.data_capture_type_id === action.data_capture_type_id
          );
        } else if (dcData.hr_id || dcData.hr_id_copy) {
          request.preProcessResponse = { ...dcData };
        } else if (action.questionnaire_id && dcData.data) {
          request.preProcessResponse = {
            document_data_capture_id: dcData.document_data_capture_id,
            answer: dcData.data,
            isComplete: dcData.isComplete,
            questionnaire_action: dcData.questionnaire_action,
          };
        } else if (action.data_capture_method === "review_group") {
          request.preProcessResponse = { newReviewGroupId: dcData };
        } else if (
          action.data_capture_method === "requisition" ||
          action.data_capture_method === "requisition_new"
        ) {
          request.preProcessResponse = { requisitions: dcData.requisitions };
        } else {
          request.preProcessResponse = dcData.value;
        }
      }

      return axios
        .post("document/processdocumentaction/", request)
        .then((resp) => {
          let result = { redirectURL: "" };
          if (resp.data.Status === "OK") {
            if (resp.data.Data.clipboard_content) {
              this.copyLinkToClipboard(resp.data.Data.clipboard_content);
            }
            if (resp.data.Data.dataCapture) {
              //   if(dc.action && dc.action.definition.document_data_capture_id === null){
              //     dc.action.definition.document_data_capture_id = resp.data.Data.dataCapture.document_data_capture_id;
              //   } else if(dc.action && resp.data.Data.dataCapture.status === "Complete"){
              //     dc.action.definition = resp.data.Data.dataCapture;
              dc.action = action;
              dc.action.definition = resp.data.Data.dataCapture;
              if (dc.action.isDataCaptureEditInHeader) {
                this.openHeader();
              } else if (
                dc.action.isDataCaptureEditPopup ||
                dc.action.definition.lifecycle_role_id_created ||
                dc.action.definition.isCommentBased ||
                dc.action.definition.isCheckBased
              ) {
                dc.action.definition.value = null;
                dc.show = true;
                if (
                  dc.action.isDataCaptureEditPopup &&
                  resp.data.Data.dataCapture.response?.method ===
                    "questionnaire" &&
                  resp.data.Data.dataCapture.response?.isCompleted
                ) {
                  this.editDisabled = false;
                }

                //update other views of same data capture (readonly view in doc etc)
                [
                  ...this.document.state.dataCaptureActionsHeader,
                  ...this.document.state.dataCaptureActionsMain,
                ]
                  .filter(
                    (rdc) =>
                      rdc.data_capture_type_id ===
                      dc.action.data_capture_type_id
                  )
                  .forEach((rdc) => {
                    rdc.definition = dc.action.definition;
                  });

                if (resp.data.Data.stateChanged) {
                  if (
                    resp.data.Data.documents &&
                    resp.data.Data.documents.length
                  ) {
                    this.documentRefreshed(resp.data.Data);
                  } else {
                    //state changed but no doc - probably don't have permission to view the document any more.
                    this.closeAndRemoveFromList(resp.data);
                  }
                }
              }
              this.isLoading = false;
              return;
            } else {
              if (
                dcReadOnly &&
                dcReadOnly.definition &&
                dcReadOnly.definition.inputs?.length
              ) {
                dcReadOnly.definition.inputs.forEach((i) => {
                  let newVal = request.preProcessResponse.find(
                    (r) => r.check_type_id === i.check_type_id
                  );
                  if (newVal) i.value = newVal.value;
                });
              }
              dc.action = null;
              dc.show = false;
              if (resp.data.Data.stateChanged) {
                if (
                  resp.data.Data.documents &&
                  resp.data.Data.documents.length
                ) {
                  this.documentHeaderSaved(resp.data);
                } else {
                  //state changed but no doc - probably don't have permission to view the document any more.
                  this.closeAndRemoveFromList(resp.data);
                }
                this.$store.dispatch("tasks/refresh", this.document.doc_id);
              }
              if (action.download_format) {
                this.generateDownload(action);
              }
              if (resp.data.Data.redirectURL) {
                // window.open(resp.data.Data.redirectURL);
                result.redirectURL = resp.data.Data.redirectURL;
              }
              if (resp.data.Data.copyAction) {
                this.doCopy(resp.data.Data.copyAction);
              }
            }
          } else {
            console.log(resp.data.Message);
          }
          this.closeEmailDialogue();
          this.isLoading = false;
          resp.data.Timeout = -1;
          this.response = resp.data;
          return result;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.closeEmailDialogue();
          this.isLoading = false;
          return false;
        });
    },
    showComments() {
      this.resetRightPanel();
      this.commentDialogue.show = true;
      if (this.current_doc_view) this.current_doc_view.showComments = true;
    },
    hideComments() {
      this.resetRightPanel();
    },
    commentsAdded(part) {
      if (part) part.hasComments = true;
    },
    resetRightPanel() {
      this.history = null;
      this.headerHistoryShow = false;
      this.ptDeleteHistoryPT = null;
      this.ptDeleteHistory = null;
      this.showSuggestions = false;
      this.commentDialogue.show = false;
      this.showDatabasePanel = false;
      if (this.current_doc_view) this.current_doc_view.showComments = false;
    },
    spellCheck() {
      //alert("Spellcheck: " + this.tempNlpText);
    },
    shortenSentence() {
      //alert("Shorten: " + this.tempNlpText);
    },
    userInitials() {
      return this.$loginState.user.name
        .split(" ")
        .map((x) => x.substr(0, 1))
        .join("");
    },
    commentHandler(action) {
      switch (action) {
        case "save_new":
          this.doStateActionComment(
            this.document.state.docPartCommentAction,
            this.comment
          );
          break;
        case "cancel_new":
          this.comment.text = "";
          this.comment.isDirty = false;
          break;
      }
    },
    doStateActionComment(action, comment) {
      if (!this.document) return;
      this.isLoading = true;
      let saveRespHandler = (data) => {
        this.commentHandler("cancel_new");
        if (data) {
          if (this.comment.part) this.comment.part.hasComments = 1;
          let newComment = data.comments[0];
          this.history.collabSummary.unshift({
            type: "comment",
            sortBy: null,
            user_name: newComment.user_name,
            user_initials: newComment.user_initials,
            data: data.comments[0],
          });
        }
      };
      let request = {
        doc_id: this.document.doc_id,
        action_available_id: action.action_available_id,
        lifecycle_role_id: action.lifecycle_role_id,
        document_role_id: action.document_role_id,
        preProcessResponse: null,
        comment: comment,
      };
      axios
        .post("document/processdocumentaction/", request)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            saveRespHandler(resp.data.Data);
          } else {
            alert(resp.data.Message);
          }
          this.isLoading = false;
          this.response = resp.data;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.containerNoPad {
  padding: 0 !important;
}
</style>
<style scoped lang="scss">
.draggableText ::selection {
  background: #ffeb0073;
}
//new UI specific styling
.v-application:not(.uitheme-oldui) {
  .doccontainer {
    padding: 0 4px;
  }

  .editorcolumn::v-deep .editsection,
  .editorcolumn::v-deep .wizard {
    padding-top: 70px;
  }

  .headerData {
    font-family: "Manrope", sans-serif !important;
    cursor: pointer;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 1.5px;
    color: #7a7e87;
    padding-left: 5px;
    min-width: 340px;
    .col {
      padding: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &.theme--dark .headerData {
    color: #cdd3e2;
  }

  .editorcolumn {
    padding: 0;
    @media (max-width: 960px) {
      overflow-x: hidden;
    }
  }

  .sourceDocColumn {
    top: 76px;
    right: 5px;
    position: fixed;
    padding-bottom: 70px;
  }

  .scorecolumn {
    top: 70px;
    right: 15px;
    position: fixed;
    padding: 0;
    overflow-y: scroll;
    max-height: calc(100% - 50px);
    .v-card {
      background: transparent;
      border: 0;
      border-radius: 0;
      box-shadow: none;
    }
  }

  .scorecard {
    display: block;
    flex: 1 1 0%;
    overflow: hidden;
    box-shadow: none;
    border: solid #d0d6ec;
    border-width: 0 0 1px 0;
    border-radius: 0;
    background: transparent;

    .scoretitle {
      font-size: 18px !important;
      padding-right: 4px;
    }

    .scoresection {
      background: transparent;
      padding-right: 5px;
      box-shadow: none;
      border: solid #d0d6ec;
      border-width: 1px 0 0 0;
      border-radius: 0;

      .sectionText {
        padding-left: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row-reverse;

        .v-progress-circular {
          margin-right: 19px;
        }
      }

      .scoreDetail {
        > :first-child {
          padding: 4px 16px;
          max-width: initial;
          flex: 0 0 50px;
          max-width: 50px;
          i {
            font-size: 22px;
          }
        }
        > :last-child {
          padding-left: 10px;
          flex: 1 1 auto;
          width: auto;
        }
      }
    }
  }
}

//old UI specific styling
.v-application.uitheme-oldui {
  .doccontainer {
    padding-top: 70px;
  }

  .scorecolumn {
    top: 70px;
    right: 30px;
    max-width: 24%;
    position: fixed;
    overflow-y: scroll;
    max-height: calc(100% - 50px);
  }

  .scorecard {
    border-radius: 25px !important;
    box-shadow: rgb(255, 255, 255) -2px -2px 4px 0px,
      rgba(0, 0, 0, 0.25) 8px 8px 8px 0px !important;
    display: block;
    flex: 1 1 0%;
    background-color: #ffffff26 !important;
    min-height: 500px;
    /* max-width: 350px; */
    margin-bottom: 25px;
    overflow: hidden;
    padding: 20px;
    z-index: 200;
    border: solid 2px #ffffffa8 !important;
    box-shadow: -2px -2px 4px 0 #fff, 8px 8px 8px 0 rgba(0, 0, 0, 0.25) !important;
  }

  .scoresection {
    color: #222;
    transition: all 100ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    border: solid #d0d6ec;
    border-width: 0;
    box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.9),
      2px 2px 2px rgba(245, 245, 245, 0.9), 1px 1px 3px rgba(0, 0, 0, 0.07) !important;
    &:not(:first-child) {
      border-width: 1px 0 0 0;
    }
  }

  .scoretitle {
    font-size: 16px !important;
    margin-top: -14px !important;
    color: rgb(71, 71, 71) !important;
    padding: 0 !important;
  }
}

.v-app-bar {
  .v-btn--outlined {
    margin-right: 10px;
  }
}

::v-deep .flag-badge .v-badge__badge {
  inset: auto auto calc(100% - 11px) calc(100% - 4px) !important;
}

.v-application.theme--light .v-progress-circular__info span {
  color: gray !important;
}

.v-application.theme--dark .v-progress-circular__info span {
  color: white !important;
}

.buttons {
  margin-top: 35px;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.dragHandle {
  color: rgba(56, 106, 163, 0.801) !important;
  cursor: grab;
  margin-left: -40px;
  /* margin-left: 30%; */
}
.drag-handle,
.number-part {
  max-width: 20px;
  padding: 6px 0;
}
.sub-part {
  max-width: 40px;
}
.WorkingPattern {
  line-height: 18px;
}
.ListPart {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  max-width: 30px;
}
.Gutter {
  color: #ce8705ba;
  width: 0px;
}
.NoGutter {
  display: none !important;
}
.headerCell {
  padding: 10px 10px 10px 0;
}
.headerCellSmall {
  padding: 0 10px;
  font-size: 0.75rem;
  color: #946206da;
}
@media print {
  .list-group {
    margin-top: -30px;
  }
}
.col.part {
  padding: 1px;
}
.editArea,
.editAreaReadOnly {
  margin-left: 50px;
  white-space: pre-wrap;
  border: solid white 1px;
}
.col.editArea,
.col.editAreaReadOnly,
.col.editAreaBlank,
.row--dense > .col.editAreaBlank {
  padding: 0 4px;
}
/* .editArea:hover,
.editAreaBlank:hover {
  outline: none;
  box-shadow: 0 0 10px #9ecaed;
  border: solid #9ecaed 1px;
  cursor: pointer;
  border-radius: 5px;
} */
.editAreaBlank {
  outline: none;
  color: #ed8a8a !important;
  box-shadow: 0 0 5px #ed8a8a30;
  border: solid #ed8a8a30 1px;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 50px;
}
.emptyText {
  font-style: italic;
  font-weight: 300 !important;
}
.v-input--selection-controls {
  margin-top: 3px;
}
label,
.v-label,
.v-input--selection-controls .v-input__slot > .v-label {
  font-size: 10px;
}
.v-messages {
  max-height: 1px;
}
.v-list-item__action {
  margin: 1px 0;
}
.subPartGutter {
  position: absolute;
  left: -80px;
  color: #ce8705ba;
}
div .subPartRow:not(first-child) {
  margin-left: -4px;
}

.v-btn.sectionItem {
  border: 0;
}

.suggestionItem {
  font-family: "Manrope", sans-serif !important;
  background: #f5f7fa;
  box-shadow: inset 2px 0px 0px #377dff;
  border-radius: 6px;
  margin-bottom: 10px;
}

.suggestionTextTitle {
  font-family: "Manrope", sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: bolder;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #75838f;
  -webkit-line-clamp: unset !important;
  padding-top: 17px;
  padding-bottom: 6px;
}

.suggestionText {
  font-family: "Manrope", sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #75838f;
  -webkit-line-clamp: unset !important;
}

.suggestionItem.v-list-item--active {
  font-family: "Manrope", sans-serif !important;
  background-color: rgba(55, 125, 255, 0.12) !important;
}

.flow_section.v-list-item--active * {
  color: #008aff !important;
  font-weight: bolder;
}

.suggestionQualityHigh {
  color: #4caf50 !important;
}
.suggestionQualityMed {
  color: orange !important;
}

.suggestionQualityLow {
  color: red !important;
}

.viewHolder {
  height: 100vh;
}

.databaseTabs .v-tab {
  font-size: 0.8rem;
  //text-transform: none;
  //font-weight: bold;
  //text-wrap: wrap;
}

.db-file-name {
  display: flex;
  margin: 0 10px;
  overflow: hidden;
  max-width: calc(100% - 180px);
  h3 {
    overflow: hidden;
  }
}

::v-deep .v-data-table.row-click {
  tbody > tr,
  tbody > tr span {
    cursor: pointer;
  }
}
</style>
