<template>
  <v-container>
    <v-row dense>
      <v-col cols="2">
        <div>
          <HierarchySearch
            :items="docParts"
            :hierarchyTypes="hierarchyTypes"
            @filterChanged="doHierarchyFilter"
          ></HierarchySearch>
        </div>
      </v-col>
      <v-col cols="2">
        <v-tabs
          vertical
          background-color="secondary-background"
          show-arrows
          v-model="activeTab"
        >
          <v-tab
            v-for="pt in items"
            :key="pt.type"
            @click="filterPartType(pt.tmpl_part_id)"
            >{{ pt.type + " (" + pt.parts.length + ")" }}</v-tab
          >
        </v-tabs>
      </v-col>
      <v-col cols="8" v-if="cleanItems.length">
        <v-row dense>
          <v-col cols="3">
            <v-text-field
              :value="filterText"
              dense
              flat
              clearable
              hide-details
              prepend-inner-icon="mdi-magnify"
              label="type text and enter to filter text..."
              class="hidden-sm-and-down"
              @input="changefilterText"
              @keypress.enter="triggerFilter"
              @click:clear="clearFilter"
            />
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="docStatusIncluded"
              :items="docStatusSummary"
              item-text="status"
              item-value="status"
              return-object
              label="Job Statuses Included"
              multiple
              :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
              dense
              @change="doFilter()"
            ></v-select>
          </v-col>
          <v-col cols="1">
            <v-btn
              icon
              @click="exportToCSV"
              class="float-right"
              color="green darken-2"
              title="Download List"
            >
              <v-icon>mdi-cloud-download</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-card-text v-if="parts.length">
          <div v-for="(p, ip) in parts" :key="'ip' + ip">
            <v-row dense>
              <v-col
                cols="9"
                style="
                  border: solid lightgray 1px;
                  margin: 5px;
                  border-radius: 8px;
                  cursor: pointer;
                "
              >
                <v-row
                  dense
                  v-for="(sp, isp) in p.subParts"
                  :key="'ip' + ip + '_' + isp"
                  :class="sp.type === 'listitem' ? 'ListItemRow' : ''"
                  ><v-col v-if="sp.type === 'text'">
                    <div
                      @click="editText(p)"
                      style="min-height: 25px; width: 100%"
                      v-html="applyReadOnlyHighlights(sp.text)"
                    ></div>
                  </v-col>
                  <v-col v-else style="margin-left: 32px; max-width: 827px">
                    <v-row dense>
                      <v-col cols="1">
                        <v-icon x-small>mdi-circle</v-icon>
                      </v-col>
                      <v-col @click="editText(p)" cols="11">
                        <div style="width: 100%">
                          <span
                            v-html="applyReadOnlyHighlights(sp.text)"
                          ></span>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="2">
                <v-row dense>
                  <v-col>
                    <span color="blue darken-2"
                      >{{ p.WordCount }} - {{ p.Status }}</span
                    >
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <span
                      @click="p.showDocs = !p.showDocs"
                      style="cursor: pointer"
                    >
                      <v-icon v-if="p.showDocs">mdi-chevron-up</v-icon>
                      <v-icon v-else>mdi-chevron-down</v-icon>
                      {{ p.documentparts.length }} document{{
                        p.documentparts.length === 1 ? "" : "s"
                      }}
                    </span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row
              dense
              wrap
              style="border-bottom: solid lightgray 1px"
              v-if="p.showDocs"
            >
              <v-col
                cols="3"
                v-for="(dp, dpi) in p.documentparts"
                :key="'ip' + ip + 'dp' + dpi"
              >
                <v-list-item class="doc">
                  <img
                    src="../assets/ja-icon.png"
                    style="width: 35px; margin-right: 5px"
                    @click="openFullDocument(dp)"
                  />
                  <v-list-item-content @click="openFullDocument(dp)">
                    <v-list-item-title
                      :color="
                        dp.doc_status === 'APPROVED'
                          ? 'green'
                          : dp.doc_status === 'DRAFT'
                          ? 'red'
                          : 'orange'
                      "
                      >{{
                        dp.system_number + " - " + dp.doc_status_text
                      }}</v-list-item-title
                    >
                    <v-list-item-subtitle>{{
                      dp.doc_name
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-col>
    </v-row>
    <ResponseHandler :serviceResponse="response"></ResponseHandler>
    <TextEdit
      :open="textEdit.show"
      @saved="saveTextEdit"
      @closed="textEdit.show = false"
      :settings="textEdit"
    ></TextEdit>
    <Loading :isVisible="isLoading" />
  </v-container>
</template>

<script>
import axios from "axios";
//import draggable from "vuedraggable";
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
import TextEdit from "@/components/cTextEditDialogue"; // @ is an alias to /src
import utils from "@/common/utils.js";
import HierarchySearch from "@/components/cHierarchySearch";

export default {
  name: "BrandedContent",
  components: {
    ResponseHandler,
    TextEdit,
    HierarchySearch,
  },
  props: {},
  data: function () {
    return {
      response: null,
      partTypeSelected: null,
      items: [],
      parts: [],
      cleanItems: [],
      isDirty: false,
      dirtyCount: 0,
      isLoading: false,
      docStatusSummary: [],
      docStatusIncluded: [],
      docTypeSummary: [],
      docTypeIncluded: [],
      itemStatusSummary: [],
      itemStatusIncluded: [],
      itemsGroups: [],
      itemsGroupsIncluded: [],
      tempFilterText: "",
      filterText: "",
      textEdit: {
        text: "",
        notes: "",
        label: "text",
        show: false,
        showWordCount: true,
        showNotes: false,
        title: "",
        wordChecks: [],
        //closed: false
      },
      dragging: false,
      docParts: [],
      hierarchyTypes: [],
      hierarchyFilters: [],
      utils: utils,
      activeTab: null,
      tmpl_part_metadata: null,
    };
  },
  created() {
    this.fetchData();
  },
  watch: {},
  methods: {
    fetchData() {
      this.isLoading = true;

      axios
        .get("document/brandedContent/")
        .then((resp) => {
          if (resp.data.Status === "OK") {
            this.docStatusSummary.length = 0;
            this.docTypeSummary.length = 0;
            this.itemsGroups.length = 0;
            this.itemStatusSummary.length = 0;
            let docParts = [];
            this.textEdit.wordChecks = utils.initialisePartChecks(
              resp.data.Data.wordChecks
            );
            this.cleanItems = resp.data.Data.documentParts.map((x) => {
              let n = JSON.parse(JSON.stringify(x));

              let pg = this.itemsGroups.find(
                (s) => s.partGroup === n.partGroup
              );
              if (!pg) {
                pg = { partGroup: n.partGroup, show: true };
                this.itemsGroups.push(pg);
              }
              n.parts.forEach((r) => {
                r.rowDirty = false;
                r.showDocs = false;
                r.rowMessage = "";
                utils
                  .setDocPartStatus(
                    r,
                    n.tmpl_part_metadata,
                    this.textEdit.wordChecks
                  )
                  .forEach((rs) => {
                    let ss = this.itemStatusSummary.find(
                      (s) => s.status === rs
                    );
                    if (!ss) {
                      ss = { status: rs, count: 0, show: true };
                      this.itemStatusSummary.push(ss);
                    }
                    ss.count++;
                  });
                r.documentparts.forEach((dp) => {
                  let ss = this.docStatusSummary.find(
                    (s) => s.status === dp.doc_status_text
                  );
                  if (!ss) {
                    ss = { status: dp.doc_status_text, count: 0, show: true };
                    this.docStatusSummary.push(ss);
                  }
                  ss.count++;

                  let st = this.docTypeSummary.find(
                    (s) => s.docType === dp.doc_type
                  );
                  if (!st) {
                    st = {
                      docType: dp.doc_type,
                      count: 0,
                      docAbbrev: dp.doc_type_abbrev,
                    };
                    this.docTypeSummary.push(st);
                  }
                  st.count++;

                  docParts.push(dp);
                });
              });

              return n;
            });

            if (!this.itemsGroupsIncluded.length) {
              this.itemsGroupsIncluded.push(
                this.itemsGroups.find(
                  (s) => s.partGroup === "Branded Content"
                ) || this.itemsGroups[0]
              );
            }
            this.docStatusIncluded.push(
              this.docStatusSummary.find(
                (s) => s.status.toLowerCase() === "approved"
              ) || this.docStatusSummary[0]
            );
            this.docTypeIncluded.push(
              this.docTypeSummary.find(
                (s) => s.docType.toLowerCase() === "job advert"
              ) || this.docTypeSummary[0]
            );
            this.itemStatusIncluded = this.itemStatusSummary.filter(s => s.status);
			let defaultTP = this.cleanItems.find(c => c.type === "Closing Copy");
			if (defaultTP) {
				this.filterPartType(defaultTP.tmpl_part_id);
				this.activeTab = this.cleanItems.findIndex(c => c.tmpl_part_id === defaultTP.tmpl_part_id);
			}
            this.hierarchyTypes = resp.data.Data.hierarchyTypes;
            this.docParts = docParts;
            this.doFilter();
            this.isLoading = false;
          }
          this.response = resp.data;
          //console.log(resp);
        })
        .catch((err) => {
          this.$emit("sessionExpired", err);
          console.log(err);
          this.isLoading = false;
        });
    },
    changefilterText(val) {
      this.tempFilterText = val;
    },
    clearFilter() {
      this.tempFilterText = "";
      this.triggerFilter();
    },
    triggerFilter() {
      this.filterText = this.tempFilterText;
      this.doFilter();
    },
    filterPartType(partType) {
      this.partTypeSelected = partType;
      this.doFilter();
    },
    doHierarchyFilter(filters) {
      this.hierarchyFilters = filters || [];
      this.doFilter();
    },
    doFilter() {
      let items = JSON.parse(JSON.stringify(this.cleanItems));
      items = items.filter((ci) =>
        this.itemsGroupsIncluded.find((pg) => pg.partGroup === ci.partGroup)
      );
      if (items.length) {
        let ptc = items.find((i) => i.tmpl_part_id === this.partTypeSelected);
        this.partTypeSelected =
          (ptc && ptc.tmpl_part_id ? ptc.tmpl_part_id : null) ||
          items.find(
            (it) => this.itemsGroupsIncluded[0].partGroup === it.partGroup
          ).tmpl_part_id;

        this.tmpl_part_metadata = items.find(
          (i) => i.tmpl_part_id === this.partTypeSelected
        ).tmpl_part_metadata;

        let hyf = this.hierarchyFilters.filter((f) => f.selected.length);
        if (hyf.length) {
          items.forEach((pt) => {
            pt.parts.forEach((p) => {
              hyf.forEach((hf) => {
                p.documentparts = p.documentparts.filter((dp) =>
                  hf.selected.find((s) => {
                    let ht = dp.hierarchies.find((ht) => ht.ht_id === hf.ht_id);
                    return ht && s.id.split("_")[0] == ht.hr_id; // && !s.children
                  })
                );
              });
            });
            pt.parts = pt.parts.filter((x) => x.documentparts.length > 0);
          });
        }

        if (this.itemStatusSummary.length > this.itemStatusIncluded.length) {
          items.forEach((pt) => {
            pt.parts = pt.parts.filter((x) =>
              x.StatusList.some((s) =>
                this.itemStatusIncluded.some((si) => si.status === s)
              )
            );
          });
        }
        if (this.docStatusSummary.length > this.docStatusIncluded.length) {
          items.forEach((pt) => {
            pt.parts.forEach((p) => {
              p.documentparts = p.documentparts.filter((dp) =>
                this.docStatusIncluded.find(
                  (s) => s.status === dp.doc_status_text
                )
              );
            });
            pt.parts = pt.parts.filter((x) => x.documentparts.length > 0);
          });
        }
        if (this.docTypeSummary.length > this.docTypeIncluded.length) {
          items.forEach((pt) => {
            pt.parts.forEach((p) => {
              p.documentparts = p.documentparts.filter((dp) =>
                this.docTypeIncluded.find((s) => s.docType === dp.doc_type)
              );
            });
            pt.parts = pt.parts.filter((x) => x.documentparts.length > 0);
          });
        }
        const filterText = (this.filterText || "").trim().toLowerCase();
        if (filterText) {
          let search = filterText
            .split(" ")
            .filter((x) => x.length)
            .map((x) => x);
          items.forEach((pt) => {
            pt.parts = pt.parts.filter((x) =>
              search.every((s) => x.text.toLowerCase().indexOf(s, 0) >= 0)
            );
            pt.parts.forEach((p) => {
              search.forEach((s) => {
                p.text = p.text.replace(
                  new RegExp(s, "gi"),
                  (match) => `<mark>${match}</mark>`
                );
              });
            });
          });
        }
      }
      this.items = items;
      let pt = items.find((x) => x.tmpl_part_id === this.partTypeSelected);
      this.parts = pt ? pt.parts : [];
      return;
    },
    getCleanParent(parent) {
      return this.cleanItems.find(
        (x) => x.system_number === parent.system_number
      );
    },
    getCleanRow(row, parent) {
      return this.getCleanParent(parent).parts.find((x) => x.id === row.id);
    },
    saveRow(row, newval) {
      this.isLoading = true;
      let origText = utils.removeTags(row.text);
      let codeerror = false;
      if (newval.subParts) {
        newval.text = utils.subpartsToPart(newval.subParts);
      }

      let data = {
        text: newval.text,
        documentparts: row.documentparts.map((dp) => {
          return {
            doc_part_id: dp.doc_part_id,
          };
        }),
      };

      axios
        .post("document/savepartdocs/", data)
        .then((resp) => {
          codeerror = true;
          if (resp.data.Status === "OK") {
            let parttypeInstance = this.cleanItems.find(
              (x) => x.type === row.type
            );
            let orig = parttypeInstance.parts.find((p) => p.text === origText);
            let toMerge = parttypeInstance.parts.find(
              (p) => p.text === newval.text
            );
            orig.text = newval.text;
            row.text = newval.text;
            utils.setDocPartStatus(orig);
            utils.setDocPartStatus(row);
            if (toMerge) {
              orig.documentparts = toMerge.documentparts.concat(
                orig.documentparts
              );
              parttypeInstance.parts = parttypeInstance.parts.filter(
                (x) => x.text !== origText
              );
            }
          } else {
            row.rowMessage = resp.data.Message;
          }
          this.response = resp.data;
          console.log(resp);
          this.isLoading = false;
        })
        .catch((err) => {
          if (!codeerror) this.$emit("sessionExpired", err);
          this.isLoading = false;
          row.rowMessage = err;
          console.log(err);
        });
    },
    countWordsText(data) {
      return this.countWords(data) + " words";
    },
    countWords(data) {
      return data.split(" ").filter((x) => x.length).length;
    },
    editText(row) {
      this.textEdit.show = true;
      this.textEdit.text = utils.removeTags(row.text);
      this.textEdit.subParts = row.subParts;
      this.textEdit.row = row;
      this.textEdit.title = row.type;
      this.textEdit.rules = this.tmpl_part_metadata;
      this.textEdit.rules.tp_locked = 0;
    },
    saveTextEdit(newval) {
      this.saveRow(this.textEdit.row, newval);
      this.textEdit.show = false;
    },
    openFullDocument(doc) {
      this.$emit("openDocument", {
        system_number: utils.removeTags(doc.system_number),
        doc_name: utils.removeTags(doc.doc_name),
      });
    },
    getStatusFilterText(item, index) {
      if (item && index > 0) return "";
      if (this.itemStatusIncluded.length === this.itemStatusSummary.length) {
        return this.itemStatusSummary.length > 0 ? "All" : "None";
      } else {
        let defList = this.itemStatusSummary.filter((s) => s.status !== "OK");
        if (
          defList.every((s) =>
            this.itemStatusIncluded.some((si) => si.status === s.status)
          )
        ) {
          let text = "";
          text = "All issue statuses";
          if (defList.length < this.itemStatusIncluded.length) {
            text +=
              " (+ " +
              this.itemStatusIncluded
                .filter(
                  (ds) => !defList.some((d) => d === ds.status.toLowerCase())
                )
                .reduce((prev, curr) => {
                  return prev + (prev ? ", " : "") + curr.status;
                }, "");
          }
          return text;
        } else {
          return this.itemStatusIncluded.reduce((prev, curr) => {
            return prev + (prev ? ", " : "") + curr.status;
          }, "");
        }
      }
    },
    applyReadOnlyHighlights(text) {
      let status = utils.applyHighlights(
        text,
        this.textEdit.wordChecks.filter((w) =>
          w.tmpl_part_types.some(
            (tp) => tp.tp_id === this.tmpl_part_metadata.tmpl_part_id
          )
        ),
        true
      );
      return status.highlightedText;
    },
    exportToCSV() {
      let data =
        '"Client","Group","Section","Description","Status","Job","Job Title","Recruiter","Cost Center","Job Status","Location"\n';
      this.items.forEach((g) => {
        g.parts.forEach((p) => {
          p.documentparts.forEach((dp) => {
            data += `"${g.client_name}","${g.partGroup}","${
              p.type
            }","${p.text.split("’").join("'")}","${p.WordCount} - ${
              p.Status
            }","${dp.system_number}",`;
            data += `"${dp.doc_name}","${dp.recruiter ? dp.recruiter : ""}","${
              dp.cost_center
            }","${dp.doc_status_text}","${dp.location}"\n`;
          });
        });
      });
      utils.downloadFile(data, "DocParts.csv", "text/csv;encoding:utf-8");
    },
  },
};
</script>
<style scoped>
.buttons {
  margin-top: 35px;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.dragHandle {
  color: blue;
  cursor: grab;
  margin-left: 30%;
}
</style>
<style>
.doc .v-list-item__content {
  padding: 4px 0px;
}
.doc .v-list-item__title {
  font-size: 14px;
}
.doc .v-list-item__subtitle {
  font-size: 12px;
}
</style>