<template>
    <v-card>
      <v-card-actions>
        <v-btn color="blue darken-1" text @click="closeCookies">Close</v-btn>
      </v-card-actions>
      <v-card-title><h1>Our personal data and cookie policy</h1></v-card-title>
      <v-main class="mx-5" style="margin-top:-45px;">
        <div>
        <h2>Personal Data</h2>
        <p>
          The only personal data we hold are your name and email address and these are only used within the RoleMapper processes.
        </p>
        </div>
      <div>
          <h2>What are cookies and similar technologies and why do we use them?</h2>
          <p>
            Cookies and similar technologies allow small pieces of information to be read from and/or placed onto your device when you visit a website or application. Your web browser (such as Google
            Chrome, Internet Explorer or Mozilla Firefox) then sends this information back to the website on each subsequent visit
            so that they can remember things like preferences.
          </p>
          <p>
            Cookies and similar technologies are very useful and do lots of different jobs. For example, they enable you to move between our web pages efficiently,
            they remember your preferences when you visit us again and generally improve your experience when browsing with
            us.
          </p>
          <p>
            Cookies last for different lengths of time depending on the job they do. There are session cookies and persistent cookies:
          </p>
          <ul>
            <li>Session cookies only last for your online session and disappear from your device when you close your browser</li>
            <li>Persistent cookies stay on your device after the browser has been closed and last for the period of time specified
              in the cookie</li>
          </ul>
      </div>
      <div class="section">
        <h2>The cookies we use</h2>
        <p>
          The cookies and similar technologies used on our website fall into two categories: strictly necessary or performance.
        </p>

        <h3>1. Strictly necessary cookies</h3>
        <p>
          Strictly necessary cookies let you move around the website and use essential features of the application.
        </p>
        <p>
          <b>We use these strictly necessary cookies to:</b>
        </p>
        <ul>
          <li>Remember your prior preferences on some screens</li>
          <li>Remember previous documents you have worked on</li>
          <li>Identify you as being logged in to our website</li>
          <li>Make sure you see the relevant options dependent on your role</li>
        </ul>
        <div style="font-size:small;font-weight:bold" class="my-2">Cookies we have defined as strictly necessary will NOT be used to gather information that could be used to advertise products or services to you</div>
      </div>

      <div class="section">
        <h3>2. Performance cookies</h3>
        <p>
          Performance cookies collect information about how you use our website. For example, which pages you visit and if you experience
          any errors. These cookies are essential to us being able to operate and maintain our website.
        </p>
        <p>
          These cookies don't collect any information that could identify you – all the information collected is anonymous.
        </p>
        <p>
          Accepting these cookies is a condition of using the website, so if you prevent them we cannot guarantee how our site will
          perform for you.
        </p>
        <p>
          In some cases, some performance cookies are managed for us by third parties, but we don't allow the third party to use the
          cookies for any purpose other than those listed below.
        </p>
        <p>
          <b>We use performance cookies to:</b>
        </p>
        <ul>
          <li>Provide statistics on how our website is used</li>
          <li>Help us improve the website by measuring any errors that occur</li>
          <li>Test different designs of our website</li>
        </ul>
        <br>
        <p>
          <b>Cookies we have defined as performance will NOT be used to:</b>
        </p>
        <ul>
          <li>Gather information that could be used to advertise products or services to you on other websites</li>
          <li>Remember your preferences or username beyond your current visit</li>
          <li>Target adverts to you on any other website </li>
        </ul>
      </div>

      <div class="section">
        <h2>Managing cookies</h2>
        <p>
          There are ways you can control and manage cookies on your device. Please remember that any settings you change will not just
          affect the cookies we use. These changes will apply to all websites you visit (unless you choose to block cookies
          from particular sites).
        </p>
        <p>
          <b>Managing cookies in your browser</b>
        </p>
        <p>
          Most browsers will allow you to choose the level of privacy settings you want. You can block all cookies, or accept all cookies
          or pick a setting somewhere in between. This range lets you control your cookie settings, so you can:
        </p>
        <ul>
          <li>See what cookies you've got and delete them on an individual basis
            </li><li>Block third party cookies</li>
            <li>Block cookies from particular sites</li>
            <li>Block all cookies from being set</li>
            <li>Delete all cookies when you close your browser</li>
        </ul><br>
        <p>
          Deleting cookies means that any preference settings you have made on a website will be lost. Blocking all cookies
          means functionality on our websites will be lost, as described above. You will not be able to use RoleMapper if
          you block all cookies.
        </p>
        <p>
          Find out how to manage your cookies using the most popular browsers below.
        </p>
        <p>
          <b>Internet Explorer</b><a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies</a>
        </p>
        <p>
          <b>Firefox</b><a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?">https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?</a>
        </p>
        <p>
          <b>Google Chrome</b><a href="https://support.google.com/chrome/answer/95647?hl=en">https://support.google.com/chrome/answer/95647?hl=en</a>
        </p>
        <p>
          <b>Safari</b><a href="https://support.apple.com/kb/PH21411?locale=en_US">https://support.apple.com/kb/PH21411?locale=en_US</a>
        </p>
      </div>
      </v-main>
      <v-card-actions>
        <v-btn color="blue darken-1" text @click="closeCookies">Close</v-btn>
      </v-card-actions>
    </v-card>
</template>

<script>

export default {
  name: "CookieDisclaimer",
  data: function() {
    return {
        showCookies: true
      }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  methods: {
    closeCookies() {
      this.$emit("closeCookies");
    },
  }
};
</script>
<style scoped>
  h2 {
    font-size: large;
    margin: 15px 0;
  }
  h3 {
    font-size: medium;
    margin: 10px 0;
  }
  p, li {
    font-size:small;
  }
  a {
    margin-left: 10px;
  }
</style>
