import { utils } from "@/../../shared/functions";
import dayjs from "dayjs";
import axios from "axios";
export default {
	...utils,
	downloadFile(content, fileName, mimeType) {
		var a = document.createElement("a");
		mimeType = mimeType || "application/octet-stream";

		if (navigator.msSaveBlob) {
			// IE10
			navigator.msSaveBlob(
				new Blob([content], {
					type: mimeType
				}),
				fileName
			);
		} else if (URL && "download" in a) {
			//html5 A[download]
			a.href = URL.createObjectURL(
				new Blob([content], {
					type: mimeType
				})
			);
			a.setAttribute("download", fileName);
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		} else {
			location.href =
				"data:application/octet-stream," + encodeURIComponent(content); // only this mime type is supported
		}
	},
	spellCheckDataExists(tp_id, data){
		if(data.length > 0){
		
		let spellChecks = data.find(x => x.type === "spell_check" && x.tp_id === tp_id);
			if(spellChecks !== undefined && spellChecks.data.length > 0){
				return spellChecks;
			}
		}

		return null;
	},
	replaceAt(text, word, idx, spellItem, spellIdx, tmpl_part, doc_part){
		let replacement = `<mark class='nlpData' data-correction-type='spellcheck' data-language-reference='${tmpl_part}_${doc_part}_${spellIdx}'>${word}</mark>`;
		let length = word.length;
		let newText = text.substring(0, idx) + replacement + text.substring((idx+length));
		return newText;
	},
	escapeRegExpString(str){
		return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
	},
	applyLanguageHighlights(text,data,tmpl_part_id, doc_part_id, dictionary){
		if(data !== null){
			let tmpl_part = data.find(x => x.tmpl_part_id === tmpl_part_id);
			if(tmpl_part){
				if(tmpl_part.parts.length === 0){
					return text;
				}
				let dp = tmpl_part.parts.find(x => x.doc_part_id === doc_part_id);
				if(dp){

					let dictionaryExceptions = dictionary.filter(x => x.type === "spellcheck" && x.active).map(x => { return x.word });
					let issueList = dp.issues.filter(function(x) { 
						return dictionaryExceptions.indexOf(x.word) < 0;
					});

					issueList.forEach(i => { 
						i.variations.forEach((v) => {
							let reg =  new RegExp(this.escapeRegExpString(i.word), 'gim');
							let result = text.match(reg);
							if(result){
								if(result.length === 1){
									text = text.replace(new RegExp(reg, 'gim'), `<mark class='nlpData' data-correction-type='spellcheck' data-language-reference='${tmpl_part_id}_${doc_part_id}_${v.idx}'>${result[0]}</mark>`)
								} else {
									let contextRegex = new RegExp(`(?:${this.escapeRegExpString(i.word)})`, 'gim');
									var matched = false;
									var matchResult;
									while ((matchResult = contextRegex.exec(text)) && !matched) {
										let test = text.substr(0,matchResult.index).replace(/<\/?[^>]+(>|$)/g, "").length === v.offset;
										if(test){
											matched = true;
											text = this.replaceAt(text, matchResult[0], matchResult.index, v, v.idx,tmpl_part_id, doc_part_id);
										}
									}
								}
							}

						})
					});	
				}
			}
		
		}

		return text;
	},
	applySpellCheckHighlight(text, data){
		if(data !== null){
			data.forEach(s => { 
				let reg =  new RegExp(s.word, 'gm');
				let result = text.match(reg);
				if (result){
					text = text.replace(new RegExp(reg, 'gm'), `<mark class='nlpData'>${result[0]}</mark>`)
				}
			});			
		}

		return text;
	},

	applyGrammarCheckHighlight(text) {
		return `<mark class="nlpDataGrammar">${text}</mark>`;
	},
	
	translateOptions: [
		{ text: "Afrikaans", value: "af", flagCode: "na" },
		{ text: "Albanian", value: "sq", flagCode: "al" },
		{ text: "Arabic", value: "ar", flagCode: "eg" },
		{ text: "Chinese (Simplified)", value: "zh", flagCode: "cn" },
		{ text: "Chinese (Traditional)", value: "zh-TW", flagCode: "cn" },
		{ text: "Croatian", value: "hr", flagCode: "hr" },
		{ text: "Czech", value: "cs", flagCode: "cz" },
		{ text: "Danish", value: "da", flagCode: "dk" },
		{ text: "Dutch", value: "nl", flagCode: "nl" },
		{ text: "English", value: "en", flagCode: "gb" },
		{ text: "French", value: "fr", flagCode: "fr" },
		{ text: "German", value: "de", flagCode: "de" },
		{ text: "Greek", value: "el", flagCode: "gr" },
		{ text: "Hindi", value: "hi", flagCode: "in" },
		{ text: "Hungarian", value: "hu", flagCode: "hu" },
		{ text: "Indonesian", value: "id", flagCode: "id" },
		{ text: "Italian", value: "it", flagCode: "it" },
		{ text: "Japanese", value: "ja", flagCode: "jp" },
		{ text: "Korean", value: "ko", flagCode: "kr" },
		{ text: "Malay", value: "ms", flagCode: "my" },
		{ text: "Polish", value: "pl", flagCode: "pl" },
		{ text: "Portuguese (Brazil)", value: "pt", flagCode: "pt" },
		{ text: "Portuguese (Portugal)", value: "pt-PT", flagCode: "pt" },
		{ text: "Romanian", value: "ro", flagCode: "ro" },
		{ text: "Russian", value: "ru", flagCode: "ru" },
		{ text: "Spanish", value: "es", flagCode: "es" },
		{ text: "Spanish (Mexico)", value: "es-MX", flagCode: "mx" },
		{ text: "Swedish", value: "sv", flagCode: "se" },
		{ text: "Thai", value: "th", flagCode: "th" },
		{ text: "Turkish", value: "tr", flagCode: "tr" },
		{ text: "Vietnamese", value: "vi", flagCode: "vn" },
		{ text: "Welsh", value: "cy", flagCode: "gb-wls" },
	],
	getLanguageFromCode(code) {
		return this.translateOptions.find((x) => x.value === code)
			|| { text: "English", value: "en", flagCode: "gb" }
	},
	async fetchDocumentData(documentId, statuses, targetLang, selectedTranslateLang, viewAction, store){
		let response = {};
		await axios
			.post("document/getDetail", { reference: documentId, viewAction: viewAction })
			.then(async (resp) => {
			if (!resp.data.Data.documents || resp.data.Data.documents.length === 0) {
				response.Message = `Error loading document data: ${resp.data.Message}`;
				response.Status = "Error";
			} else if (resp.data.Status === "OK") {
				response.Status = "OK";
				if (targetLang && selectedTranslateLang !== "en") {
					response.documentLanguage = this.getLanguageFromCode(selectedTranslateLang);
				} else {
					response.documentLanguage = this.getLanguageFromCode(resp.data.Data.documents[0].language_code);
				}

				response.hierarchyTypes = resp.data.Data.hierarchyTypes;
				response.wizardSettings = resp.data.Data.wizardSettings;
				response.openTasks = resp.data.Data.openTasks;
				response.partsHistory = resp.data.Data.documents[0].DPHistory || [];
				if (store && resp.data.Data.documents && resp.data.Data.documents.length && (resp.data.Data.documents[0].language_code === "" || resp.data.Data.documents[0].language_code === "en"))
					await store.dispatch("wordChecks/getTemplateWordChecks", resp.data.Data.documents[0].tmpl_id)
						.then((resp) => {
							response.wordChecks = resp;
						});
				else
					response.wordChecks = [];

				// response.wordChecks = this.initialisePartChecks(
				// 	resp.data.Data.documents[0].wordChecks
				// );
				response.documentIssueDisplay = resp.data.Data.settings.find(
					(x) => x.setting === "document_issue_display"
				);

				if (response.documentIssueDisplay)
					response.documentIssueDisplay = response.documentIssueDisplay.value;

				response = {
					...response,
					...this.setupDocument(resp.data.Data.documents, response.wordChecks, statuses)
				}

				let translateSetting = resp.data.Data.settings.find(
					(x) => x.setting === "UseTranslation"
				);
				if (translateSetting) {
					response.useTranslation = translateSetting.value == "true" ? true : false;
				} else {
					response.useTranslation = false;
				}

				response.nlpFunctionExclusions = resp.data.Data.documents[0].nlpFunctionExclusions;
				response.uploadedDocLink = resp.data.Data.documents[0].uploadedDocLink;
				response.spell_check_language_code = resp.data.Data.documents[0].spell_check_language_code;
				response.exportSections = resp.data.Data.documents[0].exportSections;
				response.exportConfig = resp.data.Data.documents[0].exportConfig;
				response.doc_view_config = resp.data.Data.documents[0].doc_view_config;
				response.consolidatedDocumentGroup = resp.data.Data.documents[0].consolidatedDocumentGroup;

				if (resp.data.Data.documents[0].alternateLanguageDocs) {
					response.alternateLanguageDocuments = JSON.parse(
						JSON.stringify(
						resp.data.Data.documents[0].alternateLanguageDocs
						)
					);
				}
			}
		});
		return Promise.resolve(response);
	},
	resolveHeaderStyle(doc) {
		if (doc.lifecycle_status_colour) return doc.lifecycle_status_colour;
		const color = doc.doc_status_color || doc.colour;
		const status = doc.doc_status || doc.status;
		if (doc.doc_type_abbrev == "Ef") {
			switch (doc.file_data?.file_extension) {
			case "xlsx":
			case "xls":
				return "#3cc542ad";
			case "doc":
			case "docx":
				return "#327bb5c7";
			case "txt":
			case "log":
				return "#ff23138f";
			case "pdf":
				return "#ff23138f";
			default:
				return "#327bb5c7";
			}
		} else if (color) {
			return color;
		} else {
			switch (status) {
			case "APPROVED":
				return "green";
			case "PENDING_APPROVAL":
				return "red";
			case "DRAFT":
			case "PRE-DRAFT":
				return "orange";
			case "ARCHIVED":
			case "TEMPLATE":
				return "blue";
			default:
				return "gray";
			}
		}
	},
	resolveStatusChipText(doc) {
		// if (doc.lifecycle_status_name) return doc.lifecycle_status_name;
		if (doc.doc_type_abbrev == "Ef")
			return doc.file_data?.file_extension.toUpperCase() || "FILE";
		else return doc.doc_status_text;
	},
	taskListFields: [{
			text: "Due",
			value: "due_date",
			sort: (a, b) =>
				dayjs(a).isAfter(dayjs(b))
				? 1
				: dayjs(a).isBefore(dayjs(b))
				? -1
				: 0,
		},
		{ text: "Task Description", value: "task_type" },
		{ text: "Task Status", value: "task_status" },
		{ text: "Comment", value: "comment" },
		{ text: "Document Type", value: "doc_type" },
		{ text: "Job Title", value: "doc_name" },
		{ text: "System Number", value: "reference" },
		{ text: "Doc Status", value: "document_status" },
		{ text: "Assigned To", value: "user_name_assigned" },
		{ text: "Completed By", value: "user_name_completed" },
		{
		text: "Completed When",
		value: "completed_date",
		sort: (a, b) =>
			dayjs(a).isAfter(dayjs(b))
			? 1
			: dayjs(a).isBefore(dayjs(b))
			? -1
			: 0,
		},
	]
};
