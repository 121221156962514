<template>
  <div>
    <v-dialog v-model="dialog" persistent width="800">
      <v-card>
        <v-card-title class="px-8">
          <span class="headline">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <v-btn icon large class="btn-background" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pb-0">
          <Loading :isVisible="isLoading" />
          <v-container>
            <v-row dense v-if="currentNode.length">
              <v-col>
                <HierarchyTreeNode
                  :nodes="currentNode"
                  :viewLevel="viewLevel"
                ></HierarchyTreeNode>
              </v-col>
            </v-row>
            <v-row dense
              ><v-col> <v-divider></v-divider></v-col
            ></v-row>
            <v-row
              v-if="
                (currentNode.length || mode === 'add-root') &&
                ['edit', 'add', 'add-root', 'rename'].some((x) => x === mode)
              "
            >
              <v-col v-if="nodeDetails">
                <v-text-field
                  v-model="nodeDetails.new_name"
                  :label="nodeDetails.label"
                  dense
                  outlined
                  class="roundish"
                  :placeholder="mode === 'edit' ? 'UNCLASSIFIED' : ''"
                  :persistent-placeholder="mode === 'edit'"
                  clearable
                  :return-object="false"
                ></v-text-field> </v-col
              ><v-col cols="5"
                ><v-alert dense v-if="validationError" color="warning">{{
                  validationError
                }}</v-alert></v-col
              >
            </v-row>
            <v-row v-if="nodeDetails && showDescriptor">
              <v-col>
                <v-textarea
                  v-model="nodeDetails.new_description"
                  label="Description"
                  dense
                  outlined
                  class="roundish"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row v-if="mode === 'merge' && validationError">
              <v-col>
                <v-alert
                  border="top"
                  colored-border
                  type="error"
                  elevation="2"
                  class="pb-0 mb-0"
                >
                  A {{ nodeDetails.label }} cannot be merged into itself. Please
                  select a different {{ nodeDetails.label }} to merge with.
                </v-alert>
              </v-col>
            </v-row>

            <v-row v-if="mode === 'move' && validationError">
              <v-col>
                <v-alert
                  border="top"
                  colored-border
                  type="error"
                  elevation="2"
                  class="pb-0 mb-0"
                  >{{ validationError }}
                </v-alert>
              </v-col>
            </v-row>
            <v-row v-if="['move', 'merge', 'pick'].indexOf(mode) >= 0">
              <v-col v-if="mode === 'move'">
                <v-label dense outlined class="roundish">{{
                  pickText
                }}</v-label>
                or <v-btn small @click="setUpNew">Add New</v-btn></v-col
              >
              <v-col v-if="mode === 'merge'">
                <v-label dense outlined class="roundish"
                  >Pick the {{ pickText }} to merge with</v-label
                >
              </v-col>
              <v-col v-if="showPicker"
                ><v-text-field
                  dense
                  hide-details
                  outlined
                  clearable
                  v-model="searchText"
                  placeholder="type and hit enter to search..."
                  append-icon="mdi-text-search"
                  @keypress.enter="searchNodes"
                  @click:append="searchNodes"
                  @click:clear="clearSearchNodes"
                ></v-text-field
              ></v-col>
            </v-row>

            <v-row
              style="max-height: 500px; overflow: auto"
              ref="scrollPicker"
              v-if="
                showPicker &&
                (mode === 'pick' ||
                  ((mode === 'move' || mode === 'merge') && currentNode.length))
              "
            >
              <v-col ref="scrollInner">
                <HierarchyTreeNode
                  :nodes="allNodes"
                  :viewLevel="viewLevel"
                  @pickNode="pickNode"
                ></HierarchyTreeNode>
              </v-col>
            </v-row>

            <v-row v-if="mode === 'delete' || mode === 'deactivate'">
              <v-col>
                <v-alert
                  border="top"
                  colored-border
                  type="error"
                  elevation="2"
                  class="pb-0 mb-0"
                >
                  {{ alertMessage }}
                  <br />
                  <v-list style="max-width: 300px">
                    <v-list-item
                      v-for="node in affectedNodesSummary"
                      :key="'an' + node.description"
                    >
                      <v-list-item-content>
                        {{ node.description }}
                      </v-list-item-content>
                      <v-list-item-action>
                        {{ node.affectedCount }}
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                  <div v-if="!invalid" class="pb-4">
                    Are you sure you want to continue?
                  </div>
                </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions
          class="pb-6 px-8"
          v-if="currentNode.length || mode === 'add-root'"
        >
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="close"> Cancel </v-btn>
          <v-btn
            v-if="mode === 'delete'"
            color="error"
            outlined
            @click="save"
            :disabled="invalid || !!validationError"
          >
            Delete
          </v-btn>
          <v-btn
            v-else-if="mode === 'deactivate'"
            color="error"
            outlined
            @click="save"
            :disabled="invalid || !!validationError"
          >
            Make Inactive
          </v-btn>
          <v-btn
            v-else-if="mode === 'reactivate'"
            color="primary"
            outlined
            @click="save"
            :disabled="invalid || !!validationError"
          >
            Make Active
          </v-btn>
          <v-btn
            v-else
            color="primary"
            :disabled="!isDirty || invalid || !!validationError"
            @click="save"
          >
            Save
          </v-btn>
        </v-card-actions>

        <v-card-actions
          class="pb-6 px-8"
          v-else-if="mode === 'pick' && nodeDetails"
        >
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="close"> Cancel </v-btn>
          <v-btn
            color="primary"
            :disabled="!pickedNode || invalid || !!validationError"
            @click="save"
          >
            Pick {{ nodeDetails.label }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="newParentDialog.show" width="600">
      <v-card v-if="newParentDialog.show">
        <v-card-title
          >Add New
          {{ newParentDialog.levels[newParentDialog.levels.length - 1].name }}
          and Move
          {{
            this.action.actionDef.currentLevelName +
            (multiNodes?.length ? "s" : "")
          }}</v-card-title
        >
        <v-card-text>
          <v-row
            dense
            v-for="level in newParentDialog.levels"
            :key="'l' + level.index"
          >
            <v-combobox
              v-if="level.index < newParentDialog.levels.length"
              v-model="level.value"
              :items="level.values"
              item-value="hierarchy_node_id"
              item-text="name"
              return-object
              :label="level.name"
              outlined
              class="roundish"
              dense
              @change="newParentValueChanged(level.index)"
            ></v-combobox>
            <v-text-field
              class="roundish"
              :label="level.name"
              outlined
              dense
              hide-details
              v-else
              v-model="level.value"
            ></v-text-field>
          </v-row>
        </v-card-text>
        <v-card-actions
          ><v-alert
            dense
            v-if="newParentValidationError"
            border="top"
            colored-border
            type="error"
            elevation="2"
            >{{ newParentValidationError }}</v-alert
          ><v-spacer></v-spacer
          ><v-btn @click="newParentDialog.show = false">Cancel</v-btn
          ><v-btn :disabled="!!newParentValidationError" @click="save"
            >Save</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-dialog>
    <v-dialog v-model="changeReason.show" width="600">
      <v-card v-if="changeReason.show">
        <v-card-title>Reason for change?</v-card-title>
        <v-card-text>
          <v-row><v-col cols="6">
            <v-combobox
              v-model="changeReason.temp_value"
              :items="changeReason.values"
              item-value="hierarchy_node_id"
              item-text="name"
              hide-details
              label="Category"
              outlined
              class="roundish"
              dense
            ></v-combobox></v-col></v-row><v-row><v-col>
            <v-text-field
              class="roundish"
              label="Notes"
              outlined
              dense
              hide-details
              v-model="changeReason.temp_notes"
            ></v-text-field></v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          ><v-spacer></v-spacer
          ><v-btn @click="changeReason.show = false">Cancel</v-btn
          ><v-btn :disabled="!changeReason.temp_value" @click="saveChangeReason"
            >Save</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="showErrorSnack"
      :timeout="snackTimeout"
      :color="snackColor"
      :multi-line="errorText.length > 50"
      top
    >
      {{ errorText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import HierarchyTreeNode from "@/components/hierarchy/cHierarchyTreeNode";
import utils from "../../common/utils";

export default {
  name: "cAdminHierarchyNodeDetail",
  components: {
    HierarchyTreeNode,
  },
  props: {
    show: Boolean,
    node: Object,
    action: Object,
    mode: { type: String, default: "edit" },
    showAddNodeDescriptor: Boolean,
    multiNodes: Array,
  },
  data: function () {
    return {
      spellCheckLanguages: ["us"],
      errorText: "",
      showErrorSnack: false,
      isLoading: false,
      snackColor: "error",
      snackTimeout: 4000,
      editedIds: [],
      editedName: "",
      nodeDetails: null,
      possibleParents: [],
      dialog: false,
      newParentDialog: { show: false, levels: [] },
      changeReason: { required: false, show: false, value: "", notes: "", temp_value: "", temp_notes: "", values: [] },
      viewLevel: 999,
      allNodes: [],
      currentNode: [],
      affectedNodesSummary: [],
      pickedNode: null,
      pickText: null,
      searchText: "",
      showDescriptor: false,
      showPicker: false,
      moveData: null,
      invalid: false,
      alertMessage: false,
    };
  },
  watch: {
    show(val) {
      this.dialog = val;
      if (val) this.loadDetails();
    },
  },
  computed: {
    ...mapState({
      hierarchiesLoading: (state) => state.hierarchies.loading,
      hierarchies: (state) => state.hierarchies.hierarchies,
    }),
    formTitle() {
      if (!this.nodeDetails) return "Edit Hierarchy";
      switch (this.mode) {
        case "merge":
          return `Merge ${this.nodeDetails.label}:`;
        case "add":
        case "add-root":
          return `Add new ${this.nodeDetails.label}`;
        case "rename":
          return `Rename ${this.nodeDetails.label}:`;
        case "move":
          return this.moveData?.title;
        case "editDescriptor":
          return `Edit ${this.nodeDetails.label} Description:`;
        case "pick":
          return `${this.nodeDetails.label} Selector:`;
        case "delete":
          return `Delete ${this.nodeDetails.label}:`;
        case "deactivate":
          return `Make ${this.nodeDetails.label} Inactive:`;
        case "reactivate":
          return `Make ${this.nodeDetails.label} Active:`;
        case "edit":
        default:
          return `Edit ${this.nodeDetails.label}`;
      }
    },
    hierarchyType() {
      let nod = this.node || this.multiNodes[0];
      if (!nod) return null;
      return this.hierarchies.find((ht) => ht.ht_id === nod.ht_id);
    },
    isDirty() {
      if (!this.nodeDetails) return false;
      if (this.mode === "move" || this.mode === "merge") return !this.invalid;

      return (
        this.nodeDetails.name !== this.nodeDetails.new_name ||
        (this.nodeDetails.description || "") !==
          (this.nodeDetails.new_description || "") ||
        this.nodeDetails.hierarchy_node_id !==
          this.nodeDetails.new_hierarchy_node_id // ||
        // (this.nodeDetails.parent &&
        //   this.nodeDetails.hierarchy_node_id_parent !==
        //     this.nodeDetails.parent.hierarchy_node_id)
      );
    },
    validationError() {
      let node;
      let checkNodes;
      if (!this.nodeDetails) return false;
      switch (this.mode) {
        case "add":
          node = this.findNode(
            this.allNodes,
            this.nodeDetails.hierarchy_node_id
          );
          if (
            this.nodeDetails.new_name &&
            this.checkNameExists(node.nodes, this.nodeDetails.new_name)
          ) {
            return "This name already exists.";
          }
          return false;
        case "add-root":
          if (
            this.nodeDetails.new_name &&
            this.checkNameExists(this.allNodes, this.nodeDetails.new_name)
          ) {
            return "This name already exists.";
          }
          return false;
        case "delete":
        case "deactivate":
        case "reactivate":
          break;
        case "move":
          if (this.invalid) {
            if (this.moveData.currentLevel === this.moveData.newLevel) {
              return `A ${this.nodeDetails.label} cannot be moved into itself. Please
                  select a different ${this.moveData.parentName} to move to.`;
            } else if (this.moveData.currentLevel < this.moveData.newLevel) {
              return `A ${this.nodeDetails.label} cannot be moved into itself. Please
                  select a different ${this.moveData.parentName} for the new ${this.moveData.newLevelName}.`;
            }
          }
          return this.invalid ? "duplicate" : "";
        case "merge":
          return (
            this.node.hierarchy_node_id === this.pickedNode.hierarchy_node_id
          );
        case "rename":
          if (!this.nodeDetails.new_name) return "Name required";
          node = this.findNode(
            this.allNodes,
            this.nodeDetails.hierarchy_node_id
          );
          if (node.hierarchy_node_id_parent)
            checkNodes = this.findNode(
              this.allNodes,
              node.hierarchy_node_id_parent
            ).nodes;
          else checkNodes = this.allNodes;
          if (this.checkNameExists(checkNodes, this.nodeDetails.new_name)) {
            return "This name already exists.";
          }
          break;
      }
      return "";
    },
    newParentValidationError() {
      let error = "";
      this.newParentDialog.levels.some((l) => {
        if (!l.value) {
          error = `${l.name} required`;
          return true;
        } else if (!l.value?.hierarchy_node_id) {
          if (this.checkNameExists(l.values, l.value)) {
            error = `${l.name} already exists`;
            return true;
          }
        }
      });
      return error;
    },
  },
  created: function () {
    if (this.show) this.loadDetails();
  },
  updated() {
    this.$nextTick(function () {
      if (this.setScroll) {
        this.setPickerScroll();
      }
    });
  },
  methods: {
    loadDetails() {
      this.currentNode.splice(0, this.currentNode.length);
      let nod = this.node || this.multiNodes[0];
      if (nod.ht_id) {
        let possibleError = false;
        this.isLoading = true;
        this.invalid = false;
        axios
          .post(`hierarchy/HierarchyNodeStructure`, {
            ht_id: nod.ht_id,
            ignoreInactive: this.mode !== "reactivate",
          })
          .then((resp) => {
            possibleError = true;
            const data = resp.data.Data;
			this.changeReason.values = data.change_reasons;
			this.changeReason.required = data.change_reason_required;
            this.viewLevel = nod.level;
            this.allNodes = this.setUpTree(data.nodes);
            let nodeDetails = JSON.parse(
              JSON.stringify(
                this.findNode(this.allNodes, nod.hierarchy_node_id)
              )
            );
            if (!nodeDetails)
              nodeDetails = JSON.parse(JSON.stringify(this.node)); // for new root node
            nodeDetails.new_hierarchy_node_id = nodeDetails.hierarchy_node_id;
            if (!nodeDetails.label && nodeDetails.level_name)
              nodeDetails.label = nodeDetails.level_name;
            if (this.mode === "add") {
              nodeDetails.new_name = "";
              nodeDetails.new_description = "";
              nodeDetails.label =
                nodeDetails.nextLevel ||
                nodeDetails.levels[nodeDetails.level]?.name;
              this.showDescriptor = this.showAddNodeDescriptor;
            } else {
              nodeDetails.new_name = nodeDetails.name;
              this.showDescriptor =
                this.mode === "editDescriptor" && nodeDetails.descriptor;
              nodeDetails.new_description = nodeDetails.descriptor
                ? nodeDetails.descriptor.description
                : null;
            }
            this.nodeDetails = nodeDetails;

            if (this.mode === "deactivate" || this.mode === "delete")
              this.affectedNodesSummary = this.buildAffectedNodesSummary(
                data.nodes,
                nodeDetails.hierarchy_node_id
              );

            this.dialog = true;
            this.isLoading = false;
          })
          .catch((err) => {
            if (possibleError) {
              alert("Code Error");
            } else if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              alert(err.response ? err.response.data.message : err);
            }
            console.log(err);
            this.isLoading = false;
            this.dialog = false;
          });
      } else {
        this.nodeDetails = null;
        return;
      }
    },
    setUpMove(nodes) {
      if (this.mode === "move") {
        this.invalid = false;
        if (!this.action?.actionDef) return;
        let parent =
          this.action.actionDef.newLevel > 1
            ? this.hierarchyType[
                `level${this.action.actionDef.newLevel - 1}_name`
              ]
            : null;
        let data = {
          title: `Move ${
            this.multiNodes?.length
              ? "multiple " + this.action.actionDef.currentLevelName + "s"
              : this.node.label
          }:`,
          pickText: "",
          pickLevel: this.action.actionDef.newLevel - 1,
          newLevel: this.action.actionDef.newLevel,
          viewLevel: this.action.actionDef.newLevel,
          currentLevel: this.action.actionDef.currentLevel,
          newLevelName: this.action.actionDef.newLevelName,
          currentLevelName: this.action.actionDef.currentLevelName,
          parentName: parent,
        };
        if (
          this.action.actionDef.currentLevel === this.action.actionDef.newLevel
        ) {
          if (this.node)
            data.title = `Move ${this.action.actionDef.currentLevelName} to another ${parent}`;
          else
            data.title = `Move multiple ${this.action.actionDef.currentLevelName}s to a ${parent}`;
          data.pickText = `Pick the ${parent} to move to`;
          this.invalid = true;
        } else if (
          this.action.actionDef.currentLevel > this.action.actionDef.newLevel
        ) {
          data.title = `Make ${this.action.actionDef.currentLevelName} into a new ${this.action.actionDef.newLevelName}`;
          data.pickText = parent
            ? `Pick the ${parent} to move to`
            : `Press Save to confirm creation of "${this.node.name}" as a new ${this.action.actionDef.newLevelName}`;
          if (!parent && this.checkNameExists(nodes, this.node.name)) {
            data.pickText = `A ${this.node.previousLevel} called ${this.node.name} already exists. Please rename before moving.`;
            this.invalid = true;
          }
        } else {
          data.title = `Make ${this.action.actionDef.currentLevelName} into a new ${this.action.actionDef.newLevelName}`;
          data.pickText = `Pick the ${parent} to move to`;
          this.invalid = true;
        }
        this.moveData = data;
      }
    },
    checkNameExists(nodes, name) {
      name = (name || "").toLowerCase().trim();
      return nodes.find((n) => (n.name || "").toLowerCase().trim() === name);
    },
    setUpTree(nodes) {
      let getColFactor = (level) => {
        switch (this.hierarchyType.linklevel - level + 1) {
          case 1:
            return 12;
          case 2:
            return 6;
          case 3:
            return 4;
          case 4:
            return 3;
          default:
            return 2;
        }
      };
      let nodeIds = this.node
        ? [this.node.hierarchy_node_id]
        : this.multiNodes.map((n) => n.hierarchy_node_id);
      let nodeLevel = this.viewLevel;
      let pickLevel =
        this.mode === "move"
          ? nodeLevel - 1
          : this.mode === "merge" || this.mode === "pick"
          ? nodeLevel
          : 0;
      this.pickedNode = null;
      this.pickText = pickLevel
        ? this.hierarchyType[`level${pickLevel}_name`]
        : "";
      if (this.mode === "move" && this.action?.actionDef) {
        this.setUpMove(nodes);
        pickLevel = this.moveData.pickLevel;
        this.pickText = this.moveData.pickText;
        this.viewLevel = this.moveData.viewLevel;
      }
      let processNodes = (nodes) => {
        let containsSelected = false;
        nodes.forEach((n) => {
          let colFactor = getColFactor(n.level);
          n.cols = colFactor;
          n.childCols = 12 - colFactor;
          n.pickable = n.level === pickLevel;
          n.picked = false;
          n.visible = true;
          n.filterParts = n.name
            .toLowerCase()
            .split(" ")
            .filter((x) => x);
          if (n.nodes) {
            n.expandable = true;
            n.expanded = processNodes(n.nodes);
            if (n.expanded) {
              n.selected = true;
              containsSelected = true;
              if (n.pickable && nodeLevel > pickLevel) {
                if (nodeIds.length === 1) {
                  n.picked = true;
                  this.pickedNode = n;
                }
                n.expanded = false;
              }
            }
          } else {
            n.expandable = false;
          }
          if (nodeIds.some((id) => n.hierarchy_node_id === id)) {
            containsSelected = true;
            n.selected = true;
            if (this.mode !== "move") this.viewLevel = n.level;
            if (nodeIds.length === 1 && n.pickable && nodeLevel === pickLevel) {
              n.picked = true;
              this.pickedNode = n;
            }
          }
        });
        return containsSelected;
      };

      if (processNodes(nodes)) {
        let getSels = (nodes) => {
          let sel = nodes.filter((x) => x.selected);
          if (sel.length) {
            let selNodes = sel.map((x) => {
              return {
                description: x.description,
                hasDescription: x.hasDescription,
                hierarchy_node_id: x.hierarchy_node_id,
                // hierarchy_node_id_parent: x.hierarchy_node_id_parent,
                hr_id: x.hr_id,
                ht_id: x.ht_id,
                level: x.level,
                level_name: x.level_name,
                name: x.name,
                visible: x.visible,
                expandable: false,
                expanded: true,
                selected: !x.nodes || !x.nodes.some((n) => n.selected),
                hasDocs: x.hasDocs,
              };
            });
            sel
              .filter((s, si) => s.nodes && !selNodes[si].selected)
              .forEach((sn, sni) => {
                selNodes[sni].nodes = getSels(sn.nodes);
              });
            return selNodes;
            // if (sel.length && sel[0].nodes && !selNode.selected)
            //   selNode.nodes = [getSels(sel[0].nodes)];
            // return selNode;
          }
        };
        this.currentNode = getSels(nodes);
      }
      if (pickLevel) {
        this.setScroll = true;
        let hasLevelChildren = (nodes, level) => {
          return nodes.filter((n) => {
            if (n.nodes?.length && n.level < level) {
              hasLevelChildren(n.nodes, level);
            }
            if (n.level < level && !n.nodes?.length) {
              return false;
            } else {
              return true;
            }
          });
        };
        nodes = hasLevelChildren(nodes, pickLevel);
      }
      this.showPicker = !!pickLevel;
      return nodes;
    },
    buildAffectedNodesSummary(nodes, node_id) {
      const summary = [];
      const getSumm = (nodes) => {
        if (nodes && nodes.length) {
          nodes.forEach((n) => {
            let sum = summary.find((x) => x.name === n.level_name);
            if (!sum) {
              sum = {
                name: n.level_name,
                description: utils.pluralise(n.level_name),
                affectedCount: 0,
              };
              summary.push(sum);
            }
            sum.affectedCount++;
            getSumm(n.nodes);
          });
        }
      };
      let node = this.findNode(nodes, node_id);
      if (node) {
        if (node.hasDocs) {
          this.alertMessage = `Node cannot be made inactive as it has ${
            node.hasDocs
          } linked document${node.hasDocs === 1 ? "" : "s"}`;
          this.invalid = true;
          return;
        } else {
          getSumm(node.nodes);
          this.invalid = false;
          this.alertMessage = summary.length
            ? "This action will also make the following entities inactive and is not reversible."
            : "This action will make the " +
              node.level_name +
              (this.mode === "delete" ? " deleted" : " inactive") +
              " and is not easily reversible";
        }
      }
      return summary;
    },
    findNode(nodes, node_id) {
      let n = nodes.find((x) => x.hierarchy_node_id === node_id);
      if (n) return n;
      let found = null;
      nodes.forEach((n) => {
        if (!found && n.nodes) {
          found = this.findNode(n.nodes, node_id);
        }
        if (found) return;
      });
      return found;
    },
    pickNode(node) {
      if (this.pickedNode) this.pickedNode.picked = false;
      node.picked = true;
      this.pickedNode = node;
      if (this.moveData) {
        if (this.moveData.currentLevel === this.moveData.newLevel) {
          this.invalid =
            this.nodeDetails.hierarchy_node_id_parent ===
            node.hierarchy_node_id;
        } else if (this.moveData.currentLevel < this.moveData.newLevel) {
          // make sure new parent tree does not contain current node
          let invalid =
            this.nodeDetails.hierarchy_node_id === node.hierarchy_node_id;
          let parent = node.hierarchy_node_id_parent;
          while (!invalid && parent) {
            invalid = this.nodeDetails.hierarchy_node_id === parent;
            if (!invalid) parent = this.findNode(this.allNodes, parent);
          }
          this.invalid = invalid;
        }
      }
    },
    setPickerScroll() {
      if (this.pickText && this.setScroll) {
        let rowsVisible = 0;
        let lineSelected = 0;
        let countVisible = (nodes) => {
          nodes.forEach((n) => {
            rowsVisible++;
            if (n.expanded && n.nodes) {
              countVisible(n.nodes);
            }
            if (n.picked) lineSelected = rowsVisible;
          });
        };
        countVisible(this.allNodes);
        this.$refs.scrollPicker.scrollTop =
          this.$refs.scrollPicker.scrollHeight *
          ((lineSelected - 1) / rowsVisible);
        this.setScroll = false;
      }
    },
    clearSearchNodes() {
      this.searchText = "";
      this.searchNodes();
    },
    searchNodes() {
      let searchText = this.searchText || "";
      if (!searchText) {
        let resetVisible = (nodes) => {
          nodes.forEach((n) => {
            n.visible = true;
            n.expanded = false;
            if (n.nodes) {
              resetVisible(n.nodes);
            }
          });
        };
        resetVisible(this.allNodes);
      }
      let text = searchText
        .toLowerCase()
        .split(" ")
        .filter((x) => x);
      if (searchText.trim().length < 4 && text.length < 2) return;
      let isVisible = (nodes) => {
        let visible = false;
        nodes.forEach((n) => {
          n.visible = text.every((t) =>
            n.filterParts.some((f) => f.indexOf(t) >= 0)
          );
          let hasChildVisible = n.nodes && isVisible(n.nodes);
          if (hasChildVisible) {
            n.visible = true;
            n.expanded = true;
          } else {
            n.expanded = false;
          }
          if (n.visible) visible = true;
        });
        return visible;
      };
      isVisible(this.allNodes);
    },
    triggerNotification(text, type) {
      this.errorText = text;
      this.snackColor = type;
      this.showErrorSnack = true;
    },
    close() {
      this.nodeDetails = null;
      this.dialog = false;
      this.$emit("close");
    },
    save() {
      if (!this.hierarchyType) return;
      if (!this.captureChangeReason()) return;
      let getParentNode = (nodes, node) => {
        let parent = nodes.find(
          (n) => n.hierarchy_node_id === node.hierarchy_node_id_parent
        );
        if (parent) {
          if (!node.parents) node.parents = [];
          node.parents.push({
            hierarchy_node_id: parent.hierarchy_node_id,
            level: parent.level,
            name: parent.name,
            ht_id: parent.ht_id,
          });
          return node;
        } else {
          return nodes
            .filter((n) => n.nodes)
            .some((n) => {
              if (getParentNode(n.nodes, node)) {
                node.parents.push({
                  hierarchy_node_id: n.hierarchy_node_id,
                  level: n.level,
                  name: n.name,
                  ht_id: n.ht_id,
                });
                return true;
              } else {
                return false;
              }
            });
        }
      };
      if (this.pickedNode) {
        getParentNode(this.allNodes, this.pickedNode);
      }

      let data = this.nodeDetails;
      data.reason_type = this.changeReason.value;
      data.reason_notes = this.changeReason.notes;
      let getLeafNode = (node) => {
        if (node.nodes && node.nodes.length) {
          let n = getLeafNode(node.nodes[0]);
          if (n) {
            if (!n.parents) n.parents = [];
            n.parents.push({
              hierarchy_node_id: node.hierarchy_node_id,
              level: node.level,
              name: node.name,
            });
          }
          return n;
        } else return node;
      };
      let url = "hierarchy/savenodedetails";

      if (this.mode === "move") {
        data.moved_nodes = this.multiNodes?.length
          ? this.multiNodes.map((n) => n.hierarchy_node_id)
          : [data.hierarchy_node_id];
        data.move_from_level = this.moveData.currentLevel;
        data.move_to_level = this.moveData.newLevel;
        if (this.newParentDialog.show) {
          data.new_nodes = this.newParentDialog.levels
            // .filter((l) => !l.value.hierarchy_node_id)
            .map((l) => {
              let parent = this.newParentDialog.levels.find(
                (pl) => pl.index === l.index - 1
              )?.value;
              return {
                new_name: typeof l.value === "string" ? l.value : "",
                name: typeof l.value === "string" ? l.value : l.value.name,
                new_description: null,
                level: l.index,
                hierarchy_node_id:
                  typeof l.value === "object"
                    ? l.value.hierarchy_node_id
                    : null,
                hierarchy_node_id_parent:
                  parent && typeof parent === "object"
                    ? parent.hierarchy_node_id
                    : null,
              };
            });
          data.move_to_node = data.new_nodes[data.new_nodes.length - 1];
          data.move_to_node.parents = [];
        } else {
          data.move_to_node = this.pickedNode;
        }
      } else if (this.mode === "merge") {
        data.merge_with_node = this.pickedNode;
        data.merged_hr_id = getLeafNode(this.currentNode[0]).hr_id;
      } else if (this.mode === "pick") {
        data = {
          node: this.pickedNode,
          hr_id: getLeafNode(this.pickedNode).hr_id,
        };
        this.$emit("picked", {
          original: this.nodeDetails,
          updated: data,
          action: this.mode,
        });
        this.nodeDetails = null;
        this.dialog = false;
        return;
      } else if (this.mode === "add") {
        data.add_to_node = getLeafNode(this.currentNode[0]);
        data.hierarchy_node_id_parent = null;
        data.level = data.add_to_node.level + 1;
      } else if (this.mode === "add-root") {
        data.hierarchy_node_id_parent = null;
        data.add_root_node = true;
      } else if (this.mode === "delete") {
        data.delete_node = true;
      } else if (this.mode === "deactivate") {
        data.deactivate_node = true;
      } else if (this.mode === "reactivate") {
        data.reactivate_node = true;
      } else if (this.mode === "editDescriptor") {
        url = "hierarchy/saveNodeDescription";
        data = {
          hierarchy_node_id: this.nodeDetails.hierarchy_node_id,
          ht_id: this.nodeDetails.ht_id,
          description: this.nodeDetails.new_description,
        };
      }
      this.isLoading = true;

      axios
        .post(url, data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            this.newParentDialog.show = false;
            this.dialog = false;
            this.$store.dispatch("hierarchies/refresh");
            if (["merge", "rename", "move"].some((x) => x === this.mode))
              this.$store.dispatch("docs/refresh");
            this.$emit("saved", {
              original: this.nodeDetails,
              updated: { ...data, ...resp.data.Data },
              action: this.mode,
              newNode: ["add", "add-root"].some((x) => x === this.mode)
                ? resp.data.Data
                : null,
            });
            this.nodeDetails = null;
            this.triggerNotification("Hierarchy Saved", "success");
          } else {
            this.triggerNotification(resp.data.Message, "error");
          }
          this.response = resp.data;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
          this.dialog = false;
        });
    },
    setUpNew() {
      this.newParentDialog.levels = this.hierarchyType.levels
        .filter((l) => l.index <= this.moveData.newLevel - 1)
        .map((l) => {
          return {
            name: l.name,
            index: l.index,
            value: null,
            values: [],
          };
        });
      this.newParentDialog.levels[0].values = this.getLevelValues(1);
      this.newParentDialog.show = true;
    },
    newParentValueChanged(level) {
      this.newParentDialog.levels
        .filter((l) => l.index > level)
        .forEach((l) => {
          l.values = this.getLevelValues(l.index);
          l.value = null;
        });
    },
    getLevelValues(level) {
      let values = this.allNodes;
      let levelValues = null;
      this.newParentDialog.levels
        .filter((x) => x.index <= level)
        .forEach((l) => {
          if (l.index === level) {
            levelValues = values.map((n) => {
              return {
                hierarchy_node_id: n.hierarchy_node_id,
                name: n.name,
              };
            });
          } else if (l.value?.hierarchy_node_id) {
            let selValue = values.find(
              (v) => v.hierarchy_node_id === l.value.hierarchy_node_id
            );
            values = selValue.nodes;
          } else {
            values = [];
          }
        });
      return levelValues;
    },
    captureChangeReason() {
      if (this.changeReason.required) {
        if (this.changeReason.value && this.changeReason.show) {
          return true;
        }
        this.changeReason.show = true;
        return false;
      } else {
        return true;
      }
    },
	saveChangeReason() {
		this.changeReason.value = this.changeReason.temp_value;
		this.changeReason.notes = this.changeReason.temp_notes;
		this.changeReason.temp_value = null;
		this.changeReason.temp_notes = null;
		this.save();
        this.changeReason.show = false;
	}
  },
};
</script>

<style scoped lang="scss">
.nodeContainer {
  width: 50%;
  height: 24px;
  padding-left: 6px;
}
.nodeSeleted {
  background-color: rgb(205, 243, 205);
}
.nodeInfoIcon {
  float: right;
  padding-top: 2px;
}
.nodeInner {
  display: block;
  height: 24px;
  font-family: "Martel Sans", sans-serif;
  font-size: 12px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 90%;
  float: left;
}
.node {
  border: solid 1px #004d40;
  border-radius: 5px;
  display: block;
  height: 24px;
  font-family: "Martel Sans", sans-serif;
  font-size: 12px;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  float: right;
}
.nodePickable {
  width: 80%;
}
.nodeNonPickable {
  width: 90%;
}
.nodeExpandable {
  cursor: pointer;
}
.pickable {
  width: 10%;
  float: right;
  cursor: grab;
}
</style>