<template>
  <v-row class="justify-center mt-6 mb-6 link-container">
    <v-card
      class="help-card steps ma-3 pa-6"
      width="100%"
    >
      <v-card-text>
        <draggable
          class="row"
          v-model="links"
          group="landinglinks"
          :disabled="!editMode"
        >
            <v-col
              v-for="(s, si) in links" :key="'step' + si"
              :class="{ 'd-flex justify-space-around link-container': true, draggable: editMode }"
            >
              <div class="d-flex">
                <div v-if="editMode" class="edit-btn">
                  <v-btn icon small title="Delete" @click="deleteTile($event, s)"
                    ><v-icon>delete</v-icon></v-btn
                  >
                  <v-btn icon small title="Copy" @click="copyTile($event, s)"
                    ><v-icon>content_copy</v-icon></v-btn
                  >
                  <v-btn icon small title="Settings" @click="editTile($event, s)"
                    ><v-icon>settings</v-icon></v-btn
                  >
                </div>
                <v-avatar :color="s.icon_colour" size="120">
                  <div>
                    STEP<br />
                    <span class="headline">0{{ si + 1 }}</span>
                  </div>
                </v-avatar>
                <div class="px-3 py-6">
                  <h2 class="px-3">{{ s.title }}</h2>
                  <v-btn
                    color="primary"
                    :to="s.isExternal ? '' : s.url"
                    :href="s.isExternal ? s.url : ''"
                    :target="s.open_newtab ? '_blank' : ''"
                    text
                    large
                    class="px-3"
                    >{{ s.call_to_action }}
                    <v-icon right>mdi-arrow-right</v-icon></v-btn
                  >
                </div>
              </div>
              <div
                v-if="si < links.length - 1"
              >
                <span
                  :class="['chevron right', 'chevron-' + s.icon_colour]"
                ></span>
              </div>
            </v-col>
        </draggable>
      </v-card-text>
    </v-card>
    <v-btn
      v-if="editMode"
      class="add-btn"
      large
      icon
      title="Add Tile"
      @click="addTile($event)"
      ><v-icon>add_circle_outline</v-icon></v-btn
    >
  </v-row>
</template>

<script>
import draggable from "vuedraggable";
import landingUtils from "./landingUtils.js";

export default {
  name: "landingStepper",
  components: {
    draggable,
  },
  data: function () {
    return {
      links: []
    };
  },
  props: {
    definition: { type: Object },
    qaActions: { type: Array },
    editMode: { type: Boolean },
  },
  mounted() {},
  watch: {
    definition: {
      deep: true,
      handler(val) {
        this.links = val.links;
      }
    },
    links(val) {
      const definition = this.definition;
      definition.links = val;
      this.$emit("updated:definition", definition);
    }
  },
  computed: {},
  created() {
    this.links = this.definition.links;
  },
  methods: {
    linkClick(ev, link) {
      landingUtils.handleLandingLinkClick(this, ev, link);
    },
    editTile(ev, t) {
      ev.preventDefault();
      this.$emit("editTile", { row: this.definition, link: t });
    },
    addTile(ev) {
      ev.preventDefault();
      this.$emit("addTile", { row: this.definition });
    },
    copyTile(ev, t) {
      ev.preventDefault();
      this.$emit("copyTile", { row: this.definition, link: t });
    },
    deleteTile(ev, t) {
      ev.preventDefault();
      this.$emit("deleteTile", { row: this.definition, link: t });
    },
  },
};
</script>

<style scoped lang="scss">
.link-container {
  position: relative;
}

.edit-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.steps .edit-btn {
  top: -20px;
  right: -20px;
}

.add-btn {
  position: absolute;
  bottom: 0;
  right: -35px;
  z-index: 1;
}

.draggable {
  cursor: move;
}

.help-card {
  border-radius: 10px !important;
  min-width: 200px;
  .titlerow {
    min-height: 100px !important;
    .col {
      display: flex;
      align-items: center;
    }
    h4 {
      font-size: 14px;
      line-height: normal;
      white-space: pre-wrap;
      word-break: normal;
    }
  }
  .v-icon {
    opacity: 0.8;
  }
}

.v-avatar {
  .headline {
    font-weight: bold;
  }
  &.red {
    color: #bf012f;
    background-color: rgba(#bf012f, 0.2) !important;
  }
  &.purple {
    color: #6938b9;
    background-color: rgba(#6938b9, 0.2) !important;
  }
  &.orange {
    color: #cf8a38;
    background-color: rgba(#cf8a38, 0.2) !important;
  }
  &.blue {
    color: blue;
    background-color: rgba(blue, 0.2) !important;
  }
  &.green {
    color: green;
    background-color: rgba(green, 0.2) !important;
  }
  &.gray {
    color: gray;
    background-color: rgba(gray, 0.2) !important;
  }
}

.chevron.right {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 65px;
  height: 120px;
  border: 1px solid transparent;
  border-radius: 100px;
}
.chevron.right::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 60px;
  height: 60px;
  border-bottom: 1px solid;
  border-right: 1px solid;
  transform: rotate(-45deg);
  right: 20px;
  top: 30px;
}
.chevron.right.chevron-red::after {
  border-color: rgba(#bf012f, 0.5);
}
.chevron.right.chevron-purple::after {
  border-color: rgba(#6938b9, 0.5);
}
.chevron.right.chevron-orange::after {
  border-color: rgba(#cf8a38, 0.5);
}
.chevron.right.chevron-blue::after {
  border-color: rgba(blue, 0.5);
}
.chevron.right.chevron-green::after {
  border-color: rgba(green, 0.5);
}
.chevron.right.chevron-gray::after {
  border-color: rgba(gray, 0.5);
}
</style>