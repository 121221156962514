<template>
  <v-expansion-panels accordion v-model="ovrPanel" class="attributes-list py-2">
    <Draggable
      v-model="templatePart.overrides"
      draghandle=".draghandle"
      @start="ovrPanel = null"
      >
      <v-expansion-panel v-for="(ovr, ai) in templatePart.overrides" :key="'o' + ai" class="elevation-0" :style="`width: ${width}`">
        <v-expansion-panel-header :class="{ 'attribute': true }">
          <v-row>
            <v-col cols="1" class="d-flex align-center">
              <v-icon class="draghandle">mdi-drag</v-icon>
            </v-col>
            <v-col cols="3">
              <v-list-item-content>
                <v-list-item-title>{{ ovr.override_type ? ovr.override_type.text : '' }}</v-list-item-title>
                <v-list-item-subtitle>{{ ovr.criteria.length === 0 ? 'No Criteria' : `${ovr.criteria.length} Criteria` }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-col>
            <v-col class="d-flex align-center">
              {{ ovr.comments }}
            </v-col>
            <v-col cols="1" class="d-flex align-center">
              <v-btn icon @click="$emit('deleteOverride', { templatePart: templatePart, override: ovr })"><v-icon>mdi-delete</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="4">
              <v-select
                label="Type"
                v-model="ovr.override_type"
                :items="overrideTypes"
                item-text="text"
                item-value="value"
                return-object
                :hint="ovr.override_type.desc"
                persistent-hint>
              </v-select>
            </v-col>
            <v-col>
              <v-text-field
                label="Comments"
                v-model="ovr.comments">
              </v-text-field>
            </v-col>
            <v-col cols="3">
              <v-switch
                label="Apply on Insert"
                v-model="ovr.apply_on_insert">
              </v-switch>
              <v-switch
                label="Apply on Update"
                v-model="ovr.apply_on_update">
              </v-switch>
            </v-col>
          </v-row>
          <v-textarea
            v-if="ovr.override_type && ovr.override_type.value === 'default_text'"
            label="Default Text"
            v-model="ovr.default_text"
            auto-grow
            rows="2">
          </v-textarea>
          <v-row v-if="ovr.override_type && ovr.override_type.value === 'hrcode_lookup'">
            <v-col>
              <v-select
                label="Lookup Type"
                v-model="ovr.lookup_type"
                :items="lookupTypes"
                item-text="name"
                item-value="lookup_type_id">
              </v-select>
            </v-col>
            <v-col>
              <v-select
                label="Value"
                v-model="ovr.lookup_val"
                :items="['value1','value2','value3']">
              </v-select>
            </v-col>
          </v-row>
          <h4>Criteria <v-btn icon @click="$emit('addCriteria', ovr)"><v-icon>mdi-plus-circle</v-icon></v-btn></h4>
          <v-list>
            <v-list-item v-for="(crt, crti) in ovr.criteria" :key="crti">
              <v-row class="align-center">
                <v-col class="d-flex align-center">
                  <v-select
                    v-if="!crt.tpoc_id && !crt.tpocl_id"
                    label="Type"
                    :items="['hierarchy','classifier']"
                    v-model="crt.type">
                  </v-select>
                  <div v-else>
                    <span class="text-caption">Type</span>
                    <p>{{ crt.type }}</p>
                  </div>
                </v-col>
                <template v-if="crt.type === 'hierarchy'">
                  <v-col>
                    <v-select
                      label="Hierarchy Type"
                      v-model="crt.ht_id"
                      :items="hierarchies"
                      item-text="label"
                      item-value="ht_id"
                      :error="!crt.ht_id">
                    </v-select>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="Level1"
                      v-model="crt.h_level1"
                      placeholder="<Any>">
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="Level2"
                      v-model="crt.h_level2"
                      placeholder="<Any>">
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="Level3"
                      v-model="crt.h_level3"
                      placeholder="<Any>">
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="Level4"
                      v-model="crt.h_level4"
                      placeholder="<Any>">
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="Level5"
                      v-model="crt.h_level5"
                      placeholder="<Any>">
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="Level6"
                      v-model="crt.h_level6"
                      placeholder="<Any>">
                    </v-text-field>
                  </v-col>
                </template>
                <template v-else-if="crt.type === 'classifier'">
                  <v-col>
                    <v-select
                      label="Classifier Type"
                      v-model="crt.ct_id"
                      :items="docClassifiers"
                      item-value="ct_id"
                      item-text="name"
                      :error="!crt.ct_id">
                    </v-select>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="Classifer Value"
                      v-model="crt.cl_value"
                      placeholder="<Any>">
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="Classifier Category"
                      v-model="crt.cl_category"
                      placeholder="<Any>">
                    </v-text-field>
                  </v-col>
                </template>
                <v-btn icon @click="$emit('removeCriteria', { override: ovr, criteria: crt })"><v-icon>mdi-delete</v-icon></v-btn>
              </v-row>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </Draggable>
  </v-expansion-panels>
</template>

<script>
import { mapState } from "vuex";
import Draggable from "vuedraggable";

export default {
  name: "TemplateBuilderStructureOverrides",
  components: {
    Draggable,
  },
  props: {
    model: { type: Object, required: true },
    overrideTypes: { type: Array, required: true },
    lookupTypes: { type: Array, required: true },
    width: { type: String, required: false, default: "1150px"}
  },
  data: function () {
    return {
      templatePart: this.model,
      ovrPanel: null
    };
  },
  mounted() {},
  created() {
  },
  watch: {
    model: {
      deep: true,
      handler(val) {
        this.templatePart = val;
      }
    }
  },
  computed: {
    ...mapState({
      hierarchies: (state) => state.hierarchies.hierarchies,
      docClassifiers: (state) => state.hierarchies.docClassifiers
    }),
  },
  methods: {
  }
};
</script>

<style scoped lang="scss">

.draghandle {
  cursor: move;
}
</style>