var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{staticClass:"sectionTitle"},[_c('v-col',{staticClass:"d-flex align-center pt-0",attrs:{"cols":"8"}},[_c('h2',[_vm._v(_vm._s(_vm.section.label || _vm.section.section))])]),_c('v-col',{staticClass:"d-flex justify-end pt-0",attrs:{"cols":"4"}},[_c('v-btn',{staticClass:"btn-background",attrs:{"icon":"","title":"Download List"},on:{"click":_vm.exportToCSV}},[_c('v-icon',[_vm._v("mdi-download")])],1),_c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-background ml-2",attrs:{"icon":"","color":"grey lighten-1"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-cog ")])],1)]}}]),model:{value:(_vm.editSettings),callback:function ($$v) {_vm.editSettings=$$v},expression:"editSettings"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Panel Settings")]),_c('v-spacer'),_c('v-btn',{staticClass:"btn-background",attrs:{"icon":""},on:{"click":function($event){_vm.editSettings = false}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1),_c('v-card-text',[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_vm._v("Show/Hide Metrics")])],1),_vm._l((_vm.settings.measures),function(m,mi){return _c('v-row',{key:'m' + mi,attrs:{"dense":""}},[_c('v-col',[_c('v-switch',{attrs:{"dense":"","inset":"","value":m.name,"label":m.name},model:{value:(_vm.activeMeasures),callback:function ($$v) {_vm.activeMeasures=$$v},expression:"activeMeasures"}})],1)],1)})],2)],1)],1)],1)],1)],1),(_vm.settings.headerPosition !== 'bottom')?_c('v-row',{ref:"header",style:({ fontWeight: 'bold', fontSize: _vm.settings.fontSize || '12px' }),attrs:{"no-gutters":"","dense":""}},[(_vm.settings.titleColWidth !== 0)?_c('v-col',{attrs:{"cols":_vm.settings.titleColWidth || '2'}},[_c('h2',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.setSort(false)}}},[(
            _vm.settings.sortBy &&
            _vm.settings.sortBy.column === 'key' &&
            !_vm.settings.sortBy.desc
          )?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-sort-ascending")]):_vm._e(),(
            _vm.settings.sortBy &&
            _vm.settings.sortBy.column === 'key' &&
            _vm.settings.sortBy.desc
          )?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-sort-descending")]):_vm._e()],1)]):_vm._e(),_vm._l((_vm.getMeasures()),function(m,mi){return _c('v-col',{key:'mh' + mi,staticStyle:{"text-align":"center","cursor":"pointer"},on:{"click":function($event){return _vm.setSort(m)}}},[_vm._v(" "+_vm._s(m.name)+" "),(m.sort === 'asc')?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-sort-ascending")]):_vm._e(),(m.sort === 'desc')?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-sort-descending")]):_vm._e()],1)})],2):_vm._e(),(_vm.section.view.allowDrilldown)?_c('div',_vm._l((_vm.section.view.filterCriteria),function(fc,fci){return _c('div',{key:'fc' + fci},_vm._l((fc.levels),function(l,li){return _c('v-row',{key:'fc' + fci + 'l' + li,attrs:{"no-gutters":""}},[_c('v-col',{style:({ paddingLeft: li * 10 + 'px' })},[(li > 0)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-subdirectory-arrow-right")]):_vm._e(),_vm._v(" "+_vm._s(l)+" "),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.removeFilter(li)}}},[_vm._v("mdi-filter-remove")])],1)],1)}),1)}),0):_vm._e(),_c('perfect-scrollbar',{style:({ height: _vm.rowsHeight, padding: '4px', fontSize: _vm.settings.fontSize || '12px' })},_vm._l((_vm.section.view.data.filter(
        (d) =>
          !_vm.section.viewFilter ||
          !_vm.section.view.filterCriteria ||
          d.key === _vm.section.viewFilter
      )),function(d,di){return _c('v-row',{key:'d' + di,style:({ cursor: !!_vm.section.view.scoreBandGroupBy ? '' : 'pointer' }),attrs:{"no-gutters":""},on:{"click":function($event){return _vm.rowSelected(d.key, !!_vm.section.view.scoreBandGroupBy)},"mouseenter":function($event){_vm.hoverIdx = di},"mouseleave":function($event){_vm.hoverIdx = null}}},[(!_vm.section.view.scoreBandGroupBy)?_c('v-col',[_c('v-row',{class:{'my-1': _vm.settings.scoreStyle === 'chip'},attrs:{"no-gutters":""}},[(_vm.settings.titleColWidth !== 0)?_c('v-col',{style:({
              paddingLeft:
                _vm.section.view.filterCriteria.length &&
                _vm.section.view.filterCriteria[0].levels
                  ? _vm.section.view.filterCriteria[0].levels.length * 10 + 'px'
                  : '0px',
            }),attrs:{"cols":_vm.settings.titleColWidth || '2'}},[_vm._v(_vm._s(d.key)),(
                _vm.section.view.filterCriteria.length &&
                !_vm.section.view.allowDrilldown
              )?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.removeFilter(0)}}},[_vm._v("mdi-filter-remove")]):_vm._e()],1):_vm._e(),_vm._l((_vm.getMeasures()),function(m,mi){return _c('v-col',{key:'m' + mi,staticStyle:{"text-align":"center"}},[(m.isPercent)?_c('div',{staticClass:"d-flex justify-center"},[(
                  _vm.settings.scoreStyle === 'circular' ||
                  _vm.settings.scoreStyle === 'circular-large' ||
                  _vm.settings.scoreStyle === 'circular-xlarge'
                )?_c('v-progress-circular',{staticClass:"mr-1 mb-1 mt-1",attrs:{"rotate":90,"size":_vm.settings.scoreStyle === 'circular' ? 45 : _vm.settings.scoreStyle === 'circular-xlarge' ? 120 : 70,"width":_vm.settings.scoreStyle === 'circular' ? 5 : _vm.settings.scoreStyle === 'circular-xlarge' ? 10 : 8,"value":d[m.measureColumn],"color":d[m.measureColumn + 'Colour']}},[_c('span',{style:({ 'fontSize': _vm.settings.scoreStyle === 'circular-xlarge' ? '20px' : '13px',
                  'fontWeight': _vm.settings.scoreStyle === 'circular-xlarge' ? 'bold' : 'normal',
                  'color': 'gray'})},[_vm._v(" "+_vm._s(d[m.measureColumn]))])]):(_vm.settings.scoreStyle === 'chip')?_c('v-chip',{staticClass:"d-flex justify-center",staticStyle:{"width":"56px"},attrs:{"outlined":di !== _vm.hoverIdx,"color":d[m.measureColumn + 'Colour'],"small":""}},[_c('span',{style:({ fontSize: '13px', color: di === _vm.hoverIdx ? 'initial' : 'gray' })},[_vm._v(" "+_vm._s(d[m.measureColumn])+"% ")])]):_c('v-progress-linear',{staticClass:"mr-1 mb-1 mt-1",attrs:{"height":_vm.barHeight,"value":d[m.measureColumn],"color":d[m.measureColumn + 'Colour']},scopedSlots:_vm._u([{key:"default",fn:function({ value }){return [_c('strong',[_vm._v(_vm._s(Math.ceil(value))+"%")])]}}],null,true)})],1):_c('span',[_vm._v(_vm._s(d[m.measureColumn]))])])})],2)],1):_c('v-col',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"font-weight":"600"}},[_vm._v(_vm._s(d.key))])],1),_vm._l((d.GBDetails),function(sd,sdi){return _c('v-row',{key:'d' + di + 'sd' + sdi,staticStyle:{"cursor":"pointer"},attrs:{"no-gutters":""},on:{"click":function($event){return _vm.rowSelected(d.key + ' - ' + sd.key)}}},[_c('v-col',{staticStyle:{"padding-left":"20px"},attrs:{"cols":_vm.settings.titleColWidth || '2'}},[_vm._v(_vm._s(sd.key)),(
                _vm.section.view.filterCriteria.length &&
                !_vm.section.view.allowDrilldown
              )?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.removeFilter(0)}}},[_vm._v("mdi-filter-remove")]):_vm._e()],1),_vm._l((_vm.getMeasures()),function(m,mi){return _c('v-col',{key:'m' + mi,staticStyle:{"text-align":"center"}},[(m.isPercent)?_c('div',[(
                  _vm.settings.scoreStyle === 'circular' ||
                  _vm.settings.scoreStyle === 'circular-large'
                )?_c('v-progress-circular',{staticClass:"mr-1 mb-1 mt-1",attrs:{"rotate":90,"size":_vm.settings.scoreStyle === 'circular' ? 45 : 70,"width":_vm.settings.scoreStyle === 'circular' ? 10 : 20,"value":sd[m.measureColumn],"color":sd[m.measureColumn + 'Colour']}},[_c('span',{staticStyle:{"font-size":"13px","color":"gray"}},[_vm._v(" "+_vm._s(sd[m.measureColumn]))])]):_c('v-progress-linear',{staticClass:"mr-1 mb-1 mt-1",attrs:{"height":_vm.barHeight,"value":sd[m.measureColumn],"color":sd[m.measureColumn + 'Colour']},scopedSlots:_vm._u([{key:"default",fn:function({ value }){return [_c('strong',[_vm._v(_vm._s(Math.ceil(value))+"%")])]}}],null,true)})],1):_c('span',[_vm._v(_vm._s(sd[m.measureColumn]))])])})],2)})],2)],1)}),1),(_vm.settings.headerPosition === 'bottom')?_c('v-row',{ref:"header",style:({ fontWeight: 'bold', fontSize: _vm.settings.fontSize || '12px' }),attrs:{"no-gutters":"","dense":""}},[(_vm.settings.titleColWidth !== 0)?_c('v-col',{attrs:{"cols":_vm.settings.titleColWidth || '2'}}):_vm._e(),_vm._l((_vm.getMeasures()),function(m,mi){return _c('v-col',{key:'mh' + mi,staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(m.name)+" ")])})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }