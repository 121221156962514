<template>
  <div>
    <div v-if="viewtype === 'contained' || viewtype === 'dense_contained'">
      <v-autocomplete
        label="Review Group"
        :readonly="action.isDataCaptureViewer === 1"
        :clearable="action.isDataCaptureViewer !== 1"
        v-model="newGroupId"
        outlined
        hide-details
        :items="groups"
        item-text="name"
        item-value="review_group_id"
        :dense="viewtype === 'dense_contained'"
        class="roundish">
      </v-autocomplete>
    </div>
    <v-card v-else>
      <v-card-title>
        Assign Review Group
        <v-spacer></v-spacer>
        <v-btn v-if="viewtype === 'dialogue'" icon class="btn-background" @click="$emit('close')"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text class="pt-2 text-center">
        <v-progress-circular
          v-if="isLoading"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <template v-else>
          <v-text-field
            v-if="action.isDataCaptureViewer !== 1"
            label="Search"
            v-model="searchText"
            outlined
            class="roundish"
            clearable>
          </v-text-field>
          <div :class="[ 'listContainer', viewtype !== 'dialogue' ? 'small' : '' ]" @scroll="onListScroll">
            <div v-for="group in groupsFiltered"
              :key="'g' + group.review_group_id"
              :class="newGroupId === group.review_group_id ? 'active' : ''"
              @click="newGroupId = group.review_group_id">
              <div>
                <span>{{ group.name }}</span>
                <v-icon class="ml-2" v-if="newGroupId === group.review_group_id" color="success">task_alt</v-icon>
              </div>
              <v-chip small class="pt-1" :title="group.users + ' users'">{{ group.users }}</v-chip>
            </div>
          </div>
        </template>
      </v-card-text>
      <v-card-actions v-if="viewtype === 'dialogue'" class="mr-2 mb-2">
        <v-spacer></v-spacer>
        <v-btn outlined color="primary" @click="$emit('close')">Cancel</v-btn>
        <v-btn color="primary" :disabled="newGroupId === activeGroupId" @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "assignReviewGroup",
  components: {
  },
  props: {
    docId: { type: Number },
    action: { type: Object },
    editState: { type: Object },
    docType: { type: String },
    viewType: { type: String }
  },
  data: function () {
    return {
      isLoading: false,
      groups: [],
      groupsFiltered: [],
      searchText: "",
      pageSize: 20,
      toShow: 20,
      activeGroupId: null,
      newGroupId: null
    };
  },
  watch: {
    docId(val) {
      if (val) this.init();
    },
    docType(val) {
      if (val) this.init();
    },
    searchText() {
      this.toShow = this.pageSize;
      this.runSearch();
    },
    newGroupId(val) {
      this.$emit('change', this.newGroupId);

      if (val && val !== this.activeGroupId) {
        if (this.viewtype === 'inline')
          this.save();
        else if (this.editState) {
          this.$emit("updateDCState", {
            isDirty: true,
            canSave: true,
            canCancel: true,
            isValid: true
          });
        }
      }
    },
    "editState.doSave"(newval) {
      if (newval) {
        this.save();
      }
    },
  },
  computed: {
    viewtype() {
      if (this.viewType)
        return this.viewType;
      else
        return this.action.isDataCaptureEditInHeader
          ? "contained"
          : this.action.isDataCaptureEditInPage
          ? "inline"
          : "dialogue";
    }
  },
  mounted() {},
  created() {
    this.init();
  },
  methods: {
    init() {
      let possibleError = false;
      this.isLoading = true;
      if (this.docId)
        axios
          .get("reviewGroups/reviewGroupListForDoc/" + this.docId)
          .then((resp) => {
            possibleError = true;
            if (resp.data.Status === "OK") {
              
              this.activeGroupId = resp.data.Data.activeGroupId;
              this.newGroupId = resp.data.Data.activeGroupId;
              const groups = resp.data.Data.groups;
              const activeIdx = groups.findIndex(g => g.review_group_id === this.activeGroupId);
              if (this.activeGroupId > 0) {
                const activeGroup = groups.splice(activeIdx, 1)[0];
                groups.splice(0, 0, activeGroup);
              }
              this.groups = groups;
              this.runSearch();
            }
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            if (possibleError) {
              alert("Code Error");
            } else if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            }
            console.log(err);
          });
      else if (this.docType)
        axios
          .get("reviewGroups/reviewGroupListForDocType/" + this.docType)
          .then((resp) => {
            possibleError = true;
            if (resp.data.Status === "OK") {
              
              this.activeGroupId = null;
              this.newGroupId = null;
              const groups = resp.data.Data.groups;
              const activeIdx = groups.findIndex(g => g.review_group_id === this.activeGroupId);
              if (this.activeGroupId > 0) {
                const activeGroup = groups.splice(activeIdx, 1)[0];
                groups.splice(0, 0, activeGroup);
              }
              this.groups = groups;
              this.runSearch();
            }
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            if (possibleError) {
              alert("Code Error");
            } else if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            }
            console.log(err);
          });
    },
    runSearch() {
      const queryText = (this.searchText || "")
        .toLowerCase()
        .split(" ")
        .filter((x) => x.length)
        .map((x) => x);

      this.groupsFiltered = (!queryText || queryText.length === 0 ? this.groups :
        this.groups.filter(g => {
          const text = (g.name || "").toLowerCase()
          return queryText.every((s) => text.toLowerCase().indexOf(s) >= 0);
        }))
      .slice(0, this.toShow);
    },
    onListScroll({ target: { scrollTop, clientHeight, scrollHeight }}) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.toShow += this.pageSize;
        this.runSearch();
      }
    },
    save() {
      if (this.newGroupId)
        this.$emit('save', this.newGroupId, this.action);
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";

.listContainer {
  border: 1px solid #D8E0F0;
  border-radius: 8px;
  max-height: 500px;
  &.small {
    max-height: 200px;
  }
  overflow: auto;
  padding: 0 10px;
  > div {
    cursor: pointer;
    display: flex;
    align-content: center;
    justify-content: space-between;
    padding: 15px 10px;
    border-bottom: solid 1px #EBEEF9;
    &.active {
      span { font-weight: bold;}
    }
  }
}
</style>