<template>
  <v-row>
    <v-col cols="12" sm="6" md="3">
      <v-hover v-slot="{ hover }">
        <v-card class="link-card" to="alljobs" :elevation="hover ? 6 : 2">
          <v-card-text>
            <v-card class="dummydoc mx-5" elevation="0">
              <v-card-text>
                <v-divider class="mt-2"></v-divider>
                <v-divider class="mt-5 mr-12"></v-divider>
              </v-card-text>
            </v-card>
          </v-card-text>
          <v-card-actions class="pl-5 py-3 text--bold">
            View Existing
          </v-card-actions>
        </v-card>
      </v-hover>
    </v-col>
    <v-col v-if="!$loginState.readOnly" cols="12" sm="6" md="3">
      <v-hover v-slot="{ hover }">
        <v-card
          class="link-card"
          @click="addDocument"
          :elevation="hover ? 6 : 2"
        >
          <v-card-text>
            <v-card class="dummydoc mx-5" elevation="0">
              <v-card-text class="d-flex justify-center pt-8">
                <v-icon color="blue">mdi-plus</v-icon>
              </v-card-text>
            </v-card>
          </v-card-text>
          <v-card-actions class="pl-5 py-3 text--bold">
            Create New
          </v-card-actions>
        </v-card>
      </v-hover>
    </v-col>
    <v-col v-if="!$loginState.readOnly" cols="12" sm="6" md="3">
      <v-hover v-slot="{ hover }">
        <v-card
          class="link-card"
          @click="addDocument"
          :elevation="hover ? 6 : 2"
        >
          <v-card-text>
            <v-card class="dummydoc mx-5" elevation="0">
              <v-card-text>
                <v-divider class="orange mt-2 mb-2 mr-12"></v-divider>

                <v-divider
                  class="orange mt-3 d-inline-flex"
                  style="width: 35%"
                ></v-divider>
                <v-icon class="d-inline-flex orange mt-2" style="width: 25%"
                  >mdi-swap-vertical</v-icon
                >
                <v-divider
                  class="orange mt-3 d-inline-flex"
                  style="width: 35%"
                ></v-divider>

                <v-divider
                  class="orange mt-3 mr-12"
                  style="width: 30%"
                ></v-divider>
                <br />
              </v-card-text>
            </v-card>
          </v-card-text>
          <v-card-actions class="pl-5 py-3 text--bold">
            Upload {{ documentType }}
          </v-card-actions>
        </v-card>
      </v-hover>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "landingLinks",
  components: {},
  data: function () {
    return {
      links: [],
    };
  },
  props: {
    documentType: { type: String }
  },
  mounted() {
  },
  watch: {},
  created() {},
  methods: {
    addDocument() {
      this.$emit("addDocument", this.documentType || '');
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";

.link-card {
  border-radius: 10px !important;
  .v-card__text {
    background-color: #e9ecf5;
    padding-bottom: 0;
  }
  .v-card__actions {
    border-top: 1px solid #d0d6ec;
    font-size: 14px;
  }
  .dummydoc {
    min-height: 100px;
    background-color: $primary-background-light;
    .v-card__text {
      background-color: $primary-background-light;
    }
    hr {
      border-width: 2px !important;
      border-color: #d0d6ec;
      &.orange {
        border-width: 1px !important;
        border-color: #c26900;
        opacity: 0.2;
      }
    }
    .v-icon {
      font-size: 26px;
    }
    .v-icon.orange {
      background-color: transparent !important;
      color: #c26900;
      font-size: 22px;
    }
  }
}

.link-card.theme--dark {
  .v-card__text {
    background-color: #333;
  }
  .v-card__actions {
    border-top: 1px solid #333;
  }
  .dummydoc {
    background-color: $primary-background-dark;
    .v-card__text {
      background-color: $primary-background-dark;
    }
    hr {
      border-color: #333;
      &.orange {
        border-color: #c26900;
        opacity: 0.2;
      }
    }
    .v-icon.orange {
      background-color: transparent !important;
      color: #c26900;
    }
  }
}
</style>