<template>
<v-card color="transparent" dense class="suggestionsPanel" flat>
    <v-card-title style="padding-top: 5px;">
        <v-row dense style="height: 50px; ">
            <v-col cols="5" v-if="suggestionSourceItems.length > 1" >
                <v-select 
                dense
                multiple
                placeholder="Source"
                class="suggestionsInput"
                style="padding-top:2px"
                v-model="suggestionsSourceIncluded"
                :items="suggestionSourceItems"
                @change="filterSuggestions"
                :menu-props="{
                    closeOnContentClick: false,
                    maxHeight: '400',
                    'offset-y': true,
                }"
                >
                <template v-slot:selection="{ item, index }">
                    <span style="font-size:13px;">{{ getSuggestionSourceFilterText(item, index) }}</span>
                </template>                 
                </v-select>
            </v-col>
            <v-col :cols="suggestionSourceItems.length > 1 ? 7 : 12">
                <v-text-field
                dense
                append-icon="mdi-magnify"
                placeholder="Filter"
                class="suggestionsInput"
                v-model="suggestionFilterText"
                v-debounce:500ms="filterSuggestions"
                ></v-text-field>      
            </v-col>
        </v-row>
        <v-spacer></v-spacer>
        <v-btn title="Close Suggestions" small icon @click="closeSuggestionsPanel">
        <v-icon>mdi-close-thick</v-icon>
        </v-btn>
    </v-card-title>
    <!--<v-row dense>
        <v-col dense cols="12">
            <v-checkbox></v-checkbox>
        </v-col>
    </v-row>-->
    <v-row v-if="qualityFilterEnabled" dense style="margin-left:15px; margin-right:15px; padding-top:10px">
        <v-col dense cols="2"><span style="font-size:13px">Quality</span></v-col>
        <v-col dense cols="8" style="padding-top: 0px;">
            <v-slider
                dense
                v-model="suggestionQualityThreshold"
                :color="suggestionQualityThreshold >= 80 ? 'green' : (suggestionQualityThreshold < 80 && suggestionQualityThreshold > 40 ? 'orange' : 'red')"
                @change="filterSuggestions()"
            >
            </v-slider>
        </v-col>
        <v-col cols="1" align="left">
            <span style="padding-top:5px; width:25px; font-size: 11px;">{{suggestionQualityThreshold}}</span>
        </v-col>
        <v-col cols="1" align="right">
            <span style="padding-left:15px">
                <v-icon @click="toggleRelevantContent" v-if="showRelevantContentOnly" title="Show All Content" color="#FFD700">mdi-star</v-icon>
                <v-icon @click="toggleRelevantContent" v-else title="Show Only Job Title Matched Content">mdi-star-outline</v-icon>
            </span>
        </v-col>
    </v-row>
    <v-row style="margin-top: 0px;" dense>
      <v-col style="color: #75838f; font-weight: 600; padding-left: 25px; font-size: 13px; margin-bottom: 5px;">Suggestion Count: {{ filteredSuggestionsAll.length }} {{ selectedItemList.length> 0 ? `(${selectedItemList.length} Selected)` : '' }}</v-col>
    </v-row>
    <v-card-text class="suggestionsText" :style="{'margin-top': qualityFilterEnabled ? '0px' : '15px'}" @scroll="handleScroll" ref="suggestionsText">
        <div
        v-if="suggestionsLoading"
        style="height: 100%; top: 50%"
        align="center"
        >
        <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
            style="top: 45%"
        ></v-progress-circular>
        </div>
        <div v-else>
        <v-alert type="info" text v-if="!part && (!partsList || partsList.length === 1)">
            Please select a document part to show suggestions
        </v-alert>
        <div v-else-if="filteredSuggestions.length === 0">
            No Suggestions!
        </div>
        <v-list subheader three-line flat v-else-if="1 === 0">
            <v-list-item-group v-model="selectedSuggestions" multiple>
            <v-list-item
                class="suggestionItem draggableText"
                v-for="(item, i) in filteredSuggestions"
                :key="'suggestion_' + i"
                style="cursor: move"
                @click="selectSuggestion(item)"
            >
                <template v-slot:default="">
                <v-list-item-action
                    style="padding-top: 0px; margin-right: 16px"
                >
                    <v-checkbox
                    color="primary"
                    :value="item.selected"
                    ></v-checkbox>
                </v-list-item-action>

                <v-list-item-content
                    style="padding-top: 0px"
                    @dragstart="dragStartSuggestion(item, $event)"
                    @dragend="dragEnd"
                    draggable
                >
                <div style="display:inline-flex">
                    <v-icon v-if="item.relevant" :title="`Job Title Matched Content - ` + item.doc_name" small color="#FFD700" style="padding-top: 8px; padding-right:4px">mdi-star</v-icon>  
                    <v-list-item-title
                      class="suggestionTextTitle"
                    >
                      {{ item.type }}
                    </v-list-item-title>    
                        <v-chip
                         v-if="suggestionSourceItems.length > 1" style="display:inline-flex;margin-top: 20px !important; max-width: 120px;"
                        :color="item.source ==='Internal' ? 'green' : 'deep-purple accent-4'"
                        outlined
                        x-small
                        >
                            <span style="font-size: 10px;padding-left: 3px;padding-bottom: 3px;" :class="getSuggestionQualityColor(item.quality)">{{item.source}} {{item.quality}}%</span>
                        </v-chip>     
                </div>
                <div>
                    <v-list-item-subtitle
                      class="suggestionText"
                    >
                      {{ item.varVal }}
                    </v-list-item-subtitle>  
                </div>
                </v-list-item-content>                         
                </template>
            </v-list-item>
            </v-list-item-group>
        </v-list>
        <div v-else>
          <div class="suggestionItem draggableText"
            :class="item.selected ? 'activeSuggestion' : ''"
            v-for="(item, i) in filteredSuggestions"
            :key="'suggestion_' + i" 
            @mouseup="selectSuggestion(item)"
            @dragstart="dragStartSuggestion(item, $event)"
            @dragend="dragEnd"
            draggable
            style="cursor: move">
            <div style="display: inline-flex;">
              <v-icon :color="item.selected ? 'blue' : ''" class="suggestionTextCheckbox"> {{ item.selected ? 'mdi-checkbox-outline' : 'mdi-checkbox-blank-outline' }}</v-icon>
              <div class="suggestionTextTitle">{{ item.type }}</div>
              <v-chip
                         v-if="suggestionSourceItems.length > 1" style="display:inline-flex;margin-top: 14px !important; max-width: 120px; margin-left: 15px;"
                        :color="item.source ==='Internal' ? 'green' : 'deep-purple accent-4'"
                        outlined
                        x-small
                        >
                            <span style="font-size: 10px;padding-left: 3px;padding-bottom: 3px;" :class="getSuggestionQualityColor(item.quality)">{{item.source}} {{ item.source ==='Internal' ? '' : `${item.quality}%`  }}</span>
                        </v-chip> 
            </div>
            <div class="suggestionText">{{ item.varVal }}</div>
            
          </div>
        </div>
    </div>
    </v-card-text>
    
      <v-dialog v-model="suggestionsFilterDialog" max-width="800px">
        <v-card>
          <v-card-title style="margin-bottom: 10px"
            >Suggestions Filter Options:</v-card-title
          >
          <v-card-text style="height: 250px">
            <v-row>
              <v-col cols="4">Doc Parts:</v-col>
              <v-col cols="8">
                <v-select
                  v-model="suggestionPartFilter"
                  :items="suggestionTypes"
                  item-text="type"
                  item-value="type"
                  return-object
                  label=""
                  multiple
                  dense
                  hide-details
                  outlined
                  @change="filterSuggestions()"
                >
                  <template v-slot:selection="{ item, index }">
                    {{ getSuggestionTypeFilterText(item, index) }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" @click="suggestionsFilterDialog = false"
              >Close</v-btn
            >
          </v-card-actions>
          <v-divider></v-divider>
        </v-card>
      </v-dialog>
</v-card>
</template>

<script>
import axios from "axios"; 

export default {
  name: 'SuggestionsPanel',  
  props: {
    show: Boolean,
    part: Object,
    docList: Array,
    partsList: Array,
    languageCode: String
  },  
  data: function() {
    return {
      partType: null,
      suggestionTypes: [],
      suggestionSourceItems: [
        "Internal"
      ],
      suggestionsSourceIncluded: ["Internal"],
      externalContentItems:[],
      suggestionQualityThreshold: 80,
      suggestionFilterText: "",
      suggestionPartFilter: [],
      databaseViewExternalDocs: false,
      //filteredSuggestions: [],
      suggestionsLoading: false,
      selectedSuggestions: [],
      filteredSuggestionsAll: [],
      filteredSuggestions: [],
      suggestionsFilterDialog:false,
      showRelevantContentOnly: false,
      cachedLookups: [],
      qualityFilterEnabled: false,
      selectedItemList: []
    }
  },
  components: {

  },
  created() {
    if(this.part && this.part?.type !== ""){
      this.initSuggestions();
    }

    if(this.partsList && this.partsList.length > 0){
      this.initMultiSuggestion();
    }
  },    
  watch: {
    display(newVal){
        this.displayMode = newVal;
    },
    part(newVal){
        if(newVal && (!this.partType || newVal.type !== this.partType.type)){
            this.initSuggestions();
        }
    },
    partsList(){
      if(this.partsList.length > 0){
        this.initMultiSuggestion();
      } else {
        this.filteredSuggestionsAll = [];
        this.filteredSuggestions = [];
      }
    }
  },
  computed: {

  },
  methods: {
    clearSelection(){
      this.filteredSuggestions = this.filteredSuggestions.map(s => {
        return {
          ...s,
          selected: false
        }
      });
    },
    toggleRelevantContent(){
        this.showRelevantContentOnly = !this.showRelevantContentOnly;
        this.filterSuggestions();
    },
    initMultiSuggestion(){
      this.suggestionsLoading = true;
        this.databaseViewExternalDocs = this.$loginState.user.settings.some(
        (s) =>
            s.setting === "document_database_view_allow_external" &&
            s.value === "true"
        );

        this.suggestionFilterText = "";
        this.suggestionQualityThreshold = 80;

        if(this.databaseViewExternalDocs){
            if(this.suggestionSourceItems.indexOf("External") === -1) { this.suggestionSourceItems.push("External"); }
            if(this.suggestionsSourceIncluded.indexOf("External") === -1) { this.suggestionsSourceIncluded.push("External"); }
            this.qualityFilterEnabled = true;
        }

        this.loadSuggestions(this.partsList);
    },
    initSuggestions(){
        this.suggestionsLoading = true;
        this.databaseViewExternalDocs = this.$loginState.user.settings.some(
        (s) =>
            s.setting === "document_database_view_allow_external" &&
            s.value === "true"
        );

        this.suggestionFilterText = "";
        this.suggestionQualityThreshold = 80;

        if(this.databaseViewExternalDocs){
            if(this.suggestionSourceItems.indexOf("External") === -1) { this.suggestionSourceItems.push("External"); }
            if(this.suggestionsSourceIncluded.indexOf("External") === -1) { this.suggestionsSourceIncluded.push("External"); }
            this.qualityFilterEnabled = true;
        }

        this.partType = this.part;
        this.loadSuggestions([this.partType]);
    },
    selectSuggestion(item) {

        let targetType = this.partType ?? this.partsList.find(x => x.type === item.type);  
        let idx = this.filteredSuggestions.indexOf(item);
        if(targetType && !targetType.multiple){
          this.selectedSuggestions = [];
          this.filteredSuggestions = this.filteredSuggestions.map(x => {
            return {
              ...x,
              selected: false
            }
          })
        }
        
        item.selected = !item.selected;
        this.filteredSuggestions[idx] = item;
        this.selectedItemList = this.filteredSuggestions.filter((x) => x.selected);
        //this.$emit("selectionUpdated", this.selectedItemList.map((x) => { return x.varVal; }));
        this.$emit("selectionUpdated", this.selectedItemList.map((x) => { return {
          varVal: x.varVal,
          type: x.type
        }; }));

      /*this.$nextTick(() => {

        if(!this.partType){
          let targetType = this.partsList.find(x => x.type === item.type);
          if(!targetType.multiple){
            this.selectedSuggestions = [];
            this.filteredSuggestions.forEach(s => {
              s.selected = false;
            })
          }
        } else {
          if(!this.partType.multiple){
            this.selectedSuggestions = [];
            this.filteredSuggestions.forEach(s => {
              s.selected = false;
            })
          }
        }
        
        item.selected = !item.selected;
        this.filteredSuggestions = this.filteredSuggestions.map(s => ({ ...s }));    
        let items = this.filteredSuggestions
          .filter((x) => x.selected)
          .map((x) => {
            return x.varVal;
          });
          
        this.selectedItemList = items;
        this.$emit("selectionUpdated", items);
      })*/
    },    
    processContent(varVal){
      return varVal.trim();
    },
    dragStartSuggestion(item, event) {
      let items = this.filteredSuggestions
        .filter((x) => x.selected)
        .map((x) => {
          return { varVal: this.processContent(x.varVal) };
        });

      if (items.length > 1) {
        /*let text = "";
        items.forEach((x) => {
          text += "<p>" + x.varVal + "</p>";
        });*/

        /*let data = JSON.stringify({
          text: item.varVal,
          html: text,
        });*/
        event.dataTransfer.setData("array", JSON.stringify(items));
        this.isDataDrag = true;
        this.$emit("dragStart");
      } else {
        let data = JSON.stringify([{
          varVal: this.processContent(item.varVal)
        }]);
        event.dataTransfer.setData("array", data);
        this.isDataDrag = true;
        this.$emit("dragStart");
      }
      //event.dataTransfer.dropEffect = "move";
    },
    dragEnd(){
      this.isDataDrag = false;
      this.$emit("dragEnd");
      this.selectedSuggestions = [];
    }, 
    getSuggestionQualityColor(val){
      if(val >= 80){
        return "suggestionQualityHigh";
      }
      if(val > 40 && val < 80){
        return "suggestionQualityMed";
      }

      return "suggestionQualityLow";
    },    
    filterSuggestions() {
      this.filteredSuggestions = [];
      let filteredSuggestions = this.partSuggestions;

      if(this.suggestionsSourceIncluded.includes("External")){
        filteredSuggestions = filteredSuggestions.filter(p => p.quality >= this.suggestionQualityThreshold);
      }

      filteredSuggestions = filteredSuggestions.filter(p => this.suggestionsSourceIncluded.includes(p.source) && this.suggestionPartFilter.includes(p.type));

      if (this.suggestionFilterText !== "") {
        let search = this.suggestionFilterText.toLowerCase();
        filteredSuggestions = filteredSuggestions.filter(x => x.varVal.toLowerCase().indexOf(search) > -1);
      }

      filteredSuggestions =  [...new Map(filteredSuggestions.map(item => [item["varVal"], item])).values()].sort((a, b) => b.relevant - a.relevant || b.quality - a.quality);

      this.filteredSuggestionsAll = filteredSuggestions;
      this.filteredSuggestions = JSON.parse(JSON.stringify(filteredSuggestions.slice(0, 20)));
    },
    handleScroll() {
      const obj = this.$refs.suggestionsText;
      if(obj && obj.scrollTop === (obj.scrollHeight - obj.offsetHeight)) {
        //user scrolled to bottom so load more
        const currLength = this.filteredSuggestions.length;
        this.filteredSuggestions.push(...JSON.parse(JSON.stringify(this.filteredSuggestionsAll.slice(currLength, currLength + 20))));
      }
    },
    closeSuggestionsPanel(){
        this.$emit("close");
    },
    setupData(data){
      this.partSuggestions = this.markSuggestions(data);

      this.suggestionTypes = [
      ...new Set(this.partSuggestions.map((item) => item.type)),
      ];
      this.suggestionPartFilter = [
      ...new Set(this.partSuggestions.map((item) => item.type)),
      ];

      this.filterSuggestions();
      this.isLoading = false;
      this.suggestionsLoading = false;
    },
    markSuggestions(data){
      return data.map(x => {
          let matching = this.docList.map(x => x.doc_name).includes(x.doc_name);
          return {
              ...x,
              relevant: matching,
              selected: false
          }
      })
    },
    setCache(req, result){
      req.forEach(req => {
        this.cachedLookups.push({
          type: req.type,
          tmpl_part_id: req.tmpl_part_id,
          attr_id: req.attr_id || 0,
          data: result.filter(x => x.type === req.type)
        })
      })
    },
    getFromCache(ids){
      let allData = ids.map(x => {
        let cachedData = this.cachedLookups.find(c => c.tmpl_part_id === x.tmpl_part_id && c.attr_id === x.attr_id);
        return cachedData ? cachedData.data : []
      });
      return allData.flat(1);
    },
    loadSuggestions(ids) {
      if(ids.length === 0) {
        this.isLoading = false;
        this.suggestionsLoading = false;
        return;
      }
      if (!this.cachedLookups) this.cachedLookups = [];

      this.selectedSuggestions = [];
      this.selectedItemList = [];
      let cacheIds = this.cachedLookups.map(c => { return `${c.tmpl_part_id}_${c.attr_id || 0}`});

      let allIds = ids.map((a) => {
        return { tmpl_part_id: a.tmpl_part_id, attr_id: a.attr_id || 0, dp_id: a.dp_id, type: a.type };
      });
      let data = ids.filter(i => !cacheIds.includes(`${i.tmpl_part_id}_${i.attr_id || 0}`)).map((a) => {
        return { doc_id: a.doc_id, tmpl_part_id: a.tmpl_part_id, attr_id: a.attr_id, dp_id: a.dp_id, type: a.type, lang: this.languageCode };
      });

      //let existing = this.cachedLookups.find((l) => l.tmpl_part_id === tmpl_part_id);
      if(data.length === 0){
        let allResults = this.getFromCache(allIds);
        this.setupData(allResults);
        this.isLoading = false;
        this.suggestionsLoading = false;
      } else {
        //this.currentSuggestionPart = ids[0].tmpl_part_id;
        let possibleError = false;
        this.isLoading = true;
        this.suggestionsLoading = true;
        axios
          .post("document/getPartSuggestions/", data)
          .then((resp) => {
            possibleError = true;
            this.setCache(data, resp.data);
            let allResults = this.getFromCache(allIds);
            this.setupData(allResults);
            this.suggestionsLoading = false;
          })
          .catch((err) => {
            if (possibleError) {
              alert("Code Error");
            } else if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              alert(err.response ? err.response.data.message : err);
            }
            console.log(err);
            this.isLoading = false;
            this.suggestionsLoading = false;
          });
      }
    },
    getSuggestionSourceFilterText(item, index){
      if (item && index > 0) return "";
      if (this.suggestionsSourceIncluded.length === this.suggestionSourceItems.length)
        return this.suggestionSourceItems.length > 0 ? "All" : "None";

      if (this.suggestionsSourceIncluded.length > 2)
        return `${this.suggestionsSourceIncluded.length} items`;

      return this.suggestionsSourceIncluded.map((i) => i).join(", ");
    },    
  }
}
</script>
<style scoped lang="scss">

.suggestionQualityHigh{
  color:#4caf50 !important;
}
.suggestionQualityMed{
    color:orange !important;
}

.suggestionQualityLow{
  color:red !important;
}

.suggestionsInput{
    padding-left: 5px;
    font-size:13px; 
    min-height: 30px !important;
    max-height: 30px !important;

    .v-input__slot {
        min-height:35px !important;
        max-height:35px !important;
    }
    .v-input__control .v-input__slot {
        min-height:30px;
    }

    .v-select .v-select__slot {
        padding-bottom: 2px;
    }
}

</style>