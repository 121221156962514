import Vue from 'vue'
import Vuex from 'vuex'
import tasks from './modules/tasks'
import docs from './modules/docs'
import hierarchies from './modules/hierarchies'
import header from './modules/header'
import theme from './modules/theme'
import wordChecks from './modules/wordChecks'
import customers from './modules/customers'
import settings from './modules/settings';
import files from './modules/files';

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        tasks,
        docs,
        hierarchies,
        header,
        theme,
        wordChecks,
		customers,
		settings,
        files,
    }
})