<template>
  <v-container>
    <v-row
      style="
        border: solid 1px gray;
        border-radius: 5px;
        padding: 10px;
        height: 100%;
        margin-bottom: 10px;
      "
    >
      <v-col
        ><v-row>
          <v-col cols="4" style="border-right: solid grey 2px">
            <v-row class="flex-nowrap">
              <v-col
                ><v-text-field
                  v-if="node.reviewStatus.editMode"
                  outlined
                  style="font-weight: bold"
                  hide-details
                  v-model="node.reviewStatus.name"
                ></v-text-field
                ><span
                  v-else
                  @click="selectReviewChange"
                  style="font-weight: bold; cursor: text"
                >
                  <HierarchyNodeDisplay
                    :node="node"
                    hideReview
                    hideEdit
                    hideDescriptor
                    hideDrilldown
                    @action="doMenuAction($event)"
                  ></HierarchyNodeDisplay>
                </span>
              </v-col>
              <v-col cols="2"
                ><div
                  title="Job Titles"
                  :class="node.job_count ? 'job_count' : 'job_count_zero'"
                >
                  {{ node.job_count }}
                </div></v-col
              >
              <v-col cols="2"
                ><div
                  title="Employees"
                  :class="
                    node.employee_count
                      ? 'employee_count'
                      : 'employee_count_zero'
                  "
                >
                  {{ node.employee_count }}
                </div></v-col
              >
            </v-row>
            <v-row>
              <v-col v-if="node.reviewStatus.editMode"
                ><v-textarea
                  outlined
                  hide-details
                  v-model="node.reviewStatus.description"
                ></v-textarea>
              </v-col>
              <v-col
                v-else
                @click="selectReviewChange"
                style="cursor: text"
                >{{ node.description }}
              </v-col>
            </v-row>
            <v-row dense v-if="node.reviewStatus.editMode">
              <v-col></v-col>
              <v-col cols="2"
                ><v-btn @click="saveReviewChange" small color="info"
                  >Save</v-btn
                ></v-col
              >
              <v-col cols="2"
                ><v-btn
                  small
                  color="info"
                  @click="cancelReviewChange"
                  >Cancel</v-btn
                ></v-col
              >
            </v-row>
            <v-row v-if="node.nodes && node.nodes.length">
              <v-col>
                <v-row @click="node.showNodes = !node.showNodes"
                  ><v-col cols="10"
                    >{{ nodeChildLabel }}s ({{
                      node.nodes.length
                    }})</v-col
                  >
                  <v-col>
                    <v-icon v-if="node.showNodes">mdi-chevron-up</v-icon>
                    <v-icon v-else>mdi-chevron-down</v-icon></v-col
                  >
                </v-row>
                <div v-if="node.showNodes">
                  <v-row
                    v-for="(nn, nni) in node.nodes"
                    :key="'ndn' + nni"
                  >
                    <v-col>{{ nn.name }} </v-col>
                    <v-col cols="2"
                      ><div
                        :class="nn.job_count ? 'job_count' : 'job_count_zero'"
                      >
                        {{ nn.job_count }}
                      </div></v-col
                    >
                    <v-col cols="2"
                      ><div
                        :class="
                          nn.employee_count
                            ? 'employee_count'
                            : 'employee_count_zero'
                        "
                      >
                        {{ nn.employee_count }}
                      </div></v-col
                    >
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="8">
            <v-row
              class="feedback-row"
              v-if="
                viewAllReviews ||
                node.reviewStatus.docs_open.some((d) => d.hasData)
              "
            >
              <v-col cols="2"><h3>Review</h3></v-col>
              <v-col cols="5"
                ><h3>{{ node.label }} Updates</h3></v-col
              >
              <v-col cols="4"
                ><h3>{{ nestedMode ? node.label : "" }} Comments</h3></v-col
              >
              <v-col cols="1" v-if="nestedMode">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      left
                      @click.stop="viewAllReviews = !viewAllReviews"
                      v-bind="attrs"
                      v-on="on"
                      :color="
                        node.reviewStatus.hasData ? 'orange' : 'blue'
                      "
                    >
                      {{ viewAllReviews ? "mdi-eye-off" : "mdi-eye" }}
                    </v-icon>
                  </template>
                  <span>{{
                    viewAllReviews
                      ? "Hide reviews without Feedback"
                      : "Show all reviews"
                  }}</span>
                </v-tooltip></v-col
              >
            </v-row>
            <v-row class="feedback-row" v-else>
              <v-col cols="11"
                ><h3>
                  {{ "No " + node.label + " feedback received" }}
                </h3></v-col
              >
              <v-col cols="1" v-if="nestedMode">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      left
                      @click.stop="viewAllReviews = !viewAllReviews"
                      v-bind="attrs"
                      v-on="on"
                      :color="
                        node.reviewStatus.hasData ? 'orange' : 'blue'
                      "
                    >
                      {{ viewAllReviews ? "mdi-eye-off" : "mdi-eye" }}
                    </v-icon>
                  </template>
                  <span>{{
                    viewAllReviews
                      ? "Hide reviews without Feedback"
                      : "Show all reviews"
                  }}</span>
                </v-tooltip></v-col
              >
            </v-row>
            <v-row v-if="viewAllReviews">
              <v-col>
                <h3>
                  Open Reviews ({{ node.reviewStatus.docs_open.length }}):
                </h3>
              </v-col>
            </v-row>
            <v-row
              v-for="(rd, rdi) in node.reviewStatus.docs_open.filter(
                (d) => d.hasData || viewAllReviews
              )"
              :key="'rd' + rdi"
              class="feedback-row"
            >
              <v-col cols="2"
                ><v-tooltip right>
                  <template v-slot:activator="{ on, attrs }"
                    ><v-btn
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      small
                      @click="openDocument(rd)"
                      ><v-icon>mdi-file-eye</v-icon>
                      {{ rd.created_date }}</v-btn
                    >
                  </template>
                  <div
                    v-for="(a, ai) in rd.activity"
                    :key="'rd' + rd.doc_id + 'a' + ai"
                  >
                    {{ a }}
                  </div>
                </v-tooltip>
                <div
                  v-for="(a, ai) in rd.roles"
                  :key="'rd' + rd.doc_id + 'r' + ai"
                  style="padding-top: 5px"
                >
                  {{ a.role_type + (a.role_users.length > 1 ? "s" : "") }}:
                  <div
                    class="review-user"
                    v-for="(c, ci) in a.role_users"
                    :key="'rd' + rd.doc_id + 'r' + ai + 'c' + ci"
                  >
                    {{ c }}
                  </div>
                </div></v-col
              >
              <v-col cols="5">
                <v-row v-if="rd.name_last_updated_date">
                  <v-col cols="11"
                    ><h4>
                      Name:
                      <v-icon v-if="rd.name_status === 'Change Applied'" color="green" :title="rd.name_status">mdi-check-circle</v-icon>
                    <div style="float: right">
                        {{ rd.name_last_updated_date }} by
                        <v-chip outlined>{{
                          rd.name_last_updated_by_name
                        }}</v-chip>
                      </div>
                    </h4>
                    <br />
                    <div v-html="rd.name_html"></div>
                    <br />
					<v-btn v-if="rd.name_status !== 'Change Applied'"
                      style="float: right"
                      small
                      @click="selectReviewChange(rd, 'name')"
                      >Apply Change</v-btn
                    ></v-col
                  >
                </v-row>
                <v-row v-if="rd.description_last_updated_date">
                  <v-col cols="11"
                    ><h4>
                      Description:
					<v-icon v-if="rd.description_status === 'Change Applied'" color="green" :title="rd.description_status">mdi-check-circle</v-icon>
                      <div style="float: right; font-size: 12px;;">
                        {{ rd.description_last_updated_date }} by
                        <v-chip small outlined>{{
                          rd.description_last_updated_by_name
                        }}</v-chip>
                      </div>
                    </h4>
                    <br />
                    <div v-html="rd.description_html"></div>
                    <br />
					<v-btn v-if="rd.description_status !== 'Change Applied'"
                      style="float: right"
                      small
                      @click="selectReviewChange(rd, 'description')"
                      >Apply Change</v-btn
                    >
                  </v-col>
                </v-row>
                <v-row
                  v-if="
                    !rd.name_last_updated_date &&
                    !rd.description_last_updated_date
                  "
                  ><v-col>No updates suggested</v-col></v-row
                >
              </v-col>
              <v-col
                cols="5"
                v-if="rd.part_comment_count && (!viewAllReviews || nestedMode)"
              >
                <div style="height: 100%; position: relative">
                  <div style="overflow-y: scroll; max-height: 200px">
                    <DocumentComments
                      :document="rd"
                      :doc_part_id="rd.dp_id"
                      hideHeader
                    ></DocumentComments>
                  </div>
                </div>
              </v-col>
              <v-col
                cols="5"
                v-if="
                  viewAllReviews &&
                  !nestedMode &&
                  (rd.overall_comment_count || rd.part_comment_count)
                "
              >
                <v-tabs v-model="rd.tab" height="40px">
                  <v-tab v-if="rd.part_comment_count">
                    <v-badge color="green" :content="rd.part_comment_count"
                      >{{ node.label }}
                    </v-badge></v-tab
                  >
                  <v-tab v-if="rd.overall_comment_count">
                    <v-badge color="green" :content="rd.overall_comment_count"
                      >{{ nodeParentLabel || "Overall" }}
                    </v-badge></v-tab
                  >
                </v-tabs>
                <v-tabs-items v-model="rd.tab"
                  ><v-tab-item v-if="rd.part_comment_count">
                    <div style="height: 100%; position: relative">
                      <div style="overflow-y: scroll; max-height: 200px">
                        <DocumentComments
                          :document="rd"
                          :doc_part_id="rd.dp_id"
                          hideHeader
                        ></DocumentComments>
                      </div></div
                  ></v-tab-item>
                  <v-tab-item v-if="rd.overall_comment_count">
                    <div style="height: 100%; position: relative">
                      <div style="overflow-y: scroll; max-height: 200px">
                        <DocumentComments
                          :document="rd"
                          :part="null"
                          hideHeader
                        ></DocumentComments>
                      </div></div></v-tab-item
                ></v-tabs-items>
              </v-col>
            </v-row>
            <v-row
              v-if="
                node.reviewStatus.docs_completed.length && viewAllReviews
              "
            >
              <v-col cols="3"
                ><h3>
                  Completed Reviews ({{
                    node.reviewStatus.docs_completed.length
                  }}):
                </h3></v-col
              >
              <v-col v-if="!nestedMode"
                ><v-switch
                  v-model="showCompletedReviews"
                  label="Show"
                ></v-switch
              ></v-col>
            </v-row>
            <v-row
              v-for="(rd, rdi) in node.reviewStatus.docs_completed.filter(
                (d) =>
                  d && viewAllReviews && (showCompletedReviews || nestedMode)
              )"
              :key="'rdc' + rdi"
              style="
                background-color: #eceff1;
                border-radius: 8px;
                margin-left: 0;
                margin-bottom: 0;
              "
            >
              <v-col cols="2"
                ><v-tooltip right>
                  <template v-slot:activator="{ on, attrs }"
                    ><v-btn
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      small
                      @click="openDocument(rd)"
                      ><v-icon>mdi-file-eye</v-icon>
                      {{ rd.created_date }}</v-btn
                    >
                  </template>
                  <div
                    v-for="(a, ai) in rd.activity"
                    :key="'rd' + rd.doc_id + 'a' + ai"
                  >
                    {{ a }}
                  </div>
                </v-tooltip>
                <div
                  v-for="(a, ai) in rd.roles"
                  :key="'rd' + rd.doc_id + 'r' + ai"
                  style="padding-top: 5px"
                >
                  {{ a.role_type + (a.role_users.length > 1 ? "s" : "") }}:
                  <div
                    class="review-user"
                    v-for="(c, ci) in a.role_users"
                    :key="'rd' + rd.doc_id + 'r' + ai + 'c' + ci"
                  >
                    {{ c }}
                  </div>
                </div></v-col
              >
              <v-col cols="5">
                <v-row v-if="rd.name_last_updated_date">
                  <v-col cols="11"
                    ><h4>
                      Name:
                      <div style="float: right">
                        {{ rd.name_last_updated_date }} by
                        <v-chip outlined>{{
                          rd.name_last_updated_by_name
                        }}</v-chip>
                      </div>
                    </h4>
                    <br />
                    <div v-html="rd.name_html"></div
                  ></v-col>
                </v-row>
                <v-row v-if="rd.description_last_updated_date">
                  <v-col cols="11"
                    ><h4>
                      Description:
                      <div style="float: right">
                        {{ rd.description_last_updated_date }} by
                        <v-chip outlined>{{
                          rd.description_last_updated_by_name
                        }}</v-chip>
                      </div>
                    </h4>
                    <br />
                    <div v-html="rd.description_html"></div>
                  </v-col>
                </v-row>
                <v-row
                  v-if="
                    !rd.name_last_updated_date &&
                    !rd.description_last_updated_date
                  "
                  ><v-col>No updates suggested</v-col></v-row
                >
              </v-col>
              <v-col cols="5" v-if="rd.part_comment_count && !viewAllReviews"
                >Comments ({{ rd.part_comment_count }}):
                <div style="height: 100%; position: relative">
                  <div style="overflow-y: scroll; max-height: 200px">
                    <DocumentComments
                      :document="rd"
                      :doc_part_id="rd.dp_id"
                      hideHeader
                    ></DocumentComments>
                  </div>
                </div>
              </v-col>
              <v-col
                cols="5"
                v-if="
                  viewAllReviews &&
                  (rd.overall_comment_count || rd.part_comment_count)
                "
              >
                <v-tabs v-model="rd.tab" height="40px">
                  <v-tab v-if="rd.part_comment_count">
                    <v-badge color="green" :content="rd.part_comment_count"
                      >{{ node.label }}
                    </v-badge></v-tab
                  >
                  <v-tab v-if="rd.overall_comment_count">
                    <v-badge color="green" :content="rd.overall_comment_count"
                      >{{ nodeParentLabel || "Overall" }}
                    </v-badge></v-tab
                  >
                </v-tabs>
                <v-tabs-items v-model="rd.tab"
                  ><v-tab-item v-if="rd.part_comment_count">
                    <div style="height: 100%; position: relative">
                      <div style="overflow-y: scroll; max-height: 200px">
                        <DocumentComments
                          :document="rd"
                          :doc_part_id="rd.dp_id"
                          hideHeader
                        ></DocumentComments>
                      </div></div
                  ></v-tab-item>
                  <v-tab-item v-if="rd.overall_comment_count">
                    <div style="height: 100%; position: relative">
                      <div style="overflow-y: scroll; max-height: 200px">
                        <DocumentComments
                          :document="rd"
                          :part="null"
                          hideHeader
                        ></DocumentComments>
                      </div></div></v-tab-item
                ></v-tabs-items>
              </v-col>
            </v-row>
          </v-col> </v-row
      ></v-col>
    </v-row>
  </v-container>
</template>

<script>
import HierarchyNodeDisplay from "@/components/hierarchy/cHierarchyNodeDisplay";
import DocumentComments from "@/components/comments/CommentsContainer";
import axios from "axios";
import utils from "@/common/utils.js";

export default {
  name: "HierarchyNodeReview",
  components: {
    HierarchyNodeDisplay,
    DocumentComments,
  },
  props: {
    actions: [],
    reviewNode: Object,
    nodeChildLabel: String,
    showAllReviews: Boolean,
    nestedMode: Boolean,
    nodeParentLabel: String,
    // nodeColumn: String,
  },
  data: function () {
    return {
      node: null,
      showCompletedReviews: false,
      viewAllReviews: false,
    };
  },
  watch: {
    reviewNode(newval) {
      this.node = JSON.parse(JSON.stringify(newval));
    },
	},
  computed: {},
  created: function () {
      this.node = JSON.parse(JSON.stringify(this.reviewNode));
    if (this.showAllReviews) this.viewAllReviews = true;
  },
  methods: {
    selectReviewChange(review, type) {
      if (!this.node.reviewStatus.description)
        this.node.reviewStatus.description = this.node.description;
      if (!this.node.reviewStatus.name) this.node.reviewStatus.name = this.node.name;
      if (review) {
        this.node.reviewStatus[type] = review[type + "_reviewed"];
      }
      this.node.reviewStatus.editMode = true;
    },
    cancelReviewChange() {
      this.node.reviewStatus.description = this.node.reviewStatus.description_original;
      this.node.reviewStatus.name = this.node.reviewStatus.name_original;
      this.node.reviewStatus.editMode = false;
    },
    saveReviewChange() {
      let possibleError = false;
      this.node.new_name = this.node.reviewStatus.name;
      this.node.new_description = this.node.reviewStatus.description;
      this.node.descriptor = {
        ht_id: this.node.ht_id,
        hierarchy_node_id: this.node.hierarchy_node_id,
        description: this.node.description,
      };
      axios
        .post("hierarchy/savenodedetails/", this.node)
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            this.node.name = this.node.new_name;
            this.node.description = this.node.new_description;
            this.node.reviewStatus.editMode = false;
            this.$emit("nodeSaved", this.reviewNode, {
              name: this.node.new_name,
              description: this.node.description,
            });
          }
          this.response = resp.data;
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
    doMenuAction(action) {
      this.$emit("action", action); //{ action: action, node: this.reviewNode });
    },
    openDocument(item) {
      this.$emit("openDocument", {
        system_number: utils.removeTags(item.system_number),
        doc_name: utils.removeTags(item.doc_name),
        doc_type: item.doc_type,
        viewAction: item.viewAction,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.displayBorder {
  border: solid 1px #004d40;
  border-radius: 5px;
  display: block;
  height: 24px;
  font-family: "Martel Sans", sans-serif;
  font-size: 12px;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}
.displayTitle {
  border: solid 1px #004d40;
  border-radius: 5px;
  display: block;
  height: 36px;
  font-family: "Martel Sans", sans-serif;
  font-size: 16px;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  //   width: 100%;
}
.selectable {
  cursor: pointer;
}

.review-user {
  padding-left: 10px;
  background-color: white;
  border-radius: 5px;
  margin-left: 10px;
  margin-bottom: 3px;
}
.feedback-row {
  background-color: #eceff1;
  border-radius: 8px;
  margin-left: 0;
  margin-bottom: 0;
}
</style>