<template>
  <v-container>
    <v-row>
      <v-col>
        <h1 class="title">Administer Hierarchies</h1>
      </v-col>
    </v-row>
    <v-card
      class="mainPanel mt-6"
      v-if="!hierarchiesLoading && ht_id && hierarchieTypes"
    >
      <v-card-title class="pb-0">
        <v-row dense>
          <v-col cols="2">
            <v-select
              :items="hierarchieTypes"
              :required="true"
              label="Hierarchy Type"
              item-text="ht_name"
              item-value="ht_id"
              v-model="ht_id"
              hide-details
              dense
              outlined
            ></v-select>
          </v-col>
          <v-col cols="2">
            <v-switch v-model="hideInactive" label="Hide Inactive"></v-switch
          ></v-col>
          <v-col cols="2">
            <v-btn color="primary" @click="doAdd = !doAdd"
              ><v-icon class="pr-4">mdi-briefcase-plus-outline</v-icon> Add new
              {{
                hierarchieTypes.find((x) => x.ht_id === ht_id).level1_name
              }}</v-btn
            ></v-col
          >
          <v-col
            ><v-btn-toggle
              dense
              v-model="viewType"
              borderless
              class="float-right"
            >
              <v-btn value="tree">
                <span class="hidden-sm-and-down">Tree View</span>
                <v-icon right>mdi-file-tree</v-icon>
              </v-btn>
              <v-btn value="table">
                <span class="hidden-sm-and-down">List View</span>
                <v-icon right>mdi-table-large</v-icon>
              </v-btn>
            </v-btn-toggle></v-col
          >
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row dense
          ><v-col>
            <HierarchyTreeNodeHorizontal
              :viewLevel="1"
              :ht_id="ht_id"
              :hideInactive="hideInactive"
              :viewType="viewType"
              :doAdd="doAdd"
            >
            </HierarchyTreeNodeHorizontal> </v-col
        ></v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import HierarchyTreeNodeHorizontal from "@/components/hierarchy/cHierarchyTreeNodeHorizontal";

export default {
  name: "adminHierarchy",
  data: function () {
    return {
      ht_id: null,
      hideInactive: true,
      doAdd: false,
      viewType: "tree",
    };
  },
  components: {
    HierarchyTreeNodeHorizontal,
  },
  created: function () {
    if (this.hierarchieTypes.length) this.ht_id = this.hierarchieTypes[0].ht_id;
  },
  computed: {
    ...mapState({
      hierarchiesLoading: (state) => state.hierarchies.loading,
      hierarchieTypes: (state) => state.hierarchies.hierarchies,
    }),
  },
  watch: {
    hierarchiesLoading(val) {
      if (val === false) {
        this.ht_id = this.hierarchieTypes[0].ht_id;
      }
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
::v-deep .v-data-table tbody td {
  cursor: pointer !important;
}
</style>