var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.nodes)?_c('div',_vm._l((_vm.nodes.filter((x) => x.visible)),function(node){return _c('v-row',{key:'n' + node.hierarchy_node_id,attrs:{"dense":""}},[_c('v-col',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('div',{staticClass:"nodeContainer",style:({
                marginLeft: (node.level - 1) * 10 + '%',
              }),attrs:{"title":(node.name || 'UNCLASSIFIED') + ' (' + (node.hasDocs === 0 ? 'UNUSED BUT ACTIVE)' : node.hasDocs + ' linked  documents)')},on:{"click":function($event){node.expanded = !node.expandable ? node.expanded :
                  node.expandable && node.level < _vm.viewLevel && !node.expanded}}},[(node.expandable && node.level < _vm.viewLevel)?_c('v-icon',[_vm._v(_vm._s(node.expanded ? "mdi-chevron-up" : "mdi-chevron-down"))]):_vm._e(),(node.pickable)?_c('v-icon',{staticClass:"pickable",on:{"click":function($event){$event.stopPropagation();return _vm.pickNode(node)}}},[_vm._v(_vm._s(node.picked ? "mdi-radiobox-marked" : "mdi-radiobox-blank"))]):_vm._e(),_c('div',{staticClass:"node",class:[
                  node.pickable ? 'nodePickable' : 'nodeNonPickable',
                  node.expandable && node.level < _vm.viewLevel
                    ? 'nodeExpandable'
                    : '',
                  node.selected ? 'nodeSeleted' : '',
                  !node.selected && node.picked ? 'nodePicked' : '',
                ],style:({ backgroundColor: node.hasDocs === 0 ? (_vm.$vuetify.theme.isDark ? '#a77262' : '#FFCCBC') : ''})},[(node.pickable)?_c('div',{staticClass:"nodeInner",on:{"click":function($event){$event.stopPropagation();return _vm.pickNode(node)}}},[_vm._v(_vm._s((node.name || 'UNCLASSIFIED') + ' [' + node.hasDocs + ']'))]):_c('div',{staticClass:"nodeInner"},[_vm._v(_vm._s((node.name || 'UNCLASSIFIED') + ' [' + node.hasDocs + ']'))]),(node.hasDescription)?_c('v-icon',{staticClass:"nodeInfoIcon",attrs:{"small":"","title":node.description}},[_vm._v("mdi-information")]):_vm._e()],1)],1)])],1),(node.nodes && node.expanded)?_c('div',[_c('HierarchyTreeNode',{attrs:{"nodes":node.nodes,"viewLevel":_vm.viewLevel},on:{"pickNode":_vm.pickNode}})],1):_vm._e()],1)],1)}),1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }