<template>
  <div>
    <div v-if="textItems.length > 0" class="no-print">
      <v-data-table
        :headers="headers"
        :items="filteredItems"
        :page.sync="currentPage"
        dense
        class="mt-6 elevation-0"
        :footer-props="{ 'items-per-page-options': [10, 20, 30, 50, -1] }"
        sort-by="tc_word"
        :items-per-page="dtItemsPerPage"
        @click:row="editItem"
        hide-default-footer
      >
        <template v-slot:top>
          <v-row class="mx-3" dense>
            <v-col cols="2">
              <v-text-field
                v-model="search.text"
                label="Search"
                outlined
                dense
                prepend-inner-icon="mdi-magnify"
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-select
                label="Type"
                v-model="search.tc_type"
                :items="typeOptions"
                outlined
                dense
                clearable
              >
              </v-select>
            </v-col>
            <v-col cols="2">
              <v-select
                label="Check Type"
                v-model="search.check_type"
                :items="checkTypeOptions"
                outlined
                dense
                clearable
              >
              </v-select>
            </v-col>
            <v-col cols="4">
              <v-switch
                label="Applicable to this customer only"
                v-model="search.isClient"
              >
              </v-switch>
            </v-col>
          </v-row>
        </template>
        <template #[`item.client`]="{ item }">
          <div class="truncate" :title="getClientDesc(item.ids)">{{ getClientDesc(item.ids, true) }}</div>
        </template>
        <template #[`item.tc_active`]="{ item }">
          <v-icon v-if="item.tc_active == 1">check_circle</v-icon>
          <v-icon v-else class="outlined">cancel</v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="resetSearch"> Reset </v-btn>
        </template>
      </v-data-table>
    </div>
    <v-row class="my-2 mx-1">
      <v-col cols="3">
        <v-btn
            color="primary"
            outlined
            small
            @click="dialog = true"
            >New Item</v-btn
          >
      </v-col>
      <v-col class="text-center">
        <Pagination
          :totalItems="filteredItems.length"
          :pageSize="dtItemsPerPage"
          :currentPage="currentPage"
          @pageNavigation="currentPage = $event"
        />
      </v-col>
      <v-col cols="3" class="text-right">
        <v-menu offset-y z-index="301">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="!$loginState.readOnly"
              outlined
              small
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              {{ itemsPerPageText }}
              <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item
              v-for="item in itemsPerPageOptions"
              :key="item.value"
              @click="setPageSize(item.value)"
            >
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          color="primary"
          class="ml-2"
          outlined
          small
          @click="exportToCSV()"
          >Export to CSV</v-btn
        >
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" persistent width="1200">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            large
            class="btn-background"
            @click="close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="editedItem.tc_word"
                  label="Word"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-select
                  :items="typeOptions"
                  :required="true"
                  label="Type"
                  v-model="editedItem.tc_type"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-select
                  :items="checkTypeOptions"
                  label="Check Type"
                  :required="true"
                  v-model="editedItem.check_type"
                ></v-select>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="4" md="4">
                <v-container class="px-0" fluid>
                  <v-switch
                    v-model="editedItem.tc_active"
                    label="Active"
                  ></v-switch>
                </v-container>
              </v-col>
              <v-col cols="12" sm="8" md="8">
                <v-select
                  v-model="editedItem.client_ids"
                  item-text="client_name"
                  item-value="client_id"
                  :items="clients"
                  label="Customers"
                  multiple
                >
                  <template v-slot:selection="{ item, index }">
                    <span v-if="item && index === 0">
                      {{ selectedClientsDesc }}
                    </span>
                  </template>
                  <template v-slot:prepend-item>
                    <v-list-item
                      ripple
                      @mousedown.prevent
                      @click="toggleAllClients"
                    >
                      <v-list-item-action>
                        <v-icon
                          :color="
                            editedItem.client_ids.length > 0 ? 'primary' : ''
                          "
                        >
                          {{ icon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title> Select All </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="12" md="12">
                <span>Alternative Words</span>
              </v-col>
            </v-row>
            <v-row dense>
              <v-container fluid>
                <v-combobox
                  v-model="editedItem.alternatives"
                  :items="editedItem.alternatives"
                  :search-input.sync="altsearch"
                  hide-selected
                  label="Add some words"
                  multiple
                  dense
                  solo
                  flat
                  small-chips
                  @change="itemAdded"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          No results matching "<strong>{{ altsearch }}</strong
                          >". Press <kbd>enter</kbd> to create a new one
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template
                    v-slot:selection="{ attrs, item, select, selected }"
                  >
                    <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      close
                      dense
                      @click="select"
                      @click:close="removeAltWordFromList(item)"
                    >
                      <strong>{{ item }}</strong>
                    </v-chip>
                  </template>
                </v-combobox>
              </v-container>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="12" md="12">
                <span>Whitelist Phrases</span>
              </v-col>
            </v-row>
            <v-row dense>
              <v-container fluid>
                <v-combobox
                  v-model="editedItem.whitelist_phrases"
                  :items="editedItem.whitelist_phrases"
                  :search-input.sync="oksearch"
                  hide-selected
                  label="Add some phrases where it is ok for this word to appear"
                  multiple
                  dense
                  solo
                  flat
                  small-chips
                  @change="whitelistPhraseAdded"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          No results matching "<strong>{{ oksearch }}</strong
                          >". Press <kbd>enter</kbd> to create a new one
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template
                    v-slot:selection="{ attrs, item, select, selected }"
                  >
                    <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      close
                      dense
                      @click="select"
                      @click:close="removeWhitelistPhraseFromList(item)"
                    >
                      <strong>{{ item }}</strong>
                    </v-chip>
                  </template>
                </v-combobox>
              </v-container>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn color="error" outlined @click="deleteCurrentItem">
            Delete
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="close"> Cancel </v-btn>
          <v-btn color="primary" @click="save"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="showErrorSnack"
      :timeout="snackTimeout"
      :color="snackColor"
      :multi-line="errorText.length > 50"
      top
    >
      {{ errorText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="dialogDelete" max-width="900px">
      <v-card>
        <v-card-title class="justify-center">
          <div class="justify-center">
            Are you sure you want to delete this item?
          </div>
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="12" md="12">
              <div align="center" class="justify-center">
                {{ editedItem.tc_word }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="closeDelete">Cancel</v-btn>
          <v-btn color="error" @click="deleteItemConfirm">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ResponseHandler :serviceResponse="response"></ResponseHandler>
  </div>
</template>

<script>
import axios from "axios";
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
import Pagination from "@/components/cPagination";
import utils from "@/common/utils.js";
//import moment from "moment";

export default {
  name: "adminText",
  components: {
    ResponseHandler,
    Pagination
  },
  data: function () {
    return {
      dtItemsPerPage: 20,
      itemsPerPageOptions: [
        { text: "10 Rows", value: 10 },
        { text: "20 Rows", value: 20 },
        { text: "30 Rows", value: 30 },
        { text: "50 Rows", value: 50 },
      ],
      currentPage: 1,
      response: null,
      display: true,
      tab: null,
      textItems: [],
      clients: [],
      altsearch: "",
      oksearch: "",
      errorText: "",
      showErrorSnack: false,
      snackColor: "error",
      snackTimeout: 4000,
      blankItemArray: [],
      search: {
        text: null,
        tc_type: null,
        check_type: null,
        isClient: true,
      },
      addedAlternativeWord: "",
      utils: utils,
      formTitle: "Text Check",
      headers: [
        { text: "Word", value: "tc_word" },
        { text: "Clients", value: "client", filterable: false },
        { text: "Type", value: "tc_type", filterable: false },
        { text: "Alternatives", value: "alternatives", filterable: false },
        { text: "Check Type", value: "check_type", filterable: false },
        { text: "Active", value: "tc_active", filterable: false },
      ],
      tmpCopy: {},
      editedIndex: -1,
      editedItem: {
        tc_word: "",
        alternatives: [],
        whitelist_phrases: [],
        tc_active: true,
        tc_type: "",
        check_type: "",
        stored_ids: [],
        client_ids: [],
      },
      defaultItem: {
        tc_word: "",
        alternatives: [],
        whitelist_phrases: [],
        tc_active: true,
        tc_type: "",
        check_type: "",
        stored_ids: [],
        client_ids: [],
      },
      dialog: null,
      dialogDelete: null,
      typeOptions: [
        //todo: pull from db
        "Gender",
        "Age",
        "Elitism",
        "Race",
        "Religious",
        "Disability",
        "Educationalism",
        "Talent Excluder",
        "ingCheck",
        "infCheck",
        "Readability",
      ],
      checkTypeOptions: ["exact", "postfix", "prefix"],
    };
  },
  props: {
    method: { type: Function },
  },
  mounted() {},
  computed: {
    allClientsSelected() {
      return (
        this.editedItem.client_ids &&
        this.editedItem.client_ids.length === this.clients.length
      );
    },
    someClientsSelected() {
      return (
        this.editedItem.client_ids &&
        this.editedItem.client_ids.length > 0 &&
        !this.allClientsSelected
      );
    },
    selectedClientsDesc() {
      if (this.allClientsSelected) return "All";

      if (this.editedItem.client_ids.includes(this.$loginState.user.client_id))
        return `${this.$loginState.user.client}${this.editedItem.client_ids.length > 1 ? " + " + (this.editedItem.client_ids.length - 1) + " others" : ''}`;

      if (this.editedItem.client_ids.length > 5)
        return `${this.editedItem.client_ids.length} selected`;

      return this.editedItem.client_ids
        .map((id) => this.clients.find((c) => c.client_id === id)?.client_name)
        .join(", ");
    },
    icon() {
      if (this.allClientsSelected) return "mdi-close-box";
      if (this.someClientsSelected) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    filteredItems() {
      if (!this.textItems) return [];

      let items = [...this.textItems];

      if (this.search.text)
        items = items.filter((ti) =>
          ti.tc_word.toLowerCase().includes(this.search.text.toLowerCase())
        );

      if (this.search.tc_type)
        items = items.filter((ti) => ti.tc_type === this.search.tc_type);

      if (this.search.check_type)
        items = items.filter((ti) => ti.check_type === this.search.check_type);

      if (this.search.isClient)
        items = items.filter(
          (ti) =>
            ti.ids.some((id) => id.client_id === null) ||
            ti.ids.some(
              (id) => id.client_id === this.$loginState.user.client_id
            )
        );

      return items;
    },
    itemsPerPageText() {
      const option = this.itemsPerPageOptions.find(
        (o) => o.value == this.dtItemsPerPage
      );
      return option ? option.text : `${this.dtItemsPerPage} Items`;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    initialise() {
      this.loadData();
    },
    loadData() {
      let possibleError = false;
      this.isLoading = true;
      axios
        .get("admin/text/list/")
        .then((resp) => {
          possibleError = true;
          this.textItems = resp.data.Data.textChecks.map((tc) => {
            tc.id = `${tc.tc_word}|${tc.tc_type}|${tc.alternatives.join(",")}|${tc.whitelist_phrases.join(",")}|${
              tc.check_type
            }|${tc.exact_check}`;
            return tc;
          });
          this.typeOptions = resp.data.Data.checkTypes;
          this.clients = resp.data.Data.clients;
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
          this.isLoading = false;
        });
    },
    resetSearch() {
      this.search.text = null;
      this.search.tc_type = null;
      this.search.check_type = null;
      this.search.isClient = true;
    },
    getClientDesc(ids, short) {
      if (ids.some(i => i.client_id === null))
        return "All";

      const clients = this.clients
        .filter(c => ids.some(i => i.client_id === c.client_id));

      if (short) {
        if (clients.some(c => c.client_id === this.$loginState.user.client_id))
          return `${this.$loginState.user.client}${clients.length > 1 ? " + " + (clients.length - 1) + " others" : ''}`;
        else if (clients.length > 2)
          return `${clients.length} customers`
      }

      return clients.map(c => c.client_name).join(", ");
    },
    exportToCSV() {
      let data = this.headers.map((h) => '"' + h.text + '"').join(",") + "\n";
      this.filteredItems.forEach((d) => {
        data +=
          this.headers
            .map((h) => {
              if (h.value === "client")
                return utils.csvEscape(this.getClientDesc(d.ids));
              else if (h.value === "alternatives")
                return utils.csvEscape(d.alternatives.join(","));
              else if (h.value === "whitelist_phrases")
                return utils.csvEscape(d.whitelist_phrases.join(","));
              else
                return utils.csvEscape(utils.removeTags(d[h.value]));
            })
            .join(",")
            + "\n";
      });
      utils.downloadFile(data, "Users.csv", "text/csv;encoding:utf-8");
    },
    editItem(item) {
      this.editedIndex = this.textItems.indexOf(item);
      let editedItem = JSON.parse(JSON.stringify(item));
      editedItem.client_ids = item.ids.some((id) => id.client_id === null)
        ? this.clients.map((c) => c.client_id)
        : this.clients
            .filter((c) =>
              item.ids.some((i) => i.client_id === c.client_id)
            )
            .map((c) => c.client_id);
      this.editedItem = Object.assign({}, editedItem);
      this.dialog = true;
    },
    toggleAllClients() {
      this.$nextTick(() => {
        if (this.allClientsSelected) {
          this.editedItem.client_ids.splice(0);
        } else {
          this.editedItem.client_ids.splice(0);
          this.editedItem.client_ids.push(
            ...this.clients.map((c) => c.client_id)
          );
        }
      });
    },

    deleteCurrentItem() {
      this.dialogDelete = true;
    },

    deleteItem(item) {
      this.editedIndex = this.textItems.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let data = this.editedItem;
      axios
        .post("admin/text/delete", data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            var deletedItem = JSON.parse(resp.config.data);
            this.triggerNotification(
              deletedItem.tc_word + " Deleted",
              "success"
            );
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });

      this.textItems.splice(this.editedIndex, 1);
      this.closeDelete();
      this.close();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    save() {
      let data = this.editedItem;

      if (!data.tc_type || !data.check_type || !data.tc_word) {
        this.triggerNotification("Please fill in all fields", "error");
        return;
      }

      data.allClients = this.allClientsSelected;

      axios
        .post("admin/text", data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            this.updateItem(resp.data.Data);
            this.close();
          }
          this.response = resp.data;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },

    removeAlternativeWord(value) {
      var itemToRemove = this.editedItem.alternatives.indexOf(value);
      if (itemToRemove > -1) {
        this.editedItem.alternatives.splice(itemToRemove, 1);
      }
    },

    removeWhitelistPhrase(value) {
      var itemToRemove = this.editedItem.whitelist_phrases.indexOf(value);
      if (itemToRemove > -1) {
        this.editedItem.whitelist_phrases.splice(itemToRemove, 1);
      }
    },

    updateItem(updateItem) {
      if (this.editedIndex > -1) {
        this.textItems.splice(this.editedIndex, 1, updateItem);
      } else {
        this.textItems.push(updateItem);
      }
    },

    removeAltWordFromList(item) {
      let idx = this.editedItem.alternatives.indexOf(item);
      this.editedItem.alternatives.splice(idx, 1);
      this.editedItem.alternatives = [...this.editedItem.alternatives];
    },

    removeWhitelistPhraseFromList(item) {
      let idx = this.editedItem.whitelist_phrases.indexOf(item);
      this.editedItem.whitelist_phrases.splice(idx, 1);
      this.editedItem.whitelist_phrases = [...this.editedItem.whitelist_phrases];
    },

    triggerNotification(text, type) {
      this.errorText = text;
      this.snackColor = type;
      this.showErrorSnack = true;
    },

    itemAdded() {
      var itemToAdd = this.altsearch.trim();
      let existingItems = this.textItems.findIndex(
        (item) => item.tc_word === itemToAdd
      );

      if (existingItems == -1) {
        this.removeAlternativeWord(this.altsearch);

        let existingSubItems = this.editedItem.alternatives.findIndex(
          (item) => item === itemToAdd
        );
        if (existingSubItems == -1) {
          this.editedItem.alternatives.push(itemToAdd);
        } else {
          this.triggerNotification(
            "'" +
              this.altsearch +
              "' cannot be added as it is already being used",
            "error"
          );
        }
      } else {
        this.removeAlternativeWord(itemToAdd);
        this.triggerNotification(
          "'" +
            this.altsearch +
            "' cannot be added as it is already being used in the main list",
          "error"
        );
        return false;
      }
    },

    whitelistPhraseAdded() {
      var itemToAdd = this.oksearch.trim();
      let existingItems = this.textItems.findIndex(
        (item) => item.tc_word === itemToAdd
      );

      if (existingItems == -1) {
        this.removeWhitelistPhrase(this.oksearch);

        let existingSubItems = this.editedItem.whitelist_phrases.findIndex(
          (item) => item === itemToAdd
        );
        if (existingSubItems == -1) {
          this.editedItem.whitelist_phrases.push(itemToAdd);
        } else {
          this.triggerNotification(
            "'" +
              this.oksearch +
              "' cannot be added as it is already being used",
            "error"
          );
        }
      } else {
        this.removeWhitelistPhrase(itemToAdd);
        this.triggerNotification(
          "'" +
            this.oksearch +
            "' cannot be added as it is already being used in the main list",
          "error"
        );
        return false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.v-data-table::v-deep {
  tbody {
    tr {
      cursor: pointer;
      td {
        font-size: 0.8rem !important;
      }
    }
  }
  .truncate {
    width: 200px;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>