import axios from "axios";

export const state = () => ({
	initialised: false,
	loading: false,
	customerList: []
});

export const mutations = {
	initialised(state) {
		state.initialised = true;
		state.loading = true;
	},
	clear(state) {
		state.customerList.splice(0);
	},
	unload(state) {
		state.initialised = false;
		state.loading = false;
		mutations.clear(state);
	},
	replace(state, data) {
		mutations.clear(state);
		state.customerList = data.customerList;
		state.loading = false;
	}
};

export const actions = {
	init({ commit, getters }) {
		if (!getters.isInitialised) {
			commit("initialised");
			actions.getCustomers({ commit, getters });
		}
	},
	unload({ commit }) {
		commit("unload");
	},
	getCustomers({ commit, getters }) {
		if (!getters.isInitialised) return;

		axios
			.get("client/customerList")
			.then((resp) => {
				if (resp.data.Status === "OK") {
					const data = {};
					data.customerList = resp.data.Data
					commit("replace", data);
				}
			})
			.catch((err) => {
				if (err.response && err.response.status === 401) {
					commit("unload");
				} else {
					//try again in 30 secs
					setTimeout(() => actions.getCustomers({ commit, getters }), 30 * 1000);
				}
				console.log(err);
			});
	},
};

export const getters = {
	isInitialised: (state) => {
		return state.initialised;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
