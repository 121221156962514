<template>
    <div style="height:100%">
        <div v-if="currentEdit" class="sectionAdminContent flow-font-small">
            <div v-if="currentEdit.edit_type === 'section'">
                <!--<v-row dense justify-center>
                    <v-col cols="12" style="text-align: center;">
                        
                    Editing Section: {{ currentEdit.item.section }}
                     
                            <div class="iconBar">
                                <v-icon>mdi-shield-account</v-icon>
                                <v-icon>mdi-cog</v-icon>
                            </div>
                        
                    </v-col>
                </v-row>-->
                
                <v-row dense class="d-flex align-center justify-center" v-if="currentEdit.item.view_mode === null" >
                    <v-col dense>  
                        <v-btn @click="editParts" color="primary">Configure Parts <v-icon right>mdi-wrench</v-icon> </v-btn>
                    </v-col>
                </v-row>   
                <v-row dense>
                    <v-col>        
                        <v-text-field
                            label="Section Name"
                            placeholder="Section Name"
                            type="input"
                            v-model="currentEdit.item.section"
                            :value="currentEdit.item.section"
                            @input="preview"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col>        
                        <v-text-field
                            label="Header Value"
                            placeholder="Header Value"
                            type="input"
                            v-model="currentEdit.item.header_type"
                            :value="currentEdit.item.header_type"
                            @input="preview"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col>        
                        <v-text-field
                            label="Footer Value"
                            placeholder="Footer Value"
                            type="input"
                            v-model="currentEdit.item.footer_type"
                            :value="currentEdit.item.footer_type"
                            @input="preview"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row v-if="currentEdit.item.sub_sections.length > 1" dense>
                    <v-col cols="12">        
                        <v-switch @change="preview" label="Enable 'Complete all' Action?" v-model="currentEdit.item.enable_complete_subsections" style="word-break: break-word"></v-switch>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            label="Label"
                            placeholder="Label"
                            type="input"
                            v-model="currentEdit.item.complete_all_label"
                            :value="currentEdit.item.complete_all_label"
                            @input="preview"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-switch @change="preview" label="View Linked Content?" v-model="currentEdit.item.view_linked_content" style="word-break: break-word"></v-switch>
                    </v-col>
                    <v-col cols="12">
                        <v-select
                            dense
                            class="slimSelect"
                            bg-color="transparent"
                            item-text="section"
                            label="Target Section"
                            item-value="tmpl_view_section_id"
                            v-model="currentEdit.item.complete_all_target_section_id"
                            :items="sectionList"
                            style="padding-top: 15px;"
                            @change="preview" 
                        >
                            <template slot="selection" slot-scope="data">
                                <span style="font-size:11px; background-color:transparent">{{ data.item.section }}</span>
                            </template>  
                            <template slot="item" slot-scope="data">
                                <span style="font-size:11px;">{{ data.item.section }}</span>
                            </template> 
                        </v-select>
                    </v-col>
                </v-row>
                <!--<v-row  dense>
                    <v-col>        
                        <v-switch @change="preview" label="Completed?" v-model="currentEdit.item.complete" style="word-break: break-word"></v-switch>
                    </v-col>
                </v-row>-->
                <v-row dense class="d-flex align-center justify-center" >
                    <v-col dense cols="5">Display Type:</v-col>
                    <v-col dense>      
                        <v-select
                            dense
                            class="slimSelect"
                            bg-color="transparent"
                            item-text="text"
                            item-value="value"
                            v-model="currentEdit.item.view_mode"
                            :items="sectionViewType"
                            style="top:5px;"
                            @change="preview" 
                        >
                            <template slot="selection" slot-scope="data">
                                <span style="font-size:11px; background-color:transparent">{{ data.item.text }}</span>
                            </template>  
                            <template slot="item" slot-scope="data">
                                <span style="font-size:11px;">{{ data.item.text }}</span>
                            </template> 
                        </v-select>                        
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col dense cols="6">
                        <v-switch @change="preview" :true-value=1 :false-value=0 label="LHS Expanded?" v-model="currentEdit.item.lhs_expanded" style="word-break: break-word"></v-switch>
                    </v-col>
                    <v-col dense cols="6">
                        <v-switch @change="preview" :true-value=1 :false-value=0 label="LHS Expandable?" v-model="currentEdit.item.lhs_expandable" style="word-break: break-word"></v-switch>
                    </v-col>
                    <v-col dense cols="6">
                        <v-switch @change="preview" :true-value=1 :false-value=0 label="RHS Expanded?" v-model="currentEdit.item.rhs_expanded" style="word-break: break-word"></v-switch>
                    </v-col>
                    <v-col dense cols="6">
                        <v-switch @change="preview" :true-value=1 :false-value=0 label="RHS Expandable?" v-model="currentEdit.item.rhs_expandable" style="word-break: break-word"></v-switch>
                    </v-col>
                </v-row>
                <v-row dense class="d-flex align-center justify-center" v-if="currentEdit.item.view_mode === 'REVIEW'">
                    <v-col v-if="configData && configData.actions">
                        <v-select :items="configData.actions" v-model="currentEdit.item.submit_action_id" item-value="lifecycle_action_id" item-text="name"></v-select>
                    </v-col>
                </v-row>             
            </div>
            <div v-else-if="currentEdit.edit_type === 'part'">

                <div v-if="currentEdit.item.tmpl_part_metadata.contentPart">
                    <v-row dense justify-center>
                        <v-col cols="12" style="text-align: center;">{{ currentEdit.part.tmpl_view_section_part_id === 0 ? 'Add' : 'Edit'}} Part: {{ currentEdit.part.content_name }}</v-col>
                    </v-row>
                    <v-row dense>
                        <v-col dense cols="6">Content Part Name:</v-col>
                        <v-col dense cols="6">
                            <v-text-field
                                placeholder="Enter a name for your Content Part"
                                type="input"
                                v-model="currentEdit.part.content_name"
                                :value="currentEdit.part.content_name"
                                @input="preview"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col dense cols="6">Content Type:</v-col>
                        <v-col dense cols="6">
                            <v-select @change="preview" dense v-model="currentEdit.part.content_type" :items="partTypes"></v-select>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col>        
                            <v-text-field
                                label="Placeholder"
                                placeholder="Placeholder Text"
                                type="input"
                                v-model="currentEdit.part.content_placeholder"
                                :value="currentEdit.part.content_placeholder"
                                @input="preview"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col>        
                            <v-text-field
                                label="Text"
                                placeholder="Text"
                                type="input"
                                v-model="currentEdit.part.content_text"
                                :value="currentEdit.part.content_text"
                                @input="preview"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col>        
                            <v-switch @change="preview" label="Readonly?" v-model="currentEdit.part.readonly" style="word-break: break-word"></v-switch>
                        </v-col>
                    </v-row>  
                    <v-row dense>
                        <v-col cols="12">Active Styles:</v-col>
                        <v-col>
                            <v-chip-group
                                multiple
                                selected-class="activeChip"
                                :value="currentEdit.part.layout"
                                v-model="currentEdit.part.layout" @change="preview"
                            >
                                <v-chip
                                    v-for="style in styles"
                                    :key="style" :value="style"
                                >
                                    {{ style }}
                                </v-chip>
                            </v-chip-group>
                        </v-col>
                    </v-row>                  
                </div>
            </div>
        </div>
        <div v-if="display === 'inline'" class="sectionAdminFooter">
            <v-btn @click="cancelEdit">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" class="ml-2" @click="save">Save</v-btn>            
        </div>
        <Notification :notification="notification"/>
        <v-dialog v-model="permissionDialog.show" v-if="currentEdit.edit_type === 'partPermissions' || currentEdit.edit_type === 'sectionPermissions'">
            <v-card>
                <v-card-title class="d-flex align-center">
                    Editing Permissions for:  {{ permissionDialog.title }}
                </v-card-title>
                <v-card-text>
                    <v-expansion-panels multiple v-model="permissionDialog.expandedSection">
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <v-flex xs2 class="ml-2 mb-1">
                                    <v-switch @click.native.stop label="Permission Control?" v-model="currentEdit.item.permissions_list.permissionControlled" style="word-break: break-word"></v-switch>
                                </v-flex>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row v-if="currentEdit.item.permissions_list.permissionControlled" dense justify-center>
                                    <v-col cols="12" style="text-align: center;">
                                        <v-select
                                            dense
                                            class="slimSelect"
                                            bg-color="transparent"
                                            item-text="name"
                                            item-value="id"
                                            v-model="currentEdit.item.workflowId"
                                            :items="workflowConfig"
                                            @change="changeWorkflow"
                                        >
                                            <template slot="label">
                                                <span style="font-size:11px; background-color:transparent">Select Workflow</span>
                                            </template>
                                            <template slot="selection" slot-scope="data">
                                                <span style="font-size:11px; background-color:transparent">{{ data.item.name }}</span>
                                            </template>  
                                            <template slot="item" slot-scope="data">
                                                <span style="font-size:11px;">{{ data.item.name }}</span>
                                            </template> 
                                        </v-select>
                                    </v-col>
                                </v-row>   
                                <v-row dense v-if="configData && currentEdit.item.workflowId">
                                    <v-col>
                                        <v-simple-table dense style="width: 100%">
                                        <thead>
                                        <tr>
                                            <th style="border-right: 1px solid #dfdfdf;">Role Group:</th>
                                            <th style="text-align: center;" class="px-0" v-for="status in configData.statuses" :key="status.lifecycle_status_id">
                                                <v-chip
                                                    class="mx-2 status-chip"
                                                    label
                                                    small
                                                    outlined
                                                    :color="status.colour"
                                                >
                                                    {{status.name}}
                                                </v-chip>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr
                                            v-for="role in configData.roles"
                                            :key="role.lifecycle_role_id"
                                        >
                                            <td style="border-right: 1px solid #dfdfdf;">
                                                <v-chip outlined label color="primary" small>{{role.name}}</v-chip>
                                            </td>

                                            <td style="text-align: center;" class="px-0" v-for="status in configData.statuses" :key="status.lifecycle_status_id">
                                                <template>
                                                    <v-tooltip bottom v-if="hasEditPerms(status, role)">
                                                        <template v-slot:activator="{ on: tooltip }">
                                                            <v-btn
                                                                icon
                                                                @click="toggleWorkflowPermissions(status, role)"
                                                                v-on="tooltip"
                                                            >
                                                                <v-icon>edit</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Edit Permissions<br /> Click to remove rights</span>
                                                    </v-tooltip>
                                                    <v-tooltip bottom v-else-if="hasViewPerms(status, role)">
                                                        <template v-slot:activator="{ on: tooltip }">
                                                            <v-btn
                                                                icon
                                                                @click="toggleWorkflowPermissions(status, role)"
                                                                v-on="tooltip"
                                                            >
                                                                <v-icon>visibility</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>View only permissions<br /> Click to add edit rights</span>
                                                    </v-tooltip>
                                                    <v-tooltip bottom v-else>
                                                        <template v-slot:activator="{ on: tooltip }">
                                                            <v-btn
                                                                icon
                                                                @click="toggleWorkflowPermissions(status, role)"
                                                                style="opacity:0.2"
                                                                v-on="tooltip"
                                                            >
                                                                <v-icon>add_circle_outline</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>No permissions<br /> Click to add rights</span>
                                                    </v-tooltip>
                                                </template>
                                            </td>
                                        </tr>
                                        </tbody>
                                        </v-simple-table>
                                    </v-col>
                                </v-row> 
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel v-model="currentEdit.item.permissions_list.permissionControlled">
                            <v-expansion-panel-header>
                                <v-flex xs2 class="ml-2 mb-1">
                                    <v-switch @click.native.stop label="Hierarchy Control?" v-model="currentEdit.item.permissions_list.hrPermissionControlled" style="word-break: break-word"></v-switch>
                                </v-flex>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row dense justify-center>
                                    <v-col>
                                        <v-simple-table>
                                            <thead>
                                                <tr>
                                                    <th>Hierarchy Type: <v-icon @click="addHierarchyPermissionRow" class="ml-4" style="cursor:pointer" small color="primary">mdi-plus-circle</v-icon></th>
                                                    <th>Level 1</th>
                                                    <th>Level 2</th>
                                                    <th>Level 3</th>
                                                    <th>Level 4</th>
                                                    <th>Level 5</th>
                                                    <th>Level 6</th>
                                                    <th>Permission Type</th>
                                                    <th>Permission Value</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="(item,idx) in currentEdit.item.permissions_list.hierarchyPermissions"
                                                    :key="idx"
                                                >
                                                    <td>
                                                        <v-select 
                                                            :items="hierarchies" 
                                                            dense
                                                            item-text="ht_name"
                                                            item-value="ht_id"
                                                            v-model="item.ht_id"
                                                        >
                                                        </v-select>
                                                    </td>
                                                    <td><v-text-field dense v-model="item.level1"></v-text-field></td>
                                                    <td><v-text-field dense v-model="item.level2"></v-text-field></td>
                                                    <td><v-text-field dense v-model="item.level3"></v-text-field></td>
                                                    <td><v-text-field dense v-model="item.level4"></v-text-field></td>
                                                    <td><v-text-field dense v-model="item.level5"></v-text-field></td>
                                                    <td><v-text-field dense v-model="item.level6"></v-text-field></td>
                                                    <td>                                                        
                                                        <v-select 
                                                            :items="['VIEW','EDIT']" 
                                                            dense
                                                            v-model="item.permission_type"
                                                        >
                                                        </v-select>
                                                    </td>
                                                    <td>                                                        
                                                        <v-select 
                                                            :items="['ALLOW','RESTRICT']" 
                                                            dense
                                                            v-model="item.permission_value"
                                                        >
                                                        </v-select>
                                                    </td>
                                                    <td style="padding-bottom: 15px;">
                                                        <v-icon @click="deleteHrPerm(item)">mdi-delete</v-icon>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </v-simple-table>
                                    </v-col>                        
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel> 
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <v-flex xs2 class="ml-2 mb-1">
                                    <v-switch @click.native.stop label="Value Control?" v-model="currentEdit.item.permissions_list.valueControlled" style="word-break: break-word"></v-switch>
                                </v-flex>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row dense justify-center>
                                    <v-col>                                        
                                        <v-simple-table>
                                            <thead>
                                                <tr>
                                                    <th style="width:15%">Display Type: <v-icon @click="addViewDependency" class="ml-4" style="cursor:pointer" small color="primary">mdi-plus-circle</v-icon></th>
                                                    <th>Conditions</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(row,vIdx) in currentEdit.item.permissions_list.view_dependencies" :key="vIdx">
                                                    <td>
                                                        <v-select v-model="row.display_type" dense :items="displayType"></v-select>
                                                    </td>
                                                    <td style="display: inline-flex;">
                                                        <v-select v-model="row.tmpl_part" label="Part" dense :items="partItems" item-text="type" item-value="tmpl_part_id" return-object class="ml-2 mr-2" @change="row.tpa_id = null"></v-select>
                                                        <v-select v-model="row.tpa_id" v-if="row.tmpl_part !== null && row.tmpl_part.attributes.length > 0" label="Attr" item-text="title" item-value="tpa_id" :items="row.tmpl_part.attributes" dense class="ml-2 mr-2"></v-select>
                                                        <v-select v-model="row.display_condition" dense :items="displayCondition" class="ml-2 mr-2"></v-select>
                                                        <v-text-field v-model="row.display_values" dense class="ml-2 mr-2"></v-text-field>
                                                    </td>
                                                    <td>
                                                        <v-icon>mdi-delete</v-icon>
                                                    </td>
                                                </tr>   
                                            </tbody>
                                        </v-simple-table>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>                       
                    </v-expansion-panels>
                </v-card-text>
                <v-card-actions class="d-flex justify-end px-6 pb-4">
                    <v-btn @click="permissionDialog.show = false">
                        Cancel
                    </v-btn>
                    <v-btn color="primarylight" @click="savePermissions">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>            
    </div>

</template>

<script>

import { mapState } from "vuex";
import viewUtils from "./utils";
import Notification from "@/components/common/SnackBar.vue";

export default {
    name: 'ViewSectionAdmin',
    props: {
        value: Object,
        workflowConfig: Array,
        display: String,
        sectionList: Array
    },
    data: function() {
        return {
            partTypes: [
                'LABEL',
                'TEXTBOX',
                'TEXTAREA'
            ],
            currentEdit: null,
            originalEdit: null,
            configData: null,
            notification: {
                text:"",
                type:"success"
            },
            styles: [
                "Bold",
                "Central",
                "Header"
            ],
            sectionViewType: [
                { text: "Parts", value: null },
                { text: "Review", value: "REVIEW" },
                { text: "Data Capture", value: "DATACAPTURE" }
            ],
            displayType: [
                { text: "Show", value: "SHOW" },
                { text: "Hide", value: "HIDE" }
            ],
            displayCondition: [
                { text: "Equals", value: "EQUALS" },
                { text: "Does Not Equal", value: "NOT_EQUAL" }
            ],
            permissionDialog:{
                show:false,
                title: "",
                edit_type: "",
                expandedSection: [0],
            },
            editingWorkflow:0
        }
    },
    components: {
        Notification
    },
    watch: {
        
        value(val){
            this.initEvent(val);      
        },
        
    },    
    computed: { 
        ...mapState({
            hierarchies: (state) => state.hierarchies.hierarchies,
        }),        
        partItems(){
            return this.sectionList.map(s => {
                return s.part_data.map(p => {
                    return {
                        type: p.type,                  
                        tmpl_part_id: p.tmpl_part_id,
                        tmpl_view_section_part_id: p.tmpl_view_section_part_id,
                        attributes: p.tmpl_part_metadata.attributes.length > 0 ? p.tmpl_part_metadata.attributes?.map(a => {
                            return {
                                tpa_id: a.tpa_id,
                                title: a.title
                            }
                        }) : []
                    }
                })
            }).flat(1);
        }
    },
    mounted() {

    },
    created() {
        if(this.value){
            this.initEvent(this.value);         
        }
    },    
    methods: { 
        addViewDependency(){
            let newPerm = {
                display_type: "Show",
                tmpl_view_section_id: null,
                tmpl_view_section_part_id: null,
                tmpl_part: null,
                tpa_id: null,
                display_condition: null,
                display_values: "",
            };
            if(this.currentEdit.edit_type === 'sectionPermissions'){
                newPerm.tmpl_view_section_id = this.currentEdit.item.tmpl_view_section_id;
            } else if(this.currentEdit.edit_type === 'partPermissions'){
                newPerm.tmpl_view_section_id = this.value.sectionId;
                newPerm.tmpl_view_section_part_id = this.currentEdit.item.tmpl_view_section_part_id;
            }
            this.currentEdit.item.permissions_list.view_dependencies.push(newPerm);
        },        
        getKey(type){
            switch(type){
                case "partPermissions":
                    return "tmpl_view_section_part_permission_id";
                case "sectionPermissions":
                    return "tmpl_view_section_permission_id";
            }
        },
        async deleteHrPerm(item){
            let key = this.currentEdit.edit_type === 'partPermissions' ? 'tmpl_view_section_part_hr_perm_id' : 'tmpl_view_section_hr_perm_id';
            let idx = this.currentEdit.item.permissions_list.hierarchyPermissions.findIndex(x => x[key] === item[key]);
            if(idx > -1){
                await viewUtils.deletePartHrPermission(item[key]);
                this.currentEdit.item.permissions_list.hierarchyPermissions.splice(idx, 1);
                this.triggerNotification("Permission Deleted", "success");
            }
        },
        async toggleWorkflowPermissions(status, role) {
            let basePermissionObj = {
                toDelete: false,
                tmpl_id: this.permissionDialog.tmpl_id,
                lifecycle_id: this.currentEdit.item.workflowId,
                permission_value: 'ALLOW',
                permission_active: true,
                lifecycle_status_id: status.lifecycle_status_id,
                lifecycle_role_id: role.lifecycle_role_id,
            }

            if(this.permissionDialog.edit_type === 'partPermissions'){
                basePermissionObj = {
                    ...basePermissionObj,
                    tmpl_view_section_part_permission_id: 0,
                    tmpl_view_section_part_id: this.currentEdit.item.tmpl_view_section_part_id
                };          
            } else if(this.permissionDialog.edit_type === 'sectionPermissions'){
                basePermissionObj = {
                    ...basePermissionObj,
                    permission_scope: ["TMPL","CONTENT"],
                    tmpl_view_section_permission_id: 0,
                    tmpl_view_section_id: this.currentEdit.item.tmpl_view_section_id
                }; 
            }

            const perms = this.configData.permissions.filter(x => x.lifecycle_role_id === role.lifecycle_role_id && x.lifecycle_status_id === status.lifecycle_status_id && x.permission_active && !x.toDelete);
            if (perms.some(x => x.permission_type === 'EDIT' && x.permission_value === 'ALLOW')) {
                perms.forEach(x => x.toDelete = true);
            } else if (perms.some(x => x.permission_type === 'VIEW' && x.permission_value === 'ALLOW')) {
                //progress from view only to edit perms
                const editPerm = {
                    ...basePermissionObj,
                    permission_type: 'EDIT',
                }
                this.configData.permissions.push(editPerm);
            } else {
                //progress from no perms to view only
                const viewPerm = {
                    ...basePermissionObj,
                    permission_type: 'VIEW',
                }
                this.configData.permissions.push(viewPerm);
            }
        },
        async persistWorkflowPermissions(){
            if (!this.configData)
                return;
            
            const deletePermission = this.permissionDialog.edit_type === 'partPermissions' ? viewUtils.deletePartPermission : viewUtils.deleteSectionPermission;
            const savePermission = this.permissionDialog.edit_type === 'partPermissions' ? viewUtils.savePartPermission : viewUtils.saveSectionPermission;
            const idAttr = this.permissionDialog.edit_type === 'partPermissions' ? 'tmpl_view_section_part_permission_id' : 'tmpl_view_section_permission_id';

            this.configData.permissions = this.configData.permissions.filter(x => !x.toDelete || x[idAttr]);

            const toDelete = this.configData.permissions.filter(x => x.toDelete && x[idAttr]);
            if (toDelete.length)
                await deletePermission(toDelete.map(x => x[idAttr]))
                    .then(() => {
                        this.configData.permissions = this.configData.permissions.filter(x => !x.toDelete);
                    });

            const toAdd = this.configData.permissions.filter(x => !x[idAttr]);
            if (toAdd.length)
                await savePermission(toAdd).then((resp) => {
                    resp.data.forEach((p, pi) => {
                        toAdd[pi][idAttr] = p[idAttr];
                    });
                });

            this.currentEdit.item.permissions_list.permissions = this.currentEdit.item.permissions_list.permissions.filter(x => x.lifecycle_id !== this.configData.lifecycle_id);
            this.currentEdit.item.permissions_list.permissions.push(...this.configData.permissions);
        },
        async addSectionHrPermission(){
            let objs = [];
            this.currentEdit.item.permissions_list.hierarchyPermissions.forEach(p => {
                let newObj = {
                    tmpl_view_section_hr_perm_id: p.tmpl_view_section_hr_perm_id ?? null,
                    tmpl_view_section_id: this.currentEdit.item.tmpl_view_section_id,
                    ht_id: p.ht_id,
					permission_type: "VIEW",
					permission_value: "ALLOW",
					permission_active: true,       
                    hierarchy_node_id: null            
                };

                [1, 2, 3, 4, 5, 6].forEach((l) => {
                    newObj["level" + l] = p["level" + l] ?? null;
                });

                objs.push(newObj);
            })

            await viewUtils.addSectionHrPerm(objs).then(() => {
                this.triggerNotification("Permission Added", "success");
            })

            if(this.currentEdit.item.permissions_list.view_dependencies.length){
                let data = this.currentEdit.item.permissions_list.view_dependencies.map(
                    d => {
                        return {
                            ...d,
                            tmpl_part_id: d.tmpl_part.tmpl_part_id,
                            display_values: [d.display_values]
                        }
                    }
                );
                await viewUtils.saveDisplayPermission(data).then(() => {
                    this.triggerNotification("Permission Added", "success");
                })
            }
        },
        async savePartHrPermissions(){

            let objs = [];
            this.currentEdit.item.permissions_list.hierarchyPermissions.forEach(p => {
                let newObj = {
                    tmpl_view_section_part_hr_perm_id: p.tmpl_view_section_part_hr_perm_id ?? null,
                    tmpl_view_section_part_id: this.currentEdit.item.tmpl_view_section_part_id,
                    ht_id: p.ht_id,
					permission_type: p.permission_type,
					permission_value: p.permission_value,
					permission_active: true,       
                    hierarchy_node_id: null            
                };

                [1, 2, 3, 4, 5, 6].forEach((l) => {
                    newObj["level" + l] = p["level" + l] ?? null;
                });

                objs.push(newObj);
            })

            await viewUtils.addSectionHrPerm(objs).then(() => {
                //console.log(resp);
            })

            if(this.currentEdit.item.permissions_list.view_dependencies.length){
                let data = this.currentEdit.item.permissions_list.view_dependencies.map(
                    d => {
                        return {
                            ...d,
                            tmpl_part_id: d.tmpl_part.tmpl_part_id,
                            display_values: [d.display_values]
                        }
                    }
                );
                await viewUtils.saveDisplayPermission(data).then(() => {
                    //console.log(resp);
                })
            }
        },
        async savePermissions(){
            switch(this.permissionDialog.edit_type){
                case "sectionPermissions":
                    await this.persistWorkflowPermissions();
                    await this.addSectionHrPermission();
                    break;
                case "partPermissions":
                    await this.persistWorkflowPermissions();
                    await this.savePartHrPermissions();
                    break;
            }
            this.permissionDialog.show = false;
        },
        addHierarchyPermissionRow(){
            this.currentEdit.item.permissions_list.hierarchyPermissions.push({})
        },
        save(){
            switch(this.currentEdit.edit_type){
                case "section":
                    this.saveSection();
                    break;
            }
        },
        saveSection(){
            this.$emit("saveSection", this.currentEdit.item.tmpl_view_section_id);
        },
        editParts(){
            this.$emit("configParts", this.value.item.tmpl_view_section_id);
        },
        initEvent(data){
            this.originalEdit = JSON.parse(JSON.stringify(data));
            this.currentEdit = data;
            const defaultWorkflow = this.workflowConfig?.find(x => x.isDefault);
            if(data.edit_type === "sectionPermissions" || data.edit_type === "partPermissions"){
                this.permissionDialog.expandedSection.splice(0);
                this.currentEdit.item.permissions_list.permissionControlled = this.currentEdit.item.permissions_list.permissions?.length > 0;
                this.currentEdit.item.permissions_list.hrPermissionControlled = this.currentEdit.item.permissions_list.hierarchyPermissions?.length > 0;
                if(this.currentEdit.item.permissions_list.view_dependencies.length > 0){
                    this.currentEdit.item.permissions_list.view_dependencies.forEach(d => {
                        d.tmpl_part = this.partItems.find(x => x.tmpl_part_id === d.tmpl_part_id);
                    })
                }
                if (this.currentEdit.item.permissions_list.permissionControlled && defaultWorkflow) {
                    this.currentEdit.item.workflowId = defaultWorkflow.id;
                    this.changeWorkflow(defaultWorkflow.id);
                    this.permissionDialog.expandedSection.push(0);
                }
                if (this.currentEdit.item.permissions_list.hrPermissionControlled) {
                    this.permissionDialog.expandedSection.push(1);
                }
                this.permissionDialog.title = data.item.type;
                this.permissionDialog.edit_type = data.edit_type;
                this.permissionDialog.tmpl_id = data.tmpl_id;

                this.permissionDialog.show = true;
            }   
            if(data.edit_type === "section"){
                if(defaultWorkflow && this.editingWorkflow === 0){
                    this.changeWorkflow(defaultWorkflow.id);
                } 
            }
        },
        toggle(event){
            event.cancelBubble = true;
        },
        triggerNotification(text,type){
            this.notification = {
                text:text,
                type:type
            }
        },
        hasEditPerms(status, role) {
            return this.configData.permissions
                .some(x => 
                    x.lifecycle_role_id === role.lifecycle_role_id && x.lifecycle_status_id === status.lifecycle_status_id && x.permission_active
                    && x.permission_type === 'EDIT' && x.permission_value === 'ALLOW' && !x.toDelete
                );
        },
        hasViewPerms(status, role) {
            return this.configData.permissions
                .some(x => 
                    x.lifecycle_role_id === role.lifecycle_role_id && x.lifecycle_status_id === status.lifecycle_status_id && x.permission_active
                    && x.permission_type === 'VIEW' && x.permission_value === 'ALLOW' && !x.toDelete
                );
        },
        changeWorkflow(id){
            this.loadWorkflowData(id);
            //this.$emit("changeRHS","partPermissions");
        },
        async loadWorkflowData(id){
            await viewUtils.loadWorkflowData(id).then((resp) => {
                this.editingWorkflow = id;
                let statuses = resp.data.Data.lifecycle[0].statuses.filter(x => x.lifecycle_status_id).map(x => {
                    return {
                        name: x.name,
                        colour: x.colour,
                        lifecycle_status_id: x.lifecycle_status_id
                    }
                });
                let roles = resp.data.Data.lifecycle[0].roles;
                let permissions = [];
                let actions = [];

                if(this.currentEdit.edit_type === "partPermissions" || this.currentEdit.edit_type === "sectionPermissions"){
                    permissions = this.currentEdit.item.permissions_list.permissions.filter(x => x.lifecycle_id === resp.data.Data.lifecycle[0].lifecycle_id);
                }
                if(this.currentEdit.edit_type === "section"){
                    actions =  resp.data.Data.lifecycle[0].actions;
                    //console.log(actions);
                }
                //let views = this.viewList.find(x => x.tmpl_id === tmpl);
                this.configData = {
                    statuses: statuses,
                    roles: roles,
                    permissions: permissions.map(x => {
                        return { ...x, toDelete: false };
                    }),
                    actions: actions,
                    //views: views?.views ?? [],
                    lifecycle_id: resp.data.Data.lifecycle[0].lifecycle_id,
                    //tmpl_id: tmpl
                }
            });
        },            
        cancelEdit(){
            this.$emit("cancel", this.originalEdit);
            this.currentEdit =  JSON.parse(JSON.stringify(this.originalEdit));
        },
        preview(){
            this.$emit("preview", this.currentEdit);
        }
    }
}
</script>

<style scoped lang="scss">

:global() {
    font-family: "Manrope", sans-serif !important;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;  
    color:#75838F;
} 

.v-chip-group .v-chip--active {
    background-color:rgb(0 196 255 / 47%);
    .v-chip__content{
        color: white !important;
    }
}

table {
    font-family: "Manrope", sans-serif !important;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;  
 }

$footerHeight: 60px;

.sectionAdminContent{
    width:100%; 
    padding: 10px 15px; 
    height: calc(100% - #{$footerHeight}); 
    max-height: calc(100% - #{$footerHeight});

    .iconBar {
        display: inline-flex;
        float: right;
        padding-top: 5px;
        position: absolute;
        right: 15px;
    }
}

.sectionAdminFooter {
    width:100%; 
    padding: 6px 15px;
    height: $footerHeight; 
    max-height: $footerHeight; 
    display:inline-flex;
    padding-top:10px;
}
</style>