import axios from "axios";
export default {
    
  saveDictionary(data){
    let processPromise = new Promise((resolve, reject) => {
      axios
        .post("user/saveDictionary/", data)
        .then((resp) => {
          if (resp.status === 200 || resp.status === 201) {
            resolve(data);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });

    });
    
    return processPromise;

  },
  getNlpActions(settings){
    let list = [];
    let setting = settings.find((s) => s.setting === 'NlpActions');
    if(setting !== undefined){

      let settingsObj = JSON.parse(setting.value);

      list.push({ text: "EnableDocCompare", action:"", baseAction: false, enabled: settingsObj.EnableDocCompare});
    
      settingsObj.NlpActions.forEach(s => {
        list.push({ text: s.action, action:s.endpoint, enabled: s.enabled, options: s.options});
      })

      return list;
    } else {
      return list;
    }    
  },
  getLanguageTools(user){
    let clientLevel = user.settings.find(s => s.setting === 'document_spellcheck')?.value;
    let clientSpellCheckConfig = clientLevel ? JSON.parse(clientLevel) : null;
    let clientSpellCheckEnabled = clientSpellCheckConfig && clientSpellCheckConfig.enabled;
    let userSpellCheckEnabled = user.languageOptions.spellCheck.enabled;

    let defaultOpt = {
      spellCheck: {
      enabled: false,
        options: {
          checkOnLoad: false,
          dictionary: []
        }
      }
    }

    if(clientSpellCheckEnabled){
      defaultOpt.spellCheck = {
          enabled: clientSpellCheckConfig.enabled,
          options: {
              checkOnLoad: clientSpellCheckConfig.checkOnLoad,
              dictionary: []
          },
      }
    }

    if(userSpellCheckEnabled){
      defaultOpt.spellCheck = user.languageOptions.spellCheck;
    }

    return defaultOpt;
  },
  getSpellCheckSettings(user){
    let clientLevel = user.settings.find(s => s.setting === 'document_spellcheck')?.value;
    let clientSpellCheckConfig = clientLevel ? JSON.parse(clientLevel) : null;
    let clientSpellCheckEnabled = clientSpellCheckConfig && clientSpellCheckConfig.enabled;
    let userSpellCheckEnabled = user.languageOptions.spellCheck.enabled;
    return clientSpellCheckEnabled || userSpellCheckEnabled;
  },
  showContextMenu(settings){
    
    let setting = settings.find((s) => s.setting === 'NlpActions');
    if(setting !== undefined){

      let settingsObj = JSON.parse(setting.value);

      let availableActions = settingsObj.NlpActions.some(x => x.enabled === true);

      return availableActions;

    }

    return false;
  },

  async doSpellGramCheck(text, lang) {
    return axios.post("document/spellGramCheck/", { txt: text, model: 2, lang:lang }).catch(error => {
      if (error.response) {
        // Request made and server responded
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }

      throw error;
    });
  },
  
  isNlpFunctionAvailable(data, func, p){
    let item = data.find(x => x.tp_id == p);
    if(item === undefined){
      return true;
    }
    if(item.opts.indexOf(func) > -1){
      return false;
    } else {
      return true;
    }
  },
  async doSummarise(txt, sentences){
    let processPromise = new Promise((resolve, reject) => {

      let data ={
        txt: txt,
        max_sents: sentences
      }

      axios
      .post("document/summarise/", data)
      //      .get("http://dictionary-fargate.dev.private.rolemapper.com:5020/api/nlp/spell_check",{ query: { txt:  "Test Words n Stuffz", model: 2 }})
      .then(resp => {
        resolve(resp);
      })
      .catch(error => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        reject(error);

      });
    });
    
    return processPromise;
  },
  async fetchLinkedDocData (templateType){
    let processPromise = new Promise((resolve, reject) => {
      axios
        .get("document/getLinkedTemplates/",  { params: { template:  templateType }})
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
    
    return processPromise;
  },   
  async loadSettings(){

    let processPromise = new Promise((resolve, reject) => {
      axios
      .get("client/settings/")
      .then(resp => {
        resolve(resp);
      })
      .catch(err => {
        reject(err);
      });
    });
    
    return processPromise;
  },    
  async loadDoc(id, config){

    let data = {
      ids: id,
      config: config
    }

    let processPromise = new Promise((resolve, reject) => {
      axios
        .post("file/processFileMulti", data)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });

    return processPromise;
  },
  async createDocsNew(data){

    let processPromise = new Promise((resolve, reject) => {
      axios
      .post("document/createDocMulti/", data)
      .then(resp => {
        resolve(resp);
      })
      .catch(err => {
        reject(err);
      });
    });
    
    return processPromise;
  },
  processFilesMulti(id_list, config){

    let data = {
      ids: id_list,
      config: config
    }

    let processPromise = new Promise((resolve, reject) => {
      axios
        .post("file/processFileMulti", data)
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });


    return processPromise;

  },  
  async getDocText(id, row_id){

    let processPromise = new Promise((resolve, reject) => {
      axios
        .get("file/fileText",  { params: { id:  id, row_id: row_id }})
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });


    return processPromise;
  },  
  async getFiles(){

    let processPromise = new Promise((resolve, reject) => {
      axios
        .get("file/list/")
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });


    return processPromise;
  },

  downloadFile(fileId) {
    if (!fileId)
      return;

    let possibleError = false;
    axios
      .get("file/download/", {responseType: 'text', params: { id: fileId}})
      .then(resp => {
          possibleError = true;
          if (resp.data)
            window.open(resp.data,"_blank");
          else
            alert("Access Denied");
      })
      .catch(err => {
        if (possibleError) {
            alert("Code Error");
        } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
        } else {
            alert(err.response ? err.response.data.message : err);
        }
        console.log(err);
      });
  },
  
  resolveIcon(extension){
    switch(extension){
        case "xlsx":
        case "xls":
            return "mdi-microsoft-excel";
        case "doc":
        case "docx":
        case "rtf":
            return "mdi-microsoft-word";
        case "txt":
        case "log":
            return "mdi-file-document-outline";
        case "pdf":
            return "mdi-file-pdf-box";
    }
  },
  resolveHeaderStyle(extension){
      switch(extension){
          case "xlsx":
          case "xls":
              return "#3cc542ad";
          case "doc":
          case "docx":
          case "rtf":
              return "#327bb5c7";
          case "txt":
          case "log":
              return "#ff23138f";
          case "pdf":
              return "#ff23138f";
      }
  },
  resolveSelectedBackground(extension){
    switch(extension){
          case "xlsx":
          case "xls":
              return "#3cc54229";
          case "doc":
          case "docx":
          case "rtf":
              return "#0037ff1c";
          case "txt":
          case "log":
              return "#ff23138f";
          case "pdf":
              return "#ff23138f";          
    }
  },
};