<template>
    <v-dialog v-model="display" width="800">
      <v-card>
        <v-card-title>
          User Dictionary
        </v-card-title>
        <v-card-text>

          <v-data-table
            :headers="headers"
            dense
            :items="spellCheckData.options.dictionary"
          >
            <template v-slot:[`item.word`]="{ item }">
                <div>
                    {{item.word}}
                </div>
            </template>           
            <template v-slot:[`item.actions`]="{ item }">
                <div style="display:inline-flex">
                    <v-switch dense label="Enabled?" v-model="item.active"></v-switch> &nbsp; &nbsp;
                </div>
            </template> 
          </v-data-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close">
            Close
          </v-btn>
          <v-btn color="primary" @click="save">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
      <Notification
        :notification="notification"
      >

      </Notification>
    </v-dialog>
</template>

<script>
import nlpUtils from "@/common/nlpUtils.js";
import Notification from "@/components/common/SnackBar.vue";

export default {
  name: 'UserDictionary',
  props: {
    user: Object,
    show: Boolean
  },  
  data: function() {
    return {
      user_id: "",
      spellCheckData: {
        enabled: false,
        options: {
            checkOnLoad: false,
            dictionary: []
        }
      },
      display: false,
      headers: [
        { text: "Word", align: "start", value: "word", width: '70%' },
        { text: "Actions", value: "actions", sortable: false, width: '30%' }
      ],
      notification: {
        text:"",
        type:"success"
      }
    }
  },
  components: {
    Notification
  },
  watch: {
    user(val){
        this.user_id = val.user_id;
        this.spellCheckData = val.spellCheck;
    },
    show(val){
        this.display = val;
    },
  },  
  mounted() {

  },
  created() {

  },
  computed: {

  },
  methods: { 
    close(){
        this.$emit("close", false);
    },
    triggerNotification(text,type){
        this.notification = {
            text:text,
            type:type
        }
    },  
    async save(){
        let data = {
            dictionary_user: this.user_id,
            dictionary: this.spellCheckData.options.dictionary
        }
        await nlpUtils.saveDictionary(data).then(() => {
            this.triggerNotification("User Dictionary Saved", "success");
        });
    }
  }
}
</script>
<style>
</style>