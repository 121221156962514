<template>
  <v-container>
    <!-- <v-dialog v-model="editSettings" max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          small
          color="grey lighten-1"
          v-bind="attrs"
          v-on="on"
          style="float: right; margin-top: -15px; margin-right: -15px"
        >
          mdi-cog
        </v-icon>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Panel Settings</span>
          <v-spacer></v-spacer>
          <v-icon @click="editSettings = false"> mdi-close </v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col>
                <v-select
                  :items="options.views"
                  v-model="selectedView"
                  label="View"
                  outlined
                  dense
                  @change="changeView"
                ></v-select>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>Show/Hide Metrics</v-col>
            </v-row>
            <v-row dense v-for="(m, mi) in settings.measures" :key="'m' + mi">
              <v-col>
                <v-switch
                  dense
                  v-model="m.visible"
                  inset
                  :label="m.name"
                ></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog> -->
    <v-row no-gutters class="sectionTitle mb-2">
      <h2>
        Issues
      </h2>
    </v-row>
    <v-row dense :style="{ fontWeight: 'bold', fontSize: settings.fontSize || '12px' }">
      <v-col cols="6" @click="setSort('name')" style="cursor: pointer">
        <span
          v-if="
            this.settings.sortBy && this.settings.sortBy.column === 'name'
          "
        >
          <v-icon small v-if="this.settings.sortBy.desc"
            >mdi-sort-descending</v-icon
          >
          <v-icon small v-else>mdi-sort-ascending</v-icon></span
        ></v-col>
      <v-col cols="2" @click="setSort('docCount')" style="cursor: pointer">Docs
        <span
          v-if="
            this.settings.sortBy && this.settings.sortBy.column === 'docCount'
          "
        >
          <v-icon small v-if="this.settings.sortBy.desc"
            >mdi-sort-descending</v-icon
          >
          <v-icon small v-else>mdi-sort-ascending</v-icon></span
        ></v-col>
      <v-col cols="2" @click="setSort('errorCount')" style="cursor: pointer">Errors
        <span
          v-if="
            this.settings.sortBy && this.settings.sortBy.column === 'errorCount'
          "
        >
          <v-icon small v-if="this.settings.sortBy.desc"
            >mdi-sort-descending</v-icon
          >
          <v-icon small v-else>mdi-sort-ascending</v-icon></span
        ></v-col>
      <v-col cols="2" @click="setSort('warningCount')" style="cursor: pointer">Warnings
        <span
          v-if="
            this.settings.sortBy && this.settings.sortBy.column === 'warningCount'
          "
        >
          <v-icon small v-if="this.settings.sortBy.desc"
            >mdi-sort-descending</v-icon
          >
          <v-icon small v-else>mdi-sort-ascending</v-icon></span
        ></v-col>
    </v-row>
    <perfect-scrollbar
      :style="{ height: settings.height - 157 + 'px', padding: '4px', fontSize: settings.fontSize || '12px' }"
    >
      <v-row
        no-gutters
        v-for="(i, ii) in issues"
        :key="'i' + ii"
        @click="rowSelected(i)"
        style="cursor: pointer;"
      >
        <v-col cols="6"
          >{{ i.name }}
          <v-icon v-if="filterIssue === i.name" small @click.stop="removeFilter"
            >mdi-filter-remove</v-icon
          ></v-col
        >
        <v-col cols="2">{{ i.docCount }}</v-col>
        <v-col cols="2">{{ i.errorCount }}</v-col>
        <v-col cols="2">{{ i.warningCount }}</v-col>
      </v-row>
    </perfect-scrollbar>
  </v-container>
</template>
<script>
export default {
  name: "DocumentDashboardPartIssues",
  components: {},
  props: {
    issues: Array,
    options: Object,
    settings: Object,
    filterIssue: String,
  },
  data: function () {
    return {
      response: null,
      editSettings: false,
      selectedView: null,
    };
  },
  updated() {
    this.selectedView = this.settings.name;
  },
  //   created() {
  //     this.fetchData();
  //   },
  watch: {},
  methods: {
    rowSelected(line) {
      if (this.issues.length > 1 && !this.filterIssue)
        this.$emit("rowSelected", line);
      else if (this.filterIssue) this.removeFilter();
    },
    changeView(name) {
      this.$emit("changeView", name || this.selectedView);
    },
    removeFilter() {
      this.$emit("removeFilter");
    },
    setSort(item) {
      this.$emit("changeSort", this.settings, item);
	},
  },
};
</script>
<style scoped>
.container {
  padding: 6px;
}
h2 {
  margin: 0px;
}
</style>