<template>
  <div>
    <v-toolbar dense flat underline>
      <v-toolbar-title> Settings </v-toolbar-title>
      <v-spacer />
      <v-btn icon @click="close" aria-label="Close Settings">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container>
      <v-subheader
        v-if="qaActions.length > 1"
        class="mb-0 pl-1 text-subtitle-2 font-weight-black text--primary"
        >Default Page</v-subheader
      >
      <v-row v-if="qaActions.length > 1" dense class="mx-auto pb-4">
        <v-col>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                large
                block
                v-bind="attrs"
                v-on="on"
                color="primary"
                depressed
                class="pr-2 d-flex justify-space-between"
                ref="defaultPageBtn"
              >
                <v-icon>{{ selectedRoute.meta.icon }}</v-icon>
                <span class="text-none">{{
                  selectedRoute.meta.text
                }}</span>
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                v-for="action in qaActions"
                :key="action.name"
                :class="{ 'v-list-item--active': action == selectedRoute }"
                @click="setDefaultPage(action.name)"
              >
                <v-list-item-icon>
                  <v-icon>{{ action.meta.icon.split(' ')[0] }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ action.meta.text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>

      <v-divider v-if="qaActions.length > 1 && themes.length > 1" />

      <template v-if="themes.length > 1">
        <v-subheader
          class="mb-0 pl-1 text-subtitle-2 font-weight-black text--primary"
          >Theme</v-subheader
        >
        <v-row dense>
          
          <v-col cols="6" v-for="(theme, index) in themes" :key="theme.name">
            <v-btn
              block
              large
              depressed
              :color="uiTheme == theme.name ? 'primary' : 'tertiary'"
              class="pa-3 d-flex justify-space-between"
              @click="setTheme(theme)"
            >
              <span class="text-none">
                {{ index == 0 ? "Default" : theme.display }}
              </span>
              <v-icon>{{ theme.icon }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <ResponseHandler :serviceResponse="response"></ResponseHandler>
    </v-container>
  </div>
</template>

<script>
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
import { mapState } from "vuex";

export default {
  name: "UserSettings",
  components: {
    ResponseHandler,
  },
  props: {
    open: null,
    qaActions: { type: Array, required: true },
  },
  data: function () {
    return {
      response: null,
      defaultPage: null,
    };
  },
  updated() {},
  watch: {},
  mounted() {
    this.defaultPage = this.$loginState.getUserSetting("default_page");
    setTimeout(() => {
      this.$refs.defaultPageBtn.$el.focus();
    }, 100);
  },
  computed: {
    selectedRoute: function () {
      const route = this.qaActions.find((a) => a.name == this.defaultPage);
      if (route) return route;
      else return this.qaActions[0];
    },
    ...mapState({
      newUi: (state) => state.theme.newUi,
      uiTheme: (state) => state.theme.name,
      themes: (state) => state.theme.clientThemes,
    }),
  },
  methods: {
    close() {
      this.$emit("closed");
    },
    setDefaultPage(page) {
      this.defaultPage = page;
      this.$loginState.saveUserSetting("default_page", page);
    },
    setTheme(theme) {
      this.$emit("themeChange", theme.name);
      this.$loginState.saveUserSetting("ui_theme", theme.name);
    },
  },
};
</script>
<style scoped>
</style>
