<template>
      <v-menu
        v-model="displayMenu"
        :position-x="x"
        :position-y="y"
        :close-on-content-click="false"
        absolute
        offset-y
        dense
        @mousedown="preventSelectionClear($event)"
        @mouseup="preventSelectionClear($event)"
        @click="preventSelectionClear($event)"
        @click.stop
        @keyup.prevent
      >
<v-card style="width:400px" dense>       
        <v-list dense>
          <v-list-item v-if="isFunctionAvailable('SpellCheck')">
            <v-list-item-action>
              <v-checkbox v-model="runSpellCheck"  color="primary"></v-checkbox>
            </v-list-item-action>
            <v-list-item-title>Spellcheck</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="isFunctionAvailable('Summarize')">
            <v-list-item-action>
              <v-checkbox v-model="runSummarise" color="primary"></v-checkbox>
            </v-list-item-action>
            <v-list-item-title>
              Summarise Text
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="runSummarise">
            <v-row dense>
              <v-col cols="12" dense style="display:inline-flex">
                <v-btn dense :disabled="summarise_max_sent === 0" @click="summarise_max_sent = summarise_max_sent - 1"><v-icon small>mdi-minus</v-icon></v-btn>
                <v-text-field
                  v-model="summarise_max_sent"
                  hide-details
                  disabled
                  readonly
                  single-line @mousedown="preventSelectionClear($event)"
                  @click.stop
                  @keyup.prevent
                  style="padding-top:0px"
                />              
                <v-btn dense @click="summarise_max_sent = summarise_max_sent + 1"><v-icon small>mdi-plus</v-icon></v-btn>
              </v-col>
            </v-row>
            <v-list-item-action style="display:inline-flex">


            </v-list-item-action>
            <v-list-item-title>
              Max Sentences
            </v-list-item-title>

          </v-list-item>
        </v-list> 

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            @click="closeMenu($event)"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="updateSettings($event)"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-menu>  
        
</template>

<script>
//import nlpUtils from "@/common/nlpUtils.js";

export default {
  name: 'nlpMenu',
  props: ['docData','x','y','showMenu', 'settings'],
  data: function() {
    return {
        items: [
            { title: 'style 1 title', menuAction: 'style 1' },
            { title: 'style 2 title', menuAction: action => { alert(action) } },
            { title: 'style 3 title', menuAction: action => { this.someFunc(action) } }
        ], 
        configData: [],
        nlp_annotate:false,
        displayMenu: false,
        targetPart: 0,
        nlpOptions: [],
        runSummarise: false,
        runSpellCheck:false,
        summarise_max_sent: 1
    }
  },
  watch: {
    docData: function(data) {
        this.setNlpAnnotationSections(data);
    },
    showMenu: function(data){
        this.displayMenu = data;
    },
    settings: function(data){
        this.nlpOptions = data;
    }
  },
  created() {
  },
  mounted() {

  },
  components: {
  },

  computed: {
  },  
  methods:{
    preventSelectionClear(event){
      event.stopPropagation();
      event.preventDefault();
    },
    async updateItem(){
      this.targetPart.actions = [];
      if(this.runSummarise){
        this.targetPart.actions.push({"type":"summarise","params":{max_sent:this.summarise_max_sent}})
      }
      this.$emit("updateDocPart",this.targetPart);
      this.displayMenu = false;
    },
    setNlpAnnotationSections(data){
        this.configData = JSON.parse(JSON.stringify(data));
    },
    isFunctionAvailable(fn){
      let opt = this.nlpOptions.find((s) => s.text === fn);
      if(opt === undefined){
        return false;
      } else {
        return opt.enabled;
      }
    },
    closeMenu(event){
      this.preventSelectionClear(event);
      this.resetMenu();
      this.displayMenu = false;
    },
    populateNlpSection(){
      this.document.parttypes.forEach((pt) => {
        if(pt.type.indexOf("Hdr") === -1 && pt.type.indexOf("Header") === -1 && pt.tmpl_part_metadata.tp_locked === 0){
          this.nlp_annotationSections.push({
                type:pt.type,
                tmpl_part_id:pt.tmpl_part_id
              }
            );
        }
      });
    },
    resetMenu(){
      this.nlp_annotate = false;
      this.targetPart = null;
      this.runSummarise = false;
      this.runSpellCheck =false;
      this.summarise_max_sent= 1;
    },
    updateSettings(event){
      this.preventSelectionClear(event);
      let actions = [];
      if(this.runSummarise){
        actions.push({"type":"summarise","params":{max_sent:this.summarise_max_sent}});
        this.$emit("setNlpAction",actions);
      }
      //this.resetMenu();
      this.displayMenu = false;
    }
  }
}
</script>

<style scoped>
</style>