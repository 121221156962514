<template>
  <v-container fluid style="padding-left: 0; padding-right: 0">
    <v-row dense v-if="!isReplyList && canAdd" style="padding-bottom: 10px">
      <div style="flex-basis: 35px; padding: 5px">
        <v-avatar color="primary" size="30">
          <span class="white--text">{{ userInitials() }}</span>
        </v-avatar>
      </div>
      <div style="flex-basis: calc(100% - 115px)">
        <v-textarea
          v-model="comment.text"
          auto-grow
          dense
          hide-details
          outlined
          rows="1"
          placeholder="Add your comment..."
          @input="comment.isDirty = true"
          @keypress.enter.prevent="handler('save_new')"
        ></v-textarea>
      </div>
      <div style="flex-basis: 70px; padding-left: 5px; padding-top: 5px">
        <v-btn
          small
          color="primary"
          :disabled="!comment.isDirty"
          @click="handler('save_new')"
          >Send</v-btn
        >
      </div>
    </v-row>
    <v-row dense v-for="(cm, cmi) in commentList" :key="'cm' + cmi">
      <div style="flex-basis: 35px; padding: 5px">
        <v-avatar color="primary" :size="!isReplyList ? 30 : 25">
          <span class="white--text">{{ cm.user_initials }}</span>
        </v-avatar>
      </div>
      <div style="flex-basis: calc(100% - 45px)">
        <div
          style="
            border: solid #cfd8dc 1px;
            background-color: #eceff1;
            border-radius: 5px;
            padding: 3px;
          "
        >
          <strong style="font-size: 11px">{{ cm.user_name }}</strong>
          <!-- <strong style="float: right">{{ cm.created_date }}</strong -->
          <span v-if="cm.role" class="doc-role">{{ cm.role }}</span>
          <br />
          <span>{{ cm.comment }}</span>
        </div>
        <div
          title="Reply"
          style="font-size: 12px; font-weight: bold"
          class="d-flex align-center"
        >
          <v-btn v-if="canAdd"
            small text
            @click="showReply(cm)"
            style="opacity: 0.7; font-weight: bold;"
            >Reply</v-btn
          >
          <span style="margin-left: 15px" :title="cm.created_date">{{
            cm.elapsed_time
          }}</span>
          <v-spacer></v-spacer>
          <v-btn v-if="canAdd && cm.replies.length === 0 && cm.created_by === $loginState.user.user_id"
            @click="showRemoveComment(cm)" text small
            style="opacity: 0.7; font-weight: bold;">
            Remove
          </v-btn>
        </div>
        <div
          v-if="cm.reply.show"
          dense
          style="display: flex; padding-top: 5px; padding-bottom: 10px"
        >
          <div style="flex-basis: 35px; padding: 5px">
            <v-avatar color="primary" size="25">
              <span class="white--text">{{ userInitials() }}</span>
            </v-avatar>
          </div>
          <div style="flex-basis: calc(100% - 115px)">
            <v-textarea
              ref="replyText"
              v-model="cm.reply.text"
              auto-grow
              dense
              hide-details
              outlined
              rows="1"
              placeholder="Add your reply..."
              @input="cm.reply.isDirty = true"
              @keypress.enter.prevent="handler('save_new_reply', cm)"
            ></v-textarea>
          </div>
          <div style="flex-basis: 70px; padding-left: 5px; padding-top: 5px">
            <v-btn
              small
              color="primary"
              :disabled="!cm.reply.isDirty"
              @click="handler('save_new_reply', cm)"
              >Send</v-btn
            >
          </div>
        </div>
        <DocumentComments
          v-if="cm.replies.length"
          :document="document"
          :commentList="cm.replies"
          :doc_part_id="doc_part_id"
          isReplyList
          @saved="saved"
        ></DocumentComments>
      </div>
    </v-row>
    <v-dialog
        v-model="removeComment.show"
        max-width="600px"
      >
      <v-card>
        <v-card-title class="justify-right">
          Confirm
          <v-spacer></v-spacer>
          <v-btn icon class="btn-background" @click="removeComment.show = false"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-card-text class="pb-0">
          <p>Permanently remove comment ?</p>
        </v-card-text>
        <v-card-actions class="px-6 pb-4">
          <v-btn color="primary" outlined @click="removeComment.show = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          
          <v-btn class="error" @click="handler('remove', removeComment.comment)"
            >Remove</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
// import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
import utils from "@/common/utils.js";

export default {
  name: "DocumentComments",
  components: {
    // ResponseHandler,
  },
  props: {
    document: null,
    partType: null,
    doc_part_id: null,
    commentList: null,
    isReplyList: Boolean,
  },
  data: function () {
    return {
      title: "",
      comment: {
        dp_id: null,
        text: "",
        isDirty: false,
        action: null,
      },
      response: null,
      isLoading: false,
      canAdd: false,
      utils: utils,
      removeComment: {
        comment: null,
        show: false
      }
    };
  },
  updated() {},
  created() {
    if (this.document) {
      this.handler("show");
    }
  },
  watch: {
    // commentList(newval) {
    //   if (newval?.doc_part_id !== this.dp_id) this.handler("show");
    // },
  },
  methods: {
    saved(data) {
      this.$emit("saved", data);
    },
    handler(action, item) {
      let saveRespHandler = (data) => {
        if (data) {
          if (item && item.reply?.show) {
            item.reply.text = "";
            item.reply.isDirty = false;
            item.reply.show = false;
          } else {
            this.resetComment();
          }
          this.saved(data);
        }
      };
      switch (action) {
        case "show":
          this.title = `${this.partType || "Document"} Comments`;
          this.resetComment();
          this.comment.dp_id = this.doc_part_id;
          this.canAdd = this.document.state && (this.document.state.docPartCommentAction || this.document.state.docCommentAction);
          break;
        case "save_new":
          this.saveComment(
            this.doc_part_id
              ? this.document.state.docPartCommentAction
              : this.document.state.docCommentAction,
            false,
            saveRespHandler,
            this.comment
          );
          break;
        case "save_new_reply":
          this.saveComment(
            this.doc_part_id
              ? this.document.state.docPartCommentAction
              : this.document.state.docCommentAction,
            false,
            saveRespHandler,
            item.reply
          );
          break;
        case "remove":
          this.saveComment(
            this.doc_part_id
              ? this.document.state.docPartCommentAction
              : this.document.state.docCommentAction,
            false,
            saveRespHandler,
            { ...item, remove: true }
          );
          break;
      }
    },
    resetComment() {
      this.comment.text = "";
      this.comment.isDirty = false;
    },
    saveComment(action, savedata, callback, comment) {
      if (!this.document) return;
      //   let notPermitted = this.isActionDisabled(action, true);
      //   if (notPermitted) {
      //     alert(notPermitted);
      //     return;
      //   }
      this.isLoading = true;
      let request = {
        doc_id: this.document.doc_id,
        action_available_id: action.action_available_id,
        lifecycle_role_id: action.lifecycle_role_id,
        document_role_id: action.document_role_id,
        preProcessResponse: null,
        comment: comment,
      };
      if (savedata) {
        request.preProcessResponse = this.dataCapture.value;
      }
      axios
        .post("document/processdocumentaction/", request)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            if (callback) {
              callback(resp.data.Data);
            }
          } else {
            alert(resp.data.Message);
          }
          this.isLoading = false;
          this.removeComment.show = false;
          this.response = resp.data;
          this.$store.dispatch('docs/updateCommentCount', { doc_id: this.document.doc_id, action: comment.remove ? 'remove' : 'add' })
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.removeComment.show = false;
          this.isLoading = false;
        });
    },
    userInitials() {
      return this.$loginState.user.name
        .split(" ")
        .map((x) => x.substr(0, 1))
        .join("");
    },
    showReply(cm) {
      cm.reply.show = !cm.reply.show;
      if (cm.reply.show) {
        this.$nextTick(() => {
          this.$refs.replyText[0].focus();
        });
      }
    },
    showRemoveComment(cm) {
      this.removeComment.comment = cm;
      this.removeComment.show = true;
    }
  },
};
</script>
<style scoped>
.doc-role {
  font-size: 11px;
  border: 1px solid green;
  margin-left: 10px;
  border-radius: 3px;
  padding: 0 5px;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
