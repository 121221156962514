<template>
  <v-container style="max-width: 100%">
    <v-row dense>
      <v-col>
        <v-card class="mapCard mt-2">
          <v-card-text>
            <table style="border-collapse: collapse">
              <tr>
                <td
                  v-for="(db, dbi) in dashboards.filter((d) => d.menuTitle)"
                  :colspan="db.subTitle === 'Analysis' ? '2' : '4'"
                  :key="'dbh' + dbi"
                  :style="{
                    'font-size': '30px',
                    'border-right': '1px solid lightgrey',
                    'text-align': 'center',
                  }"
                  :class="[
                    'card-title',
                    db.subTitle === 'Analysis' ? 'pr-4' : 'px-4',
                  ]"
                >
                  {{ db.menuTitle }}
                </td>
              </tr>
              <tr>
                <td
                  :colspan="db.subTitle === 'Analysis' ? '2' : '1'"
                  :style="{
                    'font-size': '18px',
                    'border-right':
                      db.subTitle === 'Analysis' || db.subTitle === 'Applicants'
                        ? '1px solid lightgrey'
                        : 'none',
                  }"
                  v-for="(db, dbi) in dashboards"
                  :key="'dbhs' + dbi"
                  :class="[
                    'card-title',
                    tab === dbi ? 'selTab' : '',
                    'pt-2',
                    db.subTitle === 'Analysis' ? 'pr-4' : 'px-2',
                  ]"
                  @click="openDashboard(dbi)"
                >
                  {{ db.subTitle }}
                </td>
              </tr>
            </table>
            <v-icon
              @click="exportToCSV"
              class="float-right"
              color="green darken-2"
              title="Download Dashboard Data"
              >mdi-cloud-download</v-icon
            >
            <v-row v-for="(db, dbi) in dashboards" :key="'db' + dbi" dense>
              <v-col cols="12" v-show="tab === dbi">
                <v-row dense>
                  <v-col
                    cols="4"
                    v-for="col in [1, 2, 3]"
                    :key="'db' + dbi + '_' + col"
                  >
                    <div
                      v-for="(ch, chi) in db.charts.filter(
                        (ch) => ch.column === col
                      )"
                      :key="'chd' + dbi + '_' + col + '_' + chi"
                    >
                      <v-progress-circular
                        :size="90"
                        :width="17"
                        color="grey"
                        indeterminate
                        v-show="!db.loaded"
                      ></v-progress-circular>
                      <v-row dense>
                        <v-col>
                          <v-breadcrumbs :items="ch.navItems" divider=">">
                            <template v-slot:item="{ item }">
                              <v-breadcrumbs-item
                                link
                                @click="clickHandler(db, item, ch, true)"
                                :disabled="item.level === ch.drillDownLevel"
                                >{{ item.text }}</v-breadcrumbs-item
                              >
                            </template>
                          </v-breadcrumbs>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col v-if="ch.type === 'map' && ch.ready">
                          <l-map
                            :ref="ch.ref"
                            :style="{
                              height: ch.height + 'px',
                              width: ch.width + 'px',
                              zIndex: 1,
                            }"
                            :options="{ zoomSnap: 0.25 }"
                          >
                            <l-tile-layer
                              :url="ch.map.url"
                              :attribution="ch.map.attribution"
                            />
                            <l-geo-json
                              v-if="ch.map.geojson"
                              :geojson="ch.map.geojson"
                              :options="ch.map.options"
                              :options-style="ch.map.optionsStyle"
                            />
                            <!-- <l-marker :lat-lng="ch.map.marker" /> -->
                          </l-map>
                        </v-col>
                        <v-col v-else>
                          <div :id="ch.ref"></div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import utils from "@/common/utils.js";
import countrygeoJSON from "../common/countryGeoJSON";
import dayJS from "dayjs";
import { latLng } from "leaflet";
// import { LMap, LTileLayer, LMarker, LGeoJson } from "vue2-leaflet";
import { LMap, LTileLayer, LGeoJson } from "vue2-leaflet";
const d3 = require("d3");
const dimple = require("../common/dimple.js");
export default {
  name: "ChartDashboard",
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
    // LMarker
  },
  props: {},
  data: function () {
    return {
      response: null,
      draw: false,
      countryLookup: [],
      tab: 0,
      hbarHeight: 21,
      colours: [
        "#80B1D3", // Blue
        "#FB8072", // Red
        "#FDB462", // Orange
        "#B3DE69", // Green
        "#FFED6F", // Yellow
        "#BC80BD", // Purple
        "#8DD3C7", // Turquoise
        "#CCEBC5", // Pale Blue
        "#FFFFB3", // Pale Yellow
        "#BEBADA", // Lavender
        "#FCCDE5", // Pink
        "#D9D9D9", // Grey],
      ],
      dimensionColours: [],
      //   mapCol: this.colours
      //     .map(c => new dimple.color(c)),
      //     .map(c => Object.assign({}, c, { region: "" })),
      regionColours: [
        // new dimple.color("#80B1D3"), // Blue
        new dimple.color("#FB8072"), // Red
        new dimple.color("#FDB462"), // Orange
        new dimple.color("#B3DE69"), // Green
        new dimple.color("#FFED6F"), // Yellow
        new dimple.color("#BC80BD"), // Purple
        new dimple.color("#8DD3C7"), // Turquoise
        new dimple.color("#CCEBC5"), // Pale Blue
        new dimple.color("#FFFFB3"), // Pale Yellow
        new dimple.color("#BEBADA"), // Lavender
        new dimple.color("#FCCDE5"), // Pink
        new dimple.color("#D9D9D9"), // Grey
      ].map((c) => Object.assign({}, c, { region: "" })),
      dashboards: [
        {
          id: 1,
          title: "Job Adverts",
          menuTitle: "Job Adverts",
          subTitle: "Analysis",
          loaded: false,
          sourceType: "url",
          sourceURL: "report/documents/",
          sourceDatasets: [
            { id: "countries", type: "country lookup" },
            {
              id: "documents",
              type: "raw",
              filter: (x) =>
                !["DELETED", "ARCHIVED", "Deleted", "Archived"].some(
                  (st) => st === x.doc_status_text
                ),
            },
          ],
          dimData: null,
          initialised: false,
          charts: [
            {
              index: 1,
              column: 1,
              colWidth: 4,
              name: "Map",
              title: "Countries",
              type: "map",
              dimension: "country",
              countryDim: "country",
              countryCode: "CountryCode",
              countryRegion: "region",
              measure: "count",
              drillDownDimensions: [{ id: "Country" }],
            },
            {
              index: 2,
              column: 1,
              colWidth: 4,
              name: "TimeView",
              title: "Documents Added",
              type: "bar",
              dimension: "created_date",
              measure: "count",
              isFilter: true,
              byMonth: true,
            },
            {
              index: 3,
              column: 2,
              colWidth: 4,
              name: "Geography",
              title: "Geography",
              type: "hbar",
              dimension: "region",
              measure: "count",
              drillDownDimensions: [{ id: "country" }],
            },
            {
              index: 4,
              column: 2,
              colWidth: 4,
              name: "Organisation",
              title: "Organisations",
              type: "hbar",
              dimension: "group",
              measure: "count",
              drillDownDimensions: [{ id: "department" }],
            },
            {
              index: 5,
              column: 3,
              colWidth: 4,
              name: "JobFamily",
              title: "Job Families",
              type: "hbar",
              dimension: "jobgroup",
              measure: "count",
              drillDownDimensions: [{ id: "jobarea" }],
            },
            {
              index: 6,
              column: 3,
              colWidth: 4,
              name: "DocumentStatus",
              title: "Document Status",
              type: "pie",
              dimension: "doc_status_text",
              measure: "count",
              isFilter: true,
            },
          ],
          measure: "count",
          def: {
            dimensions: [
              {
                source: "region",
                id: "region",
                display: "Region",
                isRegionDim: true,
                isCountryDim: false,
                ht_id: 3,
                dimensions: [],
              },
              {
                source: "country",
                id: "country",
                display: "Country",
                lookupList: "countryLookup",
                lookupSearch: "name",
                lookupValue: "code",
                lookupid: "CountryCode",
                isRegionDim: false,
                isCountryDim: true,
                ht_id: 3,
                dimensions: [],
              },
              {
                source: "doc_status_text",
                id: "doc_status_text",
                display: "Status",
                isRegionDim: false,
                isCountryDim: false,
                dimensions: [],
              },
              {
                source: "hierarchy6",
                id: "group",
                display: "Org Group",
                isRegionDim: false,
                isCountryDim: false,
                ht_id: 1,
                dimensions: [],
              },
              {
                source: "hierarchy3",
                id: "department",
                display: "Department",
                isRegionDim: false,
                isCountryDim: false,
                ht_id: 1,
                dimensions: [],
              },
              {
                source: "hierarchy3",
                id: "jobgroup",
                display: "Job Group",
                isRegionDim: false,
                isCountryDim: false,
                ht_id: 2,
                dimensions: [],
              },
              {
                source: "hierarchy2",
                id: "jobarea",
                display: "Job Area",
                isRegionDim: false,
                isCountryDim: false,
                ht_id: 2,
                dimensions: [],
              },
              {
                source: "job_created_at",
                id: "created_date",
                display: "Created Date",
                isRegionDim: false,
                isCountryDim: false,
                isDate: true,
                dimensions: [],
              },
            ],
            measures: [{ name: "count", id: "count", isCount: true }],
          },
        },
        {
          id: 2,
          title: "Headcount (Gender)",
          menuTitle: "Gender",
          subTitle: "Headcount",
          loaded: false,
          sourceType: "url",
          sourceURL: "report/category/2",
          sourceDatasets: [
            {
              id: "",
              type: "raw",
            },
          ],
          dimData: null,
          initialised: false,
          charts: [
            {
              index: 1,
              column: 1,
              colWidth: 4,
              name: "Map",
              title: "Countries",
              type: "map",
              dimension: "country",
              countryDim: "country",
              countryCode: "CountryCode",
              countryRegion: "region",
              measure: "headcount",
              drillDownDimensions: [{ id: "country" }],
            },
            {
              index: 2,
              column: 1,
              colWidth: 4,
              name: "TimeView",
              title: "Headcount by Month",
              type: "bar",
              dimension: "headcount_date",
              measure: "headcount",
              isSingleSelect: true,
              defaultSelect: -1,
              byMonth: true,
            },
            {
              index: 3,
              column: 2,
              colWidth: 4,
              name: "Geography",
              title: "Regions",
              type: "hbar",
              dimension: "region",
              subDimension: "gender",
              subAsPercent: true,
              measure: "headcount",
              drillDownDimensions: [{ id: "country" }],
            },
            {
              index: 4,
              column: 2,
              colWidth: 4,
              name: "Organisation",
              title: "Organisations",
              type: "hbar",
              dimension: "group",
              subDimension: "gender",
              subAsPercent: true,
              measure: "headcount",
              drillDownDimensions: [{ id: "department" }],
            },
            {
              index: 5,
              column: 3,
              colWidth: 4,
              name: "JobFamily",
              title: "Job Families",
              type: "hbar",
              dimension: "jobgroup",
              subDimension: "gender",
              subAsPercent: true,
              measure: "headcount",
              drillDownDimensions: [{ id: "jobtitle" }],
            },
            {
              index: 6,
              column: 3,
              colWidth: 4,
              name: "Gender",
              title: "Gender",
              type: "pie",
              dimension: "gender",
              measure: "headcount",
              isFilter: true,
            },
          ],
          def: {
            dimensions: [
              {
                source: "geo1",
                id: "region",
                display: "Region",
                isRegionDim: true,
                isCountryDim: false,
              },
              {
                source: "geo2",
                id: "country",
                display: "Country",
                lookupid: "CountryCode",
                isRegionDim: false,
                isCountryDim: true,
              },
              {
                source: "dimension",
                id: "gender",
                display: "Gender",
              },
              {
                source: "org1",
                id: "group",
                display: "Org Group",
              },
              {
                source: "org2",
                id: "department",
                display: "Department",
              },
              {
                source: "job1",
                id: "jobgroup",
                display: "Job Group",
              },
              {
                source: "job2",
                id: "jobtitle",
                display: "Job Title",
              },
              {
                source: "data_date",
                id: "headcount_date",
                display: "Headcount Date",
                isDate: true,
              },
            ],
            measures: [
              { name: "Headcount", id: "headcount", source: "measure" },
            ],
          },
        },
        {
          id: 3,
          title: "Attrition (Gender)",
          menuTitle: "",
          subTitle: "Attrition",
          loaded: false,
          sourceType: "url",
          sourceURL: "report/category/4",
          sourceDatasets: [
            {
              id: "",
              type: "raw",
            },
          ],
          dimData: null,
          initialised: false,
          charts: [
            {
              index: 1,
              column: 1,
              colWidth: 4,
              name: "Map",
              title: "Countries",
              type: "map",
              dimension: "country",
              countryDim: "country",
              countryCode: "CountryCode",
              countryRegion: "region",
              measure: "leavers",
              drillDownDimensions: [{ id: "country" }],
            },
            {
              index: 2,
              column: 1,
              colWidth: 4,
              name: "TimeView",
              title: "Attrition by Month",
              type: "bar",
              dimension: "left_date",
              measure: "leavers",
              isFilter: true,
              byMonth: true,
            },
            {
              index: 3,
              column: 2,
              colWidth: 4,
              name: "Geography",
              title: "Regions",
              type: "hbar",
              dimension: "region",
              subDimension: "gender",
              subAsPercent: true,
              measure: "leavers",
              drillDownDimensions: [{ id: "country" }],
            },
            {
              index: 4,
              column: 2,
              colWidth: 4,
              name: "Organisation",
              title: "Organisations",
              type: "hbar",
              dimension: "group",
              subDimension: "gender",
              subAsPercent: true,
              measure: "leavers",
              drillDownDimensions: [{ id: "department" }],
            },
            {
              index: 5,
              column: 3,
              colWidth: 4,
              name: "JobFamily",
              title: "Job Families",
              type: "hbar",
              dimension: "jobgroup",
              subDimension: "gender",
              subAsPercent: true,
              measure: "leavers",
              drillDownDimensions: [{ id: "jobtitle" }],
            },
            {
              index: 6,
              column: 3,
              colWidth: 4,
              name: "Gender",
              title: "Gender",
              type: "pie",
              dimension: "gender",
              measure: "leavers",
              isFilter: true,
            },
          ],
          def: {
            dimensions: [
              {
                source: "geo1",
                id: "region",
                display: "Region",
                isRegionDim: true,
                isCountryDim: false,
              },
              {
                source: "geo2",
                id: "country",
                display: "Country",
                lookupid: "CountryCode",
                isRegionDim: false,
                isCountryDim: true,
              },
              {
                source: "dimension",
                id: "gender",
                display: "Gender",
              },
              {
                source: "org1",
                id: "group",
                display: "Org Group",
              },
              {
                source: "org2",
                id: "department",
                display: "Department",
              },
              {
                source: "job1",
                id: "jobgroup",
                display: "Job Group",
              },
              {
                source: "job2",
                id: "jobtitle",
                display: "Job Title",
              },
              {
                source: "data_date",
                id: "left_date",
                display: "Left Date",
                isDate: true,
              },
            ],
            measures: [{ name: "Leavers", id: "leavers", source: "measure" }],
          },
        },
        {
          id: 4,
          title: "Hires (Gender)",
          menuTitle: "",
          subTitle: "Hires",
          loaded: false,
          sourceType: "url",
          sourceURL: "report/category/6",
          sourceDatasets: [
            {
              id: "",
              type: "raw",
            },
          ],
          dimData: null,
          initialised: false,
          charts: [
            {
              index: 1,
              column: 1,
              colWidth: 4,
              name: "Map",
              title: "Countries",
              type: "map",
              dimension: "country",
              countryDim: "country",
              countryCode: "CountryCode",
              countryRegion: "region",
              measure: "starters",
              drillDownDimensions: [{ id: "country" }],
            },
            {
              index: 2,
              column: 1,
              colWidth: 4,
              name: "TimeView",
              title: "Hires by Month",
              type: "bar",
              dimension: "start_date",
              measure: "starters",
              isFilter: true,
              byMonth: true,
            },
            {
              index: 3,
              column: 2,
              colWidth: 4,
              name: "Geography",
              title: "Regions",
              type: "hbar",
              dimension: "region",
              subDimension: "gender",
              subAsPercent: true,
              measure: "starters",
              drillDownDimensions: [{ id: "country" }],
            },
            {
              index: 4,
              column: 2,
              colWidth: 4,
              name: "Organisation",
              title: "Organisations",
              type: "hbar",
              dimension: "group",
              subDimension: "gender",
              subAsPercent: true,
              measure: "starters",
              drillDownDimensions: [{ id: "department" }],
            },
            {
              index: 5,
              column: 3,
              colWidth: 4,
              name: "JobFamily",
              title: "Job Families",
              type: "hbar",
              dimension: "jobgroup",
              subDimension: "gender",
              subAsPercent: true,
              measure: "starters",
              drillDownDimensions: [{ id: "jobtitle" }],
            },
            {
              index: 6,
              column: 3,
              colWidth: 4,
              name: "Gender",
              title: "Gender",
              type: "pie",
              dimension: "gender",
              measure: "starters",
              isFilter: true,
            },
          ],
          def: {
            dimensions: [
              {
                source: "geo1",
                id: "region",
                display: "Region",
                isRegionDim: true,
                isCountryDim: false,
              },
              {
                source: "geo2",
                id: "country",
                display: "Country",
                lookupid: "CountryCode",
                isRegionDim: false,
                isCountryDim: true,
              },
              {
                source: "dimension",
                id: "gender",
                display: "Gender",
              },
              {
                source: "org1",
                id: "group",
                display: "Org Group",
              },
              {
                source: "org2",
                id: "department",
                display: "Department",
              },
              {
                source: "job1",
                id: "jobgroup",
                display: "Job Group",
              },
              {
                source: "job2",
                id: "jobtitle",
                display: "Job Title",
              },
              {
                source: "data_date",
                id: "start_date",
                display: "Start Date",
                isDate: true,
              },
            ],
            measures: [{ name: "Hires", id: "starters", source: "measure" }],
          },
        },
        {
          id: 5,
          title: "Applicants (Gender)",
          menuTitle: "",
          subTitle: "Applicants",
          loaded: false,
          sourceType: "url",
          sourceURL: "report/category/7",
          sourceDatasets: [
            {
              id: "",
              type: "raw",
            },
          ],
          dimData: null,
          initialised: false,
          charts: [
            {
              index: 1,
              column: 1,
              colWidth: 4,
              name: "Map",
              title: "Countries",
              type: "map",
              dimension: "country",
              countryDim: "country",
              countryCode: "CountryCode",
              countryRegion: "region",
              measure: "applicants",
              drillDownDimensions: [{ id: "country" }],
            },
            {
              index: 2,
              column: 1,
              colWidth: 4,
              name: "TimeView",
              title: "Applicants by Month",
              type: "bar",
              dimension: "app_date",
              measure: "applicants",
              isFilter: true,
              byMonth: true,
            },
            {
              index: 3,
              column: 2,
              colWidth: 4,
              name: "Geography",
              title: "Regions",
              type: "hbar",
              dimension: "region",
              subDimension: "gender",
              subAsPercent: true,
              measure: "applicants",
              drillDownDimensions: [{ id: "country" }],
            },
            {
              index: 4,
              column: 2,
              colWidth: 4,
              name: "Status - Male",
              title: "Male Applicants",
              type: "funnel",
              dimension: "status",
              isSingleSelect: true,
              defaultSelect: 0,
              measure: "applicants",
              //   dataFilter: d => d.gender === "Male",
              dataFilterDimension: "gender",
              dataFilterValue: "Male",
              linkedChartGroup: 1,
            },
            {
              index: 5,
              column: 2,
              colWidth: 4,
              name: "Status - Female",
              title: "Female Applicants",
              type: "funnel",
              dimension: "status",
              isSingleSelect: true,
              defaultSelect: 0,
              measure: "applicants",
              //   dataFilter: d => d.gender === "Female",
              dataFilterDimension: "gender",
              dataFilterValue: "Female",
              linkedChartGroup: 1,
              linkedChartColourBands: [
                { lowPct: 0, highPct: 0.8, colour: "red" },
                { lowPct: 0.8001, highPct: 0.9999, colour: "#bc8b36" },
                { lowPct: 1, colour: "green" },
              ],
              linkedValueTitle: "Adverse Impact",
            },
            {
              index: 6,
              column: 3,
              colWidth: 4,
              name: "Organisation",
              title: "Organisations",
              type: "hbar",
              dimension: "group",
              subDimension: "gender",
              subAsPercent: true,
              measure: "applicants",
              drillDownDimensions: [{ id: "department" }],
            },
            {
              index: 7,
              column: 3,
              colWidth: 4,
              name: "JobFamily",
              title: "Job Families",
              type: "hbar",
              dimension: "jobgroup",
              subDimension: "gender",
              subAsPercent: true,
              measure: "applicants",
              drillDownDimensions: [{ id: "jobtitle" }],
            },
            {
              index: 8,
              column: 3,
              colWidth: 4,
              name: "Gender",
              title: "Gender",
              type: "pie",
              dimension: "gender",
              measure: "applicants",
              isFilter: true,
            },
          ],
          def: {
            dimensions: [
              {
                source: "geo1",
                id: "region",
                display: "Region",
                isRegionDim: true,
                isCountryDim: false,
              },
              {
                source: "geo2",
                id: "country",
                display: "Country",
                lookupid: "CountryCode",
                isRegionDim: false,
                isCountryDim: true,
              },
              {
                source: "dimension",
                id: "gender",
                display: "Gender",
              },
              {
                source: "org1",
                id: "group",
                display: "Org Group",
              },
              {
                source: "org2",
                id: "department",
                display: "Department",
              },
              {
                source: "job1",
                id: "jobgroup",
                display: "Job Group",
              },
              {
                source: "job2",
                id: "jobtitle",
                display: "Job Title",
              },
              {
                source: "add_value1",
                id: "status",
                display: "Application Status",
              },
              {
                source: "data_date",
                id: "app_date",
                display: "Application Date",
                isDate: true,
              },
            ],
            measures: [
              { name: "Applicants", id: "applicants", source: "measure" },
            ],
          },
        },
        {
          id: 6,
          title: "Headcount (Ethnicity - US)",
          menuTitle: "Ethnicity (US)",
          subTitle: "Headcount",
          loaded: false,
          sourceType: "url",
          sourceURL: "report/category/1",
          sourceDatasets: [
            {
              id: "",
              type: "raw",
            },
          ],
          dimData: null,
          initialised: false,
          charts: [
            {
              index: 1,
              column: 1,
              colWidth: 4,
              name: "Map",
              title: "Countries",
              type: "map",
              dimension: "country",
              countryDim: "country",
              countryCode: "CountryCode",
              countryRegion: "region",
              measure: "headcount",
              drillDownDimensions: [{ id: "country" }],
            },
            {
              index: 2,
              column: 1,
              colWidth: 4,
              name: "TimeView",
              title: "Headcount by Month",
              type: "bar",
              dimension: "headcount_date",
              measure: "headcount",
              isSingleSelect: true,
              defaultSelect: -1,
              byMonth: true,
            },
            {
              index: 3,
              column: 2,
              colWidth: 4,
              name: "Geography",
              title: "Regions",
              type: "hbar",
              dimension: "region",
              subDimension: "ethnicity",
              subAsPercent: true,
              measure: "headcount",
              drillDownDimensions: [{ id: "country" }],
            },
            {
              index: 4,
              column: 2,
              colWidth: 4,
              name: "Organisation",
              title: "Organisations",
              type: "hbar",
              dimension: "group",
              subDimension: "ethnicity",
              measure: "headcount",
              drillDownDimensions: [{ id: "department" }],
            },
            {
              index: 5,
              column: 3,
              colWidth: 4,
              name: "JobFamily",
              title: "Job Families",
              type: "hbar",
              dimension: "jobgroup",
              subDimension: "ethnicity",
              subAsPercent: true,
              measure: "headcount",
              drillDownDimensions: [{ id: "jobtitle" }],
            },
            {
              index: 6,
              column: 3,
              colWidth: 4,
              name: "Ethnicity",
              title: "Ethnicity",
              type: "pie",
              dimension: "ethnicity",
              measure: "headcount",
              isFilter: true,
            },
          ],
          def: {
            dimensions: [
              {
                source: "geo1",
                id: "region",
                display: "Region",
                isRegionDim: true,
                isCountryDim: false,
              },
              {
                source: "geo2",
                id: "country",
                display: "Country",
                lookupid: "CountryCode",
                isRegionDim: false,
                isCountryDim: true,
              },
              {
                source: "dimension",
                id: "ethnicity",
                display: "Ethnicity",
              },
              {
                sourceFunc: (d) =>
                  d.dimension === "White" ? "White" : "Ethnic",
                id: "ethnicity_cat",
                display: "Ethnicity Category",
              },
              {
                source: "org1",
                id: "group",
                display: "Org Group",
              },
              {
                source: "org2",
                id: "department",
                display: "Department",
              },
              {
                source: "job1",
                id: "jobgroup",
                display: "Job Group",
              },
              {
                source: "job2",
                id: "jobtitle",
                display: "Job Title",
              },
              {
                source: "data_date",
                id: "headcount_date",
                display: "Headcount Date",
                isDate: true,
              },
            ],
            measures: [
              { name: "Headcount", id: "headcount", source: "measure" },
            ],
          },
        },
        {
          id: 7,
          title: "Attrition (Ethnicity - US)",
          menuTitle: "",
          subTitle: "Attrition",
          loaded: false,
          sourceType: "url",
          sourceURL: "report/category/3",
          sourceDatasets: [
            {
              id: "",
              type: "raw",
            },
          ],
          dimData: null,
          initialised: false,
          charts: [
            {
              index: 1,
              column: 1,
              colWidth: 4,
              name: "Map",
              title: "Countries",
              type: "map",
              dimension: "country",
              countryDim: "country",
              countryCode: "CountryCode",
              countryRegion: "region",
              measure: "leavers",
              drillDownDimensions: [{ id: "country" }],
            },
            {
              index: 2,
              column: 1,
              colWidth: 4,
              name: "TimeView",
              title: "Attrition by Month",
              type: "bar",
              dimension: "left_date",
              measure: "leavers",
              isFilter: true,
              byMonth: true,
            },
            {
              index: 3,
              column: 2,
              colWidth: 4,
              name: "Geography",
              title: "Regions",
              type: "hbar",
              dimension: "region",
              subDimension: "ethnicity",
              subAsPercent: true,
              measure: "leavers",
              drillDownDimensions: [{ id: "country" }],
            },
            {
              index: 4,
              column: 2,
              colWidth: 4,
              name: "Organisation",
              title: "Organisations",
              type: "hbar",
              dimension: "group",
              subDimension: "ethnicity",
              subAsPercent: true,
              measure: "leavers",
              drillDownDimensions: [{ id: "department" }],
            },
            {
              index: 5,
              column: 3,
              colWidth: 4,
              name: "JobFamily",
              title: "Job Families",
              type: "hbar",
              dimension: "jobgroup",
              subDimension: "ethnicity",
              subAsPercent: true,
              measure: "leavers",
              drillDownDimensions: [{ id: "jobtitle" }],
            },
            {
              index: 6,
              column: 3,
              colWidth: 4,
              name: "Ethnicity",
              title: "Ethnicity",
              type: "pie",
              dimension: "ethnicity",
              measure: "leavers",
              isFilter: true,
            },
          ],
          def: {
            dimensions: [
              {
                source: "geo1",
                id: "region",
                display: "Region",
                isRegionDim: true,
                isCountryDim: false,
              },
              {
                source: "geo2",
                id: "country",
                display: "Country",
                lookupid: "CountryCode",
                isRegionDim: false,
                isCountryDim: true,
              },
              {
                source: "dimension",
                id: "ethnicity",
                display: "Ethnicity",
              },
              {
                sourceFunc: (d) =>
                  d.dimension === "White" ? "White" : "Ethnic",
                id: "ethnicity_cat",
                display: "Ethnicity Category",
              },
              {
                source: "org1",
                id: "group",
                display: "Org Group",
              },
              {
                source: "org2",
                id: "department",
                display: "Department",
              },
              {
                source: "job1",
                id: "jobgroup",
                display: "Job Group",
              },
              {
                source: "job2",
                id: "jobtitle",
                display: "Job Title",
              },
              {
                source: "data_date",
                id: "left_date",
                display: "Leaving Date",
                isDate: true,
              },
            ],
            measures: [{ name: "Leavers", id: "leavers", source: "measure" }],
          },
        },
        {
          id: 8,
          title: "Hires (Ethnicity - US)",
          menuTitle: "",
          subTitle: "Hires",
          loaded: false,
          sourceType: "url",
          sourceURL: "report/category/5",
          sourceDatasets: [
            {
              id: "",
              type: "raw",
            },
          ],
          dimData: null,
          initialised: false,
          charts: [
            {
              index: 1,
              column: 1,
              colWidth: 4,
              name: "Map",
              title: "Countries",
              type: "map",
              dimension: "country",
              countryDim: "country",
              countryCode: "CountryCode",
              countryRegion: "region",
              measure: "starters",
              drillDownDimensions: [{ id: "country" }],
            },
            {
              index: 2,
              column: 1,
              colWidth: 4,
              name: "TimeView",
              title: "Hires by Month",
              type: "bar",
              dimension: "start_date",
              measure: "starters",
              isFilter: true,
              byMonth: true,
            },
            {
              index: 3,
              column: 2,
              colWidth: 4,
              name: "Geography",
              title: "Regions",
              type: "hbar",
              dimension: "region",
              subDimension: "ethnicity",
              subAsPercent: true,
              measure: "starters",
              drillDownDimensions: [{ id: "country" }],
            },
            {
              index: 4,
              column: 2,
              colWidth: 4,
              name: "Organisation",
              title: "Organisations",
              type: "hbar",
              dimension: "group",
              subDimension: "ethnicity",
              subAsPercent: true,
              measure: "starters",
              drillDownDimensions: [{ id: "department" }],
            },
            {
              index: 5,
              column: 3,
              colWidth: 4,
              name: "JobFamily",
              title: "Job Families",
              type: "hbar",
              dimension: "jobgroup",
              subDimension: "ethnicity",
              subAsPercent: true,
              measure: "starters",
              drillDownDimensions: [{ id: "jobtitle" }],
            },
            {
              index: 6,
              column: 3,
              colWidth: 4,
              name: "Ethnicity",
              title: "Ethnicity",
              type: "pie",
              dimension: "ethnicity",
              measure: "starters",
              isFilter: true,
            },
          ],
          def: {
            dimensions: [
              {
                source: "geo1",
                id: "region",
                display: "Region",
                isRegionDim: true,
                isCountryDim: false,
              },
              {
                source: "geo2",
                id: "country",
                display: "Country",
                lookupid: "CountryCode",
                isRegionDim: false,
                isCountryDim: true,
              },
              {
                source: "dimension",
                id: "ethnicity",
                display: "Ethnicity",
              },
              {
                sourceFunc: (d) =>
                  d.dimension === "White" ? "White" : "Ethnic",
                id: "ethnicity_cat",
                display: "Ethnicity Category",
              },
              {
                source: "org1",
                id: "group",
                display: "Org Group",
              },
              {
                source: "org2",
                id: "department",
                display: "Department",
              },
              {
                source: "job1",
                id: "jobgroup",
                display: "Job Group",
              },
              {
                source: "job2",
                id: "jobtitle",
                display: "Job Title",
              },
              {
                source: "data_date",
                id: "start_date",
                display: "Start Date",
                isDate: true,
              },
            ],
            measures: [{ name: "Starters", id: "starters", source: "measure" }],
          },
        },
        {
          id: 8,
          title: "Applicants (Ethnicity - US)",
          menuTitle: "",
          subTitle: "Applicants",
          loaded: false,
          sourceType: "url",
          sourceURL: "report/category/8",
          sourceDatasets: [
            {
              id: "",
              type: "raw",
            },
          ],
          dimData: null,
          initialised: false,
          charts: [
            {
              index: 1,
              column: 1,
              colWidth: 4,
              name: "Map",
              title: "Countries",
              type: "map",
              dimension: "country",
              countryDim: "country",
              countryCode: "CountryCode",
              countryRegion: "region",
              measure: "applicants",
              drillDownDimensions: [{ id: "country" }],
            },
            {
              index: 2,
              column: 1,
              colWidth: 4,
              name: "TimeView",
              title: "Applicants by Month",
              type: "bar",
              dimension: "app_date",
              measure: "applicants",
              isFilter: true,
              byMonth: true,
            },
            {
              index: 3,
              column: 2,
              colWidth: 4,
              name: "Geography",
              title: "Regions",
              type: "hbar",
              dimension: "region",
              subDimension: "ethnicity_cat",
              subAsPercent: true,
              measure: "applicants",
              drillDownDimensions: [{ id: "country" }],
            },
            {
              index: 3,
              column: 2,
              colWidth: 4,
              name: "Status",
              title: "White Applicants",
              type: "funnel",
              dimension: "status",
              isSingleSelect: true,
              defaultSelect: 0,
              measure: "applicants",
              //   dataFilter: d => d.ethnicity === "White",
              dataFilterDimension: "ethnicity_cat",
              dataFilterValue: "White",
              linkedChartGroup: 1,
            },
            {
              index: 5,
              column: 2,
              colWidth: 4,
              name: "Status - Male",
              title: "Ethnic Applicants",
              type: "funnel",
              dimension: "status",
              isSingleSelect: true,
              defaultSelect: 0,
              measure: "applicants",
              //   dataFilter: d => d.ethnicity !== "White",
              dataFilterDimension: "ethnicity_cat",
              dataFilterValue: "Ethnic",
              //   dataFilterNotValue: "White",
              linkedChartGroup: 1,
              linkedChartColourBands: [
                { lowPct: 0, highPct: 0.8, colour: "red" },
                { lowPct: 0.8001, highPct: 0.9999, colour: "#bc8b36" },
                { lowPct: 1, colour: "green" },
              ],
              linkedValueTitle: "Adverse Impact",
            },
            {
              index: 6,
              column: 3,
              colWidth: 4,
              name: "Organisation",
              title: "Organisations",
              type: "hbar",
              dimension: "group",
              subDimension: "ethnicity_cat",
              subAsPercent: true,
              measure: "applicants",
              drillDownDimensions: [{ id: "department" }],
            },
            {
              index: 7,
              column: 3,
              colWidth: 4,
              name: "JobFamily",
              title: "Job Families",
              type: "hbar",
              dimension: "jobgroup",
              subDimension: "ethnicity_cat",
              subAsPercent: true,
              measure: "applicants",
              drillDownDimensions: [{ id: "jobtitle" }],
            },
            {
              index: 8,
              column: 3,
              colWidth: 4,
              name: "Ethnicity",
              title: "Ethnicity Category",
              type: "pie",
              dimension: "ethnicity_cat",
              measure: "applicants",
              isFilter: true,
            },
          ],
          def: {
            dimensions: [
              {
                source: "geo1",
                id: "region",
                display: "Region",
                isRegionDim: true,
                isCountryDim: false,
              },
              {
                source: "geo2",
                id: "country",
                display: "Country",
                lookupid: "CountryCode",
                isRegionDim: false,
                isCountryDim: true,
              },
              {
                source: "dimension",
                id: "ethnicity",
                display: "Ethnicity",
              },
              {
                sourceFunc: (d) =>
                  d.dimension === "White" ? "White" : "Ethnic",
                id: "ethnicity_cat",
                display: "Ethnicity Category",
              },
              {
                source: "org1",
                id: "group",
                display: "Org Group",
              },
              {
                source: "org2",
                id: "department",
                display: "Department",
              },
              {
                source: "job1",
                id: "jobgroup",
                display: "Job Group",
              },
              {
                source: "job2",
                id: "jobtitle",
                display: "Job Title",
              },
              {
                source: "add_value1",
                id: "status",
                display: "Status",
              },
              {
                source: "data_date",
                id: "app_date",
                display: "Application Date",
                isDate: true,
              },
            ],
            measures: [
              { name: "Applicants", id: "applicants", source: "measure" },
            ],
          },
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.fetchData();
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  updated() {
    this.$nextTick(() => {
      this.drawDashboards();
    });
  },
  computed: {},
  methods: {
    fetchData() {
      this.prepareDashboard(this.dashboards[0]);
    },
    openDashboard(dbi) {
      this.tab = dbi;
      this.prepareDashboard(this.dashboards[dbi]);
    },
    prepareDashboard(db) {
      if (db.loaded) return;
      let possibleError = false;
      axios
        .get(db.sourceURL)
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            db.sourceDatasets.forEach((ds) => {
              let data = ds.id ? resp.data.Data[ds.id] : resp.data.Data;
              if (data.length) {
                if (ds.filter) {
                  data = data.filter(ds.filter);
                }
                if (ds.type === "raw") {
                  //   if (dbi === 0)
                  if(data.length) {
                    if(data[0].hierarchies){
                      if(data[0].hierarchies.ht_id !== 1) {
                        //remaps dimension hierarchies for non-base clients
                        let hierarchies = data[0].hierarchies
                        let baseHierarchies = [1,3,2]
                        baseHierarchies.forEach((bh,i) => {
                          db.def.dimensions.filter(d => d.ht_id === bh).forEach(ht => {ht.ht_id = hierarchies[i].ht_id})
                        })
                      }
                    }
                  }
                  if(data[0].client_name === 'Elsevier') {
                    db.def.dimensions.filter(d => d.source === 'hierarchy6').forEach(h6 => {
                      h6.source = 'hierarchy3'
                    })
                  }

                  this.initialiseDashboard(db, data);
                } else if (ds.type === "country lookup") {
                  this.countryLookup = data;
                }
              }
            });
            db.loaded = true;
          }
          this.response = resp.data;
          //console.log(resp);
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
    prepareRowData(rows, definition) {
      let measures = definition.measures;
      let countryLookup = this.countryLookup;
      let processDims = function (dimensions, dataRow, row) {
        let outRow = row || {};
        dimensions.forEach((d) => {
          if (d.ht_id) {
            let htv = dataRow.hierarchies.find((h) => h.ht_id === d.ht_id);
            outRow[d.id] = htv ? htv[d.source] : "";
          } else if (d.sourceFunc) {
            outRow[d.id] = d.sourceFunc(dataRow);
          } else {
            outRow[d.id] = dataRow[d.source];
          }
          if (!outRow[d.id]) outRow[d.id] = "UNKNOWN " + d.id;
          outRow[d.id] = outRow[d.id].trim();
          if (d.isDate) {
            d[d.id] = dayJS(d[d.id]);
          } else if (d.lookupList) {
            let list =
              d.lookupList === "countryLookup" ? countryLookup : d.lookupList;
            let val = list.find((l) => l[d.lookupSearch] === outRow[d.id]);
            outRow[d.lookupid] = val ? val[d.lookupValue] : null;
          } else if (d.lookupid) {
            outRow[d.lookupid] = dataRow[d.lookupid];
          }
          if (!d.Domain) d.Domain = [];
          if (!d.Domain.some((v) => v.key === outRow[d.id])) {
            d.Domain.push({ key: outRow[d.id], text: outRow[d.id] });
          }
          measures.forEach((m) => {
            outRow[m.id] = m.isCount ? 1 : parseInt(dataRow[m.source]);
          });
          if (d.dimensions && d.dimensions.length) {
            processDims(d.dimensions, dataRow, outRow);
          }
        });
        return outRow;
      };
      return rows.map((r) => {
        return processDims(definition.dimensions, r);
      });
    },
    filterForSelection(dashboard, chart) {
      let data = dashboard.rawData;
      dashboard.charts.forEach((c) => {
        if (c.selected && c.selected.length) {
          if (
            !chart ||
            ((chart.isFilter || chart.isSingleSelect) &&
              chart.name !== c.name &&
              (!chart.linkedChartGroup ||
                chart.linkedChartGroup !== c.linkedChartGroup))
          ) {
            let dimDef = dashboard.def.dimensions.find(
              (dd) => dd.id === c.dimension
            );
            if (dimDef.isDate && c.byMonth) {
              data = data.filter((d) =>
                c.selected.some(
                  (s) => s === dayJS(d[c.dimension]).format("YYYY MM")
                )
              );
            } else {
              data = data.filter((d) =>
                c.selected.some((s) => s === d[c.dimension])
              );
            }
          }
        }
        if (c.drillDownDimensions && c.drillDownDimensions.length) {
          c.drillDownDimensions
            .filter((x) => x.selected)
            .forEach((x) => {
              let dimDef = dashboard.def.dimensions.find(
                (dd) => dd.id === x.id
              );
              if (dimDef.isDate && c.byMonth) {
                data = data.filter(
                  (d) => x.selected === dayJS(d[x.id]).format("YYYY MM")
                );
              } else {
                data = data.filter((d) => x.selected === d[x.id]);
              }
            });
        }
      });
      return data;
    },
    getFilterClickFunction(chartType, level, verticalBar) {
      let self = this;
      return function (elem) {
        self[level + chartType + "Filter"] = verticalBar
          ? elem.xValue
          : elem.yValue;
        self["draw" + chartType + "Charts"]();
      };
    },
    initialiseChartDef(chart, dbid) {
      let w = document.body.clientWidth * 0.9;
      chart = Object.assign({}, chart, {
        ref:
          chart.measure +
          chart.dimension +
          chart.type +
          dbid +
          "_" +
          chart.index,
        element:
          "#" +
          chart.measure +
          chart.dimension +
          chart.type +
          dbid +
          "_" +
          chart.index,
        width: (w * chart.colWidth) / 12,
        height: 0,
        measureTitle: "Items",
        Chart: null,
        Bars: null,
        selected: [],
        navItems: [],
        drillDownLevel: 0,
      });
      chart.drillDownDimensions = chart.drillDownDimensions
        ? chart.drillDownDimensions.map((d) =>
            Object.assign(d, { selected: "" })
          )
        : [];
      chart.navItems.push({
        text: "All " + chart.title,
        level: 0,
        domain: [],
      });
      if (chart.type === "pie") {
        chart.height = 200; //chart.width;
      }
      if (chart.type === "bar") {
        chart.height = chart.width / 2;
      }
      if (chart.type === "map") {
        chart.height = chart.width / 1.5;
        chart.ready = false;
        chart.map = {
          geojson: { type: "FeatureCollection", features: [] }, // geo,
          fillColor: "#e4ce7f",
          //   url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
          url:
            "http://{s}.basemaps.cartocdn.com/rastertiles/voyager_no_labels_no_buildings/{z}/{x}/{y}.png",
          attribution:
            '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          marker: latLng(47.41322, -1.219482),
          options: {
            onEachFeature(feature, layer) {
              layer.bindTooltip(
                `<div>${feature.properties.name} (${
                  feature[chart.countryRegion]
                }): ${feature[chart.measure]}</div>`,
                { permanent: false, sticky: true }
              );
            },
          },
          optionsStyle: (feature) => {
            let colour = this.regionColours.find(
              (r) => r.region === feature[chart.countryRegion]
            );
            if (!colour) {
              colour = this.regionColours.find((r) => r.region === "");
              colour.region = feature[chart.countryRegion];
            }
            return {
              weight: 1,
              color: "gray",
              opacity: 1,
              fillColor: colour.fill,
              fillOpacity: 0.6,
            };
          },
        };
      }
      return chart;
    },
    drawDashboards() {
      let tab = this.tab;
      this.dashboards.forEach((d, di) => {
        if (!d.initialised && di === tab && d.loaded) {
          this.drawDashboard(d);
          d.initialised = true;
        } else if (di === tab) {
          d.charts
            .filter((c) => c.type === "map")
            .forEach((c) => {
              this.fitMap(c);
            });
        }
      });
    },
    drawDashboard(dashboard) {
      let data = this.filterForSelection(dashboard);
      dashboard.charts
        .filter((c) => c.isSingleSelect)
        .forEach((c) => {
          this.setChartData(dashboard, c, data);
        });
      data = this.filterForSelection(dashboard);
      dashboard.charts
        .filter((c) => !c.isSingleSelect)
        .forEach((c) => {
          this.setChartData(dashboard, c, data);
        });
    },
    initialiseDashboard(dashboard, rawdata) {
      let preppedData = this.prepareRowData(rawdata, dashboard.def);
      dashboard.rawData = preppedData;

      dashboard.charts.forEach((c, i) => {
        c = this.initialiseChartDef(c, dashboard.id);
        this.$set(dashboard.charts, i, c);
      });

      // let hHeight = sHeight - aHeight - 70,
      //     xaxis = this.SplitYear ? "YearWeek" : "Week";
      // if (hHeight < 150) hHeight = 250;
      // def = {
      //     element: "#HistorySpendChart",
      //     chartname: "history",
      //     width: hWidth,
      //     height: hHeight,
      //     xAxis: xaxis,
      //     xAxisTitle: "Week",
      //     yAxisTitle: "Sales",
      //     Measures: [
      //         { Property: "ActualThisYear", Title: "Actual This Year" },
      //         { Property: "ActualLastYear", Title: "Actual Last Year" },
      //         { Property: "ForecastCost", Title: "Forecast Cost" },
      //         { Property: "PlannedCost", Title: "Planned Cost" },
      //         { Property: "Prediction", Title: "Predicted Cost" }]
      // };

      // chartHelper.drawMultiSeriesChart(def, data.dataset);
    },
    drawChart(def, dashboard, data, dimension) {
      switch (def.type) {
        case "hbar": {
          def.showBarLabels = true;
          this.drawHorizontalBarChart(def, dashboard, data, dimension);
          break;
        }
        case "bar": {
          def.showBarLabels = true;
          this.drawVerticalBarChart(def, dashboard, data, dimension);
          break;
        }
        case "pie":
          //   def.height = def.width;
          this.drawPieChart(def, dashboard, data, dimension);
          break;
        case "map":
          this.drawMap(def, dashboard);
          break;
        case "funnel": {
          def.showBarLabels = true;
          this.drawHorizontalFunnelChart(def, dashboard, data, dimension);
          break;
        }
      }
    },
    clickHandler(dashboard, item, def, isUp) {
      if (def.isSingleSelect && isUp) {
        return;
      } else if (def.isFilter || def.isSingleSelect) {
        this.doFilter(dashboard, item, def, isUp);
      } else if (def.drillDownDimensions && def.drillDownDimensions.length) {
        if (isUp) {
          this.doDrillUp(dashboard, item, def);
        } else {
          this.doDrillDown(dashboard, item, def);
        }
      }
    },
    doFilter(dashboard, item, def, isUp) {
      let redraw = false;
      let selIndex;
      if (isUp) {
        if (
          !def.isSingleSelect &&
          (item.level === -1 || def.selected.length === 1)
        ) {
          def.selected.splice(0);
          def.navItems
            .filter((n) => n.svgElement)
            .forEach((n) => chartHelper.elementSelect(n.svgElement, false));
          def.navItems.splice(0);
          def.navItems.push({ text: def.title + " - ALL", level: -1 });
        } else {
          selIndex = def.selected.findIndex((x) => x === item.text);
          def.selected.splice(selIndex, 1);
          const selIndex2 = def.navItems.findIndex((x) => x.text === item.text);
          chartHelper.elementSelect(def.navItems[selIndex2].svgElement, false);
          def.navItems.splice(selIndex, 1);
        }
      } else {
        let value;
        switch (def.type) {
          case "hbar":
          case "funnel":
            value = item.yValue;
            break;
          case "bar":
            value = item.xValue;
            break;
          default:
            value = item.seriesValue[0];
        }
        if (def.isSingleSelect) {
          dashboard.charts
            .filter((c) =>
              def.linkedChartGroup
                ? c.linkedChartGroup === def.linkedChartGroup
                : c.index === def.index
            )
            .forEach((c) => {
              c.selected.splice(0);
              c.selected.push(value);
              c.navItems.forEach((n) =>
                chartHelper.elementSelect(n.svgElement, false)
              );
              this.processSelectedValues(
                c,
                c.index === def.index ? item.selectedShape : null
              );
              chartHelper.elementSelect(item.selectedShape, true);
            });
        } else {
          selIndex = def.selected.findIndex((x) => x === value);
          if (selIndex < 0) {
            def.selected.push(value);
          } else {
            def.selected.splice(selIndex, 1);
          }
          this.processSelectedValues(def, item.selectedShape);
          chartHelper.elementSelect(item.selectedShape, selIndex < 0);
        }
      }
      let fullFilter = this.filterForSelection(dashboard);

      if (redraw) {
        this.setChartData(
          dashboard,
          def,
          this.filterForSelection(dashboard, def)
        );
      }
      dashboard.charts
        .filter((c) => c.name !== def.name)
        .forEach((c) => {
          if (c.type !== "map") {
            this.setChartData(
              dashboard,
              c,
              c.isFilter || c.isSingleSelect
                ? this.filterForSelection(dashboard, c)
                : fullFilter,
              true
            );
          } else {
            this.drawMap(c, dashboard);
          }
        });
    },
    processSelectedValues(chart, element) {
      chart.navItems.splice(0);
      if (chart.selected && chart.selected.length) {
        chart.navItems.push({ text: chart.title, level: -1 });
        chart.selected.forEach((s) => {
          chart.navItems.push({
            text: s,
            level: -2,
            svgElement: element || null,
          });
        });
      } else {
        chart.navItems.push({ text: chart.title + " - ALL", level: -1 });
      }
    },
    deriveDownDimension(chart) {
      return chart.drillDownLevel >= chart.drillDownDimensions.length
        ? chart.drillDownDimensions[chart.drillDownDimensions.length - 1].id
        : chart.drillDownDimensions[chart.drillDownLevel].id;
    },
    deriveCurrentDimension(chart) {
      let ddLevel = chart.drillDownLevel > 0 ? chart.drillDownLevel : 0;
      if (ddLevel > chart.drillDownDimensions.length)
        ddLevel = chart.drillDownDimensions.length;
      return ddLevel > 0
        ? chart.drillDownDimensions[ddLevel - 1].id
        : chart.dimension;
    },
    setChartData(
      dashboard,
      chart,
      data,
      redrawCurrentDimension,
      drillDownValue,
      drillUpLevel
    ) {
      let dimension = chart.dimension;
      if (redrawCurrentDimension)
        dimension = this.deriveCurrentDimension(chart);
      if (drillDownValue) dimension = this.deriveDownDimension(chart);
      if (drillUpLevel >= 0)
        dimension =
          drillUpLevel === 0
            ? chart.dimension
            : chart.drillDownDimensions[drillUpLevel - 1].id;

      let dimDef = dashboard.def.dimensions.find((x) => x.id === dimension);
      if (chart.dataFilter && typeof chart.dataFilter === "function") {
        data = JSON.parse(JSON.stringify(data)).filter(chart.dataFilter);
      } else if (chart.dataFilterDimension && chart.dataFilterValue) {
        data = JSON.parse(JSON.stringify(data)).filter(
          (d) => d[chart.dataFilterDimension] === chart.dataFilterValue
        );
      } else if (chart.dataFilterDimension && chart.dataFilterNotValue) {
        data = JSON.parse(JSON.stringify(data)).filter(
          (d) => d[chart.dataFilterDimension] !== chart.dataFilterNotValue
        );
      }
      let d;
      if (!chart.subDimension) {
        d = chartHelper.groupBy(
          data,
          dimension,
          chart.measure,
          dimDef.isDate && chart.byMonth
        );
      } else {
        d = chartHelper.groupByKeys(
          data,
          [dimension, chart.subDimension],
          [chart.measure],
          dimDef.isDate && chart.byMonth
        );
      }
      chart.maxValue = d.reduce((p, c) => {
        return c[chart.measure] > p ? c[chart.measure] : p;
      }, 0);
      chart.seriesLength = d.length;

      if (chart.type === "funnel") {
        d = d.sort((a, b) =>
          a[dimension] > b[dimension] ? 1 : a[dimension] < b[dimension] ? -1 : 0
        );
        let prev = null;
        d.forEach((v) => {
          v.labelPct =
            prev === null ? null : prev === 0 ? 0 : v[chart.measure] / prev;
          v.labelText =
            prev === null ? v[chart.measure] : d3.format(".1%")(v.labelPct);
          prev = v[chart.measure];
        });
      }

      if (redrawCurrentDimension) {
        d3.select(chart.element).selectAll(".barLabel").remove();
        let redraw = d.every((x) =>
          chart.Chart.data.some((s) => s[dimension] === x[dimension])
        );
        if (redraw) {
          chart.Chart.data.forEach((d, di) => {
            d[chart.measure] = 0;
            if (d.labelText) d.labelText = di === 0 ? "0" : "0%";
            if (d.labelPct) d.labelPct = di === 0 ? null : 0;
          });
          d.forEach((x) => {
            let v = chart.Chart.data.find(
              (cd) =>
                cd[dimension] === x[dimension] &&
                (!chart.subDimension ||
                  cd[chart.subDimension] === x[chart.subDimension])
            );
            if (v) {
              v[chart.measure] = x[chart.measure];
              if (v.labelText) {
                v.labelText = x.labelText;
                v.labelPct = x.labelPct;
              }
            } else {
              chart.Chart.data.push(x);
              chart.Chart.height = chart.Chart.data * this.hbarHeight + 55;
            }
          });
          chart.Chart.draw(1000);
        } else {
          this.drawChart(chart, dashboard, d, dimension);
        }
      } else if (drillDownValue) {
        chart.drillDownLevel++;
        chart.height = 0;
        chart.navItems.push({
          text: drillDownValue,
          level: chart.drillDownLevel,
          domain: d.map((x) => [
            x[dimension],
            chart.subDimension ? x[chart.subDimension] : null,
          ]),
        });
        this.drawChart(chart, dashboard, d, dimension);
      } else if (drillUpLevel >= 0) {
        chart.drillDownLevel = drillUpLevel;
        chart.height = 0;
        let domain = chart.navItems[drillUpLevel].domain.map((x) => {
          //   let r = { id: x };
          let r = {};
          r[dimension] = x[0];
          if (chart.subDimension) r[chart.subDimension] = x[1];
          r[chart.measure] = 0;
          return r;
        });
        domain.forEach((x) => {
          let val = d.find(
            (v) =>
              v[dimension] === x[dimension] &&
              (!chart.subDimension ||
                v[chart.subDimension] === x[chart.subDimension])
          );
          if (val) x[chart.measure] = val[chart.measure];
        });

        this.drawChart(chart, dashboard, domain, dimension);
      } else {
        chart.navItems[0].domain = d.map((x) => [
          x[dimension],
          chart.subDimension ? x[chart.subDimension] : null,
        ]);
        if (chart.isSingleSelect && chart.navItems.length <= 1) {
          let index =
            chart.defaultSelect < 0
              ? chart.navItems[0].domain.length + chart.defaultSelect
              : chart.defaultSelect;
          let value = chart.navItems[0].domain.sort()[index][0];
          chart.selected.push(value);
          this.processSelectedValues(chart);
          //   chart.navItems.splice(0);
          //   chart.navItems.push({ text: chart.title, level: -1 });
          //   chart.navItems.push({ text: value, level: -2, svgElement: null });
        }
        this.drawChart(chart, dashboard, d);
      }
    },
    doDrillDown(dashboard, item, def) {
      const value = def.type === "hbar" ? item.yValue : item.seriesValue[0];
      if (def.drillDownLevel === 0) {
        def.selected.length = 0;
        def.selected.push(value);
      } else {
        def.drillDownDimensions[def.drillDownLevel - 1].selected = value;
      }
      let filtered = this.filterForSelection(dashboard);

      dashboard.charts
        .filter((c) => c.name !== def.name)
        .forEach((c) => {
          if (c.type !== "map") {
            this.setChartData(
              dashboard,
              c,
              c.isFilter || c.isSingleSelect
                ? this.filterForSelection(dashboard, c)
                : filtered,
              true
            );
          } else {
            this.drawMap(c, dashboard);
          }
        });
      //   def.data.length = 0;
      this.setChartData(dashboard, def, filtered, false, value);
    },
    doDrillUp(dashboard, item, def) {
      //   const value = item.text;
      if (item.level === 0) {
        def.selected.length = 0;
      }
      def.drillDownDimensions.forEach((dd, ddi) => {
        if (ddi >= item.level - 1) {
          dd.selected = "";
        }
      });

      let filtered = this.filterForSelection(dashboard);

      def.navItems.splice(item.level + 1);

      dashboard.charts
        .filter((c) => c.name !== def.name)
        .forEach((c) => {
          if (c.type !== "map") {
            this.setChartData(
              dashboard,
              c,
              c.isFilter || c.isSingleSelect
                ? this.filterForSelection(dashboard, c)
                : filtered,
              true
            );
          } else {
            this.drawMap(c, dashboard);
          }
        });
      //   def.data.length = 0;
      this.setChartData(dashboard, def, filtered, false, null, item.level);
    },
    assignChartColours(chart) {
      // keep colours consistent across all charts/dashboards
      let getColour = (d, v) => {
        let col = this.dimensionColours.find(
          (dc) => dc.dimension === d && dc.value === v
        );
        if (!col) {
          let index = this.dimensionColours.filter((x) => x.dimension === d)
            .length;
          col = { dimension: d, value: v, colour: this.colours[index] };
          this.dimensionColours.push(col);
        }
        return col.colour;
      };
      if (chart.subDimension || chart.type === "pie") {
        let dim = chart.subDimension || chart.dimension;
        let processed = [];
        chart.Chart.data.forEach((cd) => {
          let value = cd[dim];
          if (processed.indexOf(value) < 0) {
            processed.push(value);
            chart.Chart.assignColor(value, getColour(dim, value));
          }
        });
      } else {
        if (chart.dataFilterDimension && chart.dataFilterValue) {
          chart.Chart.defaultColors = [
            new dimple.color(
              getColour(chart.dataFilterDimension, chart.dataFilterValue)
            ),
          ];
        } else if (chart.dataFilterDimension && chart.dataFilterNotValue) {
          chart.Chart.defaultColors = [
            new dimple.color(
              getColour(
                chart.dataFilterDimension,
                "!" + chart.dataFilterNotValue
              )
            ),
          ];
        }
      }
    },
    drawVerticalBarChart(def, dashboard, data, dimension) {
      let vm = this;
      d3.select(def.element).selectAll("*").remove();
      let svg = dimple.newSvg(def.element, def.width, def.height),
        aChart = new dimple.chart(svg, data),
        axis = def.subAsPercent
          ? aChart.addPctAxis("y", def.measure)
          : aChart.addMeasureAxis("y", def.measure);

      def.Chart = aChart;
      axis.title = def.measureTitle;
      axis.ticks = 5;
      axis.title = "";
      //   axis = aChart.addCategoryAxis("x", "id");
      //   axis.addOrderRule(def.sortProperty ? def.sortProperty : "id");
      axis = aChart.addCategoryAxis("x", dimension || def.dimension);
      axis.addOrderRule(
        def.sortProperty ? def.sortProperty : dimension || def.dimension
      );
      axis.title = ""; //def.xAxisTitle;
      def.Bars = aChart.addSeries(def.subDimension || null, dimple.plot.bar);

      this.assignChartColours(def);

      if (def.showBarLabels)
        def.Bars.afterDraw = chartHelper.verticalBarLabels(svg, def);
      def.Bars.addEventHandler("click", function (ev) {
        vm.clickHandler(dashboard, ev, def, false);
      });
      def.Bars.getTooltipText = (ev) => {
        return [`${ev.x}:`, `${d3.format(",")(ev.yValue)}`];
      };
      //chart.addLegend(60, 10, 510, 20, "right");
      aChart.setMargins(32, 30, 10, 45); // (left, top, right, bottom)
      aChart.draw();
    },
    drawHorizontalBarChart(def, dashboard, data, dimension) {
      let vm = this;
      d3.select(def.element).selectAll("*").remove();
      let bars = chartHelper.distinctValues(data, [dimension || def.dimension])
        .length;
      //   if (!def.height) {
      def.height = bars * this.hbarHeight + 55;
      //   }
      let svg = dimple.newSvg(def.element, def.width, def.height),
        aChart = new dimple.chart(svg, data),
        axis = def.subAsPercent
          ? aChart.addPctAxis("x", def.measure)
          : aChart.addMeasureAxis("x", def.measure),
        lMargin = 150;
      def.Chart = aChart;

      axis.title = def.measureTitle;
      axis.ticks = parseInt((def.width - lMargin) / 50);
      axis.title = "";

      axis = aChart.addCategoryAxis("y", dimension || def.dimension);
      axis.addOrderRule(def.measure);
      axis.title = "";
      def.Bars = aChart.addSeries(def.subDimension || null, dimple.plot.bar);

      this.assignChartColours(def);

      def.Bars.afterDraw = chartHelper.horizontalBarLabels(svg, def);
      def.Bars.addEventHandler("click", function (ev) {
        vm.clickHandler(dashboard, ev, def, false);
      });
      if (def.subAsPercent) {
        def.Bars.getTooltipText = (ev) => {
          //   return [
          //     `${dimension || def.dimension}: ${ev.y}`,
          //     `${def.subDimension}: ${ev.aggField[0]}`,
          //     `${def.measure}: ${d3.format(",")(ev.xValue)} (${d3.format(".1f")(
          //       ev.width * 100
          //     )}%)`
          //   ];
          return [
            `${ev.y} - ${ev.aggField[0]}:`,
            `${d3.format(",")(ev.xValue)} (${d3.format(".1f")(
              ev.width * 100
            )}%)`,
          ];
        };
      } else {
        def.Bars.getTooltipText = (ev) => {
          return [`${ev.y}:`, `${d3.format(",")(ev.xValue)}`];
        };
      }
      //chart.addLegend(60, 10, 510, 20, "right");
      aChart.setMargins(lMargin, 10, 40, 50); // (left, top, right, bottom)
      aChart.draw();
    },
    drawHorizontalFunnelChart(def, dashboard, data, dimension) {
      let vm = this;
      let dim = dimension || def.dimension;
      d3.select(def.element).selectAll("*").remove();
      let bars = chartHelper.distinctValues(data, [dim]).length;
      if (!def.height) {
        def.height = bars * this.hbarHeight + 55;
      }
      let svg = dimple.newSvg(def.element, def.width, def.height),
        aChart = new dimple.chart(svg, data),
        axis = aChart.addMeasureAxis("x", def.measure),
        lMargin = 150;
      def.Chart = aChart;
      axis.title = def.measureTitle;
      axis.showGridlines = false;
      axis.hidden = true;

      let yaxis = aChart.addCategoryAxis("y", dim);
      yaxis.addOrderRule(dim, true);
      yaxis.title = "";
      yaxis.hidden = true;

      def.Bars = aChart.addSeries(def.subDimension || null, dimple.plot.bar);

      this.assignChartColours(def);

      def.Bars.afterDraw = chartHelper.funnelLabels(svg, def, data, dashboard);
      // if (def.filterHandler)
      def.Bars.addEventHandler("click", function (ev) {
        vm.clickHandler(dashboard, ev, def, false);
      });
      def.Bars.getTooltipText = (ev) => {
        return [`${ev.y}:`, `${d3.format(",")(ev.xValue)}`];
      };
      //chart.addLegend(60, 10, 510, 20, "right");
      aChart.setMargins(lMargin, def.linkedValueTitle ? 20 : 5, 40, 50); // (left, top, right, bottom)
      aChart.draw();
    },
    drawPieChart(def, dashboard, data, dimension) {
      let vm = this;
      d3.select(def.element).selectAll("*").remove();
      if (!def.height) {
        def.height = def.width * 1.25;
      }
      let svg = dimple.newSvg(def.element, def.width, def.height),
        pChart = new dimple.chart(svg, data);
      def.Chart = pChart;
      pChart.addMeasureAxis("p", def.measure);

      this.assignChartColours(def);

      //pChart.setBounds(10, "15%", "70%", "90%"); // x,y,w,h
      pChart.setBounds(0, 0, "70%", "100%"); // x,y,w,h
      //   var ring = pChart.addSeries("id", dimple.plot.pie);
      var ring = pChart.addSeries(dimension || def.dimension, dimple.plot.pie);
      ring.innerRadius = "50%";
      //pChart.addLegend("75%", 10, "25%", "50%", "left");
      pChart.addLegend("70%", 0, "30%", "100%", "left");
      def.Bars = ring;

      def.Bars.addEventHandler("click", function (ev) {
        vm.clickHandler(dashboard, ev, def, false);
      });
      def.Bars.getTooltipText = (ev) => {
        return [
          `${ev.aggField[0]}:`,
          `${d3.format(",")(ev.pValue)} (${d3.format(".1f")(
            ev.piePct * 100
          )}%)`,
        ];
      };
      ring.afterDraw = chartHelper.pieLabels(svg, def);

      pChart.draw();
    },
    fitMap(def) {
      if (def.ready) {
        this.$refs[def.ref][0].mapObject.flyToBounds(def.bounds);
      }
    },
    drawMap(def, dashboard) {
      let filtered = this.filterForSelection(dashboard);
      let data = chartHelper.groupByKeys(
        filtered,
        [def.countryDim, def.countryCode, def.countryRegion],
        [def.measure]
      );
      let minmax = { minX: 1000, maxX: -1000, minY: 1000, maxY: -1000 };

      def.map.geojson.features = geo.features
        .filter((f) => data.some((d) => d[def.countryCode] === f.id))
        .map((f) => {
          f.geometry.coordinates.forEach((x) => {
            x.forEach((s) => {
              if (s[0][0]) {
                s.forEach((ss) => {
                  minmax.minX = ss[0] < minmax.minX ? ss[0] : minmax.minX;
                  minmax.minY = ss[1] < minmax.minY ? ss[1] : minmax.minY;
                  minmax.maxX = ss[0] > minmax.maxX ? ss[0] : minmax.maxX;
                  minmax.maxY = ss[1] > minmax.maxY ? ss[1] : minmax.maxY;
                });
              } else {
                minmax.minX = s[0] < minmax.minX ? s[0] : minmax.minX;
                minmax.minY = s[1] < minmax.minY ? s[1] : minmax.minY;
                minmax.maxX = s[0] > minmax.maxX ? s[0] : minmax.maxX;
                minmax.maxY = s[1] > minmax.maxY ? s[1] : minmax.maxY;
              }
            });
          });
          return Object.assign(
            {},
            f,
            data.find((c) => c[def.countryCode] === f.id)
          );
        });
      def.bounds = [
        [minmax.maxY, minmax.maxX],
        [minmax.minY, minmax.minX],
      ];
      def.ready = true;

      //def, dashboard, data) {
      //   if (!$(".mapContainer").length) return;
      //   if (!$("#map").length)
      //     $(".mapContainer").html(
      //       '<div id="map" style="height: 600px; z-index: 1;"></div>'
      //     );
      //   let data = this.filterForMap(),
      //     filterhandler = this.setupAreaLabel("Map"),
      //     sizeAreaChart = chartHelper.getChartSize("AreaMapChart"),
      //     sizeMapChart = chartHelper.getChartSize("EmbeddedMapChart");
      //   this.mapPopupWidth = sizeMapChart.width;
      //   this.mapPopupHeight = sizeMapChart.height;
      //   this.storesMap = data.stores;
      //   this.selectedStore = null;
      //   // let def = { element: "#AreaMapChart", chartname: "area", width: sizeAreaChart.width, height: 0, measureTitle: "YTD Shifts", filterHandler: filterhandler };
      //   // chartHelper.drawHorizontalBarChart(def, data.areas);
      //   let thisVue = this;
      //   let self = { map: this.map };
      //   if (!this.map) {
      //     this.map = L.map("map").setView([53.505, -0.09], 7);
      //     self.map = this.map;
      //     this.tileLayer = L.tileLayer(
      //       //                        'https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png',
      //       "http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png",
      //       {
      //         maxZoom: 18,
      //         attribution:
      //           '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attribution">CARTO</a>'
      //       }
      //     );
      //     this.tileLayer.addTo(this.map);
      //   }
      //   if (this.mapMarkers) {
      //     this.mapMarkers.clearLayers();
      //   } else {
      //     this.mapMarkers = new L.LayerGroup();
      //   }
      //   if (data.stores.length) {
      //     let latlngs = [];
      //     data.stores.forEach(function(store) {
      //       let latlng = { lat: store.Latitude, lng: store.Longitude },
      //         icon = L.icon({
      //           iconUrl: store.Shifts
      //             ? "/content/waitrose-sm.png"
      //             : "/content/waitrose-nosupply-sm.png",
      //           iconSize: [30, 30],
      //           iconAnchor: [14, 22],
      //           popupAnchor: [0, 0]
      //         }),
      //         marker = L.marker(latlng, { icon: icon });
      //       marker.bindPopup(thisVue.getStorePopupHTML(store), {
      //         maxWidth: thisVue.mapPopupWidth
      //       });
      //       marker.bindTooltip(store.Location);
      //       thisVue.mapMarkers.addLayer(marker);
      //       latlngs.push(latlng);
      //     });
      //     let lyr = this.map.addLayer(thisVue.mapMarkers);
      //     let bounds = new L.LatLngBounds(latlngs);
      //     this.map.flyToBounds(bounds);
      //     this.mapSetting.bounds = this.map.getBounds();
      //     this.mapSetting.zoom = this.map.getZoom();
      //     self.map.on("popupopen", this.doMapPopUp);
      //   }
    },
    exportToCSV() {
      let dashboard = this.dashboards[this.tab];
      let cols = dashboard.def.dimensions.map((d) => {
        return { text: d.display, value: d.id };
      });
      dashboard.def.measures.forEach((m) => {
        cols.push({ text: m.name, value: m.id });
      });
      let data = cols.map((h) => '"' + h.text + '"').join(",") + "\n";
      let filtered = this.filterForSelection(dashboard);
      filtered.forEach((d) => {
        data +=
          cols.map((h) => utils.csvEscape(utils.removeTags(d[h.value]))).join(",") +
          "\n";
      });
      utils.downloadFile(
        data,
        `${dashboard.title}.csv`,
        "text/csv;encoding:utf-8"
      );
    },
    //             doMapPopUp: function (item) {
    //                 if (!$("#_" + this.mapStoreSelected.LocationId + " svg").length) { // openpopup can fire multiple times
    //                     let keyClass = function (week) {
    //                             let s = 1 + parseInt((week - 1) / 4) * 4;
    //                             return s + "-" + (s + 3);
    //                         },
    //                         keys = [],
    //                         wk = parseInt(this.CurrentYearWeek.toString().substr(4, 2));
    //                     for (let i = 1; i < wk; i = i + 4) {
    //                         keys.push({ key: keyClass(i), value: 0, sort: i });
    //                     }
    //                     let sel = this.mapStoreSelected.Location,
    //                         data = this.weekspenddata.filter(function (item) {
    //                             return item.Location === sel && item.ActualThisYear !== 0;
    //                         }),
    //                         byLocation = chartHelper.groupBy(data, 'Week', 'ActualThisYear', keys, keyClass);
    //                     if (data.length) {
    //                         let def = {
    //                             element: "#_" + this.mapStoreSelected.LocationId,
    //                             width: this.mapPopupWidth - 30,
    //                             height: this.mapPopupHeight,
    //                             xAxis: "Week",
    //                             xAxisTitle: "Week",
    //                             yAxisTitle: "Invoiced",
    //                             sortProperty: "sort"
    //                         };
    //                         $(def.element).css('height', def.height + 'px');
    //                         chartHelper.drawVerticalBarChart(def, byLocation);
    //                     }
    //                 }
    //             },
    //             getStorePopupHTML: function (store) {
    //                 let thisVue = this;
    //                 return function () {
    //                     thisVue.mapStoreSelected = store;
    //                     let selweek = thisVue.mapWeekSelected + thisVue.mapWeekSelector.yearpart,
    //                         wk = thisVue.weekspenddata.filter(function (item) {
    //                             return item.Location === store.Location && item.YearWeek === selweek;
    //                         }),
    //                         content = store.Location + '<br>',
    //                         figs = "";
    //                     if (wk.length) {
    //                         wk = wk[0];
    //                         figs = wk.Shifts + " Shift" + (wk.Shifts === 1 ? ", " : "s, ") + wk.LastMin + " Last Min, " + wk.NoShow + ' No Show<br>';
    //                         content += wk.Shifts + " Shift" + (wk.Shifts === 1 ? ", " : "s, ") + wk.LastMin + " Last Min, " + wk.NoShow + ' No Show<br>';
    //                     }

    //                     let html =
    //                         '<div class="v-card theme--light" style="width:' + thisVue.mapPopupWidth + 'px;">' +
    //                         '   <div class="v-card__title green">' +
    //                         '       <div style="width: 100%;">' +
    //                         '           <span class="white--text" style="float: left;">' + store.Location + '</span>' +
    //                         '           <span class="white--text" style="float: right;">Week ' + thisVue.mapWeekSelected + '</span>' +
    //                         '       </div>' +
    //                         '   </div>' +
    //                         '    <div class="v-card__text">' +
    //                         '       <span>' + store.Region + '</span>';
    //                     if (figs) html +=
    //                         '       <br>' +
    //                         '       <span>' + figs + '</span>' +
    //                         '       </div>';
    //                     html +=
    //                         '       <div id="_' + store.LocationId + '">' +
    //                         '    </div>' +
    //                         '</div>';
    //                     return html;
    //                 }
    //             }
  },
};
var geo = countrygeoJSON;
var chartHelper = {
  getBar: function (shape) {
    // Get the shape as a d3 selection
    let s = d3.select(shape),
      rect = {
        x: parseFloat(s.attr("x")),
        y: parseFloat(s.attr("y")),
        width: parseFloat(s.attr("width")),
        height: parseFloat(s.attr("height")),
      };
    return {
      x: isNaN(rect.x) ? 0 : rect.x,
      y: isNaN(rect.y) ? 0 : rect.y,
      width: isNaN(rect.width) ? 0 : rect.width,
      height: isNaN(rect.height) ? 0 : rect.height,
      d3Shape: s,
    };
  },
  horizontalBarLabels: function (svg, chart) {
    return function (shape, data) {
      var rect = chartHelper.getBar(shape);
      if (chart.isSingleSelect)
        chartHelper.preselectElement(rect.d3Shape, chart, data.yValue);
      if (chart.showBarLabels && !chart.subDimension) {
        if (rect.height >= 8) {
          // Add a text label for the value
          svg
            .append("text")
            .attr("class", "barLabel")
            .attr("x", rect.x + rect.width + 20)
            .attr("y", rect.y + rect.height / 2 + 3.5)
            .style("text-anchor", "middle")
            .style("font-size", "10px")
            .style("font-family", "sans-serif")
            .style("opacity", 0.6)
            .style("pointer-events", "none")
            .text(
              data.xValue > 10000
                ? d3.format(",.1f")(data.xValue / 1000) + "k"
                : data.xValue
            );
        }
      }
    };
  },
  funnelLabels: function (svg, chart, labels, dashboard) {
    let getLinkedValue = (dimValue) => {
      let linkedCharts =
        chart.linkedChartColourBands && chart.linkedChartColourBands.length
          ? dashboard.charts.filter(
              (c) =>
                c.linkedChartGroup === chart.linkedChartGroup &&
                c.index !== chart.index
            )
          : [];
      let linkedLabelValues = [];
      if (linkedCharts.length) {
        linkedCharts.forEach((lc) => {
          lc.Chart.data
            .filter(
              (x) => x.labelPct !== null && x[chart.dimension] === dimValue
            )
            .forEach((cd) => {
              let lv = linkedLabelValues.find(
                (x) => x.dimValue === cd[chart.dimension]
              );
              if (!lv) {
                lv = { dimValue: cd[chart.dimension], pctValue: 0 };
                linkedLabelValues.push(lv);
              }
              lv.pctValue += cd.labelPct;
            });
        });
        linkedLabelValues.forEach(
          (x) => (x.pctValue = x.pctValue / linkedCharts.length)
        );
      }
      return {
        value: linkedLabelValues.length ? linkedLabelValues[0].pctValue : null,
        fullWidth: chart.fullWidth,
      };
    };
    return function (shape, data) {
      var rect = chartHelper.getBar(shape);
      if (chart.isSingleSelect)
        chartHelper.preselectElement(rect.d3Shape, chart, data.y);
      let widthPct = chart.maxValue > 0 ? data.xValue / chart.maxValue : 1,
        fullWidth =
          chart.maxValue > 0 ? rect.width / widthPct : chart.width - 75;

      let isLinked = chart.linkedChartGroup && chart.linkedChartGroup > 0;
      let linkedChart = getLinkedValue(data.y);

      if (isLinked && !linkedChart.fullWidth) {
        // make narrower to allow for addional columns
        let reduceTo = (fullWidth - 50) / fullWidth;
        fullWidth = fullWidth * reduceTo;
        rect.width = rect.width * reduceTo;
        chart.fullWidth = fullWidth;
      } else if (linkedChart.fullWidth) {
        let reduceTo = linkedChart.fullWidth / fullWidth;
        fullWidth = fullWidth * reduceTo;
        rect.width = rect.width * reduceTo;
        chart.fullWidth = fullWidth;
      }
      let right = rect.x + fullWidth;

      rect.x += (fullWidth - rect.width) / 2;
      rect.d3Shape.attr("x", rect.x);
      rect.d3Shape.attr("width", rect.width === 0 ? 1 : rect.width);

      if (!chart.subDimension) {
        if (rect.height >= 8) {
          // Add a text label for the value
          let lbl = labels.find((l) => l[chart.dimension] === data.y);
          let label = !lbl ? data.xValue : lbl.labelText;
          let band;
          let calc;
          if (
            linkedChart.value &&
            chart.linkedChartColourBands &&
            chart.linkedChartColourBands.length
          ) {
            if (linkedChart.value) {
              calc = lbl.labelPct / linkedChart.value;
              band = chart.linkedChartColourBands.find(
                (x) =>
                  (x.lowPct || 0) <= calc && (x.highPct || 999999999999) >= calc
              );
            }
          }
          svg
            .append("text")
            .attr("class", "barLabel")
            .attr("x", right + 20)
            .attr("y", rect.y + rect.height / 2 + 3.5)
            .style("text-anchor", "middle")
            .style("font-size", "10px")
            .style("font-family", "sans-serif")
            // .style("fill", band ? band.colour : "")
            // .style("pointer-events", "none")
            .text(label); // .append("title").text(band ? band.colour : "no colour");
          if (calc) {
            svg
              .append("text")
              .attr("class", "barLabel")
              .attr("x", right + 70)
              .attr("y", rect.y + rect.height / 2 + 3.5)
              .style("text-anchor", "middle")
              .style("font-size", "10px")
              .style("font-family", "sans-serif")
              .style("fill", band ? band.colour : "")
              // .style("pointer-events", "none")
              .text((calc * 100).toFixed(1) + "%"); // .append("title").text(band ? band.colour : "no colour");
          }
          svg
            .append("text")
            .attr("class", "barLabel")
            .attr("x", 0)
            .attr("y", rect.y + rect.height / 2 + 3.5)
            .style("text-anchor", "left")
            .style("font-size", "10px")
            .style("font-family", "sans-serif")
            // .style("opacity", 0.6)
            // .style("pointer-events", "none")
            .text(data.y);
          if (chart.linkedValueTitle && lbl.labelPct === null) {
            //   let y = 10;
            chart.linkedValueTitle.split(" ").forEach((t, ti) => {
              svg
                .append("text")
                .attr("class", "barLabel")
                .attr("x", right + 72)
                .attr("y", 10 * (ti + 1)) //rect.y - rect.height)
                .style("text-anchor", "middle")
                .style("font-size", "10px")
                .style("font-family", "sans-serif")
                .text(t);
            });
          }
        }
      }
    };
  },
  verticalBarLabels: function (svg, chart) {
    return function (shape, data) {
      var rect = chartHelper.getBar(shape);
      chartHelper.preselectElement(rect.d3Shape, chart, data.x);
      if (rect.height >= 0) {
        svg
          .append("text")
          .attr("class", "barLabel")
          .attr("x", rect.x + rect.width / 2)
          .attr("y", rect.y - 5)
          .style("text-anchor", "middle")
          .style("font-size", "10px")
          .style("font-family", "sans-serif")
          .style("opacity", 0.6)
          .style("pointer-events", "none")
          .text(
            data.yValue > 10000
              ? d3.format(",.1f")(data.yValue / 1000) + "k"
              : data.yValue
          );
      }
    };
  },
  pieLabels: function (svg, chart) {
    return function (shape, data) {
      var arc = d3
        .arc()
        .outerRadius(data.outerRadius)
        .innerRadius(data.outerRadius / 2);
      chartHelper.preselectElement(arc, chart, data.value);

      var ctm = shape.getCTM();

      svg
        .append("text")
        // Position text in the centre of the shape
        .attr("x", arc.centroid(data)[0])
        .attr("y", arc.centroid(data)[1])
        .attr("class", "barLabel")
        .attr("transform", function () {
          return "translate(" + ctm.e + "," + ctm.f + ")";
        })
        // Centre align and nicer display
        .style("text-anchor", "middle")
        .style("font-size", "10px")
        .style("font-family", "sans-serif")
        .style("opacity", 0.8)
        // Prevent text cursor on hover and allow tooltips
        .style("pointer-events", "none")
        // Display text
        .text((data.piePct * 100).toFixed(0) + "%");
    };
  },
  preselectElement(shape, chart, value) {
    if (
      chart.isSingleSelect &&
      chart.navItems[1].svgElement === null &&
      chart.navItems[1].text === value
    ) {
      chart.navItems[1].svgElement = shape;
      chartHelper.elementSelect(shape, true);
    }
  },
  elementSelect(shape, select) {
    if (shape) {
      shape.style("stroke", select ? "black" : "gray");
      shape.style("stroke-width", select ? "2" : "1");
    }
  },
  groupBy: function (arr, key, measure, dateToMonth) {
    let list = [];
    arr.forEach(function (item) {
      let dKey = item[key];
      if (dateToMonth) dKey = dayJS(dKey).format("YYYY MM");
      let row = list.filter(function (li) {
        return li[key] === dKey;
      })[0];
      if (!row) {
        row = {};
        row[key] = dKey;
        row[measure] = 0;
        list.push(row);
      }
      row[measure] += item[measure] || 0;
    });
    return list;
  },
  groupByKeys: function (arr, keys, measures, dateToMonth) {
    let list = [];
    arr.forEach(function (item) {
      let key = {},
        keyVal = "";
      keys.forEach(function (k, i) {
        let dKey = item[k];
        if (dateToMonth && i === 0) dKey = dayJS(dKey).format("YYYY MM");
        key[k] = dKey;
        keyVal += "|" + dKey + "|";
      });

      let row = list.find(function (li) {
        return li.keyVal === keyVal;
      });
      if (!row) {
        row = { keyVal: keyVal };
        keys.forEach(function (k) {
          row[k] = key[k];
        });
        measures.forEach(function (m) {
          row[m] = item[m] || 0;
        });
        list.push(row);
      } else {
        measures.forEach(function (m) {
          row[m] += item[m] || 0;
        });
      }
    });
    return list;
  },
  groupByM: function (arr, key, measures) {
    let list = [];
    arr.forEach(function (item) {
      let row = list.filter(function (li) {
        return li[key] === item[key];
      })[0];
      if (!row) {
        row = {};
        row[key] = item[key];
        measures.forEach(function (m) {
          row[m] = 0;
        });
        list.push(row);
      }
      measures.forEach(function (m) {
        if (item[m] !== 0) row[m] = (row[m] || 0) + item[m];
      });
    });
    return list;
  },
  distinctValues: function (arr, keys) {
    let list = [];
    arr.forEach(function (item) {
      let row = list.filter(function (li) {
        let match = true;
        keys.forEach(function (k) {
          if (li[k] !== item[k]) match = false;
        });
        return match;
      })[0];
      if (!row) {
        row = {};
        keys.forEach(function (k) {
          row[k] = item[k];
        });
        list.push(row);
      }
    });
    return list;
  },
  //   formatNumber: function(val, showdp, units) {
  //     let value;
  //     switch (units) {
  //       case "k":
  //         value = val / 1000;
  //         break;
  //       case "m":
  //         value = val / 1000000;
  //         break;
  //       default:
  //         value = val;
  // 	}
  // 	let rx = new RegExp("d(?=(d{3})+.)", "g");
  //     let text = value.toFixed(2).replace(rx, "$&,");
  //     //let text = value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  //     if (!showdp) text = text.split(".")[0];
  //     return text + (units ? units : "");
  //   },
  drawStackedBarChart: function (def, data) {
    // $(def.element + " svg").remove();
    d3.select(def.element).selectAll("*").remove();
    let SVG = dimple.newSvg(def.element, def.width, def.height),
      Chart = new dimple.chart(SVG, data);
    //   series = [];

    let xAxis = Chart.addCategoryAxis("x", def.xAxis),
      yAxis = Chart.addMeasureAxis("y", def.Measures[0].Property);
    //   axis;

    //series.push(Chart.addSeries(def.Measures[0].Property === def.Measures[0].Title ? def.Measures[0].Title + " " : def.Measures[0].Title, dimple.plot.line, [xAxis, yAxis]));
    //series[0].interpolation = "cardinal";
    //series[0].lineMarkers = true

    //for (let m = 1; m < def.Measures.length; m++) {
    //    axis = Chart.addMeasureAxis(yAxis, def.Measures[m].Property);
    //    if (def.yAxisFormat) axis.tickFormat = def.yAxisFormat;
    //    series.push(Chart.addSeries(def.Measures[m].Property === def.Measures[m].Title ? def.Measures[m].Title + " " : def.Measures[m].Title, dimple.plot.line, [xAxis, axis]));
    //    series[m].interpolation = "cardinal";
    //    series[m].lineMarkers = true;
    //}

    def.Bars = Chart.addSeries(def.VerticalGroup, dimple.plot.bar);

    xAxis.title = def.xAxisTitle;
    yAxis.title = def.yAxisTitle;
    if (def.yAxisFormat) yAxis.tickFormat = def.yAxisFormat;

    if (def.filterHandler) def.Bars.addEventHandler("click", def.filterHandler);
    Chart.addLegend(10, 2, def.width, def.width < 600 ? 40 : 25, "right"); // (x, y, width, height, horizontalAlign, series)
    Chart.setMargins(60, 20, 10, 50); // (left, top, right, bottom)
    Chart.draw();
    //series.forEach(function (item) {
    //    item.shapes.style("opacity", function (d) {
    //        return (d.y === 0 ? 0 : 0.8);
    //    });
    //});
    def.Chart = Chart;
  },
  drawMultiSeriesChart: function (def, data) {
    // $(def.element + " svg").remove();
    d3.select(def.element).selectAll("*").remove();
    let SVG = dimple.newSvg(def.element, def.width, def.height),
      Chart = new dimple.chart(SVG, data),
      series = [];

    let xAxis = Chart.addCategoryAxis("x", def.xAxis),
      yAxis = Chart.addMeasureAxis("y", def.Measures[0].Property),
      axis;

    series.push(
      Chart.addSeries(
        def.Measures[0].Property === def.Measures[0].Title
          ? def.Measures[0].Title + " "
          : def.Measures[0].Title,
        dimple.plot.line,
        [xAxis, yAxis]
      )
    );
    series[0].interpolation = "cardinal";
    series[0].lineMarkers = true;

    for (let m = 1; m < def.Measures.length; m++) {
      axis = Chart.addMeasureAxis(yAxis, def.Measures[m].Property);
      if (def.yAxisFormat) axis.tickFormat = def.yAxisFormat;
      series.push(
        Chart.addSeries(
          def.Measures[m].Property === def.Measures[m].Title
            ? def.Measures[m].Title + " "
            : def.Measures[m].Title,
          dimple.plot.line,
          [xAxis, axis]
        )
      );
      series[m].interpolation = "cardinal";
      series[m].lineMarkers = true;
    }

    yAxis.title = def.yAxisTitle; // "Sales";
    if (def.yAxisFormat) yAxis.tickFormat = def.yAxisFormat;

    Chart.addLegend(10, 2, def.width, def.width < 600 ? 40 : 25, "right"); // (x, y, width, height, horizontalAlign, series)
    Chart.setMargins(60, 20, 10, 50); // (left, top, right, bottom)
    Chart.draw();
    series.forEach(function (item) {
      item.shapes.style("opacity", function (d) {
        return d.y === 0 ? 0 : 0.8;
      });
    });
    def.Chart = Chart;
  },
  getChartSize: function (chart) {
    let win = { w: window.innerWidth, h: window.innerHeight };
    switch (chart) {
      case "AreaMapChart":
        if (win.w > 1200) return { width: win.w * 0.4, height: -1 };
        else if (win.w > 850) return { width: win.w * 0.45, height: -1 };
        else return { width: win.w * 0.9, height: -1 };
      case "EmbeddedMapChart":
        if (win.w > 1100) return { width: 400, height: 150 };
        else return { width: 300, height: 100 };
    }
  },
};
</script>
<style scoped lang="scss">
.card-title {
  margin: 0;
  color: #252422;
  font-weight: 300;
  cursor: pointer;
}
.category {
  font-size: 14px;
  font-weight: 400;
  color: #9a9a9a;
  margin-bottom: 0;
}
.theme--dark {
  .card-title {
    color: #fff;
  }
  .category {
    color: #fff;
  }
}
.h4,
h4 {
  font-size: 1.5em;
  font-weight: 600;
  line-height: 1.2em;
}
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  padding: 12px 11px;
  vertical-align: middle;
}
.table tbody tr > td,
.table tbody tr > th,
.table tfoot tr > td,
.table tfoot tr > th,
.table thead tr > td,
.table thead tr > th {
  border-top: 1px solid #ccc5b9;
}
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
}
.card-content {
  padding: 15px 15px 10px;
}
.jvectormap-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  touch-action: none;
}
.stats {
  color: #a49e93;
  font-weight: 300;
}
[class^="ti-"],
[class*=" ti-"] {
  font-family: "themify";
  font-style: normal;
  font-weight: bold;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.mapCard,
.v-breadcrumbs {
  padding: 1px;
}
.workflowCard {
  padding: 15px;
  height: 100%;
}
.card1 {
  height: 130px;
  background-size: cover;
  background-image: url("https://bh.role-mapper.com/assets/img/home-page-col-33-item-1-bg.jpg");
}
.card2 {
  height: 100%;
  background-size: cover;
  background-image: url("https://bh.role-mapper.com/assets/img/home-page-col-33-item-2-bg.jpg");
}
.card3 {
  height: 100%;
  background-size: cover;
  background-image: url("https://bh.role-mapper.com/assets/img/home-page-col-33-item-3-bg.jpg");
}
.card4 {
  height: 100%;
  background-size: cover;
  background-image: url("https://bh.role-mapper.com/assets/img/home-page-col-50-item-2-bg.jpg");
}
.cardText {
  color: white;
  font-size: 1.8rem;
  padding: 30px 0 0 20px;
  text-shadow: 1px 1px 3px #666;
}
.smallCardText {
  color: white;
  font-size: 0.9rem;
  padding: 5px 5px 0 20px;
  text-shadow: 1px 1px 2px #666;
}
.flag {
  border-radius: 2px;
  width: 25px;
  box-shadow: 1px 1px 1px 1px #eee;
}
.smallText {
  font-size: 0.7rem;
}
.selHierarchy {
  margin-right: 10px;
  display: inline-block;
  border-bottom: 1px solid #999;
  padding-bottom: 2px;
  font-size: 0.8rem;
  color: #999;
  cursor: auto;
}
.othHierarchy {
  margin-right: 10px;
  font-size: 0.8rem;
  color: #999;
  cursor: pointer;
}
.selTab {
  text-decoration: underline;
  cursor: auto;
}
</style>
<style>
.leaflet-container {
  box-shadow: 4px 4px lightgrey;
  border-radius: 6px;
  opacity: 0.92;
}
.leaflet-control-container {
  display: none;
}
</style>