<template>

<div>
    <div style="display: none">
      <!-- Sacrificial fields to remove annoying Chrome habit of auto-filling fields -->
      <input type="text" />
      <input type="password" />
    </div>
    <v-container fluid class="no-print">
      <v-dialog v-model="dialog" max-width="1200px">
        <v-card>
          <v-card-title>
            <span class="headline">View Details</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              {{ viewItem.DataPassed }}
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close"> Close </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-row dense>
        <v-col cols="1"></v-col>
        <v-col cols="9">
          <v-text-field
            v-model="filterText"
            dense
            flat
            clearable
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="type text and enter to filter..."
            class="hidden-sm-and-down"
          />
        </v-col>
        <v-col cols="1"></v-col>
        <v-col cols="1" class="d-flex align-center justify-center">
          <v-icon
            @click="exportToCSV"
            class="float-right"
            color="green darken-2"
            title="Download List"
            >mdi-cloud-download</v-icon
          >
        </v-col>
      </v-row>
      <div v-if="items.length" class="no-print mt-6">
        <v-data-table
          :headers="headers"
          dense
          :items="items"
          sort-by="name"
          class="elevation-1 py-3"
          :search="filterText"
          :custom-filter="filterData"
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 50, -1],
          }"
          :items-per-page="dtItemsPerPage"
          @update:options="dtOptionChange"
        >
          <template v-slot:[`item.reference`]="{ item }">
            <span v-html="item.reference" style="font-size: 0.7rem"></span>
          </template>
          <template v-slot:[`item.doc_name`]="{ item }">
            <span v-html="item.doc_name" style="font-size: 0.7rem"></span>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <span v-html="item.status" style="font-size: 0.7rem"></span>
          </template>
          <template v-slot:[`item.first_name`]="{ item }">
            <span v-html="item.first_name" style="font-size: 0.7rem"></span>
          </template>
          <template v-slot:[`item.last_name`]="{ item }">
            <span v-html="item.last_name" style="font-size: 0.7rem"></span>
          </template>

          <template v-slot:[`item.GroupName`]="{ item }">
            <span v-html="item.GroupName" style="font-size: 0.7rem"></span>
          </template>
          <template v-slot:[`item.CostCenterName`]="{ item }">
            <span v-html="item.CostCenterName" style="font-size: 0.7rem"></span>
          </template>
          <template v-slot:[`item.CostCenter`]="{ item }">
            <span v-html="item.CostCenter" style="font-size: 0.7rem"></span>
          </template>

          <template v-slot:[`item.Country`]="{ item }">
            <span v-html="item.Country" style="font-size: 0.7rem"></span>
          </template>
          <template v-slot:[`item.Region`]="{ item }">
            <span v-html="item.Region" style="font-size: 0.7rem"></span>
          </template>
          <template v-slot:[`item.Division`]="{ item }">
            <span v-html="item.Division" style="font-size: 0.7rem"></span>
          </template>
          <template v-slot:[`item.SubDivision`]="{ item }">
            <span v-html="item.SubDivision" style="font-size: 0.7rem"></span>
          </template>
          <template v-slot:[`item.Organization`]="{ item }">
            <span v-html="item.Organization" style="font-size: 0.7rem"></span>
          </template>
          <template v-slot:[`item.mdate`]="{ item }">
            <span v-html="item.mdate" style="font-size: 0.7rem"></span>
          </template>
          <template v-slot:[`item.ReqID`]="{ item }">
            <span v-html="item.ReqID" style="font-size: 0.7rem"></span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small title="View Details" @click="viewDetails(item)"
              >mdi-magnify</v-icon
            >
          </template>
        </v-data-table>
      </div>
      <ResponseHandler :serviceResponse="response"></ResponseHandler>

      <Loading :isVisible="isLoading" />
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
//import draggable from "vuedraggable";
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
import utils from "@/common/utils.js";

export default {
  name: "ATSExportReport",
  components: {
    ResponseHandler,
    // draggable
  },
  props: {},
  data: function () {
    return {
      response: null,
      filterText: "",
      headers: [
        { text: "Reference", value: "reference" },
        { text: "Doc Name", value: "doc_name" },
        { text: "Status", value: "status" },
        { text: "First Name", value: "first_name" },
        { text: "Last Name", value: "last_name" },
        { text: "Region", value: "Region" },
        { text: "Country", value: "Country" },
        { text: "Division", value: "Division" },
        { text: "SubDivision", value: "SubDivision" },
        { text: "Organization", value: "Organization" },
        { text: "Date", value: "mdate" },
        { text: "ReqID", value: "ReqID" },
        { text: "Details", value: "actions", sortable: false },
      ],
      exportHeaders: [
        { text: "Reference", value: "reference" },
        { text: "Doc Name", value: "doc_name" },
        { text: "Status", value: "status" },
        { text: "First Name", value: "first_name" },
        { text: "Last Name", value: "last_name" },
        { text: "Group", value: "GroupName" },
        { text: "Cost Center", value: "CostCenterName" },
        { text: "Cost Center Number", value: "CostCenter" },
        { text: "Region", value: "Region" },
        { text: "Country", value: "Country" },
        { text: "Division", value: "Division" },
        { text: "SubDivision", value: "SubDivision" },
        { text: "Organization", value: "Organization" },
        { text: "Date", value: "mdate" },
        { text: "ReqID", value: "ReqID" },
      ],
      dtItemsPerPage: 20,
      items: [],
      dialog: false,
      viewItem: {
        DataPassed: "",
      },
      exportItems: [],
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.fetchData();
    this.dtItemsPerPage = Number(localStorage.getItem("dtUL")) || 20;
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      let possibleError = false;
      axios
        .get("report/ats/")
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            this.items = resp.data.Data;
            this.items.forEach((item) => {
              switch (item.status) {
                case "PENDING_APPROVAL":
                  item.status = "Pending";
                  break;
                default:
                  item.status = toProperCase(item.status);
              }
            });
            this.isLoading = false;
          }
          this.response = resp.data;
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
          this.isLoading = false;
        });
    },
    viewDetails(item) {
      this.viewItem = item;
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },
    dtOptionChange(value) {
      localStorage.setItem("dtUL", value.itemsPerPage);
      this.dtItemsPerPage = value.itemsPerPage;
    },
    exportToCSV() {
      let data =
        this.exportHeaders.map((h) => '"' + h.text + '"').join(",") + "\n";
      this.items.forEach((d) => {
        let matched = false;
        this.exportHeaders.forEach((h) => {
          var dataItem = d[h.value];
          if (dataItem) {
            if (
              dataItem.toLowerCase().indexOf(this.filterText.toLowerCase()) !==
              -1
            ) {
              matched = true;
            }
          }
        });

        if (matched) {
          this.exportItems.push(d);
          return;
        }
      });

      this.exportItems.forEach((d) => {
        data +=
          this.exportHeaders.map((h) => utils.csvEscape(d[h.value])).join(",") +
          "\n";
      });

      utils.downloadFile(data, "ATSExport.csv", "text/csv;encoding:utf-8");
    },
    filterData(value, search) {
      if (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
      ) {
        return true;
      }
      return false;
    },
  },
};
function toProperCase(s) {
  return s.toLowerCase().replace(/^(.)|\s(.)/g, function ($1) {
    return $1.toUpperCase();
  });
}
</script>
<style scoped></style>
