<template>
  <div class="pt-2">
    <v-row class="px-3">
      <v-col cols="8">
        <v-tabs v-model="tab" background-color="transparent">
          <v-tab>Structure</v-tab>
          <v-tab>Validation</v-tab>
          <v-tab>Relationships</v-tab>
          <v-tab>Copy Hierarchy Exclusions</v-tab>
          <v-tab>Export Sections</v-tab>
          <v-tab>Export Format</v-tab>
          <v-tab>Hierarchy Visibility</v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="4" class="d-flex align-center">
        <v-btn
          @click="addTemplate"
          title="New Template"
          class="mr-2"
          icon>
          <v-icon>mdi-plus-circle</v-icon>
        </v-btn>
        <v-btn
          v-if="allowRegen && regenOptions.length > 0"
          @click="summaryRegen.show = true"
          title="Regen"
          class="mr-2"
          icon>
          <v-icon>sync</v-icon>
        </v-btn>
        <v-select
          label="Template"
          dense
          outlined
          v-model="template"
          :items="templates"
          item-text="tmpl_name"
          return-object
          hide-details
        >
          <template v-slot:selection="{ item }">
            <span :class="!item.tmpl_active ? 'text-decoration-line-through' : ''">
              {{ item.tmpl_name }}
            </span>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item ripple>
              <v-list-item-content>
                <v-list-item-title :class="!item.tmpl_active ? 'text-decoration-line-through' : ''">
                  {{ item.tmpl_name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>

        </v-select>
      </v-col>
    </v-row>
    <v-tabs-items class="mt-3" v-model="tab">
      <v-tab-item>
        <TemplateStructure v-if="template && tab === 0" v-model="template.tmpl_id" @headerUpdated="headerUpdated">
        </TemplateStructure>
      </v-tab-item>
      <v-tab-item>
        <TemplateValidation v-if="template && template.tmpl_id && tab === 1" v-model="template.tmpl_id">
        </TemplateValidation>
      </v-tab-item>
      <v-tab-item>
        <TemplateLinks v-if="template && template.tmpl_id && tab === 2" v-model="template.tmpl_id">
        </TemplateLinks>
      </v-tab-item>
      <v-tab-item>
        <TemplateHierarchyCopy v-if="template && template.tmpl_id && tab === 3" v-model="template.tmpl_id">
        </TemplateHierarchyCopy>
      </v-tab-item>
      <v-tab-item>
        <TemplateExportSections v-if="template && template.tmpl_id && tab === 4" v-model="template.tmpl_id">
        </TemplateExportSections>
      </v-tab-item>
      <v-tab-item>
        <TemplateExportFormat v-if="template && template.tmpl_id && tab === 5" v-model="template.tmpl_id">
        </TemplateExportFormat>
      </v-tab-item>
      <v-tab-item>
        <TemplateBuilderHierarchyHidden v-if="template && template.tmpl_id && tab === 6" v-model="template.tmpl_id">
        </TemplateBuilderHierarchyHidden>
      </v-tab-item>
    </v-tabs-items>

    <v-dialog v-model="summaryRegen.show" v-if="allowRegen" max-width="1000px">
      <v-card v-if="template">
        <v-card-title>
          <v-row>
            <v-col cols="10">
              Regenerate Reporting Data
            </v-col>
            <v-col cols="2" class="d-flex justify-end">
              <v-btn icon @click="summaryRegen.show = false"
                ><v-icon>close</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <div v-if="summaryRegen.statusLoading" class="d-flex justify-center">
            <v-progress-circular
              size="50"
              indeterminate
              >
            </v-progress-circular>
          </div>
          <div v-else-if="summaryRegen.messageId" class="text-center" style="width: 600px; margin-left: auto; margin-right: auto;">
            <p v-if="summaryRegen.progress === null">Your request is <b>queued</b> and will be processed shortly.</p>
            <p v-else>Your request is <b>processing</b>.</p>
            <v-progress-linear
              striped
              height="10"
              color="primary"
              :value="summaryRegen.progress"
              rounded
              :indeterminate="summaryRegen.progress === null"
              class="my-4"
              style="pointer-events: none"
              >
            </v-progress-linear>
            <p>This dialogue can be closed - processing will continue in the background.</p>
          </div>
          <div v-else>
            <p>This is necessary sometimes e.g. when significant changes to template validation have been made</p>
            <p><b>Warning:</b> this can take a long time for large amounts of documents.</p>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-end mr-2 pb-4" v-if="!summaryRegen.messageId && !summaryRegen.statusLoading">
          <v-select
            v-if="regenOptions.length > 1"
            dense
            color="primary"
            outlined
            :items="regenOptions"
            item-text="text"
            item-value="value"
            return-object
            v-model="summaryRegen.template"
            style="max-width: 300px"
            hide-details>
          </v-select>
          <v-btn color="primary" @click="requestRegen" v-if="summaryRegen.template" class="ml-2">
            Regenerate {{ summaryRegen.template.count }} {{ summaryRegen.template.value ? utils.pluralise(summaryRegen.template.text) : 'Documents' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Loading :isVisible="isLoading" />
    <ResponseHandler :serviceResponse="response"></ResponseHandler>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import utils from "@/common/utils.js";
import ResponseHandler from "@/components/ResponseHandler";
import TemplateStructure from "@/components/templateBuilder/cTemplateBuilderStructure";
import TemplateValidation from "@/components/templateBuilder/cAdminTemplateValidation";
import TemplateLinks from "@/components/templateBuilder/cTemplateBuilderLinks";
import TemplateHierarchyCopy from "@/components/templateBuilder/cTemplateBuilderHierarchyCopy";
import TemplateExportSections from "@/components/templateBuilder/cTemplateExportSections";
import TemplateExportFormat from "@/components/templateBuilder/cTemplateExportFormat";
import TemplateBuilderHierarchyHidden from "@/components/templateBuilder/cTemplateBuilderHierarchyHidden";

export default {
  name: "vLandingConfig",
  components: {
    ResponseHandler,
    TemplateStructure,
    TemplateValidation,
    TemplateLinks,
	TemplateHierarchyCopy,
  TemplateExportSections,
  TemplateExportFormat, TemplateBuilderHierarchyHidden,
  },
  props: {},
  data: function () {
    return {
      utils: utils,
      isLoading: false,
      response: null,
      templates: [],
      template: null,
      tab: 0,
      showTemplateSettings: false,
      showTemplateEditor: false,
      summaryRegen : {
        show: false,
        template: null,
        statusLoading: false,
        messageId: null,
        progress: null,
        timeout: null
      },
    };
  },
  mounted() {},
  created() {
    if (!this.hierarchiesLoading) this.init();
  },
  destroyed() {
    if (this.summaryRegen.timeout) {
      clearTimeout(this.summaryRegen.timeout);
      this.summaryRegen.timeout = null;
    }
  },
  watch: {
    hierarchiesLoading(val) {
      if (!val) this.init();
    },
    'summaryRegen.show'(val) {
      if (val) {
        if (this.regenOptions.length)
          this.summaryRegen.template =
            this.regenOptions.find(o => o.value === this.template.tmpl_id)
            ||this.regenOptions[0];
      }
    }
  },
  computed: {
    regenOptions() {
      const options = this.templates
        .filter(t => t.tmpl_active === 1
          && t.docCount !== 0
          && t.tmpl_name !== "External File")
        .map(t => {
          return {
            text: t.tmpl_name,
            value: t.tmpl_id,
            count: Number(t.docCount),
          }
        });
      if (options.length > 1) {
        options.push({
          text: "All Documents",
          value: null,
          count: options.reduce((sum, o) => sum + o.count, 0)
        })
      }
      return options;
    },
    ...mapState({
      newUi: (state) => state.theme.newUi,
      docsLoading: (state) => state.docs.loading,
      docStatusSummary: (state) => state.docs.docStatusSummary,
      docTypeSummary: (state) => state.docs.docTypeSummary,
      hierarchiesLoading: (state) => state.hierarchies.loading,
      docTypes: (state) => state.hierarchies.docTypes,
      allowRegen: (state) => state.settings.config.settings?.some(
        (s) => s.setting === "document_summary_mode" && s.value === "queue"
      ),
    }),
  },
  methods: {
    sessionExpired(err) {
      this.$emit("sessionExpired", err);
    },
    init() {
      let possibleError = false;
      this.isLoading = true;
      axios
        .get("templateBuilder/templateList")
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            const templates = resp.data.Data;
            this.templates = templates;
            if (this.templates.length !== 0) this.template = this.templates.find(t => t.tmpl_active) || this.templates[0];
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
    editTemplate(t) {
      this.template = t;
      this.showTemplateEditor = true;
    },
    headerUpdated(data) {
      const template = this.templates.find(t => t.tmpl_id === data.tmpl_id);
      if (template) {
        template.tmpl_name = data.tmpl_name;
        template.tmpl_active = data.tmpl_active;
      } else {
        this.templates.push(data);
        this.template = data;
      }
    },
    addTemplate() {
      this.tab = 0;
      this.template = {
        tmpl_id: null,
        tmpl_name: "Unknown",
        tmpl_active: 1
      }
    },
    getRegenStatus() {
      if (!this.summaryRegen.messageId) {
        this.summaryRegen.statusLoading = false;
        return;
      }

      let possibleError = false;
      this.response = null;
      axios
        .get("document/getRegenDocSummaryStatus/" + this.summaryRegen.messageId)
        .then((resp) => {
          possibleError = true;
          this.summaryRegen.statusLoading = false;

          if (this.summaryRegen.messageId && resp.data.Data.completed) {
            this.summaryRegen.show = false;
            this.response = {
              Status: "OK",
              Message: "Regen process complete"
            };
            this.summaryRegen.messageId = null;
          }
          this.summaryRegen.progress = resp.data.Data.progress;
          if (!resp.data.Data.completed) {
            this.summaryRegen.timeout = setTimeout(this.getRegenStatus, 3000);
          } else {
            this.summaryRegen.timeout = null;
          }
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
    requestRegen() {
      this.summaryRegen.statusLoading = true;
      let possibleError = false;
      axios
        .post("document/regenDocSummary", { tmplId: this.summaryRegen.template.value })
        .then((resp) => {
          possibleError = true;
          this.summaryRegen.statusLoading = false;
          if (resp.data.Status === "OK") {
            if (resp.data.Data.method === "Queue") {
              this.summaryRegen.messageId = resp.data.Data.messageId;
              this.summaryRegen.progress = null;
              this.summaryRegen.timeout = setTimeout(this.getRegenStatus, 3000);
            } else {
              this.response = resp.data;
              this.summaryRegen.messageId = null;
              this.summaryRegen.show = false;
            }
          }
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";

tr.inactive {
  background-color: #eee;
}

.v-tabs-items {
  background-color: transparent;
}
</style>