<template>
  <v-container @click.stop="$emit('clicked')">
    <v-row
      dense
      v-for="(i, ii) in summary.categories.filter((c) => c.titles.length)"
      :key="'i' + ii"
      style="border-top: white solid 3px"
    >
      <v-col cols="2"
        ><h5>{{ i.category.trim() }}</h5>
      </v-col>
      <v-col dense>
        <v-row
          no-gutters
          v-for="(t, ti) in i.titles"
          :key="'i' + ii + 'ti' + ti"
        >
          <!-- <v-col cols="1">
                        <div
                          :style="{
                            'margin-top': '3px',
                            height:
                              t.effect === 'highlight' || t.effect === 'border'
                                ? '18px'
                                : '12px',
                            'background-color':
                              t.effect === 'highlight'
                                ? t.colour
                                : 'transparent',
                            'border-bottom':
                              t.effect.indexOf('dotted') >= 0
                                ? 'dotted 3px ' + t.colour
                                : t.effect.indexOf('solid') >= 0
                                ? 'solid 3px ' + t.colour
                                : t.effect === 'border'
                                ? 'solid 1px ' + t.colour
                                : '',
                            'border-top':
                              t.effect === 'border'
                                ? 'solid 1px ' + t.colour
                                : '',
                            'border-left':
                              t.effect === 'border'
                                ? 'solid 1px ' + t.colour
                                : '',
                            'border-right':
                              t.effect === 'border'
                                ? 'solid 1px ' + t.colour
                                : '',
                            'border-radius':
                              t.effect === 'border' ? '4px ' : '',
                          }"
                        >
                          <v-icon v-if="t.effect.substr(0, 5) === 'icon-'">{{
                            t.effect.substr(5)
                          }}</v-icon>
                        </div>
                      </v-col> -->
          <v-col cols="2">
            <span style="font-size: 13px; margin-right: 10px">{{
              t.title
            }}</span>
          </v-col>
          <v-col>
            <v-row
              no-gutters
              v-for="(d, di) in t.issues"
              :key="'i' + ii + 'ti' + ti + 'di' + di"
            >
              <v-col v-if="d.errorCount > 0">
                <v-icon color="red" style="font-size: 18px">mdi-alert</v-icon>
                <span
                  v-if="d.errorCount > 1"
                  style="
                    margin-top: -12px;
                    margin-left: -5px;
                    font-size: 9px;
                    text-transform: none;
                  "
                  >x{{ d.errorCount }}</span
                >
                <span v-html="d.description"></span>
              </v-col>
              <v-col bottom v-if="d.warningCount > 0">
                <v-icon color="grey" style="font-size: 18px">mdi-alert</v-icon>
                <span
                  v-if="d.warningCount > 1"
                  style="
                    margin-top: -12px;
                    margin-left: -5px;
                    font-size: 9px;
                    text-transform: none;
                  "
                  >x{{ d.warningCount }}</span
                >
                <span v-html="d.description"></span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "CheckSummary",
  components: {},
  props: {
    summary: Object,
  },
  data: function () {
    return {
      response: null,
    };
  },
  updated() {},
  //   created() {
  //     this.fetchData();
  //   },
  watch: {},
  methods: {},
};
</script>
<style scoped>
.container {
  padding: 1px;
}
h2 {
  margin: 0px;
}
</style>