<template>
  <v-row class=" mt-6 mb-6 link-container">
    <draggable
      class="row px-3 justify-center"
      v-model="links"
      group="landinglinks"
      :disabled="!editMode"
    >
      <v-col
        v-for="(s, si) in links"
        :key="'step' + si"
        :class="{
          'd-flex justify-space-around link-container': true,
          draggable: editMode,
          'link-col': true
        }"
        :cols="s.tile_cols"
      >
        <v-hover v-slot="{ hover }">
          <v-card
            :class="['help-card', `card-${s.icon_colour}`]"
            width="100%"
            :to="editMode || s.passedParams || s.isExternal ? '' : s.url"
            :href="!editMode && s.isExternal ? s.url : ''"
            :target="s.open_newtab ? '_blank' : ''"
            :elevation="!editMode && hover && s.url ? 1 : 0"
            @click="linkClick($event, s)"
            :ripple="!editMode"
          >
            <v-card-text>
              <div class="card-content">
                <div class="px-1">
                  <div v-if="editMode" class="edit-btn">
                    <v-btn
                      icon
                      small
                      title="Delete"
                      @click="deleteTile($event, s)"
                      ><v-icon>delete</v-icon></v-btn
                    >
                    <v-btn icon small title="Copy" @click="copyTile($event, s)"
                      ><v-icon>content_copy</v-icon></v-btn
                    >
                    <v-btn
                      icon
                      small
                      title="Settings"
                      @click="editTile($event, s)"
                      ><v-icon>settings</v-icon></v-btn
                    >
                  </div>
                  <h3>STEP</h3>
                  <h2>{{ si + 1 }}</h2>
                  <h4>{{ s.title }}</h4>
                </div>
                <div v-if="s.call_to_action">
                  <v-divider class="mb-2 mx-2" />
                  <v-btn color="primary" text large class="cta"
                    >{{ s.call_to_action }}
                    <v-icon right>mdi-arrow-right</v-icon></v-btn
                  >
                </div>
              </div>
              <div v-if="si < links.length - 1" class="box-arrow">
                <div class="top-border"></div>
                <div class="bottom-border"></div>
              </div>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>
    </draggable>
    <v-btn
      v-if="editMode"
      class="add-btn"
      large
      icon
      title="Add Tile"
      @click="addTile($event)"
      ><v-icon>add_circle_outline</v-icon></v-btn
    >
  </v-row>
</template>

<script>
import landingUtils from "./landingUtils.js";
import draggable from "vuedraggable";

export default {
  name: "landingStepperSmall",
  components: {
    draggable,
  },
  data: function () {
    return {
      links: []
    };
  },
  props: {
    definition: { type: Object },
    qaActions: { type: Array },
    editMode: { type: Boolean },
  },
  mounted() {},
  watch: {
    definition: {
      deep: true,
      handler(val) {
        this.links = val.links;
      }
    },
    links(val) {
      const definition = this.definition;
      definition.links = val;
      this.$emit("updated:definition", definition);
    }
  },
  computed: {},
  created() {
    this.links = this.definition.links;
  },
  methods: {
    linkClick(ev, link) {
      landingUtils.handleLandingLinkClick(this, ev, link);
    },
    editTile(ev, t) {
      ev.preventDefault();
      this.$emit("editTile", { row: this.definition, link: t });
    },
    addTile(ev) {
      ev.preventDefault();
      this.$emit("addTile", { row: this.definition });
    },
    copyTile(ev, t) {
      ev.preventDefault();
      this.$emit("copyTile", { row: this.definition, link: t });
    },
    deleteTile(ev, t) {
      ev.preventDefault();
      this.$emit("deleteTile", { row: this.definition, link: t });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";

.link-container {
  position: relative;
}

.edit-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.add-btn {
  position: absolute;
  bottom: 0;
  right: -35px;
  z-index: 1;
}

.draggable {
  cursor: move;
}

::v-deep .help-card {
  border-radius: 10px !important;
  min-height: 220px;
  border: 2px dashed;
  .h4 {
    font-weight: normal;
  }
  .v-icon {
    opacity: 0.8;
  }
  .v-card__text {
    height: 100%;
    padding: 0;
  }
  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 15px;
    height: 100%;
  }
  h2 {
    font-size: 26px;
    font-weight: bold;
    padding: 5px 0;
  }
  h3 {
    font-size: 14px;
    font-weight: normal;
    padding: 5px 0;
  }
  h4 {
    font-size: 20px;
    font-weight: bold;
    padding: 5px 0;
  }
  .box-arrow {
    position: absolute;
    right: -15px;
    top: 25px;
    border-top: 15px solid transparent;
    border-right: none;
    border-left: 15px solid $primary-background-light;
    border-bottom: 15px solid transparent;
    .top-border {
      position: absolute;
      right: -7px;
      top: -18px;
      border-top: 2px dotted;
      width: 20px;
      transform: rotate(45deg);
      transform-origin: 0% 0%;
    }
    .bottom-border {
      position: absolute;
      right: -7px;
      top: 12px;
      border-top: 2px dotted;
      width: 20px;
      transform: rotate(-45deg);
      transform-origin: 0% 0%;
    }
  }
  &.theme--dark .box-arrow {
    border-left: 15px solid $primary-background-dark;
  }
  &.card-red {
    border-color: rgba(#bf012f, 0.5);
    .box-arrow .top-border,
    .box-arrow .bottom-border {
      border-color: rgba(#bf012f, 0.5);
    }
    h2,
    h3,
    .cta .v-btn__content {
      color: rgba(#bf012f, 0.8);
    }
  }
  &.card-purple {
    border-color: rgba(#6938b9, 0.5);
    .box-arrow .top-border,
    .box-arrow .bottom-border {
      border-color: rgba(#6938b9, 0.5);
    }
    h2,
    h3,
    .cta .v-btn__content {
      color: rgba(#6938b9, 0.8);
    }
  }
  &.card-orange {
    border-color: rgba(#cf8a38, 0.5);
    .box-arrow .top-border,
    .box-arrow .bottom-border {
      border-color: rgba(#cf8a38, 0.5);
    }
    h2,
    h3,
    .cta .v-btn__content {
      color: rgba(#cf8a38, 0.8);
    }
  }
  &.card-blue {
    border-color: rgba(#377dff, 0.5);
    .box-arrow .top-border,
    .box-arrow .bottom-border {
      border-color: rgba(#377dff, 0.5);
    }
    h2,
    h3,
    .cta .v-btn__content {
      color: rgba(#377dff, 0.8);
    }
  }
  &.card-green {
    border-color: rgba(#00a67d, 0.5);
    .box-arrow .top-border,
    .box-arrow .bottom-border {
      border-color: rgba(#00a67d, 0.5);
    }
    h2,
    h3,
    .cta .v-btn__content {
      color: rgba(#00a67d, 0.8);
    }
  }
  &.card-gray {
    border-color: rgba(#3e3e3e, 0.5);
    .box-arrow .top-border,
    .box-arrow .bottom-border {
      border-color: rgba(#3e3e3e, 0.5);
    }
    h2,
    h3,
    .cta .v-btn__content {
      color: rgba(#3e3e3e, 0.8);
    }
  }
}

::v-deep .v-btn.cta {
  width: 100%;
  padding: 0 5px;
  .v-btn__content {
    text-align: left;
    display: flex;
    justify-content: space-between;
  }
}
</style>