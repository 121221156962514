import axios from "axios";
import utils from "@/common/utils.js";
export default {
	genericFlatten(list, childKey) {
		if (list) {
			const getGenericFlat = (data) => data.map(d => {
				return [d, ...getGenericFlat(d[childKey] ?? [])]
			}).flat();
			let flattened = getGenericFlat(list);
			return flattened;
		} else {
			return [];
		}
	},
	getFlatSections(sections) {
		if (sections) {
			const getFlat = (data) => data.map(d => [d, ...getFlat(d.sub_sections)]).flat();
			let flattened = getFlat(sections);
			return flattened;
		} else {
			return [];
		}
	},
	async generateExternalContent(data) {
		let processPromise = new Promise((resolve, reject) => {
			axios
				.post("externalContent/generate", data)
				.then((resp) => {
					resolve(resp);
				})
				.catch((error) => {
					reject(error);
				});
		});

		return processPromise;
	},
	async revertDeletePart(data) {
		let processPromise = new Promise((resolve, reject) => {
			axios
				.post("document/undoRemoveDocpart/", data)
				.then((resp) => {
					resolve(resp);
				})
				.catch((error) => {
					reject(error);
				});
		});

		return processPromise;
	},
	async saveDisplayPermission(data) {
		let processPromise = new Promise((resolve, reject) => {
			axios
				.post("admin/docViewSaveDisplayPermission/", data)
				.then((resp) => {
					resolve(resp);
				})
				.catch((error) => {
					reject(error);
				});
		});

		return processPromise;
	},
	async processdocumentaction(data) {
		let processPromise = new Promise((resolve, reject) => {
			axios
				.post("document/processdocumentaction/", data)
				.then((resp) => {
					resolve(resp);
				})
				.catch((error) => {
					reject(error);
				});
		});

		return processPromise;
	},
	async deletePart(data) {
		let processPromise = new Promise((resolve, reject) => {
			axios
				.post("document/removedocpart/", data)
				.then((resp) => {
					resolve(resp);
				})
				.catch((error) => {
					reject(error);
				});
		});

		return processPromise;
	},
	async saveItemOrder(data) {
		let processPromise = new Promise((resolve, reject) => {
			axios
				.post("document/savedocpartsequence/", data)
				.then((resp) => {
					resolve(resp);
				})
				.catch((error) => {
					reject(error);
				});
		});

		return processPromise;
	},
	async auditSectionChange(data) {
		let processPromise = new Promise((resolve, reject) => {
			axios
				.post("document/auditSectionChange/", data)
				.then((resp) => {
					resolve(resp);
				})
				.catch((error) => {
					reject(error);
				});
		});

		return processPromise;
	},
	async saveContentPart(data) {
		let processPromise = new Promise((resolve, reject) => {
			axios
				.post("document/saveDocContentPart/", data)
				.then((resp) => {
					resolve(resp);
				})
				.catch((error) => {
					reject(error);
				});
		});

		return processPromise;
	},
	async deleteDefaultView(data) {
		let processPromise = new Promise((resolve, reject) => {
			axios
				.post("admin/docViewDeleteDefaultPermission/", data)
				.then((resp) => {
					resolve(resp);
				})
				.catch((error) => {
					reject(error);
				});
		});

		return processPromise;
	},
	async saveDefaultView(data) {
		let processPromise = new Promise((resolve, reject) => {
			axios
				.post("admin/docViewSaveDefaultPermission/", data)
				.then((resp) => {
					resolve(resp);
				})
				.catch((error) => {
					reject(error);
				});
		});

		return processPromise;
	},
	async addSectionHrPerm(data) {
		let processPromise = new Promise((resolve, reject) => {
			axios
				.post("admin/docViewAddSectionHierarchyPermission/", data)
				.then((resp) => {
					resolve(resp);
				})
				.catch((error) => {
					reject(error);
				});
		});

		return processPromise;
	},
	async updateDocPart(data) {
		let processPromise = new Promise((resolve, reject) => {
			axios
				.post("document/savedocpart/", data)
				.then((resp) => {
					resolve({ type: "Updated", request: data, result: resp.data });
				})
				.catch((error) => {
					reject(error);
				});
		});

		return processPromise;
	},
	async saveView(data) {
		let processPromise = new Promise((resolve, reject) => {
			axios
				.post("admin/docViewCreate/", data)
				.then(resp => {
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});

		return processPromise;
	},
	async deleteSectionPermission(tmpl_view_section_permission_ids) {
		let processPromise = new Promise((resolve, reject) => {
			axios
				.delete("admin/deleteDocViewSectionPermission/" + tmpl_view_section_permission_ids)
				.then(resp => {
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});

		return processPromise;
	},
	async deletePartPermission(tmpl_view_section_part_permission_ids) {
		let processPromise = new Promise((resolve, reject) => {
			axios
				.delete("admin/deleteDocViewPartPermission/" + tmpl_view_section_part_permission_ids)
				.then(resp => {
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});

		return processPromise;
	},
	async deletePartHrPermission(id) {
		let processPromise = new Promise((resolve, reject) => {
			axios
				.delete("admin/deleteDocViewPartHrPermission/" + id)
				.then(resp => {
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});

		return processPromise;
	},
	async deleteSectionHrPermission(id) {
		let processPromise = new Promise((resolve, reject) => {
			axios
				.delete("admin/deleteDocViewSectionHrPermission/" + id)
				.then(resp => {
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});

		return processPromise;
	},
	async deletePermission(tmpl_view_permissions_id) {
		let processPromise = new Promise((resolve, reject) => {
			axios
				.delete("admin/deleteDocViewPermission/" + tmpl_view_permissions_id)
				.then(resp => {
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});

		return processPromise;
	},
	async savePartPermission(data) {
		let processPromise = new Promise((resolve, reject) => {
			axios
				.post("admin/docViewAddPartPermission/", data)
				.then(resp => {
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});

		return processPromise;
	},
	async docViewSaveSectionParts(data) {
		let processPromise = new Promise((resolve, reject) => {
			axios
				.post("admin/docViewSaveSectionParts/", data)
				.then(resp => {
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});

		return processPromise;
	},
	async docViewSaveSection(data) {
		let processPromise = new Promise((resolve, reject) => {
			axios
				.post("admin/docViewSaveSection/", data)
				.then(resp => {
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});

		return processPromise;
	},
	async saveSectionPermission(data) {
		let processPromise = new Promise((resolve, reject) => {
			axios
				.post("admin/docViewAddSectionPermission/", data)
				.then(resp => {
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});

		return processPromise;
	},
	async savePermission(data) {
		let processPromise = new Promise((resolve, reject) => {
			axios
				.post("admin/docViewAddPermission/", data)
				.then(resp => {
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});

		return processPromise;
	},
	async loadSettings(data) {
		let processPromise = new Promise((resolve, reject) => {
			axios
				.post("document/docViews/", data)
				.then(resp => {
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});

		return processPromise;
	},
	async loadWorkflowData(id) {
		let processPromise = new Promise((resolve, reject) => {
			axios
				.get("workflow/admin/" + id)
				.then(resp => {
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});

		return processPromise;
	},
	mapCssStyles(tag, attr_styles) {
		let base = "doc-view-font";
		if (tag !== undefined && tag !== "") {
			if (Array.isArray(tag)) {
				return this.mapDocViewFonts(tag);
			}
			let items = tag.split("|");
			if (Array.isArray(items)) {
				let allStyles = items.filter(x => x).map(t => {
					return this.getCssStyle(t)
				});
				let attrStyles = attr_styles ? attr_styles.split(" ").map(t => {
					return this.getCssStyle(t)
				}) : [];
				return `${base} ${allStyles.filter(x => x).join(" ")} ${attrStyles.filter(x => x).join(" ")}`;
			} else {
				return this.getCssStyle(items);
			}
		}
	},
	mapDocViewFonts(styles) {
		let base = "doc-view-font";
		if (styles) {
			let allStyles = styles.map(s => {
				return s.toLowerCase()
			});
			return `${base} ${allStyles.join(" ")}`;
		}
	},
	getCssStyle(style) {
		switch (style) {
			case "BoldFlow":
				return "bold";
			case "HeaderPart":
				return "bold header";
			case "Title":
				return "bold header";
			case "CentralTitle":
				return "central header bold";
			case "CentralHeaderPart":
				return "central header";
			case "Bold":
				return "bold";
			case "text-right":
				return "text-right";
			default:
				return "";
		}
	},

	flowStyling(style) {
		switch (style) {
			case "":
				return "flow-font";
			case "bold":
				return "flow-font-bold";
			case "header":
				return "flow-font-header";
			case "line":
				return "flow-font-line-spacer";
			default:
				return style;
		}

	},
	dummyAttribute(part, partType) {
		return {
			active: true,
			allow_adding: true,
			cols: 12,
			css_class_name: "",
			doc_part_id: part.doc_part_id,
			default_text: partType.tmpl_part_metadata?.default_text ?? "",
			include_in_part_val: true,
			locked: partType.tmpl_part_metadata.tp_locked === 1,
			lookup_dependency_tpa_source: null,
			lookup_key: null,
			lookup_type: null,
			lookup_v2_tpa_destination: null,
			lookup_v3_tpa_destination: null,
			linked_parts: [],
			position: 1,
			prefix: null,
			required: null,
			restrict_to_date: null,
			restrict_to_lookup: null,
			rank_attribute: null,
			separator: null,
			suffix: null,
			title: null,
			tpa_id: null,
			tmpl_part_id: partType.tmpl_part_id,
			text: part.text,
			is_essential: partType.is_essential,
			previousValue: part.text,
			isLookup: false,
			tag_type_id: null,
			isDependentLookup: false,
			view_dependencies: [],

			editing: false,
			isDirty: true,
			restrict_to_htid: 0,
			restrict_to_ht_level: 0,
		};
	},

	instantiateNewRow(data, partType, parent, attr = null, text = "", idx = null) {
		// let currentParts = (parent ? parent.childParts : partType.parts).filter(
		//     (x) => x.tmpl_part_id === partType.tmpl_part_id
		// );
		let currentParts = partType.parts;
		let sequence = idx !== null ? idx : currentParts.length
			? currentParts.reduce(
				(pval, cval) => (pval > cval.sequence ? pval : cval.sequence),
				0
			) + 1
			: 1;
		let ret = {
			Status: "",
			StatusList: [],
			WordCount: "",
			doc_part_id: 0,
			id: "",
			notes: "",
			isDirty: false,
			rowMessage: "",
			rowDirty: false,
			sequence: sequence,
			status: "",
			text: text,
			is_essential:
				partType.tmpl_part_metadata.uses_essential_flag === 2
					? this.tempEssentials
						? null
						: 1
					: 0,
			attributes: [],
			quality: 0,
			type: partType.type,
			tmpl_part_id: partType.tmpl_part_id,
			parent_dp_id: parent ? parent.doc_part_id : null,
			highlightSection: false,
			editing: false,
			hover: false,
			moveable: partType.tmpl_part_metadata.moveable,
			expanded: false,
			active: true,	// copied from formatDocPart
			locked: partType.tmpl_part_metadata.tp_locked, // copied from formatDocPart, but based on parent there      linked_content: [],
			childPartTypes: [],
			linked_content: [],
			totalChildren: 0,
		};
		if (partType.tmpl_part_metadata.attributes.length) {
			ret.attributes = partType.tmpl_part_metadata.attributes.map((a) => {
				return {
					...a,
					dpa_id: 0,
					text: attr && a.tpa_id === attr.tpa_id ? text : a.default_text,
					editing: false,
					isDirty: true,
					active: true,
					tags: [],
				};
			});
		} else {
			ret.attributes.push(this.dummyAttribute(ret, partType));
		}

		if (partType.childPartTypes && partType.childPartTypes.length) {
			ret.childPartTypes = JSON.parse(JSON.stringify(partType.childPartTypes));
			ret.childPartTypes.forEach(cpt => cpt.parts = []);
		}
		utils.setDocPartStatus(
			ret,
			partType,
			data.wordChecks,
			data.docStatus,
			data.document
		);

		if (parent !== undefined && parent.temp === true) {
			ret.temp = true;
		}

		return ret;
	},
};