<template>
    <div v-if="value">
        <template v-for="(t, ti) in value">
            <h3 v-if="t.header && value.length > 1"
                :class="['mb-2', 'userPilotOAIData' + t.header.replace(/\s+/g, '')]" :key="'h' + ti">{{ t.header }}</h3>
            <p v-if="t.isList && t.parts && t.parts.length" :key="'l' + ti">
            <ul>
                <li v-for="(r, ri) in t.parts" :key="`rl_${ti}_${ri}`">
                    {{ r }}
                </li>
            </ul>
            </p>
            <template v-else-if="t.parts && t.parts.length" v-for="(r, ri) in t.parts">
                <p :key="`rp_${ti}_${ri}`">{{ r }}</p>
            </template>
        </template>
    </div>
</template>

<script>

export default {
    name: 'AIContent',
    props: {
        value: Array
    },
    data: function () {
        return {
        }
    },
    components: {
    },
    watch: {
    },
    computed: {
    },
    mounted() {
    },
    updated() {
    },
    created() {
    },
    methods: {

    }
}
</script>

<style scoped lang="scss">
::selection {
  background: #ffeb0073;
}
</style>