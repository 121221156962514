<template>
<div>
    <div>
        <v-row>
            <v-col align="right" style="padding-right:25px">
                <v-spacer></v-spacer>
                <v-btn color="primary" outlined @click="addTemplateExportSection">Add New Export Section</v-btn>  
                <v-btn color="primary" @click="save" style="margin-left:10px">Save</v-btn>
            </v-col>
        </v-row>
    </div>
    <div style="padding-left:15px; padding-right:5px; height:calc(100vh - 165px); max-height:calc(100vh - 165px); overflow: hidden scroll; margin-top:10px">
        <v-expansion-panels multiple accordion>
            <v-expansion-panel
            v-for="(section,idx) in exportSections"
            :key="idx"
            >
            <v-expansion-panel-header style="padding-left:10px">{{section.section}}</v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-row dense>
                <v-col cols="3">Section Name:</v-col>
                <v-col cols="3">
                    <v-text-field dense v-model="section.section"></v-text-field>
                </v-col>
                <v-col cols="3"></v-col>
                <v-col cols="3" align="left">
                    <v-switch
                    v-model="section.is_active"
                    label="Active?"
                    ></v-switch>
                </v-col>
                </v-row>
                <v-row dense>
                <v-col cols="6">
                    Internal Content: <br/>
                    <v-textarea
                    rows="10"
                    outlined
                    auto-grow
                    v-model="section.section_content_internal"
                    ></v-textarea>
                </v-col>
                <v-col  cols="6">
                    External Content: <br/>
                    <v-textarea
                    rows="10"
                    outlined
                    auto-grow
                    v-model="section.section_content_external"
                    ></v-textarea>
                </v-col>
                </v-row>
            </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>    

    <Loading :isVisible="isLoading" />
    <ResponseHandler :serviceResponse="response"></ResponseHandler>          
</div>

</template>


<script>
import axios from "axios";
import ResponseHandler from "@/components/ResponseHandler";

export default {
  name: "templateExportSections",
  components: {
    ResponseHandler
  },
  props: {
    value: { type: Number, required: false },
  },
  data: function () {
    return {
      isLoading: false,
      response: null,        
      exportSections: []
    };
  },
  mounted() {},
  created() {
    this.init();
  },
  watch: {
    value() {
      this.init();
    },
  },
  computed: {
  },
  methods: {
    addTemplateExportSection(){
      this.exportSections.push({
        section: "New Section",
        section_content_internal: "",
        section_content_external: "",
        is_active: true,
        tmpl_export_section_id: 0
      });
    },        
    sessionExpired(err) {
      this.$emit("sessionExpired", err);
    },
    save(){
        let data = {
            tmpl_id: this.value,
            sections: this.exportSections
        }

        let possibleError = false;
        this.isLoading = true;

        axios
        .post("templateBuilder/saveTemplateExportSections", data)
        .then((resp) => {
          possibleError = true;
          this.exportSections = resp.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
    init() {
      let possibleError = false;
      this.isLoading = true;
      axios
        .get("templateBuilder/templateExportSections/" + this.value)
        .then((resp) => {
          possibleError = true;
          this.exportSections = resp.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
  },
};
</script>
