<template>
    <div style="width:100%; padding-top:5px" :class="[getCssStyles(metadata)]">
        <div v-if="metadata.content_type ==='LABEL'">
            <label v-html="metadata.content_text" ></label>
        </div>
        <div v-else-if="metadata.content_type ==='TEXTAREA'"  style="">
            <v-textarea
                :placeholder="metadata.content_placeholder"
                auto-grow
                hide-details
                variant="filled"
                outlined
                rows="7"
                row-height="25"
                :disabled="!metadata.active || disabled"
                v-model="text"
                @input="changedData"
                @blur="doUpdate"
            ></v-textarea>
        </div>
        <div v-else-if="metadata.content_type ==='TEXTBOX'"  style="">
            <v-text-field
                :placeholder="metadata.content_placeholder"
                type="input"
                dense
                hide-details
                outlined
                class="textTheme"
                v-model="text"
                :disabled="!metadata.active || disabled"
                @input="changedData"
                @blur="doUpdate"
            ></v-text-field>
        </div>
        <div v-else-if="metadata.content_type ==='TABLE'"  style="">
            <table width="100%" class="contentTable"  cellspacing="0" cellpadding="0" :style="cssVars">
                <thead>
                    <tr>
                        <th v-for="(col,cIdx) in metadata.content_metadata.columns" :key="`hdr_${cIdx}`" :width="`${100/metadata.content_metadata.columns.length}%`" style="text-align:left">
                            {{ col.header }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="val in value.rows" :key="val.idx">
                        <td v-for="(col,cIdx) in val.data" :key="`row_${cIdx}`" :width="`${100/metadata.content_metadata.columns.length}%`" style="text-align:left">
                            <v-text-field
                                v-if="col.type === 'TEXT'"
                                type="input"
                                v-model="col.value"
                                dense
                                :label="col.header"
                                :disabled="!metadata.active || disabled"
                                @input="changedData"
                                @blur="doUpdate(val)"
                                hide-details
                                outlined
                            ></v-text-field>

                            <v-select
                                v-else-if="col.type === 'DROPDOWN'"
                                flat
                                outlined
                                :label="col.header"
                                :items="col.data"
                                v-model="col.value"
                                @input="changedData"
                                :disabled="!metadata.active || disabled"
                                dense
                                bg-color="#FFFFFF00"
                                class="slimSelect"
                                item-text="text"
                                item-value="value"
                                style="top: 12px;"
                                @change="doUpdate(val)"
                            >
                            </v-select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import viewUtils from "./utils.js";

export default {
    name: 'ViewContent',
    props: {
        value: Object,
        metadata: Object,
        disabled: Boolean,
    },
    data: function() {
        return {
            text: null,
            isDirty: false,
        }
    },
    components: {

    },
    watch: {
        value(obj){
            if(obj){
                this.init();
            }
        }
    },    
    computed: { 
        cssVars () {
            return {
                '--table-header-row-colour': this.metadata.content_metadata.header_colour,
                '--table-row-even-colour': this.metadata.content_metadata.even_row_colour,
                '--table-row-odd-colour': this.metadata.content_metadata.odd_row_colour
            }
        },
    },
    mounted() {

    },
    created() {
        this.init();
    },    
    methods: {
        changedData(){
            this.isDirty = true;
        },
        doUpdate(val){
            if(this.metadata.content_type ==='TABLE'){
                let updateData = {
                    doc_view_content_id: val.doc_view_content_id,
                    doc_part_id: this.value.dp_id,
                    tmpl_view_section_part_id: this.value.tmpl_view_section_part_id,
                    sequence: val.sequence,
                    value: JSON.stringify(val.data)
                }
                this.$emit("update", updateData);
                this.isDirty = false;
            } else {
                if(this.isDirty && this.value.originalValue !== this.value.content_text){
                    let updateData = {
                        doc_view_content_id: this.value.doc_view_content_id,
                        doc_part_id: this.value.dp_id,
                        tmpl_view_section_part_id: this.value.tmpl_view_section_part_id,
                        sequence: this.value.sequence,
                        value: this.text
                    }              
                    this.$emit("update", updateData);
                    this.isDirty = false;
                }
            }

        },
        init(){
            if(this.value){
                this.text = this.value.value;
            }
            //if(this.value.document_content.length){
                //this.setValues();
            //}
        },
        getCssStyles(metadata){
            if(metadata.layout){
                let layout = [...metadata.layout];
                if(!metadata.active){
                    layout.push("disabled");
                }
                return viewUtils.mapDocViewFonts(layout);
            }
        },
    }
}
</script>

<style scoped lang="scss">
    [contenteditable]:not([data-placeholder=""]):empty::before {
        content: attr(data-placeholder);
        color: #ed8a8a !important;
        cursor: pointer;
        font-style: italic;
        font-weight: 300 !important;  
    }

    .textTheme {
        font-family: 'DM Sans';
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #75838F;
    }

    .doc-view-font.central{
        margin-left:12px;

        .disabled{
            text-decoration: line-through;
        }
    }

    .contentTable{
        width: 100%;
        thead tr {
            background-color: var(--table-header-row-colour);
            th {
                padding:5px;
            }
        }
        tbody tr td {
            padding:5px;
        }

        tbody tr:nth-child(even) {
            background-color: var(--table-row-even-colour);
        }

        tbody tr:nth-child(odd) {
            background-color: var(--table-row-odd-colour);
        }
    }
</style>