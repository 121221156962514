<template>
  <div>
    <v-tabs
      @change="testMethod"
      ref="tabs"
      v-model="tab"
      class="primary-background"
    >
      <v-tab v-for="item in tabItems" :key="item.name">
        {{ item.name }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in tabItems" :key="item.name">
        <v-card v-if="item.name === 'User Logins Report'" flat>
          <UserMaintenanceReport />
        </v-card>
        <v-card v-if="item.name === 'User Impersonations'" flat>
          <UserImpersonationReport />
        </v-card>
        <!--<v-card v-if="item.name === 'User Document Report'" flat> 
            <UserDocumentReport />
          </v-card> -->
        <v-card v-if="item.name === 'ATS Export Report'" flat>
          <ATSExportReport />
        </v-card>
        <v-card v-if="item.name === 'Intake Flow Report'" flat>
          <IntakeReport />
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import UserMaintenanceReport from "../components/cUserMaintenanceReport.vue";
import UserImpersonationReport from "../components/cUserImpersonationReport.vue";
//import UserDocumentReport from '../components/cUserDocumentReport.vue'
import IntakeReport from "./cIntakeReport.vue";
import ATSExportReport from "./cATSReport.vue";

export default {
  name: "reportHolder",
  data: function () {
    return {
      tab: null,
      reports: [
        { name: "User Logins Report", text: "" },
        { name: "User Impersonations", text: "" },
        { name: "ATS Export Report", text: "" },
        { name: "Intake Flow Report", text: "" },
      ],
      clientReports: [
        "User Logins Report",
        "ATS Export Report",
        "Intake Flow Report",
      ],
      adminReports: [
        "User Logins Report",
        "User Impersonations",
        "ATS Export Report",
        "Intake Flow Report",
      ],
    };
  },
  computed: {
    tabItems() {
      const enabledReports = this.$loginState.canAdmin ? this.adminReports : this.clientReports;
      return this.reports.filter(r => enabledReports.includes(r.name));
    },
  },
  mounted() {
    const clientReportsSetting = this.$loginState.user.settings.find((s) => s.setting === "client_usage_reports")?.value;
    if (clientReportsSetting)
      this.clientReports = clientReportsSetting.split("|");

    const adminReportsSetting = this.$loginState.user.settings.find((s) => s.setting === "admin_usage_reports")?.value;
    if (adminReportsSetting)
      this.adminReports = adminReportsSetting.split("|");

    if (this.$route.params.reportName) {
      let reportTab = this.tabItems.findIndex(
        (tab) => tab.name === this.$route.params.reportName
      );
      if (reportTab) {
        this.tab = reportTab;
      }
    }
  },
  created: function () {},
  components: {
    UserMaintenanceReport,
    UserImpersonationReport,
    //UserDocumentReport,
    ATSExportReport,
    IntakeReport,
  },
  methods: {
    testMethod() {
      //alert(this.tab);
    },
  },
};
</script>