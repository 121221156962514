<template>
  <v-snackbar
    v-model="show"
    :color="status"
    :multi-line="message.length > 50"
    :timeout="timeout"
    top
  >
    <div class="d-flex justify-space-between align-center">
      <div class="text-subtitle-1" v-html="message"></div>
      <v-btn dark text @click="show = false">Close</v-btn>
    </div>
  </v-snackbar>
</template>


<script>
export default {
  name: "responseHandler",
  props: {
    serviceResponse: Object,
  },
  data: function () {
    return {
      show: false,
      status: "success",
      message: "",
      timeout: 3000,
    };
  },
  created() {
    this.showMessage();
  },
  watch: {
    serviceResponse() {
      this.showMessage();
    },
  },
  methods: {
    showMessage() {
      if (this.serviceResponse) {
        let show = false;	
        if (this.serviceResponse.Status === "OK") {
          if (this.serviceResponse.Message) {
            this.message = this.serviceResponse.Message;
            this.status = "success";
            this.timeout = this.serviceResponse.Timeout || 3000;
            show = true;
          }
        } else {
          this.status = this.serviceResponse.Status?.toLowerCase();
          if (!["success", "info", "warning", "error"].includes(this.status))
            this.status = "error";
          this.message =
            (this.status === "error" ? "Error: " : "") +
            (this.serviceResponse.Message ||
              this.serviceResponse.message ||
              "Unexpected error");
          this.timeout = -1;
          show = true;
        }
        this.show = show;
      } else {
        this.show = false;
      }
    },
  },
};
</script>
<style>
.v-snack__content a {
  color: #d0eede;
}
</style>