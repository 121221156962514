<template>
  <v-dialog v-model="show" persistent @keydown.esc="close()">
    <v-card class="px-1 py-2">
      <v-card-title class="d-flex align-center">
        <div class="subtitle-1" v-if="option === 'questions'">Please answer the following questions to understand the nature of your role</div>
        <div class="subtitle-1" v-else>RoleMapper has suggested the following flexible working patterns for your role</div>
        <v-spacer />
        <v-btn icon large class="btn-background" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text dense class="pb-2">
        <RoleFlexQuestions
          ref="flexQuestions"
          v-if="option === 'questions'"
          :documentId="documentId"
          :open="open"
          :fixedResults="false"
          @completed="option = 'options'"
        >
        </RoleFlexQuestions>
        <RoleFlexOptions
          ref="flexOptions"
          v-else
          :documentId="documentId"
          :open="open"
          :fixedResults="fixedResults"
          :allowClose="true"
          @closed="option = 'questions'"
          @flexupdated="updateDoc"
        >
        </RoleFlexOptions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import RoleFlexQuestions from "@/components/cRoleFlexQuestions";
import RoleFlexOptions from "@/components/cRoleFlexOptions";

export default {
  components: {
    RoleFlexQuestions,
    RoleFlexOptions,
  },
  props: {
    watchedObject: {}, //{ text: '', closed: false } // this is an option from pages with high volumes of data
    documentId: String,
    open: Boolean,
    fixedResults: Boolean,
  },
  data: function () {
    return {
      show: false,
      option: "questions"
    };
  },
  watch: {
    open() {
      this.show = !!this.open;
      if (this.show) {
        this.option = "questions";
      }
    },
  },
  computed: {
  },
  methods: {
    close() {
      this.show = false;
      if (this.watchedObject) {
        const updated = this.watchedObject;
        updated.closed = true;
        this.$emit("updated:watchedObject", updated);
      } else {
        this.$emit("closed");
      }
    },
    updateDoc(vm) {
      this.$emit("flexupdated", vm);
      this.close();
    },
  },
};
</script>
<style scoped lang="scss">
</style>
