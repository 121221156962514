<template>
  <v-card v-if="vm" flat>
    <v-card-text class="pa-0">
      <v-row style="min-height: 480px;" class="ma-0">
        <v-col cols="4" class="tabsCol">
          <v-tabs v-model="secSel" vertical>
            <v-tab
              v-for="sec in vm.sections"
              :key="`'Tab${sec.order}`"
              editable
              @click="sectionSelected(sec.order)"
              :class="[
                secSel + 1 == sec.order ? 'currStepHeading' : 'stepHeading',
                'd-flex justify-space-between pl-4 pr-4',
              ]"
            >
              <div class="d-flex align-center">
                <v-avatar size="24">
                  {{ sec.order }}
                </v-avatar>
                <div class="text-left d-flex flex-column">
                  {{ sec.name }}&nbsp;
                  <div v-if="sec.complete">
                    <span
                      :class="['secOutcome', `${sec.outcomeColour}--text`]"
                      >{{ sec.OutcomeFull }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="d-flex align-center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      :color="sec.showDesc ? 'primary' : ''"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <div style="max-width: 500px" v-html="sec.description"></div>
                </v-tooltip>
              </div>
            </v-tab>
          </v-tabs>
        </v-col>
        <v-col cols="8" class="questCol">
          <v-tabs-items v-model="secSel" vertical>
            <v-tab-item v-for="sec in vm.sections" :key="`Tab${sec.order}`">
              <v-card class="ma-1" flat>
                <v-stepper
                  v-model="questSel"
                  class="questionStepper elevation-0"
                >
                  <v-stepper-header class="questionHeader elevation-0">
                    <template v-for="quest in sec.questions">
                      <v-stepper-step
                        :complete="!!quest.response && quest.order !== questSel"
                        :key="`${quest.order}-qStep`"
                        :step="quest.order"
                        style="cursor: pointer"
                        @click="questionSelected(quest.order)"
                      >
                        <v-icon v-if="!!quest.response && quest.order !== questSel" small left>check</v-icon>
                        Question {{ quest.order }}
                      </v-stepper-step>
                      <!-- <v-divider
                        :key="`${quest.order}-qdStep`"
                        class="stepHeadDivider"
                      ></v-divider> -->
                    </template>
                    <v-stepper-step
                      style="cursor: pointer"
                      @click="questionSelected('R')"
                      step="R"
                      :complete="sec.complete"
                    >Outcome</v-stepper-step>
                  </v-stepper-header>

                  <v-stepper-items>
                    <template v-for="quest in sec.questions">
                      <v-stepper-content
                        :key="`${quest.order}-qContent`"
                        :step="quest.order"
                        class="questionStep"
                        editable
                      >
                        <v-row class="mx-3">
                          <v-col cols="12">
                            <span class="body-1" v-html="quest.question"></span>
                          </v-col>
                          <v-col>
                            <v-radio-group
                              v-model="quest.response"
                              class="responses"
                              @change="nextQuestion(sec)"
                              :error="validationError"
                            >
                              <v-row>
                                <template v-for="(resp, index) in quest.options">
                                  <v-col :key="'tt' + index" cols="4">
                                    <v-tooltip v-if="resp.info" bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <div v-bind="attrs" v-on="on" class="pb-2">
                                          <v-radio
                                            :value="resp.value"
                                            :label="resp.text"
                                          >
                                          </v-radio>
                                        </div>
                                      </template>
                                      <span>{{ resp.info }}</span>
                                    </v-tooltip>
                                    <v-radio
                                      v-else
                                      :value="resp.value"
                                      :label="resp.text"
                                    >
                                    </v-radio>
                                  </v-col>
                                </template>
                              </v-row>
                            </v-radio-group>
                          </v-col>
                        </v-row>
                      </v-stepper-content>
                    </template>
                    <v-stepper-content step="R" class="questionStep" editable>
                      <v-row class="mx-3">
                        <v-col cols="12" class="body-1">
                          <span
                            v-html="sec.outcomeDesc"
                            v-if="sec.complete"
                          ></span>
                          <span v-else> Please complete all questions </span>
                        </v-col>
                        <v-col class="pb-0">
                          <v-textarea
                            label="Enter comments"
                            v-model="sec.comment"
                            outlined
                            rows="1"
                            auto-grow
                            :rules="commentsRules"
                          ></v-textarea>
                        </v-col>
                        <v-col
                          v-if="sectionComplete && !allSectionsComplete"
                          cols="12"
                          class="d-flex pb-8 align-end justify-end"
                        >
                          <v-btn color="success" @click="nextSection"
                            >Continue</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions
      class="mt-5 px-5 d-flex justify-space-between align-center"
      style="min-height: 52px"
    >
      <v-dialog
        width="500"
        v-model="confirmClearDialog"
        v-if="showClearAnswers"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on"> Clear Answers </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5"> Clear Answers </v-card-title>

          <v-card-text>
            Clear all options/answers and reset document?
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="clearAnswers"> Confirm </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div v-else></div>
      <v-btn
        color="primary"
        v-if="allSectionsComplete"
        @click="saveAndComplete"
      >
        {{ fixedResults ? "Go to Results" : "Go to Options" }}
      </v-btn>
      <span v-if="!allSectionsComplete" class="pleaseComplete"
        >Please complete all sections</span
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  props: {
    watchedObject: {}, //{ text: '', closed: false } // this is an option from pages with high volumes of data
    documentId: String,
    open: Boolean,
    fixedResults: Boolean,
  },
  data: function () {
    return {
      questSel: 1,
      secSel: 0,
      option: "questions",
      wordCount: "0 words",
      vm: null,
      commentsRules: [
        (c) =>
          !c ||
          c.length <= 500 ||
          "This field exceeds maximum (500) allowed characters",
      ],
      confirmClearDialog: false,
      validationError: false
    };
  },
  created() {
    this.show = !!this.open;
    if (this.show) {
      this.fetchData();
      this.secSel = 0;
      this.questSel = 1;
      this.option = "questions";
    } else {
      this.vm = null;
    }
  },
  watch: {
    open() {
      this.show = !!this.open;
      if (this.show) {
        this.fetchData();
        this.secSel = 0;
        this.questSel = 1;
        this.option = "questions";
      } else {
        this.vm = null;
      }
    },
    secSel() {
      this.validationError = false;
      if (this.vm && this.option !== "options") this.saveAnswers(false);
    },
    questSel() {
      this.validationError = false;
    }
  },
  computed: {
    allSectionsComplete() {
      return !this.vm.sections.some((s) =>
        s.questions.some((q) => !q.response)
      );
    },
    sectionComplete() {
      return !this.vm.sections[this.secSel].questions.some((q) => !q.response);
    },
    showClearAnswers() {
      return this.$loginState.user.settings.some(
        (s) => s.setting === "flex_allow_clearanswers" && s.value !== "false"
      );
    },
  },
  methods: {
    save() {
      this.errMessage = "";
      this.show = false;
      if (this.watchedObject) {
        const updated = this.watchedObject;
        updated.closed = true;
        this.$emit("updated:watchedObject", updated);
      } else {
        this.$emit("closed");
      }
    },
    close() {
      this.show = false;
      if (this.watchedObject) {
        const updated = this.watchedObject;
        updated.closed = true;
        this.$emit("updated:watchedObject", updated);
      } else {
        this.$emit("closed");
      }
    },
    questionSelected(questSel) {
      const section = this.vm.sections[this.secSel];
      section.complete = !section.questions.some((q) => !q.response);
      this.questSel = questSel;
    },
    sectionSelected(secSel) {
      let ss = this.vm.sections.find((sec) => sec.order === secSel);
      if (ss) {
        if (ss.complete) this.questSel = "R";
        else this.questSel = ss.questions[0].order;
      }
    },
    nextSection() {
      this.secSel++;
      this.sectionSelected(this.secSel + 1);
    },
    nextQuestion(sec) {
      this.setSectionOutcome(sec);
      let maxQuest =
        this.vm.sections[this.secSel].questions[
          this.vm.sections[this.secSel].questions.length - 1
        ].order;
      if (this.questSel < maxQuest) this.questionSelected(this.questSel + 1);
      else if (this.questSel == maxQuest) this.questionSelected("R");
    },
    setVM(vm) {
      vm.sections.forEach((s) => {
        s.outcomeOrig = s.outcome;
        s.commentOrig = s.comment;
        s.complete = !s.questions.some((q) => !q.response);
        s.questions.forEach((q) => {
          q.responseOrig = q.response;
          q.isDirty = false;
        });
      });
      this.vm = vm;
    },
    fetchData() {
      this.isLoading = true;
      axios
        .get("roleflex/document/" + this.documentId)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            this.setVM(resp.data.Data);
            if (this.vm.sections[0].complete) this.questSel = "R";
            this.isLoading = false;
          }
        })
        .catch((err) => {
          this.$emit("sessionExpired", err);
          console.log(err);
          this.isLoading = false;
        });
      return;
    },
    checkAllComplete() {
      if (this.allSectionsComplete)
        return true;
      
      this.validationError = true;
      let firstUncompleted;
      this.vm.sections.some((s, si) => {
        firstUncompleted = s.questions.find((q) => !q.response);
        if (firstUncompleted) {
          this.secSel = si;
          this.sectionSelected(this.secSel + 1);
          this.questSel = firstUncompleted.order;
          return true;
        }
      });

      return false;
    },
    saveAndComplete() {
      const that = this;
      this.saveAnswers(function() {
        that.$emit('completed');
      })
    },
    saveAnswers(callback) {
      let data = [];
      this.vm.sections.forEach((dp) => {
        dp.questions
          .filter(q => q.response !== q.responseOrig)
          .forEach((q) => {
            let new_resp = q.options.find((rr) => q.response === rr.value); // get new response
            let old_resp = q.options.find((rr) => rr.dar_id); // get old response
            if (
              new_resp &&
              (!old_resp ||
                new_resp.alr_id !== old_resp.alr_id ||
                dp.comment !== dp.commentOrig ||
                dp.outcome !== dp.outcomeOrig)
            ) {
              data.push({
                doc_id: dp.doc_id,
                alq_id: q.alq_id,
                alr_id: new_resp ? new_resp.alr_id : undefined,
                comment: dp.comment,
                outcome: dp.outcome,
              });
            }
          });
      });
      if (data.length) {
        axios
          .post("roleflex/saveAnswers", data)
          .then((resp) => {
            if (resp.data.Status === "OK") {
              this.setVM(resp.data.Data);
              if (typeof callback === "function") callback();
            }
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              console.log(err);
              this.response = err.response
                ? err.response.data
                : { message: "Unexpected Error" };
            }
            this.isLoading = false;
          });
      } else {
        // nothing to save
        if (typeof callback === "function") callback();
      }
    },
    clearAnswers() {
      axios
        .delete("roleflex/document/" + this.documentId)
        .then((resp) => {
          this.confirmClearDialog = false;
          if (resp.data.Status === "OK") {
            //this.setVM(resp.data.Data);
            const vm = resp.data.Data;
            vm.options.forEach((o) => {
              o.outcome = "";
              o.outcomeDesc = "";
              o.outcomeColour = "";
            });
            vm.sections.forEach((o) => {
              o.outcome = "";
              o.outcomeDesc = "";
              o.outcomeColour = "";
            });
            //this.updateDoc(vm);
            this.setVM(vm);
            this.secSel = 0;
            this.questSel = 1;
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
          this.confirmClearDialog = false;
        });
    },
    setSectionOutcome(sec) {
      sec.outcome = "Low";
      switch (sec.name) {
        case "Capability and Expertise":
          if (sec.questions.some((q) => q.response === 3)) {
            sec.outcome = "High";
          } else if (sec.questions.some((q) => q.response === 2)) {
            sec.outcome = "Med";
          }
          break;
        case "Workflow Regularity":
          if (sec.questions.some((q) => q.response === 1)) {
            sec.outcome = "Low";
          } else if (sec.questions.some((q) => q.response === 2)) {
            sec.outcome = "Med";
          } else {
            sec.outcome = "High";
          }
          break;
        case "Location Flexibility":
          if (sec.questions.some(q => q.order < 4 && q.response === 1)) {
						sec.outcome = "Office";
					} else if (sec.questions.some(q => q.order === 4 && q.response <= 2)) {
						sec.outcome = "Field";
					} else {
						sec.outcome = "Flex";
					}
          break;
        default:
          if (
            sec.questions.some(
              (q) => q.response === 1 && q.order !== 7 && q.order !== 8
            )
          ) {
            sec.outcome = "High";
          } else if (
            sec.questions.some(
              (q) => q.response === 1 && (q.order === 7 || q.order === 8)
            ) ||
            sec.questions.some((q) => q.response === 2)
          ) {
            sec.outcome = "Med";
          }
          break;
      }
      switch (sec.outcome) {
        case "High":
        case "Office":
          sec.outcomeColour =
            sec.name === "Capability and Expertise" ? "green" : "red";
          sec.OutcomeFull = sec.outcome;
          break;
        case "Med":
          sec.outcomeColour = "orange";
          sec.OutcomeFull = "Medium";
          break;
        case "Field":
          sec.outcomeColour = "orange";
          sec.OutcomeFull = sec.outcome;
          break;
        case "Low":
        case "Flex":
          sec.outcomeColour =
            sec.name === "Capability and Expertise" ? "red" : "green";
          sec.OutcomeFull = sec.outcome;
          break;
        default:
          sec.outcomeColour = "";
          sec.OutcomeFull = "";
          break;
      }
      let outDesc = this.vm.sectionOutcomes.find((od) => {
        return (
          od.rfOutcome == sec.outcome.toUpperCase() &&
          od.rfSection == sec.name.toUpperCase()
        );
      });
      if (outDesc) sec.outcomeDesc = outDesc.rfDescription;
      else sec.outcomeDesc = "Please leave any comments regarding this section before continuing to the next.";
    },
    getControlColor(values, step) {
      if (step == 1) {
        if (values > 4) return "green darken-2";
        if (values > 3) return "green lighten-2";
        if (values > 2) return "orange";
        if (values > 1) return "orange darken-2";
        if (values > 0) return "deep-orange";
        if (values === 0) return "deep-orange darken-2";
      } else {
        if (values > 4) return "green darken-2";
        if (values > 2) return "green lighten-2";
        if (values > 0) return "orange";
      }
      return "red";
    },
    getTextColor(values, step) {
      if (step == 1) {
        if (values > 4) return "#388E3C";
        if (values > 3) return "#81C784";
        if (values > 2) return "orange";
        if (values > 1) return "#F57C00";
        if (values > 0) return "#FF5722";
        if (values === 0) return "#F4511E";
      } else {
        if (values > 4) return "#388E3C";
        if (values > 2) return "#81C784";
        if (values > 0) return "orange";
      }
      return "red";
    },
    optValueIncremented(opt, q, inverse) {
      q.selected = q.selected + (inverse ? -1 : 1) * q.respStep;
      if (q.selected < q.respLowerRange) {
        q.selected = q.respLowerRange;
        return;
      } else if (q.selected > q.respUpperRange) {
        q.selected = q.respUpperRange;
        return;
      }
      this.optValueChanged(opt);
    },
    optValueChanged(opt) {
      opt.accepted = false;
      this.checkOptChanged(opt);
    },
    checkOptChanged(opt) {
      opt.changed = opt.questions.some((q) => q.calculated !== q.selected);
      opt.error =
        opt.changed && !opt.comment
          ? "Option changed from recommendation - reason is required"
          : "";
      return !opt.error;
    },
    countAllWords(subParts) {
      let data = subParts.map((sp) => sp.text).join(" ");
      let count = data ? data.split(" ").filter((x) => x.length).length : 0;
      return count + " words";
    },
    allAccepted() {
      return this.fixedResults || !this.vm.options.some((o) => !o.accepted);
    },
  },
};
</script>
<style scoped lang="scss">

::v-deep .v-tabs-slider-wrapper {
  left: auto !important;
  right: 0;
}

.tabsCol {
  padding: 0;
  border: 1px solid #e6eaf0;

  .v-avatar {
    border: 1.5px solid #D5DDE3;
    color: #D5DDE3;
    margin-right: 10px;
    font-size: 14px;
    padding-top: 2px;
  }

  .v-tab--active {
    .v-avatar {
      border-color: #377DFF;
      color: #377DFF;
    }
  }
}

.questCol {
  border-top: 1px solid #e6eaf0;
  border-bottom: 1px solid #e6eaf0;
  border-right: 1px solid #e6eaf0;
  padding: 0 12px 12px 0;
}

::v-deep .v-stepper {
  margin-top: 10px;
  .questionHeader {
    margin: 0 auto 0 auto;
    height: 48px;
    max-width: 800px;
    border-radius: 8px;
    .v-stepper__step {
      margin: 5px;
      padding: 0 10px;
      border-radius: 4px;
      @media (min-width: 960px) {
        .v-stepper__step__step {
          display: none;
        }
      }
      .v-stepper__label {
        flex-direction: row;
        align-items: center;
      }
    }
  }
}

::v-deep .v-stepper.theme--light {
  .questionHeader {
    background-color: #F5F6F7;
    .v-stepper__step {
      @media (min-width: 960px) {
        &.v-stepper__step--active {
          background-color: #fff;
        }
        &:not(.v-stepper__step--active):hover {
          background-color: rgb(252, 252, 252);
        }
      }
      .v-stepper__label {
        color: black !important;
      }
      &.v-stepper__step--complete {
        .v-stepper__label, .v-icon {
          color: #00B515 !important;
        } 
      }
    }
  }
}

::v-deep .v-stepper.theme--dark {
  .questionHeader {
    .v-stepper__step {
      @media (min-width: 960px) {
        &.v-stepper__step--active {
          background-color: rgb(104, 104, 104);
        }
        &:not(.v-stepper__step--active):hover {
          background-color: rgb(110, 110, 110);
        }
      }
      .v-stepper__label {
        color: white !important;
      }
      &.v-stepper__step--complete {
        .v-stepper__label, .v-icon {
          color: #00B515 !important;
        } 
      }
    }
  }
}

.currStepHeading,
.stepHeading {
  min-height: 80px;
  min-width: 100%;
  padding: 0;
  border-bottom: 1px solid #e6eaf0;
  text-transform: none;
  font-size: 16px;
}

.v-tabs.theme--light .currStepHeading {
  background-color: #f1f7fc;
}

.currStepHeading {
  font-weight: bold;
}

.secOutcome {
  text-transform: uppercase;
}

.pleaseComplete {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 12px 16px 8px;
  border-radius: 8px;
}

.theme--light .pleaseComplete {
  background: #f5f6f7;
}

.v-radio.theme--light {
  background: #F8F9FC;
  border: 1px solid #E6EAF0;
  border-radius: 8px;
  padding: 8px 16px;
  &.v-item--active {
    background: #FFFFFF;
    border: 1.5px solid #377DFF;
  }
}

.v-input--has-state .v-radio.theme--light {
  border: 1px solid #ff5252;
}

</style>
