import axios from "axios";
import utils from "@/common/utils.js";

export const state = () => ({
	wordChecks: {}
});

export const mutations = {
	unload(state) {
		state.wordChecks = {};
	},
	cacheWordChecks(state, data) {
		state.wordChecks[data.tmplId] = data;
	}
};

export const actions = {
	unload({ commit }) {
		commit("unload");
	},
	getTemplateWordChecks({ commit, getters }, tmplId) {

		return new Promise((resolve, reject) => {
			let state = getters.wordChecks(tmplId);
			let fifteenMinutesAgo = Date.now() - (1000 * 60 * 15);

			if (state && state.cached > fifteenMinutesAgo) {
				resolve(state.wordChecks);
			} else {
				axios.get(`document/wordchecks/${tmplId}`)
					.then((resp) => {
						this.isLoading = false;
						if (resp.data.Status === "OK") {
							state = {
								tmplId: tmplId,
								cached: new Date(),
								wordChecks: utils.initialisePartChecks(
									resp.data.Data
								)
							} 

							commit("cacheWordChecks", state);

							resolve(state.wordChecks);
						}
					})
					.catch(error => {
						reject(error);
					});
			}
		})
	}
};

export const getters = {
	wordChecks: (state) => (tmplId) => {
		return state.wordChecks[tmplId];
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
