<template>
  <div style="display: inline">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-row
          v-if="column"
          dense
          class="columnPicker"
          v-bind="attrs"
          v-on="on"
          :title="column.name"
        >
          <v-col @click="mode = ''" :cols="10 - (allowStyling ? 1 : 0) - (allowRemove ? 1 : 0)">
            <div class="pickerContent">{{ column.name }}</div>
          </v-col>
          <v-col cols="1">
            <v-icon @click="mode = ''"
              class="columnAdder"
              title="Change column"
              >mdi-chevron-down</v-icon
            ></v-col
          >
          <v-col cols="1" v-if="allowStyling">
            <v-icon
              class="columnAdder"
              @click.stop="showColumnSettings"
              title="Custome colours"
              >mdi-cog</v-icon
            ></v-col
          >
          <v-col cols="1"
              v-if="allowRemove">
            <v-icon
              class="columnAdder"
              title="Remove column"
              @click.stop="$emit('removeColumn', columnType, index)"
              >mdi-minus-thick</v-icon
            ></v-col
          >
          <v-col cols="1"
              v-if="allowAdd">
            <v-icon class="columnAdder" @click="mode = 'add'" title="Add column"
              >mdi-plus-thick</v-icon
            ></v-col
          >
        </v-row>
        <v-row v-else v-bind="attrs" v-on="on">
          <v-col cols="1">
            <v-icon class="columnAdder" @click="mode = 'add'" title="Add column"
              >mdi-plus-thick</v-icon
            ></v-col
          ></v-row
        >
      </template>
      <v-list dense style="max-height: 700px">
        <v-list-item>
          <v-list-item-group>{{
            mode === "add" ? "Pick column to add:" : "Change existing column:"
          }}</v-list-item-group> </v-list-item
        ><v-divider></v-divider>
        <v-list-item
          dense
          v-for="(co, coi) in columnsAvailable.filter((x) => !x.hidden)"
          :key="'co' + coi"
          :style="
            'background-color: ' + (column && co.name === column.name ? '#daecf2;' : 'white;')
          "
          @click="
            $emit(
              mode === 'add' ? 'addColumn' : 'changeColumn',
              columnType,
              co,
              index
            )
          "
        >
          <v-list-item-title>{{ co.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog v-model="settings.show" max-width="900">
      <v-card v-if="column">
        <v-card-title class="text-h5"> {{ column.name }} Styling </v-card-title>
        <v-card-text><v-container>
          <v-row
            ><v-col>
              <div
                :style="{
                  color: settings.color_f,
                  backgroundColor: settings.color_b,
                }"
                style="padding: 2px 5px; border-radius: 5px; border: 1px solid lightgray;"
              >
                Sample Text
              </div></v-col
            ></v-row
          >
          <v-row
            ><v-col><h3>Background Colour</h3></v-col><v-col><h3>Font Colour</h3></v-col></v-row
          >
          <v-row
            ><v-col>
              <v-color-picker
                v-model="settings.color_b"
                hide-inputs
                show-swatches
              ></v-color-picker> </v-col
            ><v-col>
              <v-color-picker
                v-model="settings.color_f"
                hide-inputs
                show-swatches
              ></v-color-picker> </v-col
          ></v-row></v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="settings.show = false"> Cancel </v-btn>
          <v-btn text @click="saveSettingsRevert"> Use Default </v-btn>
          <v-btn text @click="saveSettings"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "cHierarchyViewColumnPicker",
  props: {
    column: Object,
    index: Number,
    columnsAvailable: Array,
    columnType: String,
    columnSubject: String,
    allowRemove: Boolean,
    allowAdd: Boolean,
    allowStyling: Boolean,
  },
  data: function () {
    return {
      settings: { show: false, color_f: "#878787", color_b: "#ffffff" },
      mode: "",
    };
  },
  components: {},
  watch: {},
  computed: {},
  created: function () {},
  methods: {
    showColumnSettings() {
      this.settings.show = true;
    },
    saveSettings() {
      this.settings.show = false;
      this.$emit("changeColumnStyle",
        this.columnType,
        {
          color: this.settings.color_f,
          backgroundColor: this.settings.color_b,
        },
        this.index
      );
    },
    saveSettingsRevert() {
      this.settings.show = false;
      this.$emit("changeColumnStyle",
        this.columnType, null,
        this.index
      );
    },
  },
};
</script>
  <style scoped lang="scss">
.columnAdder {
  background-color: #e0f2f1;
  width: 22px;
  display: inline-table;
  margin-left: 3px;
  padding-left: 0px;
  padding-right: 0px;

  border: solid 1px #bcd4d0;
  border-radius: 5px;
  height: 24px;
  font-size: 12px;
}
.columnPicker {
  background-color: #e0f2f1;
  /*   width: calc(100% - 52px);
   display: inline-table; 
  display: flex;
  width: 100%;*/
  border: solid 1px #bcd4d0;
  border-radius: 5px;
  /* height: 24px; */
  font-size: 12px;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.pickerContent {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-flex;
  padding-top: 3px;
  width: 100%;
}
</style>