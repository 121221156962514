<template>
  <v-card class="mx-auto" flat v-if="vm">
    <v-card-text class="pa-0">
      <v-row style="min-height: 480px;" class="ma-0">
        <v-col cols="4" class="tabsCol">
          <v-tabs v-model="optSel" vertical>
            <v-tab
              v-for="(opt, index) in vm.options"
              :key="`'Tab${index}`"
              editable
              @click="optionSectionSelected(index)"
              :class="[
                optSel == index ? 'currStepHeading' : 'stepHeading',
                'd-flex justify-space-between pl-4 pr-4',
              ]"
            >
              <div class="d-flex align-center">
                <v-avatar size="24">
                  {{ index + 1 }}
                </v-avatar>
                <div class="text-left d-flex flex-column">
                  <span>
                    {{ opt.sectionName
                    }}<v-icon small v-if="opt.accepted" right>
                      mdi-check-circle-outline
                    </v-icon>
                  </span>
                  <div>
                    <span :class="['secOutcome', `${opt.outcomeColour}--text`]">{{
                      opt.outcome
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="d-flex align-center">
                <v-tooltip bottom v-if="opt.description">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      :color="opt.showDesc ? 'primary' : ''"
                      v-bind="attrs"
                      v-on="on"
                      class="ml-2"
                    >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <div style="max-width: 500px" v-html="opt.description"></div>
                </v-tooltip>
                <v-tooltip bottom v-if="opt.insights && !fixedResults">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      :color="opt.showInsights ? 'primary' : ''"
                      v-bind="attrs"
                      v-on="on"
                      class="ml-2"
                    >
                      mdi-lightbulb-outline
                    </v-icon>
                  </template>
                  <div style="max-width: 500px" v-html="opt.insights"></div>
                </v-tooltip>
              </div>
            </v-tab>
          </v-tabs>
        </v-col>
        <v-col cols="8" class="questCol">
          <v-tabs-items v-model="optSel" vertical>
            <v-tab-item
              v-for="(opt, index) in vm.options"
              :key="`Tab${index}`"
              class="pt-4 px-12"
            >
              <v-expansion-panels v-if="opt.outcomeDesc" class="mb-6">
                <v-expansion-panel class="px-6">
                  <v-expansion-panel-header class="subtitle-2">
                    How to make it work?
                  </v-expansion-panel-header>
                  <v-expansion-panel-content
                    class="pb-4"
                  >
                    <div v-html="opt.outcomeDesc">
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-card class="px-12 py-6">
                <div v-for="(q, qi) in opt.questions" :key="q.respOrder" v-show="q.dependantOptionIndex === null || opt.questions[q.dependantOptionIndex].selected === q.dependantOptionValue">
                  <v-row v-if="!fixedResults || q.afrType === 'SLIDER' || q.selected"
                    :class="[ q.afrType === 'SLIDER' ? 'qSlider' : 'qSwitch', q.afrType === 'SLIDER' && !fixedResults && qi > 0 ? 'pt-3' : '']"
                  >
                    <v-col v-if="q.afrType === 'SLIDER'" cols="12">
                      <div>
                        <v-icon v-if="fixedResults" large>mdi-circle-small</v-icon>
                        <span :class="[ fixedResults? 'body-1 font-weight-light' : 'body-2 text-uppercase' ]" v-html="getDaysText(q, false)">
                        </span>
                      </div>
                    </v-col>
                    <v-col
                      v-else-if="!fixedResults"
                      :cols="12"
                      style="padding-bottom: 0"
                    >
                      <v-switch
                        style="margin-top: 0; padding-top: 10px"
                        v-model="q.selected"
                        :label="q.respText"
                        @change="optValueChanged(opt)"
                      ></v-switch>
                    </v-col>
                    <v-col
                      v-else-if="fixedResults && q.selected"
                      cols="12"
                      style="padding-bottom: 0"
                    >
                      <v-icon v-if="fixedResults" large>mdi-circle-small</v-icon>
                      <span :class="[ 'body-1', 'font-weight-light', fixedResults ? '' : 'ml-3' ]">{{
                        q.respText
                      }}</span>
                    </v-col>

                    <v-col
                      cols="12"
                      v-if="!fixedResults && q.afrType === 'SLIDER'"
                      class="d-flex justify-content-center"
                    >
                      <v-slider
                        @change="optValueChanged(opt)"
                        style="font-size: 12px"
                        v-model="q.selected"
                        :color="getControlColor(q.selected, q.respStep)"
                        ticks="always"
                        :tick-labels="q.tickLabels"
                        track-color="grey"
                        always-dirty
                        :min="q.respLowerRange"
                        :max="q.respUpperRange"
                        :step="q.respStep"
                        :key="'sliderQ' + q.respOrder"
                        :id="'sliderQ' + q.respOrder"
                      >
                        <template v-slot:prepend>
                          <v-btn
                            outlined
                            small
                            color="grey"
                            style="
                              padding: 0;
                              min-width: 28px;
                              width: 28px;
                              border-radius: 8px;
                            "
                            @click="optValueIncremented(opt, q, true)"
                          >
                            <v-icon> mdi-minus </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:append>
                          <v-btn
                            outlined
                            small
                            color="primary"
                            style="
                              padding: 0;
                              min-width: 28px;
                              width: 28px;
                              border-radius: 8px;
                            "
                            @click="optValueIncremented(opt, q)"
                          >
                            <v-icon> mdi-plus </v-icon>
                          </v-btn>
                        </template>
                      </v-slider>
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="!fixedResults && q.afrType === 'SLIDER'"
                      class="pt-4"
                    >
                      <div v-if="q.selected > 0 && opt.sectionName !== 'Compressed Hours' &&
                                (q.respAscDesc === 'DESCENDING'
                                  ? (9 - q.selected) / q.respStep
                                  : q.selected / q.respStep) > 0">
                        <span class="body-1">
                          
                          <span
                            class="font-weight-bold"
                            style="font-size: 32px"
                          >
                            {{
                              q.respAscDesc === "DESCENDING"
                                ? (9 - q.selected) / q.respStep
                                : q.selected / q.respStep
                            }}
                          </span>
                          {{
                            (q.respAscDesc === "DESCENDING"
                              ? (9 - q.selected) / q.respStep
                              : q.selected / q.respStep) > 1
                              ? q.respMeasureText + "s"
                              : q.respMeasureText
                          }}
                        </span>
                        <span v-if="!fixedResults"></span>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <v-row
                  v-if="
                    fixedResults &&
                    !opt.questions.some(
                      (q) => q.afrType === 'SLIDER' || q.selected
                    )
                  "
                >
                  <v-col>
                    <span :class="[ 'body-1 orange--text', 'font-weight-light', fixedResults ? '' : 'ml-3' ]"
                      >{{ opt.notApplicableText || "Not Applicable" }}</span
                    >
                  </v-col>
                </v-row>
                <v-row class="mt-4" v-if="flexWorkerCopy && opt.sectionName.toLowerCase() === 'location flexibility'">
                  <v-col>
                    <span class="body-1 text--secondary" v-html="flexWorkerCopy"></span>
                  </v-col>
                </v-row>
                <v-row v-if="!fixedResults">
                  <v-col cols="6"></v-col>
                  <v-col cols="6" class="d-flex justify-end">
                    <v-btn
                      v-if="!opt.accepted"
                      color="primary"
                      class="accept"
                      dark
                      outlined
                      @click="acceptOption(opt)"
                      >Accept</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card>

              <v-row>
                <v-col cols="12" class="pt-12 pb-0">
                  <v-textarea
                    v-if="opt.changed"
                    label="Enter reason for changing from recommended options"
                    v-model="opt.comment"
                    outlined
                    autofocus
                    rows="1"
                    auto-grow
                    persistent-hint
                    :hint="opt.error"
                    @keydown="opt.accepted = false"
                    :success="!!opt.comment"
                    :error="!opt.comment"
                    :rules="commentsRules"
                  ></v-textarea>
                  <v-textarea
                    v-else
                    label="Enter comment"
                    @keydown="opt.accepted = false"
                    v-model="opt.comment"
                    outlined
                    rows="1"
                    auto-grow
                    :rules="commentsRules"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="d-flex justify-space-between align-center px-5 pt-5">
      <v-btn v-if="allowClose" text @click="close">Back</v-btn>
      <div v-else></div>
      <v-btn
        color="primary"
        @click="saveAndClose"
        v-if="allowClose && allAccepted()"
        >Update Document</v-btn
      >
      <div class="pleaseAccept" v-if="!allAccepted()" flat>
        Please accept all sections
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  props: {
    watchedObject: {}, //{ text: '', closed: false } // this is an option from pages with high volumes of data
    documentId: String,
    open: Boolean,
    fixedResults: Boolean,
    allowClose: Boolean
  },
  data: function () {
    return {
      show: false,
      questSel: 1,
      optSel: 0,
      wordCount: "0 words",
      vm: null,
      commentsRules: [
        (c) =>
          !c ||
          c.length <= 500 ||
          "This field exceeds maximum (500) allowed characters",
      ],
      confirmClearDialog: false,
      flexWorkerCopy: this.$loginState.user.settings.find(s => s.setting === "roleflex_flexworker_copy")?.value
    };
  },
  created() {
    this.show = !!this.open;
    if (this.show) {
      this.fetchData();
      this.questSel = 1;
    } else {
      this.vm = null;
    }
  },
  watch: {
    open() {
      this.show = !!this.open;
      if (this.show) {
        this.fetchData();
        this.questSel = 1;
      } else {
        this.vm = null;
      }
    },
  },
  computed: {
    showClearAnswers() {
      return this.$loginState.user.settings.some(
        (s) => s.setting === "flex_allow_clearanswers" && s.value !== "false"
      );
    },
  },
  methods: {
    save() {
      this.errMessage = "";
      this.show = false;
      if (this.watchedObject) {
        const updated = this.watchedObject;
        updated.closed = true;
        this.$emit("updated:watchedObject", updated);
      } else {
        this.$emit("closed");
      }
    },
    close() {
      this.$emit("closed");
    },
    sectionSelected(secSel) {
      let ss = this.vm.sections.find((sec) => sec.order === secSel);
      if (ss) {
        this.questSel = ss.questions[0].order;
        this.vm.sections
          .filter((s) => s !== ss)
          .forEach((s) => {
            s.showDesc = false;
            s.showOutcomeDesc = false;
          });
      }
    },
    optionSectionSelected(optSel) {
      if (this.vm.options.length > optSel) {
        let ss = this.vm.options[optSel];
        this.questSel = ss.questions[0].order;
        this.vm.options
          .filter((o) => o !== ss)
          .forEach((o) => {
            o.showDesc = false;
            o.showInsights = false;
            o.showOutcomeDesc = false;
          });
      }
    },
    setVM(vm) {
      vm.sections.forEach((s) => {
        s.outcomeOrig = s.outcome;
        s.commentOrig = s.comment;
        s.questions.forEach((q) => (q.isDirty = false));
      });
      vm.options.forEach((o) => {
        o.outcomeOrig = o.outcome;
        o.questions.forEach((q) => (q.isDirty = false));
      });
      this.vm = vm;
    },
    fetchData() {
      this.isLoading = true;
      axios
        .get("roleflex/document/" + this.documentId)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            this.setVM(resp.data.Data);
            this.isLoading = false;
          }
        })
        .catch((err) => {
          this.$emit("sessionExpired", err);
          console.log(err);
          this.isLoading = false;
        });
      return;
    },
    saveAnswers(goToOptions) {
      let data = [];
      this.vm.sections.forEach((dp) => {
        dp.questions.forEach((q) => {
          let new_resp = q.options.find((rr) => q.response === rr.value); // get new response
          let old_resp = q.options.find((rr) => rr.dar_id); // get old response
          if (
            new_resp &&
            (!old_resp ||
              new_resp.alr_id !== old_resp.alr_id ||
              dp.comment !== dp.commentOrig ||
              dp.outcome !== dp.outcomeOrig)
          ) {
            data.push({
              doc_id: dp.doc_id,
              alq_id: q.alq_id,
              alr_id: new_resp ? new_resp.alr_id : undefined,
              comment: dp.comment,
              outcome: dp.outcome,
            });
          }
        });
      });
      if (data.length) {
        axios
          .post("roleflex/saveAnswers", data)
          .then((resp) => {
            if (resp.data.Status === "OK") {
              this.setVM(resp.data.Data);
              if (goToOptions) {
                this.option = "options";
                this.optSel = 1;
                this.questSel = 1;
              }
            }
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              console.log(err);
              this.response = err.response
                ? err.response.data
                : { message: "Unexpected Error" };
            }
            this.isLoading = false;
          });
      } else {
        // nothing to save
        if (goToOptions) {
          this.option = "options";
          this.optSel = 1;
          this.questSel = 1;
        }
      }
    },
    clearAnswers() {
      axios
        .delete("roleflex/document/" + this.documentId)
        .then((resp) => {
          this.confirmClearDialog = false;
          if (resp.data.Status === "OK") {
            //this.setVM(resp.data.Data);
            const vm = resp.data.Data;
            vm.options.forEach((o) => {
              o.outcome = "";
              o.outcomeDesc = "";
              o.outcomeColour = "";
            });
            vm.sections.forEach((o) => {
              o.outcome = "";
              o.outcomeDesc = "";
              o.outcomeColour = "";
            });
            this.updateDoc(vm);
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
          this.confirmClearDialog = false;
        });
    },
    saveAndClose() {
      const that = this;
      this.saveOptions('all', true, function() {
        that.$emit("flexupdated", that.vm);
      })
    },
    saveOptions(option, updateDoc, callback) {
      const vm = this.vm;
      let changedOptions = [];
      vm.options
        .filter((opt) => opt === option || option === "all")
        .forEach((opt) => {
          opt.questions.forEach((q) => {
            changedOptions.push({
              docId: vm.sections[0].doc_id,
              afrId: q.afrId,
              comment: opt.comment || "",
              calculated:
                q.calculated === true
                  ? 1
                  : q.calculated === false
                  ? 0
                  : q.calculated,
              selected:
                q.selected === true ? 1 : q.selected === false ? 0 : q.selected,
              outcome: opt.outcome,
            });
            q.outputText = this.getDaysText(q, true);
          });
        });

      if (changedOptions.length) {
        axios
          .post("roleflex/saveOptions", changedOptions)
          .then(() => {
            if (updateDoc) this.updateDoc(vm, callback);
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              console.log(err);
              this.response = err.response
                ? err.response.data
                : { message: "Unexpected Error" };
            }
            this.isLoading = false;
          });
      } else {
        // nothing to save
        if (updateDoc) this.updateDoc(vm, callback);
      }
    },
    getDaysText(q, output) {
      let outText = "";
      if (q.selected === q.respLowerRange && q.respMinText) {
        outText = output ? q.respMinOutputText : q.respMinText;
      } else if (q.selected === 0 && q.respZeroText) {
        outText = output ? q.respZeroOutputText : q.respZeroText;
      } else if (q.selected > 0) {
        if (q.selected === q.respUpperRange && q.respMaxText)
          outText = output ? q.respMaxOutputText : q.respMaxText;
        else {
          const stepValue =
            q.respAscDesc === "DESCENDING"
              ? (9 - q.selected) / q.respStep
              : q.selected / q.respStep;
          if (stepValue === 0 && q.respZeroText)
            outText = output ? q.respZeroOutputText : q.respZeroText;
          else {
            let daysText = `${stepValue.toString()} day${stepValue > 1 ? "s" : ""}`;
            if (q.respAscDesc === "ASCENDING")
              daysText = `${stepValue.toString()} day${stepValue > 1 ? "s" : ""} a week (or ${100 * stepValue / 5}% of working time)`;
            outText = (output ? q.respOutputText : q.respText).replace("{$daysText}", daysText);
          }
        }
      }
      return outText;
    },
    updateDoc(vm, callback) {
      let html = "";
      vm.options.forEach((ss) => {
        ss.activeQuestions = ss.questions.filter(q => q.dependantOptionIndex === null || ss.questions[q.dependantOptionIndex].selected === q.dependantOptionValue);
        ss.activeQuestions.forEach((q) => {
          if (q.outputText) html += "<li>" + q.outputText + "</li>";
        });
      });
      if (html) html = `<ul>${html}</ul>`;

      let notes = "";

      vm.options.forEach((ss) => {
        if (ss.comment) {
          notes += ss.sectionName + ': "' + ss.comment + '"\n';
        }
      });

      vm.workingPatternHtml = html;
      vm.workingPatternNotes = notes;

      //save doc part with new data
      this.$emit("flexupdated", vm);

      if (typeof callback === "function") callback();
    },
    getControlColor(values, step) {
      if (step == 1) {
        if (values > 4) return "deep-orange darken-2";
        if (values > 3) return "deep-orange";
        if (values > 2) return "orange darken-2";
        if (values > 1) return "orange";
        if (values > 0) return "green lighten-2";
        if (values === 0) return "green darken-2";
        return "green darken-2";
      } else {
        if (values > 4) return "green darken-2";
        if (values > 2) return "green lighten-2";
        if (values > 0) return "orange";
        return "red";
      }
    },
    getTextColor(values, step) {
      if (step == 1) {
        if (values > 4) return "#388E3C";
        if (values > 3) return "#81C784";
        if (values > 2) return "orange";
        if (values > 1) return "#F57C00";
        if (values > 0) return "#FF5722";
        if (values === 0) return "#F4511E";
      } else {
        if (values > 4) return "#388E3C";
        if (values > 2) return "#81C784";
        if (values > 0) return "orange";
      }
      return "red";
    },
    optValueIncremented(opt, q, inverse) {
      q.selected = q.selected + (inverse ? -1 : 1) * q.respStep;
      if (q.selected < q.respLowerRange) {
        q.selected = q.respLowerRange;
        return;
      } else if (q.selected > q.respUpperRange) {
        q.selected = q.respUpperRange;
        return;
      }
      this.optValueChanged(opt);
    },
    optValueChanged(opt) {
      opt.accepted = false;
      this.checkOptChanged(opt);
    },
    acceptOption(opt) {
      if (this.checkOptChanged(opt)) {
        opt.accepted = true;
        this.saveOptions(opt);
        const nextOptIdx = this.optSel + 1 >= this.vm.options.length ? 0 : this.optSel + 1;
        this.optSel = nextOptIdx;
        this.optionSectionSelected(nextOptIdx);
      }
    },
    checkOptChanged(opt) {
      opt.changed = opt.questions.some((q) => q.calculated !== q.selected);
      opt.error =
        opt.changed && !opt.comment
          ? "Option changed from recommendation - reason is required"
          : "";
      return !opt.error;
    },
    countAllWords(subParts) {
      let data = subParts.map((sp) => sp.text).join(" ");
      let count = data ? data.split(" ").filter((x) => x.length).length : 0;
      return count + " words";
    },
    allAccepted() {
      return this.fixedResults || !this.vm.options.some((o) => !o.accepted);
    },
  },
};
</script>
<style scoped lang="scss">

::v-deep .v-tabs-slider-wrapper {
  left: auto !important;
  right: 0;
}

.tabsCol {
  padding: 0;
  border: 1px solid #e6eaf0;

  .v-avatar {
    border: 1.5px solid #D5DDE3;
    color: #D5DDE3;
    margin-right: 10px;
    font-size: 14px;
    padding-top: 2px;
  }

  .v-tab--active {
    .v-avatar {
      border-color: #377DFF;
      color: #377DFF;
    }
  }
}

.questCol {
  border-top: 1px solid #e6eaf0;
  border-bottom: 1px solid #e6eaf0;
  border-right: 1px solid #e6eaf0;
  padding: 0;
}

.currStepHeading,
.stepHeading {
  min-height: 80px;
  min-width: 100%;
  padding: 0;
  border-bottom: 1px solid #e6eaf0;
  text-transform: none;
  font-size: 16px;
}

::v-deep .v-expansion-panel-content .v-expansion-panel-content__wrap {
  padding: 0;
}

.v-tabs.theme--light .currStepHeading {
  background-color: #f1f7fc;
}

.currStepHeading {
  font-weight: bold;
}

.secOutcome {
  text-transform: uppercase;
}

.pleaseAccept {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 12px 16px 8px;

  background: #f5f6f7;
  border-radius: 8px;
}
</style>
