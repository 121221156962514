<template>
  <div>
    <span v-if="pageTo > 0">{{ 1 + pageFrom }} - {{ pageTo }} of {{ totalItems }}</span>
  </div>
</template>

<script>
export default {
  name: "PageDescription",
  components: {},
  props: {
    totalItems: { type: Number, required: true },
    pageSize: { type: Number, required: true },
    currentPage: { type: Number, required: true },
  },
  data: function () {
    return {};
  },
  watch: {},
  computed: {
    pageFrom() {
      return this.pageSize < 0 ? 0 : this.pageSize * (this.currentPage - 1);
    },
    pageTo() {
      if (this.pageSize < 0)
        return this.totalItems;
        
      const pageTo = this.pageFrom + this.pageSize;
      if (pageTo > this.totalItems) return this.totalItems;
      else return pageTo;
    },
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/vars";

div {
  font-size: 0.875rem;
}

.v-application.theme--light {
  div {
    color: $input-text-color;
  }
}
</style>
