<template>
  <v-card
    class="uploadContentPnl"
    flat
  >
    <v-card-title>
      Data Upload
      <v-btn v-if="selectedFile" small @click="selectedFile = null;" style="margin-left:40px">Back</v-btn>
      <div style="padding-left:20px; display:inline-flex" v-if="selectedFile === null && !loading">
        <FileUploader :displayMode="displayMode" :fileUploadData="fileUploadFormData" :uploadDisabled="disableFileUpload" :multiple="multiUpload" :uploadedCallback="filesUploadedCallback"/>
      </div>
      <v-spacer></v-spacer>
      <div v-if="(selectedFile !== null) && !loading">
        <v-btn class="mr-4" color="primary" @click="historyDialog = true;">
          <v-icon class="mr-2" >mdi-file-clock-outline</v-icon>
          View History
        </v-btn>
        <v-btn class="mr-4" color="primary" @click="downloadFile(selectedFile.file_id)">
          <v-icon class="mr-2" >mdi-cloud-download</v-icon>
          Download
        </v-btn>
        <v-btn class="mr-4" color="success" title="Save Config" @click="saveFileExcelMap()">
          <v-icon class="mr-2" >mdi-content-save</v-icon>
          Save Config
        </v-btn>
      </div>
    </v-card-title>
    <v-card-text style="padding-top: 8px; height: calc(100% - 120px); min-height: calc(100% - 120px);">
      <v-row v-if="selectedFile === null && !loading" dense>
        <v-col dense cols="12">
          <v-data-table
            show-select
            item-key="file_id"
            :headers="headers"
            :items="filteredFileItems"
            :search="search"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            class="elevation-1"
            @page-count="pageCount = $event"
          >
          <template v-slot:[`item.docLinks`]="{ item }">
            <div v-if="item.docLinks.length > 0" @click="openLinkedFilesDialog(item.file_id)" style="cursor:pointer">
              <v-icon text style="padding-bottom: 3px" >
                  mdi-view-list
              </v-icon>                   
              {{item.docLinks.length}}
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn small class="mr-4" color="primary" @click="selectFile(item)">
              <v-icon small class="mr-2" >mdi-file-document-plus</v-icon>
              Configure
            </v-btn>        
          </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row v-else-if="loading" style="height:100%" dense>
        <v-col dense cols="12" style="display:inline-flex">
          Loading<br/>
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
            style="top: 45%; left: 45%;"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row v-else-if="creating" style="height:100%" dense>
        <v-col dense cols="12" style="display:inline-flex">
          Creating<br/>
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
            style="top: 45%; left: 45%;"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row v-else-if="(selectedFile !== null) && !loading && !creating" style="height:100%" dense>
        <v-col dense cols="2" style="display:inline-flex">
          <div style="padding-top:5px">
            Creation Options:<br/>
            <v-select dense @change="docTypeChange" label="Document Type?" v-model="createRequest.tmpl_id" style="max-width:230px; padding-top:15px" :items="allTmpl" item-text="tmpl.tmpl_name" item-value="tmpl.tmpl_id" return-object></v-select>
          </div>
        </v-col>
        <v-col dense cols="6" style="display:inline-flex">
          <div style="padding-top:5px; width:100%">
            Hierarchy Config: <br/>
            <v-row dense>
            <v-col dense cols="4" v-for="ht in createRequest.hierarchies" :key="ht.ht_id">
              <div style="padding-top:5px">
                <b><i>{{ht.label}}</i></b><br/>
                <div v-for="hr in ht.config" :key="`${ht.ht_id}_${hr.level}`">
                  <v-icon :color="hr.valid ? 'green' : 'red'" small > {{ hr.valid ? 'mdi-check' : 'mdi-close' }}</v-icon>
                  &nbsp; {{hr.name}} {{ hr.valid ? `(${hr.type === "map" ? 'Mapped' : 'Text'})${hr.type === "value" ? ` - "${hr.value}"` : ''}` : '' }}
                  <v-icon v-if="hr.type === 'value'" small style="bottom: 2px; left: 5px;" @click="openHrMenu($event,ht,hr)" >mdi-pencil</v-icon> 
                </div>
              </div>
            </v-col> 
            
            <v-menu
                v-model="hrMenu.show"
                :value="hrMenu.show"
                absolute
                :position-x="hrMenu.posX"
                :position-y="hrMenu.posY"
                :close-on-content-click="false"
                :close-on-click="false"
                :nudge-width="350"
                offset-x
              >                
                <v-card>
                  <v-card-text>
                    <v-text-field v-model="hrMenu.item.value" dense></v-text-field>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="clearHrConfig()">
                      Clear
                    </v-btn>                 
                    <v-btn
                      color="primary"
                      text
                      @click="saveHrConfig()"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>                     
          </v-row>
          </div>
        </v-col>
        <v-col dense cols="3">
          File Details:<br/>
          <span>
            {{fileStructure.file_name}}<br/>
            {{fileStructure.rowCount}} row(s) of {{ fileStructure.totalRows }}
          </span>
          <br/>
          <div>
            <v-btn :disabled="!validAction" class="mr-4" @click="submitRequest()" color="success">Create!</v-btn>
            <div style="display:inline-flex; align-items: center;">
              <v-text-field v-model="createRequest.row_start" style="width:70px" width="70px" :min="1" :max="fileStructure.totalRows" type="number" class="mr-4"></v-text-field> to <v-text-field v-model="createRequest.row_end" class="ml-4" :min="1" :max="fileStructure.totalRows" style="width:70px" width="70px" type="number"></v-text-field>
            </div>
            <div v-if="!validAction" style="color:red">No Workflow Action set up for "Data Upload" - Please Update Workflow before uploading!</div>
          </div>
        </v-col>
        <v-col dense cols="1">
        </v-col>
        <v-col dense cols="12" style="height:calc(100% - 80px);">
            <div style="width:100%; max-width:calc(100vw - 0px); overflow:scroll hidden; padding-top:20px; padding-bottom:20px; height:100%">
              <div style="display:inline-flex">
                Hidden Columns: {{hiddenColumns.length}} <br/> <v-btn class="ml-4" v-if="hiddenColumns.length > 0" x-small @click="clearHidden()">Clear Hidden Columns</v-btn>
              </div>
              <table v-columns-resizable>
                <thead>
                  <tr>
                    <th width="50px"></th>
                    <th v-for="(header, hIdx) in visibleHeaders" :key="hIdx" :class="header.mappings.some(x => x.selected) ? 'activeHeader' : ''">
                      <div style="display: inline-flex; align-items: center; width: 100%; justify-content: center;" >
                        <div style="padding-top:10px">{{ header.value }}</div>
                        <v-icon style="font-size: 20px; top: 3px; left: 10px;" @click="checkColumn(header)">mdi-cog</v-icon>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(sampleRow, dIdx) in fileStructure.sampleText" :key="dIdx">
                    <td>
                      <v-icon>mdi-magnify</v-icon>
                    </td>
                    <td v-for="(header, hIdx) in visibleHeaders" :key="hIdx" :class="header.mappings.some(x => x.selected) ? 'activeHeader' : ''" >
                      <div :title="sampleRow[header.value]">
                        {{ sampleRow[header.value] }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
        </v-col>
      </v-row>
    </v-card-text>
    <Notification :notification="notification"/>
    <v-dialog 
      fullscreen
      :scrim="false"
      transition="dialog-bottom-transition"
      persistent
      no-click-animation
      scrollable 
      v-model="historyDialog" 
      >
      <v-card>
          <v-card-title class="justify-center">
            <v-row dense>
              <v-col>File History</v-col>
            </v-row>
          </v-card-title>
          <v-card-text v-if="historyLog === null">
            <v-row dense>
              <v-col cols="1">ID</v-col>
              <v-col cols="2">Documents Created</v-col>
              <v-col cols="1">Status</v-col>
              <v-col cols="4">Message</v-col>
              <v-col cols="2">Time</v-col>
              <v-col cols="1"></v-col>
            </v-row>            
            <v-row dense v-for="(h,idx) in this.fileStructure?.history" :key="`history_${idx}`">
              <v-col cols="1">{{ h.doc_creation_request_id ?? `Legacy #${idx}` }}</v-col>
              <v-col cols="2">{{ h.row_start }} to {{ h.row_end }} of {{ fileStructure?.totalRows }}</v-col>
              <v-col cols="1">{{ h.status }}</v-col>
              <v-col cols="4">{{ h.message }}</v-col>
              <v-col cols="2">{{ h.timestamp ?? "Unknown - Legacy Data"}} </v-col>
              <v-col cols="1">
                <v-icon @click="loadHistoryLog(h)">mdi-view-list</v-icon>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-if="historyLog">
            <v-row dense>
              <v-col>
                <v-btn @click="closeHistoryLog()">
                  Back
                </v-btn>
              </v-col>
              <v-col>
                <div style="display:inline-flex">
                  <v-switch label="Get Live Updates?" @change="changeRefresh()" v-model="refreshLive"></v-switch>
                  <v-progress-circular
                    v-if="refreshLive"
                    :size="25"
                    color="primary"
                    indeterminate
                    style="top: 45%; left: 45%;"
                  ></v-progress-circular>
                </div>
              </v-col>   
              <v-col>
              </v-col>              
            </v-row>   
            <v-row>
              <v-col>
                <h3>Request Information:</h3>
              </v-col>
            </v-row>     
            <v-row style="padding-bottom:20px">
              <v-col cols="2">
                <b>Current Status:</b> {{historyLog.status}}
              </v-col>
              <v-col cols="2">
                <b>Request Timestamp:</b> {{historyLog.timestamp}}
              </v-col>
              <v-col cols="2">
                <b>Start Row:</b> {{historyLog.row_start}}
              </v-col>
              <v-col cols="2">
                <b>End Row:</b> {{historyLog.row_end}}
              </v-col>  
              <v-col cols="4">
                <b>Status Message:</b> {{historyLog.message}}
              </v-col> 
            </v-row>         
            <v-row v-for="item in historyLog.rows" :key="item.doc_id" dense>
              <v-col cols="1">Row #{{item.row_id}}</v-col>
              <v-col cols="2">{{item.doc_type}}</v-col>
              <v-col cols="6">
                <div v-if="item.doc_id">
                  {{item.doc_name}}
                </div>
                <div v-else>
                  <v-progress-circular
                    v-if="refreshLive"
                    :size="10"
                    color="primary"
                    indeterminate
                    style="top: 45%; left: 45%; padding-top:35px;"
                  ></v-progress-circular> Pending....
                </div>
              </v-col>
              <v-col cols="2">{{item.reference}}</v-col>
              <v-col cols="1">
                <v-icon @click="openDoc(item)" >
                  mdi-magnify
                </v-icon>
              </v-col>
            </v-row>
          </v-card-text>          
          <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="historyDialog = false;">Close</v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>  
    <v-dialog max-width="1200px"
      persistent
      no-click-animation
      scrollable 
      v-model="columnDialog.show" 
    >
      <v-card>
        <v-card-title>
          <span class="headline">Mapping Details</span>
        </v-card-title>

        <v-card-text>
          <v-container v-if="columnDialog.item">
            <v-tabs :value="activeTab" vertical>
              <v-tab v-for="(map,idx) in columnDialog.item.mappings" @click.prevent="setTab(idx)" :key="`mappingTab_${idx}`">
                Mapping {{ idx+1 }}
              </v-tab>
              <v-tab-item v-for="(item,iIdx) in columnDialog.item.mappings" :key="`mapping_${iIdx}`">
                <v-card flat>
                  <v-card-text style="padding-top: 5px;">
                    <v-switch v-model="item.selected" label="Map?" dense small></v-switch>
                    <v-radio-group
                      row
                      v-if="item.selected"
                      v-model="item.type"
                      style="padding-top:10px"
                      @change="modifyMap(item)" 
                    >
                      <v-radio v-if="columnDialog.item.update" 
                        label="Update"
                        value="update"
                      ></v-radio>
                      <v-radio v-if="columnDialog.item.update" 
                        label="Link Document"
                        value="link"
                      ></v-radio>
                      <v-radio
                        label="Template Part"
                        value="column_map"
                      ></v-radio>
                      <v-radio
                        label="Hierarchy Value"
                        value="hierarchy_map"
                      ></v-radio>
                      <v-radio
                        v-if="createRequest.dataCaptureMap?.length > 0"
                        label="Data Capture"
                        value="dc_map"
                      ></v-radio>
                    </v-radio-group>
                    <v-switch style="padding-top:10px" v-model="item.update_deleteList" v-if="item.selected && item.type === 'update'" label="Delete List Items?" dense small></v-switch>
                    <div style="padding-top:10px" v-if="item.selected && item.type === 'link'">
                      Document Creation Method: {{docCreationType}}<br/>
                      <v-select  dense :items="['Parent','Child']" v-model="item.link_type"></v-select>
                      <div v-if="item.link_type" style="color:red">
                        NOTE: Document Referenced in this column will become {{item.link_type.toLowerCase()}} of the {{ docCreationType === 'Create' ? 'newly created' : 'updated' }} document
                        <div v-if="item.link_type === 'Child'">
                          Use this setting when you are {{ docCreationType === 'Create' ? 'creating' : 'updating' }} a Original Document that should be linked to the existing Document referenced in this column
                        </div>
                        <div v-if="item.link_type === 'Parent'">
                          Use this setting when you are {{ docCreationType === 'Create' ? 'creating' : 'updating' }} a Draft document that should be linked to the existing Original Document referenced in this column
                        </div>
                      </div>
                    </div>
                    <v-select dense v-model="item.dc_map" v-if="item.selected && item.type === 'dc_map'" :items="createRequest.dataCaptureMap" item-value="data_capture_type_id" return-object>
                      <template slot="item" slot-scope="data">
                        {{data.item.method === 'form' ? data.item.data_capture_field : data.item.input_label}}
                      </template> 
                      <template slot="selection" slot-scope="data">
                        {{data.item.method === 'form' ? data.item.data_capture_field : data.item.input_label}}
                      </template>                                           
                    </v-select>
                    <div v-if="item.selected && item.type === 'dc_map'" style="width:100%;display:inline-flex">
                      <v-checkbox small label="Split Cell Value?" v-model="item.dc_split"></v-checkbox>
                      <v-text-field label="Split Value" width="150px" style="padding-left: 30px;" dense v-model="item.dc_split_value"></v-text-field>
                      <v-text-field label="Value Idx (zero based)" type="number" width="150px" style="padding-left: 30px;" dense v-model="item.dc_split_value_idx"></v-text-field>
                    </div>
                    <v-select style="padding-top:10px" dense @change="getTmplPartDetails(item)" v-model="item.tmpl_part" v-if="item.selected && item.type === 'column_map'" :items="excelDataFieldTargets" item-text="label" return-object></v-select>
                    <div style="width:100%;display:inline-flex" v-if="item.selected && item.type === 'column_map' && !item.uses_tags">
                      <v-checkbox small label="Split Multiple Rows?" v-model="item.multi_row"></v-checkbox>
                      <v-text-field label="Delimiter" width="150px" style="padding-left: 30px;" dense v-if="item.multi_row" v-model="item.delimiter"></v-text-field>
                    </div>
                    <div v-if="item.selected && item.uses_tags" style="width:100%;">
                      <div style="width:100%; display:inline-flex; padding-bottom: 10px;">
                        Mapping Tags: {{item.tag_type}}
                      </div>                                        
                      <div style="width:100%; display:inline-flex; align-items: baseline;">
                        Split Multiple Tags? <v-text-field label="Delimiter" width="150px" style="padding-left: 30px;" dense v-model="item.delimiter"></v-text-field><br/>
                      </div>
                      <div style="width:100%; display:inline-flex">
                        <v-checkbox small label="Use Tag Ratings?" v-model="item.use_tag_ratings"></v-checkbox>
                        <v-text-field label="Delimiter" width="150px" style="padding-left: 30px;" dense v-if="item.use_tag_ratings" v-model="item.tag_rating_delimiter"></v-text-field>
                      </div>                                        
                    </div>  
                    <div style="width:100%;display:inline-flex" v-if="item.selected && item.type === 'column_map' && item.has_child_part">
                      <v-checkbox small label="Map Child Parts?" v-model="item.map_child_part"></v-checkbox>
                      <v-text-field label="Child Part Delimiter" width="150px" style="padding-left: 30px;" dense v-model="item.child_part_delimiter"></v-text-field>
                    </div>
                    <v-select label="Essential Value?" v-if="item.selected && item.uses_essential_flag > 0" :items="essentialValueMap" v-model="item.default_essential_value"></v-select>
                    <v-radio-group
                      row
                      v-if="item.selected && item.type === 'column_map' && item.attr_list?.length > 0 && !item.uses_tags"
                      v-model="item.attr_type"
                      :value="item.attr_type"
                    >
                      <v-radio
                        label="Full Value"
                        value="attr_full"
                      ></v-radio>
                      <v-radio
                        label="Split Value"
                        value="attr_split"
                      ></v-radio>
                    </v-radio-group>                                     
                      <v-select style="padding-top:10px" dense v-if="item.selected && item.type === 'column_map' && item.attr_list?.length > 0 && item.attr_type === 'attr_full'" :items="item.attr_list" item-text="title" item-value="tpa_id" v-model="item.tpa_map"></v-select>
                    <div v-if="item.selected && item.type === 'column_map' && item.attr_list?.length > 0 && item.attr_type === 'attr_split'" style="padding-top:10px">
                      <div style="width:100%;display:inline-flex">
                        <v-checkbox v-model="item.split_content" :label="`Split Entire Cell Content?`" hide-details></v-checkbox><v-text-field v-model="item.split_value" label="Content Split Value" dense class="ml-3"></v-text-field>
                      </div>
                      <div v-for="a in item.attr_list" :key="a.tpa_id">
                        <div style="width:100%;display:inline-flex">
                          <v-checkbox v-model="a.mapped" hide-details :label="`Use ${a.title}?`"></v-checkbox>
                          <v-select width="150px" dense :items="['Map','Text']" v-model="a.type" class="ml-3"></v-select>
                          <v-text-field v-model="a.text" dense class="ml-3"></v-text-field>
                        </div>                                          
                      </div>
                    </div>
                    <v-select style="padding-top:10px" dense v-model="item.hr" v-if="item.selected && item.type === 'hierarchy_map'" :items="hierarchies" item-text="label" item-value="ht_id" return-object></v-select>
                    <v-select style="padding-top:10px" dense v-model="item.hr_level" v-if="item.selected && item.type === 'hierarchy_map' && item.hr" :items="item.hr?.levels || []" item-text="name" item-value="index"></v-select>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn text @click="addMapping(columnDialog.item)">
            Add Mapping
          </v-btn>
          <v-btn color="red" v-if="columnDialog.item && columnDialog.item.mappings.length > 0 && activeTab > 0" text @click="deleteMapping(columnDialog.item)">
            Delete Mapping
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="columnDialog.show = false">
            Close
          </v-btn>    
          <v-btn text @click="cancelColumnConfig(columnDialog.item)">
            Clear
          </v-btn>                  
          <v-btn text color="red" @click="hideColumn(columnDialog.item)">
            Hide Column
          </v-btn>                   
          <v-btn
            color="primary"
            text
            @click="saveColumnConfig(columnDialog.item)"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from 'axios'
import { mapState } from "vuex";
import Notification from "@/components/common/SnackBar.vue";
import FileUploader from '../components/cFileUpload.vue';

export default {
  name: 'uploadContentNew',
  data: function() {
    return {
      filteredFileItems: [],
      search: "",
      page: 1,
      activeTab: 0,
      itemsPerPage: 10,
      loading: false,
      creating: false,
      selectedFile: null,
      fileStructure: null,
      excelDataFieldTargets: null,
      tmplFullDetails: null,
      allTmpl: null,
      historyDialog: false,
      createRequest: {
        tmpl_id: 0,
        hr: [],
        headers: [],
        data_capture: [],
        row_start: 1,
        row_end: 1
      },
      hrMenu: {
        show: false,
        item: {
          value: null
        },
        posX: 0,
        posY: 0
      },
      headers: [
        { text: 'File Name', value: 'file_name' },
        { text: 'File Type', value: 'file_extension' },
        { text: 'Linked Docs', value: 'docLinks' },
        { text: 'Uploaded By', value: 'created_by_user' },
        { text: 'Uploaded Time', value: 'created_at', align: 'start', },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      notification: {
          text:"",
          type:"success"
      }, 
      essentialValueMap: [
        { text: 'Not Set', value: null },
        { text: 'Essential', value: 1 },
        { text: 'Desirable', value: 0 }
      ],
      historyLog: null,
      displayMode: 'small',
      multiUpload: "true",
      fileUploadFormData: {
        parseDoc: false
      },     
      disableFileUpload: false,
      refreshTimeout: null,
      refreshLive: false,
      selectedUpdateLog: null,
      columnDialog: {
        show: false,
        item: null
      },
      validAction: false
    }
  },
  watch: {
  },
  created() {
  },
  beforeDestroy(){
  },
  mounted() {
    this.getFileList();
  },
  components: {
    Notification, FileUploader
  },
  computed: {
    ...mapState({
      hierarchiesLoading: (state) => state.hierarchies.loading,
      hierarchies: (state) => state.hierarchies.hierarchies,
      hierarchyTypes: (state) => state.docs.hierarchyTypes,
      cleanItems: (state) => state.docs.docsList,
    }),
    visibleHeaders() {
      return this.fileStructure.headers ? this.fileStructure.headers.filter(x => !x.hidden) : [];
    },
    hiddenColumns() {
      return this.fileStructure.headers ? this.fileStructure.headers.filter(x => x.hidden) : [];
    },
    creationAllowed() {
      let allHrs = this.createRequest.hierarchies.map(x => {
        return x.config.flat()
      }).flat();

      return !allHrs.some(x => !x.valid) && this.createRequest.tmpl_id !== 0;
    },
    docCreationType(){
      let headers = this.fileStructure.headers.filter(x => x.mappings.some(m => m.selected)).map(r => { return r.mappings.flat() }).flat().filter(x => x.type !== "hierarchy_map");     
      return headers.some(x => x.selected && x.type === "update") ? "Update" : "Create";
    }
  },  
  methods:{
    setTab(idx){
      this.activeTab = idx;
    },
    checkColumn(header){
      this.columnDialog.show = true;
      this.columnDialog.item = header;
      let text = this.fileStructure.sampleText[0][header.value];
      let isIdField = (text || "").match(/[RM-][0-9]{8}/g)?.length > 0;
      header.update = isIdField;
    },
    filesUploadedCallback(){
      this.$store.dispatch("files/reload");
      this.getFileList();  
    },     
    openDoc(item){
      //let source = this.cleanItems.find(x => x.doc_id === item.doc_id);
      this.$emit("openDocument",item);
    },    
    openHrMenu(e,ht,hr){
      if (e.preventDefault) e.preventDefault();
      this.hrMenu.posX = e.clientX;
      this.hrMenu.posY = e.clientY;
      this.hrMenu.item = hr;
      this.hrMenu.show = true;
    },
    clearHidden(){
      this.fileStructure.headers.forEach(h => {
        h.hidden = false;
      });
    },
    triggerNotification(text,type){
      this.notification = {
          text:text,
          type:type
      }
    },    
    getFileList(){
      axios
        .get('/file/list/')
        .then(resp => {
            if(resp.status === 200){
                this.filteredFileItems = resp.data.filter(x => x.file_extension === "xlsx");
                this.disableFileUpload = true;
                this.multiUpload = "false";
                this.$nextTick(() => {
                  this.disableFileUpload = false;
                  this.multiUpload = "true";
                });
            }
                
        })
        .catch(error => console.error(error));
    },
    setAvailableParts(tmpl_id){
      this.excelDataFieldTargets = [
        { header: 'Default' },
        { divider: true },
        {"label":"Document HTML Content", "text":"Job Content", "value":"job", "tmpl_part_id":0},
        {"label":"Document Name", "text":"Document Name", "value":"doc_name", "tmpl_part_id":0}
      ];     
      
      let t = this.allTmpl.find(x => x.tmpl.tmpl_id === tmpl_id);
      this.excelDataFieldTargets.push({ header: t.tmpl.doc_type });
      this.excelDataFieldTargets.push({ divider: true });
      let activeParts = t.detail.parts.filter(x => x.tp_active).map(x => { return x.tp_id })
      t.tmpl.parttypes.filter(x => activeParts.includes(x.tmpl_part_id)).forEach(p => {
          p.parts.forEach((tp,idx) => {
            let type = p.parts.length > 1 ? `${tp.type} ${idx+1}` : tp.type;
            this.excelDataFieldTargets.push({ label: type + ` (${t.tmpl.doc_type_abbrev})`, text: type, value: type + ` (${t.tmpl.doc_type_abbrev})`, tmpl_part_id: tp.id, tmpl_id: t.tmpl.tmpl_id });
          })
        });      
    },
    selectFile(item){
      this.loading = true;
      axios.get("file/getSpreadsheetInfoNew",  { params: { ids:  [item.file_id], sampleCount: 3, config: null}})
      .then((resp) => {
        this.selectedFile = item;
        this.fileStructure = resp.data.file[0];
        /*this.excelDataFieldTargets = [
          { header: 'Default' },
          { divider: true },
          {"label":"Job Content", "text":"Job Content", "value":"job", "tmpl_part_id":0},
          {"label":"Document Name", "text":"Document Name", "value":"doc_name", "tmpl_part_id":0}
        ];   */       

        this.tmplFullDetails = [];
        this.allTmpl = resp.data.tmpl.filter(x => x.tmpl.tmpl_name !== "External File");
        this.allTmpl.forEach(t => {
          this.tmplFullDetails.push(t);
          /*this.excelDataFieldTargets.push({ header: t.tmpl.doc_type });
          this.excelDataFieldTargets.push({ divider: true });
          let activeParts = t.detail.parts.filter(x => x.tp_active).map(x => { return x.tp_id })
          t.tmpl.parttypes.filter(x => activeParts.includes(x.tmpl_part_id)).forEach(p => {
              p.parts.forEach((tp,idx) => {
                let type = p.parts.length > 1 ? `${tp.type} ${idx+1}` : tp.type;
                this.excelDataFieldTargets.push({ label: type + ` (${t.tmpl.doc_type_abbrev})`, text: type, value: type + ` (${t.tmpl.doc_type_abbrev})`, tmpl_part_id: tp.id, tmpl_id: t.tmpl.tmpl_id });
              })
            });*/
        });
        this.createRequest.tmpl_id = this.allTmpl.find(x => x.tmpl.tmpl_id === this.fileStructure.excel_map.docType) ?? this.allTmpl[0];
        this.setAvailableParts(this.createRequest.tmpl_id.tmpl.tmpl_id);
        this.docTypeChange();
      })
      .then(() => {
        this.setupHierarchyConfig();
        this.setData();
      })
      .then(() => {
        this.loading = false;
      })
      .catch(err => {
        console.log(err);
      });    
    },
    setupHierarchyConfig(){
      this.createRequest.hierarchies = this.hierarchies.map(ht => {
        let exists = this.fileStructure.excel_map?.hierarchies?.find(x => x.ht_id === ht.ht_id);
        return {
          ...ht,
          config: ht.levels.map((htl, htlIdx) => { 
            let item = exists?.config.find(x => x.level === (htlIdx+1) && x.name === htl.name);
            return {
              level: (htlIdx + 1),
              name: htl.name,
              valid: item ? item.valid : false,
              type: item ? item.type : "value",
              show: false,
              value: item ? item.value : "",
              col_header: item ? item.col_header : null,
              column: item ? item.column : null,
              show_menu: false,
            }
          })
        }
      });

      /*this.createRequest.hierarchies.forEach(ht => {
        ht.config = [];
        let exists = this.fileStructure.excel_map?.hierarchies?.find(x => x.ht_id === ht.ht_id);
        ht.levels.forEach((htl, htlIdx) => {
          let item = exists?.config.find(x => x.level === (htlIdx+1) && x.name === htl.name);
          ht.config.push({
            level: (htlIdx + 1),
            name: htl.name,
            valid: item ? item.valid : false,
            type: item ? item.type : "value",
            show: false,
            value: item ? item.value : "",
            col_header: item ? item.col_header : null,
            column: item ? item.column : null
          });
        })
      });

      this.createRequest.hierarchies = JSON.parse(JSON.stringify(this.createRequest.hierarchies.map(object => ({ ...object }))));*/
    },    
    setData(){
      if(this.fileStructure.excel_map){
        //fileStructure.value.headers = fileStructure.value.excel_map.headers ? fileStructure.value.excel_map.headers : fileStructure.value.headers;
        let newData  = this.fileStructure.headers.map(h => {
          let exists = this.fileStructure.excel_map?.headers?.filter(x => (x.value === h.value || x.col_header === h.value) && x.col_idx === h.idx);
          let dcMapped = exists?.find(x => x.dc_map);
          if(dcMapped && this.createRequest.dataCaptureMap){
            let source = this.createRequest.dataCaptureMap.find(x => x.data_capture_type_id === dcMapped.dc_map.data_capture_type_id);
            if(source){
              source.type = "map";
              source.value = dcMapped.value;
              exists.dc_map = source;
            }
          }
          return {
            ...h,
            mappings: exists?.length > 0 ? [...exists] : h.mappings
          }
        })

        this.fileStructure.headers = newData;
      }
    },
    clearHrConfig(){
      this.hrMenu.show = false;
      this.hrMenu.item.valid = false;
      this.hrMenu.item.value = null;
      this.hrMenu.item.type = "value";
      this.createRequest.hierarchies = this.createRequest.hierarchies.map(object => ({ ...object }));  
    },
    saveHrConfig(){
      this.hrMenu.show = false;
      this.hrMenu.item.valid = true;
      this.createRequest.hierarchies = this.createRequest.hierarchies.map(object => ({ ...object }));  
    },    
    addMapping(item){
      item.mappings.push(this.baseConfig());
    },
    updateHr(item) {
      switch(item.type){
        case "column_map":
          item.hr = null;
          item.hr_level = null;
          break;
        case "hierarchy_map":
          item.tmpl_part = null;
          break;
      }
    },
    modifyMap(item) {
      switch(item.type){
        case "column_map":
          item.hr = null;
          item.hr_level = null;
          break;
        case "hierarchy_map":
          item.tmpl_part = null;
          break;
      }
    },
    openEditHeaderConfig(item){
      this.editHeaderConfig = {
        parent: {
          ...item,
          show: true
        }
      }
    },
    openEditHrConfig(item){
      this.editHrConfig = {
        parent: {
          ...item,
          show: true
        }
      }
    },
    baseConfig() {
      return {
        show: false,
        selected: false,
        hidden: false
      }
    },
    hideColumn(item){
      item.hidden = true;
      item.show = false;
      this.columnDialog.show = false;
    },
    cancelColumnConfig(item){ 
      let mapping = item.mappings[this.activeTab];
      mapping.selected = false;
      mapping.type = null;
      item.show = false;

      if(mapping.hr){
        let ht = this.createRequest.hierarchies.find(x => x.ht_id === mapping.hr.ht_id);
        let level = ht.config.find(x => x.level === mapping.hr_level);
        level.value = "";
        level.valid = false;
        level.type = "value";
      }

    },
    loadDataCaptureItems(item, selectedTemplate){
      let dc = selectedTemplate.lifecycles[0].dataCaptures;//.filter(x => x.method === 'form');
      dc.forEach(c => {
        switch(c.method){
          case "form":
            item.dataCaptureMap.push({"method":"form","data_capture_field":c.input_label,"value":"", "type":"","data_capture_mandatory":c.data_capture_mandatory, "data_capture_type_id":c.data_capture_type_id}); 
            break;  
          default:
            item.dataCaptureMap.push({ ...c, "type":"" });  
            break;      
        }
      })
      
      item.requiresDataCapture =  true;
    },
    docTypeChange(){
      let selectedTemplate = this.$loginState.user.uploadDocumentOptions.find(
          (a) => a.tmpl_name === this.createRequest.tmpl_id.tmpl.tmpl_name
        );
      this.selectedActionNonDefault = false;        
      if (selectedTemplate && selectedTemplate.lifecycles.length > 0) {
        this.createRequest.dataCaptureMap = [];
        this.createRequest.requiresDataCapture =  false;
        if (selectedTemplate.lifecycles.length === 1) {
          selectedTemplate.selectedActionId = selectedTemplate.lifecycles[0].lifecycle_action_id;
          if(selectedTemplate.lifecycles[0].dataCaptures){
            this.loadDataCaptureItems(this.createRequest, selectedTemplate);
          }
        }
        this.createRequest.selectedTemplate = selectedTemplate;
        this.setAvailableParts(this.createRequest.tmpl_id.tmpl.tmpl_id);
        this.setData();
        this.validAction = true;
      } else {
        this.validAction = false;
      }
    },    
    getTmplPartDetails(item){
      if(item.tmpl_part.tmpl_part_id === 0){
        item.data = item.tmpl_part.value;
      } else {
        let tmpl = this.tmplFullDetails.find(x => x.tmpl.tmpl_id === item.tmpl_part.tmpl_id);
        let part = tmpl.detail.parts.find(p => p.tp_id === item.tmpl_part.tmpl_part_id);
        let childPart = tmpl.detail.parts.find(p => p.parent_tp_id === part.tp_id);
        if(childPart){
          item.child_part = {
            tp_name: childPart.tp_name,
            tp_id: childPart.tp_id
          };
          item.has_child_part = true;
          item.map_child_part = false;
          item.child_part_delimiter = null;
        }
        let tagType =  part.attributes.find(x => x.tag_type_id);
        item.uses_tags = part.attributes.some(x => x.tag_type_id);
        item.tag_type = tagType ? tmpl.detail.tagTypes.find(x => x.tag_type_id === tagType.tag_type_id).name : null;
        item.uses_essential_flag = part.uses_essential_flag;
        item.attr_list = part.attributes.length > 0 ? part.attributes.filter(a => a.active).map(a => {
          return {
            title: `(${a.position}) ${a.title}`,
            tpa_id: a.tpa_id,
            separator: a.separator,
            type: null,
            mapped: false,
            text: "",
            tag_type_capture_rating: a.tag_type_capture_rating,
            tag_type_id: a.tag_type_id
          }
        }) : [];
        if(item.attr_list.length > 0){
          item.tpa_map = null;
        }
      }
    }, 
    saveColumnConfig(item){

      item.mappings.forEach(m => {
          m.value = item.value;
          m.col_header = item.value;
          m.col_idx = item.idx;
          switch(m.type){
          case "update":
            m.update = true;
            break;
          case "dc_map": {
            let dc = this.createRequest.dataCaptureMap.find(x => x.data_capture_field === m.dc_map.data_capture_field);
            dc.type = "map";
            dc.value = item.value;
            dc.dc_split = m.dc_split;
            dc.dc_split_value = m.dc_split_value;
            dc.dc_split_value_idx = m.dc_split_value_idx;
            m.dc_map.type = "map";
            m.dc_map.value = item.value;
            break;
          }
          case "column_map":
            m.hr = null;
            m.hr_level = null;
            m.col_header = item.value;
            m.tmpl_part_id = m.tmpl_part.tmpl_part_id;
            break;
          case "hierarchy_map": {
              m.tmpl_part = null;
              m.hr = {
                ht_id: m.hr.ht_id,
                ht_name: m.hr.ht_name,
                levels: m.hr.levels.map(l => {
                  return {
                    index: l.index,
                    name: l.name
                  }
                })
              };
              let htItem = this.createRequest.hierarchies.find(x => x.ht_id === m.hr.ht_id);
              let hrItem = htItem.config.find(x => x.level === m.hr_level);
              hrItem.type = "map";
              hrItem.valid = true;
              m.column = item.value;
              hrItem.col_header = item.value;
              hrItem.column = item.value;
              break;
            }
        }
        //createRequest.value.headers.push(JSON.parse(JSON.stringify(item)));
      })

      //this.createRequest.hierarchies = JSON.parse(JSON.stringify(this.createRequest.hierarchies));
      item.show = false;
      this.columnDialog.show = false;
    }, 
    deleteMapping(item){
      item.mappings.splice(this.activeTab,1);
      this.activeTab = this.activeTab - 1;
    }, 
    submitRequest(){

      let action = null;
      let h = this.createRequest;
      if (h.selectedTemplate.lifecyclesHT.length) {
        let htTrigger = h.hierarchies.find(
          (ht) => ht.ht_id === h.selectedTemplate.lifecyclesHT[0].ht_id_empty_trigger
        );
        if (htTrigger.hr_id === -999) {
          action = h.selectedTemplate.lifecyclesHT[0];
        }
      }
      if (!action && h.selectedTemplate.lifecycles.length) {
        if (h.selectedTemplate.lifecycles.length === 2) {
          action =
            h.selectedTemplate.lifecycles[
              h.selectedActionNonDefault ? 1 : 0
            ];
        } else {
          action = h.selectedTemplate.lifecycles.find(
            (x) => x.lifecycle_action_id === h.selectedTemplate.selectedActionId
          );
        }
      }   

      let dcFields = !h.requiresDataCapture ? null : h.dataCaptureMap.map(x => {
        switch(x.method){
          case "form":
            return {
              "method":"form",
              "data_capture_field": x.data_capture_field, 
              "value": x.value, 
              "type": x.type === "" ? "value" : x.type,
              "dc_split": x.dc_split,
              "dc_split_value": x.dc_split_value,
              "dc_split_value_idx": x.dc_split_value_idx
            }     
          default:
              return x;             
        }
      });

      dcFields?.forEach((dc) => {
        if (dc.method === "requisition") {
          dc.requisitions = [
            {
              isNew: true,
              requisition_number: dc.value,
            },
          ];
        }
      });

      let allHrs = this.createRequest.hierarchies.map(x => {
        return x.config.flat()
      }).flat();

      let headers = this.fileStructure.headers.filter(x => x.mappings.some(m => m.selected)).map(r => { return r.mappings.flat() }).flat().filter(x => x.type !== "hierarchy_map");     
   
      let isValid = !allHrs.some(x => !x.valid) && this.createRequest.tmpl_id !== 0;
      
      let isUpdate = headers.some(x => x.selected && x.type === "update");
      //let isLink = headers.some(x => x.selected && x.type === "link");

      if(!isUpdate && !isValid){
        //create docs + missing hierarchy
        this.triggerNotification("Missing Hierarchy Data", "error")
        return;
      }

      let dcMandatory = this.createRequest.dataCaptureMap?.some(x => x.data_capture_mandatory === 1 && !x.value);

      if(dcMandatory && !isUpdate){
        this.triggerNotification("Data Capture Mandatory - Please Map!", "error")
        return;
      }

      let hierarchies = this.createRequest.hierarchies.map(h => {
        return {
          ht_id: h.ht_id,
          label: h.label,
          levels: h.h_levels,
          config: h.config
        }
      });

   
      /*if(!headers.some(x => x.tmpl_part && x.tmpl_part?.tmpl_part_id === 0 && x.tmpl_part?.value === "job")){
        this.triggerNotification("Missing HTML Mapping", "error")
        return;
      }*/

      let mappingFormat = this.restructureMappingFormat();

      let data = {
        file_id: this.fileStructure.file_id,
        row_start: this.createRequest.row_start,
        row_end: this.createRequest.row_end,
        doc_type: this.createRequest.tmpl_id.tmpl.tmpl_name,
        action: action,
        dataCaptureMap: dcFields,
        config : {
          headers: headers,
          hierarchies: hierarchies
        },
        mapping: mappingFormat
      };

      this.creating = true;

      axios
			.post("document/docCreationRequest", data)
			.then((resp) => {
				if (resp.data.Status === "OK") {
          let data =  resp.data;
          if(data.Process === "Queue"){
            let newHistory = {
              ...data.Data[0],
              message: "",
              row_start: this.createRequest.row_start,
              row_end: this.createRequest.row_end,
              status: "Queued",
              rows:[]
            }
            this.fileStructure.history.unshift(newHistory);
            this.historyLog = this.fileStructure.history[0];
            this.historyDialog = true;
            this.refreshLive = true;
            this.triggerNotification("Documents Creation Request Queued!","success");
            setTimeout(this.getProcessStatus, 3000);
          } else {
            this.triggerNotification("Documents Created Successfully!","success");
          }
				}
        this.creating = false;
			})
			.catch((err) => {
        console.log(err);
        this.creating = false;
			});
    },
    downloadFile(fileId){
      let possibleError = false;
      this.isLoading = true;          
        axios
        .get("file/download/", {responseType: 'text', params: { id: fileId}})
        .then(resp => {
            possibleError = true;
            window.open(resp.data,"_blank");
            this.isLoading = false;  
        })
        .catch(err => {
          if (possibleError) {
              alert("Code Error");
          } else if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
          } else {
              alert(err.response ? err.response.data.message : err);
          }
          this.isLoading = false;
        });
      },      
    closeHistoryLog(){
      this.refreshTimeout = null;
      this.historyLog = null;
    },
    loadHistoryLog(h){
      this.historyLog = h;
      if(this.historyLog.status !== "Complete" && this.historyLog.status !== "Stalled"){
        setTimeout(this.getProcessStatus, 3000);
      }
    },
    changeRefresh(){
      if(this.refreshLive){
        this.getProcessStatus();
      } else {
        this.refreshTimeout = null;
      }
    },
    getProcessStatus(){
      if (!this.historyLog.doc_creation_request_id || !this.refreshLive) {
        this.historyLog.statusLoading = false;
        this.refreshTimeout = null;
        return;
      }
      this.historyLog.statusLoading = true;
      let possibleError = false;
      this.response = null;
      axios
        .get("document/docCreationRequestState/" + this.historyLog.doc_creation_request_id)
        .then((resp) => {
          possibleError = true;
          this.historyLog = resp.data;
          this.historyLog.statusLoading = false;
          this.historyLog.progress = resp.data.progress;
          if (this.historyLog.status !== "Complete" && this.historyLog.status !== "Stalled") {
            this.refreshTimeout = setTimeout(this.getProcessStatus, 3000);
          } else {
            this.refreshTimeout = null;
            this.refreshLive = false;
          }
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },  
    restructureMappingFormat(){      
      let headers = this.fileStructure.headers.filter(x => x.mappings.some(m => m.selected)).map(r => { return r.mappings.flat() }).flat();
      let hierarchyMapping = {
        type: "hierarchy_map",
        ht: this.createRequest.hierarchies.map(h => {
          return {
            ht_id: h.ht_id,
            ht_name: h.ht_name,
            levels: h.config.map(c => {
              return {
                level: c.level,
                type: c.type,
                column: c.type === "map" ? c.col_header : null,
                addProcess: false,
                value: c.type === "value" ? c.value : null,
                process: {
                  type: null,
                  value: null
                }
              }
            })
          }
        })
      }
      let mappingFormat = [
        hierarchyMapping,
        ...headers.filter(x => x.type !== "hierarchy_map").map(h => {
          return {
            ...h,
            data: h.tmpl_part_id ? h.value : h.data,
            delimiter: h.delimiter ?? null,
            dc_map: h.dc_map ?? null
          }
        })
      ];
      return mappingFormat;
    },
    saveFileExcelMap(){

      let hierarchies = this.createRequest.hierarchies.map(h => {
        return {
          ht_id: h.ht_id,
          label: h.label,
          levels: h.h_levels,
          config: h.config
        }
      });

      let headers = this.fileStructure.headers.filter(x => x.mappings.some(m => m.selected)).map(r => { return r.mappings.flat() }).flat();
      
      let mappingFormat = this.restructureMappingFormat();

      let data = {
        file_id: this.fileStructure.file_id,
        config : {
          headers: headers,
          hierarchies: hierarchies,
          docType: this.createRequest.tmpl_id.tmpl.tmpl_id 
        },
        mapping: mappingFormat
      };

      console.log(data);

      axios
			.post("file/saveFileExcelMap", data)
			.then((resp) => {
				if (resp.data.Status === "OK") {
          this.triggerNotification("Settings Saved Successful", "success");
				}
			})
			.catch((err) => {
        console.log(err);
			});
    }
  }
}
</script>
<style scoped lang="scss">
.uploadContentPnl{
    width:calc(100vw - 60px);
    height:calc(100vh - 90px);
    max-height:calc(100vh - 90px);
    margin:20px;
}

table {
  border: 1px solid black;
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
}

tr,
th:first-child {
  border: 1px solid black;
  width: 100px;
  overflow: hidden;
  max-height:20px;
}

tr,
th:not(first-child) {
  border: 1px solid black;
  width: 250px;
  overflow: hidden;
  max-height:20px;
  height: 50px;
}

tr, td:first-child {
  border: 1px solid black;
  overflow: hidden;
  max-height:20px;
  height:20px;
  line-height:0;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}

tr, td {
  border: 1px solid black;
  overflow: hidden;
  max-height:20px;
  height:20px;
  line-height:0;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  margin-left:5px;
  margin-right:5px;
}

tr th.activeHeader{
  background-color:#00a30038;
}

tr td.activeHeader{
  background-color:#00a30038;
}
</style>
