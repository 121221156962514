<template>
  <div>
    <v-container
      style="max-width:100% !important;"
      class="no-print"
    >
      <v-row dense>
        <v-col cols="12">
          <v-row dense>
            <v-col cols="1" style="max-width:120px;">
              <v-icon
                @click="exportToCSV"
                class="float-right"
                color="green darken-2"
                title="Download List"
              >mdi-cloud-download</v-icon>
            </v-col>
            <!-- <v-col cols="2">
              <v-select
                v-model="selectedPartType"
                :items="rules"
                item-text="tp_name"
                item-value="tp_id"
                label="Part Type"
              ></v-select>
            </v-col>-->
            <v-col cols="3">
              <v-text-field
                :value="filterText"
                dense
                flat
                clearable
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="type text and enter to filter text..."
                class="hidden-sm-and-down"
                @input="changefilterText"
                @keypress.enter="triggerFilter"
                @click:clear="clearFilter"
              />
            </v-col>
            <v-col cols="3">
              <v-select
                v-model="docStatusIncluded"
                :items="docStatusSummary"
                item-text="status"
                item-value="status"
                return-object
                label="Job Statuses Included"
                multiple
                :menu-props="{ 'closeOnClick': true, 'closeOnContentClick': true }"
                dense
                @change="doFilter()"
              >
                <template v-slot:selection="{ item, index }">
                  <span>{{ getStatusFilterText(item, index) }}</span>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col></v-col>
          </v-row>
          <div v-if="items.length" class="no-print">
            <v-data-table
              :headers="dtHeaders"
              dense
              :items="items"
              sort-by="reference"
              sort-desc
              class="elevation-1"
              :footer-props="{'items-per-page-options':[10, 20, 30, 50, -1]}"
              :items-per-page="dtItemsPerPage"
              @update:options="dtOptionChange"
            >
              <template #[`item.reference`]="{ item }">
                <div class="cellClick" @click="openDocument(item)">
                  <span v-html="item.reference" style="font-size:0.8rem"></span>
                </div>
              </template>
              <template #[`item.doc_status_text`]="{ item }">
                <span v-html="item.doc_status_text" style="font-size:0.8rem"></span>
              </template>
              <template #[`item.doc_name`]="{ item }">
                <div class="cellClick" @click="openDocument(item)">
                  <span v-html="item.doc_name" style="font-size:0.8rem"></span>
                </div>
              </template>
              <template #[`item.value`]="{ item }">
                <span v-html="item.value" style="font-size:0.8rem"></span>
              </template>
              <template #[`item.class_types`]="{ item }">
                <div v-for="(ct,cti) in item.class_types" :key="'ct' + cti">
                  <v-combobox
                    v-model="ct.classifications"
                    :items="selectedPartType.class_types[cti].values"
                    item-value="cv_id"
                    item-text="desc"
                    :label="'Select ' + ct.ct_name + '...'"
                    :search-input.sync="ct.search"
                    multiple
                    dense
                    solo
                    flat
                    small-chips
                    hide-selected
                    :hide-no-data="!ct.search"
                    @change="savePartClassifier(ct, item)"
                    :filter="utils.comboFilterPicker"
                  >
                    <!-- @keyup.enter="savePartClassifier(ct, item)" -->
                    <template v-slot:no-data>
                      <v-list-item>
                        <span class="subheading">Hit ENTER to create</span>
                        <v-chip label small>{{ ct.search }}</v-chip>
                        <span class="subheading">or ESCAPE to remove text</span>
                      </v-list-item>
                    </template>
                    <template v-slot:selection="{ attrs, item, parent, selected }">
                      <v-chip
                        v-if="item === Object(item)"
                        v-bind="attrs"
                        :input-value="selected"
                        label
                        small
                      >
                        <v-icon small @click="editClassifier(item)">mdi-pencil</v-icon>
                        <span class="pr-2">{{ item.desc }}</span>
                        <v-icon small @click="parent.selectItem(item)">mdi-close</v-icon>
                      </v-chip>
                    </template>
                    <template v-slot:item="{ index, item }">
                      <v-text-field
                        v-if="ct.editing === item"
                        v-model="ct.editing.value"
                        autofocus
                        flat
                        background-color="transparent"
                        hide-details
                        solo
                        @keyup.enter="edit(index, item)"
                      ></v-text-field>
                      <v-chip v-else dark label small>{{ item.desc }}</v-chip>
                    </template>
                  </v-combobox>
                </div>
              </template>
              <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">Reset</v-btn>
              </template>
            </v-data-table>
          </div>
        </v-col>
      </v-row>

      <ResponseHandler :serviceResponse="response"></ResponseHandler>

      <Loading :isVisible="isLoading" />
      <v-dialog v-model="editedClassifier.show" persistent width="800">
        <v-card class="pt-5">
          <v-card-text>
            <v-row dense>
              <v-col>
                <h3>Edit Classifier</h3>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="2">Pick from list</v-col>
              <v-col cols="4">
                <v-select
                  v-model="editedClassifier.Grouping"
                  label="Grouping"
                  required
                  :items="groupings"
                  item-text="text"
                  item-value="value"
                ></v-select>
              </v-col>
              <v-col cols="2">or type...</v-col>
              <v-col cols="4">
                <v-text-field v-model="editedClassifier.Grouping" label="Grouping" required></v-text-field>
              </v-col>
            </v-row>
            <!-- <v-row dense>
              <v-col cols="2">Pick from list</v-col>
              <v-col cols="4">
                <v-select
                  v-model="editedClassifier.Category"
                  label="Category"
                  required
                  :items="categories"
                  item-text="text"
                  item-value="value"
                ></v-select>
              </v-col>
              <v-col cols="2">or type</v-col>
              <v-col cols="4">
                <v-text-field v-model="editedClassifier.Category" label="Category" required></v-text-field>
              </v-col>
            </v-row>-->
            <v-row dense>
              <v-col cols="8">
                <v-text-field
                  v-model="editedClassifier.Classifier"
                  label="Classifier Value"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="blue darken-1" text @click="deleteClassifier">Delete</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="editedClassifier.show = false">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="saveClassifier">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
//import draggable from "vuedraggable";
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
import utils from "@/common/utils.js";

export default {
  name: "ClassificationList",
  components: {
    ResponseHandler
    // draggable
  },
  props: {},
  data: function() {
    return {
      response: null,
      rules: [],
      items: [],
      cleanItems: [],
      isDirty: false,
      classifierDialogue: false,
      groupings: [],
      categories: [],
      editedClassifier: {
        show: false,
        Classifier: null,
        Category: null,
        Grouping: null
      },
      isLoading: false,
      tempFilterText: "",
      filterText: "",
      dtItemsPerPage: 20,
      dtHeaders: [
        {
          text: "System #",
          align: "start",
          value: "reference",
          width: "140px"
        },
        { text: "Document Title", value: "doc_name" },
        { text: "Document Status", value: "doc_status_text", width: "140px" },
        { text: "Requirement", value: "value", width: "40%" },
        {
          text: "Linked Competencies",
          value: "class_types",
          sortable: false,
          groupable: false,
          width: "600px"
        }
      ],
      dialog: false,
      utils: utils,
      options: { itemsPerPage: 15 },
      selectedPartType: false,
      docStatusSummary: [],
      docStatusIncluded: [],
      defaultStatusFilterList: "approved,pre-draft,pending,draft"
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  created() {
    this.fetchData();
    this.dtItemsPerPage = Number(localStorage.getItem("dtCL")) || 20;
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      let possibleError = false;
      axios
        .get("classification/list/")
        .then(resp => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            this.rules = resp.data.Data.rules;
            this.rules.forEach(r => {
              r.class_types.forEach(ct => {
                ct.values.forEach(v => {
                  this.setClassifierDescription(v);
                });
              });
            });
            this.selectedPartType = this.rules[0];
            this.docStatusSummary.length = 0;
            this.cleanItems = resp.data.Data.docParts.map(x => {
              let n = JSON.parse(JSON.stringify(x));
              n.match = `${n.tp_name} ${n.reference} ${n.doc_name} ${n.value}`
                .toLowerCase()
                .split(" ")
                .filter(x => x.length)
                .reduce((p, c) => p + c + "|", "|");
              n.class_types.forEach(ct => {
                ct.classifications.forEach(c => this.setClassifierDesc(c));
                ct.original = JSON.parse(JSON.stringify(ct.classifications));
                ct.search = "";
                ct.editing = null;
              });

              let ss = this.docStatusSummary.find(
                s => s.status === n.doc_status_text
              );
              if (!ss) {
                ss = { status: n.doc_status_text, count: 0, show: true };
                this.docStatusSummary.push(ss);
              }
              ss.count++;

              return n;
            });

            this.docStatusIncluded = this.docStatusSummary.filter(
              s =>
                this.defaultStatusFilterList.indexOf(s.status.toLowerCase()) >=
                0
            );

            this.doFilter();
            this.isLoading = false;
          }
          this.response = resp.data;
          //console.log(resp);
        })
        .catch(err => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
          this.isLoading = false;
        });
    },
    setClassifierDesc(item) {
      item.desc = item.value;
      if (item.desc !== item.category)
        item.desc = `${item.desc} / ${item.category}`;
      if (
        item.desc !== item.super_category &&
        item.category !== item.super_category
      )
        item.desc = `${item.desc} / ${item.super_category}`;
    },
    setClassifierDescription(item) {
      this.setClassifierDesc(item);
      let g = this.groupings.find(x => x.text === item.super_category);
      if (!g)
        this.groupings.push({
          text: item.super_category,
          value: item.super_category,
          categories: []
        });
      let c = this.categories.find(x => x.text === item.category);
      if (!c)
        this.categories.push({ text: item.category, value: item.category });
    },
    doFilter() {
      const filterText = (this.filterText || "").trim().toLowerCase();

      let items = JSON.parse(JSON.stringify(this.cleanItems));

      if (this.docStatusSummary.length > this.docStatusIncluded.length) {
        items = items.filter(d =>
          this.docStatusIncluded.find(s => s.status === d.doc_status_text)
        );
      }

      if (filterText) {
        let search = filterText
          .split(" ")
          .filter(x => x.length)
          .map(x => x);

        items = items.filter(d =>
          search.every(s => d.match.indexOf(s, 0) >= 0)
        );
        items.forEach(d => {
          search.forEach(s => {
            ["tp_name", "reference", "doc_name", "value"].forEach(col => {
              let val = d[col] || "",
                posMs = val.indexOf("<mark>"),
                posMe = val.indexOf("</mark>"),
                posPrev = 0,
                output = "";
              while (posMs >= 0) {
                if (posMs - posPrev > 0) {
                  output += val
                    .substring(posPrev, posMs)
                    .replace(
                      new RegExp(s, "gi"),
                      match => `<mark>${match}</mark>`
                    );
                }
                output += val.substring(posMs, posMe + 7);
                posPrev = posMe + 7;
                posMs = val.indexOf("<mark>", posMe);
                posMe = val.indexOf("</mark>", posMs);
              }
              if (posPrev < val.length) {
                output += val
                  .substring(posPrev, val.length)
                  .replace(
                    new RegExp(s, "gi"),
                    match => `<mark>${match}</mark>`
                  );
              }
              d[col] = output;
            });
          });
        });
      }
      this.items = items;
    },
    changefilterText(val) {
      this.tempFilterText = val;
    },
    clearFilter() {
      this.tempFilterText = "";
      this.triggerFilter();
    },
    triggerFilter() {
      this.filterText = this.tempFilterText;
      this.doFilter();
    },
    getStatusFilterText(item, index) {
      if (item && index > 0) return "";
      if (this.docStatusIncluded.length === this.docStatusSummary.length) {
        return this.docStatusSummary.length > 0 ? "All" : "None";
      } else {
        let defList = this.defaultStatusFilterList.split(",");
        if (
          defList.every(s =>
            this.docStatusIncluded.some(si => si.status.toLowerCase() === s)
          )
        ) {
          let text = "";
          text = "Default statuses";
          if (defList.length < this.docStatusIncluded.length) {
            text +=
              " (+ " +
              this.docStatusIncluded
                .filter(ds => !defList.some(d => d === ds.status.toLowerCase()))
                .reduce((prev, curr) => {
                  return prev + (prev ? ", " : "") + curr.status;
                }, "");
          }
          return text;
        } else {
          return this.docStatusIncluded.reduce((prev, curr) => {
            return prev + (prev ? ", " : "") + curr.status;
          }, "");
        }
      }
    },
    dtOptionChange(value) {
      localStorage.setItem("dtCL", value.itemsPerPage);
      this.dtItemsPerPage = value.itemsPerPage;
    },
    exportToCSV() {
      let data = this.dtHeaders.map(h => '"' + h.text + '"').join(",") + "\n";
      this.items.forEach(d => {
        data +=
          this.dtHeaders
            .map(h => utils.csvEscape(utils.removeTags(d[h.value])))
            .join(",") + "\n";
      });
      utils.downloadFile(data, "Users.csv", "text/csv;encoding:utf-8");
    },
    editClassifier(item) {
      this.editedClassifier.show = true;
      this.editedClassifier.item = item;
      this.editedClassifier.Classifier = item.value;
      this.editedClassifier.Category = item.category;
      this.editedClassifier.Grouping = item.super_category;
    },
    saveClassifier() {
      let data = {
        cv_id: this.editedClassifier.item.cv_id,
        value: this.editedClassifier.Classifier,
        category: this.editedClassifier.Classifier, // this.editedClassifier.Category, //UNTIL all three levels are used again...
        super_category: this.editedClassifier.Grouping
      };
      axios
        .post("classification/saveClassifier/", data)
        .then(resp => {
          if (resp.data.Status === "OK") {
            this.editedClassifier.show = false;
            this.editedClassifier.item.Classifier = data.value;
            this.editedClassifier.item.Category = data.category;
            this.editedClassifier.item.Grouping = data.super_category;
            this.setClassifierDescription(this.editedClassifier.item);

            this.selectedPartType.class_types.forEach(ct => {
              let cv = ct.values.find(c => c.cv_id === data.cv_id);
              if (cv) {
                cv.value = data.value;
                cv.category = data.category;
                cv.super_category = data.super_category;
                this.setClassifierDescription(cv);
              }
            });

            this.cleanItems.forEach(x => {
              x.class_types.forEach(ct => {
                ct.classifications.forEach(c => {
                  if (c.cv_id === data.cv_id) {
                    c.value = data.value;
                    c.category = data.category;
					c.super_category = data.super_category;
					this.setClassifierDesc(c);
                  }
                });
              });
            });
            this.doFilter();
          }
          this.response = resp.data;
          console.log(resp);
          this.isLoading = false;
        })
        .catch(err => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    deleteClassifier() {
      let data = {
        cv_id: this.editedClassifier.item.cv_id
      };
      axios
        .post("classification/saveClassifier/", data)
        .then(resp => {
          if (resp.data.Status === "OK") {
            this.editedClassifier.show = false;
            this.editedClassifier.item = null;
            this.cleanItems.forEach(x => {
              x.class_types.forEach(ct => {
                ct.classifications = ct.classifications.filter(
                  c => c.cv_id !== data.cv_id
                );
              });
            });
            this.selectedPartType.class_types.forEach(ct => {
              ct.values = ct.values.filter(c => c.cv_id !== data.cv_id);
            });
            this.doFilter();
          }
          this.response = resp.data;
          console.log(resp);
          this.isLoading = false;
        })
        .catch(err => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    openDocument(item) {
      this.$emit("openDocument", {
        system_number: utils.removeTags(item.reference),
        doc_name: utils.removeTags(item.doc_name),
        doc_type: "Job Advert"
      });
    },
    savePartClassifier(classType, item) {
      //compare with original
      //console.log(ct_id + classType.values.length);
      let data = {
        deleted: classType.original.filter(
          o => !classType.classifications.some(c => o.cv_id === c.cv_id)
        ),
        added: classType.classifications
          .filter(
            o =>
              typeof o === "string" ||
              !classType.original.some(
                c => o.value.toLowerCase() === c.value.toLowerCase()
              )
          )
          .map(a => {
            let ret = null;
            if (typeof a === "string") {
              // check doesn't already exist
              let val = a.toLowerCase();
              let existing = this.selectedPartType.class_types
                .find(ct => ct.ct_id === classType.ct_id)
                .values.find(
                  cv =>
                    cv.value.toLowerCase() === val &&
                    cv.category.toLowerCase() === val &&
                    cv.super_category.toLowerCase() === val
                );
              ret = existing ? existing : { value: a, cv_id: null };
            } else {
              ret = a;
            }
            ret.ct_id = classType.ct_id;
            ret.dp_id = item.dp_id;
            return ret;
          })
      };
      classType.search = "";
      axios
        .post("classification/saveClassififcation/", data)
        .then(resp => {
          if (resp.data.Status === "OK") {
            let clean = this.cleanItems
              .find(ci => ci.dp_id === item.dp_id)
              .class_types.find(ct => ct.ct_id === classType.ct_id);
            if (data.added.length && resp.data.Data[0].length) {
              let added = resp.data.Data[0][0];
              this.setClassifierDesc(added);
              if (!clean.classifications.find(c => c.dpc_id === added.dpc_id)) {
                clean.classifications.push(added);
                let existing = this.selectedPartType.class_types.find(
                  r => r.ct_id === classType.ct_id
                ).values;
                if (!existing.some(v => v.cv_id === added.cv_id)) {
                  let newitem = {
                    cv_id: added.cv_id,
                    value: added.value,
                    category: added.category,
                    super_category: added.super_category
                  };
                  this.setClassifierDescription(newitem);
                  existing.push(newitem);
                }
              }
            }
            if (data.deleted.length) {
              clean.classifications = clean.classifications.filter(
                c => c.dpc_id !== data.deleted[0].dpc_id
              );
            }
            clean.original = JSON.parse(JSON.stringify(clean.classifications));
            classType.classifications = JSON.parse(
              JSON.stringify(clean.classifications)
            );
            classType.original = JSON.parse(
              JSON.stringify(clean.classifications)
            );
          }
          this.response = resp.data;
          console.log(resp);
          this.isLoading = false;
        })
        .catch(err => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    }
  }
};
</script>
<style scoped>
.v-list-item__action {
  margin: 3px 0;
}
.v-label {
  font-size: 0.9rem;
}
div.cellClick {
  width: 100%;
  cursor: pointer;
}
</style>
