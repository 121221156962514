<template>
  <div>
    <v-row v-if="isLoading" class="d-flex justify-center">
      <v-progress-circular
        indeterminate
        color="primary"
        size="25">
      </v-progress-circular>
    </v-row>
    <template v-else>
      <v-row v-for="(c, ci) in changes" :key="ci" class="align-center">
        <v-col>
          <span v-if="c.field" class="font-weight-bold mr-3" style="display: inline-block; min-width: 150px;">{{ c.field }}</span>
          <v-chip v-if="c.essential_changes" class="float-right" small><span v-html="c.essential_changes"></span></v-chip>
          <span v-html="c.diff"></span>
        </v-col>
      </v-row>
    </template>
    <ResponseHandler :serviceResponse="response"></ResponseHandler>
  </div>
</template>

<script>
import axios from "axios";
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
import utils from "@/common/utils.js";

export default {
  name: "DocumentActivityDetails",
  components: {
    ResponseHandler,
  },
  props: {
    doc_id: { type: Number, required: true },
    activity_id: { type: Number, required: true }
  },
  data: function () {
    return {
      changes: [],
      isLoading: false,
      response: null,
      utils: utils
    };
  },
  updated() {},
  mounted() {
    this.getData();
  },
  watch: {
  },
  methods: {
    getData() {
      this.isLoading = true;
      const params = {
        doc_id: this.doc_id,
        activity_id: this.activity_id
      }
      axios
        .get("document/documentActivityDetails", { params: params })
        .then((resp) => {
          if (resp.data.Status === "OK") {
            this.changes = resp.data.Data;
          } else {
            alert(resp.data.Message);
          }
          this.response = resp.data;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
  },
};
</script>
<style scoped lang="scss">

</style>
