<template>
  <div v-if="!item.children">
    <v-row
      dense
      v-if="item.job_count && (!showDetails || !detailDef.titleWidths.length)"
    >
      <v-col
        v-for="(m, mi) in visibleMeasures"
        :key="'m' + mi"
        :cols="detailDef.measureWidths[mi].cols"
        class="measure-column"
      >
        <MeasureCount
          :item="item"
          :measure="m"
          :displayType="measureDisplayType"
          :selectable="m.type === 'job_count'"
          @click="$emit('selectJob', item)"
          @showDocList="$emit('openDocListMenu', ...arguments)"
        ></MeasureCount
      ></v-col>
    </v-row>
    <template v-if="showDetails">
      <v-row
        class="flex-nowrap"
        dense
        v-for="(g, gi) in item.categories.filter(
          (x) => x && x.dIndex >= vScroll.dStart && x.dIndex <= vScroll.dEnd
        )"
        :key="'g' + gi"
        ><v-col v-if="detailDef.titleWidths.length" :style="{ maxWidth: detailDef.titleSectionWidth }" class="standard-column">
          <v-row class="flex-nowrap" dense :class="g.rowHeadings.length > 1 ? 'category_detail' : null">
            <v-col v-for="(h, hi) in g.rowHeadings" :key="'g' + gi + 'h' + hi" :cols="detailDef.titleWidths[hi].cols" :title="h.column_name + ': ' + h.title">
                  <div
                    v-if="h.doc_id"
                    class="document_cell"
                    @contextmenu="$emit('openDocActionsMenuContext', $event, h)"
                    :title="h.title + ': Click to view ' + catalogueDocType.docType"
                  >
                    <DocSummary
                      :doc_id="h.doc_id"
                      :text="h.title"
                      @openDocument="$emit('openDocument', $event)"
                      @copyDocument="$emit('copyDocument', $event)"
                    ></DocSummary>
                    <!-- <span :title="h.title">{{ h.title }} </span> -->
                    <v-badge
                      color="red"
                      v-if="h.match_count && 1 === 0"
                      :content="h.match_count"
                      offset-y="10"
                    >
                      <v-icon
                        class="float-fight"
                        color="blue"
                        small
                        title="click to compare similar docments"
                        @click="$emit('showMatches', g)"
                        >mdi-content-duplicate</v-icon
                      >
                    </v-badge>
                  </div>
                  <div v-else-if="h.isSelectable">
                    <v-checkbox
                      v-model="h.selected"
                      class="label_cell"
                      :label="h.title"
                      style="margin-top: 0"
                      @click="$emit('selectJob', g)"
                    >
                    </v-checkbox>
                  </div>
                  <div
                    v-else-if="h.isLeafNode && h.node" style="hover"
                    v-on:dragstart="$emit('dragStart', $event, h)"
                    :draggable="
                      h.node && !h.node.actionPending && h.node.level > 1
                    "
                    :id="'dragSource' + h.value"
                    :class="{
                      draggable:
                        h.node && !h.node.actionPending && h.node.level > 1,
                    }"
                  >
                    <HierarchyNodeDisplay
                      :node="h.node"
                      displayFilled
                      @action="$emit('doMenuAction', $event, null, null, g)"
                      @click="$emit('viewNodeDoc', $event, g)"
                      @removeActionPending="$emit('removeAdditionNode', h.node)"
                    ></HierarchyNodeDisplay>
                  </div>
                  <div v-else class="label_cell">
                    <span>{{ (h.title_prefix ? h.title_prefix + ": " : "") + h.title }}</span>
                  </div></v-col
                >
          </v-row>
        </v-col>
<v-col v-if="detailDef.measureWidths.length" :style="{ maxWidth: detailDef.measureSectionWidth }"><v-row dense>
        <v-col
          v-for="(m, mi) in visibleMeasures"
          :cols="detailDef.measureWidths[mi].cols"
          :key="'g' + gi + 'm' + mi"
          class="measure-column"
        >
          <MeasureCount
            :item="g"
            :measure="m"
            :displayType="measureDisplayType"
            :backColour="g.colour"
            :selectable="m.type === 'job_count'"
            @click="$emit('selectJob', g)"
            @showDocList="$emit('openDocListMenu', ...arguments)"
          ></MeasureCount
        ></v-col></v-row></v-col> </v-row
    ></template>
  </div>
  <div v-else>
    <v-row dense>
		<template
        v-for="(c, ci) in item.children">
      <v-col
        :key="'ch' + ci"
        :style="c.colCSS"
        class="standard-column"
      >
        <cHierarchyViewColumnDetail
          v-for="(rh, rhi) in c.rowHeadings"
          :key="'ch' + ci + 'rh' + rhi"
          :item="rh"
          :detailDef="detailDef"
          :visibleMeasures="visibleMeasures"
          :measureDisplayType="measureDisplayType"
          :showDetails="showDetails"
          :catalogueDocType="catalogueDocType"
          :vScroll="vScroll"
          @selectJob="$emit('selectJob', ...arguments)"
          @openDocListMenu="$emit('openDocListMenu', ...arguments)"
          @openDocActionsMenuContext="
            $emit('openDocActionsMenuContext', ...arguments)
          "
          @openDocument="$emit('openDocument', ...arguments)"
          @copyDocument="$emit('copyDocument', ...arguments)"
          @showMatches="$emit('showMatches', ...arguments)"
          @dragStart="$emit('dragStart', ...arguments)"
          @doMenuAction="$emit('doMenuAction', ...arguments)"
          @viewNodeDoc="$emit('viewNodeDoc', ...arguments)"
          @removeAdditionNode="$emit('removeAdditionNode', ...arguments)"
        ></cHierarchyViewColumnDetail></v-col
    >
		<div v-if="c.showLine"
        :key="'bh' + ci" class="gridline"></div></template></v-row>
  </div>
</template>
<script>
import HierarchyNodeDisplay from "@/components/hierarchy/cHierarchyNodeDisplay";
import MeasureCount from "@/components/cHierarchyViewMeasureCount.vue";
import DocSummary from "@/components/common/DocViewPopUp";
export default {
  name: "cHierarchyViewColumnDetail",
  props: {
    item: Object,
    detailDef: Object,
    visibleMeasures: Array,
    measureDisplayType: String,
    showDetails: Boolean,
    catalogueDocType: Object,
    vScroll: Object,
  },
  data: function () {
    return {};
  },
  components: {
    HierarchyNodeDisplay,
    MeasureCount,
    DocSummary,
  },
  watch: {},
  computed: {},
  created: function () {},
  methods: {},
};
</script>
  <style scoped lang="scss">
.category_detail {
  border: solid 1px lightgray;
  border-radius: 5px;
  padding: 0px;
    margin-left: 2px;
    margin-right: 2px;
}
.gridline {	
    width: 1px;
    background-color: lightgray;
    margin-top: -20px;
    position: relative;
    margin-bottom: -20px;
}
</style>