<template>
  <div v-if="internal_nodes">
    <v-row dense v-if="viewType === 'tree'">
      <v-col
        ><v-sheet
          :elevation="ht_id ? 2 : 0"
          rounded
          :style="ht_id ? 'margin-top: 10px;' : ''"
        >
          <v-row v-if="ht_id">
            <v-col>
              <div
                style="padding-top: 10px; padding-left: 10px; font-weight: bold"
              >
                <span
                  v-for="i in internal_hierarchy_type.linklevel"
                  :key="'ll' + i"
                >
                  {{ i === 1 ? "" : " > "
                  }}{{ internal_hierarchy_type["level" + i + "_name"] }}
                  <v-icon
                    v-if="i < internal_hierarchy_type.linklevel"
                    @click="expandNodes(i, true)"
                    small
                    :title="
                      'Expand all ' +
                      internal_hierarchy_type['level' + i + '_name'] +
                      'nodes'
                    "
                    >mdi-plus-box-multiple-outline</v-icon
                  >
                  <v-icon
                    v-if="i < internal_hierarchy_type.linklevel"
                    @click="expandNodes(i, false)"
                    small
                    :title="
                      'Collapse all ' +
                      internal_hierarchy_type['level' + i + '_name'] +
                      'nodes'
                    "
                    >mdi-minus-box-multiple-outline</v-icon
                  >
                </span>
              </div></v-col
            >
            <v-col
              ><v-text-field
                class="pt-0"
                hide-details
                outlined
                placeholder="Type words and hit enter to search nodes"
                v-model="treeSearchText"
                append-icon="mdi-text-search"
                clearable
                @keypress.enter="filterTree"
                @click:append="filterTree"
                @click:clear="clearFilterTree"
              ></v-text-field
            ></v-col>
            <!-- <v-col>
            <v-btn small color="primary" class="mb-4" @click="addHierarchy"
              >Add new {{ internal_hierarchy_type.level1_name }}</v-btn
            ></v-col
          > -->
            <v-col>
              <div
                class="status-col"
                style="padding-top: 15px; font-weight: bold"
              >
                Docs
              </div>
              <!-- <div class="status-col" style="padding-top: 15px">HR Code</div> -->
            </v-col>
          </v-row>
          <v-row
            v-for="(node, ni) in visible_nodes"
            :key="'n' + node.hierarchy_node_id"
            dense
            ><v-col>
              <div
                v-if="!ht_id && node.expanded"
                class="vl1"
                :class="ni + 1 < visible_nodes.length ? 'vertical-line-2' : ''"
              ></div>
              <v-row dense>
                <v-col>
                  <div v-if="!ht_id" class="horizontal-line"></div>
                  <div
                    class="vl2"
                    v-if="!ht_id"
                    :class="ni === 0 ? 'vertical-line-1' : 'vertical-line'"
                  ></div>
                  <div
                    class="nodeContainer"
                    @click="
                      node.expanded = !node.expandable ? false : !node.expanded
                    "
                  >
                    <HierarchyNodeDisplay
                      :node="node"
                      treeExpand
                      displayStatus
                      :displayBorder="false"
                      @action="doMenuAction($event)"
                    ></HierarchyNodeDisplay>
                  </div>
                </v-col>
                <v-col cols="3">
                  <div class="status-col">{{ node.hasDocs }}</div>
                </v-col>
              </v-row>
              <v-row dense v-if="node.nodes && node.expanded">
                <v-col cols="1"> </v-col>
                <v-col>
                  <HierarchyTreeNodeHorizontal
                    :nodes="node.nodes"
                    :viewLevel="viewLevel + 1"
                    @pickNode="pickNode"
                    @nodeUpdated="nodeUpdated"
                    :hierarchyType="internal_hierarchy_type"
                    :viewType="viewType"
                    :hideInactive="hideInactive"
                  >
                  </HierarchyTreeNodeHorizontal>
                </v-col>
              </v-row>
            </v-col>
          </v-row> </v-sheet
      ></v-col>
    </v-row>
    <v-card v-if="ht_id && viewType === 'table'">
      <v-card-text>
        <v-data-table
          :headers="listColumns"
          :items="listData"
          :sort-by.sync="listView.sortColumn"
          :sort-desc.sync="listView.sortDesc"
          :dense="listView.density == 'dense'"
          :class="['mt-2', listView.density]"
          :page.sync="listView.currentPage"
          hide-default-footer
          :items-per-page="listView.itemsPerPage"
          :fixed-header="!!listView.tableHeight"
          :height="listView.tableHeight"
        >
          <template v-slot:top>
            <v-row
              ><v-col>
                <!-- <v-spacer></v-spacer -->
                <v-chip
                  small
                  v-for="(f, fi) in filterList"
                  :key="'f' + fi"
                  close
                  @click:close="removeFilter(f)"
                >
                  {{ f.title }}</v-chip
                ></v-col
              >
              <!-- <v-col cols="2">
                <v-btn
                  v-if="ht_id"
                  small
                  color="primary"
                  class="mb-4"
                  @click="addHierarchy"
                  >Add new {{ internal_hierarchy_type.level1_name }}</v-btn
                ></v-col
              > -->
            </v-row>
          </template>
          <!-- eslint-disable vue/valid-v-slot -->
          <template
            v-for="(h, hi) in listColumns"
            v-slot:[`header.${h.value}`]="{ header }"
            ><div style="white-space: nowrap" :key="h.value">
              {{ header.text }}
              <v-menu
                v-if="header.filterSettings"
                v-model="header.filterSettings.show"
                :close-on-content-click="false"
                :nudge-width="200"
                offset-x
                @input="openlistViewFilter(header.filterSettings)"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="blue" small v-bind="attrs" v-on="on" icon>
                    <v-icon small>
                      {{
                        header.filterSettings.isActive
                          ? "mdi-filter-settings"
                          : "mdi-filter-outline"
                      }}</v-icon
                    >
                  </v-btn>
                </template>

                <v-card>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >{{ header.text }} Filters</v-list-item-title
                        >
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn
                          small
                          @click="filterClear(header.filterSettings)"
                        >
                          Clear
                        </v-btn>
                      </v-list-item-action>
                      <v-list-item-action>
                        <v-btn
                          small
                          @click="header.filterSettings.show = false"
                        >
                          Close
                        </v-btn>
                      </v-list-item-action>
                      <v-list-item-action>
                        <v-btn
                          small
                          @click="filterHeading(header.filterSettings)"
                        >
                          Apply
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          ><v-text-field
                            placeholder="type to filter values"
                            hide-details
                            append-icon="mdi-check-all"
                            @click:append="
                              filterSearchAll(header.filterSettings)
                            "
                            @input="filterSearch(header.filterSettings)"
                            dense
                            clearable
                            v-model="header.filterSettings.searchText"
                          ></v-text-field
                        ></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action
                        v-if="header.filterSettings.pages > 1"
                      >
                        <v-pagination
                          v-model="header.filterSettings.page"
                          :length="header.filterSettings.pages"
                          :total-visible="7"
                        ></v-pagination>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                  <v-divider></v-divider>
                  <v-list style="max-height: 100px; overflow: auto">
                    <v-list-item
                      dense
                      v-for="(v, vi) in header.filterSettings.values.filter(
                        (x) => !x.visible && x.selected
                      )"
                      :key="'head' + hi + 'vs' + vi"
                    >
                      <v-list-item-content>
                        <v-switch
                          v-model="v.selected"
                          color="blue"
                          :label="`${v.text} (${v.count})`"
                        ></v-switch>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-list
                    style="max-height: 400px; overflow: none"
                    @wheel.stop="filterScroll($event, header.filterSettings)"
                  >
                    <v-list-item
                      dense
                      v-for="(v, vi) in header.filterSettings.values.filter(
                        (x) =>
                          x.visible && x.page === header.filterSettings.page
                      )"
                      :key="'head' + hi + 'v' + vi"
                    >
                      <v-list-item-content>
                        <v-switch
                          v-model="v.selected"
                          color="blue"
                          :label="`${v.text} (${v.count})`"
                        ></v-switch>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </div>
          </template>
          <!-- eslint-enable vue/valid-v-slot -->
          <template v-slot:item="{ item }">
            <tr>
              <td v-for="col in listColumns" :key="col.value">
                <div v-if="col.value === 'hasDocs'">{{ item.hasDocs }}</div>
                <div v-else-if="col.value === 'hr_code'">
                  {{ item.hr_code }}
                </div>
                <div v-else-if="col.value === 'active'">
                  <v-icon :color="item.active ? 'green' : 'red'">{{
                    item.active
                      ? "mdi-check-circle"
                      : "mdi-emoticon-dead-outline"
                  }}</v-icon>
                </div>
                <HierarchyNodeDisplay
                  v-else-if="item[col.value]"
                  :node="getNode(item, col)"
                  :listviewColumn="col"
                  :listviewItem="item"
                  displayStatus
                  @action="doMenuAction($event)"
                ></HierarchyNodeDisplay>
              </td>
            </tr>
          </template>
        </v-data-table>
        <div
          class="d-flex justify-space-between footer-actions primary-background"
        >
          <PageDescription
            :totalItems="listData.length"
            :pageSize="listView.itemsPerPage"
            :currentPage="listView.currentPage"
          />
          <Pagination
            :totalItems="listData.length"
            :pageSize="listView.itemsPerPage"
            :currentPage="listView.currentPage"
            @pageNavigation="listView.currentPage = $event"
          />
          <div>
            <v-btn
              color="primary"
              small
              outlined
              class="mx-2"
              @click="exportLVToCSV"
            >
              <span>Export</span>
            </v-btn>
            <v-menu offset-y v-if="listView.pageSize === 0" z-index="301">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="!$loginState.readOnly"
                  outlined
                  small
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ itemsPerPageText }}
                  <v-icon right>mdi-chevron-down</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item
                  v-for="item in itemsPerPageOptions"
                  :key="item.value"
                  @click="setLVPageSize(item.value)"
                >
                  <v-list-item-title>
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <AdminHierarchyNodeDetail
      :node="hierarchyEditDialogue.node"
      :mode="hierarchyEditDialogue.mode"
      :action="hierarchyEditDialogue.action"
      :show="hierarchyEditDialogue.show"
      :showAddNodeDescriptor="hierarchyEditDialogue.showAddNodeDescriptor"
      @close="hierarchyEditDialogue.show = false"
      @saved="nodeUpdated"
      @picked="nodePicked"
      @deleted="hierarchyEditDialogue.show = false"
    >
    </AdminHierarchyNodeDetail>
    <!-- <AdminHierarchyAdd
      :ht_id="hierarchyAddDialogue.ht_id"
      :show="hierarchyAddDialogue.show"
      @close="hierarchyAddDialogue.show = false"
      @saved="nodeUpdated"
    >
    </AdminHierarchyAdd> -->
    <HierarchyNodeHistory :node="historyNode"> </HierarchyNodeHistory>
    <AdminHierarchyDetail
      :node="hr_idDialogue.node"
      :show="hr_idDialogue.show"
      @close="hr_idDialogue.show = false"
      @saved="hr_idDialogue.show = false"
    >
    </AdminHierarchyDetail>
    <Loading :isVisible="isLoading" />
  </div>
</template>

<script>
import HierarchyNodeDisplay from "@/components/hierarchy/cHierarchyNodeDisplay";
import Pagination from "@/components/cPagination";
import PageDescription from "@/components/cPageDescription";
import AdminHierarchyNodeDetail from "@/components/hierarchy/cAdminHierarchyNodeDetail";
// import AdminHierarchyAdd from "@/components/hierarchy/cAdminHierarchyAdd";
import HierarchyNodeHistory from "@/components/hierarchy/cHierarchyNodeHistory";
import AdminHierarchyDetail from "@/components/hierarchy/cAdminHierarchyDetail";
import utils from "@/common/utils.js";
export default {
  name: "HierarchyTreeNodeHorizontal",
  props: {
    ht_id: Number,
    nodes: [],
    viewLevel: Number,
    hierarchyType: Object,
    viewType: String,
    hideInactive: Boolean,
    doAdd: Boolean,
  },
  components: {
    HierarchyNodeDisplay,
    Pagination,
    PageDescription,
    AdminHierarchyNodeDetail,
    // AdminHierarchyAdd,
    HierarchyNodeHistory,
    AdminHierarchyDetail,
  },
  data: function () {
    return {
      utils: utils,
      internal_nodes: this.nodes,
      visible_nodes: this.nodes,
      internal_hierarchy_type: this.hierarchyType,
      flattened: [],
      listData: [],
      listColumns: [],
      filterList: [],
      activeFilterCount: 0,
      listView: {
        show: false,
        columnsEnabled: [],
        columns: [],
        filteredItems: [],
        sortColumn: null,
        sortDesc: null,
        pageSize: 0,
        density: "dense",
        currentPage: 1,
        itemsPerPage: 15,
        tableHeight: null,
        hierarchyView: null,
        renderCount: 0,
      },
      hierarchyEditDialogue: {
        show: false,
        mode: "edit",
        node: null,
        showAddNodeDescriptor: false,
      },
      hierarchyAddDialogue: {
        show: false,
        ht_id: null,
      },
      hr_idDialogue: {
        show: false,
        node: null,
      },
      historyNode: null,
      treeSearchText: null,
      isLoading: false,
    };
  },
  watch: {
    ht_id(newVal) {
      if (newVal) this.getData();
    },
    hideInactive() {
      this.filterData();
    },
    doAdd() {
      this.addHierarchy();
    },
    nodes(newVal) {
      this.visible_nodes = newVal;
    },
  },
  computed: {
    cols() {
      return this.getColWidth();
    },
    itemsPerPageOptions() {
      return [
        { text: "10 Rows", value: 10 },
        { text: "15 Rows", value: 15 },
        { text: "20 Rows", value: 20 },
        { text: "25 Rows", value: 25 },
        { text: "30 Rows", value: 30 },
        { text: "35 Rows", value: 35 },
        { text: "40 Rows", value: 40 },
      ];
    },
    itemsPerPage() {
      if (this.listView.pageSize !== 0) return this.listView.pageSize;
      else return this.listView.itemsPerPage;
    },
    itemsPerPageText() {
      const option = this.itemsPerPageOptions.find(
        (o) => o.value == this.listView.itemsPerPage
      );
      return option ? option.text : `$(itemsPerPage) Items`;
    },
  },
  created: function () {
    if (this.ht_id) this.getData();
  },
  mounted: function () {},
  methods: {
    pickNode(node) {
      this.$emit("pickNode", node);
    },
    getData() {
      this.treeSearchText = "";
      this.isLoading = true;
      this.$store
        .dispatch("hierarchies/getNodes", this.ht_id)
        .then((resp) => {
          this.internal_hierarchy_type = resp.data.Data.hierarchyType;
          this.setUpTree(resp.data.Data.nodes, false);
          this.filterData();
        })
        .then(() => {
          this.isLoading = false;
        }),
        (error) => {
          console.error(error);
        };
    },
    expandNodes(level, expand) {
      let doNodes = (nodes) => {
        nodes
          .filter((n) => n.level <= level)
          .forEach((n) => {
            n.expanded = expand;
            if (n.nodes) {
              doNodes(n.nodes);
            }
          });
      };
      doNodes(this.visible_nodes);
    },
    setUpTree(nodes, expandAll) {
      let flattened = [];
      let listColumns = [];
      let getColFactor = (level) => {
        switch (this.internal_hierarchy_type.linklevel - level + 1) {
          case 1:
            return 12;
          case 2:
            return 6;
          case 3:
            return 4;
          case 4:
            return 3;
          default:
            return 2;
        }
      };
      let processNodes = (nodes, parent) => {
        nodes.forEach((n) => {
          if (!listColumns.some((c) => c.level === n.level)) {
            let searchColumn = `col${n.level}_name`;
            listColumns.push({
              level: n.level,
              value: searchColumn,
              usesDescriptor: !!n.usesDescriptor,
              text: this.internal_hierarchy_type["level" + n.level + "_name"],
              filterSettings: {
                show: false,
                column: searchColumn,
                values: [],
                active: false,
                searchText: "",
                page: 1,
                pages: 1,
              },
            });
          }
          let row = parent
            ? flattened.find(
                (x) => x["col" + parent.level] === parent.hierarchy_node_id
              )
            : null;
          if (!row) {
            row = {};
            flattened.push(row);
          } else if (row["col" + n.level]) {
            row = JSON.parse(JSON.stringify(row));
            for (
              let i = n.level + 1;
              i <= this.internal_hierarchy_type.linklevel;
              i++
            ) {
              row[`col${i}`] = null;
              row[`col${i}_name`] = "";
              row[`col${i}_active`] = false;
            }
            flattened.push(row);
          }
          row[`col${n.level}`] = n.hierarchy_node_id;
          row[`col${n.level}_name`] = n.name;
          row[`col${n.level}_active`] = n.active;

          let colFactor = getColFactor(n.level);
          n.cols = colFactor;
          n.childCols = 12 - colFactor;
          //   n.pickable = n.level === pickLevel;
          n.picked = false;
          n.visible = true;
          this.setNodeActiveStatus(n, parent);
          n.canAdd = n.level < this.internal_hierarchy_type.linklevel;
          n.nextLevel = n.canAdd
            ? this.internal_hierarchy_type[`level${n.level + 1}_name`]
            : null;
          n.filterParts = n.name
            .toLowerCase()
            .split(" ")
            .filter((x) => x);
          if (n.nodes) {
            n.expandable = true;
            processNodes(n.nodes, n);
            n.expanded = expandAll;
          } else {
            n.expandable = false;
            row.hasDocs = n.hasDocs;
            row.active = n.hr_active;
            row.hr_code = n.hr_code;
          }
        });
      };

      processNodes(nodes, null);
      this.flattened = flattened;
      listColumns.push({
        level: null,
        value: "hr_code",
        usesDescriptor: false,
        text: "HR CODE",
        filterSettings: {
          show: false,
          column: "hr_code",
          values: [],
          active: false,
          searchText: "",
          page: 1,
          pages: 1,
        },
      });
      listColumns.push({
        level: null,
        value: "hasDocs",
        usesDescriptor: false,
        text: "Documents",
        filterSettings: {
          show: false,
          column: "hasDocs",
          values: [],
          active: false,
          searchText: "",
          page: 1,
          pages: 1,
        },
      });
      listColumns.forEach((c) => {
        c.filterSettings.values = this.distinctValues(flattened, [
          c.level ? "col" + c.level + "_name" : c.value,
        ])
          .map((x, xi) => {
            let col = c.level ? "col" + c.level + "_name" : c.value;
            let text =
              c.value === "active"
                ? x.active
                  ? "Active"
                  : "Inactive"
                : x[col];
            if (text === undefined || text === null) {
              text = "";
            }
            let searchText =
              c.level || c.value === "active" ? text.toLowerCase() : text;
            return {
              text: text,
              value: c.value === "active" ? x[col] : text,
              selected: false,
              visible: true,
              available: true,
              count: x._count,
              searchValues: [searchText],
              page: parseInt(xi / 7) + 1,
            };
          })
          .sort((a, b) =>
            Number(a.text) > Number(b.text)
              ? 1
              : Number(a.text) < Number(b.text)
              ? -1
              : 0
          );
        c.filterSettings.pages =
          parseInt((c.filterSettings.values.length - 1) / 7) + 1;
      });

      this.listColumns = listColumns;
      this.internal_nodes = nodes;
    },
    setNodeActiveStatus(node, parent, makeInactive) {
      if (makeInactive) node.active = 0;
      node.canDeactivate = !!node.active;
      node.canDelete = !!node.active && !node.nodes?.length && !node.hasDocs;
      node.canReactivate = !node.canDeactivate && (!parent || parent?.active);
      if (node.nodes) {
        node.nodes.forEach((n) => {
          this.setNodeActiveStatus(n, node, makeInactive);
        });
      }
    },
    getNode(row, column) {
      let node = null;
      let searchNodes = this.visible_nodes;
      for (let i = 1; i <= column.level; i++) {
        node = searchNodes.find((n) => n.hierarchy_node_id === row["col" + i]);
        if (node && node.nodes) searchNodes = node.nodes;
      }
      return node;
    },
    distinctValues: function (arr, keys) {
      let list = [];
      arr.forEach(function (item) {
        let row = list.filter(function (li) {
          let match = true;
          keys.forEach(function (k) {
            if (li[k] !== item[k]) match = false;
          });
          return match;
        })[0];
        if (!row) {
          row = { _count: 1 };
          keys.forEach(function (k) {
            row[k] = item[k];
          });
          list.push(row);
        } else {
          row._count++;
        }
      });
      return list;
    },
    getColWidth() {
      if (!this.internal_hierarchy_type) return 12;
      switch (this.internal_hierarchy_type.linklevel) {
        case 1:
          this.cols = 12;
          break;
        case 2:
          if (this.viewLevel === 1) return 6;
          if (this.viewLevel === 2) return 12;
          break;
        case 3:
          if (this.viewLevel === 1) return 4;
          if (this.viewLevel === 2) return 6;
          if (this.viewLevel === 3) return 12;
          break;
        case 4:
          if (this.viewLevel === 1) return 3;
          if (this.viewLevel === 2) return 4;
          if (this.viewLevel === 3) return 8;
          if (this.viewLevel === 4) return 12;
          break;
        case 5:
          if (this.viewLevel === 1) return 2;
          if (this.viewLevel === 2) return 3;
          if (this.viewLevel === 3) return 4;
          if (this.viewLevel === 4) return 8;
          if (this.viewLevel === 5) return 12;
          break;
        case 6:
          if (this.viewLevel === 1) this.cols = 2;
          if (this.viewLevel === 2) this.cols = 2;
          if (this.viewLevel === 3) this.cols = 3;
          if (this.viewLevel === 4) this.cols = 4;
          if (this.viewLevel === 5) this.cols = 8;
          if (this.viewLevel === 6) this.cols = 12;
          break;
      }
      return 12;
    },
    doMenuAction(action) {
      switch (action.action) {
        case "edit":
        case "rename":
        case "move":
        case "merge":
        case "add":
        case "delete":
        case "reactivate":
        case "deactivate":
          this.setUpEditHierarchy(action.node, action.action, action);
          break;
        // case "exportNodeDescriptors":
        //   this.exportNodeDescriptors(action.node);
        //   break;
        // case "viewNodeDescriptors":
        //   this.buildNodeDescriptorViewReview(action.node, true);
        //   break;
        // case "exportNodeDescriptorsNested":
        //   this.exportNodeDescriptors(action.node, true);
        //   break;
        case "editDescriptor":
        case "editHierarchyDescriptor":
          this.setUpEditHierarchy(action.node, "editDescriptor");
          break;
        // case "drilldown":
        //   this.doDrilldown(item, compareOption);
        //   break;
        case "addHierarchyDocument":
          this.addHierarchyDocument(action.node);
          break;
        case "showReview":
          this.showHierarchyNodeReview(action.node);
          break;
        case "showReviewParent":
          this.buildNodeDescriptorViewReview(action.node, true, "existing");
          break;
        case "addFilter":
          this.setFilterValue(action.listviewItem, action.listviewColumn);
          break;
        case "removeFilter":
          this.removeFilterValue(action.listviewItem, action.listviewColumn);
          break;
        case "history":
          this.historyNode = action.node;
          break;
        case "hr_id":
          this.hr_idDialogue.node = action.node;
          this.hr_idDialogue.show = true;
          break;
      }
    },
    setUpEditHierarchy(h, mode, action) {
      this.hierarchyEditDialogue.show = true;
      this.hierarchyEditDialogue.node = h;
      this.hierarchyEditDialogue.mode = mode;
      this.hierarchyEditDialogue.action = action;
    },
    nodePicked(details) {
      let target = this.editDialogue.columns.find(
        (x) => x.ht_id === details.original.ht_id
      );
      if (target.value !== details.updated.hr_id) {
        let labels = [details.updated.node.name];
        if (details.updated.node.parents)
          details.updated.node.parents.forEach((p) => labels.unshift(p.name));
        target.value = details.updated.hr_id;
        target.text = details.updated.node.name;
        target.isDirty = true;
        target.hLabel = labels;
        if (this.editDialogue.items.length === 1) {
          this.editDialogue.items[0]["change_" + target.value_column] = true;
        }
      }
      this.setCanSave();
      this.hierarchyEditDialogue.show = false;
    },
    nodeUpdated(details) {
      let desc = null;
      switch (details.action) {
        case "rename":
          this.hierarchyEditDialogue.node.name = details.updated.new_name;
          break;
        case "editDescriptor":
          this.hierarchyEditDialogue.node.description =
            details.updated.description;
          desc = this.hierarchyEditDialogue.node.descriptor;
          if (desc) {
            desc.description = details.updated.description;
          }
          break;
        case "add":
        case "add-root":
        case "move":
        case "merge":
        case "delete":
          this.hierarchyEditDialogue.show = false;
          this.hierarchyAddDialogue.show = false;
          if (!this.ht_id) this.$emit("nodeUpdated", details);
          else this.getData();
          return;
        case "deactivate":
          this.setNodeActiveStatus(this.hierarchyEditDialogue.node, null, true);
          break;
        case "reactivate":
          this.hierarchyEditDialogue.node.active = 1;
          this.setNodeActiveStatus(this.hierarchyEditDialogue.node);
          break;
      }
      this.hierarchyEditDialogue.show = false;
      this.hierarchyAddDialogue.show = false;
    },
    addHierarchy() {
      //   this.hierarchyAddDialogue.ht_id = this.ht_id;
      //   this.hierarchyAddDialogue.show = true;
      let node = JSON.parse(JSON.stringify(this.internal_nodes[0]));
      node.name = "";
      node.hierarchy_node_id = null;
      node.nodes = [];
      this.hierarchyEditDialogue.show = true;
      this.hierarchyEditDialogue.node = node;
      this.hierarchyEditDialogue.mode = "add-root";
      this.hierarchyEditDialogue.action = {
        title: "Add new " + this.internal_hierarchy_type.level1_name,
        icon: "mdi-note-plus-outline",
        type: "add-root",
        column: null,
      };
    },
    // saveHierarchy() {
    //   if (!this.hierarchyAddDialogue.text) return;
    //   this.hierarchyAddDialogue.show = false;
    //   this.hierarchyAddDialogue.hierarchyType.values.push(
    //     this.hierarchyAddDialogue.text
    //   );
    // },
    openlistViewFilter(filterSettings) {
      if (!filterSettings.show) {
        filterSettings.values
          .filter((v) => v.selected && !v.applied)
          .forEach((v) => (v.selected = false));
        filterSettings.values
          .filter((v) => !v.selected && v.applied)
          .forEach((v) => (v.selected = true));
        return;
      }
      filterSettings.values
        .filter((v) => !v.selected && v.applied)
        .forEach((v) => (v.applied = false));
      if (filterSettings.mostRecent) return;
      let usedValues = this.distinctValues(this.listData, [
        filterSettings.column,
      ]).map((x) => x[filterSettings.column]);
      let valueCount = 0;
      if (
        usedValues.length === filterSettings.values.length ||
        (this.activeFilterCount === 1 && filterSettings.isActive)
      ) {
        valueCount = filterSettings.values.length;
        filterSettings.values.forEach((x, xi) => {
          x.visible = true;
          x.page = parseInt(xi / 7) + 1;
        });
      } else {
        filterSettings.values.forEach((v) => {
          v.visible = usedValues.some((uv) => uv === v.text);
          v.page = v.visible ? parseInt(valueCount / 7) + 1 : 0;
          if (v.visible) valueCount++;
        });
      }
      filterSettings.pages = parseInt((valueCount - 1) / 7) + 1;
      filterSettings.page = 1;
    },
    removeFilter(f) {
      let lvfilter = this.listColumns.find((x) => x.value === f.column);
      if (lvfilter) {
        lvfilter.filterSettings.values.forEach((x) => {
          x.selected = false;
          x.applied = false;
        });
        lvfilter.filterSettings.isActive = false;
        this.filterData();
      }
    },
    filterScroll(data, filterSettings) {
      if (data) {
        let page = filterSettings.page + (data.deltaY >= 0 ? 1 : -1);
        if (page > filterSettings.pages || page < 1) return;
        filterSettings.page = page;
      }
    },
    setFilterValue(item, column) {
      if (column && column.filterSettings) {
        let value = item[column.value];
        column.filterSettings.values.forEach((x) => {
          x.selected = x.value === value;
        });
        this.filterHeading(column.filterSettings);
      }
    },
    removeFilterValue(item, column) {
      if (column && column.filterSettings) {
        let value = item[column.value];
        column.filterSettings.values
          .filter((x) => x.value === value)
          .forEach((x) => (x.selected = false));
        this.filterHeading(column.filterSettings);
      }
    },
    filterHeading(filterSettings) {
      filterSettings.values
        .filter((v) => v.selected)
        .forEach((v) => (v.applied = true));
      filterSettings.isActive = filterSettings.values.some((x) => x.selected);
      filterSettings.show = false;
      let activeCount = 0;
      this.listColumns
        .filter((c) => c.filterSettings)
        .forEach((c) => {
          c.filterSettings.mostRecent = false;
          if (c.filterSettings.isActive) activeCount++;
        });
      filterSettings.mostRecent = filterSettings.isActive;
      this.activeFilterCount = activeCount;
      this.filterData();
    },
    filterSearchAll(filterSettings) {
      if (filterSettings.searchText) {
        let newVal = !filterSettings.values.every(
          (x) => !x.visible || x.selected
        );
        filterSettings.values.forEach((x) => {
          if (x.visible || !newVal) x.selected = newVal;
        });
      } else {
        let newVal = !filterSettings.values.every((x) => x.selected);
        filterSettings.values.forEach((x) => {
          x.selected = newVal;
        });
      }
    },
    filterSearch(filterSettings) {
      let index = 0;
      let search = (filterSettings.searchText || "")
        .toLowerCase()
        .split(" ")
        .filter((x) => x);
      filterSettings.values.forEach((x) => {
        const visible =
          !search.length ||
          search.every((s) => x.searchValues.some((v) => v.indexOf(s) >= 0));
        x.visible = visible;
        if (visible) {
          x.page = parseInt(index / 7) + 1;
          index++;
        } else {
          x.page = 0;
        }
      });
      filterSettings.pages = parseInt((index - 1) / 7) + 1;
      filterSettings.page = 1;
    },
    filterClear(filterSettings) {
      filterSettings.searchText = "";
      filterSettings.values.forEach((x, xi) => {
        x.visible = true;
        x.selected = false;
        x.applied = false;
        x.page = parseInt(xi / 7) + 1;
      });
      filterSettings.pages =
        parseInt((filterSettings.values.length - 1) / 7) + 1;
      this.filterHeading(filterSettings);
    },
    exportLVToCSV() {
      let cols = this.listColumns.map((x) => {
        return {
          text: x.text,
          value: x.value,
        };
      });
      let data = cols.map((h) => '"' + h.text + '"').join(",");
      data += "\n";

      this.flattened.forEach((d) => {
        data += cols
          .map((h) =>
            utils.csvEscape(utils.removeTags(d[h.value]).replace(/\n/g, "|"))
          )
          .join(",");
        data += "\n";
      });

      utils.downloadFile(data, "JobFamily.csv", "text/csv;encoding:utf-8");
    },
    clearFilterTree() {
      this.treeSearchText = "";
      this.filterTree();
    },
    filterTree() {
      if (!this.internal_nodes) return;

      this.isLoading = true;
      let self = this;
      let filterNodesActive = (nodes, hideInactive) => {
        let visNodes = nodes.filter((n) => n.active || !hideInactive);
        visNodes.forEach((n) => {
          if (n.nodes) n.nodes = filterNodesActive(n.nodes, hideInactive);
        });
        return visNodes;
      };
      let filterNodesText = (nodes, text) => {
        return nodes.filter((n) => {
          let nText = n.name.toLowerCase();
          let isMatch = text.every((t) => nText.indexOf(t) >= 0);
          if (!isMatch && n.nodes) {
            n.nodes = filterNodesText(n.nodes, text);
            isMatch = !!n.nodes.length;
            if (isMatch) n.expanded = true;
          }
          return isMatch;
        });
      };
      const search = self.treeSearchText
        .toLowerCase()
        .split(" ")
        .filter((x) => x);
      setTimeout(function () {
        let visible_nodes = filterNodesActive(
          JSON.parse(JSON.stringify(self.internal_nodes)),
          self.hideInactive
        );
        if (search.length) {
          visible_nodes = filterNodesText(visible_nodes, search);
        }
        self.visible_nodes = visible_nodes;
        self.isLoading = false;
      }, 0);
    },
    filterData() {
      let filters = this.listColumns
        .filter((c) => c.filterSettings.isActive)
        .map((c) => {
          return {
            name: c.text,
            column: c.filterSettings.column,
            values: c.filterSettings.values
              .filter((v) => v.selected)
              .map((v) => v.value),
          };
        });
      let activeFilters = this.hideInactive
        ? this.listColumns
            .filter((c) => c.level)
            .map((c) => `col${c.level}_active`)
        : [];
      let data =
        !filters.length && !this.hideInactive
          ? this.flattened
          : this.flattened.filter((x) => {
              let res = true;
              if (filters.length)
                res = filters.every((f) =>
                  f.values.some((v) => x[f.column] === v)
                );
              if (res && activeFilters.length)
                res = activeFilters.every((f) => x[f] === undefined || x[f]);
              return res;
            });
      this.listData = data;
      this.filterList = filters.map((f) => {
        return {
          title: `${f.name} = [${f.values.join(", ")}]`,
          name: f.name,
          column: f.column,
        };
      });
      this.filterTree();
    },
    setLVPageSize(value) {
      if (this.listView.pageSize === 0) {
        localStorage.setItem("jcRPP", value);
        this.listView.itemsPerPage = value;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.nodeContainer {
  /*width: 50%;*/
  height: 24px;
  padding-left: 6px;
  display: inline-block;
  width: calc(100% - 3px);
}
.nodeSeleted {
  background-color: rgb(205, 243, 205);
}
.nodePicked {
  background-color: #e8e7e7;
}
.nodeInfoIcon {
  float: right;
  padding-top: 2px;
}
.nodeInner {
  display: block;
  height: 24px;
  font-family: "Martel Sans", sans-serif;
  font-size: 12px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: calc(100% - 25px);
  float: left;
}
.node {
  border: solid 1px #004d40;
  border-radius: 5px;
  display: inline-block;
  height: 24px;
  font-family: "Martel Sans", sans-serif;
  font-size: 12px;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: calc(100% - 25px);
  //   float: right;
}
.nodePickable {
  width: 80%;
}
// .nodeNonPickable {
//   width: 90%;
// }
.nodeExpandable {
  cursor: pointer;
}
.pickable {
  width: 10%;
  float: right;
  cursor: grab;
}
.noDocs {
  background-color: rgb(245, 214, 202);
}
.vertical-line,
.vertical-line-1,
.vertical-line-2 {
  display: inline-block;
  float: left;
  width: 1px;
  background-color: lightgray;
  margin-left: calc(7px + 100% / -9);
  margin-top: -40px;
  height: calc(100% + 20px);
  z-index: -1;
}
.horizontal-line {
  display: inline-block;
  float: left;
  width: calc(5px + 100% / 9);
  height: 2px;
  background-color: lightgray;
  margin-top: 10px;
  margin-left: calc(7px + 100% / -9);
  margin-right: -12px;
}
.vertical-line-1,
.vertical-line-2 {
  margin-top: -20px;
  height: calc(100%);
}
.vertical-line-2 {
  margin-left: calc(7px + 100% / -12);
}
.status-col {
  width: 60px;
  float: right;
  display: inline-block;
}
</style>