import Vue from "vue";
import axios from "axios";
import { io } from 'socket.io-client';

let instance;

export const getInstance = () => instance;

export const useEventGateway = (opts) => {
    if (instance) return instance;
  
    let defaultOptions = {
        events: [],
        count: 0,
        socket:null,
        connectionState: false,
        un: ""
    }

    instance = new Vue({
      data: () => ({ ...defaultOptions, ...opts }),
      methods: {
        registerListeners(){
            this.socket.on('disconnect', () => {
                this.connectionState = false;
                this.connect(this.un);
            }); 

            this.socket.on("*", (event,data) => {
                //this.transformMessageToEvent(event, data);
                this.$emit(event,data);
            });

            /*this.socket.on('roomCreated', (data) => {
                this.$emit("roomCreated",data);
            });
    
            this.socket.on('roomUpdate', (data) => {
                this.$emit("roomUpdate",data);
            });
    
            this.socket.on('newUser', (data) => {
                this.$emit("newUser",data);
            }); */           
        },        
        getLogs() {
            axios
            .get(`message/eventsLog`)
            .then((resp) => {
                if (resp.data.Status === "OK") {
                    this.events = resp.data.Data;
                }
            })
            .catch((err) => {
            if (err.response && err.response.status === 401) {
                this.$emit("sessionExpired", err);
            } else {
                console.log(err);
                this.response = err.response
                ? err.response.data
                : { message: "Unexpected Error" };
            }
            this.isLoading = false;
            });
        },      
        getRecentEvents(){
            return this.events.splice(0,5);
        },  
        connect(un){
            this.socket = io(process.env.VUE_APP_EVENT_GATEWAY_URL, {
                transports: ['websocket'], 
                upgrade: false,
                query: {
                    "un": un
                  }
            });

            this.registerListeners();
            var onevent = this.socket.onevent;

            this.socket.onevent = function (packet) {
                var args = packet.data || [];
                onevent.call (this, packet);
                packet.data = ["*"].concat(args);
                onevent.call(this, packet);
            };

            this.socket.on('connect', () => {
                this.getLogs();
                this.un = un;
                this.connectionState = true;
                //this.events.push("Connected to Events Gateway");
                //this.message(`Connected to Events Gateway`);
                this.$emit("connected","Connected to Events Gateway");
            });            

        },
        disconnect (){
            this.socket.disconnect();
        },
        send(fn, data){
            console.log(fn,data);
            this.socket.emit(fn,data);
        },
        message(msg){
            this.events.push(msg);
        },
        transformMessageToEvent(event,data){
            console.log(event);
            console.log(data);
        }
      },
    });
  
    return instance;
};

const EventGatewayPlugin = {
    install(Vue, options) {
        Vue.prototype.$eventGateway = useEventGateway(options);
    }
}

export default EventGatewayPlugin;