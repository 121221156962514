<template>
    <div :style="{ 'padding-top': admin ? '0px' : '48px' }">
        <div v-if="admin" style="height:42px">
            <div class="pt-2 pl-8">
                <v-btn icon title="Add New Section" @click="addSection">
                    <v-icon style="font-size:20px !important">mdi-plus-circle-outline</v-icon>
                </v-btn>
                <v-btn icon title="Edit Section Order" @click="editSectionOrder">
                    <v-icon style="font-size:20px !important">mdi-arrow-split-horizontal</v-icon>
                </v-btn>                
            </div>
        </div>
        <div v-if="currentView.sections.length === 0 && admin" style="padding: 15px;">
            No Sections - Click To Create!
        </div>
        <div
            v-for="(item, key) in visibleSection(currentView.sections)"
            v-else
            :key="key"
            @click="activateSection(item.tmpl_view_section_id)"
            class="docSectionHolder"   
            :style="cssVars"
        >
            <div v-if="item.complete" style="position: absolute; left: 12px; padding-top: 8px;">
                <v-icon  :class="[item.complete ? 'sectionComplete' : '']">mdi-check</v-icon>
            </div>        
            <div class="label" 
                @mouseover="item.hover = true"
                @mouseleave="item.hover = false"  
                :class="[`${item.tmpl_view_section_id}|` === current_sectionId ? 'activeViewSection' : '', item.complete ? 'sectionComplete' : '', 'userPilotLCMenu'+item.section.replace(/\s+/g, '')]"
            >
                {{item.section}}   
                <v-fade-transition>
                    <v-overlay
                        absolute
                        opacity="0.1"
                        v-if="item.hover && admin"
                        color="#036358"
                        class="sectionOverlayMenu  align-center justify-center"
                        @click="activateSection(item.tmpl_view_section_id)"
                    >
                    <div style="width: 200px; float: right;">
                        <v-icon title="Add Header to Section" @click.stop="addSubSection(item.tmpl_view_section_id)" style="float:right; margin-right:10px; color:#9499A0">mdi-subdirectory-arrow-right</v-icon>  
                        <v-icon title="Edit Section" @click.stop="editSection(item.tmpl_view_section_id)" style="float:right; margin-right:10px; color:#9499A0">mdi-cog</v-icon> 
                        <v-icon :title="`${item.permissionControlled || item.hrPermissionControlled || item.valueControlled ? 'Edit' : 'Add'} Permissions`" :color="item.permissionControlled || item.hrPermissionControlled || item.valueControlled ? 'success' : ''" @click.stop="editPermissions(item.tmpl_view_section_id)" style="float:right; margin-right:10px; color:#9499A0">mdi-security</v-icon> 
                    </div>
   
                    </v-overlay>
                </v-fade-transition>
            </div>
            <div v-for="(header,hIdx) in visibleSection(item.sub_sections)"
                :key="`${key}_${hIdx}`" 
                @click.stop="activateSection(item.tmpl_view_section_id, header.tmpl_view_section_id)"
                class="docSectionHeaderLabel"
                :class="[`${item.tmpl_view_section_id}|${header.tmpl_view_section_id}` === current_sectionId ? 'activeViewSection' : '', 'userPilotLCMenu'+header.section.replace(/\s+/g, '')]"
            ><!--OZ-->
                <div v-if="header.complete" style="position: absolute; left: 12px; padding-top: 8px;">
                    <v-icon  :class="[header.complete ? 'sectionComplete' : '']">mdi-check</v-icon>
                </div>
                <div 
                    class="subLabel" 
                    @mouseover.stop="item.hover = false; header.hover = true"
                    @mouseleave.stop="item.hover = false; header.hover = false"    
                    :class="[header.complete ? 'sectionComplete' : '']"
                >
                    {{ header.section }}
                    <v-fade-transition>
                        <v-overlay
                            absolute
                            opacity="0.1"
                            v-if="header.hover && admin"
                            color="#036358"
                            class="subSectionOverlayMenu"
                            @click.stop="activateSection(item.tmpl_view_section_id, header.tmpl_view_section_id)"
                        >
                        <div style="width: 200px; float: right;">
                            <v-icon title="Edit Section Contents" @click.stop="editSection(item.tmpl_view_section_id, header.tmpl_view_section_id)" style="float:right; margin-right:15px; color:#9499A0;">mdi-cog</v-icon> 
                            <v-icon :title="`${header.permissionControlled || header.hrPermissionControlled || header.valueControlled ? 'Edit' : 'Add'} Permissions`" :color="header.permissionControlled || header.hrPermissionControlled || header.valueControlled  ? 'success' : ''" @click.stop="editPermissions(item.tmpl_view_section_id, header.tmpl_view_section_id)" style="float:right; margin-right:10px; color:#9499A0">mdi-security</v-icon> 
                        </div>
    
                        </v-overlay>
                    </v-fade-transition>                    
                </div>

            </div>
        </div>
       
    </div>
</template>

<script>
export default {
    name: 'ViewSection',
    props: {
        templateView: Object,
        adminMode: Boolean
    },
    data: function() {
        return {
            currentView: {
                view_name: "",
                sections: []
            },
            current_sectionId: "",
            admin: this.adminMode ?? false
        }
    },
    components: {

    },

    watch: {
        templateView(val){
            this.init(val);
        }
    },    
    computed: {
        subSectionHoverPad(){
            return this.admin ? "55px" : "0px";
        },
        sectionHoverPad(){
            return this.admin ? "75px" : "0px";
        },        
        cssVars () {
            return {
                '--section-hover-pad': this.sectionHoverPad,
                '--subsection-hover-pad': this.subSectionHoverPad
            }
        }
    },
    mounted() {

    },
    created() {
        this.init(this.templateView);
    },    
    methods: { 
        visibleSection(s){
            return s?.filter(x => x.permissions_list.displayPermissionOverride === null || x.permissions_list.displayPermissionOverride === true);
        },
        addSubSection(parentId){
            this.$emit("addSubSection",parentId);
        },
        addSection(){
            this.$emit("addSection");
        },
        editSectionOrder(){
            this.$emit("editSectionOrder");
        },
        editSection(name,header){
            let target = `${name ?? ""}|${header ?? ""}`;
            this.$emit("editSection", target);
        },
        editPermissions(name,header){
            let target = `${name ?? ""}|${header ?? ""}`;
            this.$emit("editPermissions", target);
        },
        init(val){
            if(val){
                this.currentView = val;
                if(val.sections?.length > 0){
                    this.activateSection(val.sections[0].tmpl_view_section_id);
                }
            }

        },
        setActive(name,header){
            this.current_sectionId = `${name ?? ""}|${header ?? ""}`;
        },
        activateSection(name,header){
            this.current_sectionId = `${name ?? ""}|${header ?? ""}`;
            this.$emit("changeSection", this.current_sectionId);
        },
        activateHeader(section, header){
            if(section !== this.current_section){
                this.activateSection(section);
            }
            this.current_header = header;
            this.$emit("changeSection", this.current_sectionId);
        }
    }
}
</script>

<style scoped lang="scss">

    * {
        font-family: "Manrope" !important;
        font-size: 14px !important;
        line-height: 22px !important;
    }

    .sectionOverlayMenu{
        cursor: pointer;
        height: 40px;
        position: absolute;
        top: 6px;
        border-radius: 8px;
        width: 100%;
        text-align: right;
        flex-direction: column;   
        
        &.v-overlay__content {
            width: 100%;
        }
    }

    .subSectionOverlayMenu{
        cursor: pointer;
        height: 40px;
        position: absolute;
        top: 0px;
        border-radius: 8px;
        width: 100%;
        text-align: right;
        flex-direction: column;   
        
        &.v-overlay__content {
            width: 100%;
        }   
    }

    .docSectionHolder {
        width:200px;
        font-size: 16px !important;
        padding-top: 6px;
        margin:auto;
        flex: none;
        order: 0;
        flex-grow: 0;        
        cursor: pointer;
        position: relative;

        &:first-child {
            padding-top: 0px;
        }

        .sectionComplete{
            
            color: #00B515 !important;    
        }

        .label{
            height:40px;
            padding-top:9px;
            padding-left:16px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: #9499A0;  
            word-wrap: break-word;
            word-break: break-word;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;         

            &:hover{
                padding-right: var(--section-hover-pad); 
            }

            &.sectionComplete{
                padding-left:32px;
                color: #00B515 !important;    
            }
            
            .headerActions{
                float:right;
            }
        }

        .docSectionHeaderLabel {
            height:40px;
            padding-bottom:11px;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            width:100%;
            color: #9499A0;   


            .subLabel{
                height:40px;
                padding-top:9px;
                padding-left: 32px;
                font-size: 14px;
                line-height: 22px;
                width:100%;                
                position: relative;  
                word-wrap: break-word;
                word-break: break-word;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;  

                &:hover{
                    padding-right: var(--subsection-hover-pad); 
                }
            }

            .sectionComplete{
                color: #00B515 !important;    
            }
        }

        .activeViewSection{
            height:40px;
            background: #FFFFFF;
            box-shadow: 0px 3px 16px -4px rgba(0, 0, 0, 0.08);
            border-radius: 8px;
            font-weight: 700 !important;
            font-size: 14px !important;
            line-height: 22px !important;  
            color: #54616C !important;     
            &.sectionComplete{
                color: #00B515 !important;    
            }            
        }
    }
</style>